import type { CSSProperties, SVGProps } from 'react';

import { Card } from '../../../../components/Card';

import { cn } from '../../../../lib/utils';

export interface SuiteCardProps {
  style?: CSSProperties;
  type: 'input' | 'output';
  label: string;
  icon: React.JSXElementConstructor<SVGProps<SVGSVGElement>>;
}

export function SuiteCard({ type, style, icon: Icon, label }: SuiteCardProps) {
  const isInput = type === 'input';

  return (
    <Card
      color={isInput ? 'primary' : 'accent'}
      style={style}
      className="relative flex flex-col items-center justify-center min-w-0 min-h-0 gap-0 home-shadow-md lg:gap-2"
    >
      <Icon
        className={cn('w-5 h-5 mt-2 md:mt-0 md:w-8 md:h-8 flex-shrink-0', {
          'text-primary': isInput,
          'text-[#3E8EFF]': !isInput,
        })}
        width="100%"
        height="100%"
      />

      <div className="text-xs leading-snug text-center scale-75 sm:text-sm sm:scale-75 md:scale-100">
        {label}
      </div>
    </Card>
  );
}
