import { Performance } from '../../animations/landing/Performance';
import { FeatureSection } from '../../sections/landing/FeatureSection';
import { Button } from '../../ui/Button';

export function Analytics(props: { showRequestDemoModal: () => void }) {
  return (
    <FeatureSection
      label="Powerful analytics"
      title="Performance you can see & understand"
      description="Track your progress with real-time metrics. See how your enforcement is doing and drill down into specific policies and AI models. Get a closer look at moderator performance, create custom dashboards, and A/B test new enforcement ideas all in one place."
      cta={
        <Button onClick={props.showRequestDemoModal}>
          Get started with Cove
        </Button>
      }
    >
      <Performance />
    </FeatureSection>
  );
}
