import CoveButton, { type CoveButtonType } from './CoveButton';

export type CoveModalFooterButtonProps = {
  title: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  type?: CoveButtonType;
  loading?: boolean;
  disabled?: boolean;
};

export default function CoveModalFooter(props: {
  buttons: CoveModalFooterButtonProps[];
}) {
  const { buttons } = props;
  return (
    <div className="flex justify-end pt-4 gap-2">
      {buttons.map((button, i) => (
        <CoveButton
          key={i}
          title={button.title}
          type={button.type}
          onClick={button.onClick}
          loading={button.loading ?? false}
          disabled={button.disabled ?? false}
        />
      ))}
    </div>
  );
}
