import { m, type Variants } from "framer-motion";

const areaVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
      delay: 1.8,
    },
  },
};

interface GraphAreaProps {
  animate: boolean;
}

export function GraphArea({ animate }: GraphAreaProps) {
  return (
    <m.path
      variants={areaVariants}
      initial="hidden"
      animate={animate ? "visible" : "hidden"}
      fill="url(#a)"
      d="M96.5 117.795s94.698 22.133 157.606 73.757c62.908 51.624 94.362 184.371 157.27 184.371 62.908 0 94.362-54.696 157.27-101.404 62.908-46.708 157.27-132.133 157.27-132.133v274.191s-94.362-.706-157.27-.706c-63.146 1.704-94.362 0-157.27 0-62.876 1.704-94.376 1.205-157.27 0-63.106 1.205-157.606.706-157.606.706V117.795Z"
    />
  );
}
