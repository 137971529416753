import type { FilledIconProps } from '../types';
import { ReactComponent as Backward } from './backward.svg';
import { ReactComponent as Boombox } from './boombox.svg';
import { ReactComponent as Camera3 } from './camera-3.svg';
import { ReactComponent as Cctv } from './cctv.svg';
import { ReactComponent as DialHigh } from './dial-high.svg';
import { ReactComponent as DialMax } from './dial-max.svg';
import { ReactComponent as Dial } from './dial.svg';
import { ReactComponent as Drone } from './drone.svg';
import { ReactComponent as ExpandAlt1 } from './expand-alt-1.svg';
import { ReactComponent as FastBackward1 } from './fast-backward-1.svg';
import { ReactComponent as FastBackward } from './fast-backward.svg';
import { ReactComponent as FastForward } from './fast-forward.svg';
import { ReactComponent as Film } from './film.svg';
import { ReactComponent as Forward } from './forward.svg';
import { ReactComponent as FrameExpand } from './frame-expand.svg';
import { ReactComponent as FrameMinimize } from './frame-minimize.svg';
import { ReactComponent as FullScreen } from './full-screen.svg';
import { ReactComponent as Gallery } from './gallery.svg';
import { ReactComponent as HdVideoAlt } from './hd-video-alt.svg';
import { ReactComponent as HdVideo } from './hd-video.svg';
import { ReactComponent as MicrophoneSlash } from './microphone-slash.svg';
import { ReactComponent as Next } from './next.svg';
import { ReactComponent as Play } from './play.svg';
import { ReactComponent as Previous } from './previous.svg';
import { ReactComponent as Projector } from './projector.svg';
import { ReactComponent as Radio } from './radio.svg';
import { ReactComponent as RepeatOne } from './repeat-one.svg';
import { ReactComponent as Shuffle } from './shuffle.svg';
import { ReactComponent as Slideshow } from './slideshow.svg';
import { ReactComponent as Speaker } from './speaker.svg';
import { ReactComponent as Stop } from './stop.svg';
import { ReactComponent as VideoAlt1 } from './video-alt-1.svg';
import { ReactComponent as VideoAlt } from './video-alt.svg';
import { ReactComponent as VideoCameraAlt1 } from './video-camera-alt-1.svg';
import { ReactComponent as VideoCameraAlt2 } from './video-camera-alt-2.svg';
import { ReactComponent as VideoCameraAlt3 } from './video-camera-alt-3.svg';
import { ReactComponent as VideoCameraAlt4 } from './video-camera-alt-4.svg';
import { ReactComponent as VideoCameraAlt } from './video-camera-alt.svg';
import { ReactComponent as VideoCut } from './video-cut.svg';
import { ReactComponent as VideoPlayback } from './video-playback.svg';
import { ReactComponent as Video } from './video.svg';
import { ReactComponent as VolumeAlt } from './volume-alt.svg';
import { ReactComponent as VolumeHigh } from './volume-high.svg';
import { ReactComponent as VolumeLow } from './volume-low.svg';
import { ReactComponent as VolumeMedium } from './volume-medium.svg';
import { ReactComponent as VolumeMute } from './volume-mute.svg';
import { ReactComponent as Volume } from './volume.svg';
import { ReactComponent as WaveformLines } from './waveform-lines.svg';
import { ReactComponent as Webcam } from './webcam.svg';

const BackwardFilled = (props: FilledIconProps) => (
  <Backward fill="currentColor" {...props} />
);

const BoomboxFilled = (props: FilledIconProps) => (
  <Boombox fill="currentColor" {...props} />
);

const Camera3Filled = (props: FilledIconProps) => (
  <Camera3 fill="currentColor" {...props} />
);

const CctvFilled = (props: FilledIconProps) => (
  <Cctv fill="currentColor" {...props} />
);

const DialHighFilled = (props: FilledIconProps) => (
  <DialHigh fill="currentColor" {...props} />
);

const DialMaxFilled = (props: FilledIconProps) => (
  <DialMax fill="currentColor" {...props} />
);

const DialFilled = (props: FilledIconProps) => (
  <Dial fill="currentColor" {...props} />
);

const DroneFilled = (props: FilledIconProps) => (
  <Drone fill="currentColor" {...props} />
);

const ExpandAlt1Filled = (props: FilledIconProps) => (
  <ExpandAlt1 fill="currentColor" {...props} />
);

const FastBackward1Filled = (props: FilledIconProps) => (
  <FastBackward1 fill="currentColor" {...props} />
);

const FastBackwardFilled = (props: FilledIconProps) => (
  <FastBackward fill="currentColor" {...props} />
);

const FastForwardFilled = (props: FilledIconProps) => (
  <FastForward fill="currentColor" {...props} />
);

const FilmFilled = (props: FilledIconProps) => (
  <Film fill="currentColor" {...props} />
);

const ForwardFilled = (props: FilledIconProps) => (
  <Forward fill="currentColor" {...props} />
);

const FrameExpandFilled = (props: FilledIconProps) => (
  <FrameExpand fill="currentColor" {...props} />
);

const FrameMinimizeFilled = (props: FilledIconProps) => (
  <FrameMinimize fill="currentColor" {...props} />
);

const FullScreenFilled = (props: FilledIconProps) => (
  <FullScreen fill="currentColor" {...props} />
);

const GalleryFilled = (props: FilledIconProps) => (
  <Gallery fill="currentColor" {...props} />
);

const HdVideoAltFilled = (props: FilledIconProps) => (
  <HdVideoAlt fill="currentColor" {...props} />
);

const HdVideoFilled = (props: FilledIconProps) => (
  <HdVideo fill="currentColor" {...props} />
);

const MicrophoneSlashFilled = (props: FilledIconProps) => (
  <MicrophoneSlash fill="currentColor" {...props} />
);

const NextFilled = (props: FilledIconProps) => (
  <Next fill="currentColor" {...props} />
);

const PlayFilled = (props: FilledIconProps) => (
  <Play fill="currentColor" {...props} />
);

const PreviousFilled = (props: FilledIconProps) => (
  <Previous fill="currentColor" {...props} />
);

const ProjectorFilled = (props: FilledIconProps) => (
  <Projector fill="currentColor" {...props} />
);

const RadioFilled = (props: FilledIconProps) => (
  <Radio fill="currentColor" {...props} />
);

const RepeatOneFilled = (props: FilledIconProps) => (
  <RepeatOne fill="currentColor" {...props} />
);

const ShuffleFilled = (props: FilledIconProps) => (
  <Shuffle fill="currentColor" {...props} />
);

const SlideshowFilled = (props: FilledIconProps) => (
  <Slideshow fill="currentColor" {...props} />
);

const SpeakerFilled = (props: FilledIconProps) => (
  <Speaker fill="currentColor" {...props} />
);

const StopFilled = (props: FilledIconProps) => (
  <Stop fill="currentColor" {...props} />
);

const VideoAlt1Filled = (props: FilledIconProps) => (
  <VideoAlt1 fill="currentColor" {...props} />
);

const VideoAltFilled = (props: FilledIconProps) => (
  <VideoAlt fill="currentColor" {...props} />
);

const VideoCameraAlt1Filled = (props: FilledIconProps) => (
  <VideoCameraAlt1 fill="currentColor" {...props} />
);

const VideoCameraAlt2Filled = (props: FilledIconProps) => (
  <VideoCameraAlt2 fill="currentColor" {...props} />
);

const VideoCameraAlt3Filled = (props: FilledIconProps) => (
  <VideoCameraAlt3 fill="currentColor" {...props} />
);

const VideoCameraAlt4Filled = (props: FilledIconProps) => (
  <VideoCameraAlt4 fill="currentColor" {...props} />
);

const VideoCameraAltFilled = (props: FilledIconProps) => (
  <VideoCameraAlt fill="currentColor" {...props} />
);

const VideoCutFilled = (props: FilledIconProps) => (
  <VideoCut fill="currentColor" {...props} />
);

const VideoPlaybackFilled = (props: FilledIconProps) => (
  <VideoPlayback fill="currentColor" {...props} />
);

const VideoFilled = (props: FilledIconProps) => (
  <Video fill="currentColor" {...props} />
);

const VolumeAltFilled = (props: FilledIconProps) => (
  <VolumeAlt fill="currentColor" {...props} />
);

const VolumeHighFilled = (props: FilledIconProps) => (
  <VolumeHigh fill="currentColor" {...props} />
);

const VolumeLowFilled = (props: FilledIconProps) => (
  <VolumeLow fill="currentColor" {...props} />
);

const VolumeMediumFilled = (props: FilledIconProps) => (
  <VolumeMedium fill="currentColor" {...props} />
);

const VolumeMuteFilled = (props: FilledIconProps) => (
  <VolumeMute fill="currentColor" {...props} />
);

const VolumeFilled = (props: FilledIconProps) => (
  <Volume fill="currentColor" {...props} />
);

const WaveformLinesFilled = (props: FilledIconProps) => (
  <WaveformLines fill="currentColor" {...props} />
);

const WebcamFilled = (props: FilledIconProps) => (
  <Webcam fill="currentColor" {...props} />
);

export {
  BackwardFilled,
  BoomboxFilled,
  Camera3Filled,
  CctvFilled,
  DialHighFilled,
  DialMaxFilled,
  DialFilled,
  DroneFilled,
  ExpandAlt1Filled,
  FastBackward1Filled,
  FastBackwardFilled,
  FastForwardFilled,
  FilmFilled,
  ForwardFilled,
  FrameExpandFilled,
  FrameMinimizeFilled,
  FullScreenFilled,
  GalleryFilled,
  HdVideoAltFilled,
  HdVideoFilled,
  MicrophoneSlashFilled,
  NextFilled,
  PlayFilled,
  PreviousFilled,
  ProjectorFilled,
  RadioFilled,
  RepeatOneFilled,
  ShuffleFilled,
  SlideshowFilled,
  SpeakerFilled,
  StopFilled,
  VideoAlt1Filled,
  VideoAltFilled,
  VideoCameraAlt1Filled,
  VideoCameraAlt2Filled,
  VideoCameraAlt3Filled,
  VideoCameraAlt4Filled,
  VideoCameraAltFilled,
  VideoCutFilled,
  VideoPlaybackFilled,
  VideoFilled,
  VolumeAltFilled,
  VolumeHighFilled,
  VolumeLowFilled,
  VolumeMediumFilled,
  VolumeMuteFilled,
  VolumeFilled,
  WaveformLinesFilled,
  WebcamFilled,
};
