import { Content } from '../../animations/landing/Content';
import { FeatureSection } from '../../sections/landing/FeatureSection';
import { Button } from '../../ui/Button';

export function Flexibility(props: { showRequestDemoModal: () => void }) {
  return (
    <FeatureSection
      label="Platform agnostic"
      title="Work with any content and media types"
      description="Whether it’s posts, profiles, threads, listings, transactions, live streams, or anything else – Cove will work seamlessly with the content you have. We look at the entire object with all its relevant context, not just isolated pieces of information."
      cta={<Button onClick={props.showRequestDemoModal}>Request a demo</Button>}
    >
      <Content />
    </FeatureSection>
  );
}
