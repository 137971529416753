import {
  FlowChartAltFilled,
  FriendsFilled,
  LawBookFilled,
  SparklesFilled,
} from '@/icons';
import { ReactComponent as ChevronRight } from '@/icons/lni/Direction/chevron-right.svg';
import { animate, m } from 'framer-motion';
import { useEffect, useRef, useState, type SVGProps } from 'react';

import { cn } from '../../../lib/utils';
import { HorizontalDashes } from '../../animations/HorizontalDashes';
import { AutomatedEnforcement } from '../../animations/landing/AutomatedEnforcement';
import { CompilianceToolkit } from '../../animations/landing/ComplianceToolkit';
import { CustomModels } from '../../animations/landing/CustomModels/CustomModels';
import { Moderation } from '../../animations/landing/Moderation';
import { VerticalDashes } from '../../animations/VerticalDashes';
import { Card } from '../../Card';
import { Button } from '../../ui/Button';
import { Typography } from '../../ui/Typography';

interface ProductTabProps {
  icon: React.JSXElementConstructor<SVGProps<SVGSVGElement>>;
  iconSize?: number;
  title: string;
  autoProgress?: boolean;
  active?: boolean;
  goToNext: () => void;
  onClick: () => void;
}

function ProductTab({
  active,
  icon: Icon,
  onClick,
  iconSize = 22,
  autoProgress = true,
  goToNext,
  title,
}: ProductTabProps) {
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (active && autoProgress) {
      const node = ref.current;

      let timeout: NodeJS.Timeout;

      const controls = animate(0, 180, {
        duration: 8,
        ease: 'linear',
        onUpdate(value) {
          node && node.style.setProperty('--gradient-angle', `${value}deg`);
        },
        onComplete() {
          timeout = setTimeout(goToNext, 500);
        },
      });

      return () => {
        controls.stop();
        clearTimeout(timeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, active, autoProgress]);

  return (
    <button
      data-product-tab
      onClick={onClick}
      ref={ref}
      className={cn(
        'flex-shrink-0',
        'group',
        'cursor-pointer bg-white text-subtle h-[49px] transform-gpu overflow-hidden rounded-lg p-px font-semibold home-shadow-sm transition-all duration-300 active:scale-[0.99] active:opacity-50',
        '[background-clip:content-box,padding-box,padding-box,padding-box]',
        {
          'text-primary active': active,
          // I can't figure out how to get product-background-image to work, so
          // this is a temporary solution
          'border border-solid border-primary': active && autoProgress,
          // 'product-background-image': active && autoProgress,
          'border border-solid border-default': !active,
          'border-solid border-primary border': active && !autoProgress,
        },
      )}
    >
      <div className="flex flex-row items-center px-4 gap-2 center">
        <div
          className={cn(
            'text-muted hidden md:flex group-[.active]:text-primary flex-shrink-0',
          )}
        >
          <Icon width={iconSize} height={iconSize} />
        </div>
        {title}
      </div>
    </button>
  );
}

interface ProductScreenProps {
  title: string;
  description: string;
  active?: boolean;
  cta: {
    label: string;
  } & (
    | {
        url: string;
      }
    | { onClick: () => void }
  );
  children: React.ReactNode;
}

function ProductScreen({
  title,
  active,
  description,
  children,
  cta,
}: ProductScreenProps) {
  return (
    active && (
      <div className="relative flex flex-col-reverse items-center w-full h-full gap-8 md:grid md:grid-cols-2 md:gap-8">
        <m.div
          initial={{ x: -20, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 100,
            transition: { delay: 0.3, duration: 0.8 },
          }}
          className="flex flex-col items-start justify-start pr-16 gap-5 md:flex-1"
        >
          <Typography variant="headline" size="lg">
            {title}
          </Typography>

          <Typography
            variant="body"
            size="sm"
            className="md:text-lg"
            color="subtle"
          >
            {description}
          </Typography>

          {'url' in cta ? (
            <Button as="a" href={cta.url} className="-ml-6" variant="link">
              {cta.label}
              <ChevronRight width={14} className="fill-primary mt-0.5" />
            </Button>
          ) : (
            <Button
              as="button"
              onClick={cta.onClick}
              className="-ml-6"
              variant="link"
            >
              {cta.label}
              <ChevronRight width={14} className="fill-primary mt-0.5" />
            </Button>
          )}
        </m.div>
        <m.div
          className="w-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.25 } }}
        >
          {children}
        </m.div>
      </div>
    )
  );
}

const tabs = [
  {
    title: 'Custom AI Models',
    icon: SparklesFilled,
  },
  {
    title: 'Automated Enforcement',
    icon: FlowChartAltFilled,
  },
  {
    title: 'Moderator Console',
    icon: FriendsFilled,
  },
  {
    title: 'Compliance Toolkit',
    icon: LawBookFilled,
  },
];

export function Products() {
  const [currentTab, setCurrentTab] = useState(0);
  const [autoProgress, setAutoProgress] = useState(true);

  const tabsRef = useRef<HTMLDivElement>(null);

  function goToNext() {
    const index = (currentTab + 1) % tabs.length;
    setCurrentTab(index);
  }

  function goToTab(index: number) {
    setCurrentTab(index);
    setAutoProgress(false);
  }

  return (
    <section className="px-4 pt-16 lg:pt-48 lg:pb-16">
      <Card
        color="primary"
        size="lg"
        className="@container border-clear bg-clear md:bg-white md:border-primary container relative flex flex-col items-center justify-start px-0 py-8 md:py-16 md:h-[56rem]"
      >
        <div
          className="absolute top-0 left-0 hidden w-full -translate-y-full md:block bg-graph-paper mask-to-t h-52"
          style={{
            backgroundSize: 'calc(100cqw/12) 6.5rem',
            backgroundPosition: '-1px -1px',
          }}
        />

        <VerticalDashes className="hidden md:block mask-to-t absolute left-0 top-0 !h-60 -translate-x-px -translate-y-1/2" />

        <VerticalDashes className="hidden md:block mask-to-t absolute right-0 top-0 !h-60 -translate-y-1/2 translate-x-px" />

        <HorizontalDashes className="hidden md:block mask-to-l absolute left-0 top-0 !w-60 -translate-x-1/2 -translate-y-px" />

        <HorizontalDashes className="hidden md:block mask-to-r absolute right-0 top-0 !w-60 -translate-y-px translate-x-1/2" />

        <Typography variant="display" size="sm">
          Our products
        </Typography>

        <div
          ref={tabsRef}
          className="flex flex-row items-start justify-start w-full px-4 pt-8 pb-2 overflow-auto gap-4 md:overflow-x-scroll md:gap-11 md:px-8 md:justify-center"
        >
          {tabs.map((tab, idx) => (
            <ProductTab
              {...tab}
              autoProgress={autoProgress}
              key={tab.title}
              active={currentTab === idx}
              onClick={() => goToTab(idx)}
              goToNext={goToNext}
            />
          ))}
        </div>

        <div className="flex-grow w-full px-6 mt-5 md:px-14 md:overflow-hidden">
          <ProductScreen
            active={currentTab === 0}
            title="Create custom, LLM based models that enforce policies like you would"
            description="You give us a content policy definition in plain English, and we build you AI that understands your policy. Your custom AI models learn from your moderation decisions and automatically improve."
            cta={{ label: 'See how Custom AI Models work', url: 'ai' }}
          >
            <CustomModels />
          </ProductScreen>

          <ProductScreen
            active={currentTab === 1}
            title="Build automated rules to enforce policies - without writing code"
            description="Create rules that run on all your content, detect abuse, and take action automatically — all in a code-free UI. Use any form of detection you’d like, including custom AI models, behavioral signals, keywords and regexes, language detection, 3rd party AI models, and more."
            cta={{
              label: 'Get started now',
              url: 'enforcement',
            }}
          >
            <AutomatedEnforcement />
          </ProductScreen>

          <ProductScreen
            active={currentTab === 2}
            title="Empower moderators to make fast, context-rich decisions"
            description="Handle all of your manual moderation in one place, including creating review queues, triaging tickets, and conducting in-depth investigations. We automatically surface all the relevant context you might need to make a decision."
            cta={{
              label: 'Explore the Moderator Console',
              url: 'console',
            }}
          >
            <Moderation />
          </ProductScreen>

          <ProductScreen
            active={currentTab === 3}
            title="Comply automatically with emerging global regulations"
            description="Deliver your product to users all over the world without worrying about regulatory hurdles. We automatically generate transparency reports, provide a statement of reason with every moderation action, enable appeals, and handle reporting CSAM to NCMEC on your behalf."
            cta={{
              label: 'Learn more about Compliance',
              url: 'compliance',
            }}
          >
            <CompilianceToolkit />
          </ProductScreen>
        </div>
      </Card>
    </section>
  );
}
