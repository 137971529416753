import { animate, useInView } from 'framer-motion';
import { useEffect, useRef } from 'react';

interface CounterProps {
  value: number;
  start?: number;
}

export default function Counter({ value, start = 0 }: CounterProps) {
  const ref = useRef<HTMLSpanElement>(null);
  const inView = useInView(ref, { once: true });

  useEffect(() => {
    if (inView) {
      const node = ref.current;

      const controls = animate(start, value, {
        duration: 2,
        ease: 'linear',
        onUpdate(value) {
          node && (node.textContent = value.toFixed(0));
        },
      });

      return () => controls.stop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, ref, value]);

  return <span ref={ref} />;
}
