import type { FilledIconProps } from '../types';
import { ReactComponent as AlarmCheck } from './alarm-check.svg';
import { ReactComponent as AlarmClockAlt } from './alarm-clock-alt.svg';
import { ReactComponent as AlarmTimer } from './alarm-timer.svg';
import { ReactComponent as BellAdd } from './bell-add.svg';
import { ReactComponent as BellAlt1 } from './bell-alt-1.svg';
import { ReactComponent as BellAlt11 } from './bell-alt-11.svg';
import { ReactComponent as BellAlt } from './bell-alt.svg';
import { ReactComponent as BellMute } from './bell-mute.svg';
import { ReactComponent as BellRemove } from './bell-remove.svg';
import { ReactComponent as BellSleep } from './bell-sleep.svg';
import { ReactComponent as BrowserTimer } from './browser-timer.svg';
import { ReactComponent as CalenderMinus } from './calender-minus.svg';
import { ReactComponent as ClockAlt1 } from './clock-alt-1.svg';
import { ReactComponent as ClockAlt } from './clock-alt.svg';
import { ReactComponent as HalfAnHour } from './half-an-hour.svg';
import { ReactComponent as HalfTime } from './half-time.svg';
import { ReactComponent as History } from './history.svg';
import { ReactComponent as Hour } from './hour.svg';
import { ReactComponent as HourglassAlt } from './hourglass-alt.svg';
import { ReactComponent as LaptopTimer } from './laptop-timer.svg';
import { ReactComponent as NotificationAlt1 } from './notification-alt-1.svg';
import { ReactComponent as Notification } from './notification.svg';
import { ReactComponent as QuarterAlt1 } from './quarter-alt-1.svg';
import { ReactComponent as QuarterAlt } from './quarter-alt.svg';
import { ReactComponent as SecureTime } from './secure-time.svg';
import { ReactComponent as SmartphoneWatchAlt } from './smartphone-watch-alt.svg';
import { ReactComponent as SmartwatchAlt } from './smartwatch-alt.svg';
import { ReactComponent as ThreeQuertersAlt } from './three-querters-alt.svg';
import { ReactComponent as ThreeQuerters } from './three-querters.svg';
import { ReactComponent as TimeFolder } from './time-folder.svg';
import { ReactComponent as TimeSettings1 } from './time-settings-1.svg';
import { ReactComponent as TimeSettings } from './time-settings.svg';
import { ReactComponent as TimeShare } from './time-share.svg';
import { ReactComponent as Time } from './time.svg';
import { ReactComponent as TimerAlt } from './timer-alt.svg';
import { ReactComponent as TimerChat } from './timer-chat.svg';
import { ReactComponent as TimerDesktopAlt } from './timer-desktop-alt.svg';
import { ReactComponent as TimerDesktop } from './timer-desktop.svg';
import { ReactComponent as WristwatchAlt } from './wristwatch-alt.svg';
import { ReactComponent as Wristwatch } from './wristwatch.svg';

const AlarmCheckFilled = (props: FilledIconProps) => (
  <AlarmCheck fill="currentColor" {...props} />
);

const AlarmClockAltFilled = (props: FilledIconProps) => (
  <AlarmClockAlt fill="currentColor" {...props} />
);

const AlarmTimerFilled = (props: FilledIconProps) => (
  <AlarmTimer fill="currentColor" {...props} />
);

const BellAddFilled = (props: FilledIconProps) => (
  <BellAdd fill="currentColor" {...props} />
);

const BellAlt1Filled = (props: FilledIconProps) => (
  <BellAlt1 fill="currentColor" {...props} />
);

const BellAlt11Filled = (props: FilledIconProps) => (
  <BellAlt11 fill="currentColor" {...props} />
);

const BellAltFilled = (props: FilledIconProps) => (
  <BellAlt fill="currentColor" {...props} />
);

const BellMuteFilled = (props: FilledIconProps) => (
  <BellMute fill="currentColor" {...props} />
);

const BellRemoveFilled = (props: FilledIconProps) => (
  <BellRemove fill="currentColor" {...props} />
);

const BellSleepFilled = (props: FilledIconProps) => (
  <BellSleep fill="currentColor" {...props} />
);

const BrowserTimerFilled = (props: FilledIconProps) => (
  <BrowserTimer fill="currentColor" {...props} />
);

const CalenderMinusFilled = (props: FilledIconProps) => (
  <CalenderMinus fill="currentColor" {...props} />
);

const ClockAlt1Filled = (props: FilledIconProps) => (
  <ClockAlt1 fill="currentColor" {...props} />
);

const ClockAltFilled = (props: FilledIconProps) => (
  <ClockAlt fill="currentColor" {...props} />
);

const HalfAnHourFilled = (props: FilledIconProps) => (
  <HalfAnHour fill="currentColor" {...props} />
);

const HalfTimeFilled = (props: FilledIconProps) => (
  <HalfTime fill="currentColor" {...props} />
);

const HistoryFilled = (props: FilledIconProps) => (
  <History fill="currentColor" {...props} />
);

const HourFilled = (props: FilledIconProps) => (
  <Hour fill="currentColor" {...props} />
);

const HourglassAltFilled = (props: FilledIconProps) => (
  <HourglassAlt fill="currentColor" {...props} />
);

const LaptopTimerFilled = (props: FilledIconProps) => (
  <LaptopTimer fill="currentColor" {...props} />
);

const NotificationAlt1Filled = (props: FilledIconProps) => (
  <NotificationAlt1 fill="currentColor" {...props} />
);

const NotificationFilled = (props: FilledIconProps) => (
  <Notification fill="currentColor" {...props} />
);

const QuarterAlt1Filled = (props: FilledIconProps) => (
  <QuarterAlt1 fill="currentColor" {...props} />
);

const QuarterAltFilled = (props: FilledIconProps) => (
  <QuarterAlt fill="currentColor" {...props} />
);

const SecureTimeFilled = (props: FilledIconProps) => (
  <SecureTime fill="currentColor" {...props} />
);

const SmartphoneWatchAltFilled = (props: FilledIconProps) => (
  <SmartphoneWatchAlt fill="currentColor" {...props} />
);

const SmartwatchAltFilled = (props: FilledIconProps) => (
  <SmartwatchAlt fill="currentColor" {...props} />
);

const ThreeQuertersAltFilled = (props: FilledIconProps) => (
  <ThreeQuertersAlt fill="currentColor" {...props} />
);

const ThreeQuertersFilled = (props: FilledIconProps) => (
  <ThreeQuerters fill="currentColor" {...props} />
);

const TimeFolderFilled = (props: FilledIconProps) => (
  <TimeFolder fill="currentColor" {...props} />
);

const TimeSettings1Filled = (props: FilledIconProps) => (
  <TimeSettings1 fill="currentColor" {...props} />
);

const TimeSettingsFilled = (props: FilledIconProps) => (
  <TimeSettings fill="currentColor" {...props} />
);

const TimeShareFilled = (props: FilledIconProps) => (
  <TimeShare fill="currentColor" {...props} />
);

const TimeFilled = (props: FilledIconProps) => (
  <Time fill="currentColor" {...props} />
);

const TimerAltFilled = (props: FilledIconProps) => (
  <TimerAlt fill="currentColor" {...props} />
);

const TimerChatFilled = (props: FilledIconProps) => (
  <TimerChat fill="currentColor" {...props} />
);

const TimerDesktopAltFilled = (props: FilledIconProps) => (
  <TimerDesktopAlt fill="currentColor" {...props} />
);

const TimerDesktopFilled = (props: FilledIconProps) => (
  <TimerDesktop fill="currentColor" {...props} />
);

const WristwatchAltFilled = (props: FilledIconProps) => (
  <WristwatchAlt fill="currentColor" {...props} />
);

const WristwatchFilled = (props: FilledIconProps) => (
  <Wristwatch fill="currentColor" {...props} />
);

export {
  AlarmCheckFilled,
  AlarmClockAltFilled,
  AlarmTimerFilled,
  BellAddFilled,
  BellAlt1Filled,
  BellAlt11Filled,
  BellAltFilled,
  BellMuteFilled,
  BellRemoveFilled,
  BellSleepFilled,
  BrowserTimerFilled,
  CalenderMinusFilled,
  ClockAlt1Filled,
  ClockAltFilled,
  HalfAnHourFilled,
  HalfTimeFilled,
  HistoryFilled,
  HourFilled,
  HourglassAltFilled,
  LaptopTimerFilled,
  NotificationAlt1Filled,
  NotificationFilled,
  QuarterAlt1Filled,
  QuarterAltFilled,
  SecureTimeFilled,
  SmartphoneWatchAltFilled,
  SmartwatchAltFilled,
  ThreeQuertersAltFilled,
  ThreeQuertersFilled,
  TimeFolderFilled,
  TimeSettings1Filled,
  TimeSettingsFilled,
  TimeShareFilled,
  TimeFilled,
  TimerAltFilled,
  TimerChatFilled,
  TimerDesktopAltFilled,
  TimerDesktopFilled,
  WristwatchAltFilled,
  WristwatchFilled,
};
