import type { FilledIconProps } from '../types';
import { ReactComponent as Achievement } from './achievement.svg';
import { ReactComponent as Agenda } from './agenda.svg';
import { ReactComponent as AnalyticsAlt1 } from './analytics-alt-1.svg';
import { ReactComponent as ApartmentAlt } from './apartment-alt.svg';
import { ReactComponent as Apartment } from './apartment.svg';
import { ReactComponent as Balance1 } from './balance-1.svg';
import { ReactComponent as Balance } from './balance.svg';
import { ReactComponent as BriefcaseAlt } from './briefcase-alt.svg';
import { ReactComponent as CartAlt } from './cart-alt.svg';
import { ReactComponent as CartDown } from './cart-down.svg';
import { ReactComponent as CartEmpty } from './cart-empty.svg';
import { ReactComponent as CartFull } from './cart-full.svg';
import { ReactComponent as ChineseYuanRound } from './chinese-yuan-round.svg';
import { ReactComponent as CoinDollarRound } from './coin-dollar-round.svg';
import { ReactComponent as Consulting } from './consulting.svg';
import { ReactComponent as Customer } from './customer.svg';
import { ReactComponent as Delivery } from './delivery.svg';
import { ReactComponent as Desk } from './desk.svg';
import { ReactComponent as DigitalCurrency } from './digital-currency.svg';
import { ReactComponent as EuroCoinRound } from './euro-coin-round.svg';
import { ReactComponent as ExchangeAlt } from './exchange-alt.svg';
import { ReactComponent as Finance } from './finance.svg';
import { ReactComponent as FlowChartAlt } from './flow-chart-alt.svg';
import { ReactComponent as Fuel } from './fuel.svg';
import { ReactComponent as Global } from './global.svg';
import { ReactComponent as Grow } from './grow.svg';
import { ReactComponent as HandshakeAlt1 } from './handshake-alt-1.svg';
import { ReactComponent as HandshakeAlt } from './handshake-alt.svg';
import { ReactComponent as Handshake } from './handshake.svg';
import { ReactComponent as HierarchyAlt1 } from './hierarchy-alt-1.svg';
import { ReactComponent as Hierarchy } from './hierarchy.svg';
import { ReactComponent as InboxFull } from './inbox-full.svg';
import { ReactComponent as InvestMonitor } from './invest-monitor.svg';
import { ReactComponent as Investment } from './investment.svg';
import { ReactComponent as License } from './license.svg';
import { ReactComponent as Megaphone1 } from './megaphone-1.svg';
import { ReactComponent as Megaphone2 } from './megaphone-2.svg';
import { ReactComponent as MegaphoneAlt } from './megaphone-alt.svg';
import { ReactComponent as Notepad } from './notepad.svg';
import { ReactComponent as Offer } from './offer.svg';
import { ReactComponent as OfficePlan } from './office-plan.svg';
import { ReactComponent as Paperclip } from './paperclip.svg';
import { ReactComponent as PieChartAlt } from './pie-chart-alt.svg';
import { ReactComponent as PieChartDollar } from './pie-chart-dollar.svg';
import { ReactComponent as PieChart } from './pie-chart.svg';
import { ReactComponent as PieReport } from './pie-report.svg';
import { ReactComponent as Pin } from './pin.svg';
import { ReactComponent as Quotation } from './quotation.svg';
import { ReactComponent as Revenue } from './revenue.svg';
import { ReactComponent as SalesReport } from './sales-report.svg';
import { ReactComponent as ScaleMachine } from './scale-machine.svg';
import { ReactComponent as SeoConsulting } from './seo-consulting.svg';
import { ReactComponent as Signature } from './signature.svg';
import { ReactComponent as Stamp } from './stamp.svg';
import { ReactComponent as Stapler } from './stapler.svg';
import { ReactComponent as StatsDown } from './stats-down.svg';
import { ReactComponent as StatsUp } from './stats-up.svg';
import { ReactComponent as Swatches } from './swatches.svg';
import { ReactComponent as Table } from './table.svg';
import { ReactComponent as TabletAlt } from './tablet-alt.svg';
import { ReactComponent as Tag } from './tag.svg';
import { ReactComponent as TargetAchievement } from './target-achievement.svg';
import { ReactComponent as TargetAlt1 } from './target-alt-1.svg';
import { ReactComponent as TargetRemove } from './target-remove.svg';
import { ReactComponent as TargetRevenue } from './target-revenue.svg';
import { ReactComponent as Target } from './target.svg';
import { ReactComponent as Tie } from './tie.svg';

const AchievementFilled = (props: FilledIconProps) => (
  <Achievement fill="currentColor" {...props} />
);

const AgendaFilled = (props: FilledIconProps) => (
  <Agenda fill="currentColor" {...props} />
);

const AnalyticsAlt1Filled = (props: FilledIconProps) => (
  <AnalyticsAlt1 fill="currentColor" {...props} />
);

const ApartmentAltFilled = (props: FilledIconProps) => (
  <ApartmentAlt fill="currentColor" {...props} />
);

const ApartmentFilled = (props: FilledIconProps) => (
  <Apartment fill="currentColor" {...props} />
);

const Balance1Filled = (props: FilledIconProps) => (
  <Balance1 fill="currentColor" {...props} />
);

const BalanceFilled = (props: FilledIconProps) => (
  <Balance fill="currentColor" {...props} />
);

const BriefcaseAltFilled = (props: FilledIconProps) => (
  <BriefcaseAlt fill="currentColor" {...props} />
);

const CartAltFilled = (props: FilledIconProps) => (
  <CartAlt fill="currentColor" {...props} />
);

const CartDownFilled = (props: FilledIconProps) => (
  <CartDown fill="currentColor" {...props} />
);

const CartEmptyFilled = (props: FilledIconProps) => (
  <CartEmpty fill="currentColor" {...props} />
);

const CartFullFilled = (props: FilledIconProps) => (
  <CartFull fill="currentColor" {...props} />
);

const ChineseYuanRoundFilled = (props: FilledIconProps) => (
  <ChineseYuanRound fill="currentColor" {...props} />
);

const CoinDollarRoundFilled = (props: FilledIconProps) => (
  <CoinDollarRound fill="currentColor" {...props} />
);

const ConsultingFilled = (props: FilledIconProps) => (
  <Consulting fill="currentColor" {...props} />
);

const CustomerFilled = (props: FilledIconProps) => (
  <Customer fill="currentColor" {...props} />
);

const DeliveryFilled = (props: FilledIconProps) => (
  <Delivery fill="currentColor" {...props} />
);

const DeskFilled = (props: FilledIconProps) => (
  <Desk fill="currentColor" {...props} />
);

const DigitalCurrencyFilled = (props: FilledIconProps) => (
  <DigitalCurrency fill="currentColor" {...props} />
);

const EuroCoinRoundFilled = (props: FilledIconProps) => (
  <EuroCoinRound fill="currentColor" {...props} />
);

const ExchangeAltFilled = (props: FilledIconProps) => (
  <ExchangeAlt fill="currentColor" {...props} />
);

const FinanceFilled = (props: FilledIconProps) => (
  <Finance fill="currentColor" {...props} />
);

const FlowChartAltFilled = (props: FilledIconProps) => (
  <FlowChartAlt fill="currentColor" {...props} />
);

const FuelFilled = (props: FilledIconProps) => (
  <Fuel fill="currentColor" {...props} />
);

const GlobalFilled = (props: FilledIconProps) => (
  <Global fill="currentColor" {...props} />
);

const GrowFilled = (props: FilledIconProps) => (
  <Grow fill="currentColor" {...props} />
);

const HandshakeAlt1Filled = (props: FilledIconProps) => (
  <HandshakeAlt1 fill="currentColor" {...props} />
);

const HandshakeAltFilled = (props: FilledIconProps) => (
  <HandshakeAlt fill="currentColor" {...props} />
);

const HandshakeFilled = (props: FilledIconProps) => (
  <Handshake fill="currentColor" {...props} />
);

const HierarchyAlt1Filled = (props: FilledIconProps) => (
  <HierarchyAlt1 fill="currentColor" {...props} />
);

const HierarchyFilled = (props: FilledIconProps) => (
  <Hierarchy fill="currentColor" {...props} />
);

const InboxFullFilled = (props: FilledIconProps) => (
  <InboxFull fill="currentColor" {...props} />
);

const InvestMonitorFilled = (props: FilledIconProps) => (
  <InvestMonitor fill="currentColor" {...props} />
);

const InvestmentFilled = (props: FilledIconProps) => (
  <Investment fill="currentColor" {...props} />
);

const LicenseFilled = (props: FilledIconProps) => (
  <License fill="currentColor" {...props} />
);

const Megaphone1Filled = (props: FilledIconProps) => (
  <Megaphone1 fill="currentColor" {...props} />
);

const Megaphone2Filled = (props: FilledIconProps) => (
  <Megaphone2 fill="currentColor" {...props} />
);

const MegaphoneAltFilled = (props: FilledIconProps) => (
  <MegaphoneAlt fill="currentColor" {...props} />
);

const NotepadFilled = (props: FilledIconProps) => (
  <Notepad fill="currentColor" {...props} />
);

const OfferFilled = (props: FilledIconProps) => (
  <Offer fill="currentColor" {...props} />
);

const OfficePlanFilled = (props: FilledIconProps) => (
  <OfficePlan fill="currentColor" {...props} />
);

const PaperclipFilled = (props: FilledIconProps) => (
  <Paperclip fill="currentColor" {...props} />
);

const PieChartAltFilled = (props: FilledIconProps) => (
  <PieChartAlt fill="currentColor" {...props} />
);

const PieChartDollarFilled = (props: FilledIconProps) => (
  <PieChartDollar fill="currentColor" {...props} />
);

const PieChartFilled = (props: FilledIconProps) => (
  <PieChart fill="currentColor" {...props} />
);

const PieReportFilled = (props: FilledIconProps) => (
  <PieReport fill="currentColor" {...props} />
);

const PinFilled = (props: FilledIconProps) => (
  <Pin fill="currentColor" {...props} />
);

const QuotationFilled = (props: FilledIconProps) => (
  <Quotation fill="currentColor" {...props} />
);

const RevenueFilled = (props: FilledIconProps) => (
  <Revenue fill="currentColor" {...props} />
);

const SalesReportFilled = (props: FilledIconProps) => (
  <SalesReport fill="currentColor" {...props} />
);

const ScaleMachineFilled = (props: FilledIconProps) => (
  <ScaleMachine fill="currentColor" {...props} />
);

const SeoConsultingFilled = (props: FilledIconProps) => (
  <SeoConsulting fill="currentColor" {...props} />
);

const SignatureFilled = (props: FilledIconProps) => (
  <Signature fill="currentColor" {...props} />
);

const StampFilled = (props: FilledIconProps) => (
  <Stamp fill="currentColor" {...props} />
);

const StaplerFilled = (props: FilledIconProps) => (
  <Stapler fill="currentColor" {...props} />
);

const StatsDownFilled = (props: FilledIconProps) => (
  <StatsDown fill="currentColor" {...props} />
);

const StatsUpFilled = (props: FilledIconProps) => (
  <StatsUp fill="currentColor" {...props} />
);

const SwatchesFilled = (props: FilledIconProps) => (
  <Swatches fill="currentColor" {...props} />
);

const TableFilled = (props: FilledIconProps) => (
  <Table fill="currentColor" {...props} />
);

const TabletAltFilled = (props: FilledIconProps) => (
  <TabletAlt fill="currentColor" {...props} />
);

const TagFilled = (props: FilledIconProps) => (
  <Tag fill="currentColor" {...props} />
);

const TargetAchievementFilled = (props: FilledIconProps) => (
  <TargetAchievement fill="currentColor" {...props} />
);

const TargetAlt1Filled = (props: FilledIconProps) => (
  <TargetAlt1 fill="currentColor" {...props} />
);

const TargetRemoveFilled = (props: FilledIconProps) => (
  <TargetRemove fill="currentColor" {...props} />
);

const TargetRevenueFilled = (props: FilledIconProps) => (
  <TargetRevenue fill="currentColor" {...props} />
);

const TargetFilled = (props: FilledIconProps) => (
  <Target fill="currentColor" {...props} />
);

const TieFilled = (props: FilledIconProps) => (
  <Tie fill="currentColor" {...props} />
);

export {
  AchievementFilled,
  AgendaFilled,
  AnalyticsAlt1Filled,
  ApartmentAltFilled,
  ApartmentFilled,
  Balance1Filled,
  BalanceFilled,
  BriefcaseAltFilled,
  CartAltFilled,
  CartDownFilled,
  CartEmptyFilled,
  CartFullFilled,
  ChineseYuanRoundFilled,
  CoinDollarRoundFilled,
  ConsultingFilled,
  CustomerFilled,
  DeliveryFilled,
  DeskFilled,
  DigitalCurrencyFilled,
  EuroCoinRoundFilled,
  ExchangeAltFilled,
  FinanceFilled,
  FlowChartAltFilled,
  FuelFilled,
  GlobalFilled,
  GrowFilled,
  HandshakeAlt1Filled,
  HandshakeAltFilled,
  HandshakeFilled,
  HierarchyAlt1Filled,
  HierarchyFilled,
  InboxFullFilled,
  InvestMonitorFilled,
  InvestmentFilled,
  LicenseFilled,
  Megaphone1Filled,
  Megaphone2Filled,
  MegaphoneAltFilled,
  NotepadFilled,
  OfferFilled,
  OfficePlanFilled,
  PaperclipFilled,
  PieChartAltFilled,
  PieChartDollarFilled,
  PieChartFilled,
  PieReportFilled,
  PinFilled,
  QuotationFilled,
  RevenueFilled,
  SalesReportFilled,
  ScaleMachineFilled,
  SeoConsultingFilled,
  SignatureFilled,
  StampFilled,
  StaplerFilled,
  StatsDownFilled,
  StatsUpFilled,
  SwatchesFilled,
  TableFilled,
  TabletAltFilled,
  TagFilled,
  TargetAchievementFilled,
  TargetAlt1Filled,
  TargetRemoveFilled,
  TargetRevenueFilled,
  TargetFilled,
  TieFilled,
};
