import { m } from 'framer-motion';

export function Performance() {
  return (
    <m.img
      className="w-full max-w-2xl"
      initial={{ y: 10, opacity: 0 }}
      whileInView={{ y: 0, opacity: 100 }}
      viewport={{ once: true, amount: 0.25 }}
      transition={{ duration: 0.5, delay: 0.5 }}
      src="/graphics/performance.webp"
    />
  );
}
