import type { FilledIconProps } from '../types';
import { ReactComponent as AddFile } from './add-file.svg';
import { ReactComponent as Barrier } from './barrier.svg';
import { ReactComponent as BoltAlt } from './bolt-alt.svg';
import { ReactComponent as Bolt } from './bolt.svg';
import { ReactComponent as BookmarkAlt1 } from './bookmark-alt-1.svg';
import { ReactComponent as CheckmarkCircle } from './checkmark-circle.svg';
import { ReactComponent as Checkmark } from './checkmark.svg';
import { ReactComponent as CircleMinus } from './circle-minus.svg';
import { ReactComponent as CirclePlus } from './circle-plus.svg';
import { ReactComponent as Circle } from './circle.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Command } from './command.svg';
import { ReactComponent as Copyright } from './copyright.svg';
import { ReactComponent as CrossCircle } from './cross-circle.svg';
import { ReactComponent as Electricity } from './electricity.svg';
import { ReactComponent as EyeAlt1 } from './eye-alt-1.svg';
import { ReactComponent as EyeAlt } from './eye-alt.svg';
import { ReactComponent as Filterslider } from './filterslider.svg';
import { ReactComponent as Find } from './find.svg';
import { ReactComponent as Fingerprint1 } from './fingerprint-1.svg';
import { ReactComponent as Fingerprint3 } from './fingerprint-3.svg';
import { ReactComponent as FlagAlt } from './flag-alt.svg';
import { ReactComponent as Flag } from './flag.svg';
import { ReactComponent as FunnelAlt1 } from './funnel-alt-1.svg';
import { ReactComponent as FunnelAlt2 } from './funnel-alt-2.svg';
import { ReactComponent as FunnelAlt } from './funnel-alt.svg';
import { ReactComponent as Funnel } from './funnel.svg';
import { ReactComponent as Hand } from './hand.svg';
import { ReactComponent as Hashtag } from './hashtag.svg';
import { ReactComponent as Heart } from './heart.svg';
import { ReactComponent as Help } from './help.svg';
import { ReactComponent as HotelSign } from './hotel-sign.svg';
import { ReactComponent as IdeaAlt } from './idea-alt.svg';
import { ReactComponent as Identity } from './identity.svg';
import { ReactComponent as Infinite } from './infinite.svg';
import { ReactComponent as InfoCircle } from './info-circle.svg';
import { ReactComponent as Information } from './information.svg';
import { ReactComponent as Invention } from './invention.svg';
import { ReactComponent as KeyAlt1 } from './key-alt-1.svg';
import { ReactComponent as KeyAlt2 } from './key-alt-2.svg';
import { ReactComponent as KeyAlt3 } from './key-alt-3.svg';
import { ReactComponent as KeyAlt } from './key-alt.svg';
import { ReactComponent as Key } from './key.svg';
import { ReactComponent as LayersAlt3 } from './layers-alt-3.svg';
import { ReactComponent as Lifering } from './lifering.svg';
import { ReactComponent as ListAlt1 } from './list-alt-1.svg';
import { ReactComponent as ListAlt } from './list-alt.svg';
import { ReactComponent as List } from './list.svg';
import { ReactComponent as LockAlt1 } from './lock-alt-1.svg';
import { ReactComponent as LockAlt2 } from './lock-alt-2.svg';
import { ReactComponent as LockAlt3 } from './lock-alt-3.svg';
import { ReactComponent as LockAlt } from './lock-alt.svg';
import { ReactComponent as Lock } from './lock.svg';
import { ReactComponent as Minus } from './minus.svg';
import { ReactComponent as MoreAlt1 } from './more-alt-1.svg';
import { ReactComponent as More } from './more.svg';
import { ReactComponent as MusicNote } from './music-note.svg';
import { ReactComponent as Music } from './music.svg';
import { ReactComponent as NotificationAlt } from './notification-alt.svg';
import { ReactComponent as Passport2 } from './passport-2.svg';
import { ReactComponent as Passport3 } from './passport-3.svg';
import { ReactComponent as Passport4 } from './passport-4.svg';
import { ReactComponent as PassportAlt } from './passport-alt.svg';
import { ReactComponent as Passport } from './passport.svg';
import { ReactComponent as PlaylistAlt } from './playlist-alt.svg';
import { ReactComponent as Playlist } from './playlist.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as Pointer } from './pointer.svg';
import { ReactComponent as Portfolio } from './portfolio.svg';
import { ReactComponent as Portfolio2 } from './portfolio2.svg';
import { ReactComponent as PowerSwitch } from './power-switch.svg';
import { ReactComponent as Protection } from './protection.svg';
import { ReactComponent as QuestionCircle } from './question-circle.svg';
import { ReactComponent as RadioButton } from './radio-button.svg';
import { ReactComponent as Record } from './record.svg';
import { ReactComponent as RoundBoxCheck } from './round-box-check.svg';
import { ReactComponent as RoundBoxCross } from './round-box-cross.svg';
import { ReactComponent as RoundBoxMinus } from './round-box-minus.svg';
import { ReactComponent as RoundBoxPlus } from './round-box-plus.svg';
import { ReactComponent as SearchLocation } from './search-location.svg';
import { ReactComponent as ShieldAlt1Check } from './shield-alt-1-check.svg';
import { ReactComponent as ShieldAlt1a } from './shield-alt-1.svg';
import { ReactComponent as ShieldAlt21 } from './shield-alt-2-1.svg';
import { ReactComponent as ShieldAlt2Check1 } from './shield-alt-2-check-1.svg';
import { ReactComponent as ShieldAlt2Check } from './shield-alt-2-check.svg';
import { ReactComponent as ShieldAlt2 } from './shield-alt-2.svg';
import { ReactComponent as ShieldAlt3 } from './shield-alt-3.svg';
import { ReactComponent as Shield } from './shield.svg';
import { ReactComponent as SignLanguageAlt } from './sign-language-alt.svg';
import { ReactComponent as SignLanguage } from './sign-language.svg';
import { ReactComponent as Sort } from './sort.svg';
import { ReactComponent as Switch } from './switch.svg';
import { ReactComponent as TargetAlt3 } from './target-alt-3.svg';
import { ReactComponent as Teabag } from './teabag.svg';
import { ReactComponent as ThumbsDownAlt } from './thumbs-down-alt.svg';
import { ReactComponent as ThumbsDown } from './thumbs-down.svg';
import { ReactComponent as ThumbsUpAlt } from './thumbs-up-alt.svg';
import { ReactComponent as ThumbsUp } from './thumbs-up.svg';
import { ReactComponent as Thunderbolt } from './thunderbolt.svg';
import { ReactComponent as TicketAlt1 } from './ticket-alt-1.svg';
import { ReactComponent as TicketAlt2 } from './ticket-alt-2.svg';
import { ReactComponent as TicketAlt3 } from './ticket-alt-3.svg';
import { ReactComponent as TicketAlt4 } from './ticket-alt-4.svg';
import { ReactComponent as TicketAlt5 } from './ticket-alt-5.svg';
import { ReactComponent as TicketAlt } from './ticket-alt.svg';
import { ReactComponent as Ticket } from './ticket.svg';
import { ReactComponent as Trademark } from './trademark.svg';
import { ReactComponent as Unlock } from './unlock.svg';
import { ReactComponent as VideoCall2 } from './video-call-2.svg';
import { ReactComponent as VideoCall } from './video-call.svg';
import { ReactComponent as Vote } from './vote.svg';
import { ReactComponent as Warning } from './warning.svg';

const AddFileFilled = (props: FilledIconProps) => (
  <AddFile fill="currentColor" {...props} />
);

const BarrierFilled = (props: FilledIconProps) => (
  <Barrier fill="currentColor" {...props} />
);

const BoltAltFilled = (props: FilledIconProps) => (
  <BoltAlt fill="currentColor" {...props} />
);

const BoltFilled = (props: FilledIconProps) => (
  <Bolt fill="currentColor" {...props} />
);

const BookmarkAlt1Filled = (props: FilledIconProps) => (
  <BookmarkAlt1 fill="currentColor" {...props} />
);

const CheckmarkCircleFilled = (props: FilledIconProps) => (
  <CheckmarkCircle fill="currentColor" {...props} />
);

const CheckmarkFilled = (props: FilledIconProps) => (
  <Checkmark fill="currentColor" {...props} />
);

const CircleMinusFilled = (props: FilledIconProps) => (
  <CircleMinus fill="currentColor" {...props} />
);

const CirclePlusFilled = (props: FilledIconProps) => (
  <CirclePlus fill="currentColor" {...props} />
);

const CircleFilled = (props: FilledIconProps) => (
  <Circle fill="currentColor" {...props} />
);

const CloseFilled = (props: FilledIconProps) => (
  <Close fill="currentColor" {...props} />
);

const CommandFilled = (props: FilledIconProps) => (
  <Command fill="currentColor" {...props} />
);

const CopyrightFilled = (props: FilledIconProps) => (
  <Copyright fill="currentColor" {...props} />
);

const CrossCircleFilled = (props: FilledIconProps) => (
  <CrossCircle fill="currentColor" {...props} />
);

const ElectricityFilled = (props: FilledIconProps) => (
  <Electricity fill="currentColor" {...props} />
);

const EyeAlt1Filled = (props: FilledIconProps) => (
  <EyeAlt1 fill="currentColor" {...props} />
);

const EyeAltFilled = (props: FilledIconProps) => (
  <EyeAlt fill="currentColor" {...props} />
);

const FiltersliderFilled = (props: FilledIconProps) => (
  <Filterslider fill="currentColor" {...props} />
);

const FindFilled = (props: FilledIconProps) => (
  <Find fill="currentColor" {...props} />
);

const Fingerprint1Filled = (props: FilledIconProps) => (
  <Fingerprint1 fill="currentColor" {...props} />
);

const Fingerprint3Filled = (props: FilledIconProps) => (
  <Fingerprint3 fill="currentColor" {...props} />
);

const FlagAltFilled = (props: FilledIconProps) => (
  <FlagAlt fill="currentColor" {...props} />
);

const FlagFilled = (props: FilledIconProps) => (
  <Flag fill="currentColor" {...props} />
);

const FunnelAlt1Filled = (props: FilledIconProps) => (
  <FunnelAlt1 fill="currentColor" {...props} />
);

const FunnelAlt2Filled = (props: FilledIconProps) => (
  <FunnelAlt2 fill="currentColor" {...props} />
);

const FunnelAltFilled = (props: FilledIconProps) => (
  <FunnelAlt fill="currentColor" {...props} />
);

const FunnelFilled = (props: FilledIconProps) => (
  <Funnel fill="currentColor" {...props} />
);

const HandFilled = (props: FilledIconProps) => (
  <Hand fill="currentColor" {...props} />
);

const HashtagFilled = (props: FilledIconProps) => (
  <Hashtag fill="currentColor" {...props} />
);

const HeartFilled = (props: FilledIconProps) => (
  <Heart fill="currentColor" {...props} />
);

const HelpFilled = (props: FilledIconProps) => (
  <Help fill="currentColor" {...props} />
);

const HotelSignFilled = (props: FilledIconProps) => (
  <HotelSign fill="currentColor" {...props} />
);

const IdeaAltFilled = (props: FilledIconProps) => (
  <IdeaAlt fill="currentColor" {...props} />
);

const IdentityFilled = (props: FilledIconProps) => (
  <Identity fill="currentColor" {...props} />
);

const InfiniteFilled = (props: FilledIconProps) => (
  <Infinite fill="currentColor" {...props} />
);

const InfoCircleFilled = (props: FilledIconProps) => (
  <InfoCircle fill="currentColor" {...props} />
);

const InformationFilled = (props: FilledIconProps) => (
  <Information fill="currentColor" {...props} />
);

const InventionFilled = (props: FilledIconProps) => (
  <Invention fill="currentColor" {...props} />
);

const KeyAlt1Filled = (props: FilledIconProps) => (
  <KeyAlt1 fill="currentColor" {...props} />
);

const KeyAlt2Filled = (props: FilledIconProps) => (
  <KeyAlt2 fill="currentColor" {...props} />
);

const KeyAlt3Filled = (props: FilledIconProps) => (
  <KeyAlt3 fill="currentColor" {...props} />
);

const KeyAltFilled = (props: FilledIconProps) => (
  <KeyAlt fill="currentColor" {...props} />
);

const KeyFilled = (props: FilledIconProps) => (
  <Key fill="currentColor" {...props} />
);

const LayersAlt3Filled = (props: FilledIconProps) => (
  <LayersAlt3 fill="currentColor" {...props} />
);

const LiferingFilled = (props: FilledIconProps) => (
  <Lifering fill="currentColor" {...props} />
);

const ListAlt1Filled = (props: FilledIconProps) => (
  <ListAlt1 fill="currentColor" {...props} />
);

const ListAltFilled = (props: FilledIconProps) => (
  <ListAlt fill="currentColor" {...props} />
);

const ListFilled = (props: FilledIconProps) => (
  <List fill="currentColor" {...props} />
);

const LockAlt1Filled = (props: FilledIconProps) => (
  <LockAlt1 fill="currentColor" {...props} />
);

const LockAlt2Filled = (props: FilledIconProps) => (
  <LockAlt2 fill="currentColor" {...props} />
);

const LockAlt3Filled = (props: FilledIconProps) => (
  <LockAlt3 fill="currentColor" {...props} />
);

const LockAltFilled = (props: FilledIconProps) => (
  <LockAlt fill="currentColor" {...props} />
);

const LockFilled = (props: FilledIconProps) => (
  <Lock fill="currentColor" {...props} />
);

const MinusFilled = (props: FilledIconProps) => (
  <Minus fill="currentColor" {...props} />
);

const MoreAlt1Filled = (props: FilledIconProps) => (
  <MoreAlt1 fill="currentColor" {...props} />
);

const MoreFilled = (props: FilledIconProps) => (
  <More fill="currentColor" {...props} />
);

const MusicNoteFilled = (props: FilledIconProps) => (
  <MusicNote fill="currentColor" {...props} />
);

const MusicFilled = (props: FilledIconProps) => (
  <Music fill="currentColor" {...props} />
);

const NotificationAltFilled = (props: FilledIconProps) => (
  <NotificationAlt fill="currentColor" {...props} />
);

const Passport2Filled = (props: FilledIconProps) => (
  <Passport2 fill="currentColor" {...props} />
);

const Passport3Filled = (props: FilledIconProps) => (
  <Passport3 fill="currentColor" {...props} />
);

const Passport4Filled = (props: FilledIconProps) => (
  <Passport4 fill="currentColor" {...props} />
);

const PassportAltFilled = (props: FilledIconProps) => (
  <PassportAlt fill="currentColor" {...props} />
);

const PassportFilled = (props: FilledIconProps) => (
  <Passport fill="currentColor" {...props} />
);

const PlaylistAltFilled = (props: FilledIconProps) => (
  <PlaylistAlt fill="currentColor" {...props} />
);

const PlaylistFilled = (props: FilledIconProps) => (
  <Playlist fill="currentColor" {...props} />
);

const PlusFilled = (props: FilledIconProps) => (
  <Plus fill="currentColor" {...props} />
);

const PointerFilled = (props: FilledIconProps) => (
  <Pointer fill="currentColor" {...props} />
);

const PortfolioFilled = (props: FilledIconProps) => (
  <Portfolio fill="currentColor" {...props} />
);

const Portfolio2Filled = (props: FilledIconProps) => (
  <Portfolio2 fill="currentColor" {...props} />
);

const PowerSwitchFilled = (props: FilledIconProps) => (
  <PowerSwitch fill="currentColor" {...props} />
);

const ProtectionFilled = (props: FilledIconProps) => (
  <Protection fill="currentColor" {...props} />
);

const QuestionCircleFilled = (props: FilledIconProps) => (
  <QuestionCircle fill="currentColor" {...props} />
);

const RadioButtonFilled = (props: FilledIconProps) => (
  <RadioButton fill="currentColor" {...props} />
);

const RecordFilled = (props: FilledIconProps) => (
  <Record fill="currentColor" {...props} />
);

const RoundBoxCheckFilled = (props: FilledIconProps) => (
  <RoundBoxCheck fill="currentColor" {...props} />
);

const RoundBoxCrossFilled = (props: FilledIconProps) => (
  <RoundBoxCross fill="currentColor" {...props} />
);

const RoundBoxMinusFilled = (props: FilledIconProps) => (
  <RoundBoxMinus fill="currentColor" {...props} />
);

const RoundBoxPlusFilled = (props: FilledIconProps) => (
  <RoundBoxPlus fill="currentColor" {...props} />
);

const SearchLocationFilled = (props: FilledIconProps) => (
  <SearchLocation fill="currentColor" {...props} />
);

const ShieldAlt1CheckFilled = (props: FilledIconProps) => (
  <ShieldAlt1Check fill="currentColor" {...props} />
);

const ShieldAlt1aFilled = (props: FilledIconProps) => (
  <ShieldAlt1a fill="currentColor" {...props} />
);

const ShieldAlt21Filled = (props: FilledIconProps) => (
  <ShieldAlt21 fill="currentColor" {...props} />
);

const ShieldAlt2Check1Filled = (props: FilledIconProps) => (
  <ShieldAlt2Check1 fill="currentColor" {...props} />
);

const ShieldAlt2CheckFilled = (props: FilledIconProps) => (
  <ShieldAlt2Check fill="currentColor" {...props} />
);

const ShieldAlt2Filled = (props: FilledIconProps) => (
  <ShieldAlt2 fill="currentColor" {...props} />
);

const ShieldAlt3Filled = (props: FilledIconProps) => (
  <ShieldAlt3 fill="currentColor" {...props} />
);

const ShieldFilled = (props: FilledIconProps) => (
  <Shield fill="currentColor" {...props} />
);

const SignLanguageAltFilled = (props: FilledIconProps) => (
  <SignLanguageAlt fill="currentColor" {...props} />
);

const SignLanguageFilled = (props: FilledIconProps) => (
  <SignLanguage fill="currentColor" {...props} />
);

const SortFilled = (props: FilledIconProps) => (
  <Sort fill="currentColor" {...props} />
);

const SwitchFilled = (props: FilledIconProps) => (
  <Switch fill="currentColor" {...props} />
);

const TargetAlt3Filled = (props: FilledIconProps) => (
  <TargetAlt3 fill="currentColor" {...props} />
);

const TeabagFilled = (props: FilledIconProps) => (
  <Teabag fill="currentColor" {...props} />
);

const ThumbsDownAltFilled = (props: FilledIconProps) => (
  <ThumbsDownAlt fill="currentColor" {...props} />
);

const ThumbsDownFilled = (props: FilledIconProps) => (
  <ThumbsDown fill="currentColor" {...props} />
);

const ThumbsUpAltFilled = (props: FilledIconProps) => (
  <ThumbsUpAlt fill="currentColor" {...props} />
);

const ThumbsUpFilled = (props: FilledIconProps) => (
  <ThumbsUp fill="currentColor" {...props} />
);

const ThunderboltFilled = (props: FilledIconProps) => (
  <Thunderbolt fill="currentColor" {...props} />
);

const TicketAlt1Filled = (props: FilledIconProps) => (
  <TicketAlt1 fill="currentColor" {...props} />
);

const TicketAlt2Filled = (props: FilledIconProps) => (
  <TicketAlt2 fill="currentColor" {...props} />
);

const TicketAlt3Filled = (props: FilledIconProps) => (
  <TicketAlt3 fill="currentColor" {...props} />
);

const TicketAlt4Filled = (props: FilledIconProps) => (
  <TicketAlt4 fill="currentColor" {...props} />
);

const TicketAlt5Filled = (props: FilledIconProps) => (
  <TicketAlt5 fill="currentColor" {...props} />
);

const TicketAltFilled = (props: FilledIconProps) => (
  <TicketAlt fill="currentColor" {...props} />
);

const TicketFilled = (props: FilledIconProps) => (
  <Ticket fill="currentColor" {...props} />
);

const TrademarkFilled = (props: FilledIconProps) => (
  <Trademark fill="currentColor" {...props} />
);

const UnlockFilled = (props: FilledIconProps) => (
  <Unlock fill="currentColor" {...props} />
);

const VideoCall2Filled = (props: FilledIconProps) => (
  <VideoCall2 fill="currentColor" {...props} />
);

const VideoCallFilled = (props: FilledIconProps) => (
  <VideoCall fill="currentColor" {...props} />
);

const VoteFilled = (props: FilledIconProps) => (
  <Vote fill="currentColor" {...props} />
);

const WarningFilled = (props: FilledIconProps) => (
  <Warning fill="currentColor" {...props} />
);

export {
  AddFileFilled,
  BarrierFilled,
  BoltAltFilled,
  BoltFilled,
  BookmarkAlt1Filled,
  CheckmarkCircleFilled,
  CheckmarkFilled,
  CircleMinusFilled,
  CirclePlusFilled,
  CircleFilled,
  CloseFilled,
  CommandFilled,
  CopyrightFilled,
  CrossCircleFilled,
  ElectricityFilled,
  EyeAlt1Filled,
  EyeAltFilled,
  FiltersliderFilled,
  FindFilled,
  Fingerprint1Filled,
  Fingerprint3Filled,
  FlagAltFilled,
  FlagFilled,
  FunnelAlt1Filled,
  FunnelAlt2Filled,
  FunnelAltFilled,
  FunnelFilled,
  HandFilled,
  HashtagFilled,
  HeartFilled,
  HelpFilled,
  HotelSignFilled,
  IdeaAltFilled,
  IdentityFilled,
  InfiniteFilled,
  InfoCircleFilled,
  InformationFilled,
  InventionFilled,
  KeyAlt1Filled,
  KeyAlt2Filled,
  KeyAlt3Filled,
  KeyAltFilled,
  KeyFilled,
  LayersAlt3Filled,
  LiferingFilled,
  ListAlt1Filled,
  ListAltFilled,
  ListFilled,
  LockAlt1Filled,
  LockAlt2Filled,
  LockAlt3Filled,
  LockAltFilled,
  LockFilled,
  MinusFilled,
  MoreAlt1Filled,
  MoreFilled,
  MusicNoteFilled,
  MusicFilled,
  NotificationAltFilled,
  Passport2Filled,
  Passport3Filled,
  Passport4Filled,
  PassportAltFilled,
  PassportFilled,
  PlaylistAltFilled,
  PlaylistFilled,
  PlusFilled,
  PointerFilled,
  PortfolioFilled,
  Portfolio2Filled,
  PowerSwitchFilled,
  ProtectionFilled,
  QuestionCircleFilled,
  RadioButtonFilled,
  RecordFilled,
  RoundBoxCheckFilled,
  RoundBoxCrossFilled,
  RoundBoxMinusFilled,
  RoundBoxPlusFilled,
  SearchLocationFilled,
  ShieldAlt1CheckFilled,
  ShieldAlt1aFilled,
  ShieldAlt21Filled,
  ShieldAlt2Check1Filled,
  ShieldAlt2CheckFilled,
  ShieldAlt2Filled,
  ShieldAlt3Filled,
  ShieldFilled,
  SignLanguageAltFilled,
  SignLanguageFilled,
  SortFilled,
  SwitchFilled,
  TargetAlt3Filled,
  TeabagFilled,
  ThumbsDownAltFilled,
  ThumbsDownFilled,
  ThumbsUpAltFilled,
  ThumbsUpFilled,
  ThunderboltFilled,
  TicketAlt1Filled,
  TicketAlt2Filled,
  TicketAlt3Filled,
  TicketAlt4Filled,
  TicketAlt5Filled,
  TicketAltFilled,
  TicketFilled,
  TrademarkFilled,
  UnlockFilled,
  VideoCall2Filled,
  VideoCallFilled,
  VoteFilled,
  WarningFilled,
};
