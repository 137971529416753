import { Button } from '../../ui/Button';
import { Typography } from '../../ui/Typography';

export function CTA(props: { showRequestDemoModal: () => void }) {
  return (
    <div className="flex flex-col items-center pt-4 pb-24 gap-6">
     <Typography variant="headline" size="lg">
       Ready to get started?
     </Typography>
     <Button onClick={props.showRequestDemoModal}>Schedule a demo</Button>
    </div>
  );
}
