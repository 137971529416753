import type { FilledIconProps } from '../types';
import { ReactComponent as AnalyticsChat } from './analytics-chat.svg';
import { ReactComponent as Analytics } from './analytics.svg';
import { ReactComponent as BarChart1 } from './bar-chart-1.svg';
import { ReactComponent as BarChart } from './bar-chart.svg';
import { ReactComponent as BarCheck } from './bar-check.svg';
import { ReactComponent as BillboardAlt } from './billboard-alt.svg';
import { ReactComponent as Billboard } from './billboard.svg';
import { ReactComponent as EnvelopeOpenText } from './envelope-open-text.svg';
import { ReactComponent as GiftCard } from './gift-card.svg';
import { ReactComponent as GraphAlt1 } from './graph-alt-1.svg';
import { ReactComponent as GraphAlt2 } from './graph-alt-2.svg';
import { ReactComponent as GraphAlt3 } from './graph-alt-3.svg';
import { ReactComponent as GraphAlt4 } from './graph-alt-4.svg';
import { ReactComponent as GraphAlt } from './graph-alt.svg';
import { ReactComponent as GraphDecrease } from './graph-decrease.svg';
import { ReactComponent as GraphIncrease } from './graph-increase.svg';
import { ReactComponent as Graph } from './graph.svg';
import { ReactComponent as HandChart } from './hand-chart.svg';
import { ReactComponent as KeywordResearch } from './keyword-research.svg';
import { ReactComponent as LightbulbExclamation } from './lightbulb-exclamation.svg';
import { ReactComponent as MagnifyingGlassDollar } from './magnifying-glass-dollar.svg';
import { ReactComponent as NetworkAlt } from './network-alt.svg';
import { ReactComponent as Network } from './network.svg';
import { ReactComponent as PieChartAlt1 } from './pie-chart-alt-1.svg';
import { ReactComponent as PyramidDecrease } from './pyramid-decrease.svg';
import { ReactComponent as PyramidIncrease } from './pyramid-increase.svg';
import { ReactComponent as Pyramid } from './pyramid.svg';
import { ReactComponent as Rook } from './rook.svg';
import { ReactComponent as SeoAlt } from './seo-alt.svg';
import { ReactComponent as Seo } from './seo.svg';
import { ReactComponent as SquarePollHorizontal } from './square-poll-horizontal.svg';
import { ReactComponent as SquarePollVertical } from './square-poll-vertical.svg';
import { ReactComponent as StatisicAlt } from './statisic-alt.svg';
import { ReactComponent as Strategy } from './strategy.svg';
import { ReactComponent as TargetAudienceAlt } from './target-audience-alt.svg';
import { ReactComponent as TargetCustomer } from './target-customer.svg';
import { ReactComponent as Timeline } from './timeline.svg';
import { ReactComponent as WebsiteRank } from './website-rank.svg';
import { ReactComponent as WebsiteSeo } from './website-seo.svg';

const AnalyticsChatFilled = (props: FilledIconProps) => (
  <AnalyticsChat fill="currentColor" {...props} />
);

const AnalyticsFilled = (props: FilledIconProps) => (
  <Analytics fill="currentColor" {...props} />
);

const BarChart1Filled = (props: FilledIconProps) => (
  <BarChart1 fill="currentColor" {...props} />
);

const BarChartFilled = (props: FilledIconProps) => (
  <BarChart fill="currentColor" {...props} />
);

const BarCheckFilled = (props: FilledIconProps) => (
  <BarCheck fill="currentColor" {...props} />
);

const BillboardAltFilled = (props: FilledIconProps) => (
  <BillboardAlt fill="currentColor" {...props} />
);

const BillboardFilled = (props: FilledIconProps) => (
  <Billboard fill="currentColor" {...props} />
);

const EnvelopeOpenTextFilled = (props: FilledIconProps) => (
  <EnvelopeOpenText fill="currentColor" {...props} />
);

const GiftCardFilled = (props: FilledIconProps) => (
  <GiftCard fill="currentColor" {...props} />
);

const GraphAlt1Filled = (props: FilledIconProps) => (
  <GraphAlt1 fill="currentColor" {...props} />
);

const GraphAlt2Filled = (props: FilledIconProps) => (
  <GraphAlt2 fill="currentColor" {...props} />
);

const GraphAlt3Filled = (props: FilledIconProps) => (
  <GraphAlt3 fill="currentColor" {...props} />
);

const GraphAlt4Filled = (props: FilledIconProps) => (
  <GraphAlt4 fill="currentColor" {...props} />
);

const GraphAltFilled = (props: FilledIconProps) => (
  <GraphAlt fill="currentColor" {...props} />
);

const GraphDecreaseFilled = (props: FilledIconProps) => (
  <GraphDecrease fill="currentColor" {...props} />
);

const GraphIncreaseFilled = (props: FilledIconProps) => (
  <GraphIncrease fill="currentColor" {...props} />
);

const GraphFilled = (props: FilledIconProps) => (
  <Graph fill="currentColor" {...props} />
);

const HandChartFilled = (props: FilledIconProps) => (
  <HandChart fill="currentColor" {...props} />
);

const KeywordResearchFilled = (props: FilledIconProps) => (
  <KeywordResearch fill="currentColor" {...props} />
);

const LightbulbExclamationFilled = (props: FilledIconProps) => (
  <LightbulbExclamation fill="currentColor" {...props} />
);

const MagnifyingGlassDollarFilled = (props: FilledIconProps) => (
  <MagnifyingGlassDollar fill="currentColor" {...props} />
);

const NetworkAltFilled = (props: FilledIconProps) => (
  <NetworkAlt fill="currentColor" {...props} />
);

const NetworkFilled = (props: FilledIconProps) => (
  <Network fill="currentColor" {...props} />
);

const PieChartAlt1Filled = (props: FilledIconProps) => (
  <PieChartAlt1 fill="currentColor" {...props} />
);

const PyramidDecreaseFilled = (props: FilledIconProps) => (
  <PyramidDecrease fill="currentColor" {...props} />
);

const PyramidIncreaseFilled = (props: FilledIconProps) => (
  <PyramidIncrease fill="currentColor" {...props} />
);

const PyramidFilled = (props: FilledIconProps) => (
  <Pyramid fill="currentColor" {...props} />
);

const RookFilled = (props: FilledIconProps) => (
  <Rook fill="currentColor" {...props} />
);

const SeoAltFilled = (props: FilledIconProps) => (
  <SeoAlt fill="currentColor" {...props} />
);

const SeoFilled = (props: FilledIconProps) => (
  <Seo fill="currentColor" {...props} />
);

const SquarePollHorizontalFilled = (props: FilledIconProps) => (
  <SquarePollHorizontal fill="currentColor" {...props} />
);

const SquarePollVerticalFilled = (props: FilledIconProps) => (
  <SquarePollVertical fill="currentColor" {...props} />
);

const StatisicAltFilled = (props: FilledIconProps) => (
  <StatisicAlt fill="currentColor" {...props} />
);

const StrategyFilled = (props: FilledIconProps) => (
  <Strategy fill="currentColor" {...props} />
);

const TargetAudienceAltFilled = (props: FilledIconProps) => (
  <TargetAudienceAlt fill="currentColor" {...props} />
);

const TargetCustomerFilled = (props: FilledIconProps) => (
  <TargetCustomer fill="currentColor" {...props} />
);

const TimelineFilled = (props: FilledIconProps) => (
  <Timeline fill="currentColor" {...props} />
);

const WebsiteRankFilled = (props: FilledIconProps) => (
  <WebsiteRank fill="currentColor" {...props} />
);

const WebsiteSeoFilled = (props: FilledIconProps) => (
  <WebsiteSeo fill="currentColor" {...props} />
);

export {
  AnalyticsChatFilled,
  AnalyticsFilled,
  BarChart1Filled,
  BarChartFilled,
  BarCheckFilled,
  BillboardAltFilled,
  BillboardFilled,
  EnvelopeOpenTextFilled,
  GiftCardFilled,
  GraphAlt1Filled,
  GraphAlt2Filled,
  GraphAlt3Filled,
  GraphAlt4Filled,
  GraphAltFilled,
  GraphDecreaseFilled,
  GraphIncreaseFilled,
  GraphFilled,
  HandChartFilled,
  KeywordResearchFilled,
  LightbulbExclamationFilled,
  MagnifyingGlassDollarFilled,
  NetworkAltFilled,
  NetworkFilled,
  PieChartAlt1Filled,
  PyramidDecreaseFilled,
  PyramidIncreaseFilled,
  PyramidFilled,
  RookFilled,
  SeoAltFilled,
  SeoFilled,
  SquarePollHorizontalFilled,
  SquarePollVerticalFilled,
  StatisicAltFilled,
  StrategyFilled,
  TargetAudienceAltFilled,
  TargetCustomerFilled,
  TimelineFilled,
  WebsiteRankFilled,
  WebsiteSeoFilled,
};
