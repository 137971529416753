import { initialize, LDFlagSet } from 'launchdarkly-js-client-sdk';

export type FeatureFlagUser =
  | { key: string; email: string }
  | { anonymous: true };

const client = initialize('6299f8bbf945ea147d9dd16f', { anonymous: true });

export const FeatureFlagClient = {
  getFlag(flagName: FeatureFlagNames, defaultValue: boolean = false): boolean {
    return client.variation(flagName.toString(), defaultValue);
  },

  updateUser(
    user: FeatureFlagUser,
    onDone?: (error: Error | null, flags: LDFlagSet | null) => void,
  ) {
    client.identify(user, undefined, onDone);
  },

  closeConnection() {
    client.flush(() => {
      client.close();
    });
  },
};

// Add any new flags here to prevent typos and spelling errors.
// These names must match the feature flag labels on the LaunchDarkly dashboard EXACTLY.
export enum FeatureFlagNames {
  CheckstepSignalsEnabled = 'checkstep-signals-enabled',
  ModerationQueuesEntrypoint = 'moderation-queues-entrypoint',
  ShowUserRuleFormOption = 'show-user-rule-form-option',
  SlackRetroaction = 'slack-retroaction',
}
