import { m } from 'framer-motion';
import { useEffect, useRef, useState, type CSSProperties } from 'react';

import { cn, random } from '../../../../lib/utils';
import {
  BASE_FONT_SIZE,
  GRID_GAP,
  PIPE_MAX_DELAY,
  PIPE_MIN_DELAY,
  PIPE_RADIUS,
} from './constants';
import { PathBuilder } from './paths';

export interface PipeProps {
  style?: CSSProperties;
  color?: 'primary' | 'accent';
  spanCols?: number;
  spanRows?: number;
  animated?: boolean;
  repeatType?: 'loop' | 'reverse';
  direction: 'up-right' | 'right-down' | 'down-right' | 'right-up' | 'down';
}

function getPath(
  [w, h]: [number, number],
  [cw, ch]: [number, number],
  strokeWidth: number,
  direction: PipeProps['direction'],
) {
  const halfWidth = strokeWidth / 2;

  switch (direction) {
    case 'up-right': {
      const startX = cw / 2 + halfWidth;
      const startY = h - ch;
      const endX = w - cw;
      const endY = ch / 2 - halfWidth;

      const builder = new PathBuilder(startX, startY);

      builder.vertical(endY + PIPE_RADIUS);
      builder.arc(PIPE_RADIUS);
      builder.horizontal(endX);

      return builder.build();
    }
    case 'right-down': {
      const startX = cw;
      const startY = ch / 2 - halfWidth;
      const endX = w - (cw / 2 - halfWidth);
      const endY = h - ch;

      const builder = new PathBuilder(startX, startY);

      builder.horizontal(endX - PIPE_RADIUS);
      builder.arc(PIPE_RADIUS, 'tr');
      builder.vertical(endY);

      return builder.build();
    }
    case 'right-up': {
      const startX = cw;
      const startY = h - (ch / 2 - halfWidth);
      const endX = w - (cw / 2 - halfWidth);
      const endY = ch;

      const builder = new PathBuilder(startX, startY);

      builder.horizontal(endX - PIPE_RADIUS);
      builder.arc(PIPE_RADIUS, 'br', false);
      builder.vertical(endY);

      return builder.build();
    }
    case 'down-right': {
      const startX = cw / 2 + halfWidth;
      const startY = ch;
      const endX = w - cw;
      const endY = h - (ch / 2 - halfWidth);

      const builder = new PathBuilder(startX, startY);

      builder.vertical(endY - PIPE_RADIUS);
      builder.arc(PIPE_RADIUS, 'bl', false);
      builder.horizontal(endX);

      return builder.build();
    }
    case 'down': {
      const startX = cw / 2 + halfWidth;
      const startY = ch;
      // Some SVG weirdness: https://stackoverflow.com/a/34687362
      const endX = startX + 0.0001;
      const endY = h - ch;

      const builder = new PathBuilder(startX, startY);

      builder.vertical(endY);
      builder.horizontal(endX);

      return builder.build();
    }
  }
}

export function Pipe({
  style,
  spanCols = 1,
  spanRows = 1,
  repeatType = 'loop',
  animated,
  color = 'primary',
  direction,
}: PipeProps) {
  const cols = spanCols + 1;
  const rows = spanRows + 1;
  const ref = useRef<HTMLDivElement>(null);

  const [cellWidth, setCellWidth] = useState(0);
  const [cellHeight, setCellHeight] = useState(0);

  const gap = GRID_GAP * (BASE_FONT_SIZE / 4);
  const boxWidth = cellWidth * cols + gap * (cols - 1);
  const boxHeight = cellHeight * rows + gap * (rows - 1);

  useEffect(() => {
    function updateDimensions() {
      if (ref.current) {
        const { width, height } = ref.current.getBoundingClientRect();

        setCellWidth(width);
        setCellHeight(height);
      }
    }

    let timeout: NodeJS.Timeout;

    function throttleUpdate() {
      clearTimeout(timeout);
      timeout = setTimeout(updateDimensions, 100);
    }

    window.addEventListener('resize', throttleUpdate);

    updateDimensions();

    return () => {
      window.removeEventListener('resize', throttleUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  const strokeWidth = 2;

  const path = getPath(
    [boxWidth, boxHeight],
    [cellWidth, cellHeight],
    strokeWidth,
    direction,
  );

  return (
    <div
      ref={ref}
      style={style}
      className={cn(
        'relative',
        { '-translate-y-[calc(100%+var(--gap))]': direction === 'up-right' },
        { '-translate-x-[calc(100%+var(--gap))]': direction === 'right-up' },
      )}
    >
      <div className="absolute inset-0">
        <svg
          width={boxWidth}
          height={boxHeight}
          viewBox={`0 0 ${boxWidth} ${boxHeight}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="primary">
              <stop offset="0%" style={{ stopColor: 'var(--primary)' }} />
              <stop
                offset="100%"
                className="[stopColor:theme('colors.ternary')]"
              />
            </linearGradient>
            <linearGradient id="accent">
              <stop offset="0%" className="[stopColor:#3E8EFF]" />
              <stop offset="100%" style={{ stopColor: 'var(--primary)' }} />
            </linearGradient>
          </defs>

          {animated ? (
            <m.path
              initial={{ strokeDashoffset: 500 }}
              animate={{
                strokeDashoffset: -500,
                transition: {
                  duration: 3,
                  delay: random(PIPE_MIN_DELAY, PIPE_MAX_DELAY),
                  ease: 'easeInOut',
                  repeat: Infinity,
                  repeatDelay: random(PIPE_MIN_DELAY, PIPE_MAX_DELAY),
                  repeatType,
                },
              }}
              strokeDasharray={500}
              d={path}
              stroke={color === 'primary' ? '#7165FF' : '#3E8EFF'}
              strokeWidth={strokeWidth}
              className="z-50"
            />
          ) : (
            <path d={path} strokeWidth={strokeWidth} stroke="#CBC9CB" />
          )}
        </svg>
      </div>
    </div>
  );
}
