import type { FilledIconProps } from '../types';
import { ReactComponent as Bold } from './bold.svg';
import { ReactComponent as CapitalLetter } from './capital-letter.svg';
import { ReactComponent as ClearFormat } from './clear-format.svg';
import { ReactComponent as FontAlt } from './font-alt.svg';
import { ReactComponent as Font } from './font.svg';
import { ReactComponent as Height } from './height.svg';
import { ReactComponent as IndentDecrease } from './indent-decrease.svg';
import { ReactComponent as IndentIncrease } from './indent-increase.svg';
import { ReactComponent as Italic } from './italic.svg';
import { ReactComponent as LineDashed } from './line-dashed.svg';
import { ReactComponent as LineDotted } from './line-dotted.svg';
import { ReactComponent as LineDouble } from './line-double.svg';
import { ReactComponent as LineSpacing } from './line-spacing.svg';
import { ReactComponent as PageAlt } from './page-alt.svg';
import { ReactComponent as PageBreak } from './page-break.svg';
import { ReactComponent as Pagination } from './pagination.svg';
import { ReactComponent as Pilcrow } from './pilcrow.svg';
import { ReactComponent as QuoteAlt1 } from './quote-alt-1.svg';
import { ReactComponent as QuoteAlt2 } from './quote-alt-2.svg';
import { ReactComponent as QuoteAlt3 } from './quote-alt-3.svg';
import { ReactComponent as QuoteAlt4 } from './quote-alt-4.svg';
import { ReactComponent as QuoteAlt5 } from './quote-alt-5.svg';
import { ReactComponent as QuoteAlt61 } from './quote-alt-6-1.svg';
import { ReactComponent as QuoteAlt6 } from './quote-alt-6.svg';
import { ReactComponent as QuoteAlt71 } from './quote-alt-7-1.svg';
import { ReactComponent as QuoteAlt7 } from './quote-alt-7.svg';
import { ReactComponent as QuoteAlt } from './quote-alt.svg';
import { ReactComponent as RedoAlt } from './redo-alt.svg';
import { ReactComponent as Redo } from './redo.svg';
import { ReactComponent as Reflect } from './reflect.svg';
import { ReactComponent as SortAlphaAsc } from './sort-alpha-asc.svg';
import { ReactComponent as SortAmountAsc } from './sort-amount-asc.svg';
import { ReactComponent as SortAmountDsc } from './sort-amount-dsc.svg';
import { ReactComponent as Spellcheck } from './spellcheck.svg';
import { ReactComponent as StrikethroughAlt } from './strikethrough-alt.svg';
import { ReactComponent as Strikethrough } from './strikethrough.svg';
import { ReactComponent as Symbol } from './symbol.svg';
import { ReactComponent as TextAlignCenter } from './text-align-center.svg';
import { ReactComponent as TextAlignJustify } from './text-align-justify.svg';
import { ReactComponent as TextAlignLeft } from './text-align-left.svg';
import { ReactComponent as TextAlignRight } from './text-align-right.svg';
import { ReactComponent as TextAlt } from './text-alt.svg';
import { ReactComponent as TextFormatRemove } from './text-format-remove.svg';
import { ReactComponent as TextFormat } from './text-format.svg';
import { ReactComponent as TextUnderlineAlt } from './text-underline-alt.svg';
import { ReactComponent as Text } from './text.svg';
import { ReactComponent as Underline } from './underline.svg';
import { ReactComponent as UndoAlt } from './undo-alt.svg';
import { ReactComponent as Undo } from './undo.svg';
import { ReactComponent as VerticalLine } from './vertical-line.svg';

const BoldFilled = (props: FilledIconProps) => (
  <Bold fill="currentColor" {...props} />
);

const CapitalLetterFilled = (props: FilledIconProps) => (
  <CapitalLetter fill="currentColor" {...props} />
);

const ClearFormatFilled = (props: FilledIconProps) => (
  <ClearFormat fill="currentColor" {...props} />
);

const FontAltFilled = (props: FilledIconProps) => (
  <FontAlt fill="currentColor" {...props} />
);

const FontFilled = (props: FilledIconProps) => (
  <Font fill="currentColor" {...props} />
);

const HeightFilled = (props: FilledIconProps) => (
  <Height fill="currentColor" {...props} />
);

const IndentDecreaseFilled = (props: FilledIconProps) => (
  <IndentDecrease fill="currentColor" {...props} />
);

const IndentIncreaseFilled = (props: FilledIconProps) => (
  <IndentIncrease fill="currentColor" {...props} />
);

const ItalicFilled = (props: FilledIconProps) => (
  <Italic fill="currentColor" {...props} />
);

const LineDashedFilled = (props: FilledIconProps) => (
  <LineDashed fill="currentColor" {...props} />
);

const LineDottedFilled = (props: FilledIconProps) => (
  <LineDotted fill="currentColor" {...props} />
);

const LineDoubleFilled = (props: FilledIconProps) => (
  <LineDouble fill="currentColor" {...props} />
);

const LineSpacingFilled = (props: FilledIconProps) => (
  <LineSpacing fill="currentColor" {...props} />
);

const PageAltFilled = (props: FilledIconProps) => (
  <PageAlt fill="currentColor" {...props} />
);

const PageBreakFilled = (props: FilledIconProps) => (
  <PageBreak fill="currentColor" {...props} />
);

const PaginationFilled = (props: FilledIconProps) => (
  <Pagination fill="currentColor" {...props} />
);

const PilcrowFilled = (props: FilledIconProps) => (
  <Pilcrow fill="currentColor" {...props} />
);

const QuoteAlt1Filled = (props: FilledIconProps) => (
  <QuoteAlt1 fill="currentColor" {...props} />
);

const QuoteAlt2Filled = (props: FilledIconProps) => (
  <QuoteAlt2 fill="currentColor" {...props} />
);

const QuoteAlt3Filled = (props: FilledIconProps) => (
  <QuoteAlt3 fill="currentColor" {...props} />
);

const QuoteAlt4Filled = (props: FilledIconProps) => (
  <QuoteAlt4 fill="currentColor" {...props} />
);

const QuoteAlt5Filled = (props: FilledIconProps) => (
  <QuoteAlt5 fill="currentColor" {...props} />
);

const QuoteAlt61Filled = (props: FilledIconProps) => (
  <QuoteAlt61 fill="currentColor" {...props} />
);

const QuoteAlt6Filled = (props: FilledIconProps) => (
  <QuoteAlt6 fill="currentColor" {...props} />
);

const QuoteAlt71Filled = (props: FilledIconProps) => (
  <QuoteAlt71 fill="currentColor" {...props} />
);

const QuoteAlt7Filled = (props: FilledIconProps) => (
  <QuoteAlt7 fill="currentColor" {...props} />
);

const QuoteAltFilled = (props: FilledIconProps) => (
  <QuoteAlt fill="currentColor" {...props} />
);

const RedoAltFilled = (props: FilledIconProps) => (
  <RedoAlt fill="currentColor" {...props} />
);

const RedoFilled = (props: FilledIconProps) => (
  <Redo fill="currentColor" {...props} />
);

const ReflectFilled = (props: FilledIconProps) => (
  <Reflect fill="currentColor" {...props} />
);

const SortAlphaAscFilled = (props: FilledIconProps) => (
  <SortAlphaAsc fill="currentColor" {...props} />
);

const SortAmountAscFilled = (props: FilledIconProps) => (
  <SortAmountAsc fill="currentColor" {...props} />
);

const SortAmountDscFilled = (props: FilledIconProps) => (
  <SortAmountDsc fill="currentColor" {...props} />
);

const SpellcheckFilled = (props: FilledIconProps) => (
  <Spellcheck fill="currentColor" {...props} />
);

const StrikethroughAltFilled = (props: FilledIconProps) => (
  <StrikethroughAlt fill="currentColor" {...props} />
);

const StrikethroughFilled = (props: FilledIconProps) => (
  <Strikethrough fill="currentColor" {...props} />
);

const SymbolFilled = (props: FilledIconProps) => (
  <Symbol fill="currentColor" {...props} />
);

const TextAlignCenterFilled = (props: FilledIconProps) => (
  <TextAlignCenter fill="currentColor" {...props} />
);

const TextAlignJustifyFilled = (props: FilledIconProps) => (
  <TextAlignJustify fill="currentColor" {...props} />
);

const TextAlignLeftFilled = (props: FilledIconProps) => (
  <TextAlignLeft fill="currentColor" {...props} />
);

const TextAlignRightFilled = (props: FilledIconProps) => (
  <TextAlignRight fill="currentColor" {...props} />
);

const TextAltFilled = (props: FilledIconProps) => (
  <TextAlt fill="currentColor" {...props} />
);

const TextFormatRemoveFilled = (props: FilledIconProps) => (
  <TextFormatRemove fill="currentColor" {...props} />
);

const TextFormatFilled = (props: FilledIconProps) => (
  <TextFormat fill="currentColor" {...props} />
);

const TextUnderlineAltFilled = (props: FilledIconProps) => (
  <TextUnderlineAlt fill="currentColor" {...props} />
);

const TextFilled = (props: FilledIconProps) => (
  <Text fill="currentColor" {...props} />
);

const UnderlineFilled = (props: FilledIconProps) => (
  <Underline fill="currentColor" {...props} />
);

const UndoAltFilled = (props: FilledIconProps) => (
  <UndoAlt fill="currentColor" {...props} />
);

const UndoFilled = (props: FilledIconProps) => (
  <Undo fill="currentColor" {...props} />
);

const VerticalLineFilled = (props: FilledIconProps) => (
  <VerticalLine fill="currentColor" {...props} />
);

export {
  BoldFilled,
  CapitalLetterFilled,
  ClearFormatFilled,
  FontAltFilled,
  FontFilled,
  HeightFilled,
  IndentDecreaseFilled,
  IndentIncreaseFilled,
  ItalicFilled,
  LineDashedFilled,
  LineDottedFilled,
  LineDoubleFilled,
  LineSpacingFilled,
  PageAltFilled,
  PageBreakFilled,
  PaginationFilled,
  PilcrowFilled,
  QuoteAlt1Filled,
  QuoteAlt2Filled,
  QuoteAlt3Filled,
  QuoteAlt4Filled,
  QuoteAlt5Filled,
  QuoteAlt61Filled,
  QuoteAlt6Filled,
  QuoteAlt71Filled,
  QuoteAlt7Filled,
  QuoteAltFilled,
  RedoAltFilled,
  RedoFilled,
  ReflectFilled,
  SortAlphaAscFilled,
  SortAmountAscFilled,
  SortAmountDscFilled,
  SpellcheckFilled,
  StrikethroughAltFilled,
  StrikethroughFilled,
  SymbolFilled,
  TextAlignCenterFilled,
  TextAlignJustifyFilled,
  TextAlignLeftFilled,
  TextAlignRightFilled,
  TextAltFilled,
  TextFormatRemoveFilled,
  TextFormatFilled,
  TextUnderlineAltFilled,
  TextFilled,
  UnderlineFilled,
  UndoAltFilled,
  UndoFilled,
  VerticalLineFilled,
};
