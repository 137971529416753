import { CloseFilled } from '@/icons';
import { AnimatePresence, m } from 'framer-motion';

interface LightboxProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export function Lightbox({ isOpen, onClose, children }: LightboxProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <m.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed flex inset-0 p-4 bg-black/90 backdrop-blur-sm z-[9999]"
        >
          <div className="m-auto">
            <m.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.75 }}
              exit={{ opacity: 1, scale: 0.95 }}
            >
              {children}
            </m.div>
            <div className="absolute w-6 h-6 right-8 top-8" onClick={onClose}>
              <CloseFilled className="w-6 p-1 rounded-full cursor-pointer bg-slate-400/70 hover:bg-slate-400/50 text-slate-200" />
            </div>
          </div>
        </m.div>
      )}
    </AnimatePresence>
  );
}
