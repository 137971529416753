import { Outlet } from 'react-router-dom';

import { Footer } from './components/Footer';
import { Navigation } from './components/Navigation';

import './home.css';

export default function HomeContainer() {
  return (
    <div>
      <Navigation />
      <Outlet />
      <Footer />
    </div>
  );
}
