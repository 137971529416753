import { m } from 'framer-motion';

import { cn } from '../../../lib/utils';
import { Typography } from '../../ui/Typography';

interface FeatureSectionProps {
  label?: string;
  className?: string;
  title: string;
  description?: string;
  container?: boolean;
  overflow?: boolean;
  centered?: boolean;
  id?: string;
  size?: 'lg' | 'md';
  orientation?: 'ltr' | 'rtl' | 'ttb';
  gap?: boolean;
  children?: React.ReactNode;
  cta?: React.ReactNode;
  extraGraphicAboveCta?: React.ReactNode;
}

export function FeatureSection({
  title,
  label,
  description,
  container = true,
  className,
  cta,
  extraGraphicAboveCta,
  size = 'lg',
  overflow = false,
  id,
  gap = true,
  orientation = 'ltr',
  children,
  centered,
}: FeatureSectionProps) {
  return (
    <div className={cn({ 'overflow-hidden': !overflow })} id={id}>
      <div
        className={cn(
          'mt-8 md:mt-20 grid xl:px-24 justify-items-start xl:justify-items-center py-16',
          { container },
          { 'gap-20': gap && orientation === 'ttb' },
          { 'gap-12': gap && orientation !== 'ttb' },
          { 'grid-cols-1 xl:grid-cols-2': orientation === 'ltr' },
          {
            'items-center grid-cols-1 xl:grid-cols-2': orientation === 'rtl',
          },
          { 'grid-cols-1': orientation === 'ttb' },
          { 'justify-items-center': centered },
          { 'xl:justify-items-start': !centered },
          className,
        )}
      >
        <m.div
          transition={{ delay: 0.2, duration: 0.5 }}
          initial={{ opacity: 0, y: 22 }}
          whileInView={{ y: 0, opacity: 1 }}
          viewport={{ once: true, margin: '-100px' }}
          className={cn(
            'relative flex flex-col justify-center items-start z-40 gap-4',
            { 'xl:order-first': ['ltr', 'rtl'].includes(orientation) },
            {
              'xl:pr-8 max-w-5xl xl:max-w-2xl': orientation === 'ltr',
            },
            { 'max-w-5xl px-0 xl:px-8': orientation === 'ttb' },
            { 'xl:items-center': centered },
            { 'pb-8': orientation === 'ttb' && gap },
          )}
        >
          {label && (
            <Typography
              className="bg-gradient-to-b from-primary to-[#3E8EFF] to-75% bg-clip-text pl-1 text-clear"
              variant="title"
              size="md"
            >
              {label}
            </Typography>
          )}

          <Typography
            className={cn('text-start [&>span]:text-primary', {
              'xl:text-center': centered,
            })}
            size={size}
            as="h2"
            variant="display"
            dangerouslySetInnerHTML={{ __html: title }}
          />

          {description && (
            <div className="mb-0 sm:mb-6">
              {description.split('\n').map((d) => (
                <Typography
                  className={cn('text-start mb-4 lg:mb-4', {
                    'xl:text-center': centered,
                  })}
                  key={d}
                  size="lg"
                  color="subtle"
                >
                  {d}
                </Typography>
              ))}
            </div>
          )}
          {extraGraphicAboveCta ? extraGraphicAboveCta : null}
          <div className="hidden xl:flex">{cta}</div>
        </m.div>

        {children}
        <div className="flex xl:hidden justify-self-start">{cta}</div>
      </div>
    </div>
  );
}
