import { useEffect } from 'react';

export default function ExternalUrlRedirect(props: { to: string }) {
  const { to } = props;
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return <h5>Redirecting...</h5>;
}
