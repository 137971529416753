import { m } from 'framer-motion';
import type { HTMLAttributes } from 'react';

import { cn } from '../../lib/utils';

interface VerticalDashesProps extends HTMLAttributes<HTMLDivElement> {
  height?: string | number;
}

export function VerticalDashes({ height, className }: VerticalDashesProps) {
  return (
    <m.div
      initial={{ height: 0 }}
      animate={{
        height: height ?? '100%',
        transition: {
          duration: 2,
        },
      }}
      className={cn(
        'w-px',
        "[&]:[background-image:linear-gradient(0deg,transparent_50%,theme('colors.primary')_50%)]",
        '[&]:[background-size:100%_8px]',
        className,
      )}
    ></m.div>
  );
}
