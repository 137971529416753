import type { FilledIconProps } from '../types';
import { ReactComponent as Anchor } from './anchor.svg';
import { ReactComponent as BoardingPassAlt } from './boarding-pass-alt.svg';
import { ReactComponent as BridgeAlt } from './bridge-alt.svg';
import { ReactComponent as Bridge } from './bridge.svg';
import { ReactComponent as Bubble } from './bubble.svg';
import { ReactComponent as Bullhorn1 } from './bullhorn-1.svg';
import { ReactComponent as Bullhorn } from './bullhorn.svg';
import { ReactComponent as Bus } from './bus.svg';
import { ReactComponent as CarAlt1 } from './car-alt-1.svg';
import { ReactComponent as CarAlt2 } from './car-alt-2.svg';
import { ReactComponent as CarAlt4 } from './car-alt-4.svg';
import { ReactComponent as Caravan } from './caravan.svg';
import { ReactComponent as ChatAddAlt2 } from './chat-add-alt-2.svg';
import { ReactComponent as CommentsAlt2 } from './comments-alt-2.svg';
import { ReactComponent as CommentsReply } from './comments-reply.svg';
import { ReactComponent as Comments } from './comments.svg';
import { ReactComponent as DeliveryTruck5 } from './delivery-truck-5.svg';
import { ReactComponent as EnvelopeAlt } from './envelope-alt.svg';
import { ReactComponent as EnvelopeUnread } from './envelope-unread.svg';
import { ReactComponent as Envelope } from './envelope.svg';
import { ReactComponent as Helicopter } from './helicopter.svg';
import { ReactComponent as InboxArchive } from './inbox-archive.svg';
import { ReactComponent as Inbox } from './inbox.svg';
import { ReactComponent as Kayak } from './kayak.svg';
import { ReactComponent as Mailbox } from './mailbox.svg';
import { ReactComponent as Megaphone } from './megaphone.svg';
import { ReactComponent as MessageAttachement } from './message-attachement.svg';
import { ReactComponent as MessageCheck } from './message-check.svg';
import { ReactComponent as MessageEdit } from './message-edit.svg';
import { ReactComponent as MessageForward } from './message-forward.svg';
import { ReactComponent as MessageIncoming } from './message-incoming.svg';
import { ReactComponent as MessageLock } from './message-lock.svg';
import { ReactComponent as MessageLove } from './message-love.svg';
import { ReactComponent as MessageOutgoing } from './message-outgoing.svg';
import { ReactComponent as MessagePeopleAlt } from './message-people-alt.svg';
import { ReactComponent as MessagePeople } from './message-people.svg';
import { ReactComponent as MessagePlus } from './message-plus.svg';
import { ReactComponent as MessageReply } from './message-reply.svg';
import { ReactComponent as MessageSearch } from './message-search.svg';
import { ReactComponent as MobileAlt1 } from './mobile-alt-1.svg';
import { ReactComponent as MobileAlt } from './mobile-alt.svg';
import { ReactComponent as MobileChat } from './mobile-chat.svg';
import { ReactComponent as Page } from './page.svg';
import { ReactComponent as PhoneConference } from './phone-conference.svg';
import { ReactComponent as PhoneCut } from './phone-cut.svg';
import { ReactComponent as PhoneIncoming } from './phone-incoming.svg';
import { ReactComponent as PhoneMisscall } from './phone-misscall.svg';
import { ReactComponent as PhoneOutgoing } from './phone-outgoing.svg';
import { ReactComponent as PhoneRedial } from './phone-redial.svg';
import { ReactComponent as PhoneRing } from './phone-ring.svg';
import { ReactComponent as PhoneSet } from './phone-set.svg';
import { ReactComponent as Phone } from './phone.svg';
import { ReactComponent as PlaneAlt } from './plane-alt.svg';
import { ReactComponent as Plane } from './plane.svg';
import { ReactComponent as Postcard } from './postcard.svg';
import { ReactComponent as Reply } from './reply.svg';
import { ReactComponent as RoadAlt } from './road-alt.svg';
import { ReactComponent as Road } from './road.svg';
import { ReactComponent as Scooter } from './scooter.svg';
import { ReactComponent as Ship } from './ship.svg';
import { ReactComponent as SignalAlt } from './signal-alt.svg';
import { ReactComponent as SignalSlash } from './signal-slash.svg';
import { ReactComponent as SpeechBubble1 } from './speech-bubble-1.svg';
import { ReactComponent as SpeechBubble8 } from './speech-bubble-8.svg';
import { ReactComponent as SpeechBubble9 } from './speech-bubble-9.svg';
import { ReactComponent as SpeechBubble15 } from './speech-bubble-15.svg';
import { ReactComponent as SpeechBubble18 } from './speech-bubble-18.svg';
import { ReactComponent as SpeechBubble19 } from './speech-bubble-19.svg';
import { ReactComponent as SpeechBubble20 } from './speech-bubble-20.svg';
import { ReactComponent as SpeechBubble21 } from './speech-bubble-21.svg';
import { ReactComponent as SpeechBubble22 } from './speech-bubble-22.svg';
import { ReactComponent as SpeechBubble23 } from './speech-bubble-23.svg';
import { ReactComponent as SpeechBubble24 } from './speech-bubble-24.svg';
import { ReactComponent as SpeechBubble25 } from './speech-bubble-25.svg';
import { ReactComponent as SpeechBubble26 } from './speech-bubble-26.svg';
import { ReactComponent as SpeechBubble27 } from './speech-bubble-27.svg';
import { ReactComponent as SpeechBubble29 } from './speech-bubble-29.svg';
import { ReactComponent as SpeechBubble31 } from './speech-bubble-31.svg';
import { ReactComponent as SpeechBubble32 } from './speech-bubble-32.svg';
import { ReactComponent as SpeechBubble331 } from './speech-bubble-33-1.svg';
import { ReactComponent as SpeechBubble33 } from './speech-bubble-33.svg';
import { ReactComponent as SpeechBubble34 } from './speech-bubble-34.svg';
import { ReactComponent as SpeechBubble35 } from './speech-bubble-35.svg';
import { ReactComponent as SpeechBubble36 } from './speech-bubble-36.svg';
import { ReactComponent as SpeechBubble37 } from './speech-bubble-37.svg';
import { ReactComponent as SpeechBubble40 } from './speech-bubble-40.svg';
import { ReactComponent as SpeechBubble41 } from './speech-bubble-41.svg';
import { ReactComponent as SpeechBubble42 } from './speech-bubble-42.svg';
import { ReactComponent as SpeechBubble43 } from './speech-bubble-43.svg';
import { ReactComponent as SpeechBubble44 } from './speech-bubble-44.svg';
import { ReactComponent as SpeechBubble45 } from './speech-bubble-45.svg';
import { ReactComponent as SpeechBubble46 } from './speech-bubble-46.svg';
import { ReactComponent as SpeechBubble47 } from './speech-bubble-47.svg';
import { ReactComponent as Support } from './support.svg';
import { ReactComponent as TargetMail } from './target-mail.svg';
import { ReactComponent as Taxi } from './taxi.svg';
import { ReactComponent as TrainAlt } from './train-alt.svg';
import { ReactComponent as WifiSlash } from './wifi-slash.svg';
import { ReactComponent as Yacht2 } from './yacht-2.svg';
import { ReactComponent as Yacht3 } from './yacht-3.svg';
import { ReactComponent as Yacht } from './yacht.svg';

const AnchorFilled = (props: FilledIconProps) => (
  <Anchor fill="currentColor" {...props} />
);

const BoardingPassAltFilled = (props: FilledIconProps) => (
  <BoardingPassAlt fill="currentColor" {...props} />
);

const BridgeAltFilled = (props: FilledIconProps) => (
  <BridgeAlt fill="currentColor" {...props} />
);

const BridgeFilled = (props: FilledIconProps) => (
  <Bridge fill="currentColor" {...props} />
);

const BubbleFilled = (props: FilledIconProps) => (
  <Bubble fill="currentColor" {...props} />
);

const Bullhorn1Filled = (props: FilledIconProps) => (
  <Bullhorn1 fill="currentColor" {...props} />
);

const BullhornFilled = (props: FilledIconProps) => (
  <Bullhorn fill="currentColor" {...props} />
);

const BusFilled = (props: FilledIconProps) => (
  <Bus fill="currentColor" {...props} />
);

const CarAlt1Filled = (props: FilledIconProps) => (
  <CarAlt1 fill="currentColor" {...props} />
);

const CarAlt2Filled = (props: FilledIconProps) => (
  <CarAlt2 fill="currentColor" {...props} />
);

const CarAlt4Filled = (props: FilledIconProps) => (
  <CarAlt4 fill="currentColor" {...props} />
);

const CaravanFilled = (props: FilledIconProps) => (
  <Caravan fill="currentColor" {...props} />
);

const ChatAddAlt2Filled = (props: FilledIconProps) => (
  <ChatAddAlt2 fill="currentColor" {...props} />
);

const CommentsAlt2Filled = (props: FilledIconProps) => (
  <CommentsAlt2 fill="currentColor" {...props} />
);

const CommentsReplyFilled = (props: FilledIconProps) => (
  <CommentsReply fill="currentColor" {...props} />
);

const CommentsFilled = (props: FilledIconProps) => (
  <Comments fill="currentColor" {...props} />
);

const DeliveryTruck5Filled = (props: FilledIconProps) => (
  <DeliveryTruck5 fill="currentColor" {...props} />
);

const EnvelopeAltFilled = (props: FilledIconProps) => (
  <EnvelopeAlt fill="currentColor" {...props} />
);

const EnvelopeUnreadFilled = (props: FilledIconProps) => (
  <EnvelopeUnread fill="currentColor" {...props} />
);

const EnvelopeFilled = (props: FilledIconProps) => (
  <Envelope fill="currentColor" {...props} />
);

const HelicopterFilled = (props: FilledIconProps) => (
  <Helicopter fill="currentColor" {...props} />
);

const InboxArchiveFilled = (props: FilledIconProps) => (
  <InboxArchive fill="currentColor" {...props} />
);

const InboxFilled = (props: FilledIconProps) => (
  <Inbox fill="currentColor" {...props} />
);

const KayakFilled = (props: FilledIconProps) => (
  <Kayak fill="currentColor" {...props} />
);

const MailboxFilled = (props: FilledIconProps) => (
  <Mailbox fill="currentColor" {...props} />
);

const MegaphoneFilled = (props: FilledIconProps) => (
  <Megaphone fill="currentColor" {...props} />
);

const MessageAttachementFilled = (props: FilledIconProps) => (
  <MessageAttachement fill="currentColor" {...props} />
);

const MessageCheckFilled = (props: FilledIconProps) => (
  <MessageCheck fill="currentColor" {...props} />
);

const MessageEditFilled = (props: FilledIconProps) => (
  <MessageEdit fill="currentColor" {...props} />
);

const MessageForwardFilled = (props: FilledIconProps) => (
  <MessageForward fill="currentColor" {...props} />
);

const MessageIncomingFilled = (props: FilledIconProps) => (
  <MessageIncoming fill="currentColor" {...props} />
);

const MessageLockFilled = (props: FilledIconProps) => (
  <MessageLock fill="currentColor" {...props} />
);

const MessageLoveFilled = (props: FilledIconProps) => (
  <MessageLove fill="currentColor" {...props} />
);

const MessageOutgoingFilled = (props: FilledIconProps) => (
  <MessageOutgoing fill="currentColor" {...props} />
);

const MessagePeopleAltFilled = (props: FilledIconProps) => (
  <MessagePeopleAlt fill="currentColor" {...props} />
);

const MessagePeopleFilled = (props: FilledIconProps) => (
  <MessagePeople fill="currentColor" {...props} />
);

const MessagePlusFilled = (props: FilledIconProps) => (
  <MessagePlus fill="currentColor" {...props} />
);

const MessageReplyFilled = (props: FilledIconProps) => (
  <MessageReply fill="currentColor" {...props} />
);

const MessageSearchFilled = (props: FilledIconProps) => (
  <MessageSearch fill="currentColor" {...props} />
);

const MobileAlt1Filled = (props: FilledIconProps) => (
  <MobileAlt1 fill="currentColor" {...props} />
);

const MobileAltFilled = (props: FilledIconProps) => (
  <MobileAlt fill="currentColor" {...props} />
);

const MobileChatFilled = (props: FilledIconProps) => (
  <MobileChat fill="currentColor" {...props} />
);

const PageFilled = (props: FilledIconProps) => (
  <Page fill="currentColor" {...props} />
);

const PhoneConferenceFilled = (props: FilledIconProps) => (
  <PhoneConference fill="currentColor" {...props} />
);

const PhoneCutFilled = (props: FilledIconProps) => (
  <PhoneCut fill="currentColor" {...props} />
);

const PhoneIncomingFilled = (props: FilledIconProps) => (
  <PhoneIncoming fill="currentColor" {...props} />
);

const PhoneMisscallFilled = (props: FilledIconProps) => (
  <PhoneMisscall fill="currentColor" {...props} />
);

const PhoneOutgoingFilled = (props: FilledIconProps) => (
  <PhoneOutgoing fill="currentColor" {...props} />
);

const PhoneRedialFilled = (props: FilledIconProps) => (
  <PhoneRedial fill="currentColor" {...props} />
);

const PhoneRingFilled = (props: FilledIconProps) => (
  <PhoneRing fill="currentColor" {...props} />
);

const PhoneSetFilled = (props: FilledIconProps) => (
  <PhoneSet fill="currentColor" {...props} />
);

const PhoneFilled = (props: FilledIconProps) => (
  <Phone fill="currentColor" {...props} />
);

const PlaneAltFilled = (props: FilledIconProps) => (
  <PlaneAlt fill="currentColor" {...props} />
);

const PlaneFilled = (props: FilledIconProps) => (
  <Plane fill="currentColor" {...props} />
);

const PostcardFilled = (props: FilledIconProps) => (
  <Postcard fill="currentColor" {...props} />
);

const ReplyFilled = (props: FilledIconProps) => (
  <Reply fill="currentColor" {...props} />
);

const RoadAltFilled = (props: FilledIconProps) => (
  <RoadAlt fill="currentColor" {...props} />
);

const RoadFilled = (props: FilledIconProps) => (
  <Road fill="currentColor" {...props} />
);

const ScooterFilled = (props: FilledIconProps) => (
  <Scooter fill="currentColor" {...props} />
);

const ShipFilled = (props: FilledIconProps) => (
  <Ship fill="currentColor" {...props} />
);

const SignalAltFilled = (props: FilledIconProps) => (
  <SignalAlt fill="currentColor" {...props} />
);

const SignalSlashFilled = (props: FilledIconProps) => (
  <SignalSlash fill="currentColor" {...props} />
);

const SpeechBubble1Filled = (props: FilledIconProps) => (
  <SpeechBubble1 fill="currentColor" {...props} />
);

const SpeechBubble15Filled = (props: FilledIconProps) => (
  <SpeechBubble15 fill="currentColor" {...props} />
);

const SpeechBubble18Filled = (props: FilledIconProps) => (
  <SpeechBubble18 fill="currentColor" {...props} />
);

const SpeechBubble19Filled = (props: FilledIconProps) => (
  <SpeechBubble19 fill="currentColor" {...props} />
);

const SpeechBubble20Filled = (props: FilledIconProps) => (
  <SpeechBubble20 fill="currentColor" {...props} />
);

const SpeechBubble21Filled = (props: FilledIconProps) => (
  <SpeechBubble21 fill="currentColor" {...props} />
);

const SpeechBubble22Filled = (props: FilledIconProps) => (
  <SpeechBubble22 fill="currentColor" {...props} />
);

const SpeechBubble23Filled = (props: FilledIconProps) => (
  <SpeechBubble23 fill="currentColor" {...props} />
);

const SpeechBubble24Filled = (props: FilledIconProps) => (
  <SpeechBubble24 fill="currentColor" {...props} />
);

const SpeechBubble25Filled = (props: FilledIconProps) => (
  <SpeechBubble25 fill="currentColor" {...props} />
);

const SpeechBubble26Filled = (props: FilledIconProps) => (
  <SpeechBubble26 fill="currentColor" {...props} />
);

const SpeechBubble27Filled = (props: FilledIconProps) => (
  <SpeechBubble27 fill="currentColor" {...props} />
);

const SpeechBubble29Filled = (props: FilledIconProps) => (
  <SpeechBubble29 fill="currentColor" {...props} />
);

const SpeechBubble31Filled = (props: FilledIconProps) => (
  <SpeechBubble31 fill="currentColor" {...props} />
);

const SpeechBubble32Filled = (props: FilledIconProps) => (
  <SpeechBubble32 fill="currentColor" {...props} />
);

const SpeechBubble331Filled = (props: FilledIconProps) => (
  <SpeechBubble331 fill="currentColor" {...props} />
);

const SpeechBubble33Filled = (props: FilledIconProps) => (
  <SpeechBubble33 fill="currentColor" {...props} />
);

const SpeechBubble34Filled = (props: FilledIconProps) => (
  <SpeechBubble34 fill="currentColor" {...props} />
);

const SpeechBubble35Filled = (props: FilledIconProps) => (
  <SpeechBubble35 fill="currentColor" {...props} />
);

const SpeechBubble36Filled = (props: FilledIconProps) => (
  <SpeechBubble36 fill="currentColor" {...props} />
);

const SpeechBubble37Filled = (props: FilledIconProps) => (
  <SpeechBubble37 fill="currentColor" {...props} />
);

const SpeechBubble40Filled = (props: FilledIconProps) => (
  <SpeechBubble40 fill="currentColor" {...props} />
);

const SpeechBubble41Filled = (props: FilledIconProps) => (
  <SpeechBubble41 fill="currentColor" {...props} />
);

const SpeechBubble42Filled = (props: FilledIconProps) => (
  <SpeechBubble42 fill="currentColor" {...props} />
);

const SpeechBubble43Filled = (props: FilledIconProps) => (
  <SpeechBubble43 fill="currentColor" {...props} />
);

const SpeechBubble44Filled = (props: FilledIconProps) => (
  <SpeechBubble44 fill="currentColor" {...props} />
);

const SpeechBubble45Filled = (props: FilledIconProps) => (
  <SpeechBubble45 fill="currentColor" {...props} />
);

const SpeechBubble46Filled = (props: FilledIconProps) => (
  <SpeechBubble46 fill="currentColor" {...props} />
);

const SpeechBubble47Filled = (props: FilledIconProps) => (
  <SpeechBubble47 fill="currentColor" {...props} />
);

const SpeechBubble8Filled = (props: FilledIconProps) => (
  <SpeechBubble8 fill="currentColor" {...props} />
);

const SpeechBubble9Filled = (props: FilledIconProps) => (
  <SpeechBubble9 fill="currentColor" {...props} />
);

const SupportFilled = (props: FilledIconProps) => (
  <Support fill="currentColor" {...props} />
);

const TargetMailFilled = (props: FilledIconProps) => (
  <TargetMail fill="currentColor" {...props} />
);

const TaxiFilled = (props: FilledIconProps) => (
  <Taxi fill="currentColor" {...props} />
);

const TrainAltFilled = (props: FilledIconProps) => (
  <TrainAlt fill="currentColor" {...props} />
);

const WifiSlashFilled = (props: FilledIconProps) => (
  <WifiSlash fill="currentColor" {...props} />
);

const Yacht2Filled = (props: FilledIconProps) => (
  <Yacht2 fill="currentColor" {...props} />
);

const Yacht3Filled = (props: FilledIconProps) => (
  <Yacht3 fill="currentColor" {...props} />
);

const YachtFilled = (props: FilledIconProps) => (
  <Yacht fill="currentColor" {...props} />
);

export {
  AnchorFilled,
  BoardingPassAltFilled,
  BridgeAltFilled,
  BridgeFilled,
  BubbleFilled,
  Bullhorn1Filled,
  BullhornFilled,
  BusFilled,
  CarAlt1Filled,
  CarAlt2Filled,
  CarAlt4Filled,
  CaravanFilled,
  ChatAddAlt2Filled,
  CommentsAlt2Filled,
  CommentsReplyFilled,
  CommentsFilled,
  DeliveryTruck5Filled,
  EnvelopeAltFilled,
  EnvelopeUnreadFilled,
  EnvelopeFilled,
  HelicopterFilled,
  InboxArchiveFilled,
  InboxFilled,
  KayakFilled,
  MailboxFilled,
  MegaphoneFilled,
  MessageAttachementFilled,
  MessageCheckFilled,
  MessageEditFilled,
  MessageForwardFilled,
  MessageIncomingFilled,
  MessageLockFilled,
  MessageLoveFilled,
  MessageOutgoingFilled,
  MessagePeopleAltFilled,
  MessagePeopleFilled,
  MessagePlusFilled,
  MessageReplyFilled,
  MessageSearchFilled,
  MobileAlt1Filled,
  MobileAltFilled,
  MobileChatFilled,
  PageFilled,
  PhoneConferenceFilled,
  PhoneCutFilled,
  PhoneIncomingFilled,
  PhoneMisscallFilled,
  PhoneOutgoingFilled,
  PhoneRedialFilled,
  PhoneRingFilled,
  PhoneSetFilled,
  PhoneFilled,
  PlaneAltFilled,
  PlaneFilled,
  PostcardFilled,
  ReplyFilled,
  RoadAltFilled,
  RoadFilled,
  ScooterFilled,
  ShipFilled,
  SignalAltFilled,
  SignalSlashFilled,
  SpeechBubble1Filled,
  SpeechBubble15Filled,
  SpeechBubble18Filled,
  SpeechBubble19Filled,
  SpeechBubble20Filled,
  SpeechBubble21Filled,
  SpeechBubble22Filled,
  SpeechBubble23Filled,
  SpeechBubble24Filled,
  SpeechBubble25Filled,
  SpeechBubble26Filled,
  SpeechBubble27Filled,
  SpeechBubble29Filled,
  SpeechBubble31Filled,
  SpeechBubble32Filled,
  SpeechBubble331Filled,
  SpeechBubble33Filled,
  SpeechBubble34Filled,
  SpeechBubble35Filled,
  SpeechBubble36Filled,
  SpeechBubble37Filled,
  SpeechBubble40Filled,
  SpeechBubble41Filled,
  SpeechBubble42Filled,
  SpeechBubble43Filled,
  SpeechBubble44Filled,
  SpeechBubble45Filled,
  SpeechBubble46Filled,
  SpeechBubble47Filled,
  SpeechBubble8Filled,
  SpeechBubble9Filled,
  SupportFilled,
  TargetMailFilled,
  TaxiFilled,
  TrainAltFilled,
  WifiSlashFilled,
  Yacht2Filled,
  Yacht3Filled,
  YachtFilled,
};
