import type { FilledIconProps } from '../types';
import { ReactComponent as Atom } from './atom.svg';
import { ReactComponent as BackpackAlt } from './backpack-alt.svg';
import { ReactComponent as Backpack } from './backpack.svg';
import { ReactComponent as Beta } from './beta.svg';
import { ReactComponent as BlackboardAlt } from './blackboard-alt.svg';
import { ReactComponent as Blackboard } from './blackboard.svg';
import { ReactComponent as Book2 } from './book-2.svg';
import { ReactComponent as BookAlt } from './book-alt.svg';
import { ReactComponent as BookOpenAlt } from './book-open-alt.svg';
import { ReactComponent as BookOpen } from './book-open.svg';
import { ReactComponent as Book } from './book.svg';
import { ReactComponent as BooksAlt } from './books-alt.svg';
import { ReactComponent as Books } from './books.svg';
import { ReactComponent as Brackets } from './brackets.svg';
import { ReactComponent as CertificateAlt1 } from './certificate-alt-1.svg';
import { ReactComponent as CertificateAlt } from './certificate-alt.svg';
import { ReactComponent as Certificate } from './certificate.svg';
import { ReactComponent as ClassroomAlt } from './classroom-alt.svg';
import { ReactComponent as Classroom } from './classroom.svg';
import { ReactComponent as Clipboard } from './clipboard.svg';
import { ReactComponent as Compass } from './compass.svg';
import { ReactComponent as Cubes } from './cubes.svg';
import { ReactComponent as Ebook } from './ebook.svg';
import { ReactComponent as EraserAlt } from './eraser-alt.svg';
import { ReactComponent as Eraser } from './eraser.svg';
import { ReactComponent as FlaskAlt1 } from './flask-alt-1.svg';
import { ReactComponent as FlaskAlt2 } from './flask-alt-2.svg';
import { ReactComponent as Flask } from './flask.svg';
import { ReactComponent as Geometry } from './geometry.svg';
import { ReactComponent as GraduateAlt } from './graduate-alt.svg';
import { ReactComponent as Graduate } from './graduate.svg';
import { ReactComponent as Graduation } from './graduation.svg';
import { ReactComponent as H2o } from './h2o.svg';
import { ReactComponent as Knowledge } from './knowledge.svg';
import { ReactComponent as Letterblocks } from './letterblocks.svg';
import { ReactComponent as Library } from './library.svg';
import { ReactComponent as Lockers } from './lockers.svg';
import { ReactComponent as MedalAlt } from './medal-alt.svg';
import { ReactComponent as Medal } from './medal.svg';
import { ReactComponent as MoleculeAlt } from './molecule-alt.svg';
import { ReactComponent as Molecule } from './molecule.svg';
import { ReactComponent as PencilAlt } from './pencil-alt.svg';
import { ReactComponent as Pencil } from './pencil.svg';
import { ReactComponent as Quill } from './quill.svg';
import { ReactComponent as SchoolBenchAlt } from './school-bench-alt.svg';
import { ReactComponent as SchoolBench } from './school-bench.svg';
import { ReactComponent as SchoolBus } from './school-bus.svg';
import { ReactComponent as Sinusoid } from './sinusoid.svg';
import { ReactComponent as Sketchbook } from './sketchbook.svg';
import { ReactComponent as Telescope } from './telescope.svg';
import { ReactComponent as WhiteboardAlt1 } from './whiteboard-alt-1.svg';
import { ReactComponent as WhiteboardAlt2 } from './whiteboard-alt-2.svg';
import { ReactComponent as WhiteboardAlt } from './whiteboard-alt.svg';
import { ReactComponent as Whiteboard } from './whiteboard.svg';
import { ReactComponent as World2 } from './world-2.svg';
import { ReactComponent as WorldAlt } from './world-alt.svg';
import { ReactComponent as World } from './world.svg';
import { ReactComponent as Write } from './write.svg';

const AtomFilled = (props: FilledIconProps) => (
  <Atom fill="currentColor" {...props} />
);

const BackpackAltFilled = (props: FilledIconProps) => (
  <BackpackAlt fill="currentColor" {...props} />
);

const BackpackFilled = (props: FilledIconProps) => (
  <Backpack fill="currentColor" {...props} />
);

const BetaFilled = (props: FilledIconProps) => (
  <Beta fill="currentColor" {...props} />
);

const BlackboardAltFilled = (props: FilledIconProps) => (
  <BlackboardAlt fill="currentColor" {...props} />
);

const BlackboardFilled = (props: FilledIconProps) => (
  <Blackboard fill="currentColor" {...props} />
);

const Book2Filled = (props: FilledIconProps) => (
  <Book2 fill="currentColor" {...props} />
);

const BookAltFilled = (props: FilledIconProps) => (
  <BookAlt fill="currentColor" {...props} />
);

const BookOpenAltFilled = (props: FilledIconProps) => (
  <BookOpenAlt fill="currentColor" {...props} />
);

const BookOpenFilled = (props: FilledIconProps) => (
  <BookOpen fill="currentColor" {...props} />
);

const BookFilled = (props: FilledIconProps) => (
  <Book fill="currentColor" {...props} />
);

const BooksAltFilled = (props: FilledIconProps) => (
  <BooksAlt fill="currentColor" {...props} />
);

const BooksFilled = (props: FilledIconProps) => (
  <Books fill="currentColor" {...props} />
);

const BracketsFilled = (props: FilledIconProps) => (
  <Brackets fill="currentColor" {...props} />
);

const CertificateAlt1Filled = (props: FilledIconProps) => (
  <CertificateAlt1 fill="currentColor" {...props} />
);

const CertificateAltFilled = (props: FilledIconProps) => (
  <CertificateAlt fill="currentColor" {...props} />
);

const CertificateFilled = (props: FilledIconProps) => (
  <Certificate fill="currentColor" {...props} />
);

const ClassroomAltFilled = (props: FilledIconProps) => (
  <ClassroomAlt fill="currentColor" {...props} />
);

const ClassroomFilled = (props: FilledIconProps) => (
  <Classroom fill="currentColor" {...props} />
);

const ClipboardFilled = (props: FilledIconProps) => (
  <Clipboard fill="currentColor" {...props} />
);

const CompassFilled = (props: FilledIconProps) => (
  <Compass fill="currentColor" {...props} />
);

const CubesFilled = (props: FilledIconProps) => (
  <Cubes fill="currentColor" {...props} />
);

const EbookFilled = (props: FilledIconProps) => (
  <Ebook fill="currentColor" {...props} />
);

const EraserAltFilled = (props: FilledIconProps) => (
  <EraserAlt fill="currentColor" {...props} />
);

const EraserFilled = (props: FilledIconProps) => (
  <Eraser fill="currentColor" {...props} />
);

const FlaskAlt1Filled = (props: FilledIconProps) => (
  <FlaskAlt1 fill="currentColor" {...props} />
);

const FlaskAlt2Filled = (props: FilledIconProps) => (
  <FlaskAlt2 fill="currentColor" {...props} />
);

const FlaskFilled = (props: FilledIconProps) => (
  <Flask fill="currentColor" {...props} />
);

const GeometryFilled = (props: FilledIconProps) => (
  <Geometry fill="currentColor" {...props} />
);

const GraduateAltFilled = (props: FilledIconProps) => (
  <GraduateAlt fill="currentColor" {...props} />
);

const GraduateFilled = (props: FilledIconProps) => (
  <Graduate fill="currentColor" {...props} />
);

const GraduationFilled = (props: FilledIconProps) => (
  <Graduation fill="currentColor" {...props} />
);

const H2oFilled = (props: FilledIconProps) => (
  <H2o fill="currentColor" {...props} />
);

const KnowledgeFilled = (props: FilledIconProps) => (
  <Knowledge fill="currentColor" {...props} />
);

const LetterblocksFilled = (props: FilledIconProps) => (
  <Letterblocks fill="currentColor" {...props} />
);

const LibraryFilled = (props: FilledIconProps) => (
  <Library fill="currentColor" {...props} />
);

const LockersFilled = (props: FilledIconProps) => (
  <Lockers fill="currentColor" {...props} />
);

const MedalAltFilled = (props: FilledIconProps) => (
  <MedalAlt fill="currentColor" {...props} />
);

const MedalFilled = (props: FilledIconProps) => (
  <Medal fill="currentColor" {...props} />
);

const MoleculeAltFilled = (props: FilledIconProps) => (
  <MoleculeAlt fill="currentColor" {...props} />
);

const MoleculeFilled = (props: FilledIconProps) => (
  <Molecule fill="currentColor" {...props} />
);

const PencilAltFilled = (props: FilledIconProps) => (
  <PencilAlt fill="currentColor" {...props} />
);

const PencilFilled = (props: FilledIconProps) => (
  <Pencil fill="currentColor" {...props} />
);

const QuillFilled = (props: FilledIconProps) => (
  <Quill fill="currentColor" {...props} />
);

const SchoolBenchAltFilled = (props: FilledIconProps) => (
  <SchoolBenchAlt fill="currentColor" {...props} />
);

const SchoolBenchFilled = (props: FilledIconProps) => (
  <SchoolBench fill="currentColor" {...props} />
);

const SchoolBusFilled = (props: FilledIconProps) => (
  <SchoolBus fill="currentColor" {...props} />
);

const SinusoidFilled = (props: FilledIconProps) => (
  <Sinusoid fill="currentColor" {...props} />
);

const SketchbookFilled = (props: FilledIconProps) => (
  <Sketchbook fill="currentColor" {...props} />
);

const TelescopeFilled = (props: FilledIconProps) => (
  <Telescope fill="currentColor" {...props} />
);

const WhiteboardAlt1Filled = (props: FilledIconProps) => (
  <WhiteboardAlt1 fill="currentColor" {...props} />
);

const WhiteboardAlt2Filled = (props: FilledIconProps) => (
  <WhiteboardAlt2 fill="currentColor" {...props} />
);

const WhiteboardAltFilled = (props: FilledIconProps) => (
  <WhiteboardAlt fill="currentColor" {...props} />
);

const WhiteboardFilled = (props: FilledIconProps) => (
  <Whiteboard fill="currentColor" {...props} />
);

const World2Filled = (props: FilledIconProps) => (
  <World2 fill="currentColor" {...props} />
);

const WorldAltFilled = (props: FilledIconProps) => (
  <WorldAlt fill="currentColor" {...props} />
);

const WorldFilled = (props: FilledIconProps) => (
  <World fill="currentColor" {...props} />
);

const WriteFilled = (props: FilledIconProps) => (
  <Write fill="currentColor" {...props} />
);

export {
  AtomFilled,
  BackpackAltFilled,
  BackpackFilled,
  BetaFilled,
  BlackboardAltFilled,
  BlackboardFilled,
  Book2Filled,
  BookAltFilled,
  BookOpenAltFilled,
  BookOpenFilled,
  BookFilled,
  BooksAltFilled,
  BooksFilled,
  BracketsFilled,
  CertificateAlt1Filled,
  CertificateAltFilled,
  CertificateFilled,
  ClassroomAltFilled,
  ClassroomFilled,
  ClipboardFilled,
  CompassFilled,
  CubesFilled,
  EbookFilled,
  EraserAltFilled,
  EraserFilled,
  FlaskAlt1Filled,
  FlaskAlt2Filled,
  FlaskFilled,
  GeometryFilled,
  GraduateAltFilled,
  GraduateFilled,
  GraduationFilled,
  H2oFilled,
  KnowledgeFilled,
  LetterblocksFilled,
  LibraryFilled,
  LockersFilled,
  MedalAltFilled,
  MedalFilled,
  MoleculeAltFilled,
  MoleculeFilled,
  PencilAltFilled,
  PencilFilled,
  QuillFilled,
  SchoolBenchAltFilled,
  SchoolBenchFilled,
  SchoolBusFilled,
  SinusoidFilled,
  SketchbookFilled,
  TelescopeFilled,
  WhiteboardAlt1Filled,
  WhiteboardAlt2Filled,
  WhiteboardAltFilled,
  WhiteboardFilled,
  World2Filled,
  WorldAltFilled,
  WorldFilled,
  WriteFilled,
};
