import {
  BarCodeSearchFilled,
  FilesFilled,
  FlowChartAltFilled,
  FriendsFilled,
  GraphFilled,
  LawBookFilled,
  SparklesFilled,
} from '@/icons';
import type { ComponentProps } from 'react';

import { cn } from '../../../../lib/utils';
import type { Card, Pipe } from './types';

function Search({
  className,
  ...props
}: ComponentProps<typeof BarCodeSearchFilled>) {
  return (
    <BarCodeSearchFilled {...props} className={cn('rotate-90', className)} />
  );
}

export const PIPE_MIN_DELAY = 0.5;

export const PIPE_MAX_DELAY = 3;

export const GRID_GAP = 8;

export const BASE_FONT_SIZE = 14;

export const CELL_SIZE = 110;

export const PIPE_RADIUS = 14;

export const CARDS: Card[] = [
  {
    icon: FlowChartAltFilled,
    type: 'input',
    label: 'Automated Enforcement',
    gridArea: 'a',
  },
  {
    icon: SparklesFilled,
    type: 'input',
    label: 'Custom Models',
    gridArea: 'b',
  },
  {
    icon: FriendsFilled,
    type: 'input',
    label: 'Moderator Console',
    gridArea: 'c',
  },
  {
    icon: FilesFilled,
    type: 'output',
    label: 'Policy Management',
    gridArea: 'd',
  },
  {
    icon: LawBookFilled,
    type: 'input',
    label: 'Compliance Toolkit',
    gridArea: 'e',
  },
  {
    icon: GraphFilled,
    type: 'output',
    label: 'Trends',
    gridArea: 'f',
  },
  {
    icon: Search,
    type: 'output',
    label: 'Investigations',
    gridArea: 'g',
  },
];

export const PIPES: Pipe[] = [
  { direction: 'up-right', gridArea: 'a' },
  { direction: 'right-down', gridArea: 'b', color: 'accent' },
  { direction: 'right-down', gridArea: 'b', spanCols: 2, color: 'accent' },
  { direction: 'right-up', gridArea: 'g', color: 'accent' },
  { direction: 'right-down', gridArea: 'c' },
  { direction: 'down-right', gridArea: 'a', spanCols: 2, spanRows: 2 },
  { direction: 'right-down', gridArea: 'a' },
  { direction: 'down', gridArea: 'f', color: 'accent', repeatType: 'reverse' },
];
