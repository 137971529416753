import { SuiteGrid } from '../../animations/landing/SuiteGrid';
import Counter from '../../Counter';
import { FeatureSection } from '../../sections/landing/FeatureSection';
import { Button } from '../../ui/Button';
import { Typography } from '../../ui/Typography';

interface StatisticProps {
  statistic: number;
  after?: string;
  before?: string;
  start?: number;
  label: string;
}

function Statistic({ statistic, start, before, after, label }: StatisticProps) {
  return (
    <div className="flex flex-col items-start min-w-32">
      <Typography
        color="primary"
        variant="display"
        className="text-3.5xl md:text-4.5xl lg:text-5.5xl text-start md:text-start w-full"
      >
        {before}
        <Counter value={statistic} start={start} />
        {after}
      </Typography>
      <Typography
        variant="body"
        color="primary"
        className="w-full ml-1 text-sm font-bold whitespace-normal md:whitespace-nowrap md:text-base text-start md:text-start"
      >
        {label}
      </Typography>
    </div>
  );
}

export function Suite(props: { showRequestDemoModal: () => void }) {
  return (
    <FeatureSection
      className="mb-8"
      label="Comprehensive & flexible"
      title="Your entire Trust & Safety workflow in one place"
      description="Choose from our suite of products and only pay for what you need. Add on new Cove products as your platform grows, and watch as each product improves the others."
      cta={
        <Button onClick={props.showRequestDemoModal}>
          Get started with Cove
        </Button>
      }
      extraGraphicAboveCta={
        <div className="flex-col items-start hidden mb-10 sm:flex sm:flex-row xl:gap-8 gap-4">
          <Statistic
            after="M+"
            statistic={100}
            label="daily content moderated"
          />

          <Statistic after="%" statistic={85} label="cost savings" />

          <Statistic
            after=" min"
            start={30}
            statistic={10}
            label="to create a custom AI model"
          />
        </div>
      }
    >
      <div className="w-full md:w-auto">
        <SuiteGrid />
      </div>
    </FeatureSection>
  );
}
