import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import type { HTMLAttributes } from 'react';

const typographyVariants = cva('m-0', {
  variants: {
    variant: {
      display: 'font-bold leading-tight',
      headline: 'font-bold leading-normal',
      title: 'font-bold leading-normal',
      body: 'leading-relaxed font-medium',
      label: 'text-base uppercase font-bold',
    },
    color: {
      default: 'text',
      primary: 'text-primary',
      subtle: 'text-subtle',
      inverted: 'text-inverted',
      muted: 'text-muted',
    },
    align: {
      left: 'text-left',
      center: 'text-center',
      right: 'text-right',
    },
    size: {
      xs: '',
      sm: '',
      md: '',
      lg: '',
    },
  },
  compoundVariants: [
    {
      variant: 'display',
      size: 'lg',
      className: 'text-[2.5rem] lg:text-[3.25rem]',
    },
    {
      variant: 'display',
      size: 'md',
      className: 'text-[2.75rem]',
    },
    {
      variant: 'display',
      size: 'sm',
      className: 'text-[2.5rem]',
    },
    {
      variant: 'headline',
      size: 'lg',
      className: 'text-2xl lg:text-[2rem]',
    },
    {
      variant: 'headline',
      size: 'md',
      className: 'text-2xl',
    },
    {
      variant: 'headline',
      size: 'sm',
      className: 'text-lg',
    },
    {
      variant: 'title',
      size: 'lg',
      className: 'text-xl',
    },
    {
      variant: 'title',
      size: 'md',
      className: 'text-lg',
    },
    {
      variant: 'title',
      size: 'sm',
      className: 'text-base',
    },
    {
      variant: 'title',
      size: 'xs',
      className: 'text-sm',
    },
    {
      variant: 'body',
      size: 'lg',
      className: 'text-base lg:text-lg',
    },
    {
      variant: 'body',
      size: 'md',
      className: 'text-base',
    },
    {
      variant: 'body',
      size: 'sm',
      className: 'text-sm',
    },
    {
      variant: 'body',
      size: 'xs',
      className: 'text-xs',
    },
  ],
  defaultVariants: {
    variant: 'body',
    color: 'default',
    align: 'left',
    size: 'md',
  },
});

type TypographyElement =
  | HTMLHeadingElement
  | HTMLParagraphElement
  | HTMLSpanElement;

type TypographyTag =
  | 'div'
  | 'p'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'span';

export interface TypographyProps
  extends Omit<HTMLAttributes<TypographyElement>, 'color'>,
    VariantProps<typeof typographyVariants> {
  as?: TypographyTag;
  asChild?: boolean;
}

export function Typography({
  variant,
  as,
  asChild,
  align,
  size,
  color,
  className,
  dangerouslySetInnerHTML,
  children,
}: TypographyProps) {
  let tagName = as ?? 'p';

  if (!as) {
    switch (variant) {
      case 'title':
        tagName = 'h3';
        break;
      case 'headline':
        tagName = 'h2';
        break;
      case 'display':
        tagName = 'h1';
        break;
      default:
        break;
    }
  }

  const Comp = asChild ? Slot : tagName;

  return (
    <Comp
      className={typographyVariants({
        color,
        align,
        size,
        variant,
        className,
      })}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </Comp>
  );
}
