import { m } from 'framer-motion';
import type { HTMLAttributes } from 'react';

import { cn } from '../../lib/utils';

interface HorizontalDashesProps extends HTMLAttributes<HTMLDivElement> {
  width?: number | string;
}

export function HorizontalDashes({ width, className }: HorizontalDashesProps) {
  return (
    <m.div
      initial={{ width: 0 }}
      animate={{
        width: width ?? '100%',
        transition: {
          duration: 2,
        },
      }}
      className={cn(
        'h-px',
        "[&]:[background-image:linear-gradient(90deg,transparent_50%,theme('colors.primary')_50%)]",
        '[&]:[background-size:8px_100%]',
        className,
      )}
    ></m.div>
  );
}
