import { PIPES } from "./constants";
import { Pipe } from "./Pipe";

export function PipeGrid() {
  return (
    <div
      className="grid absolute gap-[var(--gap)] inset-0 grid-cols-4 grid-rows-4"
      style={{
        gridTemplateAreas: `
  '. b . .'
  'a . d f'
  '. c . g'
  '. . e .'
        `,
      }}
    >
      {PIPES.map(({ gridArea, ...props }) => (
        <Pipe
          {...props}
          style={{ gridArea }}
          key={JSON.stringify({ ...props, gridArea })}
        />
      ))}

      {PIPES.map(({ gridArea, ...props }) => (
        <Pipe
          {...props}
          animated
          style={{ gridArea }}
          key={JSON.stringify({ ...props, gridArea })}
        />
      ))}
    </div>
  );
}
