import { m, type Variants } from "framer-motion";

const lineVariants: Variants = {
  hidden: {
    pathLength: 0,
  },
  visible: {
    pathLength: 1,
    transition: {
      duration: 1.2,
      delay: 0.75,
      type: "spring",
      damping: 70,
      stiffness: 200,
    },
  },
};

interface GraphLineProps {
  animate: boolean;
}

export function GraphLine({ animate }: GraphLineProps) {
  return (
    <m.path
      variants={lineVariants}
      initial="hidden"
      animate={animate ? "visible" : "hidden"}
      stroke="#51ABFC"
      strokeWidth="2"
      d="M97 118.793s94.362 22.125 157.27 73.749c62.908 51.624 94.362 184.372 157.27 184.372 62.908 0 94.362-54.697 157.27-101.405 62.908-46.708 157.27-132.133 157.27-132.133"
    />
  );
}
