import type { FilledIconProps } from '../types';
import { ReactComponent as AirFlow } from './air-flow.svg';
import { ReactComponent as Air } from './air.svg';
import { ReactComponent as CelsiusAlt } from './celsius-alt.svg';
import { ReactComponent as CloudAlt1 } from './cloud-alt-1.svg';
import { ReactComponent as CloudAlt2 } from './cloud-alt-2.svg';
import { ReactComponent as CloudAlt } from './cloud-alt.svg';
import { ReactComponent as CloudMoon1 } from './cloud-moon-1.svg';
import { ReactComponent as CloudMoon } from './cloud-moon.svg';
import { ReactComponent as CloudSun } from './cloud-sun.svg';
import { ReactComponent as Cloud } from './cloud.svg';
import { ReactComponent as Clouds } from './clouds.svg';
import { ReactComponent as Cloudy } from './cloudy.svg';
import { ReactComponent as Crescent } from './crescent.svg';
import { ReactComponent as Drop } from './drop.svg';
import { ReactComponent as FarenheitAlt } from './farenheit-alt.svg';
import { ReactComponent as Farenheit } from './farenheit.svg';
import { ReactComponent as Fire } from './Fire.svg';
import { ReactComponent as HumidityAlt } from './humidity-alt.svg';
import { ReactComponent as Icicles } from './icicles.svg';
import { ReactComponent as KelvinAlt } from './kelvin-alt.svg';
import { ReactComponent as Kelvin } from './kelvin.svg';
import { ReactComponent as Mist } from './mist.svg';
import { ReactComponent as MoonAlt } from './moon-alt.svg';
import { ReactComponent as MoonStar } from './moon-star.svg';
import { ReactComponent as Moon } from './moon.svg';
import { ReactComponent as NewMoon } from './new-moon.svg';
import { ReactComponent as Night } from './night.svg';
import { ReactComponent as QuarterMoon1 } from './quarter-moon-1.svg';
import { ReactComponent as QuarterMoon } from './quarter-moon.svg';
import { ReactComponent as Radioactive } from './radioactive.svg';
import { ReactComponent as Rain } from './rain.svg';
import { ReactComponent as Rainbow } from './rainbow.svg';
import { ReactComponent as Snowfall1 } from './snowfall-1.svg';
import { ReactComponent as Snowfall } from './snowfall.svg';
import { ReactComponent as Sparkles } from './sparkles.svg';
import { ReactComponent as SunRain } from './sun-rain.svg';
import { ReactComponent as SunriseAlt1 } from './sunrise-alt-1.svg';
import { ReactComponent as SunriseAlt2 } from './sunrise-alt-2.svg';
import { ReactComponent as SunriseAlt } from './sunrise-alt.svg';
import { ReactComponent as SunsetAlt } from './sunset-alt.svg';
import { ReactComponent as ThunderAlt } from './thunder-alt.svg';
import { ReactComponent as Thunder } from './thunder.svg';
import { ReactComponent as TideAlt } from './tide-alt.svg';
import { ReactComponent as Tide } from './tide.svg';
import { ReactComponent as UmbrellaRainAlt } from './umbrella-rain-alt.svg';
import { ReactComponent as UmbrellaRain } from './umbrella-rain.svg';
import { ReactComponent as WaterDrops } from './water-drops.svg';
import { ReactComponent as WindMoon1 } from './wind-moon-1.svg';
import { ReactComponent as WindMoon } from './wind-moon.svg';
import { ReactComponent as WindRose } from './wind-rose.svg';
import { ReactComponent as WindSun1 } from './wind-sun-1.svg';
import { ReactComponent as WindSun } from './wind-sun.svg';

const FireFilled = (props: FilledIconProps) => (
  <Fire fill="currentColor" {...props} />
);

const AirFlowFilled = (props: FilledIconProps) => (
  <AirFlow fill="currentColor" {...props} />
);

const AirFilled = (props: FilledIconProps) => (
  <Air fill="currentColor" {...props} />
);

const CelsiusAltFilled = (props: FilledIconProps) => (
  <CelsiusAlt fill="currentColor" {...props} />
);

const CloudAlt1Filled = (props: FilledIconProps) => (
  <CloudAlt1 fill="currentColor" {...props} />
);

const CloudAlt2Filled = (props: FilledIconProps) => (
  <CloudAlt2 fill="currentColor" {...props} />
);

const CloudAltFilled = (props: FilledIconProps) => (
  <CloudAlt fill="currentColor" {...props} />
);

const CloudMoon1Filled = (props: FilledIconProps) => (
  <CloudMoon1 fill="currentColor" {...props} />
);

const CloudMoonFilled = (props: FilledIconProps) => (
  <CloudMoon fill="currentColor" {...props} />
);

const CloudSunFilled = (props: FilledIconProps) => (
  <CloudSun fill="currentColor" {...props} />
);

const CloudFilled = (props: FilledIconProps) => (
  <Cloud fill="currentColor" {...props} />
);

const CloudsFilled = (props: FilledIconProps) => (
  <Clouds fill="currentColor" {...props} />
);

const CloudyFilled = (props: FilledIconProps) => (
  <Cloudy fill="currentColor" {...props} />
);

const CrescentFilled = (props: FilledIconProps) => (
  <Crescent fill="currentColor" {...props} />
);

const DropFilled = (props: FilledIconProps) => (
  <Drop fill="currentColor" {...props} />
);

const FarenheitAltFilled = (props: FilledIconProps) => (
  <FarenheitAlt fill="currentColor" {...props} />
);

const FarenheitFilled = (props: FilledIconProps) => (
  <Farenheit fill="currentColor" {...props} />
);

const HumidityAltFilled = (props: FilledIconProps) => (
  <HumidityAlt fill="currentColor" {...props} />
);

const IciclesFilled = (props: FilledIconProps) => (
  <Icicles fill="currentColor" {...props} />
);

const KelvinAltFilled = (props: FilledIconProps) => (
  <KelvinAlt fill="currentColor" {...props} />
);

const KelvinFilled = (props: FilledIconProps) => (
  <Kelvin fill="currentColor" {...props} />
);

const MistFilled = (props: FilledIconProps) => (
  <Mist fill="currentColor" {...props} />
);

const MoonAltFilled = (props: FilledIconProps) => (
  <MoonAlt fill="currentColor" {...props} />
);

const MoonStarFilled = (props: FilledIconProps) => (
  <MoonStar fill="currentColor" {...props} />
);

const MoonFilled = (props: FilledIconProps) => (
  <Moon fill="currentColor" {...props} />
);

const NewMoonFilled = (props: FilledIconProps) => (
  <NewMoon fill="currentColor" {...props} />
);

const NightFilled = (props: FilledIconProps) => (
  <Night fill="currentColor" {...props} />
);

const QuarterMoon1Filled = (props: FilledIconProps) => (
  <QuarterMoon1 fill="currentColor" {...props} />
);

const QuarterMoonFilled = (props: FilledIconProps) => (
  <QuarterMoon fill="currentColor" {...props} />
);

const RadioactiveFilled = (props: FilledIconProps) => (
  <Radioactive fill="currentColor" {...props} />
);

const RainFilled = (props: FilledIconProps) => (
  <Rain fill="currentColor" {...props} />
);

const RainbowFilled = (props: FilledIconProps) => (
  <Rainbow fill="currentColor" {...props} />
);

const Snowfall1Filled = (props: FilledIconProps) => (
  <Snowfall1 fill="currentColor" {...props} />
);

const SnowfallFilled = (props: FilledIconProps) => (
  <Snowfall fill="currentColor" {...props} />
);

const SparklesFilled = (props: FilledIconProps) => (
  <Sparkles fill="currentColor" {...props} />
);

const SunRainFilled = (props: FilledIconProps) => (
  <SunRain fill="currentColor" {...props} />
);

const SunriseAlt1Filled = (props: FilledIconProps) => (
  <SunriseAlt1 fill="currentColor" {...props} />
);

const SunriseAlt2Filled = (props: FilledIconProps) => (
  <SunriseAlt2 fill="currentColor" {...props} />
);

const SunriseAltFilled = (props: FilledIconProps) => (
  <SunriseAlt fill="currentColor" {...props} />
);

const SunsetAltFilled = (props: FilledIconProps) => (
  <SunsetAlt fill="currentColor" {...props} />
);

const ThunderAltFilled = (props: FilledIconProps) => (
  <ThunderAlt fill="currentColor" {...props} />
);

const ThunderFilled = (props: FilledIconProps) => (
  <Thunder fill="currentColor" {...props} />
);

const TideAltFilled = (props: FilledIconProps) => (
  <TideAlt fill="currentColor" {...props} />
);

const TideFilled = (props: FilledIconProps) => (
  <Tide fill="currentColor" {...props} />
);

const UmbrellaRainAltFilled = (props: FilledIconProps) => (
  <UmbrellaRainAlt fill="currentColor" {...props} />
);

const UmbrellaRainFilled = (props: FilledIconProps) => (
  <UmbrellaRain fill="currentColor" {...props} />
);

const WaterDropsFilled = (props: FilledIconProps) => (
  <WaterDrops fill="currentColor" {...props} />
);

const WindMoon1Filled = (props: FilledIconProps) => (
  <WindMoon1 fill="currentColor" {...props} />
);

const WindMoonFilled = (props: FilledIconProps) => (
  <WindMoon fill="currentColor" {...props} />
);

const WindRoseFilled = (props: FilledIconProps) => (
  <WindRose fill="currentColor" {...props} />
);

const WindSun1Filled = (props: FilledIconProps) => (
  <WindSun1 fill="currentColor" {...props} />
);

const WindSunFilled = (props: FilledIconProps) => (
  <WindSun fill="currentColor" {...props} />
);

export {
  FireFilled,
  AirFlowFilled,
  AirFilled,
  CelsiusAltFilled,
  CloudAlt1Filled,
  CloudAlt2Filled,
  CloudAltFilled,
  CloudMoon1Filled,
  CloudMoonFilled,
  CloudSunFilled,
  CloudFilled,
  CloudsFilled,
  CloudyFilled,
  CrescentFilled,
  DropFilled,
  FarenheitAltFilled,
  FarenheitFilled,
  HumidityAltFilled,
  IciclesFilled,
  KelvinAltFilled,
  KelvinFilled,
  MistFilled,
  MoonAltFilled,
  MoonStarFilled,
  MoonFilled,
  NewMoonFilled,
  NightFilled,
  QuarterMoon1Filled,
  QuarterMoonFilled,
  RadioactiveFilled,
  RainFilled,
  RainbowFilled,
  Snowfall1Filled,
  SnowfallFilled,
  SparklesFilled,
  SunRainFilled,
  SunriseAlt1Filled,
  SunriseAlt2Filled,
  SunriseAltFilled,
  SunsetAltFilled,
  ThunderAltFilled,
  ThunderFilled,
  TideAltFilled,
  TideFilled,
  UmbrellaRainAltFilled,
  UmbrellaRainFilled,
  WaterDropsFilled,
  WindMoon1Filled,
  WindMoonFilled,
  WindRoseFilled,
  WindSun1Filled,
  WindSunFilled,
};
