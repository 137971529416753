import { m, type Variants } from 'framer-motion';

const variants: Variants = {
  hidden: {
    scale: 0.95,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.5,
      staggerChildren: 0.1,
    },
  },
};

export function Content() {
  return (
    <m.div
      variants={variants}
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.5, once: true }}
      className="grid grid-cols-3 lg:translate-x-10 items-center gap-4 [background-image:radial-gradient(closest-side,theme('colors.primary/0.15'),transparent)]"
    >
      <div className="flex flex-col items-center justify-center flex-1 gap-4">
        <m.img
          variants={variants}
          className="w-full rounded-lg2"
          src="/graphics/card1.webp"
        />
        <m.img
          variants={variants}
          className="w-full rounded-lg2"
          src="/graphics/card2.webp"
        />
      </div>
      <div className="flex flex-col items-center justify-center flex-1 gap-4">
        <m.img
          variants={variants}
          className="w-full rounded-lg2"
          src="/graphics/card3.webp"
        />
        <m.img
          variants={variants}
          className="w-full rounded-lg2"
          src="/graphics/card4.webp"
        />
      </div>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img className="w-full rounded-lg2" src="/graphics/card5.webp" />
    </m.div>
  );
}
