import type { FilledIconProps } from '../types';
import { ReactComponent as AddAnchorPoint } from './add-anchor-point.svg';
import { ReactComponent as AlignLeft1 } from './align-left-1.svg';
import { ReactComponent as AlignLeft2 } from './align-left-2.svg';
import { ReactComponent as AlignLeft3 } from './align-left-3.svg';
import { ReactComponent as AlignLeft } from './align-left.svg';
import { ReactComponent as AlignRight1 } from './align-right-1.svg';
import { ReactComponent as AlignRight2 } from './align-right-2.svg';
import { ReactComponent as AlignRight } from './align-right.svg';
import { ReactComponent as Arc } from './arc.svg';
import { ReactComponent as BookFont } from './book-font.svg';
import { ReactComponent as Bottom1 } from './bottom-1.svg';
import { ReactComponent as Bottom } from './bottom.svg';
import { ReactComponent as BrushAlt } from './brush-alt.svg';
import { ReactComponent as Brush } from './brush.svg';
import { ReactComponent as CenterAlign1 } from './center-align-1.svg';
import { ReactComponent as CenterAlign2 } from './center-align-2.svg';
import { ReactComponent as CenterAlign3 } from './center-align-3.svg';
import { ReactComponent as CenterAlign4 } from './center-align-4.svg';
import { ReactComponent as CenterAlign5 } from './center-align-5.svg';
import { ReactComponent as CenterAlign6 } from './center-align-6.svg';
import { ReactComponent as CenterAlign } from './center-align.svg';
import { ReactComponent as CircleDashed } from './circle-dashed.svg';
import { ReactComponent as CloudSlash } from './cloud-slash.svg';
import { ReactComponent as ColorPalette } from './color-palette.svg';
import { ReactComponent as Columns1 } from './columns-1.svg';
import { ReactComponent as Columns2 } from './columns-2.svg';
import { ReactComponent as Columns11 } from './columns-11.svg';
import { ReactComponent as Columns } from './columns.svg';
import { ReactComponent as Corner } from './corner.svg';
import { ReactComponent as Corners } from './corners.svg';
import { ReactComponent as Crop } from './crop.svg';
import { ReactComponent as DeleteAnchorPoint } from './delete-anchor-point.svg';
import { ReactComponent as DownAlign1 } from './down-align-1.svg';
import { ReactComponent as DownAlign2 } from './down-align-2.svg';
import { ReactComponent as DownAlign3 } from './down-align-3.svg';
import { ReactComponent as DownAlign } from './down-align.svg';
import { ReactComponent as Ellipse } from './ellipse.svg';
import { ReactComponent as EyeSlash } from './eye-slash.svg';
import { ReactComponent as Eye } from './eye.svg';
import { ReactComponent as FreeTransform } from './free-transform.svg';
import { ReactComponent as Grid1 } from './grid-1.svg';
import { ReactComponent as Grid2Plus } from './grid-2-plus.svg';
import { ReactComponent as GridAlt1 } from './grid-alt-1.svg';
import { ReactComponent as GridAlt } from './grid-alt.svg';
import { ReactComponent as Grid } from './grid.svg';
import { ReactComponent as Header1 } from './header-1.svg';
import { ReactComponent as Header } from './header.svg';
import { ReactComponent as HighlightAlt } from './highlight-alt.svg';
import { ReactComponent as Highlight } from './highlight.svg';
import { ReactComponent as ImageCrop } from './image-crop.svg';
import { ReactComponent as InnerAlign } from './inner-align.svg';
import { ReactComponent as Intersection } from './intersection.svg';
import { ReactComponent as LayersAlt1 } from './layers-alt-1.svg';
import { ReactComponent as LayersAlt } from './layers-alt.svg';
import { ReactComponent as Layers } from './layers.svg';
import { ReactComponent as Layout1 } from './layout-1.svg';
import { ReactComponent as Layout2 } from './layout-2.svg';
import { ReactComponent as Layout3 } from './layout-3.svg';
import { ReactComponent as LayoutAlt1 } from './layout-alt-1.svg';
import { ReactComponent as LayoutAlt2 } from './layout-alt-2.svg';
import { ReactComponent as LayoutAlt } from './layout-alt.svg';
import { ReactComponent as Layout } from './layout.svg';
import { ReactComponent as MagicWand } from './magic-wand.svg';
import { ReactComponent as Margin } from './margin.svg';
import { ReactComponent as MinusFront } from './minus-front.svg';
import { ReactComponent as Move } from './move.svg';
import { ReactComponent as NoBorder } from './no-border.svg';
import { ReactComponent as OuterAlign } from './outer-align.svg';
import { ReactComponent as Pallet } from './pallet.svg';
import { ReactComponent as Panel1 } from './panel-1.svg';
import { ReactComponent as Panel2 } from './panel-2.svg';
import { ReactComponent as Panel3 } from './panel-3.svg';
import { ReactComponent as Panel4 } from './panel-4.svg';
import { ReactComponent as Panel5 } from './panel-5.svg';
import { ReactComponent as Panel } from './panel.svg';
import { ReactComponent as Poligon } from './poligon.svg';
import { ReactComponent as Resize } from './resize.svg';
import { ReactComponent as RightAlign } from './right-align.svg';
import { ReactComponent as RotateAlt } from './rotate-alt.svg';
import { ReactComponent as Rotate } from './rotate.svg';
import { ReactComponent as RoundedRectangle } from './rounded-rectangle.svg';
import { ReactComponent as Rows1 } from './rows-1.svg';
import { ReactComponent as Rows2 } from './rows-2.svg';
import { ReactComponent as Rows } from './rows.svg';
import { ReactComponent as Scaling } from './scaling.svg';
import { ReactComponent as SelectCursor } from './select-cursor.svg';
import { ReactComponent as ShapeBuilderTool } from './shape-builder-tool.svg';
import { ReactComponent as Sidebar1 } from './sidebar-1.svg';
import { ReactComponent as Sidebar } from './sidebar.svg';
import { ReactComponent as Size } from './size.svg';
import { ReactComponent as Slice } from './slice.svg';
import { ReactComponent as SpiralTool } from './spiral-tool.svg';
import { ReactComponent as Transform } from './transform.svg';
import { ReactComponent as UpAlign1 } from './up-align-1.svg';
import { ReactComponent as UpAlign2 } from './up-align-2.svg';
import { ReactComponent as UpAlign3 } from './up-align-3.svg';
import { ReactComponent as UpAlign } from './up-align.svg';
import { ReactComponent as Ux } from './ux.svg';
import { ReactComponent as VectorAlt } from './vector-alt.svg';
import { ReactComponent as VectorPen } from './vector-pen.svg';
import { ReactComponent as Vector } from './vector.svg';
import { ReactComponent as Widget1 } from './widget-1.svg';
import { ReactComponent as Widget2 } from './widget-2.svg';
import { ReactComponent as Widget3 } from './widget-3.svg';
import { ReactComponent as Widget4 } from './widget-4.svg';
import { ReactComponent as Widget5 } from './widget-5.svg';
import { ReactComponent as Widget } from './widget.svg';

const AddAnchorPointFilled = (props: FilledIconProps) => (
  <AddAnchorPoint fill="currentColor" {...props} />
);

const AlignLeft1Filled = (props: FilledIconProps) => (
  <AlignLeft1 fill="currentColor" {...props} />
);

const AlignLeft2Filled = (props: FilledIconProps) => (
  <AlignLeft2 fill="currentColor" {...props} />
);

const AlignLeft3Filled = (props: FilledIconProps) => (
  <AlignLeft3 fill="currentColor" {...props} />
);

const AlignLeftFilled = (props: FilledIconProps) => (
  <AlignLeft fill="currentColor" {...props} />
);

const AlignRight1Filled = (props: FilledIconProps) => (
  <AlignRight1 fill="currentColor" {...props} />
);

const AlignRight2Filled = (props: FilledIconProps) => (
  <AlignRight2 fill="currentColor" {...props} />
);

const AlignRightFilled = (props: FilledIconProps) => (
  <AlignRight fill="currentColor" {...props} />
);

const ArcFilled = (props: FilledIconProps) => (
  <Arc fill="currentColor" {...props} />
);

const BookFontFilled = (props: FilledIconProps) => (
  <BookFont fill="currentColor" {...props} />
);

const Bottom1Filled = (props: FilledIconProps) => (
  <Bottom1 fill="currentColor" {...props} />
);

const BottomFilled = (props: FilledIconProps) => (
  <Bottom fill="currentColor" {...props} />
);

const BrushAltFilled = (props: FilledIconProps) => (
  <BrushAlt fill="currentColor" {...props} />
);

const BrushFilled = (props: FilledIconProps) => (
  <Brush fill="currentColor" {...props} />
);

const CenterAlign1Filled = (props: FilledIconProps) => (
  <CenterAlign1 fill="currentColor" {...props} />
);

const CenterAlign2Filled = (props: FilledIconProps) => (
  <CenterAlign2 fill="currentColor" {...props} />
);

const CenterAlign3Filled = (props: FilledIconProps) => (
  <CenterAlign3 fill="currentColor" {...props} />
);

const CenterAlign4Filled = (props: FilledIconProps) => (
  <CenterAlign4 fill="currentColor" {...props} />
);

const CenterAlign5Filled = (props: FilledIconProps) => (
  <CenterAlign5 fill="currentColor" {...props} />
);

const CenterAlign6Filled = (props: FilledIconProps) => (
  <CenterAlign6 fill="currentColor" {...props} />
);

const CenterAlignFilled = (props: FilledIconProps) => (
  <CenterAlign fill="currentColor" {...props} />
);

const CircleDashedFilled = (props: FilledIconProps) => (
  <CircleDashed fill="currentColor" {...props} />
);

const CloudSlashFilled = (props: FilledIconProps) => (
  <CloudSlash fill="currentColor" {...props} />
);

const ColorPaletteFilled = (props: FilledIconProps) => (
  <ColorPalette fill="currentColor" {...props} />
);

const Columns1Filled = (props: FilledIconProps) => (
  <Columns1 fill="currentColor" {...props} />
);

const Columns11Filled = (props: FilledIconProps) => (
  <Columns11 fill="currentColor" {...props} />
);

const Columns2Filled = (props: FilledIconProps) => (
  <Columns2 fill="currentColor" {...props} />
);

const ColumnsFilled = (props: FilledIconProps) => (
  <Columns fill="currentColor" {...props} />
);

const CornerFilled = (props: FilledIconProps) => (
  <Corner fill="currentColor" {...props} />
);

const CornersFilled = (props: FilledIconProps) => (
  <Corners fill="currentColor" {...props} />
);

const CropFilled = (props: FilledIconProps) => (
  <Crop fill="currentColor" {...props} />
);

const DeleteAnchorPointFilled = (props: FilledIconProps) => (
  <DeleteAnchorPoint fill="currentColor" {...props} />
);

const DownAlign1Filled = (props: FilledIconProps) => (
  <DownAlign1 fill="currentColor" {...props} />
);

const DownAlign2Filled = (props: FilledIconProps) => (
  <DownAlign2 fill="currentColor" {...props} />
);

const DownAlign3Filled = (props: FilledIconProps) => (
  <DownAlign3 fill="currentColor" {...props} />
);

const DownAlignFilled = (props: FilledIconProps) => (
  <DownAlign fill="currentColor" {...props} />
);

const EllipseFilled = (props: FilledIconProps) => (
  <Ellipse fill="currentColor" {...props} />
);

const EyeSlashFilled = (props: FilledIconProps) => (
  <EyeSlash fill="currentColor" {...props} />
);

const EyeFilled = (props: FilledIconProps) => (
  <Eye fill="currentColor" {...props} />
);

const FreeTransformFilled = (props: FilledIconProps) => (
  <FreeTransform fill="currentColor" {...props} />
);

const Grid1Filled = (props: FilledIconProps) => (
  <Grid1 fill="currentColor" {...props} />
);

const Grid2PlusFilled = (props: FilledIconProps) => (
  <Grid2Plus fill="currentColor" {...props} />
);

const GridAlt1Filled = (props: FilledIconProps) => (
  <GridAlt1 fill="currentColor" {...props} />
);

const GridAltFilled = (props: FilledIconProps) => (
  <GridAlt fill="currentColor" {...props} />
);

const GridFilled = (props: FilledIconProps) => (
  <Grid fill="currentColor" {...props} />
);

const Header1Filled = (props: FilledIconProps) => (
  <Header1 fill="currentColor" {...props} />
);

const HeaderFilled = (props: FilledIconProps) => (
  <Header fill="currentColor" {...props} />
);

const HighlightAltFilled = (props: FilledIconProps) => (
  <HighlightAlt fill="currentColor" {...props} />
);

const HighlightFilled = (props: FilledIconProps) => (
  <Highlight fill="currentColor" {...props} />
);

const ImageCropFilled = (props: FilledIconProps) => (
  <ImageCrop fill="currentColor" {...props} />
);

const InnerAlignFilled = (props: FilledIconProps) => (
  <InnerAlign fill="currentColor" {...props} />
);

const IntersectionFilled = (props: FilledIconProps) => (
  <Intersection fill="currentColor" {...props} />
);

const LayersAlt1Filled = (props: FilledIconProps) => (
  <LayersAlt1 fill="currentColor" {...props} />
);

const LayersAltFilled = (props: FilledIconProps) => (
  <LayersAlt fill="currentColor" {...props} />
);

const LayersFilled = (props: FilledIconProps) => (
  <Layers fill="currentColor" {...props} />
);

const Layout1Filled = (props: FilledIconProps) => (
  <Layout1 fill="currentColor" {...props} />
);

const Layout2Filled = (props: FilledIconProps) => (
  <Layout2 fill="currentColor" {...props} />
);

const Layout3Filled = (props: FilledIconProps) => (
  <Layout3 fill="currentColor" {...props} />
);

const LayoutAlt1Filled = (props: FilledIconProps) => (
  <LayoutAlt1 fill="currentColor" {...props} />
);

const LayoutAlt2Filled = (props: FilledIconProps) => (
  <LayoutAlt2 fill="currentColor" {...props} />
);

const LayoutAltFilled = (props: FilledIconProps) => (
  <LayoutAlt fill="currentColor" {...props} />
);

const LayoutFilled = (props: FilledIconProps) => (
  <Layout fill="currentColor" {...props} />
);

const MagicWandFilled = (props: FilledIconProps) => (
  <MagicWand fill="currentColor" {...props} />
);

const MarginFilled = (props: FilledIconProps) => (
  <Margin fill="currentColor" {...props} />
);

const MinusFrontFilled = (props: FilledIconProps) => (
  <MinusFront fill="currentColor" {...props} />
);

const MoveFilled = (props: FilledIconProps) => (
  <Move fill="currentColor" {...props} />
);

const NoBorderFilled = (props: FilledIconProps) => (
  <NoBorder fill="currentColor" {...props} />
);

const OuterAlignFilled = (props: FilledIconProps) => (
  <OuterAlign fill="currentColor" {...props} />
);

const PalletFilled = (props: FilledIconProps) => (
  <Pallet fill="currentColor" {...props} />
);

const Panel1Filled = (props: FilledIconProps) => (
  <Panel1 fill="currentColor" {...props} />
);

const Panel2Filled = (props: FilledIconProps) => (
  <Panel2 fill="currentColor" {...props} />
);

const Panel3Filled = (props: FilledIconProps) => (
  <Panel3 fill="currentColor" {...props} />
);

const Panel4Filled = (props: FilledIconProps) => (
  <Panel4 fill="currentColor" {...props} />
);

const Panel5Filled = (props: FilledIconProps) => (
  <Panel5 fill="currentColor" {...props} />
);

const PanelFilled = (props: FilledIconProps) => (
  <Panel fill="currentColor" {...props} />
);

const PoligonFilled = (props: FilledIconProps) => (
  <Poligon fill="currentColor" {...props} />
);

const ResizeFilled = (props: FilledIconProps) => (
  <Resize fill="currentColor" {...props} />
);

const RightAlignFilled = (props: FilledIconProps) => (
  <RightAlign fill="currentColor" {...props} />
);

const RotateAltFilled = (props: FilledIconProps) => (
  <RotateAlt fill="currentColor" {...props} />
);

const RotateFilled = (props: FilledIconProps) => (
  <Rotate fill="currentColor" {...props} />
);

const RoundedRectangleFilled = (props: FilledIconProps) => (
  <RoundedRectangle fill="currentColor" {...props} />
);

const Rows1Filled = (props: FilledIconProps) => (
  <Rows1 fill="currentColor" {...props} />
);

const Rows2Filled = (props: FilledIconProps) => (
  <Rows2 fill="currentColor" {...props} />
);

const RowsFilled = (props: FilledIconProps) => (
  <Rows fill="currentColor" {...props} />
);

const ScalingFilled = (props: FilledIconProps) => (
  <Scaling fill="currentColor" {...props} />
);

const SelectCursorFilled = (props: FilledIconProps) => (
  <SelectCursor fill="currentColor" {...props} />
);

const ShapeBuilderToolFilled = (props: FilledIconProps) => (
  <ShapeBuilderTool fill="currentColor" {...props} />
);

const Sidebar1Filled = (props: FilledIconProps) => (
  <Sidebar1 fill="currentColor" {...props} />
);

const SidebarFilled = (props: FilledIconProps) => (
  <Sidebar fill="currentColor" {...props} />
);

const SizeFilled = (props: FilledIconProps) => (
  <Size fill="currentColor" {...props} />
);

const SliceFilled = (props: FilledIconProps) => (
  <Slice fill="currentColor" {...props} />
);

const SpiralToolFilled = (props: FilledIconProps) => (
  <SpiralTool fill="currentColor" {...props} />
);

const TransformFilled = (props: FilledIconProps) => (
  <Transform fill="currentColor" {...props} />
);

const UpAlign1Filled = (props: FilledIconProps) => (
  <UpAlign1 fill="currentColor" {...props} />
);

const UpAlign2Filled = (props: FilledIconProps) => (
  <UpAlign2 fill="currentColor" {...props} />
);

const UpAlign3Filled = (props: FilledIconProps) => (
  <UpAlign3 fill="currentColor" {...props} />
);

const UpAlignFilled = (props: FilledIconProps) => (
  <UpAlign fill="currentColor" {...props} />
);

const UxFilled = (props: FilledIconProps) => (
  <Ux fill="currentColor" {...props} />
);

const VectorAltFilled = (props: FilledIconProps) => (
  <VectorAlt fill="currentColor" {...props} />
);

const VectorPenFilled = (props: FilledIconProps) => (
  <VectorPen fill="currentColor" {...props} />
);

const VectorFilled = (props: FilledIconProps) => (
  <Vector fill="currentColor" {...props} />
);

const Widget1Filled = (props: FilledIconProps) => (
  <Widget1 fill="currentColor" {...props} />
);

const Widget2Filled = (props: FilledIconProps) => (
  <Widget2 fill="currentColor" {...props} />
);

const Widget3Filled = (props: FilledIconProps) => (
  <Widget3 fill="currentColor" {...props} />
);

const Widget4Filled = (props: FilledIconProps) => (
  <Widget4 fill="currentColor" {...props} />
);

const Widget5Filled = (props: FilledIconProps) => (
  <Widget5 fill="currentColor" {...props} />
);

const WidgetFilled = (props: FilledIconProps) => (
  <Widget fill="currentColor" {...props} />
);

export {
  AddAnchorPointFilled,
  AlignLeft1Filled,
  AlignLeft2Filled,
  AlignLeft3Filled,
  AlignLeftFilled,
  AlignRight1Filled,
  AlignRight2Filled,
  AlignRightFilled,
  ArcFilled,
  BookFontFilled,
  Bottom1Filled,
  BottomFilled,
  BrushAltFilled,
  BrushFilled,
  CenterAlign1Filled,
  CenterAlign2Filled,
  CenterAlign3Filled,
  CenterAlign4Filled,
  CenterAlign5Filled,
  CenterAlign6Filled,
  CenterAlignFilled,
  CircleDashedFilled,
  CloudSlashFilled,
  ColorPaletteFilled,
  Columns1Filled,
  Columns11Filled,
  Columns2Filled,
  ColumnsFilled,
  CornerFilled,
  CornersFilled,
  CropFilled,
  DeleteAnchorPointFilled,
  DownAlign1Filled,
  DownAlign2Filled,
  DownAlign3Filled,
  DownAlignFilled,
  EllipseFilled,
  EyeSlashFilled,
  EyeFilled,
  FreeTransformFilled,
  Grid1Filled,
  Grid2PlusFilled,
  GridAlt1Filled,
  GridAltFilled,
  GridFilled,
  Header1Filled,
  HeaderFilled,
  HighlightAltFilled,
  HighlightFilled,
  ImageCropFilled,
  InnerAlignFilled,
  IntersectionFilled,
  LayersAlt1Filled,
  LayersAltFilled,
  LayersFilled,
  Layout1Filled,
  Layout2Filled,
  Layout3Filled,
  LayoutAlt1Filled,
  LayoutAlt2Filled,
  LayoutAltFilled,
  LayoutFilled,
  MagicWandFilled,
  MarginFilled,
  MinusFrontFilled,
  MoveFilled,
  NoBorderFilled,
  OuterAlignFilled,
  PalletFilled,
  Panel1Filled,
  Panel2Filled,
  Panel3Filled,
  Panel4Filled,
  Panel5Filled,
  PanelFilled,
  PoligonFilled,
  ResizeFilled,
  RightAlignFilled,
  RotateAltFilled,
  RotateFilled,
  RoundedRectangleFilled,
  Rows1Filled,
  Rows2Filled,
  RowsFilled,
  ScalingFilled,
  SelectCursorFilled,
  ShapeBuilderToolFilled,
  Sidebar1Filled,
  SidebarFilled,
  SizeFilled,
  SliceFilled,
  SpiralToolFilled,
  TransformFilled,
  UpAlign1Filled,
  UpAlign2Filled,
  UpAlign3Filled,
  UpAlignFilled,
  UxFilled,
  VectorAltFilled,
  VectorPenFilled,
  VectorFilled,
  Widget1Filled,
  Widget2Filled,
  Widget3Filled,
  Widget4Filled,
  Widget5Filled,
  WidgetFilled,
};
