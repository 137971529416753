import { m } from 'framer-motion';

import { cn } from '../../../../lib/utils';

export type HighlightProps = Pick<
  React.HTMLAttributes<HTMLSpanElement>,
  'children'
>;

export function Highlight({ children }: HighlightProps) {
  return (
    <m.span
      initial={{ backgroundPosition: 0 }}
      whileInView={{
        backgroundPosition: '-100%',
        transition: { delay: 0.5, duration: 0.6 },
      }}
      className={cn(
        '[background-image:linear-gradient(to_right,#FFFAF1_50%,transparent_50%),linear-gradient(rgba(127,184,252,0.4),rgba(127,184,252,0.4))]',
        '[background-size:200%]',
      )}
    >
      {children}
    </m.span>
  );
}
