import type { FilledIconProps } from '../types';
import { ReactComponent as Android } from './android.svg';
import { ReactComponent as AppleBrand } from './apple-brand.svg';
import { ReactComponent as Behance } from './behance.svg';
import { ReactComponent as Bitbucket } from './bitbucket.svg';
import { ReactComponent as Bitcoin } from './bitcoin.svg';
import { ReactComponent as Chrome } from './chrome.svg';
import { ReactComponent as Css3 } from './css3.svg';
import { ReactComponent as Dribbble } from './dribbble.svg';
import { ReactComponent as Dropbox } from './dropbox.svg';
import { ReactComponent as Drupal } from './drupal.svg';
import { ReactComponent as FacebookLine } from './facebook-line.svg';
import { ReactComponent as Facebook } from './facebook.svg';
import { ReactComponent as Firefox } from './firefox.svg';
import { ReactComponent as Github } from './github.svg';
import { ReactComponent as Html5 } from './html5.svg';
import { ReactComponent as Instagram } from './instagram.svg';
import { ReactComponent as Paypal } from './paypal.svg';
import { ReactComponent as Quora } from './quora.svg';
import { ReactComponent as Reddit } from './reddit.svg';
import { ReactComponent as SlackLine } from './slack-line.svg';
import { ReactComponent as Soundcloud } from './soundcloud.svg';
import { ReactComponent as Twitter } from './twitter.svg';
import { ReactComponent as Visa } from './visa.svg';

const AndroidFilled = (props: FilledIconProps) => (
  <Android fill="currentColor" {...props} />
);

const AppleBrandFilled = (props: FilledIconProps) => (
  <AppleBrand fill="currentColor" {...props} />
);

const BehanceFilled = (props: FilledIconProps) => (
  <Behance fill="currentColor" {...props} />
);

const BitbucketFilled = (props: FilledIconProps) => (
  <Bitbucket fill="currentColor" {...props} />
);

const BitcoinFilled = (props: FilledIconProps) => (
  <Bitcoin fill="currentColor" {...props} />
);

const ChromeFilled = (props: FilledIconProps) => (
  <Chrome fill="currentColor" {...props} />
);

const Css3Filled = (props: FilledIconProps) => (
  <Css3 fill="currentColor" {...props} />
);

const DribbbleFilled = (props: FilledIconProps) => (
  <Dribbble fill="currentColor" {...props} />
);

const DropboxFilled = (props: FilledIconProps) => (
  <Dropbox fill="currentColor" {...props} />
);

const DrupalFilled = (props: FilledIconProps) => (
  <Drupal fill="currentColor" {...props} />
);

const FacebookLineFilled = (props: FilledIconProps) => (
  <FacebookLine fill="currentColor" {...props} />
);

const FacebookFilled = (props: FilledIconProps) => (
  <Facebook fill="currentColor" {...props} />
);

const FirefoxFilled = (props: FilledIconProps) => (
  <Firefox fill="currentColor" {...props} />
);

const GithubFilled = (props: FilledIconProps) => (
  <Github fill="currentColor" {...props} />
);

const Html5Filled = (props: FilledIconProps) => (
  <Html5 fill="currentColor" {...props} />
);

const InstagramFilled = (props: FilledIconProps) => (
  <Instagram fill="currentColor" {...props} />
);

const PaypalFilled = (props: FilledIconProps) => (
  <Paypal fill="currentColor" {...props} />
);

const QuoraFilled = (props: FilledIconProps) => (
  <Quora fill="currentColor" {...props} />
);

const RedditFilled = (props: FilledIconProps) => (
  <Reddit fill="currentColor" {...props} />
);

const SlackLineFilled = (props: FilledIconProps) => (
  <SlackLine fill="currentColor" {...props} />
);

const SoundcloudFilled = (props: FilledIconProps) => (
  <Soundcloud fill="currentColor" {...props} />
);

const TwitterFilled = (props: FilledIconProps) => (
  <Twitter fill="currentColor" {...props} />
);

const VisaFilled = (props: FilledIconProps) => (
  <Visa fill="currentColor" {...props} />
);

export {
  AndroidFilled,
  AppleBrandFilled,
  BehanceFilled,
  BitbucketFilled,
  BitcoinFilled,
  ChromeFilled,
  Css3Filled,
  DribbbleFilled,
  DropboxFilled,
  DrupalFilled,
  FacebookLineFilled,
  FacebookFilled,
  FirefoxFilled,
  GithubFilled,
  Html5Filled,
  InstagramFilled,
  PaypalFilled,
  QuoraFilled,
  RedditFilled,
  SlackLineFilled,
  SoundcloudFilled,
  TwitterFilled,
  VisaFilled,
};
