import type { FilledIconProps } from '../types';
import { ReactComponent as ArrowTarget1 } from './arrow-target-1.svg';
import { ReactComponent as ArrowTarget } from './arrow-target.svg';
import { ReactComponent as BulbIdea } from './bulb-idea.svg';
import { ReactComponent as BusinessPartnership } from './business-partnership.svg';
import { ReactComponent as BusinessRocket } from './business-rocket.svg';
import { ReactComponent as CloudIdea } from './cloud-idea.svg';
import { ReactComponent as FlashIdea } from './flash-idea.svg';
import { ReactComponent as GoFrame1 } from './go-frame-1.svg';
import { ReactComponent as GoFrame } from './go-frame.svg';
import { ReactComponent as GraphGrowth } from './graph-growth.svg';
import { ReactComponent as GrowHelp1 } from './grow-help-1.svg';
import { ReactComponent as GrowHelp } from './grow-help.svg';
import { ReactComponent as HeadIdeaSetting } from './head-idea-setting.svg';
import { ReactComponent as IdeaAgenda } from './idea-agenda.svg';
import { ReactComponent as IdeaBriefcase } from './idea-briefcase.svg';
import { ReactComponent as IdeaChat } from './idea-chat.svg';
import { ReactComponent as IdeaDown } from './idea-down.svg';
import { ReactComponent as IdeaEnvelope } from './idea-envelope.svg';
import { ReactComponent as IdeaHead1 } from './idea-head-1.svg';
import { ReactComponent as IdeaLabel } from './idea-label.svg';
import { ReactComponent as IdeaLocation } from './idea-location.svg';
import { ReactComponent as IdeaPresentation } from './idea-presentation.svg';
import { ReactComponent as IdeaReload } from './idea-reload.svg';
import { ReactComponent as IdeaRise } from './idea-rise.svg';
import { ReactComponent as IdeaSearch } from './idea-search.svg';
import { ReactComponent as IdeaSetting } from './idea-setting.svg';
import { ReactComponent as IdeaShare } from './idea-share.svg';
import { ReactComponent as IdeaShield } from './idea-shield.svg';
import { ReactComponent as IpoFlag } from './ipo-flag.svg';
import { ReactComponent as LaptopTarget } from './laptop-target.svg';
import { ReactComponent as LetterIdea } from './letter-idea.svg';
import { ReactComponent as Maze } from './maze.svg';
import { ReactComponent as Mission } from './mission.svg';
import { ReactComponent as MobileRocket1 } from './mobile-rocket-1.svg';
import { ReactComponent as MobileRocket3 } from './mobile-rocket-3.svg';
import { ReactComponent as MobileRocket } from './mobile-rocket.svg';
import { ReactComponent as MobileTarget1 } from './mobile-target-1.svg';
import { ReactComponent as MobileTarget } from './mobile-target.svg';
import { ReactComponent as MoneyIdea } from './money-idea.svg';
import { ReactComponent as NetworkingStartup } from './networking-startup.svg';
import { ReactComponent as PaperIdea } from './paper-idea.svg';
import { ReactComponent as Preview } from './preview.svg';
import { ReactComponent as Puzzle } from './puzzle.svg';
import { ReactComponent as RocketEarth } from './rocket-earth.svg';
import { ReactComponent as RocketFavourite } from './rocket-favourite.svg';
import { ReactComponent as RocketGrowth1 } from './rocket-growth-1.svg';
import { ReactComponent as RocketGrowth2 } from './rocket-growth-2.svg';
import { ReactComponent as RocketGrowth } from './rocket-growth.svg';
import { ReactComponent as RocketIdea } from './rocket-idea.svg';
import { ReactComponent as RocketLaunch1 } from './rocket-launch-1.svg';
import { ReactComponent as RocketLaunch2 } from './rocket-launch-2.svg';
import { ReactComponent as RocketLaunch3 } from './rocket-launch-3.svg';
import { ReactComponent as RocketLaunch4 } from './rocket-launch-4.svg';
import { ReactComponent as RocketLaunch5 } from './rocket-launch-5.svg';
import { ReactComponent as RocketLaunch6 } from './rocket-launch-6.svg';
import { ReactComponent as RocketLaunch } from './rocket-launch.svg';
import { ReactComponent as RocketMoney } from './rocket-money.svg';
import { ReactComponent as RocketSearch } from './rocket-search.svg';
import { ReactComponent as RocketSlim } from './rocket-slim.svg';
import { ReactComponent as RocketStar } from './rocket-star.svg';
import { ReactComponent as RocketStartAlt } from './rocket-start-alt.svg';
import { ReactComponent as RocketStart } from './rocket-start.svg';
import { ReactComponent as RocketTarget } from './rocket-target.svg';
import { ReactComponent as RocketVerified } from './rocket-verified.svg';
import { ReactComponent as View } from './view.svg';

const ArrowTarget1Filled = (props: FilledIconProps) => (
  <ArrowTarget1 fill="currentColor" {...props} />
);

const ArrowTargetFilled = (props: FilledIconProps) => (
  <ArrowTarget fill="currentColor" {...props} />
);

const BulbIdeaFilled = (props: FilledIconProps) => (
  <BulbIdea fill="currentColor" {...props} />
);

const BusinessPartnershipFilled = (props: FilledIconProps) => (
  <BusinessPartnership fill="currentColor" {...props} />
);

const BusinessRocketFilled = (props: FilledIconProps) => (
  <BusinessRocket fill="currentColor" {...props} />
);

const CloudIdeaFilled = (props: FilledIconProps) => (
  <CloudIdea fill="currentColor" {...props} />
);

const FlashIdeaFilled = (props: FilledIconProps) => (
  <FlashIdea fill="currentColor" {...props} />
);

const GoFrame1Filled = (props: FilledIconProps) => (
  <GoFrame1 fill="currentColor" {...props} />
);

const GoFrameFilled = (props: FilledIconProps) => (
  <GoFrame fill="currentColor" {...props} />
);

const GraphGrowthFilled = (props: FilledIconProps) => (
  <GraphGrowth fill="currentColor" {...props} />
);

const GrowHelp1Filled = (props: FilledIconProps) => (
  <GrowHelp1 fill="currentColor" {...props} />
);

const GrowHelpFilled = (props: FilledIconProps) => (
  <GrowHelp fill="currentColor" {...props} />
);

const HeadIdeaSettingFilled = (props: FilledIconProps) => (
  <HeadIdeaSetting fill="currentColor" {...props} />
);

const IdeaAgendaFilled = (props: FilledIconProps) => (
  <IdeaAgenda fill="currentColor" {...props} />
);

const IdeaBriefcaseFilled = (props: FilledIconProps) => (
  <IdeaBriefcase fill="currentColor" {...props} />
);

const IdeaChatFilled = (props: FilledIconProps) => (
  <IdeaChat fill="currentColor" {...props} />
);

const IdeaDownFilled = (props: FilledIconProps) => (
  <IdeaDown fill="currentColor" {...props} />
);

const IdeaEnvelopeFilled = (props: FilledIconProps) => (
  <IdeaEnvelope fill="currentColor" {...props} />
);

const IdeaHead1Filled = (props: FilledIconProps) => (
  <IdeaHead1 fill="currentColor" {...props} />
);

const IdeaLabelFilled = (props: FilledIconProps) => (
  <IdeaLabel fill="currentColor" {...props} />
);

const IdeaLocationFilled = (props: FilledIconProps) => (
  <IdeaLocation fill="currentColor" {...props} />
);

const IdeaPresentationFilled = (props: FilledIconProps) => (
  <IdeaPresentation fill="currentColor" {...props} />
);

const IdeaReloadFilled = (props: FilledIconProps) => (
  <IdeaReload fill="currentColor" {...props} />
);

const IdeaRiseFilled = (props: FilledIconProps) => (
  <IdeaRise fill="currentColor" {...props} />
);

const IdeaSearchFilled = (props: FilledIconProps) => (
  <IdeaSearch fill="currentColor" {...props} />
);

const IdeaSettingFilled = (props: FilledIconProps) => (
  <IdeaSetting fill="currentColor" {...props} />
);

const IdeaShareFilled = (props: FilledIconProps) => (
  <IdeaShare fill="currentColor" {...props} />
);

const IdeaShieldFilled = (props: FilledIconProps) => (
  <IdeaShield fill="currentColor" {...props} />
);

const IpoFlagFilled = (props: FilledIconProps) => (
  <IpoFlag fill="currentColor" {...props} />
);

const LaptopTargetFilled = (props: FilledIconProps) => (
  <LaptopTarget fill="currentColor" {...props} />
);

const LetterIdeaFilled = (props: FilledIconProps) => (
  <LetterIdea fill="currentColor" {...props} />
);

const MazeFilled = (props: FilledIconProps) => (
  <Maze fill="currentColor" {...props} />
);

const MissionFilled = (props: FilledIconProps) => (
  <Mission fill="currentColor" {...props} />
);

const MobileRocket1Filled = (props: FilledIconProps) => (
  <MobileRocket1 fill="currentColor" {...props} />
);

const MobileRocket3Filled = (props: FilledIconProps) => (
  <MobileRocket3 fill="currentColor" {...props} />
);

const MobileRocketFilled = (props: FilledIconProps) => (
  <MobileRocket fill="currentColor" {...props} />
);

const MobileTarget1Filled = (props: FilledIconProps) => (
  <MobileTarget1 fill="currentColor" {...props} />
);

const MobileTargetFilled = (props: FilledIconProps) => (
  <MobileTarget fill="currentColor" {...props} />
);

const MoneyIdeaFilled = (props: FilledIconProps) => (
  <MoneyIdea fill="currentColor" {...props} />
);

const NetworkingStartupFilled = (props: FilledIconProps) => (
  <NetworkingStartup fill="currentColor" {...props} />
);

const PaperIdeaFilled = (props: FilledIconProps) => (
  <PaperIdea fill="currentColor" {...props} />
);

const PreviewFilled = (props: FilledIconProps) => (
  <Preview fill="currentColor" {...props} />
);

const PuzzleFilled = (props: FilledIconProps) => (
  <Puzzle fill="currentColor" {...props} />
);

const RocketEarthFilled = (props: FilledIconProps) => (
  <RocketEarth fill="currentColor" {...props} />
);

const RocketFavouriteFilled = (props: FilledIconProps) => (
  <RocketFavourite fill="currentColor" {...props} />
);

const RocketGrowth1Filled = (props: FilledIconProps) => (
  <RocketGrowth1 fill="currentColor" {...props} />
);

const RocketGrowth2Filled = (props: FilledIconProps) => (
  <RocketGrowth2 fill="currentColor" {...props} />
);

const RocketGrowthFilled = (props: FilledIconProps) => (
  <RocketGrowth fill="currentColor" {...props} />
);

const RocketIdeaFilled = (props: FilledIconProps) => (
  <RocketIdea fill="currentColor" {...props} />
);

const RocketLaunch1Filled = (props: FilledIconProps) => (
  <RocketLaunch1 fill="currentColor" {...props} />
);

const RocketLaunch2Filled = (props: FilledIconProps) => (
  <RocketLaunch2 fill="currentColor" {...props} />
);

const RocketLaunch3Filled = (props: FilledIconProps) => (
  <RocketLaunch3 fill="currentColor" {...props} />
);

const RocketLaunch4Filled = (props: FilledIconProps) => (
  <RocketLaunch4 fill="currentColor" {...props} />
);

const RocketLaunch5Filled = (props: FilledIconProps) => (
  <RocketLaunch5 fill="currentColor" {...props} />
);

const RocketLaunch6Filled = (props: FilledIconProps) => (
  <RocketLaunch6 fill="currentColor" {...props} />
);

const RocketLaunchFilled = (props: FilledIconProps) => (
  <RocketLaunch fill="currentColor" {...props} />
);

const RocketMoneyFilled = (props: FilledIconProps) => (
  <RocketMoney fill="currentColor" {...props} />
);

const RocketSearchFilled = (props: FilledIconProps) => (
  <RocketSearch fill="currentColor" {...props} />
);

const RocketSlimFilled = (props: FilledIconProps) => (
  <RocketSlim fill="currentColor" {...props} />
);

const RocketStarFilled = (props: FilledIconProps) => (
  <RocketStar fill="currentColor" {...props} />
);

const RocketStartAltFilled = (props: FilledIconProps) => (
  <RocketStartAlt fill="currentColor" {...props} />
);

const RocketStartFilled = (props: FilledIconProps) => (
  <RocketStart fill="currentColor" {...props} />
);

const RocketTargetFilled = (props: FilledIconProps) => (
  <RocketTarget fill="currentColor" {...props} />
);

const RocketVerifiedFilled = (props: FilledIconProps) => (
  <RocketVerified fill="currentColor" {...props} />
);

const ViewFilled = (props: FilledIconProps) => (
  <View fill="currentColor" {...props} />
);

export {
  ArrowTarget1Filled,
  ArrowTargetFilled,
  BulbIdeaFilled,
  BusinessPartnershipFilled,
  BusinessRocketFilled,
  CloudIdeaFilled,
  FlashIdeaFilled,
  GoFrame1Filled,
  GoFrameFilled,
  GraphGrowthFilled,
  GrowHelp1Filled,
  GrowHelpFilled,
  HeadIdeaSettingFilled,
  IdeaAgendaFilled,
  IdeaBriefcaseFilled,
  IdeaChatFilled,
  IdeaDownFilled,
  IdeaEnvelopeFilled,
  IdeaHead1Filled,
  IdeaLabelFilled,
  IdeaLocationFilled,
  IdeaPresentationFilled,
  IdeaReloadFilled,
  IdeaRiseFilled,
  IdeaSearchFilled,
  IdeaSettingFilled,
  IdeaShareFilled,
  IdeaShieldFilled,
  IpoFlagFilled,
  LaptopTargetFilled,
  LetterIdeaFilled,
  MazeFilled,
  MissionFilled,
  MobileRocket1Filled,
  MobileRocket3Filled,
  MobileRocketFilled,
  MobileTarget1Filled,
  MobileTargetFilled,
  MoneyIdeaFilled,
  NetworkingStartupFilled,
  PaperIdeaFilled,
  PreviewFilled,
  PuzzleFilled,
  RocketEarthFilled,
  RocketFavouriteFilled,
  RocketGrowth1Filled,
  RocketGrowth2Filled,
  RocketGrowthFilled,
  RocketIdeaFilled,
  RocketLaunch1Filled,
  RocketLaunch2Filled,
  RocketLaunch3Filled,
  RocketLaunch4Filled,
  RocketLaunch5Filled,
  RocketLaunch6Filled,
  RocketLaunchFilled,
  RocketMoneyFilled,
  RocketSearchFilled,
  RocketSlimFilled,
  RocketStarFilled,
  RocketStartAltFilled,
  RocketStartFilled,
  RocketTargetFilled,
  RocketVerifiedFilled,
  ViewFilled,
};
