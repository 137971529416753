import { PlayFilled } from '@/icons';
import { useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import latestBlogPost from '../../../content/blog_posts/moderating-with-llms';
import { Card } from '../../Card';
import { Lightbox } from '../../Lightbox';
import { Button } from '../../ui/Button';
import { Typography } from '../../ui/Typography';

interface AnnouncementCardProps {
  label: string;
  title: string;
  description: string;
  cta: {
    label: string;
    action: (() => void) | string;
  };
  children?: React.ReactNode;
}

function AnnouncementCard({
  title,
  cta,
  description,
  children,
  label,
}: AnnouncementCardProps) {
  return (
    <Card
      color="primary"
      className="flex flex-col-reverse items-start gap-8 p-8 lg:flex-row lg:items-center"
    >
      <div className="flex flex-col items-start justify-start flex-grow h-full gap-2">
        <Typography
          className="bg-gradient-to-b from-ternary to-primary bg-clip-text text-clear"
          variant="title"
          size="sm"
        >
          {label}
        </Typography>
        <Typography variant="headline" size="md">
          {title}
        </Typography>
        <Typography variant="body" className="flex-1" size="md">
          {description}
        </Typography>
        <Button
          variant="outlined"
          className="mt-4"
          {...(typeof cta.action === 'string'
            ? { as: 'a', href: cta.action }
            : { onClick: cta.action })}
        >
          {cta.label}
        </Button>
      </div>
      {children}
    </Card>
  );
}

export function Announcements() {
  const [showVideo, setShowVideo] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  function closeVideo() {
    videoRef.current?.pause();
    setShowVideo(false);
  }

  function playVideo() {
    setShowVideo(true);
  }

  const customAiModelsCta = useMemo(
    () => ({ label: 'Watch the demo', action: playVideo }),
    [],
  );
  const latestBlogPostCta = useMemo(
    () => ({ label: 'Read more', action: `/blog/${latestBlogPost.slug}` }),
    [],
  );

  return (
    <div className="container grid grid-cols-1 gap-5 px-8 lg:px-12 md:grid-cols-2">
      <AnnouncementCard
        label="Custom AI Models launch"
        title="Our latest AI product"
        description="Turn plain English policies into custom AI models that are trained specifically for you."
        cta={customAiModelsCta}
      >
        {typeof document !== 'undefined' &&
          createPortal(
            <Lightbox isOpen={showVideo} onClose={closeVideo}>
              <video
                ref={videoRef}
                src="/videos/ai-demo.mp4"
                controls
                autoPlay
              />
            </Lightbox>,
            document.body,
          )}
        <div className="relative">
          <div
            onClick={playVideo}
            className="relative cursor-pointer flex-shrink-0 border rounded bg-cover bg-center w-[227px] h-[144px]"
          >
            <img
              alt="Cove AI"
              src="/graphics/cove-ai.webp"
              className="absolute w-full h-full rounded"
            />
          </div>
          <div
            onClick={playVideo}
            className="absolute cursor-pointer top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-inverted rounded-full w-12 h-12 bg-primary/90 border-4 border-[rgba(147,129,238,0.9)] flex justify-center items-center"
          >
            <PlayFilled width={16} height={16} />
          </div>
        </div>
      </AnnouncementCard>
      <AnnouncementCard
        label={latestBlogPost.label}
        title={latestBlogPost.title}
        description={latestBlogPost.description}
        cta={latestBlogPostCta}
      >
        <img
          alt={latestBlogPost.coverPhoto.attribution}
          src={latestBlogPost.coverPhoto.url}
          className="object-contain w-64 border border-solid rounded-lg"
        />
      </AnnouncementCard>
    </div>
  );
}
