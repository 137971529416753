import { domAnimation, LazyMotion } from 'framer-motion';
import { useState } from 'react';

import RequestDemoModal from '../components/RequestDemoModal';
import { Analytics } from '../components/sections/landing/Analytics';
import { Announcements } from '../components/sections/landing/Announcements';
import { Clients } from '../components/sections/landing/Clients';
import { CTA } from '../components/sections/landing/CTA';
import { Flexibility } from '../components/sections/landing/Flexibility';
import { Hero } from '../components/sections/landing/Hero';
import MobileProducts from '../components/sections/landing/MobileProducts';
import { More } from '../components/sections/landing/More';
import { Products } from '../components/sections/landing/Products';
import { Suite } from '../components/sections/landing/Suite';
import { UseCases } from '../components/sections/landing/UseCases';

export default function Home() {
  const [requestDemoModalVisible, setRequestDemoModalVisible] = useState(false);
  const showRequestDemoModal = () => setRequestDemoModalVisible(true);
  return (
    <LazyMotion features={domAnimation}>
      <Hero showRequestDemoModal={showRequestDemoModal} />
      <Clients />
      <Suite showRequestDemoModal={showRequestDemoModal} />
      <Announcements />
      <div className="invisible w-auto h-0 md:visible md:h-auto">
        <Products />
      </div>
      <div className="visible w-full h-auto max-w-full md:invisible md:h-0">
        <MobileProducts />
      </div>
      <Flexibility showRequestDemoModal={showRequestDemoModal} />
      <Analytics showRequestDemoModal={showRequestDemoModal} />
      <More />
      <UseCases />
      <CTA showRequestDemoModal={showRequestDemoModal} />
      <RequestDemoModal
        visible={requestDemoModalVisible}
        onClose={() => setRequestDemoModalVisible(false)}
        entrypoint="HOME"
      />
    </LazyMotion>
  );
}
