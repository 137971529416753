/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { JsonObject, JsonValue } from 'type-fest';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Represents a string encoding information about a Cove Model Signal creation session. */
  CoveModelSignalCreationSessionId: any;
  /** Represents a string encoding information about a Cove Model Signal sampling strategy. */
  CoveModelSignalSamplingStrategyToken: string;
  Cursor: string;
  /** Date represents just a date, with no time, no timezone, no offset. */
  Date: Date | string;
  /**
   * DateTime represents an instant, with a UTC offset, serialized in ISO 8601
   * (specifically, the profile of ISO 8601 supported by Date.toISOString()).
   * as implemented by https://www.graphql-scalars.dev/docs/scalars/date-time
   * NB: This is different from Apollo's default serialization of JS Date's, which
   * uses a string with a unix timestamp in it, so be careful when updating existing
   * fields.
   */
  DateTime: Date | string;
  /** Represents an arbitrary json object. */
  JSONObject: JsonObject;
  /** Represents a string that must be non-empty. */
  NonEmptyString: string;
  /** Represents the possible types for the name of a ConditionInput */
  ProtegoInputOrString: string;
  /** Represents a string | float union, which is the type of a Condition's threshold */
  StringOrFloat: string | number;
};

export type GQLAcceptAppealDecisionComponent =
  GQLManualReviewDecisionComponentBase & {
    readonly __typename: 'AcceptAppealDecisionComponent';
    readonly actionIds: ReadonlyArray<Scalars['String']>;
    readonly appealId: Scalars['String'];
    readonly type: GQLManualReviewDecisionType;
  };

export type GQLAction =
  | GQLCustomerDefinedAction
  | GQLEnqueueAuthorToMrtAction
  | GQLEnqueueToMrtAction
  | GQLEnqueueToNcmecAction;

export type GQLActionBase = {
  readonly applyUserStrikes?: Maybe<Scalars['Boolean']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly itemTypes: ReadonlyArray<GQLItemType>;
  readonly name: Scalars['String'];
  readonly orgId: Scalars['String'];
  readonly penalty: GQLUserPenaltySeverity;
};

export type GQLActionData = {
  readonly __typename: 'ActionData';
  readonly action_id?: Maybe<Scalars['String']>;
  readonly count: Scalars['Int'];
  readonly item_type_id?: Maybe<Scalars['String']>;
  readonly policy_id?: Maybe<Scalars['String']>;
  readonly rule_id?: Maybe<Scalars['String']>;
  readonly source?: Maybe<Scalars['String']>;
  readonly time: Scalars['String'];
};

export type GQLActionNameExistsError = GQLError & {
  readonly __typename: 'ActionNameExistsError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export const GQLActionSource = {
  AutomatedRule: 'AUTOMATED_RULE',
  ManualActionRun: 'MANUAL_ACTION_RUN',
  MrtDecision: 'MRT_DECISION',
  PostActions: 'POST_ACTIONS',
} as const;

export type GQLActionSource =
  (typeof GQLActionSource)[keyof typeof GQLActionSource];
export type GQLActionStatisticsFilters = {
  readonly actionIds: ReadonlyArray<Scalars['String']>;
  readonly endDate: Scalars['DateTime'];
  readonly itemTypeIds: ReadonlyArray<Scalars['String']>;
  readonly policyIds: ReadonlyArray<Scalars['String']>;
  readonly ruleIds: ReadonlyArray<Scalars['String']>;
  readonly sources: ReadonlyArray<GQLActionSource>;
  readonly startDate: Scalars['DateTime'];
};

export const GQLActionStatisticsGroupByColumns = {
  ActionId: 'ACTION_ID',
  ActionSource: 'ACTION_SOURCE',
  ItemTypeId: 'ITEM_TYPE_ID',
  PolicyId: 'POLICY_ID',
  RuleId: 'RULE_ID',
} as const;

export type GQLActionStatisticsGroupByColumns =
  (typeof GQLActionStatisticsGroupByColumns)[keyof typeof GQLActionStatisticsGroupByColumns];
export type GQLActionStatisticsInput = {
  readonly filterBy: GQLActionStatisticsFilters;
  readonly groupBy: GQLActionStatisticsGroupByColumns;
  readonly timeDivision: GQLMetricsTimeDivisionOptions;
  readonly timeZone: Scalars['String'];
};

export type GQLAddAccessibleQueuesToUserInput = {
  readonly queueIds: ReadonlyArray<Scalars['ID']>;
  readonly userId: Scalars['ID'];
};

export type GQLAddAccessibleQueuesToUserResponse =
  GQLMutateAccessibleQueuesForUserSuccessResponse;

export type GQLAddCommentFailedError = GQLError & {
  readonly __typename: 'AddCommentFailedError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLAddFavoriteMrtQueueSuccessResponse = {
  readonly __typename: 'AddFavoriteMRTQueueSuccessResponse';
  readonly _?: Maybe<Scalars['Boolean']>;
};

export type GQLAddFavoriteRuleResponse = GQLAddFavoriteRuleSuccessResponse;

export type GQLAddFavoriteRuleSuccessResponse = {
  readonly __typename: 'AddFavoriteRuleSuccessResponse';
  readonly _?: Maybe<Scalars['Boolean']>;
};

export type GQLAddManualReviewJobCommentResponse =
  | GQLAddManualReviewJobCommentSuccessResponse
  | GQLNotFoundError;

export type GQLAddManualReviewJobCommentSuccessResponse = {
  readonly __typename: 'AddManualReviewJobCommentSuccessResponse';
  readonly comment: GQLManualReviewJobComment;
};

export type GQLAddPoliciesResponse = {
  readonly __typename: 'AddPoliciesResponse';
  readonly failures: ReadonlyArray<Scalars['String']>;
  readonly policies: ReadonlyArray<GQLPolicy>;
};

export type GQLAddPolicyInput = {
  readonly enforcementGuidelines?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly parentId?: InputMaybe<Scalars['ID']>;
  readonly parentName?: InputMaybe<Scalars['String']>;
  readonly policyText?: InputMaybe<Scalars['String']>;
  readonly policyType?: InputMaybe<GQLPolicyType>;
};

export type GQLAggregation = {
  readonly __typename: 'Aggregation';
  readonly type: GQLAggregationType;
};

export type GQLAggregationClause = {
  readonly __typename: 'AggregationClause';
  readonly aggregation?: Maybe<GQLAggregation>;
  readonly conditionSet?: Maybe<GQLConditionSet>;
  readonly groupBy: ReadonlyArray<GQLConditionInputField>;
  readonly id: Scalars['ID'];
  readonly window: GQLWindowConfiguration;
};

export type GQLAggregationClauseInput = {
  readonly aggregation: GQLAggregationInput;
  readonly conditionSet?: InputMaybe<GQLConditionSetInput>;
  readonly groupBy: ReadonlyArray<GQLConditionInputFieldInput>;
  readonly window: GQLWindowConfigurationInput;
};

export type GQLAggregationInput = {
  readonly type: GQLAggregationType;
};

export type GQLAggregationSignalArgs = {
  readonly __typename: 'AggregationSignalArgs';
  readonly aggregationClause?: Maybe<GQLAggregationClause>;
};

export type GQLAggregationSignalArgsInput = {
  readonly aggregationClause: GQLAggregationClauseInput;
};

export const GQLAggregationType = {
  Count: 'COUNT',
} as const;

export type GQLAggregationType =
  (typeof GQLAggregationType)[keyof typeof GQLAggregationType];
export type GQLAllLanguages = {
  readonly __typename: 'AllLanguages';
  readonly _?: Maybe<Scalars['Boolean']>;
};

export type GQLAllRuleInsights = {
  readonly __typename: 'AllRuleInsights';
  readonly actionedSubmissionsByActionByDay: ReadonlyArray<GQLCountByActionByDay>;
  readonly actionedSubmissionsByDay: ReadonlyArray<GQLCountByDay>;
  readonly actionedSubmissionsByPolicyByDay: ReadonlyArray<GQLCountByPolicyByDay>;
  readonly actionedSubmissionsByTagByDay: ReadonlyArray<GQLCountByTagByDay>;
  readonly totalSubmissionsByDay: ReadonlyArray<GQLCountByDay>;
};

export type GQLAmazonRekognitionIntegrationApiCredential = {
  readonly __typename: 'AmazonRekognitionIntegrationApiCredential';
  readonly awsAccessKeyId: Scalars['String'];
  readonly awsSecretAccessKey: Scalars['String'];
};

export type GQLAmazonRekognitionIntegrationApiCredentialInput = {
  readonly awsAccessKeyId: Scalars['String'];
  readonly awsSecretAccessKey: Scalars['String'];
};

export const GQLAppealDecision = {
  Accept: 'ACCEPT',
  Reject: 'REJECT',
} as const;

export type GQLAppealDecision =
  (typeof GQLAppealDecision)[keyof typeof GQLAppealDecision];
export type GQLAppealEnqueueSourceInfo = {
  readonly __typename: 'AppealEnqueueSourceInfo';
  readonly kind: GQLJobCreationSourceOptions;
};

export type GQLAppealSettings = {
  readonly __typename: 'AppealSettings';
  readonly appealsCallbackBody?: Maybe<Scalars['JSONObject']>;
  readonly appealsCallbackHeaders?: Maybe<Scalars['JSONObject']>;
  readonly appealsCallbackUrl?: Maybe<Scalars['String']>;
};

export type GQLAppealSettingsInput = {
  readonly appealsCallbackBody?: InputMaybe<Scalars['JSONObject']>;
  readonly appealsCallbackHeaders?: InputMaybe<Scalars['JSONObject']>;
  readonly appealsCallbackUrl?: InputMaybe<Scalars['String']>;
};

export type GQLAutomaticCloseDecisionComponent =
  GQLManualReviewDecisionComponentBase & {
    readonly __typename: 'AutomaticCloseDecisionComponent';
    readonly type: GQLManualReviewDecisionType;
  };

export type GQLBacktest = {
  readonly __typename: 'Backtest';
  readonly contentItemsMatched: Scalars['Int'];
  readonly contentItemsProcessed: Scalars['Int'];
  readonly createdAt: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly results?: Maybe<GQLRuleExecutionResultsConnection>;
  readonly sampleActualSize: Scalars['Int'];
  readonly sampleDesiredSize: Scalars['Int'];
  readonly sampleEndAt: Scalars['String'];
  readonly sampleStartAt: Scalars['String'];
  readonly samplingComplete: Scalars['Boolean'];
  readonly status: GQLBacktestStatus;
};

export type GQLBacktestResultsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<GQLSortOrder>;
};

export const GQLBacktestStatus = {
  Canceled: 'CANCELED',
  Complete: 'COMPLETE',
  Running: 'RUNNING',
} as const;

export type GQLBacktestStatus =
  (typeof GQLBacktestStatus)[keyof typeof GQLBacktestStatus];
export type GQLBaseField = GQLField & {
  readonly __typename: 'BaseField';
  readonly container?: Maybe<GQLContainer>;
  readonly name: Scalars['String'];
  readonly required: Scalars['Boolean'];
  readonly type: GQLFieldType;
};

export type GQLCannotDeleteDefaultUserError = GQLError & {
  readonly __typename: 'CannotDeleteDefaultUserError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLCheckstepIntegrationApiCredential = {
  readonly __typename: 'CheckstepIntegrationApiCredential';
  readonly apiKeys: ReadonlyArray<GQLCheckstepIntegrationApiCredentialPair>;
};

export type GQLCheckstepIntegrationApiCredentialInput = {
  readonly apiKeys: ReadonlyArray<GQLCheckstepIntegrationApiCredentialPairInput>;
};

export type GQLCheckstepIntegrationApiCredentialPair = {
  readonly __typename: 'CheckstepIntegrationApiCredentialPair';
  readonly apiKey: Scalars['String'];
  readonly platform: Scalars['String'];
};

export type GQLCheckstepIntegrationApiCredentialPairInput = {
  readonly apiKey: Scalars['String'];
  readonly platform: Scalars['String'];
};

export type GQLClarifaiIntegrationApiCredential = {
  readonly __typename: 'ClarifaiIntegrationApiCredential';
  readonly image?: Maybe<GQLClarifaiIntegrationApiModelCredential>;
  readonly text?: Maybe<GQLClarifaiIntegrationApiModelCredential>;
};

export type GQLClarifaiIntegrationApiCredentialInput = {
  readonly image?: InputMaybe<GQLClarifaiIntegrationApiModelCredentialInput>;
  readonly text?: InputMaybe<GQLClarifaiIntegrationApiModelCredentialInput>;
};

export type GQLClarifaiIntegrationApiKey = {
  readonly __typename: 'ClarifaiIntegrationApiKey';
  readonly apiKey: Scalars['String'];
};

export type GQLClarifaiIntegrationApiKeyInput = {
  readonly apiKey: Scalars['String'];
};

export type GQLClarifaiIntegrationApiModelCredential =
  | GQLClarifaiIntegrationApiKey
  | GQLClarifaiIntegrationPersonalAccessToken;

export type GQLClarifaiIntegrationApiModelCredentialInput = {
  readonly apiKey?: InputMaybe<GQLClarifaiIntegrationApiKeyInput>;
  readonly personalAccessToken?: InputMaybe<GQLClarifaiIntegrationPersonalAccessTokenInput>;
};

export type GQLClarifaiIntegrationPersonalAccessToken = {
  readonly __typename: 'ClarifaiIntegrationPersonalAccessToken';
  readonly appId: Scalars['String'];
  readonly pat: Scalars['String'];
  readonly userId: Scalars['String'];
};

export type GQLClarifaiIntegrationPersonalAccessTokenInput = {
  readonly appId: Scalars['String'];
  readonly pat: Scalars['String'];
  readonly userId: Scalars['String'];
};

export type GQLCondition = GQLConditionSet | GQLLeafCondition;

export const GQLConditionConjunction = {
  And: 'AND',
  Or: 'OR',
  Xor: 'XOR',
} as const;

export type GQLConditionConjunction =
  (typeof GQLConditionConjunction)[keyof typeof GQLConditionConjunction];
export type GQLConditionInput = {
  readonly comparator?: InputMaybe<GQLValueComparator>;
  readonly conditions?: InputMaybe<ReadonlyArray<GQLConditionInput>>;
  readonly conjunction?: InputMaybe<GQLConditionConjunction>;
  readonly input?: InputMaybe<GQLConditionInputFieldInput>;
  readonly matchingValues?: InputMaybe<GQLConditionMatchingValuesInput>;
  readonly signal?: InputMaybe<GQLConditionInputSignalInput>;
  readonly threshold?: InputMaybe<Scalars['StringOrFloat']>;
};

export type GQLConditionInputField = {
  readonly __typename: 'ConditionInputField';
  readonly contentTypeId?: Maybe<Scalars['String']>;
  readonly contentTypeIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly name?: Maybe<Scalars['ProtegoInputOrString']>;
  readonly spec?: Maybe<GQLDerivedFieldSpec>;
  readonly type: GQLConditionInputInputType;
};

export type GQLConditionInputFieldInput = {
  readonly contentTypeId?: InputMaybe<Scalars['String']>;
  readonly contentTypeIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly name?: InputMaybe<Scalars['ProtegoInputOrString']>;
  readonly spec?: InputMaybe<GQLDerivedFieldSpecInput>;
  readonly type: GQLConditionInputInputType;
};

export const GQLConditionInputInputType = {
  ContentDerivedField: 'CONTENT_DERIVED_FIELD',
  ContentField: 'CONTENT_FIELD',
  ContentProtegoInput: 'CONTENT_PROTEGO_INPUT',
  FullItem: 'FULL_ITEM',
  UserId: 'USER_ID',
} as const;

export type GQLConditionInputInputType =
  (typeof GQLConditionInputInputType)[keyof typeof GQLConditionInputInputType];
export type GQLConditionInputSignalInput = {
  readonly args?: InputMaybe<GQLSignalArgsInput>;
  readonly id: Scalars['ID'];
  readonly name?: InputMaybe<Scalars['String']>;
  readonly subcategory?: InputMaybe<Scalars['String']>;
  readonly type: GQLSignalType;
};

export type GQLConditionMatchingValuesInput = {
  readonly locationBankIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly locations?: InputMaybe<ReadonlyArray<GQLLocationAreaInput>>;
  readonly strings?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly textBankIds?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export const GQLConditionOutcome = {
  Errored: 'ERRORED',
  Failed: 'FAILED',
  Inapplicable: 'INAPPLICABLE',
  Passed: 'PASSED',
} as const;

export type GQLConditionOutcome =
  (typeof GQLConditionOutcome)[keyof typeof GQLConditionOutcome];
export type GQLConditionResult = {
  readonly __typename: 'ConditionResult';
  readonly matchedValue?: Maybe<Scalars['String']>;
  readonly outcome: GQLConditionOutcome;
  readonly score?: Maybe<Scalars['String']>;
};

export type GQLConditionSet = {
  readonly __typename: 'ConditionSet';
  readonly conditions: ReadonlyArray<GQLCondition>;
  readonly conjunction: GQLConditionConjunction;
};

export type GQLConditionSetInput = {
  readonly conditions: ReadonlyArray<GQLConditionInput>;
  readonly conjunction: GQLConditionConjunction;
};

export type GQLConditionSetWithResult = {
  readonly __typename: 'ConditionSetWithResult';
  readonly conditions: ReadonlyArray<GQLConditionWithResult>;
  readonly conjunction?: Maybe<GQLConditionConjunction>;
  readonly result?: Maybe<GQLConditionResult>;
};

export type GQLConditionWithResult =
  | GQLConditionSetWithResult
  | GQLLeafConditionWithResult;

export type GQLContainer = {
  readonly __typename: 'Container';
  readonly containerType: GQLContainerType;
  readonly keyScalarType?: Maybe<GQLScalarType>;
  readonly valueScalarType: GQLScalarType;
};

export type GQLContainerInput = {
  readonly containerType: GQLContainerType;
  readonly keyScalarType?: InputMaybe<GQLScalarType>;
  readonly valueScalarType: GQLScalarType;
};

export const GQLContainerType = {
  Array: 'ARRAY',
  Map: 'MAP',
} as const;

export type GQLContainerType =
  (typeof GQLContainerType)[keyof typeof GQLContainerType];
export type GQLContentAppealManualReviewJobPayload = {
  readonly __typename: 'ContentAppealManualReviewJobPayload';
  readonly actionsTaken: ReadonlyArray<Scalars['String']>;
  readonly additionalContentItems: ReadonlyArray<GQLContentItem>;
  readonly appealId: Scalars['String'];
  readonly appealReason?: Maybe<Scalars['String']>;
  readonly appealerIdentifier?: Maybe<GQLItemIdentifier>;
  readonly enqueueSourceInfo?: Maybe<GQLAppealEnqueueSourceInfo>;
  readonly item: GQLContentItem;
  readonly userScore?: Maybe<Scalars['Int']>;
};

export type GQLContentItem = GQLItemBase & {
  readonly __typename: 'ContentItem';
  readonly data: Scalars['JSONObject'];
  readonly id: Scalars['ID'];
  readonly submissionId: Scalars['ID'];
  readonly submissionTime?: Maybe<Scalars['DateTime']>;
  readonly type: GQLContentItemType;
};

export type GQLContentItemType = GQLItemTypeBase & {
  readonly __typename: 'ContentItemType';
  readonly baseFields: ReadonlyArray<GQLBaseField>;
  readonly derivedFields: ReadonlyArray<GQLDerivedField>;
  readonly description?: Maybe<Scalars['String']>;
  readonly hiddenFields: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly schemaFieldRoles: GQLContentSchemaFieldRoles;
  readonly schemaVariant: GQLItemTypeSchemaVariant;
  readonly version: Scalars['String'];
};

export type GQLContentManualReviewJobPayload = {
  readonly __typename: 'ContentManualReviewJobPayload';
  readonly additionalContentItems: ReadonlyArray<GQLContentItem>;
  readonly enqueueSourceInfo?: Maybe<GQLManualReviewJobEnqueueSourceInfo>;
  readonly item: GQLContentItem;
  readonly itemThreadContentItems?: Maybe<ReadonlyArray<GQLContentItem>>;
  readonly reportHistory: ReadonlyArray<GQLReportHistoryEntry>;
  readonly reportedForReason?: Maybe<Scalars['String']>;
  readonly reportedForReasons: ReadonlyArray<GQLReportedForReason>;
  readonly userScore?: Maybe<Scalars['Int']>;
};

export type GQLContentRule = GQLRule & {
  readonly __typename: 'ContentRule';
  readonly actions: ReadonlyArray<GQLAction>;
  readonly backtests: ReadonlyArray<GQLBacktest>;
  readonly conditionSet: GQLConditionSet;
  readonly createdAt: Scalars['String'];
  readonly creator: GQLUser;
  readonly description?: Maybe<Scalars['String']>;
  readonly expirationTime?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly insights: GQLRuleInsights;
  readonly itemTypes: ReadonlyArray<GQLItemType>;
  readonly maxDailyActions?: Maybe<Scalars['Float']>;
  readonly name: Scalars['String'];
  readonly parentId?: Maybe<Scalars['ID']>;
  readonly policies: ReadonlyArray<GQLPolicy>;
  readonly status: GQLRuleStatus;
  readonly tags?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly updatedAt: Scalars['String'];
};

export type GQLContentRuleBacktestsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type GQLContentSchemaFieldRoles = {
  readonly __typename: 'ContentSchemaFieldRoles';
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly creatorId?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly isDeleted?: Maybe<Scalars['String']>;
  readonly parentId?: Maybe<Scalars['String']>;
  readonly threadId?: Maybe<Scalars['String']>;
};

export type GQLContentSchemaFieldRolesInput = {
  readonly createdAt?: InputMaybe<Scalars['String']>;
  readonly creatorId?: InputMaybe<Scalars['String']>;
  readonly displayName?: InputMaybe<Scalars['String']>;
  readonly isDeleted?: InputMaybe<Scalars['String']>;
  readonly parentId?: InputMaybe<Scalars['String']>;
  readonly threadId?: InputMaybe<Scalars['String']>;
};

export type GQLContentType = {
  readonly __typename: 'ContentType';
  readonly actions: ReadonlyArray<GQLAction>;
  readonly baseFields: ReadonlyArray<GQLBaseField>;
  readonly derivedFields: ReadonlyArray<GQLDerivedField>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type GQLCountByActionByDay = {
  readonly __typename: 'CountByActionByDay';
  readonly action: GQLCountByActionByDayAction;
  readonly count: Scalars['Int'];
  readonly date: Scalars['Date'];
};

export type GQLCountByActionByDayAction = {
  readonly __typename: 'CountByActionByDayAction';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type GQLCountByDay = {
  readonly __typename: 'CountByDay';
  readonly count: Scalars['Int'];
  readonly date: Scalars['Date'];
};

export type GQLCountByDecisionTypeByDay = {
  readonly __typename: 'CountByDecisionTypeByDay';
  readonly count: Scalars['Int'];
  readonly date: Scalars['Date'];
  readonly decisionType: Scalars['String'];
};

export type GQLCountByPolicyByDay = {
  readonly __typename: 'CountByPolicyByDay';
  readonly count: Scalars['Int'];
  readonly date: Scalars['Date'];
  readonly policy: GQLCountByPolicyByDayPolicy;
};

export type GQLCountByPolicyByDayPolicy = {
  readonly __typename: 'CountByPolicyByDayPolicy';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type GQLCountByTagByDay = {
  readonly __typename: 'CountByTagByDay';
  readonly count: Scalars['Int'];
  readonly date: Scalars['Date'];
  readonly tag: Scalars['String'];
};

export type GQLCoveModel = {
  readonly __typename: 'CoveModel';
  readonly alias?: Maybe<GQLModelVersionAlias>;
  readonly createdAt: Scalars['DateTime'];
  readonly family: Scalars['NonEmptyString'];
  readonly id: Scalars['NonEmptyString'];
  readonly itemRequirement: GQLItemRequirement;
  readonly name: Scalars['NonEmptyString'];
  readonly policy: GQLPolicy;
  readonly status: GQLModelVersionStatus;
  readonly version: Scalars['Int'];
};

export type GQLCoveModelIdentifierInput = {
  readonly id: Scalars['NonEmptyString'];
  readonly version: Scalars['Int'];
};

export type GQLCoveModelItem = {
  readonly __typename: 'CoveModelItem';
  readonly data: Scalars['JSONObject'];
  readonly id: Scalars['ID'];
  readonly itemType: GQLItemTypeIdentifier;
};

export type GQLCoveModelLabel = {
  readonly __typename: 'CoveModelLabel';
  readonly id: Scalars['String'];
  readonly itemFieldJsonPointers: ReadonlyArray<Scalars['String']>;
  readonly itemId: Scalars['String'];
  readonly labelerId?: Maybe<Scalars['String']>;
  readonly labelerType?: Maybe<Scalars['String']>;
  readonly violatesPolicy: Scalars['Boolean'];
};

export type GQLCoveModelRuleAction = {
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type GQLCoveModelRuleThreshold = {
  readonly action: GQLCoveModelRuleAction;
  readonly lowerThreshold: Scalars['Float'];
  readonly upperThreshold: Scalars['Float'];
};

export type GQLCoveModelSignalCreatedPending = {
  readonly __typename: 'CoveModelSignalCreatedPending';
  readonly _?: Maybe<Scalars['Boolean']>;
};

export type GQLCoveModelSignalCreatedSuccess = {
  readonly __typename: 'CoveModelSignalCreatedSuccess';
  readonly signal: GQLSignal;
};

export type GQLCoveModelSignalCreationError = GQLError & {
  readonly __typename: 'CoveModelSignalCreationError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLCoveModelSignalCreationSessionResult =
  | GQLCoveModelSignalCreatedPending
  | GQLCoveModelSignalCreatedSuccess
  | GQLCoveModelSignalCreationError;

export type GQLCreateActionInput = {
  readonly applyUserStrikes?: InputMaybe<Scalars['Boolean']>;
  readonly callbackUrl: Scalars['String'];
  readonly callbackUrlBody?: InputMaybe<Scalars['JSONObject']>;
  readonly callbackUrlHeaders?: InputMaybe<Scalars['JSONObject']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly itemTypeIds: ReadonlyArray<Scalars['ID']>;
  readonly name: Scalars['String'];
};

export type GQLCreateBacktestInput = {
  readonly ruleId: Scalars['ID'];
  readonly sampleDesiredSize: Scalars['Int'];
  readonly sampleEndAt: Scalars['String'];
  readonly sampleStartAt: Scalars['String'];
};

export type GQLCreateBacktestResponse = {
  readonly __typename: 'CreateBacktestResponse';
  readonly backtest: GQLBacktest;
};

export type GQLCreateContentItemTypeInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly fieldRoles: GQLContentSchemaFieldRolesInput;
  readonly fields: ReadonlyArray<GQLFieldInput>;
  readonly hiddenFields?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly name: Scalars['String'];
};

export type GQLCreateContentRuleInput = {
  readonly actionIds: ReadonlyArray<Scalars['ID']>;
  readonly conditionSet: GQLConditionSetInput;
  readonly contentTypeIds: ReadonlyArray<Scalars['ID']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly expirationTime?: InputMaybe<Scalars['DateTime']>;
  readonly maxDailyActions?: InputMaybe<Scalars['Float']>;
  readonly name: Scalars['String'];
  readonly parentId?: InputMaybe<Scalars['ID']>;
  readonly policyIds: ReadonlyArray<Scalars['ID']>;
  readonly rateLimitConfig?: InputMaybe<GQLRateLimitConfigInput>;
  readonly status: GQLRuleStatus;
  readonly tags: ReadonlyArray<Scalars['String']>;
};

export type GQLCreateContentRuleResponse =
  | GQLMutateContentRuleSuccessResponse
  | GQLRuleNameExistsError;

export type GQLCreateCoveModelSignalCreationSamplingJobInput = {
  readonly numSamples: Scalars['Int'];
  readonly samplingStrategy: GQLSamplingStrategyInput;
  readonly sessionId: Scalars['CoveModelSignalCreationSessionId'];
};

export type GQLCreateExistingCoveModelSamplingJobInput = {
  readonly modelId: Scalars['String'];
  readonly modelVersion: Scalars['Int'];
  readonly numSamples: Scalars['Int'];
  readonly samplingStrategy: GQLSamplingStrategyInput;
};

export type GQLCreateLocationBankInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly locations: ReadonlyArray<GQLLocationAreaInput>;
  readonly name: Scalars['String'];
};

export type GQLCreateManualReviewJobCommentInput = {
  readonly commentText: Scalars['String'];
  readonly jobId: Scalars['String'];
};

export type GQLCreateManualReviewQueueInput = {
  readonly autoCloseJobs: Scalars['Boolean'];
  readonly description?: InputMaybe<Scalars['String']>;
  readonly hiddenActionIds: ReadonlyArray<Scalars['ID']>;
  readonly isAppealsQueue: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly userIds: ReadonlyArray<Scalars['ID']>;
};

export type GQLCreateManualReviewQueueResponse =
  | GQLManualReviewQueueNameExistsError
  | GQLMutateManualReviewQueueSuccessResponse;

export type GQLCreateOrgInput = {
  readonly email: Scalars['String'];
  readonly name: Scalars['String'];
  readonly website: Scalars['String'];
};

export type GQLCreateOrgResponse =
  | GQLCreateOrgSuccessResponse
  | GQLOrgWithEmailExistsError
  | GQLOrgWithNameExistsError;

export type GQLCreateOrgSuccessResponse = {
  readonly __typename: 'CreateOrgSuccessResponse';
  readonly id: Scalars['ID'];
};

export type GQLCreateReportingRuleInput = {
  readonly actionIds: ReadonlyArray<Scalars['ID']>;
  readonly conditionSet: GQLConditionSetInput;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly itemTypeIds: ReadonlyArray<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly policyIds: ReadonlyArray<Scalars['ID']>;
  readonly status: GQLReportingRuleStatus;
};

export type GQLCreateReportingRuleResponse =
  | GQLMutateReportingRuleSuccessResponse
  | GQLReportingRuleNameExistsError;

export type GQLCreateRoutingRuleInput = {
  readonly conditionSet: GQLConditionSetInput;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly destinationQueueId: Scalars['ID'];
  readonly isAppealsRule?: InputMaybe<Scalars['Boolean']>;
  readonly itemTypeIds: ReadonlyArray<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly sequenceNumber?: InputMaybe<Scalars['Int']>;
  readonly status: GQLRoutingRuleStatus;
};

export type GQLCreateRoutingRuleResponse =
  | GQLMutateRoutingRuleSuccessResponse
  | GQLQueueDoesNotExistError
  | GQLRoutingRuleNameExistsError;

export type GQLCreateRulesForCoveModelInput = {
  readonly model: GQLCoveModelIdentifierInput;
  readonly status: GQLRuleStatus;
  readonly thresholds: ReadonlyArray<GQLCoveModelRuleThreshold>;
};

export type GQLCreateRulesForModelSuccessResponse = {
  readonly __typename: 'CreateRulesForModelSuccessResponse';
  readonly rules: ReadonlyArray<GQLRule>;
};

export type GQLCreateSamplingJobResponse = {
  readonly __typename: 'CreateSamplingJobResponse';
  readonly jobId: Scalars['ID'];
};

export type GQLCreateTextBankInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly strings: ReadonlyArray<Scalars['String']>;
  readonly type: GQLTextBankType;
};

export type GQLCreateThreadItemTypeInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly fieldRoles: GQLThreadSchemaFieldRolesInput;
  readonly fields: ReadonlyArray<GQLFieldInput>;
  readonly hiddenFields?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly name: Scalars['String'];
};

export type GQLCreateUserItemTypeInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly fieldRoles: GQLUserSchemaFieldRolesInput;
  readonly fields: ReadonlyArray<GQLFieldInput>;
  readonly hiddenFields?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly name: Scalars['String'];
};

export type GQLCreateUserRuleInput = {
  readonly actionIds: ReadonlyArray<Scalars['ID']>;
  readonly conditionSet: GQLConditionSetInput;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly expirationTime?: InputMaybe<Scalars['DateTime']>;
  readonly maxDailyActions?: InputMaybe<Scalars['Float']>;
  readonly name: Scalars['String'];
  readonly parentId?: InputMaybe<Scalars['ID']>;
  readonly policyIds: ReadonlyArray<Scalars['ID']>;
  readonly rateLimitConfig?: InputMaybe<GQLRateLimitConfigInput>;
  readonly status: GQLRuleStatus;
  readonly tags: ReadonlyArray<Scalars['String']>;
};

export type GQLCreateUserRuleResponse =
  | GQLMutateUserRuleSuccessResponse
  | GQLRuleNameExistsError;

export type GQLCustomMrtApiParamSpec = {
  readonly __typename: 'CustomMrtApiParamSpec';
  readonly displayName: Scalars['String'];
  readonly name: Scalars['String'];
  readonly type: Scalars['String'];
};

export type GQLCustomerActionDecisionAction = {
  readonly id: Scalars['ID'];
};

export type GQLCustomerActionDecisionPolicy = {
  readonly id: Scalars['ID'];
};

export type GQLCustomerDefinedAction = GQLActionBase & {
  readonly __typename: 'CustomerDefinedAction';
  readonly applyUserStrikes?: Maybe<Scalars['Boolean']>;
  readonly callbackUrl: Scalars['String'];
  readonly callbackUrlBody?: Maybe<Scalars['JSONObject']>;
  readonly callbackUrlHeaders?: Maybe<Scalars['JSONObject']>;
  readonly customMrtApiParams: ReadonlyArray<Maybe<GQLCustomMrtApiParamSpec>>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly itemTypes: ReadonlyArray<GQLItemType>;
  readonly name: Scalars['String'];
  readonly orgId: Scalars['String'];
  readonly penalty: GQLUserPenaltySeverity;
};

export type GQLCustomerInfoInput = {
  readonly company: Scalars['String'];
  readonly email: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
};

export type GQLCustomerOrRelatedActionDecisionComponent =
  GQLManualReviewDecisionComponentBase & {
    readonly __typename: 'CustomerOrRelatedActionDecisionComponent';
    readonly actionIds: ReadonlyArray<Scalars['String']>;
    readonly customMrtApiParams?: Maybe<Scalars['JSONObject']>;
    readonly itemIds: ReadonlyArray<Scalars['String']>;
    readonly itemTypeId: Scalars['String'];
    readonly policyIds: ReadonlyArray<Scalars['String']>;
    readonly type: GQLManualReviewDecisionType;
  };

export type GQLDailyUsageStatistics = {
  readonly __typename: 'DailyUsageStatistics';
  readonly date: Scalars['Date'];
  readonly numCreditsUsed: Scalars['Float'];
  readonly numImages: Scalars['Int'];
  readonly numItems: Scalars['Int'];
};

export const GQLDecisionActionType = {
  CustomerAction: 'CUSTOMER_ACTION',
  RelatedAction: 'RELATED_ACTION',
} as const;

export type GQLDecisionActionType =
  (typeof GQLDecisionActionType)[keyof typeof GQLDecisionActionType];
export type GQLDecisionCount = {
  readonly __typename: 'DecisionCount';
  readonly action_id?: Maybe<Scalars['String']>;
  readonly count: Scalars['Int'];
  readonly policy_id?: Maybe<Scalars['String']>;
  readonly queue_id?: Maybe<Scalars['String']>;
  readonly reviewer_id?: Maybe<Scalars['String']>;
  readonly time: Scalars['String'];
  readonly type?: Maybe<GQLManualReviewDecisionType>;
};

export type GQLDecisionCountFilterBy = {
  readonly __typename: 'DecisionCountFilterBy';
  readonly actionIds: ReadonlyArray<Scalars['String']>;
  readonly endDate: Scalars['DateTime'];
  readonly filteredDecisionActionType?: Maybe<
    ReadonlyArray<GQLDecisionActionType>
  >;
  readonly itemTypeIds: ReadonlyArray<Scalars['String']>;
  readonly policyIds: ReadonlyArray<Scalars['String']>;
  readonly queueIds: ReadonlyArray<Scalars['String']>;
  readonly reviewerIds: ReadonlyArray<Scalars['String']>;
  readonly startDate: Scalars['DateTime'];
  readonly type: ReadonlyArray<GQLManualReviewDecisionType>;
};

export type GQLDecisionCountFilterByInput = {
  readonly actionIds: ReadonlyArray<Scalars['String']>;
  readonly endDate: Scalars['DateTime'];
  readonly filteredDecisionActionType?: InputMaybe<
    ReadonlyArray<GQLDecisionActionType>
  >;
  readonly itemTypeIds: ReadonlyArray<Scalars['String']>;
  readonly policyIds: ReadonlyArray<Scalars['String']>;
  readonly queueIds: ReadonlyArray<Scalars['String']>;
  readonly reviewerIds: ReadonlyArray<Scalars['String']>;
  readonly startDate: Scalars['DateTime'];
  readonly type: ReadonlyArray<GQLManualReviewDecisionType>;
};

export const GQLDecisionCountGroupByColumns = {
  PolicyId: 'POLICY_ID',
  QueueId: 'QUEUE_ID',
  ReviewerId: 'REVIEWER_ID',
  Type: 'TYPE',
} as const;

export type GQLDecisionCountGroupByColumns =
  (typeof GQLDecisionCountGroupByColumns)[keyof typeof GQLDecisionCountGroupByColumns];
export type GQLDecisionCountSettingsInput = {
  readonly filterBy: GQLDecisionCountFilterByInput;
  readonly groupBy: ReadonlyArray<GQLDecisionCountGroupByColumns>;
  readonly timeDivision: GQLMetricsTimeDivisionOptions;
};

export type GQLDecisionCountTableFilterByInput = {
  readonly endDate: Scalars['DateTime'];
  readonly queueIds: ReadonlyArray<Scalars['String']>;
  readonly reviewerIds: ReadonlyArray<Scalars['String']>;
  readonly startDate: Scalars['DateTime'];
};

export type GQLDecisionSubmission = {
  readonly acceptAppeal?: InputMaybe<GQLSubmitAppealDecisionInput>;
  readonly customerAction?: InputMaybe<GQLExecuteBulkActionsInput>;
  readonly ignore?: InputMaybe<GQLProtegoActionDecisionInput>;
  readonly rejectAppeal?: InputMaybe<GQLSubmitAppealDecisionInput>;
  readonly submitNcmecReport?: InputMaybe<GQLSubmitNcmecReportInput>;
  readonly transformJobAndRecreateInQueue?: InputMaybe<GQLTransformJobAndRecreateInQueue>;
};

export const GQLDecisionsCountGroupBy = {
  QueueId: 'QUEUE_ID',
  ReviewerId: 'REVIEWER_ID',
} as const;

export type GQLDecisionsCountGroupBy =
  (typeof GQLDecisionsCountGroupBy)[keyof typeof GQLDecisionsCountGroupBy];
export type GQLDeleteAllJobsFromQueueResponse =
  | GQLDeleteAllJobsFromQueueSuccessResponse
  | GQLDeleteAllJobsUnauthorizedError;

export type GQLDeleteAllJobsFromQueueSuccessResponse = {
  readonly __typename: 'DeleteAllJobsFromQueueSuccessResponse';
  readonly _: Scalars['Boolean'];
};

export type GQLDeleteAllJobsUnauthorizedError = GQLError & {
  readonly __typename: 'DeleteAllJobsUnauthorizedError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLDeleteItemTypeResponse =
  | GQLCannotDeleteDefaultUserError
  | GQLDeleteItemTypeSuccessResponse;

export type GQLDeleteItemTypeSuccessResponse = {
  readonly __typename: 'DeleteItemTypeSuccessResponse';
  readonly _?: Maybe<Scalars['Boolean']>;
};

export type GQLDeleteManualReviewJobCommentInput = {
  readonly commentId: Scalars['String'];
  readonly jobId: Scalars['String'];
};

export type GQLDeleteRoutingRuleInput = {
  readonly id: Scalars['ID'];
  readonly isAppealsRule?: InputMaybe<Scalars['Boolean']>;
};

export type GQLDequeueManualReviewJobResponse =
  GQLDequeueManualReviewJobSuccessResponse;

export type GQLDequeueManualReviewJobSuccessResponse = {
  readonly __typename: 'DequeueManualReviewJobSuccessResponse';
  readonly job: GQLManualReviewJob;
  readonly lockToken: Scalars['String'];
  readonly numPendingJobs: Scalars['Int'];
};

export type GQLDerivedField = GQLField & {
  readonly __typename: 'DerivedField';
  readonly container?: Maybe<GQLContainer>;
  readonly name: Scalars['String'];
  readonly spec: GQLDerivedFieldSpec;
  readonly type: GQLFieldType;
};

export const GQLDerivedFieldDerivationType = {
  EnglishTranslation: 'ENGLISH_TRANSLATION',
  Ocr: 'OCR',
  VideoTranscription: 'VIDEO_TRANSCRIPTION',
} as const;

export type GQLDerivedFieldDerivationType =
  (typeof GQLDerivedFieldDerivationType)[keyof typeof GQLDerivedFieldDerivationType];
export type GQLDerivedFieldFieldSource = {
  readonly __typename: 'DerivedFieldFieldSource';
  readonly contentTypeId: Scalars['String'];
  readonly name: Scalars['String'];
};

export type GQLDerivedFieldFieldSourceInput = {
  readonly contentTypeId: Scalars['String'];
  readonly name: Scalars['String'];
};

export type GQLDerivedFieldFullItemSource = {
  readonly __typename: 'DerivedFieldFullItemSource';
  readonly _?: Maybe<Scalars['Boolean']>;
};

export type GQLDerivedFieldFullItemSourceInput = {
  readonly _?: InputMaybe<Scalars['Boolean']>;
};

export type GQLDerivedFieldProtegoInputSource = {
  readonly __typename: 'DerivedFieldProtegoInputSource';
  readonly name: GQLProtegoInput;
};

export type GQLDerivedFieldProtegoInputSourceInput = {
  readonly name: GQLProtegoInput;
};

export type GQLDerivedFieldSource =
  | GQLDerivedFieldFieldSource
  | GQLDerivedFieldFullItemSource
  | GQLDerivedFieldProtegoInputSource;

export type GQLDerivedFieldSourceInput = {
  readonly contentField?: InputMaybe<GQLDerivedFieldFieldSourceInput>;
  readonly contentProtegoInput?: InputMaybe<GQLDerivedFieldProtegoInputSourceInput>;
  readonly fullItem?: InputMaybe<GQLDerivedFieldFullItemSourceInput>;
};

export type GQLDerivedFieldSpec = {
  readonly __typename: 'DerivedFieldSpec';
  readonly derivationType: GQLDerivedFieldDerivationType;
  readonly source: GQLDerivedFieldSource;
};

export type GQLDerivedFieldSpecInput = {
  readonly derivationType: GQLDerivedFieldDerivationType;
  readonly source: GQLDerivedFieldSourceInput;
};

export type GQLDisabledInfo = {
  readonly __typename: 'DisabledInfo';
  readonly disabled: Scalars['Boolean'];
  readonly disabledMessage?: Maybe<Scalars['String']>;
};

export type GQLDisabledInfoInput = {
  readonly disabled?: InputMaybe<Scalars['Boolean']>;
  readonly disabledMessage?: InputMaybe<Scalars['String']>;
};

export type GQLEnqueueAuthorToMrtAction = GQLActionBase & {
  readonly __typename: 'EnqueueAuthorToMrtAction';
  readonly applyUserStrikes: Scalars['Boolean'];
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly itemTypes: ReadonlyArray<GQLItemType>;
  readonly name: Scalars['String'];
  readonly orgId: Scalars['String'];
  readonly penalty: GQLUserPenaltySeverity;
};

export type GQLEnqueueToMrtAction = GQLActionBase & {
  readonly __typename: 'EnqueueToMrtAction';
  readonly applyUserStrikes?: Maybe<Scalars['Boolean']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly itemTypes: ReadonlyArray<GQLItemType>;
  readonly name: Scalars['String'];
  readonly orgId: Scalars['String'];
  readonly penalty: GQLUserPenaltySeverity;
};

export type GQLEnqueueToNcmecAction = GQLActionBase & {
  readonly __typename: 'EnqueueToNcmecAction';
  readonly applyUserStrikes?: Maybe<Scalars['Boolean']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly itemTypes: ReadonlyArray<GQLItemType>;
  readonly name: Scalars['String'];
  readonly orgId: Scalars['String'];
  readonly penalty: GQLUserPenaltySeverity;
};

export type GQLEnumSignalOutputType = {
  readonly __typename: 'EnumSignalOutputType';
  readonly enum: ReadonlyArray<Scalars['String']>;
  readonly ordered: Scalars['Boolean'];
  readonly scalarType: GQLScalarType;
};

/** Base type for all errors. */
export type GQLError = {
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLExecuteActionResponse = {
  readonly __typename: 'ExecuteActionResponse';
  readonly actionId: Scalars['String'];
  readonly itemId: Scalars['String'];
  readonly success: Scalars['Boolean'];
};

export type GQLExecuteBulkActionInput = {
  readonly actionIds: ReadonlyArray<Scalars['String']>;
  readonly itemIds: ReadonlyArray<Scalars['String']>;
  readonly itemTypeId: Scalars['String'];
  readonly policyIds: ReadonlyArray<Scalars['String']>;
};

export type GQLExecuteBulkActionResponse = {
  readonly __typename: 'ExecuteBulkActionResponse';
  readonly results: ReadonlyArray<GQLExecuteActionResponse>;
};

export type GQLExecuteBulkActionsInput = {
  readonly actionIds: ReadonlyArray<Scalars['String']>;
  readonly actionIdsToMrtApiParamDecisionPayload?: InputMaybe<
    Scalars['JSONObject']
  >;
  readonly itemIds: ReadonlyArray<Scalars['String']>;
  readonly itemTypeId: Scalars['String'];
  readonly policyIds: ReadonlyArray<Scalars['String']>;
};

export type GQLField = {
  readonly container?: Maybe<GQLContainer>;
  readonly name: Scalars['String'];
  readonly type: GQLFieldType;
};

export type GQLFieldInput = {
  readonly container?: InputMaybe<GQLContainerInput>;
  readonly name: Scalars['String'];
  readonly required: Scalars['Boolean'];
  readonly type: GQLFieldType;
};

export const GQLFieldType = {
  Array: 'ARRAY',
  Audio: 'AUDIO',
  Boolean: 'BOOLEAN',
  Datetime: 'DATETIME',
  Geohash: 'GEOHASH',
  Id: 'ID',
  Image: 'IMAGE',
  Map: 'MAP',
  Number: 'NUMBER',
  PolicyId: 'POLICY_ID',
  RelatedItem: 'RELATED_ITEM',
  String: 'STRING',
  Url: 'URL',
  UserId: 'USER_ID',
  Video: 'VIDEO',
} as const;

export type GQLFieldType = (typeof GQLFieldType)[keyof typeof GQLFieldType];
export type GQLFollowModelGuidanceSamplingStrategyInput = {
  readonly _?: InputMaybe<Scalars['Boolean']>;
};

export const GQLForgotPasswordError = {
  Other: 'OTHER',
  UserNotFound: 'USER_NOT_FOUND',
} as const;

export type GQLForgotPasswordError =
  (typeof GQLForgotPasswordError)[keyof typeof GQLForgotPasswordError];
export type GQLGeneratePolicyInput = {
  readonly companyType?: InputMaybe<Scalars['String']>;
  readonly policyName: Scalars['String'];
};

export type GQLGeneratedPolicyTextCompletionError = GQLError & {
  readonly __typename: 'GeneratedPolicyTextCompletionError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLGeneratedPolicyTextCompletionResult =
  | GQLGeneratedPolicyTextCompletionError
  | GQLGeneratedPolicyTextCompletionSuccess;

export type GQLGeneratedPolicyTextCompletionSuccess = {
  readonly __typename: 'GeneratedPolicyTextCompletionSuccess';
  readonly policyText: Scalars['String'];
};

export type GQLGetDecisionCountInput = {
  readonly filterBy: GQLDecisionCountFilterByInput;
  readonly groupBy: ReadonlyArray<GQLDecisionCountGroupByColumns>;
  readonly timeDivision: GQLMetricsTimeDivisionOptions;
  readonly timeZone: Scalars['String'];
};

export type GQLGetDecisionCountSettings = {
  readonly __typename: 'GetDecisionCountSettings';
  readonly filterBy: GQLDecisionCountFilterBy;
  readonly groupBy: ReadonlyArray<GQLDecisionCountGroupByColumns>;
  readonly metric: GQLManualReviewChartMetric;
  readonly timeDivision: GQLMetricsTimeDivisionOptions;
  readonly title: Scalars['String'];
};

export type GQLGetDecisionCountsTableInput = {
  readonly filterBy: GQLDecisionCountTableFilterByInput;
  readonly groupBy: GQLDecisionsCountGroupBy;
  readonly timeZone: Scalars['String'];
};

export type GQLGetFullReportingRuleResultForItemResponse =
  | GQLNotFoundError
  | GQLReportingRuleExecutionResult;

export type GQLGetFullResultForItemInput = {
  readonly date?: InputMaybe<Scalars['String']>;
  readonly item: GQLItemIdentifierInput;
  readonly lookback?: InputMaybe<GQLLookbackVersion>;
  readonly ruleId: Scalars['ID'];
};

export type GQLGetFullResultForItemResponse =
  | GQLNotFoundError
  | GQLRuleExecutionResult;

export type GQLGetJobCreationCountInput = {
  readonly filterBy: GQLJobCreationFilterByInput;
  readonly groupBy: ReadonlyArray<GQLJobCreationGroupByColumns>;
  readonly timeDivision: GQLMetricsTimeDivisionOptions;
  readonly timeZone: Scalars['String'];
};

export type GQLGetJobCreationCountSettings = {
  readonly __typename: 'GetJobCreationCountSettings';
  readonly filterBy: GQLJobCreationFilterBy;
  readonly groupBy: ReadonlyArray<GQLJobCreationGroupByColumns>;
  readonly metric: GQLManualReviewChartMetric;
  readonly timeDivision: GQLMetricsTimeDivisionOptions;
  readonly title: Scalars['String'];
};

export type GQLGetLabelsForPolicyInput = {
  readonly policyId: Scalars['ID'];
};

export type GQLGetLabelsForPolicyResponse = {
  readonly __typename: 'GetLabelsForPolicyResponse';
  readonly items: ReadonlyArray<GQLCoveModelItem>;
  readonly labels: ReadonlyArray<GQLCoveModelLabel>;
};

export type GQLGetRedditExamplesForLabelingScreenInput = {
  readonly policyName: Scalars['String'];
};

export type GQLGetRedditExamplesForLabelingScreenResponse = {
  readonly __typename: 'GetRedditExamplesForLabelingScreenResponse';
  readonly items: ReadonlyArray<Scalars['String']>;
};

export type GQLGetRedditExamplesForThresholdScreenInput = {
  readonly policyName: Scalars['String'];
};

export type GQLGetRedditExamplesForThresholdScreenResponse = {
  readonly __typename: 'GetRedditExamplesForThresholdScreenResponse';
  readonly items: ReadonlyArray<GQLScoredRedditExample>;
};

export type GQLGetResolvedJobCountInput = {
  readonly filterBy: GQLJobCountFilterByInput;
  readonly groupBy: ReadonlyArray<GQLJobCountGroupByColumns>;
  readonly timeDivision: GQLMetricsTimeDivisionOptions;
  readonly timeZone: Scalars['String'];
};

export type GQLGetSkippedJobCountInput = {
  readonly filterBy: GQLSkippedJobFilterByInput;
  readonly groupBy: ReadonlyArray<GQLSkippedJobCountGroupByColumns>;
  readonly timeDivision: GQLMetricsTimeDivisionOptions;
  readonly timeZone: Scalars['String'];
};

export type GQLGooglePlaceLocationInfo = {
  readonly __typename: 'GooglePlaceLocationInfo';
  readonly id: Scalars['ID'];
};

export type GQLGpt4oMiniSignalArgs = {
  readonly __typename: 'Gpt4oMiniSignalArgs';
  readonly policyId: Scalars['ID'];
};

export type GQLGpt4oMiniSignalArgsInput = {
  readonly policyId: Scalars['ID'];
};

export type GQLHiveIntegrationApiCredential = {
  readonly __typename: 'HiveIntegrationApiCredential';
  readonly apiKeys: ReadonlyArray<GQLHiveIntegrationApiCredentialPair>;
};

export type GQLHiveIntegrationApiCredentialInput = {
  readonly apiKeys: ReadonlyArray<GQLHiveIntegrationApiCredentialPairInput>;
};

export type GQLHiveIntegrationApiCredentialPair = {
  readonly __typename: 'HiveIntegrationApiCredentialPair';
  readonly apiKey: Scalars['String'];
  readonly project: Scalars['String'];
};

export type GQLHiveIntegrationApiCredentialPairInput = {
  readonly apiKey: Scalars['String'];
  readonly project: Scalars['String'];
};

export type GQLIgnoreDecisionComponent =
  GQLManualReviewDecisionComponentBase & {
    readonly __typename: 'IgnoreDecisionComponent';
    readonly type: GQLManualReviewDecisionType;
  };

export type GQLImageModelV1SignalCreationSessionInput = {
  readonly name: Scalars['String'];
  readonly policyIdentifier: GQLPolicyIdentifierInput;
};

export const GQLIntegration = {
  Akismet: 'AKISMET',
  AmazonRekognition: 'AMAZON_REKOGNITION',
  Checkstep: 'CHECKSTEP',
  Clarifai: 'CLARIFAI',
  Crisp: 'CRISP',
  Hive: 'HIVE',
  L1Ght: 'L1GHT',
  MicrosoftAzureContentModerator: 'MICROSOFT_AZURE_CONTENT_MODERATOR',
  Oopspam: 'OOPSPAM',
  OpenAi: 'OPEN_AI',
  Perspective: 'PERSPECTIVE',
  SightEngine: 'SIGHT_ENGINE',
  SpectrumLabs: 'SPECTRUM_LABS',
  Thorn: 'THORN',
  TwoHat: 'TWO_HAT',
} as const;

export type GQLIntegration =
  (typeof GQLIntegration)[keyof typeof GQLIntegration];
export type GQLIntegrationApiCredential =
  | GQLAmazonRekognitionIntegrationApiCredential
  | GQLCheckstepIntegrationApiCredential
  | GQLClarifaiIntegrationApiCredential
  | GQLHiveIntegrationApiCredential
  | GQLOpenAiIntegrationApiCredential
  | GQLPerspectiveIntegrationApiCredential
  | GQLSpectrumIntegrationApiCredential;

export type GQLIntegrationApiCredentialInput = {
  readonly checkstep?: InputMaybe<GQLCheckstepIntegrationApiCredentialInput>;
  readonly clarifai?: InputMaybe<GQLClarifaiIntegrationApiCredentialInput>;
  readonly hive?: InputMaybe<GQLHiveIntegrationApiCredentialInput>;
  readonly openAi?: InputMaybe<GQLOpenAiIntegrationApiCredentialInput>;
  readonly perspective?: InputMaybe<GQLPerspectiveIntegrationApiCredentialInput>;
  readonly rekognition?: InputMaybe<GQLAmazonRekognitionIntegrationApiCredentialInput>;
  readonly spectrum?: InputMaybe<GQLSpectrumIntegrationApiCredentialInput>;
};

export type GQLIntegrationConfig = {
  readonly __typename: 'IntegrationConfig';
  readonly apiCredential: GQLIntegrationApiCredential;
  readonly name: GQLIntegration;
};

export type GQLIntegrationConfigQueryResponse =
  | GQLIntegrationConfigSuccessResult
  | GQLIntegrationConfigUnsupportedIntegrationError;

export type GQLIntegrationConfigSuccessResult = {
  readonly __typename: 'IntegrationConfigSuccessResult';
  readonly config?: Maybe<GQLIntegrationConfig>;
};

export type GQLIntegrationConfigTooManyCredentialsError = GQLError & {
  readonly __typename: 'IntegrationConfigTooManyCredentialsError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLIntegrationConfigUnsupportedIntegrationError = GQLError & {
  readonly __typename: 'IntegrationConfigUnsupportedIntegrationError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLIntegrationEmptyInputCredentialsError = GQLError & {
  readonly __typename: 'IntegrationEmptyInputCredentialsError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLIntegrationNoInputCredentialsError = GQLError & {
  readonly __typename: 'IntegrationNoInputCredentialsError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLInviteUserInput = {
  readonly email: Scalars['String'];
  readonly role: GQLUserRole;
};

export type GQLInviteUserToken = {
  readonly __typename: 'InviteUserToken';
  readonly email: Scalars['String'];
  readonly orgId: Scalars['String'];
  readonly role: GQLUserRole;
  readonly samlEnabled: Scalars['Boolean'];
  readonly token: Scalars['String'];
};

export type GQLInviteUserTokenExpiredError = GQLError & {
  readonly __typename: 'InviteUserTokenExpiredError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLInviteUserTokenMissingError = GQLError & {
  readonly __typename: 'InviteUserTokenMissingError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLInviteUserTokenResponse =
  | GQLInviteUserTokenExpiredError
  | GQLInviteUserTokenMissingError
  | GQLInviteUserTokenSuccessResponse;

export type GQLInviteUserTokenSuccessResponse = {
  readonly __typename: 'InviteUserTokenSuccessResponse';
  readonly tokenData: GQLInviteUserToken;
};

export type GQLIpAddress = {
  readonly __typename: 'IpAddress';
  readonly ip: Scalars['String'];
  readonly port?: Maybe<Scalars['Int']>;
};

export type GQLIpAddressInput = {
  readonly ip: Scalars['String'];
  readonly port?: InputMaybe<Scalars['Int']>;
};

export type GQLItem = GQLContentItem | GQLThreadItem | GQLUserItem;

export type GQLItemAction = {
  readonly __typename: 'ItemAction';
  readonly actionId: Scalars['ID'];
  readonly actorId?: Maybe<Scalars['ID']>;
  readonly itemCreatorId?: Maybe<Scalars['ID']>;
  readonly itemCreatorTypeId?: Maybe<Scalars['ID']>;
  readonly itemId: Scalars['ID'];
  readonly itemTypeId: Scalars['ID'];
  readonly jobId?: Maybe<Scalars['ID']>;
  readonly policies: ReadonlyArray<Scalars['String']>;
  readonly ruleIds: ReadonlyArray<Scalars['ID']>;
  readonly ts: Scalars['DateTime'];
};

export type GQLItemBase = {
  readonly data: Scalars['JSONObject'];
  readonly id: Scalars['ID'];
  readonly submissionId: Scalars['ID'];
  readonly submissionTime?: Maybe<Scalars['DateTime']>;
  readonly type: GQLItemTypeBase;
};

export type GQLItemHistoryResponse = GQLItemHistoryResult | GQLNotFoundError;

export type GQLItemHistoryResult = {
  readonly __typename: 'ItemHistoryResult';
  readonly executions: ReadonlyArray<GQLRuleExecutionResult>;
  readonly item: GQLItem;
};

export type GQLItemIdentifier = {
  readonly __typename: 'ItemIdentifier';
  readonly id: Scalars['String'];
  readonly typeId: Scalars['String'];
};

export type GQLItemIdentifierInput = {
  readonly id: Scalars['String'];
  readonly typeId: Scalars['String'];
};

export type GQLItemInput = {
  readonly data: Scalars['JSONObject'];
  readonly itemId: Scalars['NonEmptyString'];
  readonly itemType: GQLItemTypeIdentifierInput;
};

export const GQLItemRequirement = {
  HasImages: 'HAS_IMAGES',
  HasText: 'HAS_TEXT',
  HasTextOrImagesOrVideos: 'HAS_TEXT_OR_IMAGES_OR_VIDEOS',
} as const;

export type GQLItemRequirement =
  (typeof GQLItemRequirement)[keyof typeof GQLItemRequirement];
export const GQLItemRequirementInput = {
  HasImages: 'HAS_IMAGES',
  HasText: 'HAS_TEXT',
  HasTextOrImagesOrVideos: 'HAS_TEXT_OR_IMAGES_OR_VIDEOS',
} as const;

export type GQLItemRequirementInput =
  (typeof GQLItemRequirementInput)[keyof typeof GQLItemRequirementInput];
export type GQLItemSubmissions = {
  readonly __typename: 'ItemSubmissions';
  readonly latest: GQLItem;
  readonly prior?: Maybe<ReadonlyArray<GQLItem>>;
};

export type GQLItemType =
  | GQLContentItemType
  | GQLThreadItemType
  | GQLUserItemType;

export type GQLItemTypeBase = {
  readonly baseFields: ReadonlyArray<GQLBaseField>;
  readonly derivedFields: ReadonlyArray<GQLDerivedField>;
  readonly description?: Maybe<Scalars['String']>;
  readonly hiddenFields: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly schemaVariant: GQLItemTypeSchemaVariant;
  readonly version: Scalars['String'];
};

export type GQLItemTypeIdentifier = {
  readonly __typename: 'ItemTypeIdentifier';
  readonly id: Scalars['String'];
  readonly schemaVariant: GQLItemTypeSchemaVariant;
  readonly version: Scalars['NonEmptyString'];
};

export type GQLItemTypeIdentifierInput = {
  readonly id: Scalars['NonEmptyString'];
  readonly schemaVariant: GQLItemTypeSchemaVariantInput;
  readonly version: Scalars['NonEmptyString'];
};

export type GQLItemTypeNameAlreadyExistsError = GQLError & {
  readonly __typename: 'ItemTypeNameAlreadyExistsError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export const GQLItemTypeSchemaVariant = {
  Original: 'ORIGINAL',
  Partial: 'PARTIAL',
} as const;

export type GQLItemTypeSchemaVariant =
  (typeof GQLItemTypeSchemaVariant)[keyof typeof GQLItemTypeSchemaVariant];
export const GQLItemTypeSchemaVariantInput = {
  Original: 'ORIGINAL',
} as const;

export type GQLItemTypeSchemaVariantInput =
  (typeof GQLItemTypeSchemaVariantInput)[keyof typeof GQLItemTypeSchemaVariantInput];
export type GQLItemWithParents = {
  readonly __typename: 'ItemWithParents';
  readonly item: GQLItemSubmissions;
  readonly parents: ReadonlyArray<GQLItemSubmissions>;
};

export type GQLJobCountFilterByInput = {
  readonly endDate: Scalars['DateTime'];
  readonly queueIds: ReadonlyArray<Scalars['String']>;
  readonly reviewerIds: ReadonlyArray<Scalars['String']>;
  readonly startDate: Scalars['DateTime'];
};

export const GQLJobCountGroupByColumns = {
  QueueId: 'QUEUE_ID',
  ReviewerId: 'REVIEWER_ID',
} as const;

export type GQLJobCountGroupByColumns =
  (typeof GQLJobCountGroupByColumns)[keyof typeof GQLJobCountGroupByColumns];
export type GQLJobCreationCount = {
  readonly __typename: 'JobCreationCount';
  readonly count: Scalars['Int'];
  readonly itemTypeId?: Maybe<Scalars['String']>;
  readonly policyId?: Maybe<Scalars['String']>;
  readonly queueId?: Maybe<Scalars['String']>;
  readonly ruleId?: Maybe<Scalars['String']>;
  readonly source?: Maybe<GQLJobCreationSourceOptions>;
  readonly time: Scalars['String'];
};

export type GQLJobCreationFilterBy = {
  readonly __typename: 'JobCreationFilterBy';
  readonly endDate: Scalars['DateTime'];
  readonly itemTypeIds: ReadonlyArray<Scalars['String']>;
  readonly policyIds: ReadonlyArray<Scalars['String']>;
  readonly queueIds: ReadonlyArray<Scalars['String']>;
  readonly ruleIds: ReadonlyArray<Scalars['String']>;
  readonly sources: ReadonlyArray<GQLJobCreationSourceOptions>;
  readonly startDate: Scalars['DateTime'];
};

export type GQLJobCreationFilterByInput = {
  readonly endDate: Scalars['DateTime'];
  readonly itemTypeIds: ReadonlyArray<Scalars['String']>;
  readonly policyIds: ReadonlyArray<Scalars['String']>;
  readonly queueIds: ReadonlyArray<Scalars['String']>;
  readonly ruleIds: ReadonlyArray<Scalars['String']>;
  readonly sources: ReadonlyArray<GQLJobCreationSourceOptions>;
  readonly startDate: Scalars['DateTime'];
};

export const GQLJobCreationGroupByColumns = {
  ItemTypeId: 'ITEM_TYPE_ID',
  PolicyId: 'POLICY_ID',
  QueueId: 'QUEUE_ID',
  Source: 'SOURCE',
} as const;

export type GQLJobCreationGroupByColumns =
  (typeof GQLJobCreationGroupByColumns)[keyof typeof GQLJobCreationGroupByColumns];
export type GQLJobCreationSettingsInput = {
  readonly filterBy: GQLJobCreationFilterByInput;
  readonly groupBy: ReadonlyArray<GQLJobCreationGroupByColumns>;
  readonly timeDivision: GQLMetricsTimeDivisionOptions;
};

export const GQLJobCreationSourceOptions = {
  Appeal: 'APPEAL',
  MrtJob: 'MRT_JOB',
  PostActions: 'POST_ACTIONS',
  Report: 'REPORT',
  RuleExecution: 'RULE_EXECUTION',
} as const;

export type GQLJobCreationSourceOptions =
  (typeof GQLJobCreationSourceOptions)[keyof typeof GQLJobCreationSourceOptions];
export type GQLJobHasAlreadyBeenSubmittedError = GQLError & {
  readonly __typename: 'JobHasAlreadyBeenSubmittedError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLLabelCount = {
  readonly __typename: 'LabelCount';
  readonly negativeCount: Scalars['Int'];
  readonly positiveCount: Scalars['Int'];
};

export type GQLLabelItemInput = {
  readonly item: GQLItemInput;
  readonly itemFieldJsonPointers: ReadonlyArray<Scalars['String']>;
  readonly policyIdentifier: GQLPolicyIdentifierInput;
  readonly samplingStrategy: Scalars['CoveModelSignalSamplingStrategyToken'];
  readonly violatesPolicy: GQLViolatesPolicy;
};

export type GQLLabelItemsInput = {
  readonly labeledItems: ReadonlyArray<GQLLabelItemInput>;
};

export type GQLLabelItemsResponse = {
  readonly __typename: 'LabelItemsResponse';
  readonly _?: Maybe<Scalars['Boolean']>;
};

export const GQLLanguage = {
  Abkhazian: 'ABKHAZIAN',
  Afar: 'AFAR',
  Afrikaans: 'AFRIKAANS',
  Akan: 'AKAN',
  Albanian: 'ALBANIAN',
  Amharic: 'AMHARIC',
  Arabic: 'ARABIC',
  Aragonese: 'ARAGONESE',
  Armenian: 'ARMENIAN',
  Assamese: 'ASSAMESE',
  Avaric: 'AVARIC',
  Avestan: 'AVESTAN',
  Aymara: 'AYMARA',
  Azerbaijani: 'AZERBAIJANI',
  Azeri: 'AZERI',
  Bambara: 'BAMBARA',
  Bashkir: 'BASHKIR',
  Basque: 'BASQUE',
  Belarusian: 'BELARUSIAN',
  Bengali: 'BENGALI',
  Bihari: 'BIHARI',
  Bislama: 'BISLAMA',
  Bokmal: 'BOKMAL',
  Bosnian: 'BOSNIAN',
  Breton: 'BRETON',
  Bulgarian: 'BULGARIAN',
  Burmese: 'BURMESE',
  Catalan: 'CATALAN',
  Cebuano: 'CEBUANO',
  CentralKhmer: 'CENTRAL_KHMER',
  Chamorro: 'CHAMORRO',
  Chechen: 'CHECHEN',
  Chinese: 'CHINESE',
  ChurchSlavic: 'CHURCH_SLAVIC',
  Chuvash: 'CHUVASH',
  Cornish: 'CORNISH',
  Corsican: 'CORSICAN',
  Cree: 'CREE',
  Croatian: 'CROATIAN',
  Czech: 'CZECH',
  Danish: 'DANISH',
  Dhivehi: 'DHIVEHI',
  Dutch: 'DUTCH',
  Dzongkha: 'DZONGKHA',
  English: 'ENGLISH',
  Esperanto: 'ESPERANTO',
  Estonian: 'ESTONIAN',
  Ewe: 'EWE',
  Faroese: 'FAROESE',
  Farsi: 'FARSI',
  Fijian: 'FIJIAN',
  Finnish: 'FINNISH',
  Flemish: 'FLEMISH',
  French: 'FRENCH',
  Fulah: 'FULAH',
  Gaelic: 'GAELIC',
  Galician: 'GALICIAN',
  Ganda: 'GANDA',
  Georgian: 'GEORGIAN',
  German: 'GERMAN',
  Greek: 'GREEK',
  Guarani: 'GUARANI',
  Gujarati: 'GUJARATI',
  Haitian: 'HAITIAN',
  Hausa: 'HAUSA',
  Hawaiian: 'HAWAIIAN',
  Hebrew: 'HEBREW',
  Herero: 'HERERO',
  Hindi: 'HINDI',
  HiriMotu: 'HIRI_MOTU',
  Hungarian: 'HUNGARIAN',
  Icelandic: 'ICELANDIC',
  Ido: 'IDO',
  Igbo: 'IGBO',
  Indonesian: 'INDONESIAN',
  Interlingua: 'INTERLINGUA',
  Inuktitut: 'INUKTITUT',
  Inupiaq: 'INUPIAQ',
  Irish: 'IRISH',
  Italian: 'ITALIAN',
  Japanese: 'JAPANESE',
  Javanese: 'JAVANESE',
  Kalaallisut: 'KALAALLISUT',
  Kannada: 'KANNADA',
  Kanuri: 'KANURI',
  Kashmiri: 'KASHMIRI',
  Kazakh: 'KAZAKH',
  Kikuyu: 'KIKUYU',
  Kinyarwanda: 'KINYARWANDA',
  Komi: 'KOMI',
  Kongo: 'KONGO',
  Korean: 'KOREAN',
  Kuanyama: 'KUANYAMA',
  Kurdish: 'KURDISH',
  Kyrgyz: 'KYRGYZ',
  Lao: 'LAO',
  Latin: 'LATIN',
  Latvian: 'LATVIAN',
  Limburgan: 'LIMBURGAN',
  Lingala: 'LINGALA',
  Lithuanian: 'LITHUANIAN',
  LubaKatanga: 'LUBA_KATANGA',
  Luxembourgish: 'LUXEMBOURGISH',
  Macedonian: 'MACEDONIAN',
  Malagasy: 'MALAGASY',
  Malay: 'MALAY',
  Malayalam: 'MALAYALAM',
  Maltese: 'MALTESE',
  Manx: 'MANX',
  Maori: 'MAORI',
  Marathi: 'MARATHI',
  Marshallese: 'MARSHALLESE',
  Moldovian: 'MOLDOVIAN',
  Mongolian: 'MONGOLIAN',
  Nauru: 'NAURU',
  Navajo: 'NAVAJO',
  Ndonga: 'NDONGA',
  Nepali: 'NEPALI',
  NorthernSami: 'NORTHERN_SAMI',
  NorthNdebele: 'NORTH_NDEBELE',
  Norwegian: 'NORWEGIAN',
  Nyanja: 'NYANJA',
  Occitan: 'OCCITAN',
  Ojibwa: 'OJIBWA',
  Oriya: 'ORIYA',
  Oromo: 'OROMO',
  Ossetian: 'OSSETIAN',
  Pali: 'PALI',
  Pashto: 'PASHTO',
  Persian: 'PERSIAN',
  Pidgin: 'PIDGIN',
  Polish: 'POLISH',
  Portuguese: 'PORTUGUESE',
  Punjabi: 'PUNJABI',
  Quechua: 'QUECHUA',
  Romanian: 'ROMANIAN',
  Romansh: 'ROMANSH',
  Rundi: 'RUNDI',
  Russian: 'RUSSIAN',
  Samoan: 'SAMOAN',
  Sango: 'SANGO',
  Sanskrit: 'SANSKRIT',
  Sardinian: 'SARDINIAN',
  Serbian: 'SERBIAN',
  Shona: 'SHONA',
  SichuanYi: 'SICHUAN_YI',
  Sindhi: 'SINDHI',
  Sinhalese: 'SINHALESE',
  Slovak: 'SLOVAK',
  Slovene: 'SLOVENE',
  Somali: 'SOMALI',
  SouthernSotho: 'SOUTHERN_SOTHO',
  SouthNdebele: 'SOUTH_NDEBELE',
  Spanish: 'SPANISH',
  Sundanese: 'SUNDANESE',
  Swahili: 'SWAHILI',
  Swati: 'SWATI',
  Swedish: 'SWEDISH',
  Tagalog: 'TAGALOG',
  Tahitian: 'TAHITIAN',
  Tajik: 'TAJIK',
  Tamil: 'TAMIL',
  Tatar: 'TATAR',
  Telugu: 'TELUGU',
  Thai: 'THAI',
  Tibetan: 'TIBETAN',
  Tigrinya: 'TIGRINYA',
  Tonga: 'TONGA',
  Tsonga: 'TSONGA',
  Tswana: 'TSWANA',
  Turkish: 'TURKISH',
  Turkmen: 'TURKMEN',
  Twi: 'TWI',
  Ukrainian: 'UKRAINIAN',
  Urdu: 'URDU',
  Uyghur: 'UYGHUR',
  Uzbek: 'UZBEK',
  Venda: 'VENDA',
  Vietnamese: 'VIETNAMESE',
  Volapuk: 'VOLAPUK',
  Walloon: 'WALLOON',
  Welsh: 'WELSH',
  WesternFrisian: 'WESTERN_FRISIAN',
  Wolof: 'WOLOF',
  Xhosa: 'XHOSA',
  Yiddish: 'YIDDISH',
  Yoruba: 'YORUBA',
  Zhuang: 'ZHUANG',
  Zulu: 'ZULU',
} as const;

export type GQLLanguage = (typeof GQLLanguage)[keyof typeof GQLLanguage];
export type GQLLanguages = {
  readonly __typename: 'Languages';
  readonly languages: ReadonlyArray<GQLLanguage>;
};

export type GQLLatLng = {
  readonly __typename: 'LatLng';
  readonly lat: Scalars['Float'];
  readonly lng: Scalars['Float'];
};

export type GQLLatLngInput = {
  readonly lat: Scalars['Float'];
  readonly lng: Scalars['Float'];
};

export type GQLLeafCondition = {
  readonly __typename: 'LeafCondition';
  readonly comparator?: Maybe<GQLValueComparator>;
  readonly input: GQLConditionInputField;
  readonly matchingValues?: Maybe<GQLMatchingValues>;
  readonly signal?: Maybe<GQLSignal>;
  readonly threshold?: Maybe<Scalars['StringOrFloat']>;
};

export type GQLLeafConditionWithResult = {
  readonly __typename: 'LeafConditionWithResult';
  readonly comparator?: Maybe<GQLValueComparator>;
  readonly input: GQLConditionInputField;
  readonly matchingValues?: Maybe<GQLMatchingValues>;
  readonly result?: Maybe<GQLConditionResult>;
  readonly signal?: Maybe<GQLSignal>;
  readonly threshold?: Maybe<Scalars['StringOrFloat']>;
};

export type GQLLocationArea = {
  readonly __typename: 'LocationArea';
  readonly bounds?: Maybe<GQLPlaceBounds>;
  readonly geometry: GQLLocationGeometry;
  readonly googlePlaceInfo?: Maybe<GQLGooglePlaceLocationInfo>;
  readonly id: Scalars['ID'];
  readonly name?: Maybe<Scalars['String']>;
};

export type GQLLocationAreaInput = {
  readonly bounds?: InputMaybe<GQLPlaceBoundsInput>;
  readonly geometry: GQLLocationGeometryInput;
  readonly googlePlaceId?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
};

export type GQLLocationBank = {
  readonly __typename: 'LocationBank';
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly locations: ReadonlyArray<GQLLocationArea>;
  readonly name: Scalars['String'];
};

export type GQLLocationBankNameExistsError = GQLError & {
  readonly __typename: 'LocationBankNameExistsError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLLocationGeometry = {
  readonly __typename: 'LocationGeometry';
  readonly center: GQLLatLng;
  readonly radius: Scalars['Float'];
};

export type GQLLocationGeometryInput = {
  readonly center: GQLLatLngInput;
  readonly radius: Scalars['Float'];
};

export type GQLLogSkipInput = {
  readonly jobId: Scalars['String'];
  readonly queueId: Scalars['String'];
};

export type GQLLoginIncorrectPasswordError = GQLError & {
  readonly __typename: 'LoginIncorrectPasswordError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLLoginInput = {
  readonly email: Scalars['String'];
  readonly password: Scalars['String'];
  readonly remember?: InputMaybe<Scalars['Boolean']>;
};

export const GQLLoginMethod = {
  Password: 'PASSWORD',
  Saml: 'SAML',
} as const;

export type GQLLoginMethod =
  (typeof GQLLoginMethod)[keyof typeof GQLLoginMethod];
export type GQLLoginResponse =
  | GQLLoginIncorrectPasswordError
  | GQLLoginSsoRequiredError
  | GQLLoginSuccessResponse
  | GQLLoginUserDoesNotExistError;

export type GQLLoginSsoRequiredError = GQLError & {
  readonly __typename: 'LoginSsoRequiredError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLLoginSuccessResponse = {
  readonly __typename: 'LoginSuccessResponse';
  readonly user: GQLUser;
};

export type GQLLoginUserDoesNotExistError = GQLError & {
  readonly __typename: 'LoginUserDoesNotExistError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export const GQLLookbackVersion = {
  Latest: 'LATEST',
  Prior: 'PRIOR',
} as const;

export type GQLLookbackVersion =
  (typeof GQLLookbackVersion)[keyof typeof GQLLookbackVersion];
export type GQLManualReviewChartConfigurationsInput = {
  readonly chartConfigurations: ReadonlyArray<GQLManualReviewChartSettingsInput>;
};

export const GQLManualReviewChartMetric = {
  Decisions: 'DECISIONS',
  Jobs: 'JOBS',
} as const;

export type GQLManualReviewChartMetric =
  (typeof GQLManualReviewChartMetric)[keyof typeof GQLManualReviewChartMetric];
export type GQLManualReviewChartSettings =
  | GQLGetDecisionCountSettings
  | GQLGetJobCreationCountSettings;

export type GQLManualReviewChartSettingsInput = {
  readonly decisionCountSettings?: InputMaybe<GQLDecisionCountSettingsInput>;
  readonly jobCreationCountSettings?: InputMaybe<GQLJobCreationSettingsInput>;
  readonly metric: GQLManualReviewChartMetric;
  readonly title: Scalars['String'];
};

export type GQLManualReviewDecision = {
  readonly __typename: 'ManualReviewDecision';
  readonly createdAt: Scalars['DateTime'];
  readonly decisionReason?: Maybe<Scalars['String']>;
  readonly decisions: ReadonlyArray<GQLManualReviewDecisionComponent>;
  readonly id: Scalars['String'];
  readonly itemId?: Maybe<Scalars['String']>;
  readonly itemTypeId?: Maybe<Scalars['String']>;
  readonly jobId: Scalars['String'];
  readonly queueId: Scalars['String'];
  readonly relatedActions: ReadonlyArray<GQLManualReviewDecisionComponent>;
  readonly reviewerId?: Maybe<Scalars['String']>;
};

export type GQLManualReviewDecisionComponent =
  | GQLAcceptAppealDecisionComponent
  | GQLAutomaticCloseDecisionComponent
  | GQLCustomerOrRelatedActionDecisionComponent
  | GQLIgnoreDecisionComponent
  | GQLRejectAppealDecisionComponent
  | GQLSubmitNcmecReportDecisionComponent
  | GQLTransformJobAndRecreateInQueueDecisionComponent;

export type GQLManualReviewDecisionComponentBase = {
  readonly type: GQLManualReviewDecisionType;
};

export const GQLManualReviewDecisionType = {
  AcceptAppeal: 'ACCEPT_APPEAL',
  AutomaticClose: 'AUTOMATIC_CLOSE',
  CustomerAction: 'CUSTOMER_ACTION',
  Ignore: 'IGNORE',
  RejectAppeal: 'REJECT_APPEAL',
  RelatedAction: 'RELATED_ACTION',
  SubmitNcmecReport: 'SUBMIT_NCMEC_REPORT',
  TransformJobAndRecreateInQueue: 'TRANSFORM_JOB_AND_RECREATE_IN_QUEUE',
} as const;

export type GQLManualReviewDecisionType =
  (typeof GQLManualReviewDecisionType)[keyof typeof GQLManualReviewDecisionType];
export type GQLManualReviewExistingJob = {
  readonly __typename: 'ManualReviewExistingJob';
  readonly job: GQLManualReviewJob;
  readonly queueId: Scalars['String'];
};

export type GQLManualReviewJob = {
  readonly __typename: 'ManualReviewJob';
  readonly comments: ReadonlyArray<GQLManualReviewJobComment>;
  readonly createdAt: Scalars['DateTime'];
  readonly id: Scalars['ID'];
  readonly numTimesReported?: Maybe<Scalars['Int']>;
  readonly payload: GQLManualReviewJobPayload;
  readonly policyIds: ReadonlyArray<Scalars['String']>;
};

export type GQLManualReviewJobComment = {
  readonly __typename: 'ManualReviewJobComment';
  readonly author: GQLUser;
  readonly commentText: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly id: Scalars['ID'];
};

export type GQLManualReviewJobEnqueueSourceInfo =
  | GQLAppealEnqueueSourceInfo
  | GQLMrtJobEnqueueSourceInfo
  | GQLPostActionsEnqueueSourceInfo
  | GQLReportEnqueueSourceInfo
  | GQLRuleExecutionEnqueueSourceInfo;

export const GQLManualReviewJobKind = {
  Default: 'DEFAULT',
  Ncmec: 'NCMEC',
} as const;

export type GQLManualReviewJobKind =
  (typeof GQLManualReviewJobKind)[keyof typeof GQLManualReviewJobKind];
export type GQLManualReviewJobPayload =
  | GQLContentAppealManualReviewJobPayload
  | GQLContentManualReviewJobPayload
  | GQLNcmecManualReviewJobPayload
  | GQLThreadAppealManualReviewJobPayload
  | GQLThreadManualReviewJobPayload
  | GQLUserAppealManualReviewJobPayload
  | GQLUserManualReviewJobPayload;

export type GQLManualReviewJobWithDecisions = {
  readonly __typename: 'ManualReviewJobWithDecisions';
  readonly decision: GQLManualReviewDecision;
  readonly job: GQLManualReviewJob;
};

export type GQLManualReviewQueue = {
  readonly __typename: 'ManualReviewQueue';
  readonly autoCloseJobs: Scalars['Boolean'];
  readonly description?: Maybe<Scalars['String']>;
  readonly explicitlyAssignedReviewers: ReadonlyArray<GQLUser>;
  readonly hiddenActionIds: ReadonlyArray<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly isAppealsQueue: Scalars['Boolean'];
  readonly isDefaultQueue: Scalars['Boolean'];
  readonly jobs: ReadonlyArray<GQLManualReviewJob>;
  readonly name: Scalars['String'];
  readonly orgId: Scalars['ID'];
  readonly pendingJobCount: Scalars['Int'];
};

export type GQLManualReviewQueueJobsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type GQLManualReviewQueueNameExistsError = GQLError & {
  readonly __typename: 'ManualReviewQueueNameExistsError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLMatchingBanks = {
  readonly __typename: 'MatchingBanks';
  readonly locationBanks: ReadonlyArray<GQLLocationBank>;
  readonly textBanks: ReadonlyArray<GQLTextBank>;
};

export type GQLMatchingValues = {
  readonly __typename: 'MatchingValues';
  readonly locationBankIds?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly locations?: Maybe<ReadonlyArray<GQLLocationArea>>;
  readonly strings?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly textBankIds?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type GQLMessageWithIpAddress = {
  readonly __typename: 'MessageWithIpAddress';
  readonly ipAddress: GQLIpAddress;
  readonly message: GQLContentItem;
};

export const GQLMetricsTimeDivisionOptions = {
  Day: 'DAY',
  Hour: 'HOUR',
} as const;

export type GQLMetricsTimeDivisionOptions =
  (typeof GQLMetricsTimeDivisionOptions)[keyof typeof GQLMetricsTimeDivisionOptions];
export type GQLModelAliasReference = {
  readonly alias: GQLModelVersionAlias;
  readonly id: Scalars['NonEmptyString'];
};

export type GQLModelExecutionErrorResult = {
  readonly __typename: 'ModelExecutionErrorResult';
  readonly _?: Maybe<Scalars['Boolean']>;
};

export type GQLModelExecutionResult =
  | GQLModelExecutionErrorResult
  | GQLModelExecutionSuccessResult
  | GQLModelExecutionUnscoreableResult;

export type GQLModelExecutionSuccessResult = {
  readonly __typename: 'ModelExecutionSuccessResult';
  readonly score: Scalars['Float'];
};

export type GQLModelExecutionUnscoreableResult = {
  readonly __typename: 'ModelExecutionUnscoreableResult';
  readonly _?: Maybe<Scalars['Boolean']>;
};

export type GQLModelScore = {
  readonly __typename: 'ModelScore';
  readonly score?: Maybe<Scalars['Float']>;
};

export type GQLModelScoreSamplingStrategyInput = {
  readonly additionalScoringModel?: InputMaybe<GQLCoveModelIdentifierInput>;
  readonly score: GQLModelScoreSamplingStrategyThresholdInput;
};

export type GQLModelScoreSamplingStrategyThresholdInput = {
  readonly max: Scalars['Float'];
  readonly min: Scalars['Float'];
};

export const GQLModelVersionAlias = {
  Archived: 'ARCHIVED',
  Beta: 'BETA',
  Live: 'LIVE',
} as const;

export type GQLModelVersionAlias =
  (typeof GQLModelVersionAlias)[keyof typeof GQLModelVersionAlias];
export const GQLModelVersionStatus = {
  Deployed: 'DEPLOYED',
  ReadyToTrain: 'READY_TO_TRAIN',
  Training: 'TRAINING',
  TrainingFailed: 'TRAINING_FAILED',
} as const;

export type GQLModelVersionStatus =
  (typeof GQLModelVersionStatus)[keyof typeof GQLModelVersionStatus];
export type GQLModeratorSafetySettingsInput = {
  readonly moderatorSafetyBlurLevel: Scalars['Int'];
  readonly moderatorSafetyGrayscale: Scalars['Boolean'];
  readonly moderatorSafetyMuteVideo: Scalars['Boolean'];
};

export type GQLMrtJobEnqueueSourceInfo = {
  readonly __typename: 'MrtJobEnqueueSourceInfo';
  readonly kind: GQLJobCreationSourceOptions;
};

export type GQLMutateAccessibleQueuesForUserSuccessResponse = {
  readonly __typename: 'MutateAccessibleQueuesForUserSuccessResponse';
  readonly _: Scalars['Boolean'];
};

export const GQLMutateActionError = {
  ActionNameExists: 'ACTION_NAME_EXISTS',
} as const;

export type GQLMutateActionError =
  (typeof GQLMutateActionError)[keyof typeof GQLMutateActionError];
export type GQLMutateActionResponse =
  | GQLActionNameExistsError
  | GQLMutateActionSuccessResponse;

export type GQLMutateActionSuccessResponse = {
  readonly __typename: 'MutateActionSuccessResponse';
  readonly data: GQLCustomerDefinedAction;
};

export type GQLMutateBankResponse = {
  readonly __typename: 'MutateBankResponse';
  readonly error?: Maybe<Scalars['String']>;
  readonly success?: Maybe<Scalars['Boolean']>;
};

export type GQLMutateContentItemTypeResponse =
  | GQLItemTypeNameAlreadyExistsError
  | GQLMutateContentTypeSuccessResponse;

export type GQLMutateContentRuleSuccessResponse = {
  readonly __typename: 'MutateContentRuleSuccessResponse';
  readonly data: GQLContentRule;
};

export type GQLMutateContentTypeSuccessResponse = {
  readonly __typename: 'MutateContentTypeSuccessResponse';
  readonly data?: Maybe<GQLContentItemType>;
};

export type GQLMutateLocationBankResponse =
  | GQLLocationBankNameExistsError
  | GQLMutateLocationBankSuccessResponse;

export type GQLMutateLocationBankSuccessResponse = {
  readonly __typename: 'MutateLocationBankSuccessResponse';
  readonly data: GQLLocationBank;
};

export type GQLMutateManualReviewQueueSuccessResponse = {
  readonly __typename: 'MutateManualReviewQueueSuccessResponse';
  readonly data: GQLManualReviewQueue;
};

export type GQLMutateReportingRuleSuccessResponse = {
  readonly __typename: 'MutateReportingRuleSuccessResponse';
  readonly data: GQLReportingRule;
};

export type GQLMutateRoutingRuleSuccessResponse = {
  readonly __typename: 'MutateRoutingRuleSuccessResponse';
  readonly data: GQLRoutingRule;
};

export type GQLMutateRoutingRulesOrderSuccessResponse = {
  readonly __typename: 'MutateRoutingRulesOrderSuccessResponse';
  readonly data: ReadonlyArray<GQLRoutingRule>;
};

export type GQLMutateThreadItemTypeResponse =
  | GQLItemTypeNameAlreadyExistsError
  | GQLMutateThreadTypeSuccessResponse;

export type GQLMutateThreadTypeSuccessResponse = {
  readonly __typename: 'MutateThreadTypeSuccessResponse';
  readonly data?: Maybe<GQLThreadItemType>;
};

export type GQLMutateUserItemTypeResponse =
  | GQLItemTypeNameAlreadyExistsError
  | GQLMutateUserTypeSuccessResponse;

export type GQLMutateUserRuleSuccessResponse = {
  readonly __typename: 'MutateUserRuleSuccessResponse';
  readonly data: GQLUserRule;
};

export type GQLMutateUserTypeSuccessResponse = {
  readonly __typename: 'MutateUserTypeSuccessResponse';
  readonly data?: Maybe<GQLUserItemType>;
};

export type GQLMutation = {
  readonly __typename: 'Mutation';
  readonly addAccessibleQueuesToUser: GQLAddAccessibleQueuesToUserResponse;
  readonly addFavoriteMRTQueue: GQLAddFavoriteMrtQueueSuccessResponse;
  readonly addFavoriteRule: GQLAddFavoriteRuleSuccessResponse;
  readonly addPolicies: GQLAddPoliciesResponse;
  readonly approveUser?: Maybe<Scalars['Boolean']>;
  readonly bulkExecuteActions: GQLExecuteBulkActionResponse;
  readonly cancelSubscription?: Maybe<Scalars['Boolean']>;
  readonly createAction: GQLMutateActionResponse;
  readonly createBacktest?: Maybe<GQLCreateBacktestResponse>;
  readonly createContentItemType: GQLMutateContentItemTypeResponse;
  readonly createContentRule: GQLCreateContentRuleResponse;
  readonly createCoveModelSignalCreationSamplingJob: GQLCreateSamplingJobResponse;
  readonly createExistingCoveModelSamplingJob: GQLCreateSamplingJobResponse;
  readonly createLocationBank: GQLMutateLocationBankResponse;
  readonly createManualReviewJobComment: GQLAddManualReviewJobCommentResponse;
  readonly createManualReviewQueue: GQLCreateManualReviewQueueResponse;
  readonly createOrg: GQLCreateOrgResponse;
  readonly createReportingRule: GQLCreateReportingRuleResponse;
  readonly createRoutingRule: GQLCreateRoutingRuleResponse;
  readonly createRulesForModel: GQLCreateRulesForModelSuccessResponse;
  readonly createTextBank: GQLMutateBankResponse;
  readonly createThreadItemType: GQLMutateThreadItemTypeResponse;
  readonly createUserItemType: GQLMutateUserItemTypeResponse;
  readonly createUserRule: GQLCreateUserRuleResponse;
  readonly deleteAction?: Maybe<Scalars['Boolean']>;
  readonly deleteAllJobsFromQueue: GQLDeleteAllJobsFromQueueResponse;
  readonly deleteItemType: GQLDeleteItemTypeResponse;
  readonly deleteLabels: Scalars['Boolean'];
  readonly deleteLocationBank?: Maybe<Scalars['Boolean']>;
  readonly deleteManualReviewJobComment: Scalars['Boolean'];
  readonly deleteManualReviewQueue: Scalars['Boolean'];
  readonly deletePolicy?: Maybe<Scalars['Boolean']>;
  readonly deleteReportingRule: Scalars['Boolean'];
  readonly deleteRoutingRule: Scalars['Boolean'];
  readonly deleteRule?: Maybe<Scalars['Boolean']>;
  readonly deleteTextBank: Scalars['Boolean'];
  readonly deleteUser?: Maybe<Scalars['Boolean']>;
  readonly dequeueManualReviewJob?: Maybe<GQLDequeueManualReviewJobResponse>;
  readonly finishCoveModelSignalCreationSession: Scalars['Boolean'];
  readonly inviteUser?: Maybe<Scalars['Boolean']>;
  readonly labelItems: GQLLabelItemsResponse;
  readonly logSkip: Scalars['Boolean'];
  readonly login: GQLLoginResponse;
  readonly logout?: Maybe<Scalars['Boolean']>;
  readonly promoteBetaVersionToLive: Scalars['Boolean'];
  readonly rejectUser?: Maybe<Scalars['Boolean']>;
  readonly removeAccessibleQueuesToUser: GQLRemoveAccessibleQueuesToUserResponse;
  readonly removeAliasForModel: Scalars['Boolean'];
  readonly removeFavoriteMRTQueue: GQLRemoveFavoriteMrtQueueSuccessResponse;
  readonly removeFavoriteRule: GQLRemoveFavoriteRuleSuccessResponse;
  readonly reorderRoutingRules: GQLReorderRoutingRulesResponse;
  readonly requestDemo?: Maybe<Scalars['Boolean']>;
  readonly resetPassword: Scalars['Boolean'];
  readonly retrainExistingModel: GQLRetrainModelSuccessResponse;
  readonly runRetroaction?: Maybe<GQLRunRetroactionResponse>;
  readonly saveCustomerInfo?: Maybe<Scalars['Boolean']>;
  readonly sendPasswordReset: Scalars['Boolean'];
  readonly setAliasForModel: Scalars['Boolean'];
  readonly setAllUserStrikeThresholds: GQLSetAllUserStrikeThresholdsSuccessResponse;
  readonly setIntegrationConfig: GQLSetIntegrationConfigResponse;
  readonly setLabelsForItems: Scalars['Boolean'];
  readonly setModeratorSafetySettings?: Maybe<GQLSetModeratorSafetySettingsSuccessResponse>;
  readonly setMrtChartConfigurationSettings?: Maybe<GQLSetMrtChartConfigurationSettingsSuccessResponse>;
  readonly setOrgDefaultSafetySettings?: Maybe<GQLSetModeratorSafetySettingsSuccessResponse>;
  readonly signUp: GQLSignUpResponse;
  readonly startCoveModelSignalCreationSession: GQLStartCoveModelSignalCreationSessionResponse;
  readonly submitManualReviewDecision: GQLSubmitDecisionResponse;
  readonly updateAccountInfo?: Maybe<Scalars['Boolean']>;
  readonly updateAction: GQLMutateActionResponse;
  readonly updateAppealSettings: GQLAppealSettings;
  readonly updateContentItemType: GQLMutateContentItemTypeResponse;
  readonly updateContentRule: GQLUpdateContentRuleResponse;
  readonly updateLocationBank: GQLMutateLocationBankResponse;
  readonly updateManualReviewQueue: GQLUpdateManualReviewQueueQueueResponse;
  readonly updatePolicy: GQLUpdatePolicyResponse;
  readonly updateReportingRule: GQLUpdateReportingRuleResponse;
  readonly updateRole?: Maybe<Scalars['Boolean']>;
  readonly updateRoutingRule: GQLUpdateRoutingRuleResponse;
  readonly updateSSOCredentials: Scalars['Boolean'];
  readonly updateTextBank: GQLMutateBankResponse;
  readonly updateThreadItemType: GQLMutateThreadItemTypeResponse;
  readonly updateUserItemType: GQLMutateUserItemTypeResponse;
  readonly updateUserRule: GQLUpdateUserRuleResponse;
  readonly updateUserStrikeTTL: GQLUpdateUserStrikeTtlSuccessResponse;
};

export type GQLMutationAddAccessibleQueuesToUserArgs = {
  input: GQLAddAccessibleQueuesToUserInput;
};

export type GQLMutationAddFavoriteMrtQueueArgs = {
  queueId: Scalars['ID'];
};

export type GQLMutationAddFavoriteRuleArgs = {
  ruleId: Scalars['ID'];
};

export type GQLMutationAddPoliciesArgs = {
  policies: ReadonlyArray<GQLAddPolicyInput>;
};

export type GQLMutationApproveUserArgs = {
  id: Scalars['ID'];
};

export type GQLMutationBulkExecuteActionsArgs = {
  input: GQLExecuteBulkActionInput;
};

export type GQLMutationCreateActionArgs = {
  input: GQLCreateActionInput;
};

export type GQLMutationCreateBacktestArgs = {
  input: GQLCreateBacktestInput;
};

export type GQLMutationCreateContentItemTypeArgs = {
  input: GQLCreateContentItemTypeInput;
};

export type GQLMutationCreateContentRuleArgs = {
  input: GQLCreateContentRuleInput;
};

export type GQLMutationCreateCoveModelSignalCreationSamplingJobArgs = {
  input: GQLCreateCoveModelSignalCreationSamplingJobInput;
};

export type GQLMutationCreateExistingCoveModelSamplingJobArgs = {
  input: GQLCreateExistingCoveModelSamplingJobInput;
};

export type GQLMutationCreateLocationBankArgs = {
  input: GQLCreateLocationBankInput;
};

export type GQLMutationCreateManualReviewJobCommentArgs = {
  input: GQLCreateManualReviewJobCommentInput;
};

export type GQLMutationCreateManualReviewQueueArgs = {
  input: GQLCreateManualReviewQueueInput;
};

export type GQLMutationCreateOrgArgs = {
  input: GQLCreateOrgInput;
};

export type GQLMutationCreateReportingRuleArgs = {
  input: GQLCreateReportingRuleInput;
};

export type GQLMutationCreateRoutingRuleArgs = {
  input: GQLCreateRoutingRuleInput;
};

export type GQLMutationCreateRulesForModelArgs = {
  input: GQLCreateRulesForCoveModelInput;
};

export type GQLMutationCreateTextBankArgs = {
  input: GQLCreateTextBankInput;
};

export type GQLMutationCreateThreadItemTypeArgs = {
  input: GQLCreateThreadItemTypeInput;
};

export type GQLMutationCreateUserItemTypeArgs = {
  input: GQLCreateUserItemTypeInput;
};

export type GQLMutationCreateUserRuleArgs = {
  input: GQLCreateUserRuleInput;
};

export type GQLMutationDeleteActionArgs = {
  id: Scalars['ID'];
};

export type GQLMutationDeleteAllJobsFromQueueArgs = {
  queueId: Scalars['ID'];
};

export type GQLMutationDeleteItemTypeArgs = {
  id: Scalars['ID'];
};

export type GQLMutationDeleteLabelsArgs = {
  labelIds: ReadonlyArray<Scalars['ID']>;
};

export type GQLMutationDeleteLocationBankArgs = {
  id: Scalars['ID'];
};

export type GQLMutationDeleteManualReviewJobCommentArgs = {
  input: GQLDeleteManualReviewJobCommentInput;
};

export type GQLMutationDeleteManualReviewQueueArgs = {
  id: Scalars['ID'];
};

export type GQLMutationDeletePolicyArgs = {
  id: Scalars['ID'];
};

export type GQLMutationDeleteReportingRuleArgs = {
  id: Scalars['ID'];
};

export type GQLMutationDeleteRoutingRuleArgs = {
  input: GQLDeleteRoutingRuleInput;
};

export type GQLMutationDeleteRuleArgs = {
  id: Scalars['ID'];
};

export type GQLMutationDeleteTextBankArgs = {
  id: Scalars['ID'];
};

export type GQLMutationDeleteUserArgs = {
  id: Scalars['ID'];
};

export type GQLMutationDequeueManualReviewJobArgs = {
  queueId: Scalars['ID'];
};

export type GQLMutationFinishCoveModelSignalCreationSessionArgs = {
  sessionId: Scalars['CoveModelSignalCreationSessionId'];
};

export type GQLMutationInviteUserArgs = {
  input: GQLInviteUserInput;
};

export type GQLMutationLabelItemsArgs = {
  input: GQLLabelItemsInput;
};

export type GQLMutationLogSkipArgs = {
  input: GQLLogSkipInput;
};

export type GQLMutationLoginArgs = {
  input: GQLLoginInput;
};

export type GQLMutationPromoteBetaVersionToLiveArgs = {
  id: Scalars['NonEmptyString'];
};

export type GQLMutationRejectUserArgs = {
  id: Scalars['ID'];
};

export type GQLMutationRemoveAccessibleQueuesToUserArgs = {
  input: GQLRemoveAccessibleQueuesToUserInput;
};

export type GQLMutationRemoveAliasForModelArgs = {
  input: GQLModelAliasReference;
};

export type GQLMutationRemoveFavoriteMrtQueueArgs = {
  queueId: Scalars['ID'];
};

export type GQLMutationRemoveFavoriteRuleArgs = {
  ruleId: Scalars['ID'];
};

export type GQLMutationReorderRoutingRulesArgs = {
  input: GQLReorderRoutingRulesInput;
};

export type GQLMutationRequestDemoArgs = {
  input: GQLRequestDemoInput;
};

export type GQLMutationResetPasswordArgs = {
  input: GQLResetPasswordInput;
};

export type GQLMutationRetrainExistingModelArgs = {
  input: GQLRetrainExistingModelInput;
};

export type GQLMutationRunRetroactionArgs = {
  input: GQLRunRetroactionInput;
};

export type GQLMutationSaveCustomerInfoArgs = {
  input: GQLCustomerInfoInput;
};

export type GQLMutationSendPasswordResetArgs = {
  input: GQLSendPasswordResetInput;
};

export type GQLMutationSetAliasForModelArgs = {
  input: GQLSetAliasForModelInput;
};

export type GQLMutationSetAllUserStrikeThresholdsArgs = {
  input: GQLSetAllUserStrikeThresholdsInput;
};

export type GQLMutationSetIntegrationConfigArgs = {
  input: GQLSetIntegrationConfigInput;
};

export type GQLMutationSetLabelsForItemsArgs = {
  input: GQLSetLabelsForItemsInput;
};

export type GQLMutationSetModeratorSafetySettingsArgs = {
  moderatorSafetySettings: GQLModeratorSafetySettingsInput;
};

export type GQLMutationSetMrtChartConfigurationSettingsArgs = {
  mrtChartConfigurationSettings: GQLManualReviewChartConfigurationsInput;
};

export type GQLMutationSetOrgDefaultSafetySettingsArgs = {
  orgDefaultSafetySettings: GQLModeratorSafetySettingsInput;
};

export type GQLMutationSignUpArgs = {
  input: GQLSignUpInput;
};

export type GQLMutationStartCoveModelSignalCreationSessionArgs = {
  input: GQLStartCoveModelSignalCreationSessionInput;
};

export type GQLMutationSubmitManualReviewDecisionArgs = {
  input: GQLSubmitDecisionInput;
};

export type GQLMutationUpdateAccountInfoArgs = {
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type GQLMutationUpdateActionArgs = {
  input: GQLUpdateActionInput;
};

export type GQLMutationUpdateAppealSettingsArgs = {
  input: GQLAppealSettingsInput;
};

export type GQLMutationUpdateContentItemTypeArgs = {
  input: GQLUpdateContentItemTypeInput;
};

export type GQLMutationUpdateContentRuleArgs = {
  input: GQLUpdateContentRuleInput;
};

export type GQLMutationUpdateLocationBankArgs = {
  input: GQLUpdateLocationBankInput;
};

export type GQLMutationUpdateManualReviewQueueArgs = {
  input: GQLUpdateManualReviewQueueInput;
};

export type GQLMutationUpdatePolicyArgs = {
  input: GQLUpdatePolicyInput;
};

export type GQLMutationUpdateReportingRuleArgs = {
  input: GQLUpdateReportingRuleInput;
};

export type GQLMutationUpdateRoleArgs = {
  input: GQLUpdateRoleInput;
};

export type GQLMutationUpdateRoutingRuleArgs = {
  input: GQLUpdateRoutingRuleInput;
};

export type GQLMutationUpdateSsoCredentialsArgs = {
  input: GQLUpdateSsoCredentialsInput;
};

export type GQLMutationUpdateTextBankArgs = {
  input: GQLUpdateTextBankInput;
};

export type GQLMutationUpdateThreadItemTypeArgs = {
  input: GQLUpdateThreadItemTypeInput;
};

export type GQLMutationUpdateUserItemTypeArgs = {
  input: GQLUpdateUserItemTypeInput;
};

export type GQLMutationUpdateUserRuleArgs = {
  input: GQLUpdateUserRuleInput;
};

export type GQLMutationUpdateUserStrikeTtlArgs = {
  input: GQLUpdateUserStrikeTtlInput;
};

export type GQLNcmecReport = {
  readonly __typename: 'NCMECReport';
  readonly additionalFiles: ReadonlyArray<GQLNcmecAdditionalFile>;
  readonly isTest?: Maybe<Scalars['Boolean']>;
  readonly reportId: Scalars['String'];
  readonly reportXml: Scalars['String'];
  readonly reportedMedia: ReadonlyArray<GQLNcmecReportedMedia>;
  readonly reportedMessages: ReadonlyArray<GQLNcmecReportedThread>;
  readonly reviewerId?: Maybe<Scalars['String']>;
  readonly ts: Scalars['DateTime'];
  readonly userId: Scalars['String'];
  readonly userItemType: GQLUserItemType;
};

export type GQLNcmecReportedMedia = {
  readonly __typename: 'NCMECReportedMedia';
  readonly id: Scalars['String'];
  readonly xml: Scalars['String'];
};

export type GQLNcmecReportedThread = {
  readonly __typename: 'NCMECReportedThread';
  readonly csv: Scalars['String'];
  readonly fileName: Scalars['String'];
  readonly ncmecFileId: Scalars['String'];
};

export type GQLNcmecAdditionalFile = {
  readonly __typename: 'NcmecAdditionalFile';
  readonly ncmecFileId: Scalars['String'];
  readonly url: Scalars['String'];
  readonly xml: Scalars['String'];
};

export type GQLNcmecContentInThreadReport = {
  readonly chatType: Scalars['String'];
  readonly content?: InputMaybe<Scalars['String']>;
  readonly contentId: Scalars['ID'];
  readonly contentTypeId: Scalars['ID'];
  readonly creatorId: Scalars['ID'];
  readonly ipAddress: GQLIpAddressInput;
  readonly sentAt: Scalars['DateTime'];
  readonly targetId: Scalars['ID'];
  readonly type: Scalars['String'];
};

export type GQLNcmecContentItem = {
  readonly __typename: 'NcmecContentItem';
  readonly contentItem: GQLItem;
  readonly isConfirmedCSAM: Scalars['Boolean'];
  readonly isReported: Scalars['Boolean'];
};

export const GQLNcmecFileAnnotation = {
  AnimeDrawingVirtualHentai: 'ANIME_DRAWING_VIRTUAL_HENTAI',
  Bestiality: 'BESTIALITY',
  GenerativeAi: 'GENERATIVE_AI',
  Infant: 'INFANT',
  LiveStreaming: 'LIVE_STREAMING',
  PhysicalHarm: 'PHYSICAL_HARM',
  PossibleSelfProduction: 'POSSIBLE_SELF_PRODUCTION',
  PotentialMeme: 'POTENTIAL_MEME',
  ViolenceGore: 'VIOLENCE_GORE',
  Viral: 'VIRAL',
} as const;

export type GQLNcmecFileAnnotation =
  (typeof GQLNcmecFileAnnotation)[keyof typeof GQLNcmecFileAnnotation];
export const GQLNcmecIndustryClassification = {
  A1: 'A1',
  A2: 'A2',
  B1: 'B1',
  B2: 'B2',
} as const;

export type GQLNcmecIndustryClassification =
  (typeof GQLNcmecIndustryClassification)[keyof typeof GQLNcmecIndustryClassification];
export type GQLNcmecManualReviewJobPayload = {
  readonly __typename: 'NcmecManualReviewJobPayload';
  readonly allMediaItems: ReadonlyArray<GQLNcmecContentItem>;
  readonly enqueueSourceInfo?: Maybe<GQLManualReviewJobEnqueueSourceInfo>;
  readonly item: GQLUserItem;
  readonly userScore?: Maybe<Scalars['Int']>;
};

export type GQLNcmecMediaInput = {
  readonly fileAnnotations: ReadonlyArray<GQLNcmecFileAnnotation>;
  readonly id: Scalars['ID'];
  readonly industryClassification: GQLNcmecIndustryClassification;
  readonly typeId: Scalars['ID'];
  readonly url: Scalars['String'];
};

export type GQLNcmecReportedMediaDetails = {
  readonly __typename: 'NcmecReportedMediaDetails';
  readonly fileAnnotations: ReadonlyArray<GQLNcmecFileAnnotation>;
  readonly id: Scalars['String'];
  readonly industryClassification: GQLNcmecIndustryClassification;
  readonly typeId: Scalars['ID'];
  readonly url: Scalars['String'];
};

export type GQLNcmecThreadInput = {
  readonly reportedContent: ReadonlyArray<GQLNcmecContentInThreadReport>;
  readonly threadId: Scalars['ID'];
  readonly threadTypeId: Scalars['ID'];
};

export type GQLNoJobWithIdInQueueError = GQLError & {
  readonly __typename: 'NoJobWithIdInQueueError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

/**
 * A not found error that we reuse in many different places,
 * where it's obvious what the error is referring to.
 */
export type GQLNotFoundError = GQLError & {
  readonly __typename: 'NotFoundError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLNotification = {
  readonly __typename: 'Notification';
  readonly createdAt: Scalars['DateTime'];
  readonly data?: Maybe<Scalars['JSONObject']>;
  readonly id: Scalars['ID'];
  readonly message: Scalars['String'];
  readonly readAt?: Maybe<Scalars['DateTime']>;
  readonly type: GQLNotificationType;
};

export const GQLNotificationType = {
  RulePassRateIncreaseAnomalyEnd: 'RULE_PASS_RATE_INCREASE_ANOMALY_END',
  RulePassRateIncreaseAnomalyStart: 'RULE_PASS_RATE_INCREASE_ANOMALY_START',
} as const;

export type GQLNotificationType =
  (typeof GQLNotificationType)[keyof typeof GQLNotificationType];
export type GQLOpenAiIntegrationApiCredential = {
  readonly __typename: 'OpenAiIntegrationApiCredential';
  readonly apiKey: Scalars['String'];
};

export type GQLOpenAiIntegrationApiCredentialInput = {
  readonly apiKey: Scalars['String'];
};

export type GQLOrg = {
  readonly __typename: 'Org';
  readonly actions: ReadonlyArray<GQLAction>;
  readonly allowMultiplePoliciesPerAction: Scalars['Boolean'];
  readonly apiKey: Scalars['String'];
  readonly appealsRoutingRules: ReadonlyArray<GQLRoutingRule>;
  readonly banks?: Maybe<GQLMatchingBanks>;
  readonly contentTypes: ReadonlyArray<GQLContentType>;
  readonly defaultInterfacePreferences: GQLUserInterfacePreferences;
  readonly email: Scalars['String'];
  readonly hasAppealsEnabled: Scalars['Boolean'];
  readonly hasMrtAccess: Scalars['Boolean'];
  readonly hasNCMECReportingEnabled: Scalars['Boolean'];
  readonly hasPartialItemsEndpoint: Scalars['Boolean'];
  readonly hasReportingRulesEnabled: Scalars['Boolean'];
  readonly hideSkipButtonForNonAdmins: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly integrationConfigs: ReadonlyArray<GQLIntegrationConfig>;
  readonly isDemoOrg: Scalars['Boolean'];
  readonly itemTypes: ReadonlyArray<GQLItemType>;
  readonly models: ReadonlyArray<GQLCoveModel>;
  readonly mrtQueues: ReadonlyArray<GQLManualReviewQueue>;
  readonly name: Scalars['String'];
  readonly ncmecReports: ReadonlyArray<GQLNcmecReport>;
  readonly policies: ReadonlyArray<GQLPolicy>;
  readonly previewJobsViewEnabled: Scalars['Boolean'];
  readonly publicSigningKey: Scalars['String'];
  readonly reportingRules: ReadonlyArray<GQLReportingRule>;
  readonly requiresDecisionReasonInMrt: Scalars['Boolean'];
  readonly requiresPolicyForDecisionsInMrt: Scalars['Boolean'];
  readonly routingRules: ReadonlyArray<GQLRoutingRule>;
  readonly rules: ReadonlyArray<GQLRule>;
  readonly signals: ReadonlyArray<GQLSignal>;
  readonly ssoCert?: Maybe<Scalars['String']>;
  readonly ssoUrl?: Maybe<Scalars['String']>;
  readonly userStrikeTTL: Scalars['Int'];
  readonly userStrikeThresholds: ReadonlyArray<GQLUserStrikeThreshold>;
  readonly users: ReadonlyArray<GQLUser>;
  readonly usersWhoCanReviewEveryQueue: ReadonlyArray<GQLUser>;
};

export type GQLOrgSignalsArgs = {
  customOnly?: InputMaybe<Scalars['Boolean']>;
};

export type GQLOrgWithEmailExistsError = GQLError & {
  readonly __typename: 'OrgWithEmailExistsError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLOrgWithNameExistsError = GQLError & {
  readonly __typename: 'OrgWithNameExistsError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

/** Information about the current page in a connection. */
export type GQLPageInfo = {
  readonly __typename: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  readonly endCursor: Scalars['Cursor'];
  /** When paginating forwards, are there more items? */
  readonly hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  readonly hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  readonly startCursor: Scalars['Cursor'];
};

export type GQLPartialItemsEndpointResponseError = GQLError & {
  readonly __typename: 'PartialItemsEndpointResponseError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLPartialItemsInvalidResponseError = GQLError & {
  readonly __typename: 'PartialItemsInvalidResponseError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLPartialItemsMissingEndpointError = GQLError & {
  readonly __typename: 'PartialItemsMissingEndpointError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLPartialItemsResponse =
  | GQLPartialItemsEndpointResponseError
  | GQLPartialItemsInvalidResponseError
  | GQLPartialItemsMissingEndpointError
  | GQLPartialItemsSuccessResponse;

export type GQLPartialItemsSuccessResponse = {
  readonly __typename: 'PartialItemsSuccessResponse';
  readonly items: ReadonlyArray<GQLItem>;
};

export type GQLPerspectiveIntegrationApiCredential = {
  readonly __typename: 'PerspectiveIntegrationApiCredential';
  readonly apiKey: Scalars['String'];
};

export type GQLPerspectiveIntegrationApiCredentialInput = {
  readonly apiKey: Scalars['String'];
};

export type GQLPlaceBounds = {
  readonly __typename: 'PlaceBounds';
  readonly northeastCorner: GQLLatLng;
  readonly southwestCorner: GQLLatLng;
};

export type GQLPlaceBoundsInput = {
  readonly northeastCorner: GQLLatLngInput;
  readonly southwestCorner: GQLLatLngInput;
};

export type GQLPolicy = {
  readonly __typename: 'Policy';
  readonly applyUserStrikeCountConfigToChildren?: Maybe<Scalars['Boolean']>;
  readonly enforcementGuidelines?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly parentId?: Maybe<Scalars['ID']>;
  readonly policyText?: Maybe<Scalars['String']>;
  readonly policyType?: Maybe<GQLPolicyType>;
  readonly userStrikeCount?: Maybe<Scalars['Int']>;
};

export type GQLPolicyActionCount = {
  readonly __typename: 'PolicyActionCount';
  readonly actionId: Scalars['String'];
  readonly actorId?: Maybe<Scalars['String']>;
  readonly count: Scalars['Int'];
  readonly itemSubmissionIds: ReadonlyArray<Scalars['String']>;
  readonly policyId?: Maybe<Scalars['String']>;
};

export type GQLPolicyIdentifierInput = {
  readonly id: Scalars['NonEmptyString'];
};

export type GQLPolicyNameExistsError = GQLError & {
  readonly __typename: 'PolicyNameExistsError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export const GQLPolicyType = {
  DrugSales: 'DRUG_SALES',
  FraudAndDeception: 'FRAUD_AND_DECEPTION',
  Grooming: 'GROOMING',
  Harrassment: 'HARRASSMENT',
  Hate: 'HATE',
  Privacy: 'PRIVACY',
  Profanity: 'PROFANITY',
  SelfHarmAndSuicide: 'SELF_HARM_AND_SUICIDE',
  SexualContent: 'SEXUAL_CONTENT',
  SexualExploitation: 'SEXUAL_EXPLOITATION',
  Spam: 'SPAM',
  Terrorism: 'TERRORISM',
  Violence: 'VIOLENCE',
  WeaponSales: 'WEAPON_SALES',
} as const;

export type GQLPolicyType = (typeof GQLPolicyType)[keyof typeof GQLPolicyType];
export type GQLPolicyViolationsCount = {
  readonly __typename: 'PolicyViolationsCount';
  readonly count: Scalars['Int'];
  readonly policyId: Scalars['String'];
};

export type GQLPostActionsEnqueueSourceInfo = {
  readonly __typename: 'PostActionsEnqueueSourceInfo';
  readonly kind: GQLJobCreationSourceOptions;
};

export type GQLProtegoActionDecisionInput = {
  readonly _?: InputMaybe<Scalars['Boolean']>;
};

export const GQLProtegoInput = {
  AllText: 'ALL_TEXT',
  AnyGeohash: 'ANY_GEOHASH',
  AnyImage: 'ANY_IMAGE',
  AnyVideo: 'ANY_VIDEO',
  AuthorUser: 'AUTHOR_USER',
  PolicyId: 'POLICY_ID',
  Source: 'SOURCE',
} as const;

export type GQLProtegoInput =
  (typeof GQLProtegoInput)[keyof typeof GQLProtegoInput];
export type GQLQuery = {
  readonly __typename: 'Query';
  readonly action?: Maybe<GQLAction>;
  readonly actionStatistics: ReadonlyArray<GQLActionData>;
  readonly allOrgs: ReadonlyArray<GQLOrg>;
  readonly allRuleInsights: GQLAllRuleInsights;
  readonly appealSettings?: Maybe<GQLAppealSettings>;
  readonly generatePolicyText: GQLGeneratedPolicyTextCompletionResult;
  readonly getCommentsForJob: ReadonlyArray<GQLManualReviewJobComment>;
  readonly getCoveModelSignalCreationSessionResult: GQLCoveModelSignalCreationSessionResult;
  readonly getDecidedJob?: Maybe<GQLManualReviewJob>;
  readonly getDecidedJobFromJobId?: Maybe<GQLManualReviewJobWithDecisions>;
  readonly getDecisionCounts: ReadonlyArray<GQLDecisionCount>;
  readonly getDecisionsTable: ReadonlyArray<GQLTableDecisionCount>;
  readonly getExistingJobsForItem: ReadonlyArray<GQLManualReviewExistingJob>;
  readonly getFullReportingRuleResultForItem: GQLGetFullReportingRuleResultForItemResponse;
  readonly getFullRuleResultForItem: GQLGetFullResultForItemResponse;
  readonly getJobCreationCounts: ReadonlyArray<GQLJobCreationCount>;
  readonly getLabelCountForPolicy: GQLLabelCount;
  readonly getLabelsForPolicy: GQLGetLabelsForPolicyResponse;
  readonly getLatestSuccessfulSamplingJobResultForModel?: Maybe<GQLSamplingJobSuccess>;
  readonly getLiveModelForPolicy?: Maybe<GQLCoveModel>;
  readonly getModelVerionsWithDetails: ReadonlyArray<GQLCoveModel>;
  readonly getMultipleSamplingJobsResults: ReadonlyArray<GQLSamplingJobResults>;
  readonly getOrgIsReadyToCreateCoveModels: Scalars['Boolean'];
  readonly getRecentDecisions: ReadonlyArray<GQLManualReviewDecision>;
  readonly getRedditExamplesForLabelingScreen: GQLGetRedditExamplesForLabelingScreenResponse;
  readonly getRedditExamplesForThresholdScreen: GQLGetRedditExamplesForThresholdScreenResponse;
  readonly getResolvedJobCounts: ReadonlyArray<GQLResolvedJobCount>;
  readonly getResolvedJobsForUser: Scalars['Int'];
  readonly getSSORedirectUrl?: Maybe<Scalars['String']>;
  readonly getSamplingJobResults: GQLSamplingJobResults;
  readonly getSignedS3Url: GQLSignedUrl;
  readonly getSkippedJobCounts: ReadonlyArray<GQLSkippedJobCount>;
  readonly getSkippedJobsForUser: Scalars['Int'];
  readonly getSkipsForRecentDecisions: ReadonlyArray<GQLSkippedJob>;
  readonly getTimeToAction?: Maybe<ReadonlyArray<GQLTimeToAction>>;
  readonly getTotalPendingJobsCount: Scalars['Int'];
  readonly getUserStrikeCountDistribution: ReadonlyArray<GQLUserStrikeBucket>;
  readonly integrationConfig: GQLIntegrationConfigQueryResponse;
  readonly inviteUserToken: GQLInviteUserTokenResponse;
  readonly itemActionHistory: ReadonlyArray<GQLItemAction>;
  readonly itemSubmissions: ReadonlyArray<GQLItemSubmissions>;
  readonly itemType?: Maybe<GQLItemType>;
  readonly itemTypes: ReadonlyArray<GQLItemType>;
  readonly itemWithHistory: GQLItemHistoryResponse;
  readonly itemsWithId: ReadonlyArray<GQLItemSubmissions>;
  readonly latestItemSubmissions: ReadonlyArray<GQLItem>;
  readonly latestItemsCreatedBy: ReadonlyArray<GQLItemSubmissions>;
  readonly latestItemsCreatedByWithThread: ReadonlyArray<GQLThreadWithMessages>;
  readonly locationBank?: Maybe<GQLLocationBank>;
  readonly manualReviewQueue?: Maybe<GQLManualReviewQueue>;
  readonly me?: Maybe<GQLUser>;
  readonly myOrg?: Maybe<GQLOrg>;
  readonly ncmecReportById?: Maybe<GQLNcmecReport>;
  readonly ncmecThreads: ReadonlyArray<GQLThreadWithMessagesAndIpAddress>;
  readonly org?: Maybe<GQLOrg>;
  readonly partialItems: GQLPartialItemsResponse;
  readonly policy?: Maybe<GQLPolicy>;
  readonly recentUserStrikeActions: ReadonlyArray<GQLRecentUserStrikeActions>;
  readonly reportingInsights: GQLReportingInsights;
  readonly reportingRule?: Maybe<GQLReportingRule>;
  readonly rule?: Maybe<GQLRule>;
  readonly rulesWithModel: ReadonlyArray<GQLRule>;
  readonly spotTestModel: GQLModelExecutionResult;
  readonly spotTestRule: GQLRuleExecutionResult;
  readonly textBank?: Maybe<GQLTextBank>;
  readonly threadHistory: ReadonlyArray<GQLItemSubmissions>;
  readonly topPolicyViolations: ReadonlyArray<GQLPolicyViolationsCount>;
  readonly usageStatistics: GQLUsageStatisticsResponse;
  readonly user?: Maybe<GQLUser>;
  readonly userFromToken?: Maybe<Scalars['ID']>;
  readonly userHistory: GQLUserHistoryResponse;
};

export type GQLQueryActionArgs = {
  id: Scalars['ID'];
};

export type GQLQueryActionStatisticsArgs = {
  input: GQLActionStatisticsInput;
};

export type GQLQueryGeneratePolicyTextArgs = {
  input: GQLGeneratePolicyInput;
};

export type GQLQueryGetCommentsForJobArgs = {
  jobId: Scalars['ID'];
};

export type GQLQueryGetCoveModelSignalCreationSessionResultArgs = {
  sessionId: Scalars['CoveModelSignalCreationSessionId'];
};

export type GQLQueryGetDecidedJobArgs = {
  id: Scalars['ID'];
};

export type GQLQueryGetDecidedJobFromJobIdArgs = {
  id: Scalars['String'];
};

export type GQLQueryGetDecisionCountsArgs = {
  input: GQLGetDecisionCountInput;
};

export type GQLQueryGetDecisionsTableArgs = {
  input: GQLGetDecisionCountsTableInput;
};

export type GQLQueryGetExistingJobsForItemArgs = {
  itemId: Scalars['ID'];
  itemTypeId: Scalars['ID'];
};

export type GQLQueryGetFullReportingRuleResultForItemArgs = {
  input: GQLGetFullResultForItemInput;
};

export type GQLQueryGetFullRuleResultForItemArgs = {
  input: GQLGetFullResultForItemInput;
};

export type GQLQueryGetJobCreationCountsArgs = {
  input: GQLGetJobCreationCountInput;
};

export type GQLQueryGetLabelCountForPolicyArgs = {
  policyId: Scalars['ID'];
  policySemanticVersion?: InputMaybe<Scalars['Int']>;
};

export type GQLQueryGetLabelsForPolicyArgs = {
  input: GQLGetLabelsForPolicyInput;
};

export type GQLQueryGetLatestSuccessfulSamplingJobResultForModelArgs = {
  modelId: Scalars['String'];
  modelVersion: Scalars['Int'];
  samplingStrategy: GQLSamplingStrategyInput;
};

export type GQLQueryGetLiveModelForPolicyArgs = {
  policyId: Scalars['ID'];
};

export type GQLQueryGetModelVerionsWithDetailsArgs = {
  modelId: Scalars['ID'];
};

export type GQLQueryGetMultipleSamplingJobsResultsArgs = {
  jobIds: ReadonlyArray<Scalars['ID']>;
};

export type GQLQueryGetRecentDecisionsArgs = {
  input: GQLRecentDecisionsInput;
};

export type GQLQueryGetRedditExamplesForLabelingScreenArgs = {
  input: GQLGetRedditExamplesForLabelingScreenInput;
};

export type GQLQueryGetRedditExamplesForThresholdScreenArgs = {
  input: GQLGetRedditExamplesForThresholdScreenInput;
};

export type GQLQueryGetResolvedJobCountsArgs = {
  input: GQLGetResolvedJobCountInput;
};

export type GQLQueryGetResolvedJobsForUserArgs = {
  timeZone: Scalars['String'];
};

export type GQLQueryGetSsoRedirectUrlArgs = {
  emailAddress: Scalars['String'];
};

export type GQLQueryGetSamplingJobResultsArgs = {
  jobId: Scalars['ID'];
};

export type GQLQueryGetSignedS3UrlArgs = {
  fileType: Scalars['String'];
  filename: Scalars['String'];
};

export type GQLQueryGetSkippedJobCountsArgs = {
  input: GQLGetSkippedJobCountInput;
};

export type GQLQueryGetSkippedJobsForUserArgs = {
  timeZone: Scalars['String'];
};

export type GQLQueryGetSkipsForRecentDecisionsArgs = {
  input: GQLRecentDecisionsInput;
};

export type GQLQueryGetTimeToActionArgs = {
  input: GQLTimeToActionInput;
};

export type GQLQueryIntegrationConfigArgs = {
  name: GQLIntegration;
};

export type GQLQueryInviteUserTokenArgs = {
  token: Scalars['String'];
};

export type GQLQueryItemActionHistoryArgs = {
  itemIdentifier: GQLItemIdentifierInput;
  submissionTime?: InputMaybe<Scalars['DateTime']>;
};

export type GQLQueryItemSubmissionsArgs = {
  itemIdentifiers: ReadonlyArray<GQLItemIdentifierInput>;
};

export type GQLQueryItemTypeArgs = {
  id: Scalars['ID'];
  version?: InputMaybe<Scalars['String']>;
};

export type GQLQueryItemTypesArgs = {
  identifiers: ReadonlyArray<GQLItemTypeIdentifierInput>;
};

export type GQLQueryItemWithHistoryArgs = {
  itemIdentifier: GQLItemIdentifierInput;
  submissionTime?: InputMaybe<Scalars['DateTime']>;
};

export type GQLQueryItemsWithIdArgs = {
  itemId: Scalars['ID'];
  returnFirstResultOnly?: InputMaybe<Scalars['Boolean']>;
  typeId?: InputMaybe<Scalars['ID']>;
};

export type GQLQueryLatestItemSubmissionsArgs = {
  itemIdentifiers: ReadonlyArray<GQLItemIdentifierInput>;
};

export type GQLQueryLatestItemsCreatedByArgs = {
  earliestReturnedSubmissionDate?: InputMaybe<Scalars['DateTime']>;
  itemIdentifier: GQLItemIdentifierInput;
  oldestReturnedSubmissionDate?: InputMaybe<Scalars['DateTime']>;
};

export type GQLQueryLatestItemsCreatedByWithThreadArgs = {
  itemIdentifier: GQLItemIdentifierInput;
};

export type GQLQueryLocationBankArgs = {
  id: Scalars['ID'];
};

export type GQLQueryManualReviewQueueArgs = {
  id: Scalars['ID'];
};

export type GQLQueryNcmecReportByIdArgs = {
  reportId: Scalars['ID'];
};

export type GQLQueryNcmecThreadsArgs = {
  reportedMessages: ReadonlyArray<GQLItemIdentifierInput>;
  userId: GQLItemIdentifierInput;
};

export type GQLQueryOrgArgs = {
  id: Scalars['ID'];
};

export type GQLQueryPartialItemsArgs = {
  input: ReadonlyArray<GQLItemIdentifierInput>;
};

export type GQLQueryPolicyArgs = {
  id: Scalars['ID'];
};

export type GQLQueryRecentUserStrikeActionsArgs = {
  input: GQLRecentUserStrikeActionsInput;
};

export type GQLQueryReportingRuleArgs = {
  id: Scalars['ID'];
};

export type GQLQueryRuleArgs = {
  id: Scalars['ID'];
};

export type GQLQueryRulesWithModelArgs = {
  id: Scalars['NonEmptyString'];
};

export type GQLQuerySpotTestModelArgs = {
  item: GQLSpotTestItemInput;
  modelId: Scalars['ID'];
  modelVersion: Scalars['Int'];
};

export type GQLQuerySpotTestRuleArgs = {
  item: GQLSpotTestItemInput;
  ruleId: Scalars['ID'];
};

export type GQLQueryTextBankArgs = {
  id: Scalars['ID'];
};

export type GQLQueryThreadHistoryArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  threadIdentifier: GQLItemIdentifierInput;
};

export type GQLQueryTopPolicyViolationsArgs = {
  input: GQLTopPolicyViolationsInput;
};

export type GQLQueryUsageStatisticsArgs = {
  input: GQLUsageStatisticsInput;
};

export type GQLQueryUserArgs = {
  id: Scalars['ID'];
};

export type GQLQueryUserFromTokenArgs = {
  token: Scalars['String'];
};

export type GQLQueryUserHistoryArgs = {
  itemIdentifier: GQLItemIdentifierInput;
};

export type GQLQueueDoesNotExistError = GQLError & {
  readonly __typename: 'QueueDoesNotExistError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLRandomSamplingStrategyInput = {
  readonly itemRequirement: GQLItemRequirementInput;
};

export type GQLRateLimitConfigInput = {
  readonly actionCooldownMs?: InputMaybe<Scalars['Int']>;
};

export type GQLRecentDecisionsFilterInput = {
  readonly decisions?: InputMaybe<
    ReadonlyArray<GQLRecentManualReviewDecisionType>
  >;
  readonly endTime?: InputMaybe<Scalars['DateTime']>;
  readonly policyIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly queueIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly reviewerIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly startTime?: InputMaybe<Scalars['DateTime']>;
  readonly userSearchString?: InputMaybe<Scalars['String']>;
};

export type GQLRecentDecisionsForUser = {
  readonly __typename: 'RecentDecisionsForUser';
  readonly recentDecisions: ReadonlyArray<GQLManualReviewDecision>;
  readonly userSearchString: Scalars['String'];
};

export type GQLRecentDecisionsInput = {
  readonly filter: GQLRecentDecisionsFilterInput;
  readonly page?: InputMaybe<Scalars['Int']>;
};

export type GQLRecentManualReviewAcceptAppealDecision = {
  readonly _?: InputMaybe<Scalars['Boolean']>;
};

export type GQLRecentManualReviewAutomaticCloseDecision = {
  readonly _?: InputMaybe<Scalars['Boolean']>;
};

export type GQLRecentManualReviewCustomerOrRelatedActionDecision = {
  readonly actionIds: ReadonlyArray<Scalars['ID']>;
};

export type GQLRecentManualReviewDecisionType = {
  readonly acceptAppealDecision?: InputMaybe<GQLRecentManualReviewAcceptAppealDecision>;
  readonly automaticCloseDecision?: InputMaybe<GQLRecentManualReviewAutomaticCloseDecision>;
  readonly customerOrRelatedActionDecision?: InputMaybe<GQLRecentManualReviewCustomerOrRelatedActionDecision>;
  readonly ignoreDecision?: InputMaybe<GQLRecentManualReviewIgnoreDecision>;
  readonly rejectAppealDecision?: InputMaybe<GQLRecentManualReviewRejectAppealDecision>;
  readonly submitNcmecReportDecision?: InputMaybe<GQLRecentManualReviewSubmitNcmecReportDecision>;
  readonly transformJobAndRecreateInQueueDecision?: InputMaybe<GQLRecentManualReviewTransformJobAndRecreateInQueueDecision>;
};

export type GQLRecentManualReviewIgnoreDecision = {
  readonly _?: InputMaybe<Scalars['Boolean']>;
};

export type GQLRecentManualReviewRejectAppealDecision = {
  readonly _?: InputMaybe<Scalars['Boolean']>;
};

export type GQLRecentManualReviewSubmitNcmecReportDecision = {
  readonly _?: InputMaybe<Scalars['Boolean']>;
};

export type GQLRecentManualReviewTransformJobAndRecreateInQueueDecision = {
  readonly _?: InputMaybe<Scalars['Boolean']>;
};

export type GQLRecentUserStrikeActions = {
  readonly __typename: 'RecentUserStrikeActions';
  readonly actionId: Scalars['String'];
  readonly itemId: Scalars['String'];
  readonly itemTypeId: Scalars['String'];
  readonly source: Scalars['String'];
  readonly time: Scalars['DateTime'];
};

export type GQLRecentUserStrikeActionsInput = {
  readonly filterBy?: InputMaybe<GQLStartAndEndDateFilterByInput>;
  readonly limit: Scalars['Int'];
};

export type GQLRecommendedThresholds = {
  readonly __typename: 'RecommendedThresholds';
  readonly highPrecisionThreshold: Scalars['StringOrFloat'];
  readonly highRecallThreshold: Scalars['StringOrFloat'];
};

export type GQLRecordingJobDecisionFailedError = GQLError & {
  readonly __typename: 'RecordingJobDecisionFailedError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLRejectAppealDecisionComponent =
  GQLManualReviewDecisionComponentBase & {
    readonly __typename: 'RejectAppealDecisionComponent';
    readonly actionIds: ReadonlyArray<Scalars['String']>;
    readonly appealId: Scalars['String'];
    readonly type: GQLManualReviewDecisionType;
  };

export type GQLRemoveAccessibleQueuesToUserInput = {
  readonly queueIds: ReadonlyArray<Scalars['ID']>;
  readonly userId: Scalars['ID'];
};

export type GQLRemoveAccessibleQueuesToUserResponse =
  | GQLMutateAccessibleQueuesForUserSuccessResponse
  | GQLNotFoundError;

export type GQLRemoveFavoriteMrtQueueSuccessResponse = {
  readonly __typename: 'RemoveFavoriteMRTQueueSuccessResponse';
  readonly _?: Maybe<Scalars['Boolean']>;
};

export type GQLRemoveFavoriteRuleResponse =
  GQLRemoveFavoriteRuleSuccessResponse;

export type GQLRemoveFavoriteRuleSuccessResponse = {
  readonly __typename: 'RemoveFavoriteRuleSuccessResponse';
  readonly _?: Maybe<Scalars['Boolean']>;
};

export type GQLReorderRoutingRulesInput = {
  readonly isAppealsRule?: InputMaybe<Scalars['Boolean']>;
  readonly order: ReadonlyArray<Scalars['ID']>;
};

export type GQLReorderRoutingRulesResponse =
  GQLMutateRoutingRulesOrderSuccessResponse;

export type GQLReportEnqueueSourceInfo = {
  readonly __typename: 'ReportEnqueueSourceInfo';
  readonly kind: GQLJobCreationSourceOptions;
};

export type GQLReportHistoryEntry = {
  readonly __typename: 'ReportHistoryEntry';
  readonly policyId?: Maybe<Scalars['ID']>;
  readonly reason?: Maybe<Scalars['String']>;
  readonly reportId: Scalars['ID'];
  readonly reportedAt: Scalars['DateTime'];
  readonly reporterId?: Maybe<GQLItemIdentifier>;
};

export type GQLReportHistoryEntryInput = {
  readonly policyId?: InputMaybe<Scalars['ID']>;
  readonly reason?: InputMaybe<Scalars['String']>;
  readonly reportId: Scalars['ID'];
  readonly reportedAt: Scalars['DateTime'];
  readonly reporterId?: InputMaybe<GQLReporterIdInput>;
};

export type GQLReportedForReason = {
  readonly __typename: 'ReportedForReason';
  readonly reason?: Maybe<Scalars['String']>;
  readonly reporterId?: Maybe<GQLItemIdentifier>;
};

export type GQLReporterIdInput = {
  readonly id: Scalars['ID'];
  readonly typeId: Scalars['ID'];
};

export type GQLReportingInsights = {
  readonly __typename: 'ReportingInsights';
  readonly totalIngestedReportsByDay: ReadonlyArray<GQLCountByDay>;
};

export type GQLReportingRule = {
  readonly __typename: 'ReportingRule';
  readonly actions: ReadonlyArray<GQLAction>;
  readonly conditionSet: GQLConditionSet;
  readonly creator?: Maybe<GQLUser>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly insights: GQLReportingRuleInsights;
  readonly itemTypes: ReadonlyArray<GQLItemType>;
  readonly name: Scalars['String'];
  readonly orgId: Scalars['ID'];
  readonly policies: ReadonlyArray<GQLPolicy>;
  readonly status: GQLReportingRuleStatus;
};

export type GQLReportingRuleExecutionResult = {
  readonly __typename: 'ReportingRuleExecutionResult';
  readonly creatorId?: Maybe<Scalars['String']>;
  readonly creatorTypeId?: Maybe<Scalars['String']>;
  readonly date: Scalars['Date'];
  readonly environment: GQLRuleEnvironment;
  readonly itemData: Scalars['String'];
  readonly itemId: Scalars['ID'];
  readonly itemTypeId: Scalars['ID'];
  readonly itemTypeName: Scalars['String'];
  readonly passed: Scalars['Boolean'];
  readonly policyIds: ReadonlyArray<Scalars['String']>;
  readonly result?: Maybe<GQLConditionSetWithResult>;
  readonly ruleId: Scalars['ID'];
  readonly ruleName: Scalars['String'];
  readonly signalResults?: Maybe<ReadonlyArray<GQLSignalWithScore>>;
  readonly ts: Scalars['DateTime'];
};

export type GQLReportingRuleInsights = {
  readonly __typename: 'ReportingRuleInsights';
  readonly latestVersionSamples: ReadonlyArray<GQLReportingRuleExecutionResult>;
  readonly passRateData: ReadonlyArray<GQLReportingRulePassRateData>;
  readonly priorVersionSamples: ReadonlyArray<GQLReportingRuleExecutionResult>;
};

export type GQLReportingRuleInsightsPassRateDataArgs = {
  lookbackStartDate?: InputMaybe<Scalars['Date']>;
};

export type GQLReportingRuleNameExistsError = GQLError & {
  readonly __typename: 'ReportingRuleNameExistsError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLReportingRulePassRateData = {
  readonly __typename: 'ReportingRulePassRateData';
  readonly date: Scalars['String'];
  readonly totalMatches: Scalars['Float'];
  readonly totalRequests: Scalars['Float'];
};

export const GQLReportingRuleStatus = {
  Archived: 'ARCHIVED',
  Background: 'BACKGROUND',
  Draft: 'DRAFT',
  Live: 'LIVE',
} as const;

export type GQLReportingRuleStatus =
  (typeof GQLReportingRuleStatus)[keyof typeof GQLReportingRuleStatus];
export type GQLRequestDemoInput = {
  readonly company: Scalars['String'];
  readonly email: Scalars['String'];
  readonly interests: ReadonlyArray<GQLRequestDemoInterest>;
  readonly isFromGoogleAds: Scalars['Boolean'];
  readonly ref: Scalars['String'];
  readonly website: Scalars['String'];
};

export const GQLRequestDemoInterest = {
  AutomatedEnforcement: 'AUTOMATED_ENFORCEMENT',
  ComplianceToolkit: 'COMPLIANCE_TOOLKIT',
  CustomAiModels: 'CUSTOM_AI_MODELS',
  ModeratorConsole: 'MODERATOR_CONSOLE',
} as const;

export type GQLRequestDemoInterest =
  (typeof GQLRequestDemoInterest)[keyof typeof GQLRequestDemoInterest];
export type GQLResetPasswordInput = {
  readonly newPassword: Scalars['String'];
  readonly token: Scalars['String'];
};

export type GQLResolvedJobCount = {
  readonly __typename: 'ResolvedJobCount';
  readonly count: Scalars['Int'];
  readonly queueId?: Maybe<Scalars['String']>;
  readonly reviewerId?: Maybe<Scalars['String']>;
  readonly time: Scalars['String'];
};

export type GQLRetrainExistingModelInput = {
  readonly modelId: GQLCoveModelIdentifierInput;
  readonly policyIdentifier: GQLPolicyIdentifierInput;
};

export type GQLRetrainModelSuccessResponse = {
  readonly __typename: 'RetrainModelSuccessResponse';
  readonly _?: Maybe<Scalars['Boolean']>;
};

export type GQLRoutingRule = {
  readonly __typename: 'RoutingRule';
  readonly conditionSet: GQLConditionSet;
  readonly creatorId: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly destinationQueue: GQLManualReviewQueue;
  readonly id: Scalars['ID'];
  readonly itemTypes: ReadonlyArray<GQLItemType>;
  readonly name: Scalars['String'];
  readonly status: GQLRoutingRuleStatus;
};

export type GQLRoutingRuleNameExistsError = GQLError & {
  readonly __typename: 'RoutingRuleNameExistsError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export const GQLRoutingRuleStatus = {
  Live: 'LIVE',
} as const;

export type GQLRoutingRuleStatus =
  (typeof GQLRoutingRuleStatus)[keyof typeof GQLRoutingRuleStatus];
export type GQLRule = {
  readonly actions: ReadonlyArray<GQLAction>;
  readonly backtests: ReadonlyArray<GQLBacktest>;
  readonly conditionSet: GQLConditionSet;
  readonly createdAt: Scalars['String'];
  readonly creator: GQLUser;
  readonly description?: Maybe<Scalars['String']>;
  readonly expirationTime?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly insights: GQLRuleInsights;
  readonly maxDailyActions?: Maybe<Scalars['Float']>;
  readonly name: Scalars['String'];
  readonly parentId?: Maybe<Scalars['ID']>;
  readonly policies: ReadonlyArray<GQLPolicy>;
  readonly status: GQLRuleStatus;
  readonly tags?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly updatedAt: Scalars['String'];
};

export type GQLRuleBacktestsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export const GQLRuleEnvironment = {
  Background: 'BACKGROUND',
  Backtest: 'BACKTEST',
  Live: 'LIVE',
  Manual: 'MANUAL',
  Retroaction: 'RETROACTION',
} as const;

export type GQLRuleEnvironment =
  (typeof GQLRuleEnvironment)[keyof typeof GQLRuleEnvironment];
export type GQLRuleExecutionEnqueueSourceInfo = {
  readonly __typename: 'RuleExecutionEnqueueSourceInfo';
  readonly kind: GQLJobCreationSourceOptions;
  readonly rules: ReadonlyArray<GQLRule>;
};

export type GQLRuleExecutionResult = {
  readonly __typename: 'RuleExecutionResult';
  readonly content: Scalars['String'];
  readonly contentId: Scalars['String'];
  readonly date: Scalars['Date'];
  readonly environment: GQLRuleEnvironment;
  readonly itemTypeId: Scalars['ID'];
  readonly itemTypeName: Scalars['String'];
  readonly passed: Scalars['Boolean'];
  readonly policies: ReadonlyArray<Scalars['String']>;
  readonly result?: Maybe<GQLConditionSetWithResult>;
  readonly ruleId: Scalars['ID'];
  readonly ruleName: Scalars['String'];
  readonly signalResults?: Maybe<ReadonlyArray<GQLSignalWithScore>>;
  readonly tags: ReadonlyArray<Scalars['String']>;
  readonly ts: Scalars['DateTime'];
  readonly userId?: Maybe<Scalars['String']>;
  readonly userTypeId?: Maybe<Scalars['String']>;
};

export type GQLRuleExecutionResultEdge = {
  readonly __typename: 'RuleExecutionResultEdge';
  readonly cursor: Scalars['Cursor'];
  readonly node: GQLRuleExecutionResult;
};

export type GQLRuleExecutionResultsConnection = {
  readonly __typename: 'RuleExecutionResultsConnection';
  readonly edges: ReadonlyArray<GQLRuleExecutionResultEdge>;
  readonly pageInfo: GQLPageInfo;
};

export type GQLRuleHasRunningBacktestsError = GQLError & {
  readonly __typename: 'RuleHasRunningBacktestsError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLRuleInsights = {
  readonly __typename: 'RuleInsights';
  readonly latestVersionSamples: ReadonlyArray<GQLRuleExecutionResult>;
  readonly passRateData?: Maybe<ReadonlyArray<Maybe<GQLRulePassRateData>>>;
  readonly priorVersionSamples: ReadonlyArray<GQLRuleExecutionResult>;
};

export type GQLRuleInsightsPassRateDataArgs = {
  lookbackStartDate?: InputMaybe<Scalars['Date']>;
};

export type GQLRuleNameExistsError = GQLError & {
  readonly __typename: 'RuleNameExistsError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLRulePassRateData = {
  readonly __typename: 'RulePassRateData';
  readonly date: Scalars['String'];
  readonly totalMatches: Scalars['Float'];
  readonly totalRequests: Scalars['Float'];
};

export const GQLRuleStatus = {
  Archived: 'ARCHIVED',
  Background: 'BACKGROUND',
  Deprecated: 'DEPRECATED',
  Draft: 'DRAFT',
  Expired: 'EXPIRED',
  Live: 'LIVE',
} as const;

export type GQLRuleStatus = (typeof GQLRuleStatus)[keyof typeof GQLRuleStatus];
export type GQLRunRetroactionInput = {
  readonly endAt: Scalars['DateTime'];
  readonly ruleId: Scalars['ID'];
  readonly startAt: Scalars['DateTime'];
};

export type GQLRunRetroactionResponse = GQLRunRetroactionSuccessResponse;

export type GQLRunRetroactionSuccessResponse = {
  readonly __typename: 'RunRetroactionSuccessResponse';
  readonly _?: Maybe<Scalars['Boolean']>;
};

export type GQLSampleEntry = {
  readonly __typename: 'SampleEntry';
  readonly additionalModelScore?: Maybe<GQLModelScore>;
  readonly item: GQLSampledItem;
  readonly score?: Maybe<Scalars['Float']>;
};

export type GQLSampledItem = {
  readonly __typename: 'SampledItem';
  readonly data: Scalars['JSONObject'];
  readonly itemId: Scalars['ID'];
  readonly itemType: GQLItemTypeIdentifier;
};

export type GQLSampledItemInput = {
  readonly data: Scalars['JSONObject'];
  readonly id: Scalars['NonEmptyString'];
  readonly type: GQLItemTypeIdentifierInput;
};

export type GQLSamplingJobFailure = GQLError & {
  readonly __typename: 'SamplingJobFailure';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLSamplingJobNotFoundError = GQLError & {
  readonly __typename: 'SamplingJobNotFoundError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLSamplingJobPending = {
  readonly __typename: 'SamplingJobPending';
  readonly _?: Maybe<Scalars['Boolean']>;
};

export type GQLSamplingJobResults =
  | GQLSamplingJobFailure
  | GQLSamplingJobNotFoundError
  | GQLSamplingJobPending
  | GQLSamplingJobSuccess;

export type GQLSamplingJobSuccess = {
  readonly __typename: 'SamplingJobSuccess';
  readonly samples: ReadonlyArray<GQLSampleEntry>;
  readonly samplingStrategy: Scalars['CoveModelSignalSamplingStrategyToken'];
};

export type GQLSamplingStrategyInput = {
  readonly followModelGuidanceStrategy?: InputMaybe<GQLFollowModelGuidanceSamplingStrategyInput>;
  readonly modelScoreSamplingStrategy?: InputMaybe<GQLModelScoreSamplingStrategyInput>;
  readonly randomSamplingStrategy?: InputMaybe<GQLRandomSamplingStrategyInput>;
};

export type GQLScalarSignalOutputType = {
  readonly __typename: 'ScalarSignalOutputType';
  readonly scalarType: GQLScalarType;
};

export const GQLScalarType = {
  Audio: 'AUDIO',
  Boolean: 'BOOLEAN',
  Datetime: 'DATETIME',
  Geohash: 'GEOHASH',
  Id: 'ID',
  Image: 'IMAGE',
  Number: 'NUMBER',
  PolicyId: 'POLICY_ID',
  RelatedItem: 'RELATED_ITEM',
  String: 'STRING',
  Url: 'URL',
  UserId: 'USER_ID',
  Video: 'VIDEO',
} as const;

export type GQLScalarType = (typeof GQLScalarType)[keyof typeof GQLScalarType];
export type GQLSchemaFieldRoles =
  | GQLContentSchemaFieldRoles
  | GQLThreadSchemaFieldRoles
  | GQLUserSchemaFieldRoles;

export type GQLScoredRedditExample = {
  readonly __typename: 'ScoredRedditExample';
  readonly score: Scalars['Float'];
  readonly text: Scalars['String'];
};

export type GQLSendPasswordResetInput = {
  readonly email: Scalars['String'];
};

export type GQLSetAliasForModelInput = {
  readonly alias: GQLModelVersionAlias;
  readonly id: Scalars['NonEmptyString'];
  readonly version: Scalars['Int'];
};

export type GQLSetAllUserStrikeThresholdsInput = {
  readonly thresholds: ReadonlyArray<GQLSetUserStrikeThresholdInput>;
};

export type GQLSetAllUserStrikeThresholdsSuccessResponse = {
  readonly __typename: 'SetAllUserStrikeThresholdsSuccessResponse';
  readonly _?: Maybe<Scalars['Boolean']>;
};

export type GQLSetIntegrationConfigInput = {
  readonly apiCredential: GQLIntegrationApiCredentialInput;
};

export type GQLSetIntegrationConfigResponse =
  | GQLIntegrationConfigTooManyCredentialsError
  | GQLIntegrationEmptyInputCredentialsError
  | GQLIntegrationNoInputCredentialsError
  | GQLSetIntegrationConfigSuccessResponse;

export type GQLSetIntegrationConfigSuccessResponse = {
  readonly __typename: 'SetIntegrationConfigSuccessResponse';
  readonly config: GQLIntegrationConfig;
};

export type GQLSetLabelsForItemsInput = {
  readonly labels: ReadonlyArray<GQLSetLabelsForItemsLabelInput>;
};

export type GQLSetLabelsForItemsLabelInput = {
  readonly id: Scalars['ID'];
  readonly violatesPolicy: Scalars['Boolean'];
};

export type GQLSetModeratorSafetySettingsSuccessResponse = {
  readonly __typename: 'SetModeratorSafetySettingsSuccessResponse';
  readonly _?: Maybe<Scalars['Boolean']>;
};

export type GQLSetMrtChartConfigurationSettingsSuccessResponse = {
  readonly __typename: 'SetMrtChartConfigurationSettingsSuccessResponse';
  readonly _?: Maybe<Scalars['Boolean']>;
};

export type GQLSetUserStrikeThresholdInput = {
  readonly actions: ReadonlyArray<Scalars['String']>;
  readonly threshold: Scalars['Int'];
};

export type GQLSignUpInput = {
  readonly email: Scalars['String'];
  readonly firstName: Scalars['String'];
  readonly inviteUserToken?: InputMaybe<Scalars['String']>;
  readonly lastName: Scalars['String'];
  readonly loginMethod: GQLLoginMethod;
  readonly orgId: Scalars['String'];
  readonly password?: InputMaybe<Scalars['String']>;
  readonly role?: InputMaybe<GQLUserRole>;
};

export type GQLSignUpResponse =
  | GQLSignUpSuccessResponse
  | GQLSignUpUserExistsError;

export type GQLSignUpSuccessResponse = {
  readonly __typename: 'SignUpSuccessResponse';
  readonly data?: Maybe<GQLUser>;
};

export type GQLSignUpUserExistsError = GQLError & {
  readonly __typename: 'SignUpUserExistsError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLSignal = {
  readonly __typename: 'Signal';
  readonly args?: Maybe<GQLSignalArgs>;
  readonly callbackUrl?: Maybe<Scalars['String']>;
  readonly callbackUrlBody?: Maybe<Scalars['String']>;
  readonly callbackUrlHeaders?: Maybe<Scalars['String']>;
  readonly description: Scalars['String'];
  readonly disabledInfo: GQLDisabledInfo;
  readonly docsUrl?: Maybe<Scalars['String']>;
  readonly eligibleInputs: ReadonlyArray<GQLSignalInputType>;
  readonly eligibleSubcategories: ReadonlyArray<GQLSignalSubcategory>;
  readonly id: Scalars['ID'];
  readonly integration?: Maybe<GQLIntegration>;
  readonly name: Scalars['String'];
  readonly outputType: GQLSignalOutputType;
  readonly pricingStructure: GQLSignalPricingStructure;
  readonly recommendedThresholds?: Maybe<GQLRecommendedThresholds>;
  readonly shouldPromptForMatchingValues: Scalars['Boolean'];
  readonly subcategory?: Maybe<Scalars['String']>;
  readonly supportedLanguages: GQLSupportedLanguages;
  readonly type: GQLSignalType;
};

export type GQLSignalArgs = GQLAggregationSignalArgs | GQLGpt4oMiniSignalArgs;

export type GQLSignalArgsInput = {
  readonly AGGREGATION?: InputMaybe<GQLAggregationSignalArgsInput>;
  readonly GPT4O_MINI?: InputMaybe<GQLGpt4oMiniSignalArgsInput>;
};

export const GQLSignalInputType = {
  Audio: 'AUDIO',
  Boolean: 'BOOLEAN',
  Datetime: 'DATETIME',
  FullItem: 'FULL_ITEM',
  Geohash: 'GEOHASH',
  Id: 'ID',
  Image: 'IMAGE',
  Number: 'NUMBER',
  PolicyId: 'POLICY_ID',
  RelatedItem: 'RELATED_ITEM',
  String: 'STRING',
  Url: 'URL',
  UserId: 'USER_ID',
  Video: 'VIDEO',
} as const;

export type GQLSignalInputType =
  (typeof GQLSignalInputType)[keyof typeof GQLSignalInputType];
export type GQLSignalOutputType =
  | GQLEnumSignalOutputType
  | GQLScalarSignalOutputType;

export type GQLSignalPricingStructure = {
  readonly __typename: 'SignalPricingStructure';
  readonly type: GQLSignalPricingStructureType;
};

export const GQLSignalPricingStructureType = {
  Free: 'FREE',
  Subscription: 'SUBSCRIPTION',
} as const;

export type GQLSignalPricingStructureType =
  (typeof GQLSignalPricingStructureType)[keyof typeof GQLSignalPricingStructureType];
export type GQLSignalSubcategory = {
  readonly __typename: 'SignalSubcategory';
  readonly childrenIds: ReadonlyArray<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['String'];
  readonly label: Scalars['String'];
};

export type GQLSignalSubcategoryInput = {
  readonly name?: InputMaybe<Scalars['String']>;
  readonly options?: InputMaybe<
    ReadonlyArray<InputMaybe<GQLSignalSubcategoryOptionInput>>
  >;
};

export type GQLSignalSubcategoryOptionInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly name?: InputMaybe<Scalars['String']>;
};

export const GQLSignalType = {
  Aggregation: 'AGGREGATION',
  AmazonRekognitionAlcohol: 'AMAZON_REKOGNITION_ALCOHOL',
  AmazonRekognitionDrugs: 'AMAZON_REKOGNITION_DRUGS',
  AmazonRekognitionGambling: 'AMAZON_REKOGNITION_GAMBLING',
  AmazonRekognitionHateSymbols: 'AMAZON_REKOGNITION_HATE_SYMBOLS',
  AmazonRekognitionNudity: 'AMAZON_REKOGNITION_NUDITY',
  AmazonRekognitionRudeGestures: 'AMAZON_REKOGNITION_RUDE_GESTURES',
  AmazonRekognitionSuggestive: 'AMAZON_REKOGNITION_SUGGESTIVE',
  AmazonRekognitionViolence: 'AMAZON_REKOGNITION_VIOLENCE',
  AmazonRekognitionVisuallyDisturbing: 'AMAZON_REKOGNITION_VISUALLY_DISTURBING',
  BenignModel: 'BENIGN_MODEL',
  CelebrityRecognition: 'CELEBRITY_RECOGNITION',
  CheckstepAggressiveText: 'CHECKSTEP_AGGRESSIVE_TEXT',
  CheckstepBullyingText: 'CHECKSTEP_BULLYING_TEXT',
  CheckstepHateText: 'CHECKSTEP_HATE_TEXT',
  CheckstepSpamText: 'CHECKSTEP_SPAM_TEXT',
  ClarifaiDrugImage: 'CLARIFAI_DRUG_IMAGE',
  ClarifaiExplicitImage: 'CLARIFAI_EXPLICIT_IMAGE',
  ClarifaiGoreImage: 'CLARIFAI_GORE_IMAGE',
  ClarifaiIdentityHateText: 'CLARIFAI_IDENTITY_HATE_TEXT',
  ClarifaiInsultText: 'CLARIFAI_INSULT_TEXT',
  ClarifaiObsceneText: 'CLARIFAI_OBSCENE_TEXT',
  ClarifaiSevereToxicText: 'CLARIFAI_SEVERE_TOXIC_TEXT',
  ClarifaiSuggestiveImage: 'CLARIFAI_SUGGESTIVE_IMAGE',
  ClarifaiThreatText: 'CLARIFAI_THREAT_TEXT',
  ClarifaiToxicText: 'CLARIFAI_TOXIC_TEXT',
  CoveModel: 'COVE_MODEL',
  CoveNudityRekognition: 'COVE_NUDITY_REKOGNITION',
  Custom: 'CUSTOM',
  DemoHateSpeech: 'DEMO_HATE_SPEECH',
  DemoSexuallyExplicit: 'DEMO_SEXUALLY_EXPLICIT',
  GeoContainedWithin: 'GEO_CONTAINED_WITHIN',
  Gpt4OMini: 'GPT4O_MINI',
  HiveBullyingTextModel: 'HIVE_BULLYING_TEXT_MODEL',
  HiveDrugsVisualModel: 'HIVE_DRUGS_VISUAL_MODEL',
  HiveFaceDetectionModel: 'HIVE_FACE_DETECTION_MODEL',
  HiveHateTextModel: 'HIVE_HATE_TEXT_MODEL',
  HiveHateVisualModel: 'HIVE_HATE_VISUAL_MODEL',
  HiveSexualTextModel: 'HIVE_SEXUAL_TEXT_MODEL',
  HiveSexualVisualModel: 'HIVE_SEXUAL_VISUAL_MODEL',
  HiveSpamTextModel: 'HIVE_SPAM_TEXT_MODEL',
  HiveViolenceTextModel: 'HIVE_VIOLENCE_TEXT_MODEL',
  HiveViolenceVisualModel: 'HIVE_VIOLENCE_VISUAL_MODEL',
  ImageExactMatch: 'IMAGE_EXACT_MATCH',
  ImageSimilarityScore: 'IMAGE_SIMILARITY_SCORE',
  OpenAiGraphicViolenceTextModel: 'OPEN_AI_GRAPHIC_VIOLENCE_TEXT_MODEL',
  OpenAiHateTextModel: 'OPEN_AI_HATE_TEXT_MODEL',
  OpenAiHateThreateningTextModel: 'OPEN_AI_HATE_THREATENING_TEXT_MODEL',
  OpenAiSelfHarmTextModel: 'OPEN_AI_SELF_HARM_TEXT_MODEL',
  OpenAiSexualMinorsTextModel: 'OPEN_AI_SEXUAL_MINORS_TEXT_MODEL',
  OpenAiSexualTextModel: 'OPEN_AI_SEXUAL_TEXT_MODEL',
  OpenAiViolenceTextModel: 'OPEN_AI_VIOLENCE_TEXT_MODEL',
  OpenAiWhisperTranscription: 'OPEN_AI_WHISPER_TRANSCRIPTION',
  PerspectiveIdentityAttack: 'PERSPECTIVE_IDENTITY_ATTACK',
  PerspectiveInsult: 'PERSPECTIVE_INSULT',
  PerspectiveLanguageDetection: 'PERSPECTIVE_LANGUAGE_DETECTION',
  PerspectiveProfanity: 'PERSPECTIVE_PROFANITY',
  PerspectiveSevereToxicity: 'PERSPECTIVE_SEVERE_TOXICITY',
  PerspectiveSexuallyExplicit: 'PERSPECTIVE_SEXUALLY_EXPLICIT',
  PerspectiveThreat: 'PERSPECTIVE_THREAT',
  PerspectiveToxicity: 'PERSPECTIVE_TOXICITY',
  SpectrumBullyingTextModel: 'SPECTRUM_BULLYING_TEXT_MODEL',
  SpectrumCsamGroomingTextModel: 'SPECTRUM_CSAM_GROOMING_TEXT_MODEL',
  SpectrumCsamTextModel: 'SPECTRUM_CSAM_TEXT_MODEL',
  SpectrumDrugsNameModel: 'SPECTRUM_DRUGS_NAME_MODEL',
  SpectrumHateSpeechNameModel: 'SPECTRUM_HATE_SPEECH_NAME_MODEL',
  SpectrumHateTextModel: 'SPECTRUM_HATE_TEXT_MODEL',
  SpectrumInsultTextModel: 'SPECTRUM_INSULT_TEXT_MODEL',
  SpectrumPiiTextModel: 'SPECTRUM_PII_TEXT_MODEL',
  SpectrumProfanityNameModel: 'SPECTRUM_PROFANITY_NAME_MODEL',
  SpectrumProfanityTextModel: 'SPECTRUM_PROFANITY_TEXT_MODEL',
  SpectrumRadicalizationTextModel: 'SPECTRUM_RADICALIZATION_TEXT_MODEL',
  SpectrumSelfHarmTextModel: 'SPECTRUM_SELF_HARM_TEXT_MODEL',
  SpectrumSevereToxicityTextModel: 'SPECTRUM_SEVERE_TOXICITY_TEXT_MODEL',
  SpectrumSexualNameModel: 'SPECTRUM_SEXUAL_NAME_MODEL',
  SpectrumSexualTextModel: 'SPECTRUM_SEXUAL_TEXT_MODEL',
  SpectrumSolicitationOfDrugsTextModel:
    'SPECTRUM_SOLICITATION_OF_DRUGS_TEXT_MODEL',
  SpectrumSolicitationTextModel: 'SPECTRUM_SOLICITATION_TEXT_MODEL',
  SpectrumSpamDetourEmailTextModel: 'SPECTRUM_SPAM_DETOUR_EMAIL_TEXT_MODEL',
  SpectrumSpamDetourPhoneTextModel: 'SPECTRUM_SPAM_DETOUR_PHONE_TEXT_MODEL',
  SpectrumSpamDetourUrlTextModel: 'SPECTRUM_SPAM_DETOUR_URL_TEXT_MODEL',
  SpectrumSpamDetourUsernameTextModel:
    'SPECTRUM_SPAM_DETOUR_USERNAME_TEXT_MODEL',
  SpectrumSpamTextModel: 'SPECTRUM_SPAM_TEXT_MODEL',
  SpectrumThreatTextModel: 'SPECTRUM_THREAT_TEXT_MODEL',
  SpectrumUnderage_13TextModel: 'SPECTRUM_UNDERAGE_13_TEXT_MODEL',
  SpectrumUnderage_18TextModel: 'SPECTRUM_UNDERAGE_18_TEXT_MODEL',
  TextMatchingContainsRegex: 'TEXT_MATCHING_CONTAINS_REGEX',
  TextMatchingContainsText: 'TEXT_MATCHING_CONTAINS_TEXT',
  TextMatchingContainsVariant: 'TEXT_MATCHING_CONTAINS_VARIANT',
  TextMatchingNotContainsRegex: 'TEXT_MATCHING_NOT_CONTAINS_REGEX',
  TextMatchingNotContainsText: 'TEXT_MATCHING_NOT_CONTAINS_TEXT',
  TextSimilarityScore: 'TEXT_SIMILARITY_SCORE',
  ThornGroomingTextModel: 'THORN_GROOMING_TEXT_MODEL',
  UserScore: 'USER_SCORE',
  UserStrikeValue: 'USER_STRIKE_VALUE',
} as const;

export type GQLSignalType = (typeof GQLSignalType)[keyof typeof GQLSignalType];
export type GQLSignalWithScore = {
  readonly __typename: 'SignalWithScore';
  readonly integration?: Maybe<GQLIntegration>;
  readonly score: Scalars['String'];
  readonly signalName: Scalars['String'];
  readonly subcategory?: Maybe<Scalars['String']>;
};

export type GQLSignedUrl = {
  readonly __typename: 'SignedUrl';
  readonly url: Scalars['String'];
};

export type GQLSkippedJob = {
  readonly __typename: 'SkippedJob';
  readonly jobId: Scalars['String'];
  readonly queueId: Scalars['String'];
  readonly ts: Scalars['DateTime'];
  readonly userId: Scalars['String'];
};

export type GQLSkippedJobCount = {
  readonly __typename: 'SkippedJobCount';
  readonly count: Scalars['Int'];
  readonly queueId?: Maybe<Scalars['String']>;
  readonly reviewerId?: Maybe<Scalars['String']>;
  readonly time: Scalars['String'];
};

export const GQLSkippedJobCountGroupByColumns = {
  QueueId: 'QUEUE_ID',
  ReviewerId: 'REVIEWER_ID',
} as const;

export type GQLSkippedJobCountGroupByColumns =
  (typeof GQLSkippedJobCountGroupByColumns)[keyof typeof GQLSkippedJobCountGroupByColumns];
export type GQLSkippedJobFilterByInput = {
  readonly endDate: Scalars['DateTime'];
  readonly queueIds: ReadonlyArray<Scalars['String']>;
  readonly reviewerIds: ReadonlyArray<Scalars['String']>;
  readonly startDate: Scalars['DateTime'];
};

export const GQLSortOrder = {
  Asc: 'ASC',
  Desc: 'DESC',
} as const;

export type GQLSortOrder = (typeof GQLSortOrder)[keyof typeof GQLSortOrder];
export type GQLSpectrumIntegrationApiCredential = {
  readonly __typename: 'SpectrumIntegrationApiCredential';
  readonly apiKey: Scalars['String'];
  readonly clientId: Scalars['String'];
};

export type GQLSpectrumIntegrationApiCredentialInput = {
  readonly apiKey: Scalars['String'];
  readonly clientId: Scalars['String'];
};

export type GQLSpotTestItemInput = {
  readonly data: Scalars['JSONObject'];
  readonly itemTypeIdentifier: GQLItemTypeIdentifierInput;
};

export type GQLStartAndEndDateFilterByInput = {
  readonly endDate: Scalars['DateTime'];
  readonly startDate: Scalars['DateTime'];
};

export type GQLStartCoveModelSignalCreationSessionInput = {
  readonly imageModelV1?: InputMaybe<GQLImageModelV1SignalCreationSessionInput>;
  readonly textImageAndVideoModelV1?: InputMaybe<GQLTextImageAndVideoModelV1SignalCreationSessionInput>;
  readonly textModelV1?: InputMaybe<GQLTextModelV1SignalCreationSessionInput>;
};

export type GQLStartCoveModelSignalCreationSessionResponse =
  GQLStartCoveModelSignalCreationSessionSuccessResponse;

export type GQLStartCoveModelSignalCreationSessionSuccessResponse = {
  readonly __typename: 'StartCoveModelSignalCreationSessionSuccessResponse';
  readonly sessionId: Scalars['CoveModelSignalCreationSessionId'];
};

export type GQLSubmitAppealDecisionInput = {
  readonly appealId: Scalars['String'];
};

export type GQLSubmitDecisionInput = {
  readonly decisionReason?: InputMaybe<Scalars['String']>;
  readonly jobId: Scalars['ID'];
  readonly lockToken: Scalars['String'];
  readonly queueId: Scalars['ID'];
  readonly relatedItemActions: ReadonlyArray<GQLExecuteBulkActionsInput>;
  readonly reportHistory: ReadonlyArray<GQLReportHistoryEntryInput>;
  readonly reportedItemDecisionComponents: ReadonlyArray<GQLDecisionSubmission>;
};

export type GQLSubmitDecisionResponse =
  | GQLJobHasAlreadyBeenSubmittedError
  | GQLNoJobWithIdInQueueError
  | GQLRecordingJobDecisionFailedError
  | GQLSubmitDecisionSuccessResponse
  | GQLSubmittedJobActionNotFoundError;

export type GQLSubmitDecisionSuccessResponse = {
  readonly __typename: 'SubmitDecisionSuccessResponse';
  readonly success: Scalars['Boolean'];
};

export type GQLSubmitNcmecReportDecisionComponent =
  GQLManualReviewDecisionComponentBase & {
    readonly __typename: 'SubmitNCMECReportDecisionComponent';
    readonly reportedMedia: ReadonlyArray<GQLNcmecReportedMediaDetails>;
    readonly type: GQLManualReviewDecisionType;
  };

export type GQLSubmitNcmecReportInput = {
  readonly reportedMedia: ReadonlyArray<GQLNcmecMediaInput>;
  readonly reportedMessages: ReadonlyArray<GQLNcmecThreadInput>;
};

export type GQLSubmittedJobActionNotFoundError = GQLError & {
  readonly __typename: 'SubmittedJobActionNotFoundError';
  readonly detail?: Maybe<Scalars['String']>;
  readonly pointer?: Maybe<Scalars['String']>;
  readonly requestId?: Maybe<Scalars['String']>;
  readonly status: Scalars['Int'];
  readonly title: Scalars['String'];
  readonly type: ReadonlyArray<Scalars['String']>;
};

export type GQLSupportedLanguages = GQLAllLanguages | GQLLanguages;

export type GQLTableDecisionCount = {
  readonly __typename: 'TableDecisionCount';
  readonly action_id?: Maybe<Scalars['String']>;
  readonly count: Scalars['Int'];
  readonly queue_id?: Maybe<Scalars['String']>;
  readonly reviewer_id?: Maybe<Scalars['String']>;
  readonly type: GQLManualReviewDecisionType;
};

export type GQLTextBank = {
  readonly __typename: 'TextBank';
  readonly description?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly strings: ReadonlyArray<Scalars['String']>;
  readonly type: GQLTextBankType;
};

export const GQLTextBankType = {
  Regex: 'REGEX',
  String: 'STRING',
} as const;

export type GQLTextBankType =
  (typeof GQLTextBankType)[keyof typeof GQLTextBankType];
export type GQLTextImageAndVideoModelV1SignalCreationSessionInput = {
  readonly name: Scalars['String'];
  readonly policyIdentifier: GQLPolicyIdentifierInput;
};

export type GQLTextModelV1SignalCreationSessionInput = {
  readonly name: Scalars['String'];
  readonly policyIdentifier: GQLPolicyIdentifierInput;
};

export type GQLThreadAppealManualReviewJobPayload = {
  readonly __typename: 'ThreadAppealManualReviewJobPayload';
  readonly actionsTaken: ReadonlyArray<Scalars['String']>;
  readonly appealId: Scalars['String'];
  readonly appealReason?: Maybe<Scalars['String']>;
  readonly appealerIdentifier?: Maybe<GQLItemIdentifier>;
  readonly enqueueSourceInfo?: Maybe<GQLAppealEnqueueSourceInfo>;
  readonly item: GQLThreadItem;
};

export type GQLThreadItem = GQLItemBase & {
  readonly __typename: 'ThreadItem';
  readonly data: Scalars['JSONObject'];
  readonly id: Scalars['ID'];
  readonly submissionId: Scalars['ID'];
  readonly submissionTime?: Maybe<Scalars['DateTime']>;
  readonly type: GQLThreadItemType;
};

export type GQLThreadItemType = GQLItemTypeBase & {
  readonly __typename: 'ThreadItemType';
  readonly baseFields: ReadonlyArray<GQLBaseField>;
  readonly derivedFields: ReadonlyArray<GQLDerivedField>;
  readonly description?: Maybe<Scalars['String']>;
  readonly hiddenFields: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly schemaFieldRoles: GQLThreadSchemaFieldRoles;
  readonly schemaVariant: GQLItemTypeSchemaVariant;
  readonly version: Scalars['String'];
};

export type GQLThreadManualReviewJobPayload = {
  readonly __typename: 'ThreadManualReviewJobPayload';
  readonly enqueueSourceInfo?: Maybe<GQLManualReviewJobEnqueueSourceInfo>;
  readonly item: GQLThreadItem;
  readonly reportHistory: ReadonlyArray<GQLReportHistoryEntry>;
  readonly reportedForReason?: Maybe<Scalars['String']>;
  readonly reportedForReasons: ReadonlyArray<GQLReportedForReason>;
  readonly threadItems: ReadonlyArray<GQLItemWithParents>;
};

export type GQLThreadSchemaFieldRoles = {
  readonly __typename: 'ThreadSchemaFieldRoles';
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly creatorId?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly isDeleted?: Maybe<Scalars['String']>;
};

export type GQLThreadSchemaFieldRolesInput = {
  readonly createdAt?: InputMaybe<Scalars['String']>;
  readonly creatorId?: InputMaybe<Scalars['String']>;
  readonly displayName?: InputMaybe<Scalars['String']>;
  readonly isDeleted?: InputMaybe<Scalars['String']>;
};

export type GQLThreadWithMessages = {
  readonly __typename: 'ThreadWithMessages';
  readonly messages: ReadonlyArray<GQLItemSubmissions>;
  readonly threadId: Scalars['ID'];
  readonly threadTypeId: Scalars['ID'];
};

export type GQLThreadWithMessagesAndIpAddress = {
  readonly __typename: 'ThreadWithMessagesAndIpAddress';
  readonly messages: ReadonlyArray<GQLMessageWithIpAddress>;
  readonly threadId: Scalars['ID'];
  readonly threadTypeId: Scalars['ID'];
};

export type GQLTimeToAction = {
  readonly __typename: 'TimeToAction';
  readonly itemTypeId?: Maybe<Scalars['String']>;
  readonly queueId?: Maybe<Scalars['String']>;
  readonly timeToAction?: Maybe<Scalars['Int']>;
};

export type GQLTimeToActionFilterByInput = {
  readonly endDate: Scalars['DateTime'];
  readonly itemTypeIds: ReadonlyArray<Scalars['String']>;
  readonly queueIds: ReadonlyArray<Scalars['String']>;
  readonly startDate: Scalars['DateTime'];
};

export const GQLTimeToActionGroupByColumns = {
  ItemTypeId: 'ITEM_TYPE_ID',
  QueueId: 'QUEUE_ID',
  ReviewerId: 'REVIEWER_ID',
} as const;

export type GQLTimeToActionGroupByColumns =
  (typeof GQLTimeToActionGroupByColumns)[keyof typeof GQLTimeToActionGroupByColumns];
export type GQLTimeToActionInput = {
  readonly filterBy: GQLTimeToActionFilterByInput;
  readonly groupBy: ReadonlyArray<GQLTimeToActionGroupByColumns>;
};

export type GQLTopPolicyViolationsInput = {
  readonly filterBy: GQLStartAndEndDateFilterByInput;
  readonly timeZone: Scalars['String'];
};

export type GQLTransformJobAndRecreateInQueue = {
  readonly newJobKind: GQLManualReviewJobKind;
  readonly newQueueId?: InputMaybe<Scalars['String']>;
  readonly originalQueueId?: InputMaybe<Scalars['String']>;
  readonly policyIds: ReadonlyArray<Scalars['String']>;
};

export type GQLTransformJobAndRecreateInQueueDecisionComponent =
  GQLManualReviewDecisionComponentBase & {
    readonly __typename: 'TransformJobAndRecreateInQueueDecisionComponent';
    readonly newJobKind: GQLManualReviewJobKind;
    readonly newQueueId?: Maybe<Scalars['String']>;
    readonly originalQueueId?: Maybe<Scalars['String']>;
    readonly policyIds?: Maybe<ReadonlyArray<Scalars['String']>>;
    readonly type: GQLManualReviewDecisionType;
  };

export type GQLUpdateActionInput = {
  readonly applyUserStrikes?: InputMaybe<Scalars['Boolean']>;
  readonly callbackUrl?: InputMaybe<Scalars['String']>;
  readonly callbackUrlBody?: InputMaybe<Scalars['JSONObject']>;
  readonly callbackUrlHeaders?: InputMaybe<Scalars['JSONObject']>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly itemTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly name?: InputMaybe<Scalars['String']>;
};

export type GQLUpdateContentItemTypeInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly fieldRoles?: InputMaybe<GQLContentSchemaFieldRolesInput>;
  readonly fields?: InputMaybe<ReadonlyArray<GQLFieldInput>>;
  readonly hiddenFields?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['ID'];
  readonly name?: InputMaybe<Scalars['String']>;
};

export type GQLUpdateContentRuleInput = {
  readonly actionIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly cancelRunningBacktests?: InputMaybe<Scalars['Boolean']>;
  readonly conditionSet?: InputMaybe<GQLConditionSetInput>;
  readonly contentTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly expirationTime?: InputMaybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly maxDailyActions?: InputMaybe<Scalars['Float']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly parentId?: InputMaybe<Scalars['ID']>;
  readonly policyIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly rateLimitConfig?: InputMaybe<GQLRateLimitConfigInput>;
  readonly status?: InputMaybe<GQLRuleStatus>;
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type GQLUpdateContentRuleResponse =
  | GQLMutateContentRuleSuccessResponse
  | GQLNotFoundError
  | GQLRuleHasRunningBacktestsError
  | GQLRuleNameExistsError;

export type GQLUpdateLocationBankInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly locationsToAdd?: InputMaybe<ReadonlyArray<GQLLocationAreaInput>>;
  readonly locationsToDelete?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly name?: InputMaybe<Scalars['String']>;
};

export type GQLUpdateManualReviewQueueInput = {
  readonly actionIdsToHide: ReadonlyArray<Scalars['ID']>;
  readonly actionIdsToUnhide: ReadonlyArray<Scalars['ID']>;
  readonly autoCloseJobs: Scalars['Boolean'];
  readonly description?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly name?: InputMaybe<Scalars['String']>;
  readonly userIds: ReadonlyArray<Scalars['ID']>;
};

export type GQLUpdateManualReviewQueueQueueResponse =
  | GQLManualReviewQueueNameExistsError
  | GQLMutateManualReviewQueueSuccessResponse
  | GQLNotFoundError;

export type GQLUpdatePolicyInput = {
  readonly applyUserStrikeCountConfigToChildren?: InputMaybe<
    Scalars['Boolean']
  >;
  readonly enforcementGuidelines?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly parentId?: InputMaybe<Scalars['ID']>;
  readonly policyText?: InputMaybe<Scalars['String']>;
  readonly policyType?: InputMaybe<GQLPolicyType>;
  readonly userStrikeCount?: InputMaybe<Scalars['Int']>;
};

export type GQLUpdatePolicyResponse = GQLNotFoundError | GQLPolicy;

export type GQLUpdateReportingRuleInput = {
  readonly actionIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly conditionSet?: InputMaybe<GQLConditionSetInput>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly itemTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly policyIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly status?: InputMaybe<GQLReportingRuleStatus>;
};

export type GQLUpdateReportingRuleResponse =
  | GQLMutateReportingRuleSuccessResponse
  | GQLNotFoundError
  | GQLReportingRuleNameExistsError;

export type GQLUpdateRoleInput = {
  readonly id: Scalars['ID'];
  readonly role: GQLUserRole;
};

export type GQLUpdateRoutingRuleInput = {
  readonly conditionSet?: InputMaybe<GQLConditionSetInput>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly destinationQueueId?: InputMaybe<Scalars['ID']>;
  readonly id: Scalars['ID'];
  readonly isAppealsRule?: InputMaybe<Scalars['Boolean']>;
  readonly itemTypeIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly sequenceNumber?: InputMaybe<Scalars['Int']>;
  readonly status?: InputMaybe<GQLRoutingRuleStatus>;
};

export type GQLUpdateRoutingRuleResponse =
  | GQLMutateRoutingRuleSuccessResponse
  | GQLNotFoundError
  | GQLQueueDoesNotExistError
  | GQLRoutingRuleNameExistsError;

export type GQLUpdateSsoCredentialsInput = {
  readonly ssoCert: Scalars['String'];
  readonly ssoUrl: Scalars['String'];
};

export type GQLUpdateTextBankInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly name?: InputMaybe<Scalars['String']>;
  readonly strings?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly type?: InputMaybe<GQLTextBankType>;
};

export type GQLUpdateThreadItemTypeInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly fieldRoles?: InputMaybe<GQLThreadSchemaFieldRolesInput>;
  readonly fields?: InputMaybe<ReadonlyArray<GQLFieldInput>>;
  readonly hiddenFields?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['ID'];
  readonly name?: InputMaybe<Scalars['String']>;
};

export type GQLUpdateUserItemTypeInput = {
  readonly description?: InputMaybe<Scalars['String']>;
  readonly fieldRoles?: InputMaybe<GQLUserSchemaFieldRolesInput>;
  readonly fields?: InputMaybe<ReadonlyArray<GQLFieldInput>>;
  readonly hiddenFields?: InputMaybe<ReadonlyArray<Scalars['String']>>;
  readonly id: Scalars['ID'];
  readonly name?: InputMaybe<Scalars['String']>;
};

export type GQLUpdateUserRuleInput = {
  readonly actionIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly cancelRunningBacktests?: InputMaybe<Scalars['Boolean']>;
  readonly conditionSet?: InputMaybe<GQLConditionSetInput>;
  readonly description?: InputMaybe<Scalars['String']>;
  readonly expirationTime?: InputMaybe<Scalars['DateTime']>;
  readonly id: Scalars['ID'];
  readonly maxDailyActions?: InputMaybe<Scalars['Float']>;
  readonly name?: InputMaybe<Scalars['String']>;
  readonly parentId?: InputMaybe<Scalars['ID']>;
  readonly policyIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
  readonly rateLimitConfig?: InputMaybe<GQLRateLimitConfigInput>;
  readonly status?: InputMaybe<GQLRuleStatus>;
  readonly tags?: InputMaybe<ReadonlyArray<Scalars['String']>>;
};

export type GQLUpdateUserRuleResponse =
  | GQLMutateUserRuleSuccessResponse
  | GQLNotFoundError
  | GQLRuleHasRunningBacktestsError
  | GQLRuleNameExistsError;

export type GQLUpdateUserStrikeTtlInput = {
  readonly ttlDays: Scalars['Int'];
};

export type GQLUpdateUserStrikeTtlSuccessResponse = {
  readonly __typename: 'UpdateUserStrikeTTLSuccessResponse';
  readonly _?: Maybe<Scalars['Boolean']>;
};

export type GQLUsageStatisticsInput = {
  readonly endDate: Scalars['Date'];
  readonly startDate: Scalars['Date'];
};

export type GQLUsageStatisticsResponse = {
  readonly __typename: 'UsageStatisticsResponse';
  readonly dailyUsageStatistics?: Maybe<ReadonlyArray<GQLDailyUsageStatistics>>;
  readonly defaultPricePerCredit: Scalars['Float'];
  readonly numAIModelsInLiveOrBackgroundRules: Scalars['Int'];
  readonly numCreditsPurchased: Scalars['Int'];
  readonly numCreditsUsed: Scalars['Float'];
  readonly pricePerCredit: Scalars['Float'];
};

export type GQLUser = {
  readonly __typename: 'User';
  readonly approvedByAdmin?: Maybe<Scalars['Boolean']>;
  readonly createdAt: Scalars['String'];
  readonly email: Scalars['String'];
  readonly favoriteMRTQueues: ReadonlyArray<GQLManualReviewQueue>;
  readonly favoriteRules: ReadonlyArray<GQLRule>;
  readonly firstName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly interfacePreferences: GQLUserInterfacePreferences;
  readonly lastName: Scalars['String'];
  readonly loginMethods: ReadonlyArray<Scalars['String']>;
  readonly notifications: GQLUserNotifications;
  readonly orgId: Scalars['ID'];
  readonly permissions: ReadonlyArray<GQLUserPermission>;
  readonly readMeJWT?: Maybe<Scalars['String']>;
  readonly rejectedByAdmin?: Maybe<Scalars['Boolean']>;
  readonly reviewableQueues: ReadonlyArray<GQLManualReviewQueue>;
  readonly role?: Maybe<GQLUserRole>;
};

export type GQLUserReviewableQueuesArgs = {
  queueIds?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type GQLUserActionsHistory = {
  readonly __typename: 'UserActionsHistory';
  readonly countsByPolicy: ReadonlyArray<GQLPolicyActionCount>;
};

export type GQLUserAppealManualReviewJobPayload = {
  readonly __typename: 'UserAppealManualReviewJobPayload';
  readonly actionsTaken: ReadonlyArray<Scalars['String']>;
  readonly additionalContentItems: ReadonlyArray<GQLContentItem>;
  readonly appealId: Scalars['String'];
  readonly appealReason?: Maybe<Scalars['String']>;
  readonly appealerIdentifier?: Maybe<GQLItemIdentifier>;
  readonly enqueueSourceInfo?: Maybe<GQLAppealEnqueueSourceInfo>;
  readonly item: GQLUserItem;
  readonly reportedItems?: Maybe<ReadonlyArray<Maybe<GQLItemIdentifier>>>;
  readonly userScore?: Maybe<Scalars['Int']>;
};

export type GQLUserHistory = {
  readonly __typename: 'UserHistory';
  readonly actions: GQLUserActionsHistory;
  readonly executions: ReadonlyArray<GQLRuleExecutionResult>;
  readonly id: Scalars['ID'];
  readonly submissions: GQLUserSubmissionsHistory;
  readonly user?: Maybe<GQLUserItem>;
};

export type GQLUserHistoryResponse = GQLNotFoundError | GQLUserHistory;

export type GQLUserInterfacePreferences = {
  readonly __typename: 'UserInterfacePreferences';
  readonly moderatorSafetyBlurLevel: Scalars['Int'];
  readonly moderatorSafetyGrayscale: Scalars['Boolean'];
  readonly moderatorSafetyMuteVideo: Scalars['Boolean'];
  readonly mrtChartConfigurations: ReadonlyArray<GQLManualReviewChartSettings>;
};

export type GQLUserItem = GQLItemBase & {
  readonly __typename: 'UserItem';
  readonly data: Scalars['JSONObject'];
  readonly id: Scalars['ID'];
  readonly submissionId: Scalars['ID'];
  readonly submissionTime?: Maybe<Scalars['DateTime']>;
  readonly type: GQLUserItemType;
  readonly userScore: Scalars['Int'];
};

export type GQLUserItemType = GQLItemTypeBase & {
  readonly __typename: 'UserItemType';
  readonly baseFields: ReadonlyArray<GQLBaseField>;
  readonly derivedFields: ReadonlyArray<GQLDerivedField>;
  readonly description?: Maybe<Scalars['String']>;
  readonly hiddenFields: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly isDefaultUserType: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly schemaFieldRoles: GQLUserSchemaFieldRoles;
  readonly schemaVariant: GQLItemTypeSchemaVariant;
  readonly version: Scalars['String'];
};

export type GQLUserManualReviewJobPayload = {
  readonly __typename: 'UserManualReviewJobPayload';
  readonly additionalContentItems: ReadonlyArray<GQLContentItem>;
  readonly enqueueSourceInfo?: Maybe<GQLManualReviewJobEnqueueSourceInfo>;
  readonly item: GQLUserItem;
  readonly itemThreadContentItems?: Maybe<ReadonlyArray<GQLContentItem>>;
  readonly reportHistory: ReadonlyArray<GQLReportHistoryEntry>;
  readonly reportedForReasons: ReadonlyArray<GQLReportedForReason>;
  readonly reportedItems?: Maybe<ReadonlyArray<Maybe<GQLItemIdentifier>>>;
  readonly userScore?: Maybe<Scalars['Int']>;
  readonly userSubmittedItems: ReadonlyArray<GQLItemSubmissions>;
};

export type GQLUserNotificationEdge = {
  readonly __typename: 'UserNotificationEdge';
  readonly node: GQLNotification;
};

export type GQLUserNotifications = {
  readonly __typename: 'UserNotifications';
  readonly edges: ReadonlyArray<GQLUserNotificationEdge>;
};

export const GQLUserPenaltySeverity = {
  High: 'HIGH',
  Low: 'LOW',
  Medium: 'MEDIUM',
  None: 'NONE',
  Severe: 'SEVERE',
} as const;

export type GQLUserPenaltySeverity =
  (typeof GQLUserPenaltySeverity)[keyof typeof GQLUserPenaltySeverity];
export const GQLUserPermission = {
  EditMrtQueues: 'EDIT_MRT_QUEUES',
  ManageOrg: 'MANAGE_ORG',
  ManagePolicies: 'MANAGE_POLICIES',
  ManuallyActionContent: 'MANUALLY_ACTION_CONTENT',
  MutateLiveRules: 'MUTATE_LIVE_RULES',
  MutateNonLiveRules: 'MUTATE_NON_LIVE_RULES',
  RunBacktest: 'RUN_BACKTEST',
  RunRetroaction: 'RUN_RETROACTION',
  ViewChildSafetyData: 'VIEW_CHILD_SAFETY_DATA',
  ViewInsights: 'VIEW_INSIGHTS',
  ViewInvestigation: 'VIEW_INVESTIGATION',
  ViewMrt: 'VIEW_MRT',
  ViewMrtData: 'VIEW_MRT_DATA',
  ViewRulesDashboard: 'VIEW_RULES_DASHBOARD',
} as const;

export type GQLUserPermission =
  (typeof GQLUserPermission)[keyof typeof GQLUserPermission];
export const GQLUserRole = {
  Admin: 'ADMIN',
  Analyst: 'ANALYST',
  ChildSafetyModerator: 'CHILD_SAFETY_MODERATOR',
  ExternalModerator: 'EXTERNAL_MODERATOR',
  Moderator: 'MODERATOR',
  ModeratorManager: 'MODERATOR_MANAGER',
  RulesManager: 'RULES_MANAGER',
} as const;

export type GQLUserRole = (typeof GQLUserRole)[keyof typeof GQLUserRole];
export type GQLUserRule = GQLRule & {
  readonly __typename: 'UserRule';
  readonly actions: ReadonlyArray<GQLAction>;
  readonly backtests: ReadonlyArray<GQLBacktest>;
  readonly conditionSet: GQLConditionSet;
  readonly createdAt: Scalars['String'];
  readonly creator: GQLUser;
  readonly description?: Maybe<Scalars['String']>;
  readonly expirationTime?: Maybe<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly insights: GQLRuleInsights;
  readonly maxDailyActions?: Maybe<Scalars['Float']>;
  readonly name: Scalars['String'];
  readonly parentId?: Maybe<Scalars['ID']>;
  readonly policies: ReadonlyArray<GQLPolicy>;
  readonly status: GQLRuleStatus;
  readonly tags?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly updatedAt: Scalars['String'];
};

export type GQLUserRuleBacktestsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['ID']>>;
};

export type GQLUserSchemaFieldRoles = {
  readonly __typename: 'UserSchemaFieldRoles';
  readonly backgroundImage?: Maybe<Scalars['String']>;
  readonly createdAt?: Maybe<Scalars['String']>;
  readonly displayName?: Maybe<Scalars['String']>;
  readonly isDeleted?: Maybe<Scalars['String']>;
  readonly profileIcon?: Maybe<Scalars['String']>;
};

export type GQLUserSchemaFieldRolesInput = {
  readonly backgroundImage?: InputMaybe<Scalars['String']>;
  readonly createdAt?: InputMaybe<Scalars['String']>;
  readonly displayName?: InputMaybe<Scalars['String']>;
  readonly isDeleted?: InputMaybe<Scalars['String']>;
  readonly profileIcon?: InputMaybe<Scalars['String']>;
};

export type GQLUserStrikeBucket = {
  readonly __typename: 'UserStrikeBucket';
  readonly numStrikes: Scalars['Int'];
  readonly numUsers: Scalars['Int'];
};

export type GQLUserStrikeThreshold = {
  readonly __typename: 'UserStrikeThreshold';
  readonly actions: ReadonlyArray<Scalars['ID']>;
  readonly id: Scalars['String'];
  readonly threshold: Scalars['Int'];
};

export type GQLUserSubmissionCount = {
  readonly __typename: 'UserSubmissionCount';
  readonly count: Scalars['Int'];
  readonly itemTypeId: Scalars['String'];
};

export type GQLUserSubmissionsHistory = {
  readonly __typename: 'UserSubmissionsHistory';
  readonly countsByItemType: ReadonlyArray<GQLUserSubmissionCount>;
};

export const GQLValueComparator = {
  Equals: 'EQUALS',
  GreaterThan: 'GREATER_THAN',
  GreaterThanOrEquals: 'GREATER_THAN_OR_EQUALS',
  IsNotProvided: 'IS_NOT_PROVIDED',
  IsUnavailable: 'IS_UNAVAILABLE',
  LessThan: 'LESS_THAN',
  LessThanOrEquals: 'LESS_THAN_OR_EQUALS',
  NotEqualTo: 'NOT_EQUAL_TO',
} as const;

export type GQLValueComparator =
  (typeof GQLValueComparator)[keyof typeof GQLValueComparator];
export const GQLViolatesPolicy = {
  EdgeCase: 'EDGE_CASE',
  False: 'FALSE',
  NeedsContext: 'NEEDS_CONTEXT',
  True: 'TRUE',
} as const;

export type GQLViolatesPolicy =
  (typeof GQLViolatesPolicy)[keyof typeof GQLViolatesPolicy];
export type GQLWindowConfiguration = {
  readonly __typename: 'WindowConfiguration';
  readonly hopMs: Scalars['Int'];
  readonly sizeMs: Scalars['Int'];
};

export type GQLWindowConfigurationInput = {
  readonly hopMs: Scalars['Int'];
  readonly sizeMs: Scalars['Int'];
};

export type GQLUserAndOrgQueryVariables = Exact<{ [key: string]: never }>;

export type GQLUserAndOrgQuery = {
  readonly __typename: 'Query';
  readonly me?: {
    readonly __typename: 'User';
    readonly id: string;
    readonly email: string;
  } | null;
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly id: string;
    readonly hasMrtAccess: boolean;
  } | null;
};

export type GQLLoggedInUserForRouteQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLLoggedInUserForRouteQuery = {
  readonly __typename: 'Query';
  readonly me?: {
    readonly __typename: 'User';
    readonly id: string;
    readonly approvedByAdmin?: boolean | null;
    readonly rejectedByAdmin?: boolean | null;
  } | null;
};

export type GQLPermissionGatedRouteLoggedInUserQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLPermissionGatedRouteLoggedInUserQuery = {
  readonly __typename: 'Query';
  readonly me?: {
    readonly __typename: 'User';
    readonly id: string;
    readonly permissions: ReadonlyArray<GQLUserPermission>;
  } | null;
};

export type GQLLoginMutationVariables = Exact<{
  input: GQLLoginInput;
}>;

export type GQLLoginMutation = {
  readonly __typename: 'Mutation';
  readonly login:
    | {
        readonly __typename: 'LoginIncorrectPasswordError';
        readonly title: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'LoginSsoRequiredError';
        readonly title: string;
        readonly status: number;
      }
    | {
        readonly __typename: 'LoginSuccessResponse';
        readonly user: {
          readonly __typename: 'User';
          readonly id: string;
          readonly email: string;
        };
      }
    | {
        readonly __typename: 'LoginUserDoesNotExistError';
        readonly title: string;
        readonly status: number;
      };
};

export type GQLGetSsoRedirectUrlQueryVariables = Exact<{
  emailAddress: Scalars['String'];
}>;

export type GQLGetSsoRedirectUrlQuery = {
  readonly __typename: 'Query';
  readonly getSSORedirectUrl?: string | null;
};

export type GQLRejectedUserQueryVariables = Exact<{ [key: string]: never }>;

export type GQLRejectedUserQuery = {
  readonly __typename: 'Query';
  readonly me?: { readonly __typename: 'User'; readonly id: string } | null;
};

export type GQLDeleteRejectedUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLDeleteRejectedUserMutation = {
  readonly __typename: 'Mutation';
  readonly deleteUser?: boolean | null;
};

export type GQLAllOrgsQueryVariables = Exact<{ [key: string]: never }>;

export type GQLAllOrgsQuery = {
  readonly __typename: 'Query';
  readonly allOrgs: ReadonlyArray<{
    readonly __typename: 'Org';
    readonly id: string;
    readonly email: string;
    readonly name: string;
  }>;
};

export type GQLInviteUserTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GQLInviteUserTokenQuery = {
  readonly __typename: 'Query';
  readonly inviteUserToken:
    | {
        readonly __typename: 'InviteUserTokenExpiredError';
        readonly title: string;
      }
    | {
        readonly __typename: 'InviteUserTokenMissingError';
        readonly title: string;
      }
    | {
        readonly __typename: 'InviteUserTokenSuccessResponse';
        readonly tokenData: {
          readonly __typename: 'InviteUserToken';
          readonly token: string;
          readonly email: string;
          readonly role: GQLUserRole;
          readonly orgId: string;
          readonly samlEnabled: boolean;
        };
      };
};

export type GQLCreateOrgMutationVariables = Exact<{
  input: GQLCreateOrgInput;
}>;

export type GQLCreateOrgMutation = {
  readonly __typename: 'Mutation';
  readonly createOrg:
    | { readonly __typename: 'CreateOrgSuccessResponse'; readonly id: string }
    | { readonly __typename: 'OrgWithEmailExistsError'; readonly title: string }
    | { readonly __typename: 'OrgWithNameExistsError'; readonly title: string };
};

export type GQLSignUpMutationVariables = Exact<{
  input: GQLSignUpInput;
}>;

export type GQLSignUpMutation = {
  readonly __typename: 'Mutation';
  readonly signUp:
    | {
        readonly __typename: 'SignUpSuccessResponse';
        readonly data?: {
          readonly __typename: 'User';
          readonly id: string;
          readonly loginMethods: ReadonlyArray<string>;
        } | null;
      }
    | { readonly __typename: 'SignUpUserExistsError'; readonly title: string };
};

export type GQLSendPasswordResetMutationVariables = Exact<{
  input: GQLSendPasswordResetInput;
}>;

export type GQLSendPasswordResetMutation = {
  readonly __typename: 'Mutation';
  readonly sendPasswordReset: boolean;
};

export type GQLResetPasswordMutationVariables = Exact<{
  input: GQLResetPasswordInput;
}>;

export type GQLResetPasswordMutation = {
  readonly __typename: 'Mutation';
  readonly resetPassword: boolean;
};

export type GQLDashboardOrgQueryVariables = Exact<{ [key: string]: never }>;

export type GQLDashboardOrgQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly id: string;
    readonly name: string;
    readonly hasMrtAccess: boolean;
    readonly hasReportingRulesEnabled: boolean;
    readonly hasNCMECReportingEnabled: boolean;
    readonly hasAppealsEnabled: boolean;
    readonly isDemoOrg: boolean;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly id: string;
    readonly permissions: ReadonlyArray<GQLUserPermission>;
    readonly email: string;
  } | null;
};

export type GQLLogoutMutationVariables = Exact<{ [key: string]: never }>;

export type GQLLogoutMutation = {
  readonly __typename: 'Mutation';
  readonly logout?: boolean | null;
};

export type GQLCustomerDefinedActionFragmentFragment = {
  readonly __typename: 'CustomerDefinedAction';
  readonly id: string;
  readonly name: string;
  readonly description?: string | null;
  readonly callbackUrl: string;
  readonly callbackUrlHeaders?: JsonObject | null;
  readonly callbackUrlBody?: JsonObject | null;
  readonly itemTypes: ReadonlyArray<
    | { readonly __typename: 'ContentItemType'; readonly id: string }
    | { readonly __typename: 'ThreadItemType'; readonly id: string }
    | { readonly __typename: 'UserItemType'; readonly id: string }
  >;
};

export type GQLActionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLActionQuery = {
  readonly __typename: 'Query';
  readonly action?:
    | {
        readonly __typename: 'CustomerDefinedAction';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly callbackUrl: string;
        readonly callbackUrlHeaders?: JsonObject | null;
        readonly callbackUrlBody?: JsonObject | null;
        readonly itemTypes: ReadonlyArray<
          | { readonly __typename: 'ContentItemType'; readonly id: string }
          | { readonly __typename: 'ThreadItemType'; readonly id: string }
          | { readonly __typename: 'UserItemType'; readonly id: string }
        >;
      }
    | { readonly __typename: 'EnqueueAuthorToMrtAction' }
    | { readonly __typename: 'EnqueueToMrtAction' }
    | { readonly __typename: 'EnqueueToNcmecAction' }
    | null;
};

export type GQLActionFormQueryVariables = Exact<{ [key: string]: never }>;

export type GQLActionFormQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly itemTypes: ReadonlyArray<
      | {
          readonly __typename: 'ContentItemType';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'UserItemType';
          readonly id: string;
          readonly name: string;
        }
    >;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly permissions: ReadonlyArray<GQLUserPermission>;
  } | null;
};

export type GQLCreateActionMutationVariables = Exact<{
  input: GQLCreateActionInput;
}>;

export type GQLCreateActionMutation = {
  readonly __typename: 'Mutation';
  readonly createAction:
    | {
        readonly __typename: 'ActionNameExistsError';
        readonly title: string;
        readonly status: number;
        readonly type: ReadonlyArray<string>;
      }
    | {
        readonly __typename: 'MutateActionSuccessResponse';
        readonly data: {
          readonly __typename: 'CustomerDefinedAction';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly callbackUrl: string;
          readonly callbackUrlHeaders?: JsonObject | null;
          readonly callbackUrlBody?: JsonObject | null;
          readonly itemTypes: ReadonlyArray<
            | { readonly __typename: 'ContentItemType'; readonly id: string }
            | { readonly __typename: 'ThreadItemType'; readonly id: string }
            | { readonly __typename: 'UserItemType'; readonly id: string }
          >;
        };
      };
};

export type GQLUpdateActionMutationVariables = Exact<{
  input: GQLUpdateActionInput;
}>;

export type GQLUpdateActionMutation = {
  readonly __typename: 'Mutation';
  readonly updateAction:
    | {
        readonly __typename: 'ActionNameExistsError';
        readonly title: string;
        readonly status: number;
        readonly type: ReadonlyArray<string>;
      }
    | {
        readonly __typename: 'MutateActionSuccessResponse';
        readonly data: {
          readonly __typename: 'CustomerDefinedAction';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly callbackUrl: string;
          readonly callbackUrlHeaders?: JsonObject | null;
          readonly callbackUrlBody?: JsonObject | null;
          readonly itemTypes: ReadonlyArray<
            | { readonly __typename: 'ContentItemType'; readonly id: string }
            | { readonly __typename: 'ThreadItemType'; readonly id: string }
            | { readonly __typename: 'UserItemType'; readonly id: string }
          >;
        };
      };
};

export type GQLActionsQueryVariables = Exact<{ [key: string]: never }>;

export type GQLActionsQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly actions: ReadonlyArray<
      | {
          readonly __typename: 'CustomerDefinedAction';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly penalty: GQLUserPenaltySeverity;
          readonly applyUserStrikes?: boolean | null;
        }
      | {
          readonly __typename: 'EnqueueAuthorToMrtAction';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly penalty: GQLUserPenaltySeverity;
          readonly applyUserStrikes: boolean;
        }
      | {
          readonly __typename: 'EnqueueToMrtAction';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly penalty: GQLUserPenaltySeverity;
          readonly applyUserStrikes?: boolean | null;
        }
      | {
          readonly __typename: 'EnqueueToNcmecAction';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly penalty: GQLUserPenaltySeverity;
          readonly applyUserStrikes?: boolean | null;
        }
    >;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly permissions: ReadonlyArray<GQLUserPermission>;
  } | null;
};

export type GQLDeleteActionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLDeleteActionMutation = {
  readonly __typename: 'Mutation';
  readonly deleteAction?: boolean | null;
};

export type GQLCreateLocationBankMutationVariables = Exact<{
  input: GQLCreateLocationBankInput;
}>;

export type GQLCreateLocationBankMutation = {
  readonly __typename: 'Mutation';
  readonly createLocationBank:
    | { readonly __typename: 'LocationBankNameExistsError' }
    | {
        readonly __typename: 'MutateLocationBankSuccessResponse';
        readonly data: {
          readonly __typename: 'LocationBank';
          readonly id: string;
        };
      };
};

export type GQLUpdateLocationBankMutationVariables = Exact<{
  input: GQLUpdateLocationBankInput;
}>;

export type GQLUpdateLocationBankMutation = {
  readonly __typename: 'Mutation';
  readonly updateLocationBank:
    | { readonly __typename: 'LocationBankNameExistsError' }
    | {
        readonly __typename: 'MutateLocationBankSuccessResponse';
        readonly data: {
          readonly __typename: 'LocationBank';
          readonly id: string;
        };
      };
};

export type GQLLocationBankQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLLocationBankQuery = {
  readonly __typename: 'Query';
  readonly locationBank?: {
    readonly __typename: 'LocationBank';
    readonly id: string;
    readonly name: string;
    readonly description?: string | null;
    readonly locations: ReadonlyArray<{
      readonly __typename: 'LocationArea';
      readonly id: string;
      readonly name?: string | null;
      readonly geometry: {
        readonly __typename: 'LocationGeometry';
        readonly radius: number;
        readonly center: {
          readonly __typename: 'LatLng';
          readonly lat: number;
          readonly lng: number;
        };
      };
      readonly googlePlaceInfo?: {
        readonly __typename: 'GooglePlaceLocationInfo';
        readonly id: string;
      } | null;
      readonly bounds?: {
        readonly __typename: 'PlaceBounds';
        readonly northeastCorner: {
          readonly __typename: 'LatLng';
          readonly lat: number;
          readonly lng: number;
        };
        readonly southwestCorner: {
          readonly __typename: 'LatLng';
          readonly lat: number;
          readonly lng: number;
        };
      } | null;
    }>;
  } | null;
};

export type GQLLocationBankFormQueryVariables = Exact<{ [key: string]: never }>;

export type GQLLocationBankFormQuery = {
  readonly __typename: 'Query';
  readonly me?: {
    readonly __typename: 'User';
    readonly permissions: ReadonlyArray<GQLUserPermission>;
  } | null;
};

export type GQLLocationBanksQueryVariables = Exact<{ [key: string]: never }>;

export type GQLLocationBanksQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly banks?: {
      readonly __typename: 'MatchingBanks';
      readonly locationBanks: ReadonlyArray<{
        readonly __typename: 'LocationBank';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
      }>;
    } | null;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly permissions: ReadonlyArray<GQLUserPermission>;
  } | null;
};

export type GQLDeleteLocationBankMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLDeleteLocationBankMutation = {
  readonly __typename: 'Mutation';
  readonly deleteLocationBank?: boolean | null;
};

export type GQLCreateTextBankMutationVariables = Exact<{
  input: GQLCreateTextBankInput;
}>;

export type GQLCreateTextBankMutation = {
  readonly __typename: 'Mutation';
  readonly createTextBank: {
    readonly __typename: 'MutateBankResponse';
    readonly success?: boolean | null;
    readonly error?: string | null;
  };
};

export type GQLUpdateTextBankMutationVariables = Exact<{
  input: GQLUpdateTextBankInput;
}>;

export type GQLUpdateTextBankMutation = {
  readonly __typename: 'Mutation';
  readonly updateTextBank: {
    readonly __typename: 'MutateBankResponse';
    readonly success?: boolean | null;
    readonly error?: string | null;
  };
};

export type GQLTextBankQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLTextBankQuery = {
  readonly __typename: 'Query';
  readonly textBank?: {
    readonly __typename: 'TextBank';
    readonly id: string;
    readonly name: string;
    readonly description?: string | null;
    readonly type: GQLTextBankType;
    readonly strings: ReadonlyArray<string>;
  } | null;
};

export type GQLTextBankFormQueryVariables = Exact<{ [key: string]: never }>;

export type GQLTextBankFormQuery = {
  readonly __typename: 'Query';
  readonly me?: {
    readonly __typename: 'User';
    readonly permissions: ReadonlyArray<GQLUserPermission>;
  } | null;
};

export type GQLTextBanksQueryVariables = Exact<{ [key: string]: never }>;

export type GQLTextBanksQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly banks?: {
      readonly __typename: 'MatchingBanks';
      readonly textBanks: ReadonlyArray<{
        readonly __typename: 'TextBank';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly type: GQLTextBankType;
      }>;
    } | null;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly permissions: ReadonlyArray<GQLUserPermission>;
  } | null;
};

export type GQLDeleteTextBankMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLDeleteTextBankMutation = {
  readonly __typename: 'Mutation';
  readonly deleteTextBank: boolean;
};

export type GQLBulkActionsFormDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLBulkActionsFormDataQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly id: string;
    readonly allowMultiplePoliciesPerAction: boolean;
    readonly itemTypes: ReadonlyArray<
      | {
          readonly __typename: 'ContentItemType';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'UserItemType';
          readonly id: string;
          readonly name: string;
        }
    >;
    readonly actions: ReadonlyArray<
      | {
          readonly __typename: 'CustomerDefinedAction';
          readonly id: string;
          readonly name: string;
          readonly itemTypes: ReadonlyArray<
            | { readonly __typename: 'ContentItemType'; readonly id: string }
            | { readonly __typename: 'ThreadItemType'; readonly id: string }
            | { readonly __typename: 'UserItemType'; readonly id: string }
          >;
        }
      | {
          readonly __typename: 'EnqueueAuthorToMrtAction';
          readonly id: string;
          readonly name: string;
          readonly itemTypes: ReadonlyArray<
            | { readonly __typename: 'ContentItemType'; readonly id: string }
            | { readonly __typename: 'ThreadItemType'; readonly id: string }
            | { readonly __typename: 'UserItemType'; readonly id: string }
          >;
        }
      | {
          readonly __typename: 'EnqueueToMrtAction';
          readonly id: string;
          readonly name: string;
          readonly itemTypes: ReadonlyArray<
            | { readonly __typename: 'ContentItemType'; readonly id: string }
            | { readonly __typename: 'ThreadItemType'; readonly id: string }
            | { readonly __typename: 'UserItemType'; readonly id: string }
          >;
        }
      | {
          readonly __typename: 'EnqueueToNcmecAction';
          readonly id: string;
          readonly name: string;
          readonly itemTypes: ReadonlyArray<
            | { readonly __typename: 'ContentItemType'; readonly id: string }
            | { readonly __typename: 'ThreadItemType'; readonly id: string }
            | { readonly __typename: 'UserItemType'; readonly id: string }
          >;
        }
    >;
    readonly policies: ReadonlyArray<{
      readonly __typename: 'Policy';
      readonly id: string;
      readonly name: string;
      readonly parentId?: string | null;
    }>;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly permissions: ReadonlyArray<GQLUserPermission>;
  } | null;
};

export type GQLBulkActionExecutionMutationVariables = Exact<{
  input: GQLExecuteBulkActionInput;
}>;

export type GQLBulkActionExecutionMutation = {
  readonly __typename: 'Mutation';
  readonly bulkExecuteActions: {
    readonly __typename: 'ExecuteBulkActionResponse';
    readonly results: ReadonlyArray<{
      readonly __typename: 'ExecuteActionResponse';
      readonly itemId: string;
      readonly actionId: string;
      readonly success: boolean;
    }>;
  };
};

export type GQLMatchingBankIdsQueryVariables = Exact<{ [key: string]: never }>;

export type GQLMatchingBankIdsQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly banks?: {
      readonly __typename: 'MatchingBanks';
      readonly textBanks: ReadonlyArray<{
        readonly __typename: 'TextBank';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly type: GQLTextBankType;
      }>;
      readonly locationBanks: ReadonlyArray<{
        readonly __typename: 'LocationBank';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly locations: ReadonlyArray<{
          readonly __typename: 'LocationArea';
          readonly id: string;
        }>;
      }>;
    } | null;
  } | null;
};

export type GQLSetIntegrationConfigMutationVariables = Exact<{
  input: GQLSetIntegrationConfigInput;
}>;

export type GQLSetIntegrationConfigMutation = {
  readonly __typename: 'Mutation';
  readonly setIntegrationConfig:
    | {
        readonly __typename: 'IntegrationConfigTooManyCredentialsError';
        readonly title: string;
      }
    | {
        readonly __typename: 'IntegrationEmptyInputCredentialsError';
        readonly title: string;
      }
    | {
        readonly __typename: 'IntegrationNoInputCredentialsError';
        readonly title: string;
      }
    | {
        readonly __typename: 'SetIntegrationConfigSuccessResponse';
        readonly config: {
          readonly __typename: 'IntegrationConfig';
          readonly name: GQLIntegration;
        };
      };
};

export type GQLIntegrationConfigQueryVariables = Exact<{
  name: GQLIntegration;
}>;

export type GQLIntegrationConfigQuery = {
  readonly __typename: 'Query';
  readonly integrationConfig:
    | {
        readonly __typename: 'IntegrationConfigSuccessResult';
        readonly config?: {
          readonly __typename: 'IntegrationConfig';
          readonly name: GQLIntegration;
          readonly apiCredential:
            | {
                readonly __typename: 'AmazonRekognitionIntegrationApiCredential';
                readonly awsAccessKeyId: string;
                readonly awsSecretAccessKey: string;
              }
            | {
                readonly __typename: 'CheckstepIntegrationApiCredential';
                readonly apiKeys: ReadonlyArray<{
                  readonly __typename: 'CheckstepIntegrationApiCredentialPair';
                  readonly apiKey: string;
                  readonly platform: string;
                }>;
              }
            | {
                readonly __typename: 'ClarifaiIntegrationApiCredential';
                readonly text?:
                  | {
                      readonly __typename: 'ClarifaiIntegrationApiKey';
                      readonly apiKey: string;
                    }
                  | {
                      readonly __typename: 'ClarifaiIntegrationPersonalAccessToken';
                      readonly pat: string;
                      readonly userId: string;
                      readonly appId: string;
                    }
                  | null;
                readonly image?:
                  | {
                      readonly __typename: 'ClarifaiIntegrationApiKey';
                      readonly apiKey: string;
                    }
                  | {
                      readonly __typename: 'ClarifaiIntegrationPersonalAccessToken';
                      readonly pat: string;
                      readonly userId: string;
                      readonly appId: string;
                    }
                  | null;
              }
            | {
                readonly __typename: 'HiveIntegrationApiCredential';
                readonly apiKeys: ReadonlyArray<{
                  readonly __typename: 'HiveIntegrationApiCredentialPair';
                  readonly apiKey: string;
                  readonly project: string;
                }>;
              }
            | {
                readonly __typename: 'OpenAiIntegrationApiCredential';
                readonly apiKey: string;
              }
            | {
                readonly __typename: 'PerspectiveIntegrationApiCredential';
                readonly apiKey: string;
              }
            | {
                readonly __typename: 'SpectrumIntegrationApiCredential';
                readonly apiKey: string;
                readonly clientId: string;
              };
        } | null;
      }
    | {
        readonly __typename: 'IntegrationConfigUnsupportedIntegrationError';
        readonly title: string;
      };
};

export type GQLMyIntegrationsQueryVariables = Exact<{ [key: string]: never }>;

export type GQLMyIntegrationsQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly integrationConfigs: ReadonlyArray<{
      readonly __typename: 'IntegrationConfig';
      readonly name: GQLIntegration;
    }>;
  } | null;
};

export type GQLInvestigationItemTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLInvestigationItemTypesQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly itemTypes: ReadonlyArray<
      | {
          readonly __typename: 'ContentItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'ContentSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly parentId?: string | null;
            readonly threadId?: string | null;
            readonly createdAt?: string | null;
            readonly creatorId?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
      | {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'ThreadSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly creatorId?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
      | {
          readonly __typename: 'UserItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'UserSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly profileIcon?: string | null;
            readonly backgroundImage?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
    >;
  } | null;
};

export type GQLGetOrgDataQueryVariables = Exact<{ [key: string]: never }>;

export type GQLGetOrgDataQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly requiresPolicyForDecisionsInMrt: boolean;
    readonly allowMultiplePoliciesPerAction: boolean;
    readonly actions: ReadonlyArray<
      | {
          readonly __typename: 'CustomerDefinedAction';
          readonly id: string;
          readonly name: string;
          readonly penalty: GQLUserPenaltySeverity;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
      | {
          readonly __typename: 'EnqueueAuthorToMrtAction';
          readonly id: string;
          readonly name: string;
          readonly penalty: GQLUserPenaltySeverity;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
      | {
          readonly __typename: 'EnqueueToMrtAction';
          readonly id: string;
          readonly name: string;
          readonly penalty: GQLUserPenaltySeverity;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
      | {
          readonly __typename: 'EnqueueToNcmecAction';
          readonly id: string;
          readonly name: string;
          readonly penalty: GQLUserPenaltySeverity;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
    >;
    readonly itemTypes: ReadonlyArray<
      | {
          readonly __typename: 'ContentItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'ContentSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly parentId?: string | null;
            readonly threadId?: string | null;
            readonly createdAt?: string | null;
            readonly creatorId?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
      | {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'ThreadSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly creatorId?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
      | {
          readonly __typename: 'UserItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'UserSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly profileIcon?: string | null;
            readonly backgroundImage?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
    >;
    readonly policies: ReadonlyArray<{
      readonly __typename: 'Policy';
      readonly id: string;
      readonly name: string;
      readonly parentId?: string | null;
    }>;
    readonly rules: ReadonlyArray<
      | {
          readonly __typename: 'ContentRule';
          readonly id: string;
          readonly actions: ReadonlyArray<
            | {
                readonly __typename: 'CustomerDefinedAction';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'EnqueueAuthorToMrtAction';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'EnqueueToMrtAction';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'EnqueueToNcmecAction';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
      | {
          readonly __typename: 'UserRule';
          readonly id: string;
          readonly actions: ReadonlyArray<
            | {
                readonly __typename: 'CustomerDefinedAction';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'EnqueueAuthorToMrtAction';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'EnqueueToMrtAction';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'EnqueueToNcmecAction';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
    >;
  } | null;
};

export type GQLGetItemsWithIdQueryVariables = Exact<{
  id: Scalars['ID'];
  typeId?: InputMaybe<Scalars['ID']>;
}>;

export type GQLGetItemsWithIdQuery = {
  readonly __typename: 'Query';
  readonly itemsWithId: ReadonlyArray<{
    readonly __typename: 'ItemSubmissions';
    readonly latest:
      | {
          readonly __typename: 'ContentItem';
          readonly id: string;
          readonly data: JsonObject;
          readonly submissionId: string;
          readonly submissionTime?: Date | string | null;
          readonly type: {
            readonly __typename: 'ContentItemType';
            readonly id: string;
            readonly name: string;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly type: GQLFieldType;
              readonly required: boolean;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly schemaFieldRoles: {
              readonly __typename: 'ContentSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly parentId?: string | null;
              readonly threadId?: string | null;
              readonly createdAt?: string | null;
              readonly creatorId?: string | null;
            };
          };
        }
      | {
          readonly __typename: 'ThreadItem';
          readonly id: string;
          readonly data: JsonObject;
          readonly submissionId: string;
          readonly submissionTime?: Date | string | null;
          readonly type: {
            readonly __typename: 'ThreadItemType';
            readonly id: string;
            readonly name: string;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly type: GQLFieldType;
              readonly required: boolean;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly schemaFieldRoles: {
              readonly __typename: 'ThreadSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly createdAt?: string | null;
              readonly creatorId?: string | null;
            };
          };
        }
      | {
          readonly __typename: 'UserItem';
          readonly id: string;
          readonly data: JsonObject;
          readonly submissionId: string;
          readonly submissionTime?: Date | string | null;
          readonly userScore: number;
          readonly type: {
            readonly __typename: 'UserItemType';
            readonly id: string;
            readonly name: string;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly type: GQLFieldType;
              readonly required: boolean;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly schemaFieldRoles: {
              readonly __typename: 'UserSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly createdAt?: string | null;
              readonly profileIcon?: string | null;
            };
          };
        };
  }>;
};

export type GQLInvestigationItemsQueryVariables = Exact<{
  itemIdentifier: GQLItemIdentifierInput;
  submissionTime?: InputMaybe<Scalars['DateTime']>;
}>;

export type GQLInvestigationItemsQuery = {
  readonly __typename: 'Query';
  readonly itemWithHistory:
    | {
        readonly __typename: 'ItemHistoryResult';
        readonly item:
          | {
              readonly __typename: 'ContentItem';
              readonly id: string;
              readonly submissionId: string;
              readonly type: {
                readonly __typename: 'ContentItemType';
                readonly id: string;
              };
            }
          | {
              readonly __typename: 'ThreadItem';
              readonly id: string;
              readonly submissionId: string;
              readonly type: {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
              };
            }
          | {
              readonly __typename: 'UserItem';
              readonly id: string;
              readonly submissionId: string;
              readonly type: {
                readonly __typename: 'UserItemType';
                readonly id: string;
              };
            };
        readonly executions: ReadonlyArray<{
          readonly __typename: 'RuleExecutionResult';
          readonly date: Date | string;
          readonly ts: Date | string;
          readonly contentId: string;
          readonly itemTypeName: string;
          readonly itemTypeId: string;
          readonly userId?: string | null;
          readonly userTypeId?: string | null;
          readonly content: string;
          readonly environment: GQLRuleEnvironment;
          readonly passed: boolean;
          readonly ruleId: string;
          readonly ruleName: string;
          readonly policies: ReadonlyArray<string>;
          readonly tags: ReadonlyArray<string>;
          readonly result?: {
            readonly __typename: 'ConditionSetWithResult';
            readonly conjunction?: GQLConditionConjunction | null;
            readonly conditions: ReadonlyArray<
              | {
                  readonly __typename: 'ConditionSetWithResult';
                  readonly conjunction?: GQLConditionConjunction | null;
                  readonly conditions: ReadonlyArray<
                    | { readonly __typename: 'ConditionSetWithResult' }
                    | {
                        readonly __typename: 'LeafConditionWithResult';
                        readonly comparator?: GQLValueComparator | null;
                        readonly threshold?: string | number | null;
                        readonly input: {
                          readonly __typename: 'ConditionInputField';
                          readonly type: GQLConditionInputInputType;
                          readonly name?: string | null;
                          readonly contentTypeId?: string | null;
                          readonly spec?: {
                            readonly __typename: 'DerivedFieldSpec';
                            readonly derivationType: GQLDerivedFieldDerivationType;
                          } | null;
                        };
                        readonly signal?: {
                          readonly __typename: 'Signal';
                          readonly id: string;
                          readonly type: GQLSignalType;
                          readonly name: string;
                          readonly subcategory?: string | null;
                          readonly args?:
                            | { readonly __typename: 'AggregationSignalArgs' }
                            | {
                                readonly __typename: 'Gpt4oMiniSignalArgs';
                                readonly policyId: string;
                              }
                            | null;
                        } | null;
                        readonly matchingValues?: {
                          readonly __typename: 'MatchingValues';
                          readonly strings?: ReadonlyArray<string> | null;
                          readonly textBankIds?: ReadonlyArray<string> | null;
                          readonly locationBankIds?: ReadonlyArray<string> | null;
                          readonly locations?: ReadonlyArray<{
                            readonly __typename: 'LocationArea';
                            readonly id: string;
                            readonly name?: string | null;
                            readonly geometry: {
                              readonly __typename: 'LocationGeometry';
                              readonly radius: number;
                              readonly center: {
                                readonly __typename: 'LatLng';
                                readonly lat: number;
                                readonly lng: number;
                              };
                            };
                            readonly bounds?: {
                              readonly __typename: 'PlaceBounds';
                              readonly northeastCorner: {
                                readonly __typename: 'LatLng';
                                readonly lat: number;
                                readonly lng: number;
                              };
                              readonly southwestCorner: {
                                readonly __typename: 'LatLng';
                                readonly lat: number;
                                readonly lng: number;
                              };
                            } | null;
                            readonly googlePlaceInfo?: {
                              readonly __typename: 'GooglePlaceLocationInfo';
                              readonly id: string;
                            } | null;
                          }> | null;
                        } | null;
                        readonly result?: {
                          readonly __typename: 'ConditionResult';
                          readonly outcome: GQLConditionOutcome;
                          readonly score?: string | null;
                          readonly matchedValue?: string | null;
                        } | null;
                      }
                  >;
                  readonly result?: {
                    readonly __typename: 'ConditionResult';
                    readonly outcome: GQLConditionOutcome;
                    readonly score?: string | null;
                    readonly matchedValue?: string | null;
                  } | null;
                }
              | {
                  readonly __typename: 'LeafConditionWithResult';
                  readonly comparator?: GQLValueComparator | null;
                  readonly threshold?: string | number | null;
                  readonly input: {
                    readonly __typename: 'ConditionInputField';
                    readonly type: GQLConditionInputInputType;
                    readonly name?: string | null;
                    readonly contentTypeId?: string | null;
                    readonly spec?: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                    } | null;
                  };
                  readonly signal?: {
                    readonly __typename: 'Signal';
                    readonly id: string;
                    readonly type: GQLSignalType;
                    readonly name: string;
                    readonly subcategory?: string | null;
                    readonly args?:
                      | { readonly __typename: 'AggregationSignalArgs' }
                      | {
                          readonly __typename: 'Gpt4oMiniSignalArgs';
                          readonly policyId: string;
                        }
                      | null;
                  } | null;
                  readonly matchingValues?: {
                    readonly __typename: 'MatchingValues';
                    readonly strings?: ReadonlyArray<string> | null;
                    readonly textBankIds?: ReadonlyArray<string> | null;
                    readonly locationBankIds?: ReadonlyArray<string> | null;
                    readonly locations?: ReadonlyArray<{
                      readonly __typename: 'LocationArea';
                      readonly id: string;
                      readonly name?: string | null;
                      readonly geometry: {
                        readonly __typename: 'LocationGeometry';
                        readonly radius: number;
                        readonly center: {
                          readonly __typename: 'LatLng';
                          readonly lat: number;
                          readonly lng: number;
                        };
                      };
                      readonly bounds?: {
                        readonly __typename: 'PlaceBounds';
                        readonly northeastCorner: {
                          readonly __typename: 'LatLng';
                          readonly lat: number;
                          readonly lng: number;
                        };
                        readonly southwestCorner: {
                          readonly __typename: 'LatLng';
                          readonly lat: number;
                          readonly lng: number;
                        };
                      } | null;
                      readonly googlePlaceInfo?: {
                        readonly __typename: 'GooglePlaceLocationInfo';
                        readonly id: string;
                      } | null;
                    }> | null;
                  } | null;
                  readonly result?: {
                    readonly __typename: 'ConditionResult';
                    readonly outcome: GQLConditionOutcome;
                    readonly score?: string | null;
                    readonly matchedValue?: string | null;
                  } | null;
                }
            >;
            readonly result?: {
              readonly __typename: 'ConditionResult';
              readonly outcome: GQLConditionOutcome;
              readonly score?: string | null;
              readonly matchedValue?: string | null;
            } | null;
          } | null;
        }>;
      }
    | { readonly __typename: 'NotFoundError'; readonly title: string };
};

export type GQLGetAuthorInfoQueryVariables = Exact<{
  userIdentifiers:
    | ReadonlyArray<GQLItemIdentifierInput>
    | GQLItemIdentifierInput;
}>;

export type GQLGetAuthorInfoQuery = {
  readonly __typename: 'Query';
  readonly latestItemSubmissions: ReadonlyArray<
    | { readonly __typename: 'ContentItem' }
    | { readonly __typename: 'ThreadItem' }
    | {
        readonly __typename: 'UserItem';
        readonly id: string;
        readonly data: JsonObject;
        readonly submissionId: string;
        readonly submissionTime?: Date | string | null;
        readonly type: {
          readonly __typename: 'UserItemType';
          readonly id: string;
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly schemaFieldRoles: {
            readonly __typename: 'UserSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly profileIcon?: string | null;
          };
        };
      }
  >;
};

export type GQLItemTypeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLItemTypeQuery = {
  readonly __typename: 'Query';
  readonly itemType?:
    | {
        readonly __typename: 'ContentItemType';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly version: string;
        readonly schemaVariant: GQLItemTypeSchemaVariant;
        readonly hiddenFields: ReadonlyArray<string>;
        readonly schemaFieldRoles: {
          readonly __typename: 'ContentSchemaFieldRoles';
          readonly displayName?: string | null;
          readonly parentId?: string | null;
          readonly threadId?: string | null;
          readonly createdAt?: string | null;
          readonly creatorId?: string | null;
          readonly isDeleted?: string | null;
        };
        readonly baseFields: ReadonlyArray<{
          readonly __typename: 'BaseField';
          readonly name: string;
          readonly required: boolean;
          readonly type: GQLFieldType;
          readonly container?: {
            readonly __typename: 'Container';
            readonly containerType: GQLContainerType;
            readonly keyScalarType?: GQLScalarType | null;
            readonly valueScalarType: GQLScalarType;
          } | null;
        }>;
        readonly derivedFields: ReadonlyArray<{
          readonly __typename: 'DerivedField';
          readonly type: GQLFieldType;
          readonly name: string;
          readonly container?: {
            readonly __typename: 'Container';
            readonly containerType: GQLContainerType;
            readonly keyScalarType?: GQLScalarType | null;
            readonly valueScalarType: GQLScalarType;
          } | null;
          readonly spec: {
            readonly __typename: 'DerivedFieldSpec';
            readonly derivationType: GQLDerivedFieldDerivationType;
            readonly source:
              | {
                  readonly __typename: 'DerivedFieldFieldSource';
                  readonly name: string;
                  readonly contentTypeId: string;
                }
              | {
                  readonly __typename: 'DerivedFieldFullItemSource';
                  readonly _?: boolean | null;
                }
              | {
                  readonly __typename: 'DerivedFieldProtegoInputSource';
                  readonly protegoInput: GQLProtegoInput;
                };
          };
        }>;
      }
    | {
        readonly __typename: 'ThreadItemType';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly version: string;
        readonly schemaVariant: GQLItemTypeSchemaVariant;
        readonly hiddenFields: ReadonlyArray<string>;
        readonly schemaFieldRoles: {
          readonly __typename: 'ThreadSchemaFieldRoles';
          readonly displayName?: string | null;
          readonly createdAt?: string | null;
          readonly creatorId?: string | null;
          readonly isDeleted?: string | null;
        };
        readonly baseFields: ReadonlyArray<{
          readonly __typename: 'BaseField';
          readonly name: string;
          readonly required: boolean;
          readonly type: GQLFieldType;
          readonly container?: {
            readonly __typename: 'Container';
            readonly containerType: GQLContainerType;
            readonly keyScalarType?: GQLScalarType | null;
            readonly valueScalarType: GQLScalarType;
          } | null;
        }>;
        readonly derivedFields: ReadonlyArray<{
          readonly __typename: 'DerivedField';
          readonly type: GQLFieldType;
          readonly name: string;
          readonly container?: {
            readonly __typename: 'Container';
            readonly containerType: GQLContainerType;
            readonly keyScalarType?: GQLScalarType | null;
            readonly valueScalarType: GQLScalarType;
          } | null;
          readonly spec: {
            readonly __typename: 'DerivedFieldSpec';
            readonly derivationType: GQLDerivedFieldDerivationType;
            readonly source:
              | {
                  readonly __typename: 'DerivedFieldFieldSource';
                  readonly name: string;
                  readonly contentTypeId: string;
                }
              | {
                  readonly __typename: 'DerivedFieldFullItemSource';
                  readonly _?: boolean | null;
                }
              | {
                  readonly __typename: 'DerivedFieldProtegoInputSource';
                  readonly protegoInput: GQLProtegoInput;
                };
          };
        }>;
      }
    | {
        readonly __typename: 'UserItemType';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly version: string;
        readonly schemaVariant: GQLItemTypeSchemaVariant;
        readonly hiddenFields: ReadonlyArray<string>;
        readonly schemaFieldRoles: {
          readonly __typename: 'UserSchemaFieldRoles';
          readonly displayName?: string | null;
          readonly createdAt?: string | null;
          readonly profileIcon?: string | null;
          readonly backgroundImage?: string | null;
          readonly isDeleted?: string | null;
        };
        readonly baseFields: ReadonlyArray<{
          readonly __typename: 'BaseField';
          readonly name: string;
          readonly required: boolean;
          readonly type: GQLFieldType;
          readonly container?: {
            readonly __typename: 'Container';
            readonly containerType: GQLContainerType;
            readonly keyScalarType?: GQLScalarType | null;
            readonly valueScalarType: GQLScalarType;
          } | null;
        }>;
        readonly derivedFields: ReadonlyArray<{
          readonly __typename: 'DerivedField';
          readonly type: GQLFieldType;
          readonly name: string;
          readonly container?: {
            readonly __typename: 'Container';
            readonly containerType: GQLContainerType;
            readonly keyScalarType?: GQLScalarType | null;
            readonly valueScalarType: GQLScalarType;
          } | null;
          readonly spec: {
            readonly __typename: 'DerivedFieldSpec';
            readonly derivationType: GQLDerivedFieldDerivationType;
            readonly source:
              | {
                  readonly __typename: 'DerivedFieldFieldSource';
                  readonly name: string;
                  readonly contentTypeId: string;
                }
              | {
                  readonly __typename: 'DerivedFieldFullItemSource';
                  readonly _?: boolean | null;
                }
              | {
                  readonly __typename: 'DerivedFieldProtegoInputSource';
                  readonly protegoInput: GQLProtegoInput;
                };
          };
        }>;
      }
    | null;
};

export type GQLItemTypeFormOrgQueryVariables = Exact<{ [key: string]: never }>;

export type GQLItemTypeFormOrgQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly hasMrtAccess: boolean;
  } | null;
};

export type GQLDeleteItemTypeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLDeleteItemTypeMutation = {
  readonly __typename: 'Mutation';
  readonly deleteItemType:
    | {
        readonly __typename: 'CannotDeleteDefaultUserError';
        readonly title: string;
      }
    | {
        readonly __typename: 'DeleteItemTypeSuccessResponse';
        readonly _?: boolean | null;
      };
};

export type GQLCreateContentTypeMutationVariables = Exact<{
  input: GQLCreateContentItemTypeInput;
}>;

export type GQLCreateContentTypeMutation = {
  readonly __typename: 'Mutation';
  readonly createContentItemType:
    | {
        readonly __typename: 'ItemTypeNameAlreadyExistsError';
        readonly title: string;
      }
    | {
        readonly __typename: 'MutateContentTypeSuccessResponse';
        readonly data?: {
          readonly __typename: 'ContentItemType';
          readonly id: string;
        } | null;
      };
};

export type GQLUpdateContentTypeMutationVariables = Exact<{
  input: GQLUpdateContentItemTypeInput;
}>;

export type GQLUpdateContentTypeMutation = {
  readonly __typename: 'Mutation';
  readonly updateContentItemType:
    | {
        readonly __typename: 'ItemTypeNameAlreadyExistsError';
        readonly title: string;
      }
    | {
        readonly __typename: 'MutateContentTypeSuccessResponse';
        readonly data?: {
          readonly __typename: 'ContentItemType';
          readonly id: string;
        } | null;
      };
};

export type GQLCreateUserTypeMutationVariables = Exact<{
  input: GQLCreateUserItemTypeInput;
}>;

export type GQLCreateUserTypeMutation = {
  readonly __typename: 'Mutation';
  readonly createUserItemType:
    | {
        readonly __typename: 'ItemTypeNameAlreadyExistsError';
        readonly title: string;
      }
    | {
        readonly __typename: 'MutateUserTypeSuccessResponse';
        readonly data?: {
          readonly __typename: 'UserItemType';
          readonly id: string;
        } | null;
      };
};

export type GQLUpdateUserTypeMutationVariables = Exact<{
  input: GQLUpdateUserItemTypeInput;
}>;

export type GQLUpdateUserTypeMutation = {
  readonly __typename: 'Mutation';
  readonly updateUserItemType:
    | {
        readonly __typename: 'ItemTypeNameAlreadyExistsError';
        readonly title: string;
      }
    | {
        readonly __typename: 'MutateUserTypeSuccessResponse';
        readonly data?: {
          readonly __typename: 'UserItemType';
          readonly id: string;
        } | null;
      };
};

export type GQLCreateThreadTypeMutationVariables = Exact<{
  input: GQLCreateThreadItemTypeInput;
}>;

export type GQLCreateThreadTypeMutation = {
  readonly __typename: 'Mutation';
  readonly createThreadItemType:
    | {
        readonly __typename: 'ItemTypeNameAlreadyExistsError';
        readonly title: string;
      }
    | {
        readonly __typename: 'MutateThreadTypeSuccessResponse';
        readonly data?: {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
        } | null;
      };
};

export type GQLUpdateThreadTypeMutationVariables = Exact<{
  input: GQLUpdateThreadItemTypeInput;
}>;

export type GQLUpdateThreadTypeMutation = {
  readonly __typename: 'Mutation';
  readonly updateThreadItemType:
    | {
        readonly __typename: 'ItemTypeNameAlreadyExistsError';
        readonly title: string;
      }
    | {
        readonly __typename: 'MutateThreadTypeSuccessResponse';
        readonly data?: {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
        } | null;
      };
};

type GQLItemFieldsContentItemFragment = {
  readonly __typename: 'ContentItem';
  readonly id: string;
  readonly submissionId: string;
  readonly submissionTime?: Date | string | null;
  readonly data: JsonObject;
  readonly type: {
    readonly __typename: 'ContentItemType';
    readonly id: string;
    readonly name: string;
    readonly description?: string | null;
    readonly version: string;
    readonly schemaVariant: GQLItemTypeSchemaVariant;
    readonly hiddenFields: ReadonlyArray<string>;
    readonly schemaFieldRoles: {
      readonly __typename: 'ContentSchemaFieldRoles';
      readonly displayName?: string | null;
      readonly parentId?: string | null;
      readonly threadId?: string | null;
      readonly createdAt?: string | null;
      readonly creatorId?: string | null;
      readonly isDeleted?: string | null;
    };
    readonly baseFields: ReadonlyArray<{
      readonly __typename: 'BaseField';
      readonly name: string;
      readonly required: boolean;
      readonly type: GQLFieldType;
      readonly container?: {
        readonly __typename: 'Container';
        readonly containerType: GQLContainerType;
        readonly keyScalarType?: GQLScalarType | null;
        readonly valueScalarType: GQLScalarType;
      } | null;
    }>;
    readonly derivedFields: ReadonlyArray<{
      readonly __typename: 'DerivedField';
      readonly type: GQLFieldType;
      readonly name: string;
      readonly container?: {
        readonly __typename: 'Container';
        readonly containerType: GQLContainerType;
        readonly keyScalarType?: GQLScalarType | null;
        readonly valueScalarType: GQLScalarType;
      } | null;
      readonly spec: {
        readonly __typename: 'DerivedFieldSpec';
        readonly derivationType: GQLDerivedFieldDerivationType;
        readonly source:
          | {
              readonly __typename: 'DerivedFieldFieldSource';
              readonly name: string;
              readonly contentTypeId: string;
            }
          | {
              readonly __typename: 'DerivedFieldFullItemSource';
              readonly _?: boolean | null;
            }
          | {
              readonly __typename: 'DerivedFieldProtegoInputSource';
              readonly protegoInput: GQLProtegoInput;
            };
      };
    }>;
  };
};

type GQLItemFieldsThreadItemFragment = {
  readonly __typename: 'ThreadItem';
  readonly id: string;
  readonly submissionId: string;
  readonly submissionTime?: Date | string | null;
  readonly data: JsonObject;
  readonly type: {
    readonly __typename: 'ThreadItemType';
    readonly id: string;
    readonly name: string;
    readonly description?: string | null;
    readonly version: string;
    readonly schemaVariant: GQLItemTypeSchemaVariant;
    readonly hiddenFields: ReadonlyArray<string>;
    readonly schemaFieldRoles: {
      readonly __typename: 'ThreadSchemaFieldRoles';
      readonly displayName?: string | null;
      readonly createdAt?: string | null;
      readonly creatorId?: string | null;
      readonly isDeleted?: string | null;
    };
    readonly baseFields: ReadonlyArray<{
      readonly __typename: 'BaseField';
      readonly name: string;
      readonly required: boolean;
      readonly type: GQLFieldType;
      readonly container?: {
        readonly __typename: 'Container';
        readonly containerType: GQLContainerType;
        readonly keyScalarType?: GQLScalarType | null;
        readonly valueScalarType: GQLScalarType;
      } | null;
    }>;
    readonly derivedFields: ReadonlyArray<{
      readonly __typename: 'DerivedField';
      readonly type: GQLFieldType;
      readonly name: string;
      readonly container?: {
        readonly __typename: 'Container';
        readonly containerType: GQLContainerType;
        readonly keyScalarType?: GQLScalarType | null;
        readonly valueScalarType: GQLScalarType;
      } | null;
      readonly spec: {
        readonly __typename: 'DerivedFieldSpec';
        readonly derivationType: GQLDerivedFieldDerivationType;
        readonly source:
          | {
              readonly __typename: 'DerivedFieldFieldSource';
              readonly name: string;
              readonly contentTypeId: string;
            }
          | {
              readonly __typename: 'DerivedFieldFullItemSource';
              readonly _?: boolean | null;
            }
          | {
              readonly __typename: 'DerivedFieldProtegoInputSource';
              readonly protegoInput: GQLProtegoInput;
            };
      };
    }>;
  };
};

type GQLItemFieldsUserItemFragment = {
  readonly __typename: 'UserItem';
  readonly id: string;
  readonly submissionId: string;
  readonly submissionTime?: Date | string | null;
  readonly data: JsonObject;
  readonly type: {
    readonly __typename: 'UserItemType';
    readonly id: string;
    readonly name: string;
    readonly description?: string | null;
    readonly version: string;
    readonly schemaVariant: GQLItemTypeSchemaVariant;
    readonly hiddenFields: ReadonlyArray<string>;
    readonly schemaFieldRoles: {
      readonly __typename: 'UserSchemaFieldRoles';
      readonly displayName?: string | null;
      readonly createdAt?: string | null;
      readonly profileIcon?: string | null;
      readonly backgroundImage?: string | null;
      readonly isDeleted?: string | null;
    };
    readonly baseFields: ReadonlyArray<{
      readonly __typename: 'BaseField';
      readonly name: string;
      readonly required: boolean;
      readonly type: GQLFieldType;
      readonly container?: {
        readonly __typename: 'Container';
        readonly containerType: GQLContainerType;
        readonly keyScalarType?: GQLScalarType | null;
        readonly valueScalarType: GQLScalarType;
      } | null;
    }>;
    readonly derivedFields: ReadonlyArray<{
      readonly __typename: 'DerivedField';
      readonly type: GQLFieldType;
      readonly name: string;
      readonly container?: {
        readonly __typename: 'Container';
        readonly containerType: GQLContainerType;
        readonly keyScalarType?: GQLScalarType | null;
        readonly valueScalarType: GQLScalarType;
      } | null;
      readonly spec: {
        readonly __typename: 'DerivedFieldSpec';
        readonly derivationType: GQLDerivedFieldDerivationType;
        readonly source:
          | {
              readonly __typename: 'DerivedFieldFieldSource';
              readonly name: string;
              readonly contentTypeId: string;
            }
          | {
              readonly __typename: 'DerivedFieldFullItemSource';
              readonly _?: boolean | null;
            }
          | {
              readonly __typename: 'DerivedFieldProtegoInputSource';
              readonly protegoInput: GQLProtegoInput;
            };
      };
    }>;
  };
};

export type GQLItemFieldsFragment =
  | GQLItemFieldsContentItemFragment
  | GQLItemFieldsThreadItemFragment
  | GQLItemFieldsUserItemFragment;

export type GQLItemTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GQLItemTypesQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly itemTypes: ReadonlyArray<
      | {
          readonly __typename: 'ContentItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'ContentSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly parentId?: string | null;
            readonly threadId?: string | null;
            readonly createdAt?: string | null;
            readonly creatorId?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
      | {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'ThreadSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly creatorId?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
      | {
          readonly __typename: 'UserItemType';
          readonly isDefaultUserType: boolean;
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'UserSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly profileIcon?: string | null;
            readonly backgroundImage?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
    >;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly permissions: ReadonlyArray<GQLUserPermission>;
  } | null;
};

export type GQLItemActionHistoryQueryVariables = Exact<{
  itemIdentifier: GQLItemIdentifierInput;
  submissionTime?: InputMaybe<Scalars['DateTime']>;
}>;

export type GQLItemActionHistoryQuery = {
  readonly __typename: 'Query';
  readonly itemActionHistory: ReadonlyArray<{
    readonly __typename: 'ItemAction';
    readonly itemId: string;
    readonly itemTypeId: string;
    readonly itemCreatorId?: string | null;
    readonly itemCreatorTypeId?: string | null;
    readonly actionId: string;
    readonly actorId?: string | null;
    readonly jobId?: string | null;
    readonly policies: ReadonlyArray<string>;
    readonly ruleIds: ReadonlyArray<string>;
    readonly ts: Date | string;
  }>;
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly users: ReadonlyArray<{
      readonly __typename: 'User';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    }>;
    readonly actions: ReadonlyArray<
      | {
          readonly __typename: 'CustomerDefinedAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueAuthorToMrtAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueToMrtAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueToNcmecAction';
          readonly id: string;
          readonly name: string;
        }
    >;
    readonly policies: ReadonlyArray<{
      readonly __typename: 'Policy';
      readonly id: string;
      readonly name: string;
    }>;
    readonly itemTypes: ReadonlyArray<
      | {
          readonly __typename: 'ContentItemType';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'UserItemType';
          readonly id: string;
          readonly name: string;
        }
    >;
    readonly rules: ReadonlyArray<
      | {
          readonly __typename: 'ContentRule';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'UserRule';
          readonly id: string;
          readonly name: string;
        }
    >;
  } | null;
};

export type GQLGetDecidedJobFromJobIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GQLGetDecidedJobFromJobIdQuery = {
  readonly __typename: 'Query';
  readonly getDecidedJobFromJobId?: {
    readonly __typename: 'ManualReviewJobWithDecisions';
    readonly job: {
      readonly __typename: 'ManualReviewJob';
      readonly id: string;
      readonly createdAt: Date | string;
      readonly policyIds: ReadonlyArray<string>;
      readonly numTimesReported?: number | null;
      readonly payload:
        | {
            readonly __typename: 'ContentAppealManualReviewJobPayload';
            readonly userScore?: number | null;
            readonly appealReason?: string | null;
            readonly appealId: string;
            readonly actionsTaken: ReadonlyArray<string>;
            readonly item: {
              readonly __typename: 'ContentItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ContentSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly parentId?: string | null;
                  readonly threadId?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            };
            readonly additionalContentItems: ReadonlyArray<{
              readonly __typename: 'ContentItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ContentSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly parentId?: string | null;
                  readonly threadId?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            }>;
            readonly appealerIdentifier?: {
              readonly __typename: 'ItemIdentifier';
              readonly id: string;
              readonly typeId: string;
            } | null;
            readonly enqueueSourceInfo?: {
              readonly __typename: 'AppealEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
            } | null;
          }
        | {
            readonly __typename: 'ContentManualReviewJobPayload';
            readonly userScore?: number | null;
            readonly reportHistory: ReadonlyArray<{
              readonly __typename: 'ReportHistoryEntry';
              readonly policyId?: string | null;
              readonly reportId: string;
              readonly reason?: string | null;
              readonly reportedAt: Date | string;
              readonly reporterId?: {
                readonly __typename: 'ItemIdentifier';
                readonly id: string;
                readonly typeId: string;
              } | null;
            }>;
            readonly item: {
              readonly __typename: 'ContentItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ContentSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly parentId?: string | null;
                  readonly threadId?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            };
            readonly additionalContentItems: ReadonlyArray<{
              readonly __typename: 'ContentItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ContentSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly parentId?: string | null;
                  readonly threadId?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            }>;
            readonly itemThreadContentItems?: ReadonlyArray<{
              readonly __typename: 'ContentItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ContentSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly parentId?: string | null;
                  readonly threadId?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            }> | null;
            readonly reportedForReasons: ReadonlyArray<{
              readonly __typename: 'ReportedForReason';
              readonly reason?: string | null;
              readonly reporterId?: {
                readonly __typename: 'ItemIdentifier';
                readonly id: string;
                readonly typeId: string;
              } | null;
            }>;
            readonly enqueueSourceInfo?:
              | { readonly __typename: 'AppealEnqueueSourceInfo' }
              | {
                  readonly __typename: 'MrtJobEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'PostActionsEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'ReportEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'RuleExecutionEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                  readonly rules: ReadonlyArray<
                    | {
                        readonly __typename: 'ContentRule';
                        readonly id: string;
                        readonly name: string;
                      }
                    | {
                        readonly __typename: 'UserRule';
                        readonly id: string;
                        readonly name: string;
                      }
                  >;
                }
              | null;
          }
        | {
            readonly __typename: 'NcmecManualReviewJobPayload';
            readonly item: {
              readonly __typename: 'UserItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'UserSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly createdAt?: string | null;
                  readonly profileIcon?: string | null;
                  readonly backgroundImage?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            };
            readonly allMediaItems: ReadonlyArray<{
              readonly __typename: 'NcmecContentItem';
              readonly isConfirmedCSAM: boolean;
              readonly isReported: boolean;
              readonly contentItem:
                | {
                    readonly __typename: 'ContentItem';
                    readonly id: string;
                    readonly submissionId: string;
                    readonly submissionTime?: Date | string | null;
                    readonly data: JsonObject;
                    readonly type: {
                      readonly __typename: 'ContentItemType';
                      readonly id: string;
                      readonly name: string;
                      readonly description?: string | null;
                      readonly version: string;
                      readonly schemaVariant: GQLItemTypeSchemaVariant;
                      readonly hiddenFields: ReadonlyArray<string>;
                      readonly schemaFieldRoles: {
                        readonly __typename: 'ContentSchemaFieldRoles';
                        readonly displayName?: string | null;
                        readonly parentId?: string | null;
                        readonly threadId?: string | null;
                        readonly createdAt?: string | null;
                        readonly creatorId?: string | null;
                        readonly isDeleted?: string | null;
                      };
                      readonly baseFields: ReadonlyArray<{
                        readonly __typename: 'BaseField';
                        readonly name: string;
                        readonly required: boolean;
                        readonly type: GQLFieldType;
                        readonly container?: {
                          readonly __typename: 'Container';
                          readonly containerType: GQLContainerType;
                          readonly keyScalarType?: GQLScalarType | null;
                          readonly valueScalarType: GQLScalarType;
                        } | null;
                      }>;
                      readonly derivedFields: ReadonlyArray<{
                        readonly __typename: 'DerivedField';
                        readonly type: GQLFieldType;
                        readonly name: string;
                        readonly container?: {
                          readonly __typename: 'Container';
                          readonly containerType: GQLContainerType;
                          readonly keyScalarType?: GQLScalarType | null;
                          readonly valueScalarType: GQLScalarType;
                        } | null;
                        readonly spec: {
                          readonly __typename: 'DerivedFieldSpec';
                          readonly derivationType: GQLDerivedFieldDerivationType;
                          readonly source:
                            | {
                                readonly __typename: 'DerivedFieldFieldSource';
                                readonly name: string;
                                readonly contentTypeId: string;
                              }
                            | {
                                readonly __typename: 'DerivedFieldFullItemSource';
                                readonly _?: boolean | null;
                              }
                            | {
                                readonly __typename: 'DerivedFieldProtegoInputSource';
                                readonly protegoInput: GQLProtegoInput;
                              };
                        };
                      }>;
                    };
                  }
                | {
                    readonly __typename: 'ThreadItem';
                    readonly id: string;
                    readonly submissionId: string;
                    readonly submissionTime?: Date | string | null;
                    readonly data: JsonObject;
                    readonly type: {
                      readonly __typename: 'ThreadItemType';
                      readonly id: string;
                      readonly name: string;
                      readonly description?: string | null;
                      readonly version: string;
                      readonly schemaVariant: GQLItemTypeSchemaVariant;
                      readonly hiddenFields: ReadonlyArray<string>;
                      readonly schemaFieldRoles: {
                        readonly __typename: 'ThreadSchemaFieldRoles';
                        readonly displayName?: string | null;
                        readonly createdAt?: string | null;
                        readonly creatorId?: string | null;
                        readonly isDeleted?: string | null;
                      };
                      readonly baseFields: ReadonlyArray<{
                        readonly __typename: 'BaseField';
                        readonly name: string;
                        readonly required: boolean;
                        readonly type: GQLFieldType;
                        readonly container?: {
                          readonly __typename: 'Container';
                          readonly containerType: GQLContainerType;
                          readonly keyScalarType?: GQLScalarType | null;
                          readonly valueScalarType: GQLScalarType;
                        } | null;
                      }>;
                      readonly derivedFields: ReadonlyArray<{
                        readonly __typename: 'DerivedField';
                        readonly type: GQLFieldType;
                        readonly name: string;
                        readonly container?: {
                          readonly __typename: 'Container';
                          readonly containerType: GQLContainerType;
                          readonly keyScalarType?: GQLScalarType | null;
                          readonly valueScalarType: GQLScalarType;
                        } | null;
                        readonly spec: {
                          readonly __typename: 'DerivedFieldSpec';
                          readonly derivationType: GQLDerivedFieldDerivationType;
                          readonly source:
                            | {
                                readonly __typename: 'DerivedFieldFieldSource';
                                readonly name: string;
                                readonly contentTypeId: string;
                              }
                            | {
                                readonly __typename: 'DerivedFieldFullItemSource';
                                readonly _?: boolean | null;
                              }
                            | {
                                readonly __typename: 'DerivedFieldProtegoInputSource';
                                readonly protegoInput: GQLProtegoInput;
                              };
                        };
                      }>;
                    };
                  }
                | {
                    readonly __typename: 'UserItem';
                    readonly id: string;
                    readonly submissionId: string;
                    readonly submissionTime?: Date | string | null;
                    readonly data: JsonObject;
                    readonly type: {
                      readonly __typename: 'UserItemType';
                      readonly id: string;
                      readonly name: string;
                      readonly description?: string | null;
                      readonly version: string;
                      readonly schemaVariant: GQLItemTypeSchemaVariant;
                      readonly hiddenFields: ReadonlyArray<string>;
                      readonly schemaFieldRoles: {
                        readonly __typename: 'UserSchemaFieldRoles';
                        readonly displayName?: string | null;
                        readonly createdAt?: string | null;
                        readonly profileIcon?: string | null;
                        readonly backgroundImage?: string | null;
                        readonly isDeleted?: string | null;
                      };
                      readonly baseFields: ReadonlyArray<{
                        readonly __typename: 'BaseField';
                        readonly name: string;
                        readonly required: boolean;
                        readonly type: GQLFieldType;
                        readonly container?: {
                          readonly __typename: 'Container';
                          readonly containerType: GQLContainerType;
                          readonly keyScalarType?: GQLScalarType | null;
                          readonly valueScalarType: GQLScalarType;
                        } | null;
                      }>;
                      readonly derivedFields: ReadonlyArray<{
                        readonly __typename: 'DerivedField';
                        readonly type: GQLFieldType;
                        readonly name: string;
                        readonly container?: {
                          readonly __typename: 'Container';
                          readonly containerType: GQLContainerType;
                          readonly keyScalarType?: GQLScalarType | null;
                          readonly valueScalarType: GQLScalarType;
                        } | null;
                        readonly spec: {
                          readonly __typename: 'DerivedFieldSpec';
                          readonly derivationType: GQLDerivedFieldDerivationType;
                          readonly source:
                            | {
                                readonly __typename: 'DerivedFieldFieldSource';
                                readonly name: string;
                                readonly contentTypeId: string;
                              }
                            | {
                                readonly __typename: 'DerivedFieldFullItemSource';
                                readonly _?: boolean | null;
                              }
                            | {
                                readonly __typename: 'DerivedFieldProtegoInputSource';
                                readonly protegoInput: GQLProtegoInput;
                              };
                        };
                      }>;
                    };
                  };
            }>;
            readonly enqueueSourceInfo?:
              | { readonly __typename: 'AppealEnqueueSourceInfo' }
              | {
                  readonly __typename: 'MrtJobEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'PostActionsEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'ReportEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'RuleExecutionEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                  readonly rules: ReadonlyArray<
                    | {
                        readonly __typename: 'ContentRule';
                        readonly id: string;
                        readonly name: string;
                      }
                    | {
                        readonly __typename: 'UserRule';
                        readonly id: string;
                        readonly name: string;
                      }
                  >;
                }
              | null;
          }
        | {
            readonly __typename: 'ThreadAppealManualReviewJobPayload';
            readonly appealId: string;
            readonly appealReason?: string | null;
            readonly actionsTaken: ReadonlyArray<string>;
            readonly item: {
              readonly __typename: 'ThreadItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ThreadSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            };
            readonly appealerIdentifier?: {
              readonly __typename: 'ItemIdentifier';
              readonly id: string;
              readonly typeId: string;
            } | null;
            readonly enqueueSourceInfo?: {
              readonly __typename: 'AppealEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
            } | null;
          }
        | {
            readonly __typename: 'ThreadManualReviewJobPayload';
            readonly reportHistory: ReadonlyArray<{
              readonly __typename: 'ReportHistoryEntry';
              readonly reportId: string;
              readonly policyId?: string | null;
              readonly reason?: string | null;
              readonly reportedAt: Date | string;
              readonly reporterId?: {
                readonly __typename: 'ItemIdentifier';
                readonly id: string;
                readonly typeId: string;
              } | null;
            }>;
            readonly item: {
              readonly __typename: 'ThreadItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ThreadSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            };
            readonly reportedForReasons: ReadonlyArray<{
              readonly __typename: 'ReportedForReason';
              readonly reason?: string | null;
              readonly reporterId?: {
                readonly __typename: 'ItemIdentifier';
                readonly id: string;
                readonly typeId: string;
              } | null;
            }>;
            readonly enqueueSourceInfo?:
              | { readonly __typename: 'AppealEnqueueSourceInfo' }
              | {
                  readonly __typename: 'MrtJobEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'PostActionsEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'ReportEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'RuleExecutionEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                  readonly rules: ReadonlyArray<
                    | {
                        readonly __typename: 'ContentRule';
                        readonly id: string;
                        readonly name: string;
                      }
                    | {
                        readonly __typename: 'UserRule';
                        readonly id: string;
                        readonly name: string;
                      }
                  >;
                }
              | null;
          }
        | {
            readonly __typename: 'UserAppealManualReviewJobPayload';
            readonly userScore?: number | null;
            readonly appealReason?: string | null;
            readonly appealId: string;
            readonly actionsTaken: ReadonlyArray<string>;
            readonly item: {
              readonly __typename: 'UserItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'UserSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly createdAt?: string | null;
                  readonly profileIcon?: string | null;
                  readonly backgroundImage?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            };
            readonly additionalContentItems: ReadonlyArray<{
              readonly __typename: 'ContentItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ContentSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly parentId?: string | null;
                  readonly threadId?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            }>;
            readonly appealerIdentifier?: {
              readonly __typename: 'ItemIdentifier';
              readonly id: string;
              readonly typeId: string;
            } | null;
            readonly enqueueSourceInfo?: {
              readonly __typename: 'AppealEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
            } | null;
          }
        | {
            readonly __typename: 'UserManualReviewJobPayload';
            readonly userScore?: number | null;
            readonly reportHistory: ReadonlyArray<{
              readonly __typename: 'ReportHistoryEntry';
              readonly reportId: string;
              readonly policyId?: string | null;
              readonly reason?: string | null;
              readonly reportedAt: Date | string;
              readonly reporterId?: {
                readonly __typename: 'ItemIdentifier';
                readonly id: string;
                readonly typeId: string;
              } | null;
            }>;
            readonly item: {
              readonly __typename: 'UserItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'UserSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly createdAt?: string | null;
                  readonly profileIcon?: string | null;
                  readonly backgroundImage?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            };
            readonly itemThreadContentItems?: ReadonlyArray<{
              readonly __typename: 'ContentItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ContentSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly parentId?: string | null;
                  readonly threadId?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            }> | null;
            readonly reportedItems?: ReadonlyArray<{
              readonly __typename: 'ItemIdentifier';
              readonly id: string;
              readonly typeId: string;
            } | null> | null;
            readonly additionalContentItems: ReadonlyArray<{
              readonly __typename: 'ContentItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ContentSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly parentId?: string | null;
                  readonly threadId?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            }>;
            readonly reportedForReasons: ReadonlyArray<{
              readonly __typename: 'ReportedForReason';
              readonly reason?: string | null;
              readonly reporterId?: {
                readonly __typename: 'ItemIdentifier';
                readonly id: string;
                readonly typeId: string;
              } | null;
            }>;
            readonly enqueueSourceInfo?:
              | { readonly __typename: 'AppealEnqueueSourceInfo' }
              | {
                  readonly __typename: 'MrtJobEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'PostActionsEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'ReportEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'RuleExecutionEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                  readonly rules: ReadonlyArray<
                    | {
                        readonly __typename: 'ContentRule';
                        readonly id: string;
                        readonly name: string;
                      }
                    | {
                        readonly __typename: 'UserRule';
                        readonly id: string;
                        readonly name: string;
                      }
                  >;
                }
              | null;
          };
    };
    readonly decision: {
      readonly __typename: 'ManualReviewDecision';
      readonly id: string;
      readonly queueId: string;
      readonly reviewerId?: string | null;
      readonly itemId?: string | null;
      readonly itemTypeId?: string | null;
      readonly jobId: string;
      readonly decisionReason?: string | null;
      readonly createdAt: Date | string;
      readonly decisions: ReadonlyArray<
        | {
            readonly __typename: 'AcceptAppealDecisionComponent';
            readonly appealId: string;
            readonly type: GQLManualReviewDecisionType;
          }
        | {
            readonly __typename: 'AutomaticCloseDecisionComponent';
            readonly type: GQLManualReviewDecisionType;
          }
        | {
            readonly __typename: 'CustomerOrRelatedActionDecisionComponent';
            readonly itemTypeId: string;
            readonly itemIds: ReadonlyArray<string>;
            readonly actionIds: ReadonlyArray<string>;
            readonly policyIds: ReadonlyArray<string>;
            readonly type: GQLManualReviewDecisionType;
          }
        | {
            readonly __typename: 'IgnoreDecisionComponent';
            readonly type: GQLManualReviewDecisionType;
          }
        | {
            readonly __typename: 'RejectAppealDecisionComponent';
            readonly appealId: string;
            readonly type: GQLManualReviewDecisionType;
          }
        | {
            readonly __typename: 'SubmitNCMECReportDecisionComponent';
            readonly type: GQLManualReviewDecisionType;
            readonly reportedMedia: ReadonlyArray<{
              readonly __typename: 'NcmecReportedMediaDetails';
              readonly id: string;
              readonly typeId: string;
              readonly url: string;
              readonly fileAnnotations: ReadonlyArray<GQLNcmecFileAnnotation>;
              readonly industryClassification: GQLNcmecIndustryClassification;
            }>;
          }
        | {
            readonly __typename: 'TransformJobAndRecreateInQueueDecisionComponent';
            readonly newQueueId?: string | null;
            readonly originalQueueId?: string | null;
            readonly type: GQLManualReviewDecisionType;
          }
      >;
      readonly relatedActions: ReadonlyArray<
        | {
            readonly __typename: 'AcceptAppealDecisionComponent';
            readonly appealId: string;
            readonly type: GQLManualReviewDecisionType;
          }
        | {
            readonly __typename: 'AutomaticCloseDecisionComponent';
            readonly type: GQLManualReviewDecisionType;
          }
        | {
            readonly __typename: 'CustomerOrRelatedActionDecisionComponent';
            readonly itemTypeId: string;
            readonly itemIds: ReadonlyArray<string>;
            readonly actionIds: ReadonlyArray<string>;
            readonly policyIds: ReadonlyArray<string>;
            readonly type: GQLManualReviewDecisionType;
          }
        | {
            readonly __typename: 'IgnoreDecisionComponent';
            readonly type: GQLManualReviewDecisionType;
          }
        | {
            readonly __typename: 'RejectAppealDecisionComponent';
            readonly appealId: string;
            readonly type: GQLManualReviewDecisionType;
          }
        | {
            readonly __typename: 'SubmitNCMECReportDecisionComponent';
            readonly type: GQLManualReviewDecisionType;
            readonly reportedMedia: ReadonlyArray<{
              readonly __typename: 'NcmecReportedMediaDetails';
              readonly id: string;
              readonly typeId: string;
              readonly url: string;
              readonly fileAnnotations: ReadonlyArray<GQLNcmecFileAnnotation>;
              readonly industryClassification: GQLNcmecIndustryClassification;
            }>;
          }
        | {
            readonly __typename: 'TransformJobAndRecreateInQueueDecisionComponent';
            readonly newQueueId?: string | null;
            readonly originalQueueId?: string | null;
            readonly type: GQLManualReviewDecisionType;
          }
      >;
    };
  } | null;
};

export type GQLSpotTestModelQueryVariables = Exact<{
  modelVersion: Scalars['Int'];
  modelId: Scalars['ID'];
  item: GQLSpotTestItemInput;
}>;

export type GQLSpotTestModelQuery = {
  readonly __typename: 'Query';
  readonly spotTestModel:
    | {
        readonly __typename: 'ModelExecutionErrorResult';
        readonly _?: boolean | null;
      }
    | {
        readonly __typename: 'ModelExecutionSuccessResult';
        readonly score: number;
      }
    | {
        readonly __typename: 'ModelExecutionUnscoreableResult';
        readonly _?: boolean | null;
      };
};

export type GQLLatestSuccessfulSamplingJobQueryVariables = Exact<{
  modelId: Scalars['String'];
  modelVersion: Scalars['Int'];
  samplingStrategy: GQLSamplingStrategyInput;
}>;

export type GQLLatestSuccessfulSamplingJobQuery = {
  readonly __typename: 'Query';
  readonly getLatestSuccessfulSamplingJobResultForModel?: {
    readonly __typename: 'SamplingJobSuccess';
    readonly samplingStrategy: string;
    readonly samples: ReadonlyArray<{
      readonly __typename: 'SampleEntry';
      readonly score?: number | null;
      readonly item: {
        readonly __typename: 'SampledItem';
        readonly itemId: string;
        readonly data: JsonObject;
        readonly itemType: {
          readonly __typename: 'ItemTypeIdentifier';
          readonly id: string;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
        };
      };
      readonly additionalModelScore?: {
        readonly __typename: 'ModelScore';
        readonly score?: number | null;
      } | null;
    }>;
  } | null;
};

export type GQLCreateExistingCoveModelSamplingJobMutationVariables = Exact<{
  input: GQLCreateExistingCoveModelSamplingJobInput;
}>;

export type GQLCreateExistingCoveModelSamplingJobMutation = {
  readonly __typename: 'Mutation';
  readonly createExistingCoveModelSamplingJob: {
    readonly __typename: 'CreateSamplingJobResponse';
    readonly jobId: string;
  };
};

export type GQLRetrainModelMutationVariables = Exact<{
  input: GQLRetrainExistingModelInput;
}>;

export type GQLRetrainModelMutation = {
  readonly __typename: 'Mutation';
  readonly retrainExistingModel: {
    readonly __typename: 'RetrainModelSuccessResponse';
    readonly _?: boolean | null;
  };
};

export type GQLGetLiveModelForPolicyQueryVariables = Exact<{
  policyId: Scalars['ID'];
}>;

export type GQLGetLiveModelForPolicyQuery = {
  readonly __typename: 'Query';
  readonly getLiveModelForPolicy?: {
    readonly __typename: 'CoveModel';
    readonly id: string;
    readonly version: number;
  } | null;
};

export type GQLGetComparisonSamplingJobResultsQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;

export type GQLGetComparisonSamplingJobResultsQuery = {
  readonly __typename: 'Query';
  readonly getSamplingJobResults:
    | { readonly __typename: 'SamplingJobFailure'; readonly title: string }
    | {
        readonly __typename: 'SamplingJobNotFoundError';
        readonly title: string;
      }
    | { readonly __typename: 'SamplingJobPending' }
    | {
        readonly __typename: 'SamplingJobSuccess';
        readonly samplingStrategy: string;
        readonly samples: ReadonlyArray<{
          readonly __typename: 'SampleEntry';
          readonly score?: number | null;
          readonly item: {
            readonly __typename: 'SampledItem';
            readonly itemId: string;
            readonly data: JsonObject;
            readonly itemType: {
              readonly __typename: 'ItemTypeIdentifier';
              readonly id: string;
              readonly version: string;
              readonly schemaVariant: GQLItemTypeSchemaVariant;
            };
          };
          readonly additionalModelScore?: {
            readonly __typename: 'ModelScore';
            readonly score?: number | null;
          } | null;
        }>;
      };
};

export type GQLGetModelVersionsWithDetailsQueryVariables = Exact<{
  modelId: Scalars['ID'];
}>;

export type GQLGetModelVersionsWithDetailsQuery = {
  readonly __typename: 'Query';
  readonly getModelVerionsWithDetails: ReadonlyArray<{
    readonly __typename: 'CoveModel';
    readonly id: string;
    readonly version: number;
    readonly family: string;
    readonly status: GQLModelVersionStatus;
    readonly createdAt: Date | string;
    readonly itemRequirement: GQLItemRequirement;
    readonly alias?: GQLModelVersionAlias | null;
    readonly policy: {
      readonly __typename: 'Policy';
      readonly id: string;
      readonly name: string;
      readonly parentId?: string | null;
    };
  }>;
};

export type GQLModelsDashboardQueryVariables = Exact<{ [key: string]: never }>;

export type GQLModelsDashboardQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly isDemoOrg: boolean;
    readonly policies: ReadonlyArray<{
      readonly __typename: 'Policy';
      readonly id: string;
      readonly name: string;
      readonly policyText?: string | null;
      readonly enforcementGuidelines?: string | null;
      readonly parentId?: string | null;
      readonly policyType?: GQLPolicyType | null;
    }>;
    readonly models: ReadonlyArray<{
      readonly __typename: 'CoveModel';
      readonly id: string;
      readonly status: GQLModelVersionStatus;
      readonly version: number;
      readonly itemRequirement: GQLItemRequirement;
      readonly createdAt: Date | string;
      readonly alias?: GQLModelVersionAlias | null;
      readonly policy: { readonly __typename: 'Policy'; readonly id: string };
    }>;
  } | null;
};

export type GQLSaveCustomerInfoMutationVariables = Exact<{
  input: GQLCustomerInfoInput;
}>;

export type GQLSaveCustomerInfoMutation = {
  readonly __typename: 'Mutation';
  readonly saveCustomerInfo?: boolean | null;
};

export type GQLFetchSignedUrlQueryVariables = Exact<{
  filename: Scalars['String'];
  fileType: Scalars['String'];
}>;

export type GQLFetchSignedUrlQuery = {
  readonly __typename: 'Query';
  readonly getSignedS3Url: {
    readonly __typename: 'SignedUrl';
    readonly url: string;
  };
};

export type GQLGetSamplesForLabelingQueryVariables = Exact<{
  input: GQLGetRedditExamplesForLabelingScreenInput;
}>;

export type GQLGetSamplesForLabelingQuery = {
  readonly __typename: 'Query';
  readonly getRedditExamplesForLabelingScreen: {
    readonly __typename: 'GetRedditExamplesForLabelingScreenResponse';
    readonly items: ReadonlyArray<string>;
  };
};

export type GQLGetRedditExamplesQueryVariables = Exact<{
  input: GQLGetRedditExamplesForThresholdScreenInput;
}>;

export type GQLGetRedditExamplesQuery = {
  readonly __typename: 'Query';
  readonly getRedditExamplesForThresholdScreen: {
    readonly __typename: 'GetRedditExamplesForThresholdScreenResponse';
    readonly items: ReadonlyArray<{
      readonly __typename: 'ScoredRedditExample';
      readonly text: string;
      readonly score: number;
    }>;
  };
};

export type GQLOrgDataForModelTrainingQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLOrgDataForModelTrainingQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly id: string;
    readonly itemTypes: ReadonlyArray<
      | {
          readonly __typename: 'ContentItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'ContentSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly parentId?: string | null;
            readonly threadId?: string | null;
            readonly createdAt?: string | null;
            readonly creatorId?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
      | {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'ThreadSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly creatorId?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
      | {
          readonly __typename: 'UserItemType';
          readonly isDefaultUserType: boolean;
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'UserSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly profileIcon?: string | null;
            readonly backgroundImage?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
    >;
    readonly actions: ReadonlyArray<
      | {
          readonly __typename: 'CustomerDefinedAction';
          readonly id: string;
          readonly name: string;
          readonly itemTypes: ReadonlyArray<
            | { readonly __typename: 'ContentItemType'; readonly id: string }
            | { readonly __typename: 'ThreadItemType'; readonly id: string }
            | { readonly __typename: 'UserItemType'; readonly id: string }
          >;
        }
      | {
          readonly __typename: 'EnqueueAuthorToMrtAction';
          readonly id: string;
          readonly name: string;
          readonly itemTypes: ReadonlyArray<
            | { readonly __typename: 'ContentItemType'; readonly id: string }
            | { readonly __typename: 'ThreadItemType'; readonly id: string }
            | { readonly __typename: 'UserItemType'; readonly id: string }
          >;
        }
      | {
          readonly __typename: 'EnqueueToMrtAction';
          readonly id: string;
          readonly name: string;
          readonly itemTypes: ReadonlyArray<
            | { readonly __typename: 'ContentItemType'; readonly id: string }
            | { readonly __typename: 'ThreadItemType'; readonly id: string }
            | { readonly __typename: 'UserItemType'; readonly id: string }
          >;
        }
      | {
          readonly __typename: 'EnqueueToNcmecAction';
          readonly id: string;
          readonly name: string;
          readonly itemTypes: ReadonlyArray<
            | { readonly __typename: 'ContentItemType'; readonly id: string }
            | { readonly __typename: 'ThreadItemType'; readonly id: string }
            | { readonly __typename: 'UserItemType'; readonly id: string }
          >;
        }
    >;
    readonly policies: ReadonlyArray<{
      readonly __typename: 'Policy';
      readonly id: string;
      readonly name: string;
      readonly policyText?: string | null;
      readonly enforcementGuidelines?: string | null;
    }>;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly permissions: ReadonlyArray<GQLUserPermission>;
  } | null;
};

export type GQLGetItemTypesByIdentifiersQueryVariables = Exact<{
  identifiers:
    | ReadonlyArray<GQLItemTypeIdentifierInput>
    | GQLItemTypeIdentifierInput;
}>;

export type GQLGetItemTypesByIdentifiersQuery = {
  readonly __typename: 'Query';
  readonly itemTypes: ReadonlyArray<
    | {
        readonly __typename: 'ContentItemType';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly version: string;
        readonly schemaVariant: GQLItemTypeSchemaVariant;
        readonly hiddenFields: ReadonlyArray<string>;
        readonly schemaFieldRoles: {
          readonly __typename: 'ContentSchemaFieldRoles';
          readonly displayName?: string | null;
          readonly parentId?: string | null;
          readonly threadId?: string | null;
          readonly createdAt?: string | null;
          readonly creatorId?: string | null;
          readonly isDeleted?: string | null;
        };
        readonly baseFields: ReadonlyArray<{
          readonly __typename: 'BaseField';
          readonly name: string;
          readonly required: boolean;
          readonly type: GQLFieldType;
          readonly container?: {
            readonly __typename: 'Container';
            readonly containerType: GQLContainerType;
            readonly keyScalarType?: GQLScalarType | null;
            readonly valueScalarType: GQLScalarType;
          } | null;
        }>;
        readonly derivedFields: ReadonlyArray<{
          readonly __typename: 'DerivedField';
          readonly type: GQLFieldType;
          readonly name: string;
          readonly container?: {
            readonly __typename: 'Container';
            readonly containerType: GQLContainerType;
            readonly keyScalarType?: GQLScalarType | null;
            readonly valueScalarType: GQLScalarType;
          } | null;
          readonly spec: {
            readonly __typename: 'DerivedFieldSpec';
            readonly derivationType: GQLDerivedFieldDerivationType;
            readonly source:
              | {
                  readonly __typename: 'DerivedFieldFieldSource';
                  readonly name: string;
                  readonly contentTypeId: string;
                }
              | {
                  readonly __typename: 'DerivedFieldFullItemSource';
                  readonly _?: boolean | null;
                }
              | {
                  readonly __typename: 'DerivedFieldProtegoInputSource';
                  readonly protegoInput: GQLProtegoInput;
                };
          };
        }>;
      }
    | {
        readonly __typename: 'ThreadItemType';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly version: string;
        readonly schemaVariant: GQLItemTypeSchemaVariant;
        readonly hiddenFields: ReadonlyArray<string>;
        readonly schemaFieldRoles: {
          readonly __typename: 'ThreadSchemaFieldRoles';
          readonly displayName?: string | null;
          readonly createdAt?: string | null;
          readonly creatorId?: string | null;
          readonly isDeleted?: string | null;
        };
        readonly baseFields: ReadonlyArray<{
          readonly __typename: 'BaseField';
          readonly name: string;
          readonly required: boolean;
          readonly type: GQLFieldType;
          readonly container?: {
            readonly __typename: 'Container';
            readonly containerType: GQLContainerType;
            readonly keyScalarType?: GQLScalarType | null;
            readonly valueScalarType: GQLScalarType;
          } | null;
        }>;
        readonly derivedFields: ReadonlyArray<{
          readonly __typename: 'DerivedField';
          readonly type: GQLFieldType;
          readonly name: string;
          readonly container?: {
            readonly __typename: 'Container';
            readonly containerType: GQLContainerType;
            readonly keyScalarType?: GQLScalarType | null;
            readonly valueScalarType: GQLScalarType;
          } | null;
          readonly spec: {
            readonly __typename: 'DerivedFieldSpec';
            readonly derivationType: GQLDerivedFieldDerivationType;
            readonly source:
              | {
                  readonly __typename: 'DerivedFieldFieldSource';
                  readonly name: string;
                  readonly contentTypeId: string;
                }
              | {
                  readonly __typename: 'DerivedFieldFullItemSource';
                  readonly _?: boolean | null;
                }
              | {
                  readonly __typename: 'DerivedFieldProtegoInputSource';
                  readonly protegoInput: GQLProtegoInput;
                };
          };
        }>;
      }
    | {
        readonly __typename: 'UserItemType';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly version: string;
        readonly schemaVariant: GQLItemTypeSchemaVariant;
        readonly hiddenFields: ReadonlyArray<string>;
        readonly schemaFieldRoles: {
          readonly __typename: 'UserSchemaFieldRoles';
          readonly displayName?: string | null;
          readonly createdAt?: string | null;
          readonly profileIcon?: string | null;
          readonly backgroundImage?: string | null;
          readonly isDeleted?: string | null;
        };
        readonly baseFields: ReadonlyArray<{
          readonly __typename: 'BaseField';
          readonly name: string;
          readonly required: boolean;
          readonly type: GQLFieldType;
          readonly container?: {
            readonly __typename: 'Container';
            readonly containerType: GQLContainerType;
            readonly keyScalarType?: GQLScalarType | null;
            readonly valueScalarType: GQLScalarType;
          } | null;
        }>;
        readonly derivedFields: ReadonlyArray<{
          readonly __typename: 'DerivedField';
          readonly type: GQLFieldType;
          readonly name: string;
          readonly container?: {
            readonly __typename: 'Container';
            readonly containerType: GQLContainerType;
            readonly keyScalarType?: GQLScalarType | null;
            readonly valueScalarType: GQLScalarType;
          } | null;
          readonly spec: {
            readonly __typename: 'DerivedFieldSpec';
            readonly derivationType: GQLDerivedFieldDerivationType;
            readonly source:
              | {
                  readonly __typename: 'DerivedFieldFieldSource';
                  readonly name: string;
                  readonly contentTypeId: string;
                }
              | {
                  readonly __typename: 'DerivedFieldFullItemSource';
                  readonly _?: boolean | null;
                }
              | {
                  readonly __typename: 'DerivedFieldProtegoInputSource';
                  readonly protegoInput: GQLProtegoInput;
                };
          };
        }>;
      }
  >;
};

export type GQLGenerateDefaultPolicyDefinitionQueryVariables = Exact<{
  input: GQLGeneratePolicyInput;
}>;

export type GQLGenerateDefaultPolicyDefinitionQuery = {
  readonly __typename: 'Query';
  readonly generatePolicyText:
    | {
        readonly __typename: 'GeneratedPolicyTextCompletionError';
        readonly title: string;
      }
    | {
        readonly __typename: 'GeneratedPolicyTextCompletionSuccess';
        readonly policyText: string;
      };
};

export type GQLUpdatePolicyDefinitionMutationVariables = Exact<{
  input: GQLUpdatePolicyInput;
}>;

export type GQLUpdatePolicyDefinitionMutation = {
  readonly __typename: 'Mutation';
  readonly updatePolicy:
    | { readonly __typename: 'NotFoundError'; readonly title: string }
    | {
        readonly __typename: 'Policy';
        readonly id: string;
        readonly name: string;
        readonly policyText?: string | null;
      };
};

export type GQLStartCoveModelSignalCreationSessionMutationVariables = Exact<{
  input: GQLStartCoveModelSignalCreationSessionInput;
}>;

export type GQLStartCoveModelSignalCreationSessionMutation = {
  readonly __typename: 'Mutation';
  readonly startCoveModelSignalCreationSession: {
    readonly __typename: 'StartCoveModelSignalCreationSessionSuccessResponse';
    readonly sessionId: any;
  };
};

export type GQLGetLabelCountForPolicyQueryVariables = Exact<{
  policyId: Scalars['ID'];
  policySemanticVersion?: InputMaybe<Scalars['Int']>;
}>;

export type GQLGetLabelCountForPolicyQuery = {
  readonly __typename: 'Query';
  readonly getLabelCountForPolicy: {
    readonly __typename: 'LabelCount';
    readonly positiveCount: number;
    readonly negativeCount: number;
  };
};

export type GQLGetModelsQueryVariables = Exact<{ [key: string]: never }>;

export type GQLGetModelsQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly models: ReadonlyArray<{
      readonly __typename: 'CoveModel';
      readonly id: string;
      readonly version: number;
      readonly name: string;
      readonly policy: {
        readonly __typename: 'Policy';
        readonly id: string;
        readonly name: string;
      };
    }>;
    readonly policies: ReadonlyArray<{
      readonly __typename: 'Policy';
      readonly id: string;
      readonly name: string;
    }>;
  } | null;
};

export type GQLCreateCoveModelSignalCreationSamplingJobMutationVariables =
  Exact<{
    input: GQLCreateCoveModelSignalCreationSamplingJobInput;
  }>;

export type GQLCreateCoveModelSignalCreationSamplingJobMutation = {
  readonly __typename: 'Mutation';
  readonly createCoveModelSignalCreationSamplingJob: {
    readonly __typename: 'CreateSamplingJobResponse';
    readonly jobId: string;
  };
};

export type GQLSubmitLabelsMutationVariables = Exact<{
  input: GQLLabelItemsInput;
}>;

export type GQLSubmitLabelsMutation = {
  readonly __typename: 'Mutation';
  readonly labelItems: {
    readonly __typename: 'LabelItemsResponse';
    readonly _?: boolean | null;
  };
};

export type GQLGetSamplingJobResultsQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;

export type GQLGetSamplingJobResultsQuery = {
  readonly __typename: 'Query';
  readonly getSamplingJobResults:
    | { readonly __typename: 'SamplingJobFailure'; readonly title: string }
    | {
        readonly __typename: 'SamplingJobNotFoundError';
        readonly title: string;
      }
    | { readonly __typename: 'SamplingJobPending' }
    | {
        readonly __typename: 'SamplingJobSuccess';
        readonly samplingStrategy: string;
        readonly samples: ReadonlyArray<{
          readonly __typename: 'SampleEntry';
          readonly score?: number | null;
          readonly item: {
            readonly __typename: 'SampledItem';
            readonly itemId: string;
            readonly data: JsonObject;
            readonly itemType: {
              readonly __typename: 'ItemTypeIdentifier';
              readonly id: string;
              readonly version: string;
              readonly schemaVariant: GQLItemTypeSchemaVariant;
            };
          };
        }>;
      };
};

export type GQLTrainAndSaveModelMutationVariables = Exact<{
  sessionId: Scalars['CoveModelSignalCreationSessionId'];
}>;

export type GQLTrainAndSaveModelMutation = {
  readonly __typename: 'Mutation';
  readonly finishCoveModelSignalCreationSession: boolean;
};

export type GQLCreateRulesForModelThresholdsMutationVariables = Exact<{
  input: GQLCreateRulesForCoveModelInput;
}>;

export type GQLCreateRulesForModelThresholdsMutation = {
  readonly __typename: 'Mutation';
  readonly createRulesForModel: {
    readonly __typename: 'CreateRulesForModelSuccessResponse';
    readonly rules: ReadonlyArray<
      | { readonly __typename: 'ContentRule'; readonly id: string }
      | { readonly __typename: 'UserRule'; readonly id: string }
    >;
  };
};

export type GQLGetCoveModelSignalCreationSessionResultQueryVariables = Exact<{
  sessionId: Scalars['CoveModelSignalCreationSessionId'];
}>;

export type GQLGetCoveModelSignalCreationSessionResultQuery = {
  readonly __typename: 'Query';
  readonly getCoveModelSignalCreationSessionResult:
    | {
        readonly __typename: 'CoveModelSignalCreatedPending';
        readonly _?: boolean | null;
      }
    | {
        readonly __typename: 'CoveModelSignalCreatedSuccess';
        readonly signal: { readonly __typename: 'Signal'; readonly id: string };
      }
    | {
        readonly __typename: 'CoveModelSignalCreationError';
        readonly title: string;
      };
};

export type GQLManualReviewMetricsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLManualReviewMetricsQuery = {
  readonly __typename: 'Query';
  readonly getTotalPendingJobsCount: number;
  readonly reportingInsights: {
    readonly __typename: 'ReportingInsights';
    readonly totalIngestedReportsByDay: ReadonlyArray<{
      readonly __typename: 'CountByDay';
      readonly date: Date | string;
      readonly count: number;
    }>;
  };
};

export type GQLGetAverageTimeToReviewQueryVariables = Exact<{
  input: GQLTimeToActionInput;
}>;

export type GQLGetAverageTimeToReviewQuery = {
  readonly __typename: 'Query';
  readonly getTimeToAction?: ReadonlyArray<{
    readonly __typename: 'TimeToAction';
    readonly timeToAction?: number | null;
    readonly queueId?: string | null;
  }> | null;
};

export type GQLAppealSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GQLAppealSettingsQuery = {
  readonly __typename: 'Query';
  readonly appealSettings?: {
    readonly __typename: 'AppealSettings';
    readonly appealsCallbackUrl?: string | null;
    readonly appealsCallbackHeaders?: JsonObject | null;
    readonly appealsCallbackBody?: JsonObject | null;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly role?: GQLUserRole | null;
  } | null;
};

export type GQLUpdateAppealSettingsMutationVariables = Exact<{
  input: GQLAppealSettingsInput;
}>;

export type GQLUpdateAppealSettingsMutation = {
  readonly __typename: 'Mutation';
  readonly updateAppealSettings: {
    readonly __typename: 'AppealSettings';
    readonly appealsCallbackUrl?: string | null;
    readonly appealsCallbackHeaders?: JsonObject | null;
    readonly appealsCallbackBody?: JsonObject | null;
  };
};

export type GQLGetDecisionsTableQueryVariables = Exact<{
  input: GQLGetDecisionCountsTableInput;
}>;

export type GQLGetDecisionsTableQuery = {
  readonly __typename: 'Query';
  readonly getDecisionsTable: ReadonlyArray<{
    readonly __typename: 'TableDecisionCount';
    readonly count: number;
    readonly type: GQLManualReviewDecisionType;
    readonly action_id?: string | null;
    readonly queue_id?: string | null;
    readonly reviewer_id?: string | null;
  }>;
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly actions: ReadonlyArray<
      | {
          readonly __typename: 'CustomerDefinedAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueAuthorToMrtAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueToMrtAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueToNcmecAction';
          readonly id: string;
          readonly name: string;
        }
    >;
    readonly users: ReadonlyArray<{
      readonly __typename: 'User';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    }>;
    readonly mrtQueues: ReadonlyArray<{
      readonly __typename: 'ManualReviewQueue';
      readonly id: string;
      readonly name: string;
    }>;
  } | null;
};

export type GQLQueueFormDataQueryVariables = Exact<{ [key: string]: never }>;

export type GQLQueueFormDataQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly hasAppealsEnabled: boolean;
    readonly hasPartialItemsEndpoint: boolean;
    readonly users: ReadonlyArray<{
      readonly __typename: 'User';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
      readonly role?: GQLUserRole | null;
    }>;
    readonly actions: ReadonlyArray<
      | {
          readonly __typename: 'CustomerDefinedAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueAuthorToMrtAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueToMrtAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueToNcmecAction';
          readonly id: string;
          readonly name: string;
        }
    >;
    readonly usersWhoCanReviewEveryQueue: ReadonlyArray<{
      readonly __typename: 'User';
      readonly id: string;
    }>;
  } | null;
};

export type GQLManualReviewQueueQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLManualReviewQueueQuery = {
  readonly __typename: 'Query';
  readonly manualReviewQueue?: {
    readonly __typename: 'ManualReviewQueue';
    readonly id: string;
    readonly name: string;
    readonly description?: string | null;
    readonly hiddenActionIds: ReadonlyArray<string>;
    readonly isAppealsQueue: boolean;
    readonly autoCloseJobs: boolean;
    readonly explicitlyAssignedReviewers: ReadonlyArray<{
      readonly __typename: 'User';
      readonly id: string;
    }>;
  } | null;
};

export type GQLCreateManualReviewQueueMutationVariables = Exact<{
  input: GQLCreateManualReviewQueueInput;
}>;

export type GQLCreateManualReviewQueueMutation = {
  readonly __typename: 'Mutation';
  readonly createManualReviewQueue:
    | {
        readonly __typename: 'ManualReviewQueueNameExistsError';
        readonly title: string;
        readonly status: number;
        readonly type: ReadonlyArray<string>;
      }
    | {
        readonly __typename: 'MutateManualReviewQueueSuccessResponse';
        readonly data: {
          readonly __typename: 'ManualReviewQueue';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
        };
      };
};

export type GQLUpdateManualReviewQueueMutationVariables = Exact<{
  input: GQLUpdateManualReviewQueueInput;
}>;

export type GQLUpdateManualReviewQueueMutation = {
  readonly __typename: 'Mutation';
  readonly updateManualReviewQueue:
    | {
        readonly __typename: 'ManualReviewQueueNameExistsError';
        readonly title: string;
        readonly status: number;
        readonly type: ReadonlyArray<string>;
      }
    | {
        readonly __typename: 'MutateManualReviewQueueSuccessResponse';
        readonly data: {
          readonly __typename: 'ManualReviewQueue';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
        };
      }
    | {
        readonly __typename: 'NotFoundError';
        readonly title: string;
        readonly status: number;
        readonly type: ReadonlyArray<string>;
      };
};

export type GQLManualReviewQueueJobsPreviewQueryVariables = Exact<{
  queueIds?: InputMaybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
}>;

export type GQLManualReviewQueueJobsPreviewQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly policies: ReadonlyArray<{
      readonly __typename: 'Policy';
      readonly id: string;
      readonly name: string;
    }>;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly id: string;
    readonly permissions: ReadonlyArray<GQLUserPermission>;
    readonly reviewableQueues: ReadonlyArray<{
      readonly __typename: 'ManualReviewQueue';
      readonly id: string;
      readonly name: string;
      readonly description?: string | null;
      readonly pendingJobCount: number;
      readonly jobs: ReadonlyArray<{
        readonly __typename: 'ManualReviewJob';
        readonly id: string;
        readonly createdAt: Date | string;
        readonly policyIds: ReadonlyArray<string>;
        readonly payload:
          | {
              readonly __typename: 'ContentAppealManualReviewJobPayload';
              readonly item: {
                readonly __typename: 'ContentItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'ContentItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'ContentSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly parentId?: string | null;
                    readonly threadId?: string | null;
                    readonly createdAt?: string | null;
                    readonly creatorId?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              };
            }
          | {
              readonly __typename: 'ContentManualReviewJobPayload';
              readonly item: {
                readonly __typename: 'ContentItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'ContentItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'ContentSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly parentId?: string | null;
                    readonly threadId?: string | null;
                    readonly createdAt?: string | null;
                    readonly creatorId?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              };
            }
          | {
              readonly __typename: 'NcmecManualReviewJobPayload';
              readonly item: {
                readonly __typename: 'UserItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'UserItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'UserSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly createdAt?: string | null;
                    readonly profileIcon?: string | null;
                    readonly backgroundImage?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              };
            }
          | {
              readonly __typename: 'ThreadAppealManualReviewJobPayload';
              readonly item: {
                readonly __typename: 'ThreadItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'ThreadItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'ThreadSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly createdAt?: string | null;
                    readonly creatorId?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              };
            }
          | {
              readonly __typename: 'ThreadManualReviewJobPayload';
              readonly item: {
                readonly __typename: 'ThreadItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'ThreadItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'ThreadSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly createdAt?: string | null;
                    readonly creatorId?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              };
            }
          | {
              readonly __typename: 'UserAppealManualReviewJobPayload';
              readonly item: {
                readonly __typename: 'UserItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'UserItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'UserSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly createdAt?: string | null;
                    readonly profileIcon?: string | null;
                    readonly backgroundImage?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              };
            }
          | {
              readonly __typename: 'UserManualReviewJobPayload';
              readonly item: {
                readonly __typename: 'UserItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'UserItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'UserSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly createdAt?: string | null;
                    readonly profileIcon?: string | null;
                    readonly backgroundImage?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              };
            };
      }>;
    }>;
  } | null;
};

export type GQLManualReviewQueuesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLManualReviewQueuesQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly hasAppealsEnabled: boolean;
    readonly previewJobsViewEnabled: boolean;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly id: string;
    readonly permissions: ReadonlyArray<GQLUserPermission>;
    readonly favoriteMRTQueues: ReadonlyArray<{
      readonly __typename: 'ManualReviewQueue';
      readonly id: string;
    }>;
    readonly reviewableQueues: ReadonlyArray<{
      readonly __typename: 'ManualReviewQueue';
      readonly id: string;
      readonly name: string;
      readonly description?: string | null;
      readonly pendingJobCount: number;
      readonly isDefaultQueue: boolean;
      readonly isAppealsQueue: boolean;
    }>;
  } | null;
};

export type GQLRoutingRulesQueryVariables = Exact<{ [key: string]: never }>;

export type GQLRoutingRulesQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly id: string;
    readonly routingRules: ReadonlyArray<{
      readonly __typename: 'RoutingRule';
      readonly id: string;
      readonly name: string;
      readonly destinationQueue: {
        readonly __typename: 'ManualReviewQueue';
        readonly id: string;
      };
    }>;
  } | null;
};

export type GQLDeleteManualReviewQueueMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLDeleteManualReviewQueueMutation = {
  readonly __typename: 'Mutation';
  readonly deleteManualReviewQueue: boolean;
};

export type GQLDeleteAllJobsFromQueueMutationVariables = Exact<{
  queueId: Scalars['ID'];
}>;

export type GQLDeleteAllJobsFromQueueMutation = {
  readonly __typename: 'Mutation';
  readonly deleteAllJobsFromQueue:
    | {
        readonly __typename: 'DeleteAllJobsFromQueueSuccessResponse';
        readonly _: boolean;
      }
    | {
        readonly __typename: 'DeleteAllJobsUnauthorizedError';
        readonly title: string;
      };
};

export type GQLAddFavoriteMrtQueueMutationVariables = Exact<{
  queueId: Scalars['ID'];
}>;

export type GQLAddFavoriteMrtQueueMutation = {
  readonly __typename: 'Mutation';
  readonly addFavoriteMRTQueue: {
    readonly __typename: 'AddFavoriteMRTQueueSuccessResponse';
    readonly _?: boolean | null;
  };
};

export type GQLRemoveFavoriteMrtQueueMutationVariables = Exact<{
  queueId: Scalars['ID'];
}>;

export type GQLRemoveFavoriteMrtQueueMutation = {
  readonly __typename: 'Mutation';
  readonly removeFavoriteMRTQueue: {
    readonly __typename: 'RemoveFavoriteMRTQueueSuccessResponse';
    readonly _?: boolean | null;
  };
};

export type GQLGetResolvedJobsForUserQueryVariables = Exact<{
  timeZone: Scalars['String'];
}>;

export type GQLGetResolvedJobsForUserQuery = {
  readonly __typename: 'Query';
  readonly getResolvedJobsForUser: number;
};

export type GQLGetSkippedJobsForUserQueryVariables = Exact<{
  timeZone: Scalars['String'];
}>;

export type GQLGetSkippedJobsForUserQuery = {
  readonly __typename: 'Query';
  readonly getSkippedJobsForUser: number;
};

export type GQLRecentDecisionsSummaryDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLRecentDecisionsSummaryDataQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly users: ReadonlyArray<{
      readonly __typename: 'User';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    }>;
    readonly mrtQueues: ReadonlyArray<{
      readonly __typename: 'ManualReviewQueue';
      readonly id: string;
      readonly name: string;
    }>;
    readonly actions: ReadonlyArray<
      | {
          readonly __typename: 'CustomerDefinedAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueAuthorToMrtAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueToMrtAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueToNcmecAction';
          readonly id: string;
          readonly name: string;
        }
    >;
    readonly policies: ReadonlyArray<{
      readonly __typename: 'Policy';
      readonly id: string;
      readonly name: string;
    }>;
    readonly itemTypes: ReadonlyArray<
      | {
          readonly __typename: 'ContentItemType';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'UserItemType';
          readonly id: string;
          readonly name: string;
        }
    >;
  } | null;
};

type GQLManualReviewDecisionComponentFieldsAcceptAppealDecisionComponentFragment =
  {
    readonly __typename: 'AcceptAppealDecisionComponent';
    readonly appealId: string;
    readonly type: GQLManualReviewDecisionType;
  };

type GQLManualReviewDecisionComponentFieldsAutomaticCloseDecisionComponentFragment =
  {
    readonly __typename: 'AutomaticCloseDecisionComponent';
    readonly type: GQLManualReviewDecisionType;
  };

type GQLManualReviewDecisionComponentFieldsCustomerOrRelatedActionDecisionComponentFragment =
  {
    readonly __typename: 'CustomerOrRelatedActionDecisionComponent';
    readonly itemTypeId: string;
    readonly itemIds: ReadonlyArray<string>;
    readonly actionIds: ReadonlyArray<string>;
    readonly policyIds: ReadonlyArray<string>;
    readonly type: GQLManualReviewDecisionType;
  };

type GQLManualReviewDecisionComponentFieldsIgnoreDecisionComponentFragment = {
  readonly __typename: 'IgnoreDecisionComponent';
  readonly type: GQLManualReviewDecisionType;
};

type GQLManualReviewDecisionComponentFieldsRejectAppealDecisionComponentFragment =
  {
    readonly __typename: 'RejectAppealDecisionComponent';
    readonly appealId: string;
    readonly type: GQLManualReviewDecisionType;
  };

type GQLManualReviewDecisionComponentFieldsSubmitNcmecReportDecisionComponentFragment =
  {
    readonly __typename: 'SubmitNCMECReportDecisionComponent';
    readonly type: GQLManualReviewDecisionType;
    readonly reportedMedia: ReadonlyArray<{
      readonly __typename: 'NcmecReportedMediaDetails';
      readonly id: string;
      readonly typeId: string;
      readonly url: string;
      readonly fileAnnotations: ReadonlyArray<GQLNcmecFileAnnotation>;
      readonly industryClassification: GQLNcmecIndustryClassification;
    }>;
  };

type GQLManualReviewDecisionComponentFieldsTransformJobAndRecreateInQueueDecisionComponentFragment =
  {
    readonly __typename: 'TransformJobAndRecreateInQueueDecisionComponent';
    readonly newQueueId?: string | null;
    readonly originalQueueId?: string | null;
    readonly type: GQLManualReviewDecisionType;
  };

export type GQLManualReviewDecisionComponentFieldsFragment =
  | GQLManualReviewDecisionComponentFieldsAcceptAppealDecisionComponentFragment
  | GQLManualReviewDecisionComponentFieldsAutomaticCloseDecisionComponentFragment
  | GQLManualReviewDecisionComponentFieldsCustomerOrRelatedActionDecisionComponentFragment
  | GQLManualReviewDecisionComponentFieldsIgnoreDecisionComponentFragment
  | GQLManualReviewDecisionComponentFieldsRejectAppealDecisionComponentFragment
  | GQLManualReviewDecisionComponentFieldsSubmitNcmecReportDecisionComponentFragment
  | GQLManualReviewDecisionComponentFieldsTransformJobAndRecreateInQueueDecisionComponentFragment;

export type GQLGetRecentDecisionsQueryVariables = Exact<{
  input: GQLRecentDecisionsInput;
}>;

export type GQLGetRecentDecisionsQuery = {
  readonly __typename: 'Query';
  readonly getRecentDecisions: ReadonlyArray<{
    readonly __typename: 'ManualReviewDecision';
    readonly id: string;
    readonly jobId: string;
    readonly queueId: string;
    readonly reviewerId?: string | null;
    readonly itemId?: string | null;
    readonly itemTypeId?: string | null;
    readonly createdAt: Date | string;
    readonly decisionReason?: string | null;
    readonly decisions: ReadonlyArray<
      | {
          readonly __typename: 'AcceptAppealDecisionComponent';
          readonly appealId: string;
          readonly type: GQLManualReviewDecisionType;
        }
      | {
          readonly __typename: 'AutomaticCloseDecisionComponent';
          readonly type: GQLManualReviewDecisionType;
        }
      | {
          readonly __typename: 'CustomerOrRelatedActionDecisionComponent';
          readonly itemTypeId: string;
          readonly itemIds: ReadonlyArray<string>;
          readonly actionIds: ReadonlyArray<string>;
          readonly policyIds: ReadonlyArray<string>;
          readonly type: GQLManualReviewDecisionType;
        }
      | {
          readonly __typename: 'IgnoreDecisionComponent';
          readonly type: GQLManualReviewDecisionType;
        }
      | {
          readonly __typename: 'RejectAppealDecisionComponent';
          readonly appealId: string;
          readonly type: GQLManualReviewDecisionType;
        }
      | {
          readonly __typename: 'SubmitNCMECReportDecisionComponent';
          readonly type: GQLManualReviewDecisionType;
          readonly reportedMedia: ReadonlyArray<{
            readonly __typename: 'NcmecReportedMediaDetails';
            readonly id: string;
            readonly typeId: string;
            readonly url: string;
            readonly fileAnnotations: ReadonlyArray<GQLNcmecFileAnnotation>;
            readonly industryClassification: GQLNcmecIndustryClassification;
          }>;
        }
      | {
          readonly __typename: 'TransformJobAndRecreateInQueueDecisionComponent';
          readonly newQueueId?: string | null;
          readonly originalQueueId?: string | null;
          readonly type: GQLManualReviewDecisionType;
        }
    >;
    readonly relatedActions: ReadonlyArray<
      | {
          readonly __typename: 'AcceptAppealDecisionComponent';
          readonly appealId: string;
          readonly type: GQLManualReviewDecisionType;
        }
      | {
          readonly __typename: 'AutomaticCloseDecisionComponent';
          readonly type: GQLManualReviewDecisionType;
        }
      | {
          readonly __typename: 'CustomerOrRelatedActionDecisionComponent';
          readonly itemTypeId: string;
          readonly itemIds: ReadonlyArray<string>;
          readonly actionIds: ReadonlyArray<string>;
          readonly policyIds: ReadonlyArray<string>;
          readonly type: GQLManualReviewDecisionType;
        }
      | {
          readonly __typename: 'IgnoreDecisionComponent';
          readonly type: GQLManualReviewDecisionType;
        }
      | {
          readonly __typename: 'RejectAppealDecisionComponent';
          readonly appealId: string;
          readonly type: GQLManualReviewDecisionType;
        }
      | {
          readonly __typename: 'SubmitNCMECReportDecisionComponent';
          readonly type: GQLManualReviewDecisionType;
          readonly reportedMedia: ReadonlyArray<{
            readonly __typename: 'NcmecReportedMediaDetails';
            readonly id: string;
            readonly typeId: string;
            readonly url: string;
            readonly fileAnnotations: ReadonlyArray<GQLNcmecFileAnnotation>;
            readonly industryClassification: GQLNcmecIndustryClassification;
          }>;
        }
      | {
          readonly __typename: 'TransformJobAndRecreateInQueueDecisionComponent';
          readonly newQueueId?: string | null;
          readonly originalQueueId?: string | null;
          readonly type: GQLManualReviewDecisionType;
        }
    >;
  }>;
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly id: string;
    readonly actions: ReadonlyArray<
      | {
          readonly __typename: 'CustomerDefinedAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueAuthorToMrtAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueToMrtAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueToNcmecAction';
          readonly id: string;
          readonly name: string;
        }
    >;
    readonly policies: ReadonlyArray<{
      readonly __typename: 'Policy';
      readonly id: string;
      readonly name: string;
    }>;
    readonly users: ReadonlyArray<{
      readonly __typename: 'User';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    }>;
    readonly mrtQueues: ReadonlyArray<{
      readonly __typename: 'ManualReviewQueue';
      readonly id: string;
      readonly name: string;
    }>;
  } | null;
};

export type GQLGetSkipsForRecentDecisionsQueryVariables = Exact<{
  input: GQLRecentDecisionsInput;
}>;

export type GQLGetSkipsForRecentDecisionsQuery = {
  readonly __typename: 'Query';
  readonly getSkipsForRecentDecisions: ReadonlyArray<{
    readonly __typename: 'SkippedJob';
    readonly jobId: string;
    readonly userId: string;
    readonly queueId: string;
    readonly ts: Date | string;
  }>;
};

export type GQLGetDecidedJobQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLGetDecidedJobQuery = {
  readonly __typename: 'Query';
  readonly getDecidedJob?: {
    readonly __typename: 'ManualReviewJob';
    readonly id: string;
    readonly createdAt: Date | string;
    readonly policyIds: ReadonlyArray<string>;
    readonly numTimesReported?: number | null;
    readonly payload:
      | {
          readonly __typename: 'ContentAppealManualReviewJobPayload';
          readonly userScore?: number | null;
          readonly appealReason?: string | null;
          readonly appealId: string;
          readonly actionsTaken: ReadonlyArray<string>;
          readonly item: {
            readonly __typename: 'ContentItem';
            readonly id: string;
            readonly submissionId: string;
            readonly submissionTime?: Date | string | null;
            readonly data: JsonObject;
            readonly type: {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly version: string;
              readonly schemaVariant: GQLItemTypeSchemaVariant;
              readonly hiddenFields: ReadonlyArray<string>;
              readonly schemaFieldRoles: {
                readonly __typename: 'ContentSchemaFieldRoles';
                readonly displayName?: string | null;
                readonly parentId?: string | null;
                readonly threadId?: string | null;
                readonly createdAt?: string | null;
                readonly creatorId?: string | null;
                readonly isDeleted?: string | null;
              };
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly required: boolean;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly type: GQLFieldType;
                readonly name: string;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
                readonly spec: {
                  readonly __typename: 'DerivedFieldSpec';
                  readonly derivationType: GQLDerivedFieldDerivationType;
                  readonly source:
                    | {
                        readonly __typename: 'DerivedFieldFieldSource';
                        readonly name: string;
                        readonly contentTypeId: string;
                      }
                    | {
                        readonly __typename: 'DerivedFieldFullItemSource';
                        readonly _?: boolean | null;
                      }
                    | {
                        readonly __typename: 'DerivedFieldProtegoInputSource';
                        readonly protegoInput: GQLProtegoInput;
                      };
                };
              }>;
            };
          };
          readonly additionalContentItems: ReadonlyArray<{
            readonly __typename: 'ContentItem';
            readonly id: string;
            readonly submissionId: string;
            readonly submissionTime?: Date | string | null;
            readonly data: JsonObject;
            readonly type: {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly version: string;
              readonly schemaVariant: GQLItemTypeSchemaVariant;
              readonly hiddenFields: ReadonlyArray<string>;
              readonly schemaFieldRoles: {
                readonly __typename: 'ContentSchemaFieldRoles';
                readonly displayName?: string | null;
                readonly parentId?: string | null;
                readonly threadId?: string | null;
                readonly createdAt?: string | null;
                readonly creatorId?: string | null;
                readonly isDeleted?: string | null;
              };
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly required: boolean;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly type: GQLFieldType;
                readonly name: string;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
                readonly spec: {
                  readonly __typename: 'DerivedFieldSpec';
                  readonly derivationType: GQLDerivedFieldDerivationType;
                  readonly source:
                    | {
                        readonly __typename: 'DerivedFieldFieldSource';
                        readonly name: string;
                        readonly contentTypeId: string;
                      }
                    | {
                        readonly __typename: 'DerivedFieldFullItemSource';
                        readonly _?: boolean | null;
                      }
                    | {
                        readonly __typename: 'DerivedFieldProtegoInputSource';
                        readonly protegoInput: GQLProtegoInput;
                      };
                };
              }>;
            };
          }>;
          readonly appealerIdentifier?: {
            readonly __typename: 'ItemIdentifier';
            readonly id: string;
            readonly typeId: string;
          } | null;
          readonly enqueueSourceInfo?: {
            readonly __typename: 'AppealEnqueueSourceInfo';
            readonly kind: GQLJobCreationSourceOptions;
          } | null;
        }
      | {
          readonly __typename: 'ContentManualReviewJobPayload';
          readonly userScore?: number | null;
          readonly reportHistory: ReadonlyArray<{
            readonly __typename: 'ReportHistoryEntry';
            readonly policyId?: string | null;
            readonly reportId: string;
            readonly reason?: string | null;
            readonly reportedAt: Date | string;
            readonly reporterId?: {
              readonly __typename: 'ItemIdentifier';
              readonly id: string;
              readonly typeId: string;
            } | null;
          }>;
          readonly item: {
            readonly __typename: 'ContentItem';
            readonly id: string;
            readonly submissionId: string;
            readonly submissionTime?: Date | string | null;
            readonly data: JsonObject;
            readonly type: {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly version: string;
              readonly schemaVariant: GQLItemTypeSchemaVariant;
              readonly hiddenFields: ReadonlyArray<string>;
              readonly schemaFieldRoles: {
                readonly __typename: 'ContentSchemaFieldRoles';
                readonly displayName?: string | null;
                readonly parentId?: string | null;
                readonly threadId?: string | null;
                readonly createdAt?: string | null;
                readonly creatorId?: string | null;
                readonly isDeleted?: string | null;
              };
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly required: boolean;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly type: GQLFieldType;
                readonly name: string;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
                readonly spec: {
                  readonly __typename: 'DerivedFieldSpec';
                  readonly derivationType: GQLDerivedFieldDerivationType;
                  readonly source:
                    | {
                        readonly __typename: 'DerivedFieldFieldSource';
                        readonly name: string;
                        readonly contentTypeId: string;
                      }
                    | {
                        readonly __typename: 'DerivedFieldFullItemSource';
                        readonly _?: boolean | null;
                      }
                    | {
                        readonly __typename: 'DerivedFieldProtegoInputSource';
                        readonly protegoInput: GQLProtegoInput;
                      };
                };
              }>;
            };
          };
          readonly additionalContentItems: ReadonlyArray<{
            readonly __typename: 'ContentItem';
            readonly id: string;
            readonly submissionId: string;
            readonly submissionTime?: Date | string | null;
            readonly data: JsonObject;
            readonly type: {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly version: string;
              readonly schemaVariant: GQLItemTypeSchemaVariant;
              readonly hiddenFields: ReadonlyArray<string>;
              readonly schemaFieldRoles: {
                readonly __typename: 'ContentSchemaFieldRoles';
                readonly displayName?: string | null;
                readonly parentId?: string | null;
                readonly threadId?: string | null;
                readonly createdAt?: string | null;
                readonly creatorId?: string | null;
                readonly isDeleted?: string | null;
              };
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly required: boolean;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly type: GQLFieldType;
                readonly name: string;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
                readonly spec: {
                  readonly __typename: 'DerivedFieldSpec';
                  readonly derivationType: GQLDerivedFieldDerivationType;
                  readonly source:
                    | {
                        readonly __typename: 'DerivedFieldFieldSource';
                        readonly name: string;
                        readonly contentTypeId: string;
                      }
                    | {
                        readonly __typename: 'DerivedFieldFullItemSource';
                        readonly _?: boolean | null;
                      }
                    | {
                        readonly __typename: 'DerivedFieldProtegoInputSource';
                        readonly protegoInput: GQLProtegoInput;
                      };
                };
              }>;
            };
          }>;
          readonly itemThreadContentItems?: ReadonlyArray<{
            readonly __typename: 'ContentItem';
            readonly id: string;
            readonly submissionId: string;
            readonly submissionTime?: Date | string | null;
            readonly data: JsonObject;
            readonly type: {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly version: string;
              readonly schemaVariant: GQLItemTypeSchemaVariant;
              readonly hiddenFields: ReadonlyArray<string>;
              readonly schemaFieldRoles: {
                readonly __typename: 'ContentSchemaFieldRoles';
                readonly displayName?: string | null;
                readonly parentId?: string | null;
                readonly threadId?: string | null;
                readonly createdAt?: string | null;
                readonly creatorId?: string | null;
                readonly isDeleted?: string | null;
              };
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly required: boolean;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly type: GQLFieldType;
                readonly name: string;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
                readonly spec: {
                  readonly __typename: 'DerivedFieldSpec';
                  readonly derivationType: GQLDerivedFieldDerivationType;
                  readonly source:
                    | {
                        readonly __typename: 'DerivedFieldFieldSource';
                        readonly name: string;
                        readonly contentTypeId: string;
                      }
                    | {
                        readonly __typename: 'DerivedFieldFullItemSource';
                        readonly _?: boolean | null;
                      }
                    | {
                        readonly __typename: 'DerivedFieldProtegoInputSource';
                        readonly protegoInput: GQLProtegoInput;
                      };
                };
              }>;
            };
          }> | null;
          readonly reportedForReasons: ReadonlyArray<{
            readonly __typename: 'ReportedForReason';
            readonly reason?: string | null;
            readonly reporterId?: {
              readonly __typename: 'ItemIdentifier';
              readonly id: string;
              readonly typeId: string;
            } | null;
          }>;
          readonly enqueueSourceInfo?:
            | { readonly __typename: 'AppealEnqueueSourceInfo' }
            | {
                readonly __typename: 'MrtJobEnqueueSourceInfo';
                readonly kind: GQLJobCreationSourceOptions;
              }
            | {
                readonly __typename: 'PostActionsEnqueueSourceInfo';
                readonly kind: GQLJobCreationSourceOptions;
              }
            | {
                readonly __typename: 'ReportEnqueueSourceInfo';
                readonly kind: GQLJobCreationSourceOptions;
              }
            | {
                readonly __typename: 'RuleExecutionEnqueueSourceInfo';
                readonly kind: GQLJobCreationSourceOptions;
                readonly rules: ReadonlyArray<
                  | {
                      readonly __typename: 'ContentRule';
                      readonly id: string;
                      readonly name: string;
                    }
                  | {
                      readonly __typename: 'UserRule';
                      readonly id: string;
                      readonly name: string;
                    }
                >;
              }
            | null;
        }
      | {
          readonly __typename: 'NcmecManualReviewJobPayload';
          readonly item: {
            readonly __typename: 'UserItem';
            readonly id: string;
            readonly submissionId: string;
            readonly submissionTime?: Date | string | null;
            readonly data: JsonObject;
            readonly type: {
              readonly __typename: 'UserItemType';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly version: string;
              readonly schemaVariant: GQLItemTypeSchemaVariant;
              readonly hiddenFields: ReadonlyArray<string>;
              readonly schemaFieldRoles: {
                readonly __typename: 'UserSchemaFieldRoles';
                readonly displayName?: string | null;
                readonly createdAt?: string | null;
                readonly profileIcon?: string | null;
                readonly backgroundImage?: string | null;
                readonly isDeleted?: string | null;
              };
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly required: boolean;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly type: GQLFieldType;
                readonly name: string;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
                readonly spec: {
                  readonly __typename: 'DerivedFieldSpec';
                  readonly derivationType: GQLDerivedFieldDerivationType;
                  readonly source:
                    | {
                        readonly __typename: 'DerivedFieldFieldSource';
                        readonly name: string;
                        readonly contentTypeId: string;
                      }
                    | {
                        readonly __typename: 'DerivedFieldFullItemSource';
                        readonly _?: boolean | null;
                      }
                    | {
                        readonly __typename: 'DerivedFieldProtegoInputSource';
                        readonly protegoInput: GQLProtegoInput;
                      };
                };
              }>;
            };
          };
          readonly allMediaItems: ReadonlyArray<{
            readonly __typename: 'NcmecContentItem';
            readonly isConfirmedCSAM: boolean;
            readonly isReported: boolean;
            readonly contentItem:
              | {
                  readonly __typename: 'ContentItem';
                  readonly id: string;
                  readonly submissionId: string;
                  readonly submissionTime?: Date | string | null;
                  readonly data: JsonObject;
                  readonly type: {
                    readonly __typename: 'ContentItemType';
                    readonly id: string;
                    readonly name: string;
                    readonly description?: string | null;
                    readonly version: string;
                    readonly schemaVariant: GQLItemTypeSchemaVariant;
                    readonly hiddenFields: ReadonlyArray<string>;
                    readonly schemaFieldRoles: {
                      readonly __typename: 'ContentSchemaFieldRoles';
                      readonly displayName?: string | null;
                      readonly parentId?: string | null;
                      readonly threadId?: string | null;
                      readonly createdAt?: string | null;
                      readonly creatorId?: string | null;
                      readonly isDeleted?: string | null;
                    };
                    readonly baseFields: ReadonlyArray<{
                      readonly __typename: 'BaseField';
                      readonly name: string;
                      readonly required: boolean;
                      readonly type: GQLFieldType;
                      readonly container?: {
                        readonly __typename: 'Container';
                        readonly containerType: GQLContainerType;
                        readonly keyScalarType?: GQLScalarType | null;
                        readonly valueScalarType: GQLScalarType;
                      } | null;
                    }>;
                    readonly derivedFields: ReadonlyArray<{
                      readonly __typename: 'DerivedField';
                      readonly type: GQLFieldType;
                      readonly name: string;
                      readonly container?: {
                        readonly __typename: 'Container';
                        readonly containerType: GQLContainerType;
                        readonly keyScalarType?: GQLScalarType | null;
                        readonly valueScalarType: GQLScalarType;
                      } | null;
                      readonly spec: {
                        readonly __typename: 'DerivedFieldSpec';
                        readonly derivationType: GQLDerivedFieldDerivationType;
                        readonly source:
                          | {
                              readonly __typename: 'DerivedFieldFieldSource';
                              readonly name: string;
                              readonly contentTypeId: string;
                            }
                          | {
                              readonly __typename: 'DerivedFieldFullItemSource';
                              readonly _?: boolean | null;
                            }
                          | {
                              readonly __typename: 'DerivedFieldProtegoInputSource';
                              readonly protegoInput: GQLProtegoInput;
                            };
                      };
                    }>;
                  };
                }
              | {
                  readonly __typename: 'ThreadItem';
                  readonly id: string;
                  readonly submissionId: string;
                  readonly submissionTime?: Date | string | null;
                  readonly data: JsonObject;
                  readonly type: {
                    readonly __typename: 'ThreadItemType';
                    readonly id: string;
                    readonly name: string;
                    readonly description?: string | null;
                    readonly version: string;
                    readonly schemaVariant: GQLItemTypeSchemaVariant;
                    readonly hiddenFields: ReadonlyArray<string>;
                    readonly schemaFieldRoles: {
                      readonly __typename: 'ThreadSchemaFieldRoles';
                      readonly displayName?: string | null;
                      readonly createdAt?: string | null;
                      readonly creatorId?: string | null;
                      readonly isDeleted?: string | null;
                    };
                    readonly baseFields: ReadonlyArray<{
                      readonly __typename: 'BaseField';
                      readonly name: string;
                      readonly required: boolean;
                      readonly type: GQLFieldType;
                      readonly container?: {
                        readonly __typename: 'Container';
                        readonly containerType: GQLContainerType;
                        readonly keyScalarType?: GQLScalarType | null;
                        readonly valueScalarType: GQLScalarType;
                      } | null;
                    }>;
                    readonly derivedFields: ReadonlyArray<{
                      readonly __typename: 'DerivedField';
                      readonly type: GQLFieldType;
                      readonly name: string;
                      readonly container?: {
                        readonly __typename: 'Container';
                        readonly containerType: GQLContainerType;
                        readonly keyScalarType?: GQLScalarType | null;
                        readonly valueScalarType: GQLScalarType;
                      } | null;
                      readonly spec: {
                        readonly __typename: 'DerivedFieldSpec';
                        readonly derivationType: GQLDerivedFieldDerivationType;
                        readonly source:
                          | {
                              readonly __typename: 'DerivedFieldFieldSource';
                              readonly name: string;
                              readonly contentTypeId: string;
                            }
                          | {
                              readonly __typename: 'DerivedFieldFullItemSource';
                              readonly _?: boolean | null;
                            }
                          | {
                              readonly __typename: 'DerivedFieldProtegoInputSource';
                              readonly protegoInput: GQLProtegoInput;
                            };
                      };
                    }>;
                  };
                }
              | {
                  readonly __typename: 'UserItem';
                  readonly id: string;
                  readonly submissionId: string;
                  readonly submissionTime?: Date | string | null;
                  readonly data: JsonObject;
                  readonly type: {
                    readonly __typename: 'UserItemType';
                    readonly id: string;
                    readonly name: string;
                    readonly description?: string | null;
                    readonly version: string;
                    readonly schemaVariant: GQLItemTypeSchemaVariant;
                    readonly hiddenFields: ReadonlyArray<string>;
                    readonly schemaFieldRoles: {
                      readonly __typename: 'UserSchemaFieldRoles';
                      readonly displayName?: string | null;
                      readonly createdAt?: string | null;
                      readonly profileIcon?: string | null;
                      readonly backgroundImage?: string | null;
                      readonly isDeleted?: string | null;
                    };
                    readonly baseFields: ReadonlyArray<{
                      readonly __typename: 'BaseField';
                      readonly name: string;
                      readonly required: boolean;
                      readonly type: GQLFieldType;
                      readonly container?: {
                        readonly __typename: 'Container';
                        readonly containerType: GQLContainerType;
                        readonly keyScalarType?: GQLScalarType | null;
                        readonly valueScalarType: GQLScalarType;
                      } | null;
                    }>;
                    readonly derivedFields: ReadonlyArray<{
                      readonly __typename: 'DerivedField';
                      readonly type: GQLFieldType;
                      readonly name: string;
                      readonly container?: {
                        readonly __typename: 'Container';
                        readonly containerType: GQLContainerType;
                        readonly keyScalarType?: GQLScalarType | null;
                        readonly valueScalarType: GQLScalarType;
                      } | null;
                      readonly spec: {
                        readonly __typename: 'DerivedFieldSpec';
                        readonly derivationType: GQLDerivedFieldDerivationType;
                        readonly source:
                          | {
                              readonly __typename: 'DerivedFieldFieldSource';
                              readonly name: string;
                              readonly contentTypeId: string;
                            }
                          | {
                              readonly __typename: 'DerivedFieldFullItemSource';
                              readonly _?: boolean | null;
                            }
                          | {
                              readonly __typename: 'DerivedFieldProtegoInputSource';
                              readonly protegoInput: GQLProtegoInput;
                            };
                      };
                    }>;
                  };
                };
          }>;
          readonly enqueueSourceInfo?:
            | { readonly __typename: 'AppealEnqueueSourceInfo' }
            | {
                readonly __typename: 'MrtJobEnqueueSourceInfo';
                readonly kind: GQLJobCreationSourceOptions;
              }
            | {
                readonly __typename: 'PostActionsEnqueueSourceInfo';
                readonly kind: GQLJobCreationSourceOptions;
              }
            | {
                readonly __typename: 'ReportEnqueueSourceInfo';
                readonly kind: GQLJobCreationSourceOptions;
              }
            | {
                readonly __typename: 'RuleExecutionEnqueueSourceInfo';
                readonly kind: GQLJobCreationSourceOptions;
                readonly rules: ReadonlyArray<
                  | {
                      readonly __typename: 'ContentRule';
                      readonly id: string;
                      readonly name: string;
                    }
                  | {
                      readonly __typename: 'UserRule';
                      readonly id: string;
                      readonly name: string;
                    }
                >;
              }
            | null;
        }
      | {
          readonly __typename: 'ThreadAppealManualReviewJobPayload';
          readonly appealId: string;
          readonly appealReason?: string | null;
          readonly actionsTaken: ReadonlyArray<string>;
          readonly item: {
            readonly __typename: 'ThreadItem';
            readonly id: string;
            readonly submissionId: string;
            readonly submissionTime?: Date | string | null;
            readonly data: JsonObject;
            readonly type: {
              readonly __typename: 'ThreadItemType';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly version: string;
              readonly schemaVariant: GQLItemTypeSchemaVariant;
              readonly hiddenFields: ReadonlyArray<string>;
              readonly schemaFieldRoles: {
                readonly __typename: 'ThreadSchemaFieldRoles';
                readonly displayName?: string | null;
                readonly createdAt?: string | null;
                readonly creatorId?: string | null;
                readonly isDeleted?: string | null;
              };
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly required: boolean;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly type: GQLFieldType;
                readonly name: string;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
                readonly spec: {
                  readonly __typename: 'DerivedFieldSpec';
                  readonly derivationType: GQLDerivedFieldDerivationType;
                  readonly source:
                    | {
                        readonly __typename: 'DerivedFieldFieldSource';
                        readonly name: string;
                        readonly contentTypeId: string;
                      }
                    | {
                        readonly __typename: 'DerivedFieldFullItemSource';
                        readonly _?: boolean | null;
                      }
                    | {
                        readonly __typename: 'DerivedFieldProtegoInputSource';
                        readonly protegoInput: GQLProtegoInput;
                      };
                };
              }>;
            };
          };
          readonly appealerIdentifier?: {
            readonly __typename: 'ItemIdentifier';
            readonly id: string;
            readonly typeId: string;
          } | null;
          readonly enqueueSourceInfo?: {
            readonly __typename: 'AppealEnqueueSourceInfo';
            readonly kind: GQLJobCreationSourceOptions;
          } | null;
        }
      | {
          readonly __typename: 'ThreadManualReviewJobPayload';
          readonly reportHistory: ReadonlyArray<{
            readonly __typename: 'ReportHistoryEntry';
            readonly reportId: string;
            readonly policyId?: string | null;
            readonly reason?: string | null;
            readonly reportedAt: Date | string;
            readonly reporterId?: {
              readonly __typename: 'ItemIdentifier';
              readonly id: string;
              readonly typeId: string;
            } | null;
          }>;
          readonly item: {
            readonly __typename: 'ThreadItem';
            readonly id: string;
            readonly submissionId: string;
            readonly submissionTime?: Date | string | null;
            readonly data: JsonObject;
            readonly type: {
              readonly __typename: 'ThreadItemType';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly version: string;
              readonly schemaVariant: GQLItemTypeSchemaVariant;
              readonly hiddenFields: ReadonlyArray<string>;
              readonly schemaFieldRoles: {
                readonly __typename: 'ThreadSchemaFieldRoles';
                readonly displayName?: string | null;
                readonly createdAt?: string | null;
                readonly creatorId?: string | null;
                readonly isDeleted?: string | null;
              };
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly required: boolean;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly type: GQLFieldType;
                readonly name: string;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
                readonly spec: {
                  readonly __typename: 'DerivedFieldSpec';
                  readonly derivationType: GQLDerivedFieldDerivationType;
                  readonly source:
                    | {
                        readonly __typename: 'DerivedFieldFieldSource';
                        readonly name: string;
                        readonly contentTypeId: string;
                      }
                    | {
                        readonly __typename: 'DerivedFieldFullItemSource';
                        readonly _?: boolean | null;
                      }
                    | {
                        readonly __typename: 'DerivedFieldProtegoInputSource';
                        readonly protegoInput: GQLProtegoInput;
                      };
                };
              }>;
            };
          };
          readonly reportedForReasons: ReadonlyArray<{
            readonly __typename: 'ReportedForReason';
            readonly reason?: string | null;
            readonly reporterId?: {
              readonly __typename: 'ItemIdentifier';
              readonly id: string;
              readonly typeId: string;
            } | null;
          }>;
          readonly enqueueSourceInfo?:
            | { readonly __typename: 'AppealEnqueueSourceInfo' }
            | {
                readonly __typename: 'MrtJobEnqueueSourceInfo';
                readonly kind: GQLJobCreationSourceOptions;
              }
            | {
                readonly __typename: 'PostActionsEnqueueSourceInfo';
                readonly kind: GQLJobCreationSourceOptions;
              }
            | {
                readonly __typename: 'ReportEnqueueSourceInfo';
                readonly kind: GQLJobCreationSourceOptions;
              }
            | {
                readonly __typename: 'RuleExecutionEnqueueSourceInfo';
                readonly kind: GQLJobCreationSourceOptions;
                readonly rules: ReadonlyArray<
                  | {
                      readonly __typename: 'ContentRule';
                      readonly id: string;
                      readonly name: string;
                    }
                  | {
                      readonly __typename: 'UserRule';
                      readonly id: string;
                      readonly name: string;
                    }
                >;
              }
            | null;
        }
      | {
          readonly __typename: 'UserAppealManualReviewJobPayload';
          readonly userScore?: number | null;
          readonly appealReason?: string | null;
          readonly appealId: string;
          readonly actionsTaken: ReadonlyArray<string>;
          readonly item: {
            readonly __typename: 'UserItem';
            readonly id: string;
            readonly submissionId: string;
            readonly submissionTime?: Date | string | null;
            readonly data: JsonObject;
            readonly type: {
              readonly __typename: 'UserItemType';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly version: string;
              readonly schemaVariant: GQLItemTypeSchemaVariant;
              readonly hiddenFields: ReadonlyArray<string>;
              readonly schemaFieldRoles: {
                readonly __typename: 'UserSchemaFieldRoles';
                readonly displayName?: string | null;
                readonly createdAt?: string | null;
                readonly profileIcon?: string | null;
                readonly backgroundImage?: string | null;
                readonly isDeleted?: string | null;
              };
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly required: boolean;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly type: GQLFieldType;
                readonly name: string;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
                readonly spec: {
                  readonly __typename: 'DerivedFieldSpec';
                  readonly derivationType: GQLDerivedFieldDerivationType;
                  readonly source:
                    | {
                        readonly __typename: 'DerivedFieldFieldSource';
                        readonly name: string;
                        readonly contentTypeId: string;
                      }
                    | {
                        readonly __typename: 'DerivedFieldFullItemSource';
                        readonly _?: boolean | null;
                      }
                    | {
                        readonly __typename: 'DerivedFieldProtegoInputSource';
                        readonly protegoInput: GQLProtegoInput;
                      };
                };
              }>;
            };
          };
          readonly additionalContentItems: ReadonlyArray<{
            readonly __typename: 'ContentItem';
            readonly id: string;
            readonly submissionId: string;
            readonly submissionTime?: Date | string | null;
            readonly data: JsonObject;
            readonly type: {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly version: string;
              readonly schemaVariant: GQLItemTypeSchemaVariant;
              readonly hiddenFields: ReadonlyArray<string>;
              readonly schemaFieldRoles: {
                readonly __typename: 'ContentSchemaFieldRoles';
                readonly displayName?: string | null;
                readonly parentId?: string | null;
                readonly threadId?: string | null;
                readonly createdAt?: string | null;
                readonly creatorId?: string | null;
                readonly isDeleted?: string | null;
              };
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly required: boolean;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly type: GQLFieldType;
                readonly name: string;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
                readonly spec: {
                  readonly __typename: 'DerivedFieldSpec';
                  readonly derivationType: GQLDerivedFieldDerivationType;
                  readonly source:
                    | {
                        readonly __typename: 'DerivedFieldFieldSource';
                        readonly name: string;
                        readonly contentTypeId: string;
                      }
                    | {
                        readonly __typename: 'DerivedFieldFullItemSource';
                        readonly _?: boolean | null;
                      }
                    | {
                        readonly __typename: 'DerivedFieldProtegoInputSource';
                        readonly protegoInput: GQLProtegoInput;
                      };
                };
              }>;
            };
          }>;
          readonly appealerIdentifier?: {
            readonly __typename: 'ItemIdentifier';
            readonly id: string;
            readonly typeId: string;
          } | null;
          readonly enqueueSourceInfo?: {
            readonly __typename: 'AppealEnqueueSourceInfo';
            readonly kind: GQLJobCreationSourceOptions;
          } | null;
        }
      | {
          readonly __typename: 'UserManualReviewJobPayload';
          readonly userScore?: number | null;
          readonly reportHistory: ReadonlyArray<{
            readonly __typename: 'ReportHistoryEntry';
            readonly reportId: string;
            readonly policyId?: string | null;
            readonly reason?: string | null;
            readonly reportedAt: Date | string;
            readonly reporterId?: {
              readonly __typename: 'ItemIdentifier';
              readonly id: string;
              readonly typeId: string;
            } | null;
          }>;
          readonly item: {
            readonly __typename: 'UserItem';
            readonly id: string;
            readonly submissionId: string;
            readonly submissionTime?: Date | string | null;
            readonly data: JsonObject;
            readonly type: {
              readonly __typename: 'UserItemType';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly version: string;
              readonly schemaVariant: GQLItemTypeSchemaVariant;
              readonly hiddenFields: ReadonlyArray<string>;
              readonly schemaFieldRoles: {
                readonly __typename: 'UserSchemaFieldRoles';
                readonly displayName?: string | null;
                readonly createdAt?: string | null;
                readonly profileIcon?: string | null;
                readonly backgroundImage?: string | null;
                readonly isDeleted?: string | null;
              };
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly required: boolean;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly type: GQLFieldType;
                readonly name: string;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
                readonly spec: {
                  readonly __typename: 'DerivedFieldSpec';
                  readonly derivationType: GQLDerivedFieldDerivationType;
                  readonly source:
                    | {
                        readonly __typename: 'DerivedFieldFieldSource';
                        readonly name: string;
                        readonly contentTypeId: string;
                      }
                    | {
                        readonly __typename: 'DerivedFieldFullItemSource';
                        readonly _?: boolean | null;
                      }
                    | {
                        readonly __typename: 'DerivedFieldProtegoInputSource';
                        readonly protegoInput: GQLProtegoInput;
                      };
                };
              }>;
            };
          };
          readonly itemThreadContentItems?: ReadonlyArray<{
            readonly __typename: 'ContentItem';
            readonly id: string;
            readonly submissionId: string;
            readonly submissionTime?: Date | string | null;
            readonly data: JsonObject;
            readonly type: {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly version: string;
              readonly schemaVariant: GQLItemTypeSchemaVariant;
              readonly hiddenFields: ReadonlyArray<string>;
              readonly schemaFieldRoles: {
                readonly __typename: 'ContentSchemaFieldRoles';
                readonly displayName?: string | null;
                readonly parentId?: string | null;
                readonly threadId?: string | null;
                readonly createdAt?: string | null;
                readonly creatorId?: string | null;
                readonly isDeleted?: string | null;
              };
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly required: boolean;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly type: GQLFieldType;
                readonly name: string;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
                readonly spec: {
                  readonly __typename: 'DerivedFieldSpec';
                  readonly derivationType: GQLDerivedFieldDerivationType;
                  readonly source:
                    | {
                        readonly __typename: 'DerivedFieldFieldSource';
                        readonly name: string;
                        readonly contentTypeId: string;
                      }
                    | {
                        readonly __typename: 'DerivedFieldFullItemSource';
                        readonly _?: boolean | null;
                      }
                    | {
                        readonly __typename: 'DerivedFieldProtegoInputSource';
                        readonly protegoInput: GQLProtegoInput;
                      };
                };
              }>;
            };
          }> | null;
          readonly reportedItems?: ReadonlyArray<{
            readonly __typename: 'ItemIdentifier';
            readonly id: string;
            readonly typeId: string;
          } | null> | null;
          readonly additionalContentItems: ReadonlyArray<{
            readonly __typename: 'ContentItem';
            readonly id: string;
            readonly submissionId: string;
            readonly submissionTime?: Date | string | null;
            readonly data: JsonObject;
            readonly type: {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly version: string;
              readonly schemaVariant: GQLItemTypeSchemaVariant;
              readonly hiddenFields: ReadonlyArray<string>;
              readonly schemaFieldRoles: {
                readonly __typename: 'ContentSchemaFieldRoles';
                readonly displayName?: string | null;
                readonly parentId?: string | null;
                readonly threadId?: string | null;
                readonly createdAt?: string | null;
                readonly creatorId?: string | null;
                readonly isDeleted?: string | null;
              };
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly required: boolean;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly type: GQLFieldType;
                readonly name: string;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
                readonly spec: {
                  readonly __typename: 'DerivedFieldSpec';
                  readonly derivationType: GQLDerivedFieldDerivationType;
                  readonly source:
                    | {
                        readonly __typename: 'DerivedFieldFieldSource';
                        readonly name: string;
                        readonly contentTypeId: string;
                      }
                    | {
                        readonly __typename: 'DerivedFieldFullItemSource';
                        readonly _?: boolean | null;
                      }
                    | {
                        readonly __typename: 'DerivedFieldProtegoInputSource';
                        readonly protegoInput: GQLProtegoInput;
                      };
                };
              }>;
            };
          }>;
          readonly reportedForReasons: ReadonlyArray<{
            readonly __typename: 'ReportedForReason';
            readonly reason?: string | null;
            readonly reporterId?: {
              readonly __typename: 'ItemIdentifier';
              readonly id: string;
              readonly typeId: string;
            } | null;
          }>;
          readonly enqueueSourceInfo?:
            | { readonly __typename: 'AppealEnqueueSourceInfo' }
            | {
                readonly __typename: 'MrtJobEnqueueSourceInfo';
                readonly kind: GQLJobCreationSourceOptions;
              }
            | {
                readonly __typename: 'PostActionsEnqueueSourceInfo';
                readonly kind: GQLJobCreationSourceOptions;
              }
            | {
                readonly __typename: 'ReportEnqueueSourceInfo';
                readonly kind: GQLJobCreationSourceOptions;
              }
            | {
                readonly __typename: 'RuleExecutionEnqueueSourceInfo';
                readonly kind: GQLJobCreationSourceOptions;
                readonly rules: ReadonlyArray<
                  | {
                      readonly __typename: 'ContentRule';
                      readonly id: string;
                      readonly name: string;
                    }
                  | {
                      readonly __typename: 'UserRule';
                      readonly id: string;
                      readonly name: string;
                    }
                >;
              }
            | null;
        };
  } | null;
};

export type GQLManualReviewSafetySettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLManualReviewSafetySettingsQuery = {
  readonly __typename: 'Query';
  readonly me?: {
    readonly __typename: 'User';
    readonly interfacePreferences: {
      readonly __typename: 'UserInterfacePreferences';
      readonly moderatorSafetyMuteVideo: boolean;
      readonly moderatorSafetyGrayscale: boolean;
      readonly moderatorSafetyBlurLevel: number;
    };
  } | null;
};

export type GQLSetModeratorSafetySettingsMutationVariables = Exact<{
  moderatorSafetySettings: GQLModeratorSafetySettingsInput;
}>;

export type GQLSetModeratorSafetySettingsMutation = {
  readonly __typename: 'Mutation';
  readonly setModeratorSafetySettings?: {
    readonly __typename: 'SetModeratorSafetySettingsSuccessResponse';
    readonly _?: boolean | null;
  } | null;
};

export type GQLActionsWithCustomParamsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLActionsWithCustomParamsQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly actions: ReadonlyArray<
      | {
          readonly __typename: 'CustomerDefinedAction';
          readonly id: string;
          readonly name: string;
          readonly customMrtApiParams: ReadonlyArray<{
            readonly __typename: 'CustomMrtApiParamSpec';
            readonly name: string;
            readonly type: string;
            readonly displayName: string;
          } | null>;
        }
      | {
          readonly __typename: 'EnqueueAuthorToMrtAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueToMrtAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueToNcmecAction';
          readonly id: string;
          readonly name: string;
        }
    >;
  } | null;
};

export type GQLJobFieldsFragment = {
  readonly __typename: 'ManualReviewJob';
  readonly id: string;
  readonly createdAt: Date | string;
  readonly policyIds: ReadonlyArray<string>;
  readonly numTimesReported?: number | null;
  readonly payload:
    | {
        readonly __typename: 'ContentAppealManualReviewJobPayload';
        readonly userScore?: number | null;
        readonly appealReason?: string | null;
        readonly appealId: string;
        readonly actionsTaken: ReadonlyArray<string>;
        readonly item: {
          readonly __typename: 'ContentItem';
          readonly id: string;
          readonly submissionId: string;
          readonly submissionTime?: Date | string | null;
          readonly data: JsonObject;
          readonly type: {
            readonly __typename: 'ContentItemType';
            readonly id: string;
            readonly name: string;
            readonly description?: string | null;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly hiddenFields: ReadonlyArray<string>;
            readonly schemaFieldRoles: {
              readonly __typename: 'ContentSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly parentId?: string | null;
              readonly threadId?: string | null;
              readonly createdAt?: string | null;
              readonly creatorId?: string | null;
              readonly isDeleted?: string | null;
            };
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly required: boolean;
              readonly type: GQLFieldType;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly derivedFields: ReadonlyArray<{
              readonly __typename: 'DerivedField';
              readonly type: GQLFieldType;
              readonly name: string;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
              readonly spec: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
                readonly source:
                  | {
                      readonly __typename: 'DerivedFieldFieldSource';
                      readonly name: string;
                      readonly contentTypeId: string;
                    }
                  | {
                      readonly __typename: 'DerivedFieldFullItemSource';
                      readonly _?: boolean | null;
                    }
                  | {
                      readonly __typename: 'DerivedFieldProtegoInputSource';
                      readonly protegoInput: GQLProtegoInput;
                    };
              };
            }>;
          };
        };
        readonly additionalContentItems: ReadonlyArray<{
          readonly __typename: 'ContentItem';
          readonly id: string;
          readonly submissionId: string;
          readonly submissionTime?: Date | string | null;
          readonly data: JsonObject;
          readonly type: {
            readonly __typename: 'ContentItemType';
            readonly id: string;
            readonly name: string;
            readonly description?: string | null;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly hiddenFields: ReadonlyArray<string>;
            readonly schemaFieldRoles: {
              readonly __typename: 'ContentSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly parentId?: string | null;
              readonly threadId?: string | null;
              readonly createdAt?: string | null;
              readonly creatorId?: string | null;
              readonly isDeleted?: string | null;
            };
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly required: boolean;
              readonly type: GQLFieldType;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly derivedFields: ReadonlyArray<{
              readonly __typename: 'DerivedField';
              readonly type: GQLFieldType;
              readonly name: string;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
              readonly spec: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
                readonly source:
                  | {
                      readonly __typename: 'DerivedFieldFieldSource';
                      readonly name: string;
                      readonly contentTypeId: string;
                    }
                  | {
                      readonly __typename: 'DerivedFieldFullItemSource';
                      readonly _?: boolean | null;
                    }
                  | {
                      readonly __typename: 'DerivedFieldProtegoInputSource';
                      readonly protegoInput: GQLProtegoInput;
                    };
              };
            }>;
          };
        }>;
        readonly appealerIdentifier?: {
          readonly __typename: 'ItemIdentifier';
          readonly id: string;
          readonly typeId: string;
        } | null;
        readonly enqueueSourceInfo?: {
          readonly __typename: 'AppealEnqueueSourceInfo';
          readonly kind: GQLJobCreationSourceOptions;
        } | null;
      }
    | {
        readonly __typename: 'ContentManualReviewJobPayload';
        readonly userScore?: number | null;
        readonly reportHistory: ReadonlyArray<{
          readonly __typename: 'ReportHistoryEntry';
          readonly policyId?: string | null;
          readonly reportId: string;
          readonly reason?: string | null;
          readonly reportedAt: Date | string;
          readonly reporterId?: {
            readonly __typename: 'ItemIdentifier';
            readonly id: string;
            readonly typeId: string;
          } | null;
        }>;
        readonly item: {
          readonly __typename: 'ContentItem';
          readonly id: string;
          readonly submissionId: string;
          readonly submissionTime?: Date | string | null;
          readonly data: JsonObject;
          readonly type: {
            readonly __typename: 'ContentItemType';
            readonly id: string;
            readonly name: string;
            readonly description?: string | null;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly hiddenFields: ReadonlyArray<string>;
            readonly schemaFieldRoles: {
              readonly __typename: 'ContentSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly parentId?: string | null;
              readonly threadId?: string | null;
              readonly createdAt?: string | null;
              readonly creatorId?: string | null;
              readonly isDeleted?: string | null;
            };
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly required: boolean;
              readonly type: GQLFieldType;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly derivedFields: ReadonlyArray<{
              readonly __typename: 'DerivedField';
              readonly type: GQLFieldType;
              readonly name: string;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
              readonly spec: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
                readonly source:
                  | {
                      readonly __typename: 'DerivedFieldFieldSource';
                      readonly name: string;
                      readonly contentTypeId: string;
                    }
                  | {
                      readonly __typename: 'DerivedFieldFullItemSource';
                      readonly _?: boolean | null;
                    }
                  | {
                      readonly __typename: 'DerivedFieldProtegoInputSource';
                      readonly protegoInput: GQLProtegoInput;
                    };
              };
            }>;
          };
        };
        readonly additionalContentItems: ReadonlyArray<{
          readonly __typename: 'ContentItem';
          readonly id: string;
          readonly submissionId: string;
          readonly submissionTime?: Date | string | null;
          readonly data: JsonObject;
          readonly type: {
            readonly __typename: 'ContentItemType';
            readonly id: string;
            readonly name: string;
            readonly description?: string | null;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly hiddenFields: ReadonlyArray<string>;
            readonly schemaFieldRoles: {
              readonly __typename: 'ContentSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly parentId?: string | null;
              readonly threadId?: string | null;
              readonly createdAt?: string | null;
              readonly creatorId?: string | null;
              readonly isDeleted?: string | null;
            };
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly required: boolean;
              readonly type: GQLFieldType;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly derivedFields: ReadonlyArray<{
              readonly __typename: 'DerivedField';
              readonly type: GQLFieldType;
              readonly name: string;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
              readonly spec: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
                readonly source:
                  | {
                      readonly __typename: 'DerivedFieldFieldSource';
                      readonly name: string;
                      readonly contentTypeId: string;
                    }
                  | {
                      readonly __typename: 'DerivedFieldFullItemSource';
                      readonly _?: boolean | null;
                    }
                  | {
                      readonly __typename: 'DerivedFieldProtegoInputSource';
                      readonly protegoInput: GQLProtegoInput;
                    };
              };
            }>;
          };
        }>;
        readonly itemThreadContentItems?: ReadonlyArray<{
          readonly __typename: 'ContentItem';
          readonly id: string;
          readonly submissionId: string;
          readonly submissionTime?: Date | string | null;
          readonly data: JsonObject;
          readonly type: {
            readonly __typename: 'ContentItemType';
            readonly id: string;
            readonly name: string;
            readonly description?: string | null;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly hiddenFields: ReadonlyArray<string>;
            readonly schemaFieldRoles: {
              readonly __typename: 'ContentSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly parentId?: string | null;
              readonly threadId?: string | null;
              readonly createdAt?: string | null;
              readonly creatorId?: string | null;
              readonly isDeleted?: string | null;
            };
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly required: boolean;
              readonly type: GQLFieldType;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly derivedFields: ReadonlyArray<{
              readonly __typename: 'DerivedField';
              readonly type: GQLFieldType;
              readonly name: string;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
              readonly spec: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
                readonly source:
                  | {
                      readonly __typename: 'DerivedFieldFieldSource';
                      readonly name: string;
                      readonly contentTypeId: string;
                    }
                  | {
                      readonly __typename: 'DerivedFieldFullItemSource';
                      readonly _?: boolean | null;
                    }
                  | {
                      readonly __typename: 'DerivedFieldProtegoInputSource';
                      readonly protegoInput: GQLProtegoInput;
                    };
              };
            }>;
          };
        }> | null;
        readonly reportedForReasons: ReadonlyArray<{
          readonly __typename: 'ReportedForReason';
          readonly reason?: string | null;
          readonly reporterId?: {
            readonly __typename: 'ItemIdentifier';
            readonly id: string;
            readonly typeId: string;
          } | null;
        }>;
        readonly enqueueSourceInfo?:
          | { readonly __typename: 'AppealEnqueueSourceInfo' }
          | {
              readonly __typename: 'MrtJobEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
            }
          | {
              readonly __typename: 'PostActionsEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
            }
          | {
              readonly __typename: 'ReportEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
            }
          | {
              readonly __typename: 'RuleExecutionEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
              readonly rules: ReadonlyArray<
                | {
                    readonly __typename: 'ContentRule';
                    readonly id: string;
                    readonly name: string;
                  }
                | {
                    readonly __typename: 'UserRule';
                    readonly id: string;
                    readonly name: string;
                  }
              >;
            }
          | null;
      }
    | {
        readonly __typename: 'NcmecManualReviewJobPayload';
        readonly item: {
          readonly __typename: 'UserItem';
          readonly id: string;
          readonly submissionId: string;
          readonly submissionTime?: Date | string | null;
          readonly data: JsonObject;
          readonly type: {
            readonly __typename: 'UserItemType';
            readonly id: string;
            readonly name: string;
            readonly description?: string | null;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly hiddenFields: ReadonlyArray<string>;
            readonly schemaFieldRoles: {
              readonly __typename: 'UserSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly createdAt?: string | null;
              readonly profileIcon?: string | null;
              readonly backgroundImage?: string | null;
              readonly isDeleted?: string | null;
            };
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly required: boolean;
              readonly type: GQLFieldType;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly derivedFields: ReadonlyArray<{
              readonly __typename: 'DerivedField';
              readonly type: GQLFieldType;
              readonly name: string;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
              readonly spec: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
                readonly source:
                  | {
                      readonly __typename: 'DerivedFieldFieldSource';
                      readonly name: string;
                      readonly contentTypeId: string;
                    }
                  | {
                      readonly __typename: 'DerivedFieldFullItemSource';
                      readonly _?: boolean | null;
                    }
                  | {
                      readonly __typename: 'DerivedFieldProtegoInputSource';
                      readonly protegoInput: GQLProtegoInput;
                    };
              };
            }>;
          };
        };
        readonly allMediaItems: ReadonlyArray<{
          readonly __typename: 'NcmecContentItem';
          readonly isConfirmedCSAM: boolean;
          readonly isReported: boolean;
          readonly contentItem:
            | {
                readonly __typename: 'ContentItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'ContentItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'ContentSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly parentId?: string | null;
                    readonly threadId?: string | null;
                    readonly createdAt?: string | null;
                    readonly creatorId?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              }
            | {
                readonly __typename: 'ThreadItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'ThreadItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'ThreadSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly createdAt?: string | null;
                    readonly creatorId?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              }
            | {
                readonly __typename: 'UserItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'UserItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'UserSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly createdAt?: string | null;
                    readonly profileIcon?: string | null;
                    readonly backgroundImage?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              };
        }>;
        readonly enqueueSourceInfo?:
          | { readonly __typename: 'AppealEnqueueSourceInfo' }
          | {
              readonly __typename: 'MrtJobEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
            }
          | {
              readonly __typename: 'PostActionsEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
            }
          | {
              readonly __typename: 'ReportEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
            }
          | {
              readonly __typename: 'RuleExecutionEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
              readonly rules: ReadonlyArray<
                | {
                    readonly __typename: 'ContentRule';
                    readonly id: string;
                    readonly name: string;
                  }
                | {
                    readonly __typename: 'UserRule';
                    readonly id: string;
                    readonly name: string;
                  }
              >;
            }
          | null;
      }
    | {
        readonly __typename: 'ThreadAppealManualReviewJobPayload';
        readonly appealId: string;
        readonly appealReason?: string | null;
        readonly actionsTaken: ReadonlyArray<string>;
        readonly item: {
          readonly __typename: 'ThreadItem';
          readonly id: string;
          readonly submissionId: string;
          readonly submissionTime?: Date | string | null;
          readonly data: JsonObject;
          readonly type: {
            readonly __typename: 'ThreadItemType';
            readonly id: string;
            readonly name: string;
            readonly description?: string | null;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly hiddenFields: ReadonlyArray<string>;
            readonly schemaFieldRoles: {
              readonly __typename: 'ThreadSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly createdAt?: string | null;
              readonly creatorId?: string | null;
              readonly isDeleted?: string | null;
            };
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly required: boolean;
              readonly type: GQLFieldType;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly derivedFields: ReadonlyArray<{
              readonly __typename: 'DerivedField';
              readonly type: GQLFieldType;
              readonly name: string;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
              readonly spec: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
                readonly source:
                  | {
                      readonly __typename: 'DerivedFieldFieldSource';
                      readonly name: string;
                      readonly contentTypeId: string;
                    }
                  | {
                      readonly __typename: 'DerivedFieldFullItemSource';
                      readonly _?: boolean | null;
                    }
                  | {
                      readonly __typename: 'DerivedFieldProtegoInputSource';
                      readonly protegoInput: GQLProtegoInput;
                    };
              };
            }>;
          };
        };
        readonly appealerIdentifier?: {
          readonly __typename: 'ItemIdentifier';
          readonly id: string;
          readonly typeId: string;
        } | null;
        readonly enqueueSourceInfo?: {
          readonly __typename: 'AppealEnqueueSourceInfo';
          readonly kind: GQLJobCreationSourceOptions;
        } | null;
      }
    | {
        readonly __typename: 'ThreadManualReviewJobPayload';
        readonly reportHistory: ReadonlyArray<{
          readonly __typename: 'ReportHistoryEntry';
          readonly reportId: string;
          readonly policyId?: string | null;
          readonly reason?: string | null;
          readonly reportedAt: Date | string;
          readonly reporterId?: {
            readonly __typename: 'ItemIdentifier';
            readonly id: string;
            readonly typeId: string;
          } | null;
        }>;
        readonly item: {
          readonly __typename: 'ThreadItem';
          readonly id: string;
          readonly submissionId: string;
          readonly submissionTime?: Date | string | null;
          readonly data: JsonObject;
          readonly type: {
            readonly __typename: 'ThreadItemType';
            readonly id: string;
            readonly name: string;
            readonly description?: string | null;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly hiddenFields: ReadonlyArray<string>;
            readonly schemaFieldRoles: {
              readonly __typename: 'ThreadSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly createdAt?: string | null;
              readonly creatorId?: string | null;
              readonly isDeleted?: string | null;
            };
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly required: boolean;
              readonly type: GQLFieldType;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly derivedFields: ReadonlyArray<{
              readonly __typename: 'DerivedField';
              readonly type: GQLFieldType;
              readonly name: string;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
              readonly spec: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
                readonly source:
                  | {
                      readonly __typename: 'DerivedFieldFieldSource';
                      readonly name: string;
                      readonly contentTypeId: string;
                    }
                  | {
                      readonly __typename: 'DerivedFieldFullItemSource';
                      readonly _?: boolean | null;
                    }
                  | {
                      readonly __typename: 'DerivedFieldProtegoInputSource';
                      readonly protegoInput: GQLProtegoInput;
                    };
              };
            }>;
          };
        };
        readonly reportedForReasons: ReadonlyArray<{
          readonly __typename: 'ReportedForReason';
          readonly reason?: string | null;
          readonly reporterId?: {
            readonly __typename: 'ItemIdentifier';
            readonly id: string;
            readonly typeId: string;
          } | null;
        }>;
        readonly enqueueSourceInfo?:
          | { readonly __typename: 'AppealEnqueueSourceInfo' }
          | {
              readonly __typename: 'MrtJobEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
            }
          | {
              readonly __typename: 'PostActionsEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
            }
          | {
              readonly __typename: 'ReportEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
            }
          | {
              readonly __typename: 'RuleExecutionEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
              readonly rules: ReadonlyArray<
                | {
                    readonly __typename: 'ContentRule';
                    readonly id: string;
                    readonly name: string;
                  }
                | {
                    readonly __typename: 'UserRule';
                    readonly id: string;
                    readonly name: string;
                  }
              >;
            }
          | null;
      }
    | {
        readonly __typename: 'UserAppealManualReviewJobPayload';
        readonly userScore?: number | null;
        readonly appealReason?: string | null;
        readonly appealId: string;
        readonly actionsTaken: ReadonlyArray<string>;
        readonly item: {
          readonly __typename: 'UserItem';
          readonly id: string;
          readonly submissionId: string;
          readonly submissionTime?: Date | string | null;
          readonly data: JsonObject;
          readonly type: {
            readonly __typename: 'UserItemType';
            readonly id: string;
            readonly name: string;
            readonly description?: string | null;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly hiddenFields: ReadonlyArray<string>;
            readonly schemaFieldRoles: {
              readonly __typename: 'UserSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly createdAt?: string | null;
              readonly profileIcon?: string | null;
              readonly backgroundImage?: string | null;
              readonly isDeleted?: string | null;
            };
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly required: boolean;
              readonly type: GQLFieldType;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly derivedFields: ReadonlyArray<{
              readonly __typename: 'DerivedField';
              readonly type: GQLFieldType;
              readonly name: string;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
              readonly spec: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
                readonly source:
                  | {
                      readonly __typename: 'DerivedFieldFieldSource';
                      readonly name: string;
                      readonly contentTypeId: string;
                    }
                  | {
                      readonly __typename: 'DerivedFieldFullItemSource';
                      readonly _?: boolean | null;
                    }
                  | {
                      readonly __typename: 'DerivedFieldProtegoInputSource';
                      readonly protegoInput: GQLProtegoInput;
                    };
              };
            }>;
          };
        };
        readonly additionalContentItems: ReadonlyArray<{
          readonly __typename: 'ContentItem';
          readonly id: string;
          readonly submissionId: string;
          readonly submissionTime?: Date | string | null;
          readonly data: JsonObject;
          readonly type: {
            readonly __typename: 'ContentItemType';
            readonly id: string;
            readonly name: string;
            readonly description?: string | null;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly hiddenFields: ReadonlyArray<string>;
            readonly schemaFieldRoles: {
              readonly __typename: 'ContentSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly parentId?: string | null;
              readonly threadId?: string | null;
              readonly createdAt?: string | null;
              readonly creatorId?: string | null;
              readonly isDeleted?: string | null;
            };
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly required: boolean;
              readonly type: GQLFieldType;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly derivedFields: ReadonlyArray<{
              readonly __typename: 'DerivedField';
              readonly type: GQLFieldType;
              readonly name: string;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
              readonly spec: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
                readonly source:
                  | {
                      readonly __typename: 'DerivedFieldFieldSource';
                      readonly name: string;
                      readonly contentTypeId: string;
                    }
                  | {
                      readonly __typename: 'DerivedFieldFullItemSource';
                      readonly _?: boolean | null;
                    }
                  | {
                      readonly __typename: 'DerivedFieldProtegoInputSource';
                      readonly protegoInput: GQLProtegoInput;
                    };
              };
            }>;
          };
        }>;
        readonly appealerIdentifier?: {
          readonly __typename: 'ItemIdentifier';
          readonly id: string;
          readonly typeId: string;
        } | null;
        readonly enqueueSourceInfo?: {
          readonly __typename: 'AppealEnqueueSourceInfo';
          readonly kind: GQLJobCreationSourceOptions;
        } | null;
      }
    | {
        readonly __typename: 'UserManualReviewJobPayload';
        readonly userScore?: number | null;
        readonly reportHistory: ReadonlyArray<{
          readonly __typename: 'ReportHistoryEntry';
          readonly reportId: string;
          readonly policyId?: string | null;
          readonly reason?: string | null;
          readonly reportedAt: Date | string;
          readonly reporterId?: {
            readonly __typename: 'ItemIdentifier';
            readonly id: string;
            readonly typeId: string;
          } | null;
        }>;
        readonly item: {
          readonly __typename: 'UserItem';
          readonly id: string;
          readonly submissionId: string;
          readonly submissionTime?: Date | string | null;
          readonly data: JsonObject;
          readonly type: {
            readonly __typename: 'UserItemType';
            readonly id: string;
            readonly name: string;
            readonly description?: string | null;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly hiddenFields: ReadonlyArray<string>;
            readonly schemaFieldRoles: {
              readonly __typename: 'UserSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly createdAt?: string | null;
              readonly profileIcon?: string | null;
              readonly backgroundImage?: string | null;
              readonly isDeleted?: string | null;
            };
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly required: boolean;
              readonly type: GQLFieldType;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly derivedFields: ReadonlyArray<{
              readonly __typename: 'DerivedField';
              readonly type: GQLFieldType;
              readonly name: string;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
              readonly spec: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
                readonly source:
                  | {
                      readonly __typename: 'DerivedFieldFieldSource';
                      readonly name: string;
                      readonly contentTypeId: string;
                    }
                  | {
                      readonly __typename: 'DerivedFieldFullItemSource';
                      readonly _?: boolean | null;
                    }
                  | {
                      readonly __typename: 'DerivedFieldProtegoInputSource';
                      readonly protegoInput: GQLProtegoInput;
                    };
              };
            }>;
          };
        };
        readonly itemThreadContentItems?: ReadonlyArray<{
          readonly __typename: 'ContentItem';
          readonly id: string;
          readonly submissionId: string;
          readonly submissionTime?: Date | string | null;
          readonly data: JsonObject;
          readonly type: {
            readonly __typename: 'ContentItemType';
            readonly id: string;
            readonly name: string;
            readonly description?: string | null;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly hiddenFields: ReadonlyArray<string>;
            readonly schemaFieldRoles: {
              readonly __typename: 'ContentSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly parentId?: string | null;
              readonly threadId?: string | null;
              readonly createdAt?: string | null;
              readonly creatorId?: string | null;
              readonly isDeleted?: string | null;
            };
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly required: boolean;
              readonly type: GQLFieldType;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly derivedFields: ReadonlyArray<{
              readonly __typename: 'DerivedField';
              readonly type: GQLFieldType;
              readonly name: string;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
              readonly spec: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
                readonly source:
                  | {
                      readonly __typename: 'DerivedFieldFieldSource';
                      readonly name: string;
                      readonly contentTypeId: string;
                    }
                  | {
                      readonly __typename: 'DerivedFieldFullItemSource';
                      readonly _?: boolean | null;
                    }
                  | {
                      readonly __typename: 'DerivedFieldProtegoInputSource';
                      readonly protegoInput: GQLProtegoInput;
                    };
              };
            }>;
          };
        }> | null;
        readonly reportedItems?: ReadonlyArray<{
          readonly __typename: 'ItemIdentifier';
          readonly id: string;
          readonly typeId: string;
        } | null> | null;
        readonly additionalContentItems: ReadonlyArray<{
          readonly __typename: 'ContentItem';
          readonly id: string;
          readonly submissionId: string;
          readonly submissionTime?: Date | string | null;
          readonly data: JsonObject;
          readonly type: {
            readonly __typename: 'ContentItemType';
            readonly id: string;
            readonly name: string;
            readonly description?: string | null;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly hiddenFields: ReadonlyArray<string>;
            readonly schemaFieldRoles: {
              readonly __typename: 'ContentSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly parentId?: string | null;
              readonly threadId?: string | null;
              readonly createdAt?: string | null;
              readonly creatorId?: string | null;
              readonly isDeleted?: string | null;
            };
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly required: boolean;
              readonly type: GQLFieldType;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly derivedFields: ReadonlyArray<{
              readonly __typename: 'DerivedField';
              readonly type: GQLFieldType;
              readonly name: string;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
              readonly spec: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
                readonly source:
                  | {
                      readonly __typename: 'DerivedFieldFieldSource';
                      readonly name: string;
                      readonly contentTypeId: string;
                    }
                  | {
                      readonly __typename: 'DerivedFieldFullItemSource';
                      readonly _?: boolean | null;
                    }
                  | {
                      readonly __typename: 'DerivedFieldProtegoInputSource';
                      readonly protegoInput: GQLProtegoInput;
                    };
              };
            }>;
          };
        }>;
        readonly reportedForReasons: ReadonlyArray<{
          readonly __typename: 'ReportedForReason';
          readonly reason?: string | null;
          readonly reporterId?: {
            readonly __typename: 'ItemIdentifier';
            readonly id: string;
            readonly typeId: string;
          } | null;
        }>;
        readonly enqueueSourceInfo?:
          | { readonly __typename: 'AppealEnqueueSourceInfo' }
          | {
              readonly __typename: 'MrtJobEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
            }
          | {
              readonly __typename: 'PostActionsEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
            }
          | {
              readonly __typename: 'ReportEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
            }
          | {
              readonly __typename: 'RuleExecutionEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
              readonly rules: ReadonlyArray<
                | {
                    readonly __typename: 'ContentRule';
                    readonly id: string;
                    readonly name: string;
                  }
                | {
                    readonly __typename: 'UserRule';
                    readonly id: string;
                    readonly name: string;
                  }
              >;
            }
          | null;
      };
};

export type GQLManualReviewJobInfoQueryVariables = Exact<{
  jobIds?: InputMaybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
}>;

export type GQLManualReviewJobInfoQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly id: string;
    readonly hasNCMECReportingEnabled: boolean;
    readonly requiresPolicyForDecisionsInMrt: boolean;
    readonly requiresDecisionReasonInMrt: boolean;
    readonly allowMultiplePoliciesPerAction: boolean;
    readonly hideSkipButtonForNonAdmins: boolean;
    readonly policies: ReadonlyArray<{
      readonly __typename: 'Policy';
      readonly id: string;
      readonly name: string;
      readonly parentId?: string | null;
      readonly policyText?: string | null;
      readonly enforcementGuidelines?: string | null;
    }>;
    readonly itemTypes: ReadonlyArray<
      | {
          readonly __typename: 'ContentItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'ContentSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly parentId?: string | null;
            readonly threadId?: string | null;
            readonly createdAt?: string | null;
            readonly creatorId?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
      | {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'ThreadSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly creatorId?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
      | {
          readonly __typename: 'UserItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'UserSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly profileIcon?: string | null;
            readonly backgroundImage?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
    >;
    readonly actions: ReadonlyArray<
      | {
          readonly __typename: 'CustomerDefinedAction';
          readonly id: string;
          readonly name: string;
          readonly penalty: GQLUserPenaltySeverity;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
          readonly customMrtApiParams: ReadonlyArray<{
            readonly __typename: 'CustomMrtApiParamSpec';
            readonly name: string;
            readonly type: string;
            readonly displayName: string;
          } | null>;
        }
      | {
          readonly __typename: 'EnqueueAuthorToMrtAction';
          readonly id: string;
          readonly name: string;
          readonly penalty: GQLUserPenaltySeverity;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
      | {
          readonly __typename: 'EnqueueToMrtAction';
          readonly id: string;
          readonly name: string;
          readonly penalty: GQLUserPenaltySeverity;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
      | {
          readonly __typename: 'EnqueueToNcmecAction';
          readonly id: string;
          readonly name: string;
          readonly penalty: GQLUserPenaltySeverity;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
    >;
    readonly mrtQueues: ReadonlyArray<{
      readonly __typename: 'ManualReviewQueue';
      readonly id: string;
      readonly name: string;
      readonly isAppealsQueue: boolean;
      readonly autoCloseJobs: boolean;
    }>;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly id: string;
    readonly role?: GQLUserRole | null;
    readonly reviewableQueues: ReadonlyArray<{
      readonly __typename: 'ManualReviewQueue';
      readonly id: string;
      readonly name: string;
      readonly pendingJobCount: number;
      readonly hiddenActionIds: ReadonlyArray<string>;
      readonly jobs: ReadonlyArray<{
        readonly __typename: 'ManualReviewJob';
        readonly id: string;
        readonly createdAt: Date | string;
        readonly policyIds: ReadonlyArray<string>;
        readonly numTimesReported?: number | null;
        readonly payload:
          | {
              readonly __typename: 'ContentAppealManualReviewJobPayload';
              readonly userScore?: number | null;
              readonly appealReason?: string | null;
              readonly appealId: string;
              readonly actionsTaken: ReadonlyArray<string>;
              readonly item: {
                readonly __typename: 'ContentItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'ContentItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'ContentSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly parentId?: string | null;
                    readonly threadId?: string | null;
                    readonly createdAt?: string | null;
                    readonly creatorId?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              };
              readonly additionalContentItems: ReadonlyArray<{
                readonly __typename: 'ContentItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'ContentItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'ContentSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly parentId?: string | null;
                    readonly threadId?: string | null;
                    readonly createdAt?: string | null;
                    readonly creatorId?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              }>;
              readonly appealerIdentifier?: {
                readonly __typename: 'ItemIdentifier';
                readonly id: string;
                readonly typeId: string;
              } | null;
              readonly enqueueSourceInfo?: {
                readonly __typename: 'AppealEnqueueSourceInfo';
                readonly kind: GQLJobCreationSourceOptions;
              } | null;
            }
          | {
              readonly __typename: 'ContentManualReviewJobPayload';
              readonly userScore?: number | null;
              readonly reportHistory: ReadonlyArray<{
                readonly __typename: 'ReportHistoryEntry';
                readonly policyId?: string | null;
                readonly reportId: string;
                readonly reason?: string | null;
                readonly reportedAt: Date | string;
                readonly reporterId?: {
                  readonly __typename: 'ItemIdentifier';
                  readonly id: string;
                  readonly typeId: string;
                } | null;
              }>;
              readonly item: {
                readonly __typename: 'ContentItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'ContentItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'ContentSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly parentId?: string | null;
                    readonly threadId?: string | null;
                    readonly createdAt?: string | null;
                    readonly creatorId?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              };
              readonly additionalContentItems: ReadonlyArray<{
                readonly __typename: 'ContentItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'ContentItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'ContentSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly parentId?: string | null;
                    readonly threadId?: string | null;
                    readonly createdAt?: string | null;
                    readonly creatorId?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              }>;
              readonly itemThreadContentItems?: ReadonlyArray<{
                readonly __typename: 'ContentItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'ContentItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'ContentSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly parentId?: string | null;
                    readonly threadId?: string | null;
                    readonly createdAt?: string | null;
                    readonly creatorId?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              }> | null;
              readonly reportedForReasons: ReadonlyArray<{
                readonly __typename: 'ReportedForReason';
                readonly reason?: string | null;
                readonly reporterId?: {
                  readonly __typename: 'ItemIdentifier';
                  readonly id: string;
                  readonly typeId: string;
                } | null;
              }>;
              readonly enqueueSourceInfo?:
                | { readonly __typename: 'AppealEnqueueSourceInfo' }
                | {
                    readonly __typename: 'MrtJobEnqueueSourceInfo';
                    readonly kind: GQLJobCreationSourceOptions;
                  }
                | {
                    readonly __typename: 'PostActionsEnqueueSourceInfo';
                    readonly kind: GQLJobCreationSourceOptions;
                  }
                | {
                    readonly __typename: 'ReportEnqueueSourceInfo';
                    readonly kind: GQLJobCreationSourceOptions;
                  }
                | {
                    readonly __typename: 'RuleExecutionEnqueueSourceInfo';
                    readonly kind: GQLJobCreationSourceOptions;
                    readonly rules: ReadonlyArray<
                      | {
                          readonly __typename: 'ContentRule';
                          readonly id: string;
                          readonly name: string;
                        }
                      | {
                          readonly __typename: 'UserRule';
                          readonly id: string;
                          readonly name: string;
                        }
                    >;
                  }
                | null;
            }
          | {
              readonly __typename: 'NcmecManualReviewJobPayload';
              readonly item: {
                readonly __typename: 'UserItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'UserItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'UserSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly createdAt?: string | null;
                    readonly profileIcon?: string | null;
                    readonly backgroundImage?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              };
              readonly allMediaItems: ReadonlyArray<{
                readonly __typename: 'NcmecContentItem';
                readonly isConfirmedCSAM: boolean;
                readonly isReported: boolean;
                readonly contentItem:
                  | {
                      readonly __typename: 'ContentItem';
                      readonly id: string;
                      readonly submissionId: string;
                      readonly submissionTime?: Date | string | null;
                      readonly data: JsonObject;
                      readonly type: {
                        readonly __typename: 'ContentItemType';
                        readonly id: string;
                        readonly name: string;
                        readonly description?: string | null;
                        readonly version: string;
                        readonly schemaVariant: GQLItemTypeSchemaVariant;
                        readonly hiddenFields: ReadonlyArray<string>;
                        readonly schemaFieldRoles: {
                          readonly __typename: 'ContentSchemaFieldRoles';
                          readonly displayName?: string | null;
                          readonly parentId?: string | null;
                          readonly threadId?: string | null;
                          readonly createdAt?: string | null;
                          readonly creatorId?: string | null;
                          readonly isDeleted?: string | null;
                        };
                        readonly baseFields: ReadonlyArray<{
                          readonly __typename: 'BaseField';
                          readonly name: string;
                          readonly required: boolean;
                          readonly type: GQLFieldType;
                          readonly container?: {
                            readonly __typename: 'Container';
                            readonly containerType: GQLContainerType;
                            readonly keyScalarType?: GQLScalarType | null;
                            readonly valueScalarType: GQLScalarType;
                          } | null;
                        }>;
                        readonly derivedFields: ReadonlyArray<{
                          readonly __typename: 'DerivedField';
                          readonly type: GQLFieldType;
                          readonly name: string;
                          readonly container?: {
                            readonly __typename: 'Container';
                            readonly containerType: GQLContainerType;
                            readonly keyScalarType?: GQLScalarType | null;
                            readonly valueScalarType: GQLScalarType;
                          } | null;
                          readonly spec: {
                            readonly __typename: 'DerivedFieldSpec';
                            readonly derivationType: GQLDerivedFieldDerivationType;
                            readonly source:
                              | {
                                  readonly __typename: 'DerivedFieldFieldSource';
                                  readonly name: string;
                                  readonly contentTypeId: string;
                                }
                              | {
                                  readonly __typename: 'DerivedFieldFullItemSource';
                                  readonly _?: boolean | null;
                                }
                              | {
                                  readonly __typename: 'DerivedFieldProtegoInputSource';
                                  readonly protegoInput: GQLProtegoInput;
                                };
                          };
                        }>;
                      };
                    }
                  | {
                      readonly __typename: 'ThreadItem';
                      readonly id: string;
                      readonly submissionId: string;
                      readonly submissionTime?: Date | string | null;
                      readonly data: JsonObject;
                      readonly type: {
                        readonly __typename: 'ThreadItemType';
                        readonly id: string;
                        readonly name: string;
                        readonly description?: string | null;
                        readonly version: string;
                        readonly schemaVariant: GQLItemTypeSchemaVariant;
                        readonly hiddenFields: ReadonlyArray<string>;
                        readonly schemaFieldRoles: {
                          readonly __typename: 'ThreadSchemaFieldRoles';
                          readonly displayName?: string | null;
                          readonly createdAt?: string | null;
                          readonly creatorId?: string | null;
                          readonly isDeleted?: string | null;
                        };
                        readonly baseFields: ReadonlyArray<{
                          readonly __typename: 'BaseField';
                          readonly name: string;
                          readonly required: boolean;
                          readonly type: GQLFieldType;
                          readonly container?: {
                            readonly __typename: 'Container';
                            readonly containerType: GQLContainerType;
                            readonly keyScalarType?: GQLScalarType | null;
                            readonly valueScalarType: GQLScalarType;
                          } | null;
                        }>;
                        readonly derivedFields: ReadonlyArray<{
                          readonly __typename: 'DerivedField';
                          readonly type: GQLFieldType;
                          readonly name: string;
                          readonly container?: {
                            readonly __typename: 'Container';
                            readonly containerType: GQLContainerType;
                            readonly keyScalarType?: GQLScalarType | null;
                            readonly valueScalarType: GQLScalarType;
                          } | null;
                          readonly spec: {
                            readonly __typename: 'DerivedFieldSpec';
                            readonly derivationType: GQLDerivedFieldDerivationType;
                            readonly source:
                              | {
                                  readonly __typename: 'DerivedFieldFieldSource';
                                  readonly name: string;
                                  readonly contentTypeId: string;
                                }
                              | {
                                  readonly __typename: 'DerivedFieldFullItemSource';
                                  readonly _?: boolean | null;
                                }
                              | {
                                  readonly __typename: 'DerivedFieldProtegoInputSource';
                                  readonly protegoInput: GQLProtegoInput;
                                };
                          };
                        }>;
                      };
                    }
                  | {
                      readonly __typename: 'UserItem';
                      readonly id: string;
                      readonly submissionId: string;
                      readonly submissionTime?: Date | string | null;
                      readonly data: JsonObject;
                      readonly type: {
                        readonly __typename: 'UserItemType';
                        readonly id: string;
                        readonly name: string;
                        readonly description?: string | null;
                        readonly version: string;
                        readonly schemaVariant: GQLItemTypeSchemaVariant;
                        readonly hiddenFields: ReadonlyArray<string>;
                        readonly schemaFieldRoles: {
                          readonly __typename: 'UserSchemaFieldRoles';
                          readonly displayName?: string | null;
                          readonly createdAt?: string | null;
                          readonly profileIcon?: string | null;
                          readonly backgroundImage?: string | null;
                          readonly isDeleted?: string | null;
                        };
                        readonly baseFields: ReadonlyArray<{
                          readonly __typename: 'BaseField';
                          readonly name: string;
                          readonly required: boolean;
                          readonly type: GQLFieldType;
                          readonly container?: {
                            readonly __typename: 'Container';
                            readonly containerType: GQLContainerType;
                            readonly keyScalarType?: GQLScalarType | null;
                            readonly valueScalarType: GQLScalarType;
                          } | null;
                        }>;
                        readonly derivedFields: ReadonlyArray<{
                          readonly __typename: 'DerivedField';
                          readonly type: GQLFieldType;
                          readonly name: string;
                          readonly container?: {
                            readonly __typename: 'Container';
                            readonly containerType: GQLContainerType;
                            readonly keyScalarType?: GQLScalarType | null;
                            readonly valueScalarType: GQLScalarType;
                          } | null;
                          readonly spec: {
                            readonly __typename: 'DerivedFieldSpec';
                            readonly derivationType: GQLDerivedFieldDerivationType;
                            readonly source:
                              | {
                                  readonly __typename: 'DerivedFieldFieldSource';
                                  readonly name: string;
                                  readonly contentTypeId: string;
                                }
                              | {
                                  readonly __typename: 'DerivedFieldFullItemSource';
                                  readonly _?: boolean | null;
                                }
                              | {
                                  readonly __typename: 'DerivedFieldProtegoInputSource';
                                  readonly protegoInput: GQLProtegoInput;
                                };
                          };
                        }>;
                      };
                    };
              }>;
              readonly enqueueSourceInfo?:
                | { readonly __typename: 'AppealEnqueueSourceInfo' }
                | {
                    readonly __typename: 'MrtJobEnqueueSourceInfo';
                    readonly kind: GQLJobCreationSourceOptions;
                  }
                | {
                    readonly __typename: 'PostActionsEnqueueSourceInfo';
                    readonly kind: GQLJobCreationSourceOptions;
                  }
                | {
                    readonly __typename: 'ReportEnqueueSourceInfo';
                    readonly kind: GQLJobCreationSourceOptions;
                  }
                | {
                    readonly __typename: 'RuleExecutionEnqueueSourceInfo';
                    readonly kind: GQLJobCreationSourceOptions;
                    readonly rules: ReadonlyArray<
                      | {
                          readonly __typename: 'ContentRule';
                          readonly id: string;
                          readonly name: string;
                        }
                      | {
                          readonly __typename: 'UserRule';
                          readonly id: string;
                          readonly name: string;
                        }
                    >;
                  }
                | null;
            }
          | {
              readonly __typename: 'ThreadAppealManualReviewJobPayload';
              readonly appealId: string;
              readonly appealReason?: string | null;
              readonly actionsTaken: ReadonlyArray<string>;
              readonly item: {
                readonly __typename: 'ThreadItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'ThreadItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'ThreadSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly createdAt?: string | null;
                    readonly creatorId?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              };
              readonly appealerIdentifier?: {
                readonly __typename: 'ItemIdentifier';
                readonly id: string;
                readonly typeId: string;
              } | null;
              readonly enqueueSourceInfo?: {
                readonly __typename: 'AppealEnqueueSourceInfo';
                readonly kind: GQLJobCreationSourceOptions;
              } | null;
            }
          | {
              readonly __typename: 'ThreadManualReviewJobPayload';
              readonly reportHistory: ReadonlyArray<{
                readonly __typename: 'ReportHistoryEntry';
                readonly reportId: string;
                readonly policyId?: string | null;
                readonly reason?: string | null;
                readonly reportedAt: Date | string;
                readonly reporterId?: {
                  readonly __typename: 'ItemIdentifier';
                  readonly id: string;
                  readonly typeId: string;
                } | null;
              }>;
              readonly item: {
                readonly __typename: 'ThreadItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'ThreadItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'ThreadSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly createdAt?: string | null;
                    readonly creatorId?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              };
              readonly reportedForReasons: ReadonlyArray<{
                readonly __typename: 'ReportedForReason';
                readonly reason?: string | null;
                readonly reporterId?: {
                  readonly __typename: 'ItemIdentifier';
                  readonly id: string;
                  readonly typeId: string;
                } | null;
              }>;
              readonly enqueueSourceInfo?:
                | { readonly __typename: 'AppealEnqueueSourceInfo' }
                | {
                    readonly __typename: 'MrtJobEnqueueSourceInfo';
                    readonly kind: GQLJobCreationSourceOptions;
                  }
                | {
                    readonly __typename: 'PostActionsEnqueueSourceInfo';
                    readonly kind: GQLJobCreationSourceOptions;
                  }
                | {
                    readonly __typename: 'ReportEnqueueSourceInfo';
                    readonly kind: GQLJobCreationSourceOptions;
                  }
                | {
                    readonly __typename: 'RuleExecutionEnqueueSourceInfo';
                    readonly kind: GQLJobCreationSourceOptions;
                    readonly rules: ReadonlyArray<
                      | {
                          readonly __typename: 'ContentRule';
                          readonly id: string;
                          readonly name: string;
                        }
                      | {
                          readonly __typename: 'UserRule';
                          readonly id: string;
                          readonly name: string;
                        }
                    >;
                  }
                | null;
            }
          | {
              readonly __typename: 'UserAppealManualReviewJobPayload';
              readonly userScore?: number | null;
              readonly appealReason?: string | null;
              readonly appealId: string;
              readonly actionsTaken: ReadonlyArray<string>;
              readonly item: {
                readonly __typename: 'UserItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'UserItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'UserSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly createdAt?: string | null;
                    readonly profileIcon?: string | null;
                    readonly backgroundImage?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              };
              readonly additionalContentItems: ReadonlyArray<{
                readonly __typename: 'ContentItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'ContentItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'ContentSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly parentId?: string | null;
                    readonly threadId?: string | null;
                    readonly createdAt?: string | null;
                    readonly creatorId?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              }>;
              readonly appealerIdentifier?: {
                readonly __typename: 'ItemIdentifier';
                readonly id: string;
                readonly typeId: string;
              } | null;
              readonly enqueueSourceInfo?: {
                readonly __typename: 'AppealEnqueueSourceInfo';
                readonly kind: GQLJobCreationSourceOptions;
              } | null;
            }
          | {
              readonly __typename: 'UserManualReviewJobPayload';
              readonly userScore?: number | null;
              readonly reportHistory: ReadonlyArray<{
                readonly __typename: 'ReportHistoryEntry';
                readonly reportId: string;
                readonly policyId?: string | null;
                readonly reason?: string | null;
                readonly reportedAt: Date | string;
                readonly reporterId?: {
                  readonly __typename: 'ItemIdentifier';
                  readonly id: string;
                  readonly typeId: string;
                } | null;
              }>;
              readonly item: {
                readonly __typename: 'UserItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'UserItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'UserSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly createdAt?: string | null;
                    readonly profileIcon?: string | null;
                    readonly backgroundImage?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              };
              readonly itemThreadContentItems?: ReadonlyArray<{
                readonly __typename: 'ContentItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'ContentItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'ContentSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly parentId?: string | null;
                    readonly threadId?: string | null;
                    readonly createdAt?: string | null;
                    readonly creatorId?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              }> | null;
              readonly reportedItems?: ReadonlyArray<{
                readonly __typename: 'ItemIdentifier';
                readonly id: string;
                readonly typeId: string;
              } | null> | null;
              readonly additionalContentItems: ReadonlyArray<{
                readonly __typename: 'ContentItem';
                readonly id: string;
                readonly submissionId: string;
                readonly submissionTime?: Date | string | null;
                readonly data: JsonObject;
                readonly type: {
                  readonly __typename: 'ContentItemType';
                  readonly id: string;
                  readonly name: string;
                  readonly description?: string | null;
                  readonly version: string;
                  readonly schemaVariant: GQLItemTypeSchemaVariant;
                  readonly hiddenFields: ReadonlyArray<string>;
                  readonly schemaFieldRoles: {
                    readonly __typename: 'ContentSchemaFieldRoles';
                    readonly displayName?: string | null;
                    readonly parentId?: string | null;
                    readonly threadId?: string | null;
                    readonly createdAt?: string | null;
                    readonly creatorId?: string | null;
                    readonly isDeleted?: string | null;
                  };
                  readonly baseFields: ReadonlyArray<{
                    readonly __typename: 'BaseField';
                    readonly name: string;
                    readonly required: boolean;
                    readonly type: GQLFieldType;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                  }>;
                  readonly derivedFields: ReadonlyArray<{
                    readonly __typename: 'DerivedField';
                    readonly type: GQLFieldType;
                    readonly name: string;
                    readonly container?: {
                      readonly __typename: 'Container';
                      readonly containerType: GQLContainerType;
                      readonly keyScalarType?: GQLScalarType | null;
                      readonly valueScalarType: GQLScalarType;
                    } | null;
                    readonly spec: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    };
                  }>;
                };
              }>;
              readonly reportedForReasons: ReadonlyArray<{
                readonly __typename: 'ReportedForReason';
                readonly reason?: string | null;
                readonly reporterId?: {
                  readonly __typename: 'ItemIdentifier';
                  readonly id: string;
                  readonly typeId: string;
                } | null;
              }>;
              readonly enqueueSourceInfo?:
                | { readonly __typename: 'AppealEnqueueSourceInfo' }
                | {
                    readonly __typename: 'MrtJobEnqueueSourceInfo';
                    readonly kind: GQLJobCreationSourceOptions;
                  }
                | {
                    readonly __typename: 'PostActionsEnqueueSourceInfo';
                    readonly kind: GQLJobCreationSourceOptions;
                  }
                | {
                    readonly __typename: 'ReportEnqueueSourceInfo';
                    readonly kind: GQLJobCreationSourceOptions;
                  }
                | {
                    readonly __typename: 'RuleExecutionEnqueueSourceInfo';
                    readonly kind: GQLJobCreationSourceOptions;
                    readonly rules: ReadonlyArray<
                      | {
                          readonly __typename: 'ContentRule';
                          readonly id: string;
                          readonly name: string;
                        }
                      | {
                          readonly __typename: 'UserRule';
                          readonly id: string;
                          readonly name: string;
                        }
                    >;
                  }
                | null;
            };
      }>;
    }>;
  } | null;
};

export type GQLDequeueManualReviewJobMutationVariables = Exact<{
  queueId: Scalars['ID'];
}>;

export type GQLDequeueManualReviewJobMutation = {
  readonly __typename: 'Mutation';
  readonly dequeueManualReviewJob?: {
    readonly __typename: 'DequeueManualReviewJobSuccessResponse';
    readonly lockToken: string;
    readonly numPendingJobs: number;
    readonly job: {
      readonly __typename: 'ManualReviewJob';
      readonly id: string;
      readonly createdAt: Date | string;
      readonly policyIds: ReadonlyArray<string>;
      readonly numTimesReported?: number | null;
      readonly payload:
        | {
            readonly __typename: 'ContentAppealManualReviewJobPayload';
            readonly userScore?: number | null;
            readonly appealReason?: string | null;
            readonly appealId: string;
            readonly actionsTaken: ReadonlyArray<string>;
            readonly item: {
              readonly __typename: 'ContentItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ContentSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly parentId?: string | null;
                  readonly threadId?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            };
            readonly additionalContentItems: ReadonlyArray<{
              readonly __typename: 'ContentItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ContentSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly parentId?: string | null;
                  readonly threadId?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            }>;
            readonly appealerIdentifier?: {
              readonly __typename: 'ItemIdentifier';
              readonly id: string;
              readonly typeId: string;
            } | null;
            readonly enqueueSourceInfo?: {
              readonly __typename: 'AppealEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
            } | null;
          }
        | {
            readonly __typename: 'ContentManualReviewJobPayload';
            readonly userScore?: number | null;
            readonly reportHistory: ReadonlyArray<{
              readonly __typename: 'ReportHistoryEntry';
              readonly policyId?: string | null;
              readonly reportId: string;
              readonly reason?: string | null;
              readonly reportedAt: Date | string;
              readonly reporterId?: {
                readonly __typename: 'ItemIdentifier';
                readonly id: string;
                readonly typeId: string;
              } | null;
            }>;
            readonly item: {
              readonly __typename: 'ContentItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ContentSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly parentId?: string | null;
                  readonly threadId?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            };
            readonly additionalContentItems: ReadonlyArray<{
              readonly __typename: 'ContentItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ContentSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly parentId?: string | null;
                  readonly threadId?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            }>;
            readonly itemThreadContentItems?: ReadonlyArray<{
              readonly __typename: 'ContentItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ContentSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly parentId?: string | null;
                  readonly threadId?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            }> | null;
            readonly reportedForReasons: ReadonlyArray<{
              readonly __typename: 'ReportedForReason';
              readonly reason?: string | null;
              readonly reporterId?: {
                readonly __typename: 'ItemIdentifier';
                readonly id: string;
                readonly typeId: string;
              } | null;
            }>;
            readonly enqueueSourceInfo?:
              | { readonly __typename: 'AppealEnqueueSourceInfo' }
              | {
                  readonly __typename: 'MrtJobEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'PostActionsEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'ReportEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'RuleExecutionEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                  readonly rules: ReadonlyArray<
                    | {
                        readonly __typename: 'ContentRule';
                        readonly id: string;
                        readonly name: string;
                      }
                    | {
                        readonly __typename: 'UserRule';
                        readonly id: string;
                        readonly name: string;
                      }
                  >;
                }
              | null;
          }
        | {
            readonly __typename: 'NcmecManualReviewJobPayload';
            readonly item: {
              readonly __typename: 'UserItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'UserSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly createdAt?: string | null;
                  readonly profileIcon?: string | null;
                  readonly backgroundImage?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            };
            readonly allMediaItems: ReadonlyArray<{
              readonly __typename: 'NcmecContentItem';
              readonly isConfirmedCSAM: boolean;
              readonly isReported: boolean;
              readonly contentItem:
                | {
                    readonly __typename: 'ContentItem';
                    readonly id: string;
                    readonly submissionId: string;
                    readonly submissionTime?: Date | string | null;
                    readonly data: JsonObject;
                    readonly type: {
                      readonly __typename: 'ContentItemType';
                      readonly id: string;
                      readonly name: string;
                      readonly description?: string | null;
                      readonly version: string;
                      readonly schemaVariant: GQLItemTypeSchemaVariant;
                      readonly hiddenFields: ReadonlyArray<string>;
                      readonly schemaFieldRoles: {
                        readonly __typename: 'ContentSchemaFieldRoles';
                        readonly displayName?: string | null;
                        readonly parentId?: string | null;
                        readonly threadId?: string | null;
                        readonly createdAt?: string | null;
                        readonly creatorId?: string | null;
                        readonly isDeleted?: string | null;
                      };
                      readonly baseFields: ReadonlyArray<{
                        readonly __typename: 'BaseField';
                        readonly name: string;
                        readonly required: boolean;
                        readonly type: GQLFieldType;
                        readonly container?: {
                          readonly __typename: 'Container';
                          readonly containerType: GQLContainerType;
                          readonly keyScalarType?: GQLScalarType | null;
                          readonly valueScalarType: GQLScalarType;
                        } | null;
                      }>;
                      readonly derivedFields: ReadonlyArray<{
                        readonly __typename: 'DerivedField';
                        readonly type: GQLFieldType;
                        readonly name: string;
                        readonly container?: {
                          readonly __typename: 'Container';
                          readonly containerType: GQLContainerType;
                          readonly keyScalarType?: GQLScalarType | null;
                          readonly valueScalarType: GQLScalarType;
                        } | null;
                        readonly spec: {
                          readonly __typename: 'DerivedFieldSpec';
                          readonly derivationType: GQLDerivedFieldDerivationType;
                          readonly source:
                            | {
                                readonly __typename: 'DerivedFieldFieldSource';
                                readonly name: string;
                                readonly contentTypeId: string;
                              }
                            | {
                                readonly __typename: 'DerivedFieldFullItemSource';
                                readonly _?: boolean | null;
                              }
                            | {
                                readonly __typename: 'DerivedFieldProtegoInputSource';
                                readonly protegoInput: GQLProtegoInput;
                              };
                        };
                      }>;
                    };
                  }
                | {
                    readonly __typename: 'ThreadItem';
                    readonly id: string;
                    readonly submissionId: string;
                    readonly submissionTime?: Date | string | null;
                    readonly data: JsonObject;
                    readonly type: {
                      readonly __typename: 'ThreadItemType';
                      readonly id: string;
                      readonly name: string;
                      readonly description?: string | null;
                      readonly version: string;
                      readonly schemaVariant: GQLItemTypeSchemaVariant;
                      readonly hiddenFields: ReadonlyArray<string>;
                      readonly schemaFieldRoles: {
                        readonly __typename: 'ThreadSchemaFieldRoles';
                        readonly displayName?: string | null;
                        readonly createdAt?: string | null;
                        readonly creatorId?: string | null;
                        readonly isDeleted?: string | null;
                      };
                      readonly baseFields: ReadonlyArray<{
                        readonly __typename: 'BaseField';
                        readonly name: string;
                        readonly required: boolean;
                        readonly type: GQLFieldType;
                        readonly container?: {
                          readonly __typename: 'Container';
                          readonly containerType: GQLContainerType;
                          readonly keyScalarType?: GQLScalarType | null;
                          readonly valueScalarType: GQLScalarType;
                        } | null;
                      }>;
                      readonly derivedFields: ReadonlyArray<{
                        readonly __typename: 'DerivedField';
                        readonly type: GQLFieldType;
                        readonly name: string;
                        readonly container?: {
                          readonly __typename: 'Container';
                          readonly containerType: GQLContainerType;
                          readonly keyScalarType?: GQLScalarType | null;
                          readonly valueScalarType: GQLScalarType;
                        } | null;
                        readonly spec: {
                          readonly __typename: 'DerivedFieldSpec';
                          readonly derivationType: GQLDerivedFieldDerivationType;
                          readonly source:
                            | {
                                readonly __typename: 'DerivedFieldFieldSource';
                                readonly name: string;
                                readonly contentTypeId: string;
                              }
                            | {
                                readonly __typename: 'DerivedFieldFullItemSource';
                                readonly _?: boolean | null;
                              }
                            | {
                                readonly __typename: 'DerivedFieldProtegoInputSource';
                                readonly protegoInput: GQLProtegoInput;
                              };
                        };
                      }>;
                    };
                  }
                | {
                    readonly __typename: 'UserItem';
                    readonly id: string;
                    readonly submissionId: string;
                    readonly submissionTime?: Date | string | null;
                    readonly data: JsonObject;
                    readonly type: {
                      readonly __typename: 'UserItemType';
                      readonly id: string;
                      readonly name: string;
                      readonly description?: string | null;
                      readonly version: string;
                      readonly schemaVariant: GQLItemTypeSchemaVariant;
                      readonly hiddenFields: ReadonlyArray<string>;
                      readonly schemaFieldRoles: {
                        readonly __typename: 'UserSchemaFieldRoles';
                        readonly displayName?: string | null;
                        readonly createdAt?: string | null;
                        readonly profileIcon?: string | null;
                        readonly backgroundImage?: string | null;
                        readonly isDeleted?: string | null;
                      };
                      readonly baseFields: ReadonlyArray<{
                        readonly __typename: 'BaseField';
                        readonly name: string;
                        readonly required: boolean;
                        readonly type: GQLFieldType;
                        readonly container?: {
                          readonly __typename: 'Container';
                          readonly containerType: GQLContainerType;
                          readonly keyScalarType?: GQLScalarType | null;
                          readonly valueScalarType: GQLScalarType;
                        } | null;
                      }>;
                      readonly derivedFields: ReadonlyArray<{
                        readonly __typename: 'DerivedField';
                        readonly type: GQLFieldType;
                        readonly name: string;
                        readonly container?: {
                          readonly __typename: 'Container';
                          readonly containerType: GQLContainerType;
                          readonly keyScalarType?: GQLScalarType | null;
                          readonly valueScalarType: GQLScalarType;
                        } | null;
                        readonly spec: {
                          readonly __typename: 'DerivedFieldSpec';
                          readonly derivationType: GQLDerivedFieldDerivationType;
                          readonly source:
                            | {
                                readonly __typename: 'DerivedFieldFieldSource';
                                readonly name: string;
                                readonly contentTypeId: string;
                              }
                            | {
                                readonly __typename: 'DerivedFieldFullItemSource';
                                readonly _?: boolean | null;
                              }
                            | {
                                readonly __typename: 'DerivedFieldProtegoInputSource';
                                readonly protegoInput: GQLProtegoInput;
                              };
                        };
                      }>;
                    };
                  };
            }>;
            readonly enqueueSourceInfo?:
              | { readonly __typename: 'AppealEnqueueSourceInfo' }
              | {
                  readonly __typename: 'MrtJobEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'PostActionsEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'ReportEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'RuleExecutionEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                  readonly rules: ReadonlyArray<
                    | {
                        readonly __typename: 'ContentRule';
                        readonly id: string;
                        readonly name: string;
                      }
                    | {
                        readonly __typename: 'UserRule';
                        readonly id: string;
                        readonly name: string;
                      }
                  >;
                }
              | null;
          }
        | {
            readonly __typename: 'ThreadAppealManualReviewJobPayload';
            readonly appealId: string;
            readonly appealReason?: string | null;
            readonly actionsTaken: ReadonlyArray<string>;
            readonly item: {
              readonly __typename: 'ThreadItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ThreadSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            };
            readonly appealerIdentifier?: {
              readonly __typename: 'ItemIdentifier';
              readonly id: string;
              readonly typeId: string;
            } | null;
            readonly enqueueSourceInfo?: {
              readonly __typename: 'AppealEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
            } | null;
          }
        | {
            readonly __typename: 'ThreadManualReviewJobPayload';
            readonly reportHistory: ReadonlyArray<{
              readonly __typename: 'ReportHistoryEntry';
              readonly reportId: string;
              readonly policyId?: string | null;
              readonly reason?: string | null;
              readonly reportedAt: Date | string;
              readonly reporterId?: {
                readonly __typename: 'ItemIdentifier';
                readonly id: string;
                readonly typeId: string;
              } | null;
            }>;
            readonly item: {
              readonly __typename: 'ThreadItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ThreadSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            };
            readonly reportedForReasons: ReadonlyArray<{
              readonly __typename: 'ReportedForReason';
              readonly reason?: string | null;
              readonly reporterId?: {
                readonly __typename: 'ItemIdentifier';
                readonly id: string;
                readonly typeId: string;
              } | null;
            }>;
            readonly enqueueSourceInfo?:
              | { readonly __typename: 'AppealEnqueueSourceInfo' }
              | {
                  readonly __typename: 'MrtJobEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'PostActionsEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'ReportEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'RuleExecutionEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                  readonly rules: ReadonlyArray<
                    | {
                        readonly __typename: 'ContentRule';
                        readonly id: string;
                        readonly name: string;
                      }
                    | {
                        readonly __typename: 'UserRule';
                        readonly id: string;
                        readonly name: string;
                      }
                  >;
                }
              | null;
          }
        | {
            readonly __typename: 'UserAppealManualReviewJobPayload';
            readonly userScore?: number | null;
            readonly appealReason?: string | null;
            readonly appealId: string;
            readonly actionsTaken: ReadonlyArray<string>;
            readonly item: {
              readonly __typename: 'UserItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'UserSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly createdAt?: string | null;
                  readonly profileIcon?: string | null;
                  readonly backgroundImage?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            };
            readonly additionalContentItems: ReadonlyArray<{
              readonly __typename: 'ContentItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ContentSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly parentId?: string | null;
                  readonly threadId?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            }>;
            readonly appealerIdentifier?: {
              readonly __typename: 'ItemIdentifier';
              readonly id: string;
              readonly typeId: string;
            } | null;
            readonly enqueueSourceInfo?: {
              readonly __typename: 'AppealEnqueueSourceInfo';
              readonly kind: GQLJobCreationSourceOptions;
            } | null;
          }
        | {
            readonly __typename: 'UserManualReviewJobPayload';
            readonly userScore?: number | null;
            readonly reportHistory: ReadonlyArray<{
              readonly __typename: 'ReportHistoryEntry';
              readonly reportId: string;
              readonly policyId?: string | null;
              readonly reason?: string | null;
              readonly reportedAt: Date | string;
              readonly reporterId?: {
                readonly __typename: 'ItemIdentifier';
                readonly id: string;
                readonly typeId: string;
              } | null;
            }>;
            readonly item: {
              readonly __typename: 'UserItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'UserSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly createdAt?: string | null;
                  readonly profileIcon?: string | null;
                  readonly backgroundImage?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            };
            readonly itemThreadContentItems?: ReadonlyArray<{
              readonly __typename: 'ContentItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ContentSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly parentId?: string | null;
                  readonly threadId?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            }> | null;
            readonly reportedItems?: ReadonlyArray<{
              readonly __typename: 'ItemIdentifier';
              readonly id: string;
              readonly typeId: string;
            } | null> | null;
            readonly additionalContentItems: ReadonlyArray<{
              readonly __typename: 'ContentItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ContentSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly parentId?: string | null;
                  readonly threadId?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            }>;
            readonly reportedForReasons: ReadonlyArray<{
              readonly __typename: 'ReportedForReason';
              readonly reason?: string | null;
              readonly reporterId?: {
                readonly __typename: 'ItemIdentifier';
                readonly id: string;
                readonly typeId: string;
              } | null;
            }>;
            readonly enqueueSourceInfo?:
              | { readonly __typename: 'AppealEnqueueSourceInfo' }
              | {
                  readonly __typename: 'MrtJobEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'PostActionsEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'ReportEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                }
              | {
                  readonly __typename: 'RuleExecutionEnqueueSourceInfo';
                  readonly kind: GQLJobCreationSourceOptions;
                  readonly rules: ReadonlyArray<
                    | {
                        readonly __typename: 'ContentRule';
                        readonly id: string;
                        readonly name: string;
                      }
                    | {
                        readonly __typename: 'UserRule';
                        readonly id: string;
                        readonly name: string;
                      }
                  >;
                }
              | null;
          };
    };
  } | null;
};

export type GQLSubmitManualReviewDecisionMutationVariables = Exact<{
  input: GQLSubmitDecisionInput;
}>;

export type GQLSubmitManualReviewDecisionMutation = {
  readonly __typename: 'Mutation';
  readonly submitManualReviewDecision:
    | {
        readonly __typename: 'JobHasAlreadyBeenSubmittedError';
        readonly title: string;
        readonly status: number;
        readonly type: ReadonlyArray<string>;
      }
    | {
        readonly __typename: 'NoJobWithIdInQueueError';
        readonly title: string;
        readonly status: number;
        readonly type: ReadonlyArray<string>;
      }
    | {
        readonly __typename: 'RecordingJobDecisionFailedError';
        readonly title: string;
        readonly status: number;
        readonly type: ReadonlyArray<string>;
        readonly detail?: string | null;
      }
    | {
        readonly __typename: 'SubmitDecisionSuccessResponse';
        readonly success: boolean;
      }
    | {
        readonly __typename: 'SubmittedJobActionNotFoundError';
        readonly title: string;
        readonly status: number;
        readonly type: ReadonlyArray<string>;
      };
};

export type GQLLogSkipMutationVariables = Exact<{
  input: GQLLogSkipInput;
}>;

export type GQLLogSkipMutation = {
  readonly __typename: 'Mutation';
  readonly logSkip: boolean;
};

export type GQLGetRelatedItemsQueryVariables = Exact<{
  itemIdentifiers:
    | ReadonlyArray<GQLItemIdentifierInput>
    | GQLItemIdentifierInput;
}>;

export type GQLGetRelatedItemsQuery = {
  readonly __typename: 'Query';
  readonly latestItemSubmissions: ReadonlyArray<
    | {
        readonly __typename: 'ContentItem';
        readonly id: string;
        readonly submissionId: string;
        readonly submissionTime?: Date | string | null;
        readonly data: JsonObject;
        readonly type: {
          readonly __typename: 'ContentItemType';
          readonly id: string;
          readonly name: string;
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly type: GQLFieldType;
            readonly required: boolean;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
        };
      }
    | {
        readonly __typename: 'ThreadItem';
        readonly id: string;
        readonly submissionId: string;
        readonly submissionTime?: Date | string | null;
        readonly data: JsonObject;
        readonly type: {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
          readonly name: string;
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly type: GQLFieldType;
            readonly required: boolean;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
        };
      }
    | {
        readonly __typename: 'UserItem';
        readonly id: string;
        readonly submissionId: string;
        readonly submissionTime?: Date | string | null;
        readonly data: JsonObject;
        readonly type: {
          readonly __typename: 'UserItemType';
          readonly id: string;
          readonly name: string;
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly type: GQLFieldType;
            readonly required: boolean;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly schemaFieldRoles: {
            readonly __typename: 'UserSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly profileIcon?: string | null;
            readonly backgroundImage?: string | null;
          };
        };
      }
  >;
};

export type GQLManualReviewJobCommentFieldsFragment = {
  readonly __typename: 'ManualReviewJobComment';
  readonly id: string;
  readonly createdAt: Date | string;
  readonly commentText: string;
  readonly author: {
    readonly __typename: 'User';
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
  };
};

export type GQLGetCommentsForJobQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;

export type GQLGetCommentsForJobQuery = {
  readonly __typename: 'Query';
  readonly getCommentsForJob: ReadonlyArray<{
    readonly __typename: 'ManualReviewJobComment';
    readonly id: string;
    readonly createdAt: Date | string;
    readonly commentText: string;
    readonly author: {
      readonly __typename: 'User';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    };
  }>;
};

export type GQLAddJobCommentMutationVariables = Exact<{
  input: GQLCreateManualReviewJobCommentInput;
}>;

export type GQLAddJobCommentMutation = {
  readonly __typename: 'Mutation';
  readonly createManualReviewJobComment:
    | {
        readonly __typename: 'AddManualReviewJobCommentSuccessResponse';
        readonly comment: {
          readonly __typename: 'ManualReviewJobComment';
          readonly id: string;
          readonly createdAt: Date | string;
          readonly commentText: string;
          readonly author: {
            readonly __typename: 'User';
            readonly id: string;
            readonly firstName: string;
            readonly lastName: string;
          };
        };
      }
    | { readonly __typename: 'NotFoundError'; readonly title: string };
};

export type GQLDeleteJobCommentMutationVariables = Exact<{
  input: GQLDeleteManualReviewJobCommentInput;
}>;

export type GQLDeleteJobCommentMutation = {
  readonly __typename: 'Mutation';
  readonly deleteManualReviewJobComment: boolean;
};

export type GQLGetThreadHistoryQueryVariables = Exact<{
  threadIdentifier: GQLItemIdentifierInput;
  endDate?: InputMaybe<Scalars['DateTime']>;
}>;

export type GQLGetThreadHistoryQuery = {
  readonly __typename: 'Query';
  readonly threadHistory: ReadonlyArray<{
    readonly __typename: 'ItemSubmissions';
    readonly latest:
      | {
          readonly __typename: 'ContentItem';
          readonly id: string;
          readonly submissionId: string;
          readonly data: JsonObject;
          readonly type: {
            readonly __typename: 'ContentItemType';
            readonly id: string;
            readonly name: string;
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly type: GQLFieldType;
              readonly required: boolean;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly schemaFieldRoles: {
              readonly __typename: 'ContentSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly parentId?: string | null;
              readonly threadId?: string | null;
              readonly createdAt?: string | null;
              readonly creatorId?: string | null;
            };
          };
        }
      | { readonly __typename: 'ThreadItem' }
      | { readonly __typename: 'UserItem' };
  }>;
};

export type GQLItemTypeHiddenFieldsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLItemTypeHiddenFieldsQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly itemTypes: ReadonlyArray<
      | {
          readonly __typename: 'ContentItemType';
          readonly id: string;
          readonly hiddenFields: ReadonlyArray<string>;
        }
      | {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
          readonly hiddenFields: ReadonlyArray<string>;
        }
      | {
          readonly __typename: 'UserItemType';
          readonly id: string;
          readonly hiddenFields: ReadonlyArray<string>;
        }
    >;
  } | null;
};

export type GQLAllManualReviewQueuesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLAllManualReviewQueuesQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly mrtQueues: ReadonlyArray<{
      readonly __typename: 'ManualReviewQueue';
      readonly id: string;
      readonly name: string;
    }>;
  } | null;
};

export type GQLGetLatestUserSubmittedItemsWithThreadsQueryVariables = Exact<{
  userId: GQLItemIdentifierInput;
  reportedMessages:
    | ReadonlyArray<GQLItemIdentifierInput>
    | GQLItemIdentifierInput;
}>;

export type GQLGetLatestUserSubmittedItemsWithThreadsQuery = {
  readonly __typename: 'Query';
  readonly ncmecThreads: ReadonlyArray<{
    readonly __typename: 'ThreadWithMessagesAndIpAddress';
    readonly threadId: string;
    readonly threadTypeId: string;
    readonly messages: ReadonlyArray<{
      readonly __typename: 'MessageWithIpAddress';
      readonly message: {
        readonly __typename: 'ContentItem';
        readonly id: string;
        readonly submissionId: string;
        readonly data: JsonObject;
        readonly type: {
          readonly __typename: 'ContentItemType';
          readonly id: string;
          readonly name: string;
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly type: GQLFieldType;
            readonly required: boolean;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly schemaFieldRoles: {
            readonly __typename: 'ContentSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly parentId?: string | null;
            readonly threadId?: string | null;
            readonly createdAt?: string | null;
            readonly creatorId?: string | null;
          };
        };
      };
      readonly ipAddress: {
        readonly __typename: 'IpAddress';
        readonly ip: string;
        readonly port?: number | null;
      };
    }>;
  }>;
};

export type GQLGetMoreInfoForThreadItemsQueryVariables = Exact<{
  ids: ReadonlyArray<GQLItemIdentifierInput> | GQLItemIdentifierInput;
}>;

export type GQLGetMoreInfoForThreadItemsQuery = {
  readonly __typename: 'Query';
  readonly partialItems:
    | {
        readonly __typename: 'PartialItemsEndpointResponseError';
        readonly title: string;
        readonly status: number;
        readonly type: ReadonlyArray<string>;
      }
    | {
        readonly __typename: 'PartialItemsInvalidResponseError';
        readonly title: string;
        readonly status: number;
        readonly type: ReadonlyArray<string>;
      }
    | {
        readonly __typename: 'PartialItemsMissingEndpointError';
        readonly title: string;
        readonly status: number;
        readonly type: ReadonlyArray<string>;
      }
    | {
        readonly __typename: 'PartialItemsSuccessResponse';
        readonly items: ReadonlyArray<
          | { readonly __typename: 'ContentItem' }
          | {
              readonly __typename: 'ThreadItem';
              readonly id: string;
              readonly submissionId: string;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly type: GQLFieldType;
                  readonly required: boolean;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
              };
            }
          | { readonly __typename: 'UserItem' }
        >;
      };
};

export type GQLGetMoreInfoForPartialItemsQueryVariables = Exact<{
  ids: ReadonlyArray<GQLItemIdentifierInput> | GQLItemIdentifierInput;
}>;

export type GQLGetMoreInfoForPartialItemsQuery = {
  readonly __typename: 'Query';
  readonly partialItems:
    | {
        readonly __typename: 'PartialItemsEndpointResponseError';
        readonly title: string;
        readonly status: number;
        readonly type: ReadonlyArray<string>;
      }
    | {
        readonly __typename: 'PartialItemsInvalidResponseError';
        readonly title: string;
        readonly status: number;
        readonly type: ReadonlyArray<string>;
      }
    | {
        readonly __typename: 'PartialItemsMissingEndpointError';
        readonly title: string;
        readonly status: number;
        readonly type: ReadonlyArray<string>;
      }
    | {
        readonly __typename: 'PartialItemsSuccessResponse';
        readonly items: ReadonlyArray<
          | {
              readonly __typename: 'ContentItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ContentSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly parentId?: string | null;
                  readonly threadId?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            }
          | {
              readonly __typename: 'ThreadItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'ThreadSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly createdAt?: string | null;
                  readonly creatorId?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            }
          | {
              readonly __typename: 'UserItem';
              readonly id: string;
              readonly submissionId: string;
              readonly submissionTime?: Date | string | null;
              readonly data: JsonObject;
              readonly type: {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
                readonly description?: string | null;
                readonly version: string;
                readonly schemaVariant: GQLItemTypeSchemaVariant;
                readonly hiddenFields: ReadonlyArray<string>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'UserSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly createdAt?: string | null;
                  readonly profileIcon?: string | null;
                  readonly backgroundImage?: string | null;
                  readonly isDeleted?: string | null;
                };
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly required: boolean;
                  readonly type: GQLFieldType;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly derivedFields: ReadonlyArray<{
                  readonly __typename: 'DerivedField';
                  readonly type: GQLFieldType;
                  readonly name: string;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                  readonly spec: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  };
                }>;
              };
            }
        >;
      };
};

export type GQLGetExistingJobsForItemQueryVariables = Exact<{
  itemId: Scalars['ID'];
  itemTypeId: Scalars['ID'];
}>;

export type GQLGetExistingJobsForItemQuery = {
  readonly __typename: 'Query';
  readonly getExistingJobsForItem: ReadonlyArray<{
    readonly __typename: 'ManualReviewExistingJob';
    readonly queueId: string;
    readonly job: {
      readonly __typename: 'ManualReviewJob';
      readonly createdAt: Date | string;
    };
  }>;
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly mrtQueues: ReadonlyArray<{
      readonly __typename: 'ManualReviewQueue';
      readonly id: string;
      readonly name: string;
    }>;
  } | null;
};

export type GQLGetLatestUserSubmittedItemsQueryVariables = Exact<{
  itemIdentifier: GQLItemIdentifierInput;
  oldestReturnedSubmissionDate?: InputMaybe<Scalars['DateTime']>;
  earliestReturnedSubmissionDate?: InputMaybe<Scalars['DateTime']>;
}>;

export type GQLGetLatestUserSubmittedItemsQuery = {
  readonly __typename: 'Query';
  readonly latestItemsCreatedBy: ReadonlyArray<{
    readonly __typename: 'ItemSubmissions';
    readonly latest:
      | {
          readonly __typename: 'ContentItem';
          readonly id: string;
          readonly submissionId: string;
          readonly data: JsonObject;
          readonly type: {
            readonly __typename: 'ContentItemType';
            readonly id: string;
            readonly name: string;
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly type: GQLFieldType;
              readonly required: boolean;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly schemaFieldRoles: {
              readonly __typename: 'ContentSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly parentId?: string | null;
              readonly threadId?: string | null;
              readonly createdAt?: string | null;
              readonly creatorId?: string | null;
            };
          };
        }
      | { readonly __typename: 'ThreadItem' }
      | { readonly __typename: 'UserItem' };
  }>;
};

export type GQLOrgDataQueryVariables = Exact<{ [key: string]: never }>;

export type GQLOrgDataQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly id: string;
    readonly name: string;
  } | null;
};

export type GQLGetMoreInfoForItemsQueryVariables = Exact<{
  ids: ReadonlyArray<GQLItemIdentifierInput> | GQLItemIdentifierInput;
}>;

export type GQLGetMoreInfoForItemsQuery = {
  readonly __typename: 'Query';
  readonly partialItems:
    | {
        readonly __typename: 'PartialItemsEndpointResponseError';
        readonly title: string;
        readonly status: number;
        readonly type: ReadonlyArray<string>;
      }
    | {
        readonly __typename: 'PartialItemsInvalidResponseError';
        readonly title: string;
        readonly status: number;
        readonly type: ReadonlyArray<string>;
      }
    | {
        readonly __typename: 'PartialItemsMissingEndpointError';
        readonly title: string;
        readonly status: number;
        readonly type: ReadonlyArray<string>;
      }
    | {
        readonly __typename: 'PartialItemsSuccessResponse';
        readonly items: ReadonlyArray<
          | { readonly __typename: 'ContentItem' }
          | { readonly __typename: 'ThreadItem' }
          | {
              readonly __typename: 'UserItem';
              readonly id: string;
              readonly submissionId: string;
              readonly data: JsonObject;
              readonly userScore: number;
              readonly type: {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly baseFields: ReadonlyArray<{
                  readonly __typename: 'BaseField';
                  readonly name: string;
                  readonly type: GQLFieldType;
                  readonly required: boolean;
                  readonly container?: {
                    readonly __typename: 'Container';
                    readonly containerType: GQLContainerType;
                    readonly keyScalarType?: GQLScalarType | null;
                    readonly valueScalarType: GQLScalarType;
                  } | null;
                }>;
                readonly schemaFieldRoles: {
                  readonly __typename: 'UserSchemaFieldRoles';
                  readonly displayName?: string | null;
                  readonly createdAt?: string | null;
                  readonly profileIcon?: string | null;
                  readonly backgroundImage?: string | null;
                };
              };
            }
        >;
      };
};

export type GQLGetUserItemsQueryVariables = Exact<{
  itemIdentifiers:
    | ReadonlyArray<GQLItemIdentifierInput>
    | GQLItemIdentifierInput;
}>;

export type GQLGetUserItemsQuery = {
  readonly __typename: 'Query';
  readonly latestItemSubmissions: ReadonlyArray<
    | { readonly __typename: 'ContentItem' }
    | { readonly __typename: 'ThreadItem' }
    | {
        readonly __typename: 'UserItem';
        readonly id: string;
        readonly submissionId: string;
        readonly submissionTime?: Date | string | null;
        readonly data: JsonObject;
        readonly type: {
          readonly __typename: 'UserItemType';
          readonly id: string;
          readonly name: string;
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly type: GQLFieldType;
            readonly required: boolean;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly schemaFieldRoles: {
            readonly __typename: 'UserSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly profileIcon?: string | null;
            readonly backgroundImage?: string | null;
          };
        };
      }
  >;
};

export type GQLManualReviewHasAppealsEnabledQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLManualReviewHasAppealsEnabledQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly hasAppealsEnabled: boolean;
  } | null;
};

export type GQLDeleteRoutingRuleMutationVariables = Exact<{
  input: GQLDeleteRoutingRuleInput;
}>;

export type GQLDeleteRoutingRuleMutation = {
  readonly __typename: 'Mutation';
  readonly deleteRoutingRule: boolean;
};

export type GQLManualReviewQueueRoutingRulesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLManualReviewQueueRoutingRulesQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly itemTypes: ReadonlyArray<
      | {
          readonly __typename: 'ContentItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
          readonly schemaFieldRoles: {
            readonly __typename: 'ContentSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly parentId?: string | null;
            readonly threadId?: string | null;
            readonly createdAt?: string | null;
            readonly creatorId?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
        }
      | {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
          readonly schemaFieldRoles: {
            readonly __typename: 'ThreadSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly creatorId?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
        }
      | {
          readonly __typename: 'UserItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
          readonly schemaFieldRoles: {
            readonly __typename: 'UserSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly profileIcon?: string | null;
            readonly backgroundImage?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
        }
    >;
    readonly routingRules: ReadonlyArray<{
      readonly __typename: 'RoutingRule';
      readonly id: string;
      readonly name: string;
      readonly description?: string | null;
      readonly itemTypes: ReadonlyArray<
        | {
            readonly __typename: 'ContentItemType';
            readonly id: string;
            readonly name: string;
            readonly description?: string | null;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly hiddenFields: ReadonlyArray<string>;
            readonly schemaFieldRoles: {
              readonly __typename: 'ContentSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly parentId?: string | null;
              readonly threadId?: string | null;
              readonly createdAt?: string | null;
              readonly creatorId?: string | null;
              readonly isDeleted?: string | null;
            };
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly required: boolean;
              readonly type: GQLFieldType;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly derivedFields: ReadonlyArray<{
              readonly __typename: 'DerivedField';
              readonly type: GQLFieldType;
              readonly name: string;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
              readonly spec: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
                readonly source:
                  | {
                      readonly __typename: 'DerivedFieldFieldSource';
                      readonly name: string;
                      readonly contentTypeId: string;
                    }
                  | {
                      readonly __typename: 'DerivedFieldFullItemSource';
                      readonly _?: boolean | null;
                    }
                  | {
                      readonly __typename: 'DerivedFieldProtegoInputSource';
                      readonly protegoInput: GQLProtegoInput;
                    };
              };
            }>;
          }
        | {
            readonly __typename: 'ThreadItemType';
            readonly id: string;
            readonly name: string;
            readonly description?: string | null;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly hiddenFields: ReadonlyArray<string>;
            readonly schemaFieldRoles: {
              readonly __typename: 'ThreadSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly createdAt?: string | null;
              readonly creatorId?: string | null;
              readonly isDeleted?: string | null;
            };
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly required: boolean;
              readonly type: GQLFieldType;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly derivedFields: ReadonlyArray<{
              readonly __typename: 'DerivedField';
              readonly type: GQLFieldType;
              readonly name: string;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
              readonly spec: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
                readonly source:
                  | {
                      readonly __typename: 'DerivedFieldFieldSource';
                      readonly name: string;
                      readonly contentTypeId: string;
                    }
                  | {
                      readonly __typename: 'DerivedFieldFullItemSource';
                      readonly _?: boolean | null;
                    }
                  | {
                      readonly __typename: 'DerivedFieldProtegoInputSource';
                      readonly protegoInput: GQLProtegoInput;
                    };
              };
            }>;
          }
        | {
            readonly __typename: 'UserItemType';
            readonly id: string;
            readonly name: string;
            readonly description?: string | null;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly hiddenFields: ReadonlyArray<string>;
            readonly schemaFieldRoles: {
              readonly __typename: 'UserSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly createdAt?: string | null;
              readonly profileIcon?: string | null;
              readonly backgroundImage?: string | null;
              readonly isDeleted?: string | null;
            };
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly required: boolean;
              readonly type: GQLFieldType;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly derivedFields: ReadonlyArray<{
              readonly __typename: 'DerivedField';
              readonly type: GQLFieldType;
              readonly name: string;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
              readonly spec: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
                readonly source:
                  | {
                      readonly __typename: 'DerivedFieldFieldSource';
                      readonly name: string;
                      readonly contentTypeId: string;
                    }
                  | {
                      readonly __typename: 'DerivedFieldFullItemSource';
                      readonly _?: boolean | null;
                    }
                  | {
                      readonly __typename: 'DerivedFieldProtegoInputSource';
                      readonly protegoInput: GQLProtegoInput;
                    };
              };
            }>;
          }
      >;
      readonly conditionSet: {
        readonly __typename: 'ConditionSet';
        readonly conjunction: GQLConditionConjunction;
        readonly conditions: ReadonlyArray<
          | {
              readonly __typename: 'ConditionSet';
              readonly conjunction: GQLConditionConjunction;
              readonly conditions: ReadonlyArray<
                | { readonly __typename: 'ConditionSet' }
                | {
                    readonly __typename: 'LeafCondition';
                    readonly comparator?: GQLValueComparator | null;
                    readonly threshold?: string | number | null;
                    readonly input: {
                      readonly __typename: 'ConditionInputField';
                      readonly type: GQLConditionInputInputType;
                      readonly name?: string | null;
                      readonly contentTypeId?: string | null;
                      readonly contentTypeIds?: ReadonlyArray<string> | null;
                      readonly spec?: {
                        readonly __typename: 'DerivedFieldSpec';
                        readonly derivationType: GQLDerivedFieldDerivationType;
                        readonly source:
                          | {
                              readonly __typename: 'DerivedFieldFieldSource';
                              readonly name: string;
                              readonly contentTypeId: string;
                            }
                          | {
                              readonly __typename: 'DerivedFieldFullItemSource';
                              readonly _?: boolean | null;
                            }
                          | {
                              readonly __typename: 'DerivedFieldProtegoInputSource';
                              readonly protegoInput: GQLProtegoInput;
                            };
                      } | null;
                    };
                    readonly signal?: {
                      readonly __typename: 'Signal';
                      readonly id: string;
                      readonly type: GQLSignalType;
                      readonly name: string;
                      readonly subcategory?: string | null;
                      readonly args?:
                        | { readonly __typename: 'AggregationSignalArgs' }
                        | {
                            readonly __typename: 'Gpt4oMiniSignalArgs';
                            readonly policyId: string;
                          }
                        | null;
                    } | null;
                    readonly matchingValues?: {
                      readonly __typename: 'MatchingValues';
                      readonly strings?: ReadonlyArray<string> | null;
                      readonly textBankIds?: ReadonlyArray<string> | null;
                      readonly locationBankIds?: ReadonlyArray<string> | null;
                      readonly locations?: ReadonlyArray<{
                        readonly __typename: 'LocationArea';
                        readonly id: string;
                        readonly name?: string | null;
                        readonly geometry: {
                          readonly __typename: 'LocationGeometry';
                          readonly radius: number;
                          readonly center: {
                            readonly __typename: 'LatLng';
                            readonly lat: number;
                            readonly lng: number;
                          };
                        };
                        readonly bounds?: {
                          readonly __typename: 'PlaceBounds';
                          readonly northeastCorner: {
                            readonly __typename: 'LatLng';
                            readonly lat: number;
                            readonly lng: number;
                          };
                          readonly southwestCorner: {
                            readonly __typename: 'LatLng';
                            readonly lat: number;
                            readonly lng: number;
                          };
                        } | null;
                      }> | null;
                    } | null;
                  }
              >;
            }
          | {
              readonly __typename: 'LeafCondition';
              readonly comparator?: GQLValueComparator | null;
              readonly threshold?: string | number | null;
              readonly input: {
                readonly __typename: 'ConditionInputField';
                readonly type: GQLConditionInputInputType;
                readonly name?: string | null;
                readonly contentTypeId?: string | null;
                readonly contentTypeIds?: ReadonlyArray<string> | null;
                readonly spec?: {
                  readonly __typename: 'DerivedFieldSpec';
                  readonly derivationType: GQLDerivedFieldDerivationType;
                  readonly source:
                    | {
                        readonly __typename: 'DerivedFieldFieldSource';
                        readonly name: string;
                        readonly contentTypeId: string;
                      }
                    | {
                        readonly __typename: 'DerivedFieldFullItemSource';
                        readonly _?: boolean | null;
                      }
                    | {
                        readonly __typename: 'DerivedFieldProtegoInputSource';
                        readonly protegoInput: GQLProtegoInput;
                      };
                } | null;
              };
              readonly signal?: {
                readonly __typename: 'Signal';
                readonly id: string;
                readonly type: GQLSignalType;
                readonly name: string;
                readonly subcategory?: string | null;
                readonly args?:
                  | { readonly __typename: 'AggregationSignalArgs' }
                  | {
                      readonly __typename: 'Gpt4oMiniSignalArgs';
                      readonly policyId: string;
                    }
                  | null;
              } | null;
              readonly matchingValues?: {
                readonly __typename: 'MatchingValues';
                readonly strings?: ReadonlyArray<string> | null;
                readonly textBankIds?: ReadonlyArray<string> | null;
                readonly locationBankIds?: ReadonlyArray<string> | null;
                readonly locations?: ReadonlyArray<{
                  readonly __typename: 'LocationArea';
                  readonly id: string;
                  readonly name?: string | null;
                  readonly geometry: {
                    readonly __typename: 'LocationGeometry';
                    readonly radius: number;
                    readonly center: {
                      readonly __typename: 'LatLng';
                      readonly lat: number;
                      readonly lng: number;
                    };
                  };
                  readonly bounds?: {
                    readonly __typename: 'PlaceBounds';
                    readonly northeastCorner: {
                      readonly __typename: 'LatLng';
                      readonly lat: number;
                      readonly lng: number;
                    };
                    readonly southwestCorner: {
                      readonly __typename: 'LatLng';
                      readonly lat: number;
                      readonly lng: number;
                    };
                  } | null;
                }> | null;
              } | null;
            }
        >;
      };
      readonly destinationQueue: {
        readonly __typename: 'ManualReviewQueue';
        readonly id: string;
        readonly name: string;
      };
    }>;
    readonly appealsRoutingRules: ReadonlyArray<{
      readonly __typename: 'RoutingRule';
      readonly id: string;
      readonly name: string;
      readonly description?: string | null;
      readonly itemTypes: ReadonlyArray<
        | {
            readonly __typename: 'ContentItemType';
            readonly id: string;
            readonly name: string;
            readonly description?: string | null;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly hiddenFields: ReadonlyArray<string>;
            readonly schemaFieldRoles: {
              readonly __typename: 'ContentSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly parentId?: string | null;
              readonly threadId?: string | null;
              readonly createdAt?: string | null;
              readonly creatorId?: string | null;
              readonly isDeleted?: string | null;
            };
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly required: boolean;
              readonly type: GQLFieldType;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly derivedFields: ReadonlyArray<{
              readonly __typename: 'DerivedField';
              readonly type: GQLFieldType;
              readonly name: string;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
              readonly spec: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
                readonly source:
                  | {
                      readonly __typename: 'DerivedFieldFieldSource';
                      readonly name: string;
                      readonly contentTypeId: string;
                    }
                  | {
                      readonly __typename: 'DerivedFieldFullItemSource';
                      readonly _?: boolean | null;
                    }
                  | {
                      readonly __typename: 'DerivedFieldProtegoInputSource';
                      readonly protegoInput: GQLProtegoInput;
                    };
              };
            }>;
          }
        | {
            readonly __typename: 'ThreadItemType';
            readonly id: string;
            readonly name: string;
            readonly description?: string | null;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly hiddenFields: ReadonlyArray<string>;
            readonly schemaFieldRoles: {
              readonly __typename: 'ThreadSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly createdAt?: string | null;
              readonly creatorId?: string | null;
              readonly isDeleted?: string | null;
            };
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly required: boolean;
              readonly type: GQLFieldType;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly derivedFields: ReadonlyArray<{
              readonly __typename: 'DerivedField';
              readonly type: GQLFieldType;
              readonly name: string;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
              readonly spec: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
                readonly source:
                  | {
                      readonly __typename: 'DerivedFieldFieldSource';
                      readonly name: string;
                      readonly contentTypeId: string;
                    }
                  | {
                      readonly __typename: 'DerivedFieldFullItemSource';
                      readonly _?: boolean | null;
                    }
                  | {
                      readonly __typename: 'DerivedFieldProtegoInputSource';
                      readonly protegoInput: GQLProtegoInput;
                    };
              };
            }>;
          }
        | {
            readonly __typename: 'UserItemType';
            readonly id: string;
            readonly name: string;
            readonly description?: string | null;
            readonly version: string;
            readonly schemaVariant: GQLItemTypeSchemaVariant;
            readonly hiddenFields: ReadonlyArray<string>;
            readonly schemaFieldRoles: {
              readonly __typename: 'UserSchemaFieldRoles';
              readonly displayName?: string | null;
              readonly createdAt?: string | null;
              readonly profileIcon?: string | null;
              readonly backgroundImage?: string | null;
              readonly isDeleted?: string | null;
            };
            readonly baseFields: ReadonlyArray<{
              readonly __typename: 'BaseField';
              readonly name: string;
              readonly required: boolean;
              readonly type: GQLFieldType;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
            }>;
            readonly derivedFields: ReadonlyArray<{
              readonly __typename: 'DerivedField';
              readonly type: GQLFieldType;
              readonly name: string;
              readonly container?: {
                readonly __typename: 'Container';
                readonly containerType: GQLContainerType;
                readonly keyScalarType?: GQLScalarType | null;
                readonly valueScalarType: GQLScalarType;
              } | null;
              readonly spec: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
                readonly source:
                  | {
                      readonly __typename: 'DerivedFieldFieldSource';
                      readonly name: string;
                      readonly contentTypeId: string;
                    }
                  | {
                      readonly __typename: 'DerivedFieldFullItemSource';
                      readonly _?: boolean | null;
                    }
                  | {
                      readonly __typename: 'DerivedFieldProtegoInputSource';
                      readonly protegoInput: GQLProtegoInput;
                    };
              };
            }>;
          }
      >;
      readonly conditionSet: {
        readonly __typename: 'ConditionSet';
        readonly conjunction: GQLConditionConjunction;
        readonly conditions: ReadonlyArray<
          | {
              readonly __typename: 'ConditionSet';
              readonly conjunction: GQLConditionConjunction;
              readonly conditions: ReadonlyArray<
                | { readonly __typename: 'ConditionSet' }
                | {
                    readonly __typename: 'LeafCondition';
                    readonly comparator?: GQLValueComparator | null;
                    readonly threshold?: string | number | null;
                    readonly input: {
                      readonly __typename: 'ConditionInputField';
                      readonly type: GQLConditionInputInputType;
                      readonly name?: string | null;
                      readonly contentTypeId?: string | null;
                      readonly contentTypeIds?: ReadonlyArray<string> | null;
                      readonly spec?: {
                        readonly __typename: 'DerivedFieldSpec';
                        readonly derivationType: GQLDerivedFieldDerivationType;
                        readonly source:
                          | {
                              readonly __typename: 'DerivedFieldFieldSource';
                              readonly name: string;
                              readonly contentTypeId: string;
                            }
                          | {
                              readonly __typename: 'DerivedFieldFullItemSource';
                              readonly _?: boolean | null;
                            }
                          | {
                              readonly __typename: 'DerivedFieldProtegoInputSource';
                              readonly protegoInput: GQLProtegoInput;
                            };
                      } | null;
                    };
                    readonly signal?: {
                      readonly __typename: 'Signal';
                      readonly id: string;
                      readonly type: GQLSignalType;
                      readonly name: string;
                      readonly subcategory?: string | null;
                      readonly args?:
                        | { readonly __typename: 'AggregationSignalArgs' }
                        | {
                            readonly __typename: 'Gpt4oMiniSignalArgs';
                            readonly policyId: string;
                          }
                        | null;
                    } | null;
                    readonly matchingValues?: {
                      readonly __typename: 'MatchingValues';
                      readonly strings?: ReadonlyArray<string> | null;
                      readonly textBankIds?: ReadonlyArray<string> | null;
                      readonly locationBankIds?: ReadonlyArray<string> | null;
                      readonly locations?: ReadonlyArray<{
                        readonly __typename: 'LocationArea';
                        readonly id: string;
                        readonly name?: string | null;
                        readonly geometry: {
                          readonly __typename: 'LocationGeometry';
                          readonly radius: number;
                          readonly center: {
                            readonly __typename: 'LatLng';
                            readonly lat: number;
                            readonly lng: number;
                          };
                        };
                        readonly bounds?: {
                          readonly __typename: 'PlaceBounds';
                          readonly northeastCorner: {
                            readonly __typename: 'LatLng';
                            readonly lat: number;
                            readonly lng: number;
                          };
                          readonly southwestCorner: {
                            readonly __typename: 'LatLng';
                            readonly lat: number;
                            readonly lng: number;
                          };
                        } | null;
                      }> | null;
                    } | null;
                  }
              >;
            }
          | {
              readonly __typename: 'LeafCondition';
              readonly comparator?: GQLValueComparator | null;
              readonly threshold?: string | number | null;
              readonly input: {
                readonly __typename: 'ConditionInputField';
                readonly type: GQLConditionInputInputType;
                readonly name?: string | null;
                readonly contentTypeId?: string | null;
                readonly contentTypeIds?: ReadonlyArray<string> | null;
                readonly spec?: {
                  readonly __typename: 'DerivedFieldSpec';
                  readonly derivationType: GQLDerivedFieldDerivationType;
                  readonly source:
                    | {
                        readonly __typename: 'DerivedFieldFieldSource';
                        readonly name: string;
                        readonly contentTypeId: string;
                      }
                    | {
                        readonly __typename: 'DerivedFieldFullItemSource';
                        readonly _?: boolean | null;
                      }
                    | {
                        readonly __typename: 'DerivedFieldProtegoInputSource';
                        readonly protegoInput: GQLProtegoInput;
                      };
                } | null;
              };
              readonly signal?: {
                readonly __typename: 'Signal';
                readonly id: string;
                readonly type: GQLSignalType;
                readonly name: string;
                readonly subcategory?: string | null;
                readonly args?:
                  | { readonly __typename: 'AggregationSignalArgs' }
                  | {
                      readonly __typename: 'Gpt4oMiniSignalArgs';
                      readonly policyId: string;
                    }
                  | null;
              } | null;
              readonly matchingValues?: {
                readonly __typename: 'MatchingValues';
                readonly strings?: ReadonlyArray<string> | null;
                readonly textBankIds?: ReadonlyArray<string> | null;
                readonly locationBankIds?: ReadonlyArray<string> | null;
                readonly locations?: ReadonlyArray<{
                  readonly __typename: 'LocationArea';
                  readonly id: string;
                  readonly name?: string | null;
                  readonly geometry: {
                    readonly __typename: 'LocationGeometry';
                    readonly radius: number;
                    readonly center: {
                      readonly __typename: 'LatLng';
                      readonly lat: number;
                      readonly lng: number;
                    };
                  };
                  readonly bounds?: {
                    readonly __typename: 'PlaceBounds';
                    readonly northeastCorner: {
                      readonly __typename: 'LatLng';
                      readonly lat: number;
                      readonly lng: number;
                    };
                    readonly southwestCorner: {
                      readonly __typename: 'LatLng';
                      readonly lat: number;
                      readonly lng: number;
                    };
                  } | null;
                }> | null;
              } | null;
            }
        >;
      };
      readonly destinationQueue: {
        readonly __typename: 'ManualReviewQueue';
        readonly id: string;
        readonly name: string;
      };
    }>;
    readonly signals: ReadonlyArray<{
      readonly __typename: 'Signal';
      readonly id: string;
      readonly type: GQLSignalType;
      readonly name: string;
      readonly integration?: GQLIntegration | null;
      readonly docsUrl?: string | null;
      readonly description: string;
      readonly eligibleInputs: ReadonlyArray<GQLSignalInputType>;
      readonly shouldPromptForMatchingValues: boolean;
      readonly recommendedThresholds?: {
        readonly __typename: 'RecommendedThresholds';
        readonly highPrecisionThreshold: string | number;
        readonly highRecallThreshold: string | number;
      } | null;
      readonly supportedLanguages:
        | { readonly __typename: 'AllLanguages'; readonly _?: boolean | null }
        | {
            readonly __typename: 'Languages';
            readonly languages: ReadonlyArray<GQLLanguage>;
          };
      readonly pricingStructure: {
        readonly __typename: 'SignalPricingStructure';
        readonly type: GQLSignalPricingStructureType;
      };
      readonly disabledInfo: {
        readonly __typename: 'DisabledInfo';
        readonly disabled: boolean;
        readonly disabledMessage?: string | null;
      };
      readonly outputType:
        | {
            readonly __typename: 'EnumSignalOutputType';
            readonly scalarType: GQLScalarType;
            readonly enum: ReadonlyArray<string>;
            readonly ordered: boolean;
          }
        | {
            readonly __typename: 'ScalarSignalOutputType';
            readonly scalarType: GQLScalarType;
          };
      readonly eligibleSubcategories: ReadonlyArray<{
        readonly __typename: 'SignalSubcategory';
        readonly id: string;
        readonly label: string;
        readonly description?: string | null;
        readonly childrenIds: ReadonlyArray<string>;
      }>;
    }>;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly id: string;
    readonly reviewableQueues: ReadonlyArray<{
      readonly __typename: 'ManualReviewQueue';
      readonly id: string;
      readonly name: string;
      readonly isAppealsQueue: boolean;
    }>;
  } | null;
};

export type GQLCreateRoutingRuleMutationVariables = Exact<{
  input: GQLCreateRoutingRuleInput;
}>;

export type GQLCreateRoutingRuleMutation = {
  readonly __typename: 'Mutation';
  readonly createRoutingRule:
    | {
        readonly __typename: 'MutateRoutingRuleSuccessResponse';
        readonly data: {
          readonly __typename: 'RoutingRule';
          readonly id: string;
        };
      }
    | { readonly __typename: 'QueueDoesNotExistError'; readonly title: string }
    | {
        readonly __typename: 'RoutingRuleNameExistsError';
        readonly title: string;
      };
};

export type GQLUpdateRoutingRuleMutationVariables = Exact<{
  input: GQLUpdateRoutingRuleInput;
}>;

export type GQLUpdateRoutingRuleMutation = {
  readonly __typename: 'Mutation';
  readonly updateRoutingRule:
    | {
        readonly __typename: 'MutateRoutingRuleSuccessResponse';
        readonly data: {
          readonly __typename: 'RoutingRule';
          readonly id: string;
        };
      }
    | { readonly __typename: 'NotFoundError'; readonly title: string }
    | { readonly __typename: 'QueueDoesNotExistError'; readonly title: string }
    | {
        readonly __typename: 'RoutingRuleNameExistsError';
        readonly title: string;
      };
};

export type GQLReorderRoutingRulesMutationVariables = Exact<{
  input: GQLReorderRoutingRulesInput;
}>;

export type GQLReorderRoutingRulesMutation = {
  readonly __typename: 'Mutation';
  readonly reorderRoutingRules: {
    readonly __typename: 'MutateRoutingRulesOrderSuccessResponse';
    readonly data: ReadonlyArray<{
      readonly __typename: 'RoutingRule';
      readonly id: string;
    }>;
  };
};

export type GQLManualReviewChartConfigurationSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLManualReviewChartConfigurationSettingsQuery = {
  readonly __typename: 'Query';
  readonly me?: {
    readonly __typename: 'User';
    readonly interfacePreferences: {
      readonly __typename: 'UserInterfacePreferences';
      readonly mrtChartConfigurations: ReadonlyArray<
        | {
            readonly __typename: 'GetDecisionCountSettings';
            readonly title: string;
            readonly metric: GQLManualReviewChartMetric;
            readonly timeDivision: GQLMetricsTimeDivisionOptions;
            readonly decisionGroupBy: ReadonlyArray<GQLDecisionCountGroupByColumns>;
            readonly filterBy: {
              readonly __typename: 'DecisionCountFilterBy';
              readonly startDate: Date | string;
              readonly endDate: Date | string;
              readonly type: ReadonlyArray<GQLManualReviewDecisionType>;
              readonly actionIds: ReadonlyArray<string>;
              readonly policyIds: ReadonlyArray<string>;
              readonly queueIds: ReadonlyArray<string>;
              readonly reviewerIds: ReadonlyArray<string>;
              readonly itemTypeIds: ReadonlyArray<string>;
            };
          }
        | {
            readonly __typename: 'GetJobCreationCountSettings';
            readonly title: string;
            readonly metric: GQLManualReviewChartMetric;
            readonly timeDivision: GQLMetricsTimeDivisionOptions;
            readonly jobCreationGroupBy: ReadonlyArray<GQLJobCreationGroupByColumns>;
            readonly filterBy: {
              readonly __typename: 'JobCreationFilterBy';
              readonly startDate: Date | string;
              readonly endDate: Date | string;
              readonly policyIds: ReadonlyArray<string>;
              readonly queueIds: ReadonlyArray<string>;
              readonly itemTypeIds: ReadonlyArray<string>;
              readonly ruleIds: ReadonlyArray<string>;
              readonly sources: ReadonlyArray<GQLJobCreationSourceOptions>;
            };
          }
      >;
    };
  } | null;
};

export type GQLSetMrtChartConfigurationSettingsMutationVariables = Exact<{
  mrtChartConfigurationSettings: GQLManualReviewChartConfigurationsInput;
}>;

export type GQLSetMrtChartConfigurationSettingsMutation = {
  readonly __typename: 'Mutation';
  readonly setMrtChartConfigurationSettings?: {
    readonly __typename: 'SetMrtChartConfigurationSettingsSuccessResponse';
    readonly _?: boolean | null;
  } | null;
};

export type GQLManualReviewDecisionInsightsOrgInfoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLManualReviewDecisionInsightsOrgInfoQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly id: string;
    readonly actions: ReadonlyArray<
      | {
          readonly __typename: 'CustomerDefinedAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueAuthorToMrtAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueToMrtAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueToNcmecAction';
          readonly id: string;
          readonly name: string;
        }
    >;
    readonly users: ReadonlyArray<{
      readonly __typename: 'User';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    }>;
    readonly policies: ReadonlyArray<{
      readonly __typename: 'Policy';
      readonly id: string;
      readonly name: string;
    }>;
    readonly mrtQueues: ReadonlyArray<{
      readonly __typename: 'ManualReviewQueue';
      readonly id: string;
      readonly name: string;
    }>;
    readonly itemTypes: ReadonlyArray<
      | {
          readonly __typename: 'ContentItemType';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'UserItemType';
          readonly id: string;
          readonly name: string;
        }
    >;
    readonly rules: ReadonlyArray<
      | {
          readonly __typename: 'ContentRule';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'UserRule';
          readonly id: string;
          readonly name: string;
        }
    >;
  } | null;
};

export type GQLGetDecisionCountsQueryVariables = Exact<{
  input: GQLGetDecisionCountInput;
}>;

export type GQLGetDecisionCountsQuery = {
  readonly __typename: 'Query';
  readonly getDecisionCounts: ReadonlyArray<{
    readonly __typename: 'DecisionCount';
    readonly count: number;
    readonly time: string;
    readonly type?: GQLManualReviewDecisionType | null;
    readonly action_id?: string | null;
    readonly policy_id?: string | null;
    readonly queue_id?: string | null;
    readonly reviewer_id?: string | null;
  }>;
};

export type GQLGetJobCreationCountsQueryVariables = Exact<{
  input: GQLGetJobCreationCountInput;
}>;

export type GQLGetJobCreationCountsQuery = {
  readonly __typename: 'Query';
  readonly getJobCreationCounts: ReadonlyArray<{
    readonly __typename: 'JobCreationCount';
    readonly count: number;
    readonly time: string;
    readonly policyId?: string | null;
    readonly queueId?: string | null;
    readonly itemTypeId?: string | null;
    readonly ruleId?: string | null;
    readonly source?: GQLJobCreationSourceOptions | null;
  }>;
};

export type GQLGetResolvedJobCountsQueryVariables = Exact<{
  input: GQLGetResolvedJobCountInput;
}>;

export type GQLGetResolvedJobCountsQuery = {
  readonly __typename: 'Query';
  readonly getResolvedJobCounts: ReadonlyArray<{
    readonly __typename: 'ResolvedJobCount';
    readonly count: number;
    readonly time: string;
    readonly reviewerId?: string | null;
    readonly queueId?: string | null;
  }>;
};

export type GQLGetSkippedJobCountsQueryVariables = Exact<{
  input: GQLGetSkippedJobCountInput;
}>;

export type GQLGetSkippedJobCountsQuery = {
  readonly __typename: 'Query';
  readonly getSkippedJobCounts: ReadonlyArray<{
    readonly __typename: 'SkippedJobCount';
    readonly count: number;
    readonly time: string;
    readonly reviewerId?: string | null;
    readonly queueId?: string | null;
  }>;
};

export type GQLManualReviewDecisionInsightsFilterByInfoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLManualReviewDecisionInsightsFilterByInfoQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly actions: ReadonlyArray<
      | {
          readonly __typename: 'CustomerDefinedAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueAuthorToMrtAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueToMrtAction';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'EnqueueToNcmecAction';
          readonly id: string;
          readonly name: string;
        }
    >;
    readonly itemTypes: ReadonlyArray<
      | {
          readonly __typename: 'ContentItemType';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'UserItemType';
          readonly id: string;
          readonly name: string;
        }
    >;
    readonly users: ReadonlyArray<{
      readonly __typename: 'User';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    }>;
    readonly policies: ReadonlyArray<{
      readonly __typename: 'Policy';
      readonly id: string;
      readonly name: string;
    }>;
    readonly mrtQueues: ReadonlyArray<{
      readonly __typename: 'ManualReviewQueue';
      readonly id: string;
      readonly name: string;
    }>;
    readonly rules: ReadonlyArray<
      | {
          readonly __typename: 'ContentRule';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'UserRule';
          readonly id: string;
          readonly name: string;
        }
    >;
  } | null;
};

export type GQLNcmecReportValuesFragment = {
  readonly __typename: 'NCMECReport';
  readonly ts: Date | string;
  readonly reportId: string;
  readonly userId: string;
  readonly reviewerId?: string | null;
  readonly reportXml: string;
  readonly isTest?: boolean | null;
  readonly userItemType: {
    readonly __typename: 'UserItemType';
    readonly name: string;
  };
  readonly reportedMedia: ReadonlyArray<{
    readonly __typename: 'NCMECReportedMedia';
    readonly id: string;
    readonly xml: string;
  }>;
  readonly additionalFiles: ReadonlyArray<{
    readonly __typename: 'NcmecAdditionalFile';
    readonly url: string;
    readonly xml: string;
    readonly ncmecFileId: string;
  }>;
  readonly reportedMessages: ReadonlyArray<{
    readonly __typename: 'NCMECReportedThread';
    readonly fileName: string;
    readonly csv: string;
    readonly ncmecFileId: string;
  }>;
};

export type GQLAllNcmecReportsQueryVariables = Exact<{ [key: string]: never }>;

export type GQLAllNcmecReportsQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly hasNCMECReportingEnabled: boolean;
    readonly ncmecReports: ReadonlyArray<{
      readonly __typename: 'NCMECReport';
      readonly ts: Date | string;
      readonly reportId: string;
      readonly userId: string;
      readonly reviewerId?: string | null;
      readonly reportXml: string;
      readonly isTest?: boolean | null;
      readonly userItemType: {
        readonly __typename: 'UserItemType';
        readonly name: string;
      };
      readonly reportedMedia: ReadonlyArray<{
        readonly __typename: 'NCMECReportedMedia';
        readonly id: string;
        readonly xml: string;
      }>;
      readonly additionalFiles: ReadonlyArray<{
        readonly __typename: 'NcmecAdditionalFile';
        readonly url: string;
        readonly xml: string;
        readonly ncmecFileId: string;
      }>;
      readonly reportedMessages: ReadonlyArray<{
        readonly __typename: 'NCMECReportedThread';
        readonly fileName: string;
        readonly csv: string;
        readonly ncmecFileId: string;
      }>;
    }>;
    readonly users: ReadonlyArray<{
      readonly __typename: 'User';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    }>;
  } | null;
};

export type GQLPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type GQLPermissionsQuery = {
  readonly __typename: 'Query';
  readonly me?: {
    readonly __typename: 'User';
    readonly permissions: ReadonlyArray<GQLUserPermission>;
  } | null;
};

export type GQLGetNcmecReportQueryVariables = Exact<{
  reportId: Scalars['ID'];
}>;

export type GQLGetNcmecReportQuery = {
  readonly __typename: 'Query';
  readonly ncmecReportById?: {
    readonly __typename: 'NCMECReport';
    readonly ts: Date | string;
    readonly reportId: string;
    readonly userId: string;
    readonly reviewerId?: string | null;
    readonly reportXml: string;
    readonly isTest?: boolean | null;
    readonly userItemType: {
      readonly __typename: 'UserItemType';
      readonly name: string;
    };
    readonly reportedMedia: ReadonlyArray<{
      readonly __typename: 'NCMECReportedMedia';
      readonly id: string;
      readonly xml: string;
    }>;
    readonly additionalFiles: ReadonlyArray<{
      readonly __typename: 'NcmecAdditionalFile';
      readonly url: string;
      readonly xml: string;
      readonly ncmecFileId: string;
    }>;
    readonly reportedMessages: ReadonlyArray<{
      readonly __typename: 'NCMECReportedThread';
      readonly fileName: string;
      readonly csv: string;
      readonly ncmecFileId: string;
    }>;
  } | null;
};

export type GQLTotalPendingJobsQueryVariables = Exact<{ [key: string]: never }>;

export type GQLTotalPendingJobsQuery = {
  readonly __typename: 'Query';
  readonly getTotalPendingJobsCount: number;
};

export type GQLRuleNamesAndIdsQueryVariables = Exact<{ [key: string]: never }>;

export type GQLRuleNamesAndIdsQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly rules: ReadonlyArray<
      | {
          readonly __typename: 'ContentRule';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'UserRule';
          readonly id: string;
          readonly name: string;
        }
    >;
  } | null;
};

export type GQLDataForOverviewChartsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLDataForOverviewChartsQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly users: ReadonlyArray<{
      readonly __typename: 'User';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    }>;
    readonly rules: ReadonlyArray<
      | {
          readonly __typename: 'ContentRule';
          readonly id: string;
          readonly name: string;
        }
      | {
          readonly __typename: 'UserRule';
          readonly id: string;
          readonly name: string;
        }
    >;
  } | null;
};

export type GQLViolationsPerPolicyQueryVariables = Exact<{
  input: GQLTopPolicyViolationsInput;
}>;

export type GQLViolationsPerPolicyQuery = {
  readonly __typename: 'Query';
  readonly topPolicyViolations: ReadonlyArray<{
    readonly __typename: 'PolicyViolationsCount';
    readonly count: number;
    readonly policyId: string;
  }>;
};

export type GQLPolicyFieldsFragment = {
  readonly __typename: 'Policy';
  readonly id: string;
  readonly name: string;
  readonly policyText?: string | null;
  readonly enforcementGuidelines?: string | null;
  readonly parentId?: string | null;
  readonly policyType?: GQLPolicyType | null;
  readonly userStrikeCount?: number | null;
  readonly applyUserStrikeCountConfigToChildren?: boolean | null;
};

export type GQLPoliciesQueryVariables = Exact<{ [key: string]: never }>;

export type GQLPoliciesQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly policies: ReadonlyArray<{
      readonly __typename: 'Policy';
      readonly id: string;
      readonly name: string;
      readonly policyText?: string | null;
      readonly enforcementGuidelines?: string | null;
      readonly parentId?: string | null;
      readonly policyType?: GQLPolicyType | null;
      readonly userStrikeCount?: number | null;
      readonly applyUserStrikeCountConfigToChildren?: boolean | null;
    }>;
  } | null;
};

export type GQLPoliciesWithModelsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLPoliciesWithModelsQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly policies: ReadonlyArray<{
      readonly __typename: 'Policy';
      readonly id: string;
      readonly name: string;
      readonly policyText?: string | null;
      readonly enforcementGuidelines?: string | null;
      readonly parentId?: string | null;
      readonly policyType?: GQLPolicyType | null;
      readonly userStrikeCount?: number | null;
      readonly applyUserStrikeCountConfigToChildren?: boolean | null;
    }>;
    readonly models: ReadonlyArray<{
      readonly __typename: 'CoveModel';
      readonly id: string;
      readonly status: GQLModelVersionStatus;
      readonly version: number;
      readonly alias?: GQLModelVersionAlias | null;
      readonly policy: { readonly __typename: 'Policy'; readonly id: string };
    }>;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly permissions: ReadonlyArray<GQLUserPermission>;
  } | null;
};

export type GQLAddPoliciesMutationVariables = Exact<{
  policies: ReadonlyArray<GQLAddPolicyInput> | GQLAddPolicyInput;
}>;

export type GQLAddPoliciesMutation = {
  readonly __typename: 'Mutation';
  readonly addPolicies: {
    readonly __typename: 'AddPoliciesResponse';
    readonly failures: ReadonlyArray<string>;
    readonly policies: ReadonlyArray<{
      readonly __typename: 'Policy';
      readonly id: string;
      readonly name: string;
      readonly policyText?: string | null;
      readonly enforcementGuidelines?: string | null;
      readonly parentId?: string | null;
      readonly policyType?: GQLPolicyType | null;
      readonly userStrikeCount?: number | null;
      readonly applyUserStrikeCountConfigToChildren?: boolean | null;
    }>;
  };
};

export type GQLGetOrgIsReadyToCreateCoveModelsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLGetOrgIsReadyToCreateCoveModelsQuery = {
  readonly __typename: 'Query';
  readonly getOrgIsReadyToCreateCoveModels: boolean;
};

export type GQLUpdatePolicyMutationVariables = Exact<{
  input: GQLUpdatePolicyInput;
}>;

export type GQLUpdatePolicyMutation = {
  readonly __typename: 'Mutation';
  readonly updatePolicy:
    | { readonly __typename: 'NotFoundError' }
    | {
        readonly __typename: 'Policy';
        readonly id: string;
        readonly name: string;
        readonly policyText?: string | null;
        readonly enforcementGuidelines?: string | null;
        readonly parentId?: string | null;
        readonly policyType?: GQLPolicyType | null;
        readonly userStrikeCount?: number | null;
        readonly applyUserStrikeCountConfigToChildren?: boolean | null;
      };
};

export type GQLDeletePolicyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLDeletePolicyMutation = {
  readonly __typename: 'Mutation';
  readonly deletePolicy?: boolean | null;
};

export type GQLIsDemoOrgQueryVariables = Exact<{ [key: string]: never }>;

export type GQLIsDemoOrgQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly isDemoOrg: boolean;
  } | null;
};

export type GQLReportingRulesQueryVariables = Exact<{ [key: string]: never }>;

export type GQLReportingRulesQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly id: string;
    readonly name: string;
    readonly reportingRules: ReadonlyArray<{
      readonly __typename: 'ReportingRule';
      readonly id: string;
      readonly name: string;
      readonly status: GQLReportingRuleStatus;
      readonly creator?: {
        readonly __typename: 'User';
        readonly firstName: string;
        readonly lastName: string;
      } | null;
      readonly policies: ReadonlyArray<{
        readonly __typename: 'Policy';
        readonly name: string;
      }>;
      readonly actions: ReadonlyArray<
        | {
            readonly __typename: 'CustomerDefinedAction';
            readonly id: string;
            readonly name: string;
          }
        | {
            readonly __typename: 'EnqueueAuthorToMrtAction';
            readonly id: string;
            readonly name: string;
          }
        | {
            readonly __typename: 'EnqueueToMrtAction';
            readonly id: string;
            readonly name: string;
          }
        | {
            readonly __typename: 'EnqueueToNcmecAction';
            readonly id: string;
            readonly name: string;
          }
      >;
      readonly itemTypes: ReadonlyArray<
        | {
            readonly __typename: 'ContentItemType';
            readonly id: string;
            readonly name: string;
          }
        | {
            readonly __typename: 'ThreadItemType';
            readonly id: string;
            readonly name: string;
          }
        | {
            readonly __typename: 'UserItemType';
            readonly id: string;
            readonly name: string;
          }
      >;
    }>;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly permissions: ReadonlyArray<GQLUserPermission>;
  } | null;
};

export type GQLCreateReportingRuleMutationVariables = Exact<{
  input: GQLCreateReportingRuleInput;
}>;

export type GQLCreateReportingRuleMutation = {
  readonly __typename: 'Mutation';
  readonly createReportingRule:
    | {
        readonly __typename: 'MutateReportingRuleSuccessResponse';
        readonly data: {
          readonly __typename: 'ReportingRule';
          readonly id: string;
        };
      }
    | {
        readonly __typename: 'ReportingRuleNameExistsError';
        readonly title: string;
      };
};

export type GQLUpdateReportingRuleMutationVariables = Exact<{
  input: GQLUpdateReportingRuleInput;
}>;

export type GQLUpdateReportingRuleMutation = {
  readonly __typename: 'Mutation';
  readonly updateReportingRule:
    | {
        readonly __typename: 'MutateReportingRuleSuccessResponse';
        readonly data: {
          readonly __typename: 'ReportingRule';
          readonly id: string;
        };
      }
    | { readonly __typename: 'NotFoundError'; readonly title: string }
    | {
        readonly __typename: 'ReportingRuleNameExistsError';
        readonly title: string;
      };
};

export type GQLDeleteReportingRuleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLDeleteReportingRuleMutation = {
  readonly __typename: 'Mutation';
  readonly deleteReportingRule: boolean;
};

type GQLRulesDashboardRuleFieldsFragmentContentRuleFragment = {
  readonly __typename: 'ContentRule';
  readonly id: string;
  readonly name: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly status: GQLRuleStatus;
  readonly creator: {
    readonly __typename: 'User';
    readonly firstName: string;
    readonly lastName: string;
  };
  readonly policies: ReadonlyArray<{
    readonly __typename: 'Policy';
    readonly name: string;
  }>;
  readonly actions: ReadonlyArray<
    | {
        readonly __typename: 'CustomerDefinedAction';
        readonly id: string;
        readonly name: string;
      }
    | {
        readonly __typename: 'EnqueueAuthorToMrtAction';
        readonly id: string;
        readonly name: string;
      }
    | {
        readonly __typename: 'EnqueueToMrtAction';
        readonly id: string;
        readonly name: string;
      }
    | {
        readonly __typename: 'EnqueueToNcmecAction';
        readonly id: string;
        readonly name: string;
      }
  >;
};

type GQLRulesDashboardRuleFieldsFragmentUserRuleFragment = {
  readonly __typename: 'UserRule';
  readonly id: string;
  readonly name: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly status: GQLRuleStatus;
  readonly creator: {
    readonly __typename: 'User';
    readonly firstName: string;
    readonly lastName: string;
  };
  readonly policies: ReadonlyArray<{
    readonly __typename: 'Policy';
    readonly name: string;
  }>;
  readonly actions: ReadonlyArray<
    | {
        readonly __typename: 'CustomerDefinedAction';
        readonly id: string;
        readonly name: string;
      }
    | {
        readonly __typename: 'EnqueueAuthorToMrtAction';
        readonly id: string;
        readonly name: string;
      }
    | {
        readonly __typename: 'EnqueueToMrtAction';
        readonly id: string;
        readonly name: string;
      }
    | {
        readonly __typename: 'EnqueueToNcmecAction';
        readonly id: string;
        readonly name: string;
      }
  >;
};

export type GQLRulesDashboardRuleFieldsFragmentFragment =
  | GQLRulesDashboardRuleFieldsFragmentContentRuleFragment
  | GQLRulesDashboardRuleFieldsFragmentUserRuleFragment;

export type GQLRulesQueryVariables = Exact<{ [key: string]: never }>;

export type GQLRulesQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly id: string;
    readonly name: string;
    readonly rules: ReadonlyArray<
      | {
          readonly __typename: 'ContentRule';
          readonly id: string;
          readonly name: string;
          readonly createdAt: string;
          readonly updatedAt: string;
          readonly status: GQLRuleStatus;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
          readonly conditionSet: {
            readonly __typename: 'ConditionSet';
            readonly conjunction: GQLConditionConjunction;
            readonly conditions: ReadonlyArray<
              | {
                  readonly __typename: 'ConditionSet';
                  readonly conjunction: GQLConditionConjunction;
                  readonly conditions: ReadonlyArray<
                    | { readonly __typename: 'ConditionSet' }
                    | {
                        readonly __typename: 'LeafCondition';
                        readonly comparator?: GQLValueComparator | null;
                        readonly threshold?: string | number | null;
                        readonly input: {
                          readonly __typename: 'ConditionInputField';
                          readonly type: GQLConditionInputInputType;
                          readonly name?: string | null;
                          readonly contentTypeId?: string | null;
                          readonly contentTypeIds?: ReadonlyArray<string> | null;
                          readonly spec?: {
                            readonly __typename: 'DerivedFieldSpec';
                            readonly derivationType: GQLDerivedFieldDerivationType;
                            readonly source:
                              | {
                                  readonly __typename: 'DerivedFieldFieldSource';
                                  readonly name: string;
                                  readonly contentTypeId: string;
                                }
                              | {
                                  readonly __typename: 'DerivedFieldFullItemSource';
                                  readonly _?: boolean | null;
                                }
                              | {
                                  readonly __typename: 'DerivedFieldProtegoInputSource';
                                  readonly protegoInput: GQLProtegoInput;
                                };
                          } | null;
                        };
                        readonly signal?: {
                          readonly __typename: 'Signal';
                          readonly id: string;
                          readonly type: GQLSignalType;
                          readonly name: string;
                          readonly subcategory?: string | null;
                          readonly args?:
                            | { readonly __typename: 'AggregationSignalArgs' }
                            | {
                                readonly __typename: 'Gpt4oMiniSignalArgs';
                                readonly policyId: string;
                              }
                            | null;
                        } | null;
                        readonly matchingValues?: {
                          readonly __typename: 'MatchingValues';
                          readonly strings?: ReadonlyArray<string> | null;
                          readonly textBankIds?: ReadonlyArray<string> | null;
                          readonly locationBankIds?: ReadonlyArray<string> | null;
                          readonly locations?: ReadonlyArray<{
                            readonly __typename: 'LocationArea';
                            readonly id: string;
                            readonly name?: string | null;
                            readonly geometry: {
                              readonly __typename: 'LocationGeometry';
                              readonly radius: number;
                              readonly center: {
                                readonly __typename: 'LatLng';
                                readonly lat: number;
                                readonly lng: number;
                              };
                            };
                            readonly bounds?: {
                              readonly __typename: 'PlaceBounds';
                              readonly northeastCorner: {
                                readonly __typename: 'LatLng';
                                readonly lat: number;
                                readonly lng: number;
                              };
                              readonly southwestCorner: {
                                readonly __typename: 'LatLng';
                                readonly lat: number;
                                readonly lng: number;
                              };
                            } | null;
                          }> | null;
                        } | null;
                      }
                  >;
                }
              | {
                  readonly __typename: 'LeafCondition';
                  readonly comparator?: GQLValueComparator | null;
                  readonly threshold?: string | number | null;
                  readonly input: {
                    readonly __typename: 'ConditionInputField';
                    readonly type: GQLConditionInputInputType;
                    readonly name?: string | null;
                    readonly contentTypeId?: string | null;
                    readonly contentTypeIds?: ReadonlyArray<string> | null;
                    readonly spec?: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    } | null;
                  };
                  readonly signal?: {
                    readonly __typename: 'Signal';
                    readonly id: string;
                    readonly type: GQLSignalType;
                    readonly name: string;
                    readonly subcategory?: string | null;
                    readonly args?:
                      | { readonly __typename: 'AggregationSignalArgs' }
                      | {
                          readonly __typename: 'Gpt4oMiniSignalArgs';
                          readonly policyId: string;
                        }
                      | null;
                  } | null;
                  readonly matchingValues?: {
                    readonly __typename: 'MatchingValues';
                    readonly strings?: ReadonlyArray<string> | null;
                    readonly textBankIds?: ReadonlyArray<string> | null;
                    readonly locationBankIds?: ReadonlyArray<string> | null;
                    readonly locations?: ReadonlyArray<{
                      readonly __typename: 'LocationArea';
                      readonly id: string;
                      readonly name?: string | null;
                      readonly geometry: {
                        readonly __typename: 'LocationGeometry';
                        readonly radius: number;
                        readonly center: {
                          readonly __typename: 'LatLng';
                          readonly lat: number;
                          readonly lng: number;
                        };
                      };
                      readonly bounds?: {
                        readonly __typename: 'PlaceBounds';
                        readonly northeastCorner: {
                          readonly __typename: 'LatLng';
                          readonly lat: number;
                          readonly lng: number;
                        };
                        readonly southwestCorner: {
                          readonly __typename: 'LatLng';
                          readonly lat: number;
                          readonly lng: number;
                        };
                      } | null;
                    }> | null;
                  } | null;
                }
            >;
          };
          readonly creator: {
            readonly __typename: 'User';
            readonly firstName: string;
            readonly lastName: string;
          };
          readonly policies: ReadonlyArray<{
            readonly __typename: 'Policy';
            readonly name: string;
          }>;
          readonly actions: ReadonlyArray<
            | {
                readonly __typename: 'CustomerDefinedAction';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'EnqueueAuthorToMrtAction';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'EnqueueToMrtAction';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'EnqueueToNcmecAction';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
      | {
          readonly __typename: 'UserRule';
          readonly id: string;
          readonly name: string;
          readonly createdAt: string;
          readonly updatedAt: string;
          readonly status: GQLRuleStatus;
          readonly conditionSet: {
            readonly __typename: 'ConditionSet';
            readonly conjunction: GQLConditionConjunction;
            readonly conditions: ReadonlyArray<
              | {
                  readonly __typename: 'ConditionSet';
                  readonly conjunction: GQLConditionConjunction;
                  readonly conditions: ReadonlyArray<
                    | { readonly __typename: 'ConditionSet' }
                    | {
                        readonly __typename: 'LeafCondition';
                        readonly comparator?: GQLValueComparator | null;
                        readonly threshold?: string | number | null;
                        readonly input: {
                          readonly __typename: 'ConditionInputField';
                          readonly type: GQLConditionInputInputType;
                          readonly name?: string | null;
                          readonly contentTypeId?: string | null;
                          readonly contentTypeIds?: ReadonlyArray<string> | null;
                          readonly spec?: {
                            readonly __typename: 'DerivedFieldSpec';
                            readonly derivationType: GQLDerivedFieldDerivationType;
                            readonly source:
                              | {
                                  readonly __typename: 'DerivedFieldFieldSource';
                                  readonly name: string;
                                  readonly contentTypeId: string;
                                }
                              | {
                                  readonly __typename: 'DerivedFieldFullItemSource';
                                  readonly _?: boolean | null;
                                }
                              | {
                                  readonly __typename: 'DerivedFieldProtegoInputSource';
                                  readonly protegoInput: GQLProtegoInput;
                                };
                          } | null;
                        };
                        readonly signal?: {
                          readonly __typename: 'Signal';
                          readonly id: string;
                          readonly type: GQLSignalType;
                          readonly name: string;
                          readonly subcategory?: string | null;
                          readonly args?:
                            | { readonly __typename: 'AggregationSignalArgs' }
                            | {
                                readonly __typename: 'Gpt4oMiniSignalArgs';
                                readonly policyId: string;
                              }
                            | null;
                        } | null;
                        readonly matchingValues?: {
                          readonly __typename: 'MatchingValues';
                          readonly strings?: ReadonlyArray<string> | null;
                          readonly textBankIds?: ReadonlyArray<string> | null;
                          readonly locationBankIds?: ReadonlyArray<string> | null;
                          readonly locations?: ReadonlyArray<{
                            readonly __typename: 'LocationArea';
                            readonly id: string;
                            readonly name?: string | null;
                            readonly geometry: {
                              readonly __typename: 'LocationGeometry';
                              readonly radius: number;
                              readonly center: {
                                readonly __typename: 'LatLng';
                                readonly lat: number;
                                readonly lng: number;
                              };
                            };
                            readonly bounds?: {
                              readonly __typename: 'PlaceBounds';
                              readonly northeastCorner: {
                                readonly __typename: 'LatLng';
                                readonly lat: number;
                                readonly lng: number;
                              };
                              readonly southwestCorner: {
                                readonly __typename: 'LatLng';
                                readonly lat: number;
                                readonly lng: number;
                              };
                            } | null;
                          }> | null;
                        } | null;
                      }
                  >;
                }
              | {
                  readonly __typename: 'LeafCondition';
                  readonly comparator?: GQLValueComparator | null;
                  readonly threshold?: string | number | null;
                  readonly input: {
                    readonly __typename: 'ConditionInputField';
                    readonly type: GQLConditionInputInputType;
                    readonly name?: string | null;
                    readonly contentTypeId?: string | null;
                    readonly contentTypeIds?: ReadonlyArray<string> | null;
                    readonly spec?: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    } | null;
                  };
                  readonly signal?: {
                    readonly __typename: 'Signal';
                    readonly id: string;
                    readonly type: GQLSignalType;
                    readonly name: string;
                    readonly subcategory?: string | null;
                    readonly args?:
                      | { readonly __typename: 'AggregationSignalArgs' }
                      | {
                          readonly __typename: 'Gpt4oMiniSignalArgs';
                          readonly policyId: string;
                        }
                      | null;
                  } | null;
                  readonly matchingValues?: {
                    readonly __typename: 'MatchingValues';
                    readonly strings?: ReadonlyArray<string> | null;
                    readonly textBankIds?: ReadonlyArray<string> | null;
                    readonly locationBankIds?: ReadonlyArray<string> | null;
                    readonly locations?: ReadonlyArray<{
                      readonly __typename: 'LocationArea';
                      readonly id: string;
                      readonly name?: string | null;
                      readonly geometry: {
                        readonly __typename: 'LocationGeometry';
                        readonly radius: number;
                        readonly center: {
                          readonly __typename: 'LatLng';
                          readonly lat: number;
                          readonly lng: number;
                        };
                      };
                      readonly bounds?: {
                        readonly __typename: 'PlaceBounds';
                        readonly northeastCorner: {
                          readonly __typename: 'LatLng';
                          readonly lat: number;
                          readonly lng: number;
                        };
                        readonly southwestCorner: {
                          readonly __typename: 'LatLng';
                          readonly lat: number;
                          readonly lng: number;
                        };
                      } | null;
                    }> | null;
                  } | null;
                }
            >;
          };
          readonly creator: {
            readonly __typename: 'User';
            readonly firstName: string;
            readonly lastName: string;
          };
          readonly policies: ReadonlyArray<{
            readonly __typename: 'Policy';
            readonly name: string;
          }>;
          readonly actions: ReadonlyArray<
            | {
                readonly __typename: 'CustomerDefinedAction';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'EnqueueAuthorToMrtAction';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'EnqueueToMrtAction';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'EnqueueToNcmecAction';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
    >;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly permissions: ReadonlyArray<GQLUserPermission>;
    readonly favoriteRules: ReadonlyArray<
      | { readonly __typename: 'ContentRule'; readonly id: string }
      | { readonly __typename: 'UserRule'; readonly id: string }
    >;
  } | null;
};

export type GQLDeleteRuleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLDeleteRuleMutation = {
  readonly __typename: 'Mutation';
  readonly deleteRule?: boolean | null;
};

export type GQLAddFavoriteRuleMutationVariables = Exact<{
  ruleId: Scalars['ID'];
}>;

export type GQLAddFavoriteRuleMutation = {
  readonly __typename: 'Mutation';
  readonly addFavoriteRule: {
    readonly __typename: 'AddFavoriteRuleSuccessResponse';
    readonly _?: boolean | null;
  };
};

export type GQLRemoveFavoriteRuleMutationVariables = Exact<{
  ruleId: Scalars['ID'];
}>;

export type GQLRemoveFavoriteRuleMutation = {
  readonly __typename: 'Mutation';
  readonly removeFavoriteRule: {
    readonly __typename: 'RemoveFavoriteRuleSuccessResponse';
    readonly _?: boolean | null;
  };
};

export type GQLRulesDashboardInsightsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLRulesDashboardInsightsQuery = {
  readonly __typename: 'Query';
  readonly allRuleInsights: {
    readonly __typename: 'AllRuleInsights';
    readonly actionedSubmissionsByPolicyByDay: ReadonlyArray<{
      readonly __typename: 'CountByPolicyByDay';
      readonly date: Date | string;
      readonly count: number;
      readonly policy: {
        readonly __typename: 'CountByPolicyByDayPolicy';
        readonly name: string;
        readonly id: string;
      };
    }>;
    readonly actionedSubmissionsByTagByDay: ReadonlyArray<{
      readonly __typename: 'CountByTagByDay';
      readonly date: Date | string;
      readonly count: number;
      readonly tag: string;
    }>;
    readonly actionedSubmissionsByActionByDay: ReadonlyArray<{
      readonly __typename: 'CountByActionByDay';
      readonly date: Date | string;
      readonly count: number;
      readonly action: {
        readonly __typename: 'CountByActionByDayAction';
        readonly name: string;
      };
    }>;
    readonly actionedSubmissionsByDay: ReadonlyArray<{
      readonly __typename: 'CountByDay';
      readonly date: Date | string;
      readonly count: number;
    }>;
    readonly totalSubmissionsByDay: ReadonlyArray<{
      readonly __typename: 'CountByDay';
      readonly date: Date | string;
      readonly count: number;
    }>;
  };
};

export type GQLPolicyRollupDataQueryVariables = Exact<{ [key: string]: never }>;

export type GQLPolicyRollupDataQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly id: string;
    readonly policies: ReadonlyArray<{
      readonly __typename: 'Policy';
      readonly id: string;
      readonly name: string;
      readonly parentId?: string | null;
    }>;
  } | null;
};

export type GQLActionStatisticsDataQueryVariables = Exact<{
  input: GQLActionStatisticsInput;
}>;

export type GQLActionStatisticsDataQuery = {
  readonly __typename: 'Query';
  readonly actionStatistics: ReadonlyArray<{
    readonly __typename: 'ActionData';
    readonly item_type_id?: string | null;
    readonly action_id?: string | null;
    readonly policy_id?: string | null;
    readonly rule_id?: string | null;
    readonly source?: string | null;
    readonly count: number;
    readonly time: string;
  }>;
};

export type GQLReportingRuleInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLReportingRuleInfoQuery = {
  readonly __typename: 'Query';
  readonly reportingRule?: {
    readonly __typename: 'ReportingRule';
    readonly name: string;
  } | null;
};

export type GQLRuleInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLRuleInfoQuery = {
  readonly __typename: 'Query';
  readonly me?: {
    readonly __typename: 'User';
    readonly permissions: ReadonlyArray<GQLUserPermission>;
  } | null;
  readonly rule?:
    | { readonly __typename: 'ContentRule'; readonly name: string }
    | { readonly __typename: 'UserRule'; readonly name: string }
    | null;
};

export type GQLSpotTestRuleQueryVariables = Exact<{
  ruleId: Scalars['ID'];
  item: GQLSpotTestItemInput;
}>;

export type GQLSpotTestRuleQuery = {
  readonly __typename: 'Query';
  readonly spotTestRule: {
    readonly __typename: 'RuleExecutionResult';
    readonly ts: Date | string;
    readonly contentId: string;
    readonly itemTypeName: string;
    readonly itemTypeId: string;
    readonly userId?: string | null;
    readonly userTypeId?: string | null;
    readonly content: string;
    readonly environment: GQLRuleEnvironment;
    readonly result?: {
      readonly __typename: 'ConditionSetWithResult';
      readonly conjunction?: GQLConditionConjunction | null;
      readonly conditions: ReadonlyArray<
        | {
            readonly __typename: 'ConditionSetWithResult';
            readonly conjunction?: GQLConditionConjunction | null;
            readonly conditions: ReadonlyArray<
              | { readonly __typename: 'ConditionSetWithResult' }
              | {
                  readonly __typename: 'LeafConditionWithResult';
                  readonly comparator?: GQLValueComparator | null;
                  readonly threshold?: string | number | null;
                  readonly input: {
                    readonly __typename: 'ConditionInputField';
                    readonly type: GQLConditionInputInputType;
                    readonly name?: string | null;
                    readonly contentTypeId?: string | null;
                    readonly spec?: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                    } | null;
                  };
                  readonly signal?: {
                    readonly __typename: 'Signal';
                    readonly id: string;
                    readonly type: GQLSignalType;
                    readonly name: string;
                    readonly subcategory?: string | null;
                    readonly args?:
                      | { readonly __typename: 'AggregationSignalArgs' }
                      | {
                          readonly __typename: 'Gpt4oMiniSignalArgs';
                          readonly policyId: string;
                        }
                      | null;
                  } | null;
                  readonly matchingValues?: {
                    readonly __typename: 'MatchingValues';
                    readonly strings?: ReadonlyArray<string> | null;
                    readonly textBankIds?: ReadonlyArray<string> | null;
                    readonly locationBankIds?: ReadonlyArray<string> | null;
                    readonly locations?: ReadonlyArray<{
                      readonly __typename: 'LocationArea';
                      readonly id: string;
                      readonly name?: string | null;
                      readonly geometry: {
                        readonly __typename: 'LocationGeometry';
                        readonly radius: number;
                        readonly center: {
                          readonly __typename: 'LatLng';
                          readonly lat: number;
                          readonly lng: number;
                        };
                      };
                      readonly bounds?: {
                        readonly __typename: 'PlaceBounds';
                        readonly northeastCorner: {
                          readonly __typename: 'LatLng';
                          readonly lat: number;
                          readonly lng: number;
                        };
                        readonly southwestCorner: {
                          readonly __typename: 'LatLng';
                          readonly lat: number;
                          readonly lng: number;
                        };
                      } | null;
                      readonly googlePlaceInfo?: {
                        readonly __typename: 'GooglePlaceLocationInfo';
                        readonly id: string;
                      } | null;
                    }> | null;
                  } | null;
                  readonly result?: {
                    readonly __typename: 'ConditionResult';
                    readonly outcome: GQLConditionOutcome;
                    readonly score?: string | null;
                    readonly matchedValue?: string | null;
                  } | null;
                }
            >;
            readonly result?: {
              readonly __typename: 'ConditionResult';
              readonly outcome: GQLConditionOutcome;
              readonly score?: string | null;
              readonly matchedValue?: string | null;
            } | null;
          }
        | {
            readonly __typename: 'LeafConditionWithResult';
            readonly comparator?: GQLValueComparator | null;
            readonly threshold?: string | number | null;
            readonly input: {
              readonly __typename: 'ConditionInputField';
              readonly type: GQLConditionInputInputType;
              readonly name?: string | null;
              readonly contentTypeId?: string | null;
              readonly spec?: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
              } | null;
            };
            readonly signal?: {
              readonly __typename: 'Signal';
              readonly id: string;
              readonly type: GQLSignalType;
              readonly name: string;
              readonly subcategory?: string | null;
              readonly args?:
                | { readonly __typename: 'AggregationSignalArgs' }
                | {
                    readonly __typename: 'Gpt4oMiniSignalArgs';
                    readonly policyId: string;
                  }
                | null;
            } | null;
            readonly matchingValues?: {
              readonly __typename: 'MatchingValues';
              readonly strings?: ReadonlyArray<string> | null;
              readonly textBankIds?: ReadonlyArray<string> | null;
              readonly locationBankIds?: ReadonlyArray<string> | null;
              readonly locations?: ReadonlyArray<{
                readonly __typename: 'LocationArea';
                readonly id: string;
                readonly name?: string | null;
                readonly geometry: {
                  readonly __typename: 'LocationGeometry';
                  readonly radius: number;
                  readonly center: {
                    readonly __typename: 'LatLng';
                    readonly lat: number;
                    readonly lng: number;
                  };
                };
                readonly bounds?: {
                  readonly __typename: 'PlaceBounds';
                  readonly northeastCorner: {
                    readonly __typename: 'LatLng';
                    readonly lat: number;
                    readonly lng: number;
                  };
                  readonly southwestCorner: {
                    readonly __typename: 'LatLng';
                    readonly lat: number;
                    readonly lng: number;
                  };
                } | null;
                readonly googlePlaceInfo?: {
                  readonly __typename: 'GooglePlaceLocationInfo';
                  readonly id: string;
                } | null;
              }> | null;
            } | null;
            readonly result?: {
              readonly __typename: 'ConditionResult';
              readonly outcome: GQLConditionOutcome;
              readonly score?: string | null;
              readonly matchedValue?: string | null;
            } | null;
          }
      >;
      readonly result?: {
        readonly __typename: 'ConditionResult';
        readonly outcome: GQLConditionOutcome;
        readonly score?: string | null;
        readonly matchedValue?: string | null;
      } | null;
    } | null;
    readonly signalResults?: ReadonlyArray<{
      readonly __typename: 'SignalWithScore';
      readonly signalName: string;
      readonly integration?: GQLIntegration | null;
      readonly subcategory?: string | null;
      readonly score: string;
    }> | null;
  };
};

export type GQLReportingRulePassRateAnalyticsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLReportingRulePassRateAnalyticsQuery = {
  readonly __typename: 'Query';
  readonly rule?:
    | {
        readonly __typename: 'ContentRule';
        readonly insights: {
          readonly __typename: 'RuleInsights';
          readonly passRateData?: ReadonlyArray<{
            readonly __typename: 'RulePassRateData';
            readonly date: string;
            readonly totalMatches: number;
            readonly totalRequests: number;
          } | null> | null;
        };
      }
    | {
        readonly __typename: 'UserRule';
        readonly insights: {
          readonly __typename: 'RuleInsights';
          readonly passRateData?: ReadonlyArray<{
            readonly __typename: 'RulePassRateData';
            readonly date: string;
            readonly totalMatches: number;
            readonly totalRequests: number;
          } | null> | null;
        };
      }
    | null;
};

export type GQLSampleReportingRuleExecutionResultFieldsFragment = {
  readonly __typename: 'ReportingRuleExecutionResult';
  readonly ts: Date | string;
  readonly itemId: string;
  readonly itemTypeName: string;
  readonly itemTypeId: string;
  readonly creatorId?: string | null;
  readonly creatorTypeId?: string | null;
  readonly itemData: string;
  readonly environment: GQLRuleEnvironment;
  readonly signalResults?: ReadonlyArray<{
    readonly __typename: 'SignalWithScore';
    readonly signalName: string;
    readonly integration?: GQLIntegration | null;
    readonly subcategory?: string | null;
    readonly score: string;
  }> | null;
};

export type GQLReportingRuleInsightsCurrentVersionSamplesQueryVariables =
  Exact<{
    id: Scalars['ID'];
  }>;

export type GQLReportingRuleInsightsCurrentVersionSamplesQuery = {
  readonly __typename: 'Query';
  readonly reportingRule?: {
    readonly __typename: 'ReportingRule';
    readonly id: string;
    readonly name: string;
    readonly itemTypes: ReadonlyArray<
      | {
          readonly __typename: 'ContentItemType';
          readonly id: string;
          readonly name: string;
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly type: GQLFieldType;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly name: string;
            readonly type: GQLFieldType;
          }>;
        }
      | {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
          readonly name: string;
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly type: GQLFieldType;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly name: string;
            readonly type: GQLFieldType;
          }>;
        }
      | {
          readonly __typename: 'UserItemType';
          readonly id: string;
          readonly name: string;
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly type: GQLFieldType;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly name: string;
            readonly type: GQLFieldType;
          }>;
        }
    >;
    readonly insights: {
      readonly __typename: 'ReportingRuleInsights';
      readonly samples: ReadonlyArray<{
        readonly __typename: 'ReportingRuleExecutionResult';
        readonly ts: Date | string;
        readonly itemId: string;
        readonly itemTypeName: string;
        readonly itemTypeId: string;
        readonly creatorId?: string | null;
        readonly creatorTypeId?: string | null;
        readonly itemData: string;
        readonly environment: GQLRuleEnvironment;
        readonly signalResults?: ReadonlyArray<{
          readonly __typename: 'SignalWithScore';
          readonly signalName: string;
          readonly integration?: GQLIntegration | null;
          readonly subcategory?: string | null;
          readonly score: string;
        }> | null;
      }>;
    };
  } | null;
};

export type GQLReportingRuleInsightsPriorVersionSamplesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLReportingRuleInsightsPriorVersionSamplesQuery = {
  readonly __typename: 'Query';
  readonly reportingRule?: {
    readonly __typename: 'ReportingRule';
    readonly name: string;
    readonly itemTypes: ReadonlyArray<
      | {
          readonly __typename: 'ContentItemType';
          readonly id: string;
          readonly name: string;
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly type: GQLFieldType;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly name: string;
            readonly type: GQLFieldType;
          }>;
        }
      | {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
          readonly name: string;
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly type: GQLFieldType;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly name: string;
            readonly type: GQLFieldType;
          }>;
        }
      | {
          readonly __typename: 'UserItemType';
          readonly id: string;
          readonly name: string;
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly type: GQLFieldType;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly name: string;
            readonly type: GQLFieldType;
          }>;
        }
    >;
    readonly insights: {
      readonly __typename: 'ReportingRuleInsights';
      readonly samples: ReadonlyArray<{
        readonly __typename: 'ReportingRuleExecutionResult';
        readonly ts: Date | string;
        readonly itemId: string;
        readonly itemTypeName: string;
        readonly itemTypeId: string;
        readonly creatorId?: string | null;
        readonly creatorTypeId?: string | null;
        readonly itemData: string;
        readonly environment: GQLRuleEnvironment;
        readonly signalResults?: ReadonlyArray<{
          readonly __typename: 'SignalWithScore';
          readonly signalName: string;
          readonly integration?: GQLIntegration | null;
          readonly subcategory?: string | null;
          readonly score: string;
        }> | null;
      }>;
    };
  } | null;
};

export type GQLRulePassRateAnalyticsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLRulePassRateAnalyticsQuery = {
  readonly __typename: 'Query';
  readonly rule?:
    | {
        readonly __typename: 'ContentRule';
        readonly insights: {
          readonly __typename: 'RuleInsights';
          readonly passRateData?: ReadonlyArray<{
            readonly __typename: 'RulePassRateData';
            readonly date: string;
            readonly totalMatches: number;
            readonly totalRequests: number;
          } | null> | null;
        };
      }
    | {
        readonly __typename: 'UserRule';
        readonly insights: {
          readonly __typename: 'RuleInsights';
          readonly passRateData?: ReadonlyArray<{
            readonly __typename: 'RulePassRateData';
            readonly date: string;
            readonly totalMatches: number;
            readonly totalRequests: number;
          } | null> | null;
        };
      }
    | null;
  readonly allRuleInsights: {
    readonly __typename: 'AllRuleInsights';
    readonly totalSubmissionsByDay: ReadonlyArray<{
      readonly __typename: 'CountByDay';
      readonly date: Date | string;
      readonly count: number;
    }>;
  };
};

export type GQLLeafConditionWithResultFieldsFragment = {
  readonly __typename: 'LeafConditionWithResult';
  readonly comparator?: GQLValueComparator | null;
  readonly threshold?: string | number | null;
  readonly input: {
    readonly __typename: 'ConditionInputField';
    readonly type: GQLConditionInputInputType;
    readonly name?: string | null;
    readonly contentTypeId?: string | null;
    readonly spec?: {
      readonly __typename: 'DerivedFieldSpec';
      readonly derivationType: GQLDerivedFieldDerivationType;
    } | null;
  };
  readonly signal?: {
    readonly __typename: 'Signal';
    readonly id: string;
    readonly type: GQLSignalType;
    readonly name: string;
    readonly subcategory?: string | null;
    readonly args?:
      | { readonly __typename: 'AggregationSignalArgs' }
      | {
          readonly __typename: 'Gpt4oMiniSignalArgs';
          readonly policyId: string;
        }
      | null;
  } | null;
  readonly matchingValues?: {
    readonly __typename: 'MatchingValues';
    readonly strings?: ReadonlyArray<string> | null;
    readonly textBankIds?: ReadonlyArray<string> | null;
    readonly locationBankIds?: ReadonlyArray<string> | null;
    readonly locations?: ReadonlyArray<{
      readonly __typename: 'LocationArea';
      readonly id: string;
      readonly name?: string | null;
      readonly geometry: {
        readonly __typename: 'LocationGeometry';
        readonly radius: number;
        readonly center: {
          readonly __typename: 'LatLng';
          readonly lat: number;
          readonly lng: number;
        };
      };
      readonly bounds?: {
        readonly __typename: 'PlaceBounds';
        readonly northeastCorner: {
          readonly __typename: 'LatLng';
          readonly lat: number;
          readonly lng: number;
        };
        readonly southwestCorner: {
          readonly __typename: 'LatLng';
          readonly lat: number;
          readonly lng: number;
        };
      } | null;
      readonly googlePlaceInfo?: {
        readonly __typename: 'GooglePlaceLocationInfo';
        readonly id: string;
      } | null;
    }> | null;
  } | null;
  readonly result?: {
    readonly __typename: 'ConditionResult';
    readonly outcome: GQLConditionOutcome;
    readonly score?: string | null;
    readonly matchedValue?: string | null;
  } | null;
};

export type GQLSampleRuleExecutionResultFieldsFragment = {
  readonly __typename: 'RuleExecutionResult';
  readonly ts: Date | string;
  readonly contentId: string;
  readonly itemTypeName: string;
  readonly itemTypeId: string;
  readonly userId?: string | null;
  readonly userTypeId?: string | null;
  readonly content: string;
  readonly environment: GQLRuleEnvironment;
  readonly signalResults?: ReadonlyArray<{
    readonly __typename: 'SignalWithScore';
    readonly signalName: string;
    readonly integration?: GQLIntegration | null;
    readonly subcategory?: string | null;
    readonly score: string;
  }> | null;
};

export type GQLSampleRuleExecutionResultConditionResultFieldsFragment = {
  readonly __typename: 'ConditionSetWithResult';
  readonly conjunction?: GQLConditionConjunction | null;
  readonly conditions: ReadonlyArray<
    | {
        readonly __typename: 'ConditionSetWithResult';
        readonly conjunction?: GQLConditionConjunction | null;
        readonly conditions: ReadonlyArray<
          | { readonly __typename: 'ConditionSetWithResult' }
          | {
              readonly __typename: 'LeafConditionWithResult';
              readonly comparator?: GQLValueComparator | null;
              readonly threshold?: string | number | null;
              readonly input: {
                readonly __typename: 'ConditionInputField';
                readonly type: GQLConditionInputInputType;
                readonly name?: string | null;
                readonly contentTypeId?: string | null;
                readonly spec?: {
                  readonly __typename: 'DerivedFieldSpec';
                  readonly derivationType: GQLDerivedFieldDerivationType;
                } | null;
              };
              readonly signal?: {
                readonly __typename: 'Signal';
                readonly id: string;
                readonly type: GQLSignalType;
                readonly name: string;
                readonly subcategory?: string | null;
                readonly args?:
                  | { readonly __typename: 'AggregationSignalArgs' }
                  | {
                      readonly __typename: 'Gpt4oMiniSignalArgs';
                      readonly policyId: string;
                    }
                  | null;
              } | null;
              readonly matchingValues?: {
                readonly __typename: 'MatchingValues';
                readonly strings?: ReadonlyArray<string> | null;
                readonly textBankIds?: ReadonlyArray<string> | null;
                readonly locationBankIds?: ReadonlyArray<string> | null;
                readonly locations?: ReadonlyArray<{
                  readonly __typename: 'LocationArea';
                  readonly id: string;
                  readonly name?: string | null;
                  readonly geometry: {
                    readonly __typename: 'LocationGeometry';
                    readonly radius: number;
                    readonly center: {
                      readonly __typename: 'LatLng';
                      readonly lat: number;
                      readonly lng: number;
                    };
                  };
                  readonly bounds?: {
                    readonly __typename: 'PlaceBounds';
                    readonly northeastCorner: {
                      readonly __typename: 'LatLng';
                      readonly lat: number;
                      readonly lng: number;
                    };
                    readonly southwestCorner: {
                      readonly __typename: 'LatLng';
                      readonly lat: number;
                      readonly lng: number;
                    };
                  } | null;
                  readonly googlePlaceInfo?: {
                    readonly __typename: 'GooglePlaceLocationInfo';
                    readonly id: string;
                  } | null;
                }> | null;
              } | null;
              readonly result?: {
                readonly __typename: 'ConditionResult';
                readonly outcome: GQLConditionOutcome;
                readonly score?: string | null;
                readonly matchedValue?: string | null;
              } | null;
            }
        >;
        readonly result?: {
          readonly __typename: 'ConditionResult';
          readonly outcome: GQLConditionOutcome;
          readonly score?: string | null;
          readonly matchedValue?: string | null;
        } | null;
      }
    | {
        readonly __typename: 'LeafConditionWithResult';
        readonly comparator?: GQLValueComparator | null;
        readonly threshold?: string | number | null;
        readonly input: {
          readonly __typename: 'ConditionInputField';
          readonly type: GQLConditionInputInputType;
          readonly name?: string | null;
          readonly contentTypeId?: string | null;
          readonly spec?: {
            readonly __typename: 'DerivedFieldSpec';
            readonly derivationType: GQLDerivedFieldDerivationType;
          } | null;
        };
        readonly signal?: {
          readonly __typename: 'Signal';
          readonly id: string;
          readonly type: GQLSignalType;
          readonly name: string;
          readonly subcategory?: string | null;
          readonly args?:
            | { readonly __typename: 'AggregationSignalArgs' }
            | {
                readonly __typename: 'Gpt4oMiniSignalArgs';
                readonly policyId: string;
              }
            | null;
        } | null;
        readonly matchingValues?: {
          readonly __typename: 'MatchingValues';
          readonly strings?: ReadonlyArray<string> | null;
          readonly textBankIds?: ReadonlyArray<string> | null;
          readonly locationBankIds?: ReadonlyArray<string> | null;
          readonly locations?: ReadonlyArray<{
            readonly __typename: 'LocationArea';
            readonly id: string;
            readonly name?: string | null;
            readonly geometry: {
              readonly __typename: 'LocationGeometry';
              readonly radius: number;
              readonly center: {
                readonly __typename: 'LatLng';
                readonly lat: number;
                readonly lng: number;
              };
            };
            readonly bounds?: {
              readonly __typename: 'PlaceBounds';
              readonly northeastCorner: {
                readonly __typename: 'LatLng';
                readonly lat: number;
                readonly lng: number;
              };
              readonly southwestCorner: {
                readonly __typename: 'LatLng';
                readonly lat: number;
                readonly lng: number;
              };
            } | null;
            readonly googlePlaceInfo?: {
              readonly __typename: 'GooglePlaceLocationInfo';
              readonly id: string;
            } | null;
          }> | null;
        } | null;
        readonly result?: {
          readonly __typename: 'ConditionResult';
          readonly outcome: GQLConditionOutcome;
          readonly score?: string | null;
          readonly matchedValue?: string | null;
        } | null;
      }
  >;
  readonly result?: {
    readonly __typename: 'ConditionResult';
    readonly outcome: GQLConditionOutcome;
    readonly score?: string | null;
    readonly matchedValue?: string | null;
  } | null;
};

export type GQLRuleInsightsTableAllSignalsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLRuleInsightsTableAllSignalsQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly signals: ReadonlyArray<{
      readonly __typename: 'Signal';
      readonly id: string;
      readonly integration?: GQLIntegration | null;
      readonly eligibleSubcategories: ReadonlyArray<{
        readonly __typename: 'SignalSubcategory';
        readonly id: string;
        readonly label: string;
      }>;
    }>;
  } | null;
};

export type GQLRuleInsightsCurrentVersionSamplesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLRuleInsightsCurrentVersionSamplesQuery = {
  readonly __typename: 'Query';
  readonly rule?:
    | {
        readonly __typename: 'ContentRule';
        readonly name: string;
        readonly insights: {
          readonly __typename: 'RuleInsights';
          readonly samples: ReadonlyArray<{
            readonly __typename: 'RuleExecutionResult';
            readonly ts: Date | string;
            readonly contentId: string;
            readonly itemTypeName: string;
            readonly itemTypeId: string;
            readonly userId?: string | null;
            readonly userTypeId?: string | null;
            readonly content: string;
            readonly environment: GQLRuleEnvironment;
            readonly signalResults?: ReadonlyArray<{
              readonly __typename: 'SignalWithScore';
              readonly signalName: string;
              readonly integration?: GQLIntegration | null;
              readonly subcategory?: string | null;
              readonly score: string;
            }> | null;
          }>;
        };
        readonly itemTypes: ReadonlyArray<
          | {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly type: GQLFieldType;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly name: string;
                readonly type: GQLFieldType;
              }>;
            }
          | {
              readonly __typename: 'ThreadItemType';
              readonly id: string;
              readonly name: string;
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly type: GQLFieldType;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly name: string;
                readonly type: GQLFieldType;
              }>;
            }
          | {
              readonly __typename: 'UserItemType';
              readonly id: string;
              readonly name: string;
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly type: GQLFieldType;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly name: string;
                readonly type: GQLFieldType;
              }>;
            }
        >;
      }
    | {
        readonly __typename: 'UserRule';
        readonly name: string;
        readonly insights: {
          readonly __typename: 'RuleInsights';
          readonly samples: ReadonlyArray<{
            readonly __typename: 'RuleExecutionResult';
            readonly ts: Date | string;
            readonly contentId: string;
            readonly itemTypeName: string;
            readonly itemTypeId: string;
            readonly userId?: string | null;
            readonly userTypeId?: string | null;
            readonly content: string;
            readonly environment: GQLRuleEnvironment;
            readonly signalResults?: ReadonlyArray<{
              readonly __typename: 'SignalWithScore';
              readonly signalName: string;
              readonly integration?: GQLIntegration | null;
              readonly subcategory?: string | null;
              readonly score: string;
            }> | null;
          }>;
        };
      }
    | null;
};

export type GQLRuleInsightsPriorVersionSamplesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLRuleInsightsPriorVersionSamplesQuery = {
  readonly __typename: 'Query';
  readonly rule?:
    | {
        readonly __typename: 'ContentRule';
        readonly name: string;
        readonly insights: {
          readonly __typename: 'RuleInsights';
          readonly samples: ReadonlyArray<{
            readonly __typename: 'RuleExecutionResult';
            readonly ts: Date | string;
            readonly contentId: string;
            readonly itemTypeName: string;
            readonly itemTypeId: string;
            readonly userId?: string | null;
            readonly userTypeId?: string | null;
            readonly content: string;
            readonly environment: GQLRuleEnvironment;
            readonly signalResults?: ReadonlyArray<{
              readonly __typename: 'SignalWithScore';
              readonly signalName: string;
              readonly integration?: GQLIntegration | null;
              readonly subcategory?: string | null;
              readonly score: string;
            }> | null;
          }>;
        };
        readonly itemTypes: ReadonlyArray<
          | {
              readonly __typename: 'ContentItemType';
              readonly name: string;
              readonly id: string;
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly name: string;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
            }
          | {
              readonly __typename: 'ThreadItemType';
              readonly name: string;
              readonly id: string;
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly name: string;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
            }
          | {
              readonly __typename: 'UserItemType';
              readonly name: string;
              readonly id: string;
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly name: string;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
            }
        >;
      }
    | {
        readonly __typename: 'UserRule';
        readonly name: string;
        readonly insights: {
          readonly __typename: 'RuleInsights';
          readonly samples: ReadonlyArray<{
            readonly __typename: 'RuleExecutionResult';
            readonly ts: Date | string;
            readonly contentId: string;
            readonly itemTypeName: string;
            readonly itemTypeId: string;
            readonly userId?: string | null;
            readonly userTypeId?: string | null;
            readonly content: string;
            readonly environment: GQLRuleEnvironment;
            readonly signalResults?: ReadonlyArray<{
              readonly __typename: 'SignalWithScore';
              readonly signalName: string;
              readonly integration?: GQLIntegration | null;
              readonly subcategory?: string | null;
              readonly score: string;
            }> | null;
          }>;
        };
      }
    | null;
};

export type GQLGetFullResultForRuleQueryVariables = Exact<{
  input: GQLGetFullResultForItemInput;
}>;

export type GQLGetFullResultForRuleQuery = {
  readonly __typename: 'Query';
  readonly getFullRuleResultForItem:
    | { readonly __typename: 'NotFoundError'; readonly title: string }
    | {
        readonly __typename: 'RuleExecutionResult';
        readonly ts: Date | string;
        readonly contentId: string;
        readonly itemTypeName: string;
        readonly itemTypeId: string;
        readonly userId?: string | null;
        readonly userTypeId?: string | null;
        readonly content: string;
        readonly environment: GQLRuleEnvironment;
        readonly result?: {
          readonly __typename: 'ConditionSetWithResult';
          readonly conjunction?: GQLConditionConjunction | null;
          readonly conditions: ReadonlyArray<
            | {
                readonly __typename: 'ConditionSetWithResult';
                readonly conjunction?: GQLConditionConjunction | null;
                readonly conditions: ReadonlyArray<
                  | { readonly __typename: 'ConditionSetWithResult' }
                  | {
                      readonly __typename: 'LeafConditionWithResult';
                      readonly comparator?: GQLValueComparator | null;
                      readonly threshold?: string | number | null;
                      readonly input: {
                        readonly __typename: 'ConditionInputField';
                        readonly type: GQLConditionInputInputType;
                        readonly name?: string | null;
                        readonly contentTypeId?: string | null;
                        readonly spec?: {
                          readonly __typename: 'DerivedFieldSpec';
                          readonly derivationType: GQLDerivedFieldDerivationType;
                        } | null;
                      };
                      readonly signal?: {
                        readonly __typename: 'Signal';
                        readonly id: string;
                        readonly type: GQLSignalType;
                        readonly name: string;
                        readonly subcategory?: string | null;
                        readonly args?:
                          | { readonly __typename: 'AggregationSignalArgs' }
                          | {
                              readonly __typename: 'Gpt4oMiniSignalArgs';
                              readonly policyId: string;
                            }
                          | null;
                      } | null;
                      readonly matchingValues?: {
                        readonly __typename: 'MatchingValues';
                        readonly strings?: ReadonlyArray<string> | null;
                        readonly textBankIds?: ReadonlyArray<string> | null;
                        readonly locationBankIds?: ReadonlyArray<string> | null;
                        readonly locations?: ReadonlyArray<{
                          readonly __typename: 'LocationArea';
                          readonly id: string;
                          readonly name?: string | null;
                          readonly geometry: {
                            readonly __typename: 'LocationGeometry';
                            readonly radius: number;
                            readonly center: {
                              readonly __typename: 'LatLng';
                              readonly lat: number;
                              readonly lng: number;
                            };
                          };
                          readonly bounds?: {
                            readonly __typename: 'PlaceBounds';
                            readonly northeastCorner: {
                              readonly __typename: 'LatLng';
                              readonly lat: number;
                              readonly lng: number;
                            };
                            readonly southwestCorner: {
                              readonly __typename: 'LatLng';
                              readonly lat: number;
                              readonly lng: number;
                            };
                          } | null;
                          readonly googlePlaceInfo?: {
                            readonly __typename: 'GooglePlaceLocationInfo';
                            readonly id: string;
                          } | null;
                        }> | null;
                      } | null;
                      readonly result?: {
                        readonly __typename: 'ConditionResult';
                        readonly outcome: GQLConditionOutcome;
                        readonly score?: string | null;
                        readonly matchedValue?: string | null;
                      } | null;
                    }
                >;
                readonly result?: {
                  readonly __typename: 'ConditionResult';
                  readonly outcome: GQLConditionOutcome;
                  readonly score?: string | null;
                  readonly matchedValue?: string | null;
                } | null;
              }
            | {
                readonly __typename: 'LeafConditionWithResult';
                readonly comparator?: GQLValueComparator | null;
                readonly threshold?: string | number | null;
                readonly input: {
                  readonly __typename: 'ConditionInputField';
                  readonly type: GQLConditionInputInputType;
                  readonly name?: string | null;
                  readonly contentTypeId?: string | null;
                  readonly spec?: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                  } | null;
                };
                readonly signal?: {
                  readonly __typename: 'Signal';
                  readonly id: string;
                  readonly type: GQLSignalType;
                  readonly name: string;
                  readonly subcategory?: string | null;
                  readonly args?:
                    | { readonly __typename: 'AggregationSignalArgs' }
                    | {
                        readonly __typename: 'Gpt4oMiniSignalArgs';
                        readonly policyId: string;
                      }
                    | null;
                } | null;
                readonly matchingValues?: {
                  readonly __typename: 'MatchingValues';
                  readonly strings?: ReadonlyArray<string> | null;
                  readonly textBankIds?: ReadonlyArray<string> | null;
                  readonly locationBankIds?: ReadonlyArray<string> | null;
                  readonly locations?: ReadonlyArray<{
                    readonly __typename: 'LocationArea';
                    readonly id: string;
                    readonly name?: string | null;
                    readonly geometry: {
                      readonly __typename: 'LocationGeometry';
                      readonly radius: number;
                      readonly center: {
                        readonly __typename: 'LatLng';
                        readonly lat: number;
                        readonly lng: number;
                      };
                    };
                    readonly bounds?: {
                      readonly __typename: 'PlaceBounds';
                      readonly northeastCorner: {
                        readonly __typename: 'LatLng';
                        readonly lat: number;
                        readonly lng: number;
                      };
                      readonly southwestCorner: {
                        readonly __typename: 'LatLng';
                        readonly lat: number;
                        readonly lng: number;
                      };
                    } | null;
                    readonly googlePlaceInfo?: {
                      readonly __typename: 'GooglePlaceLocationInfo';
                      readonly id: string;
                    } | null;
                  }> | null;
                } | null;
                readonly result?: {
                  readonly __typename: 'ConditionResult';
                  readonly outcome: GQLConditionOutcome;
                  readonly score?: string | null;
                  readonly matchedValue?: string | null;
                } | null;
              }
          >;
          readonly result?: {
            readonly __typename: 'ConditionResult';
            readonly outcome: GQLConditionOutcome;
            readonly score?: string | null;
            readonly matchedValue?: string | null;
          } | null;
        } | null;
        readonly signalResults?: ReadonlyArray<{
          readonly __typename: 'SignalWithScore';
          readonly signalName: string;
          readonly integration?: GQLIntegration | null;
          readonly subcategory?: string | null;
          readonly score: string;
        }> | null;
      };
};

export type GQLMatchingBankNamesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLMatchingBankNamesQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly banks?: {
      readonly __typename: 'MatchingBanks';
      readonly textBanks: ReadonlyArray<{
        readonly __typename: 'TextBank';
        readonly id: string;
        readonly name: string;
      }>;
      readonly locationBanks: ReadonlyArray<{
        readonly __typename: 'LocationBank';
        readonly id: string;
        readonly name: string;
      }>;
    } | null;
  } | null;
};

export type GQLReportingRuleFormRuleFieldsFragmentFragment = {
  readonly __typename: 'ReportingRule';
  readonly id: string;
  readonly name: string;
  readonly description?: string | null;
  readonly status: GQLReportingRuleStatus;
  readonly policies: ReadonlyArray<{
    readonly __typename: 'Policy';
    readonly id: string;
  }>;
  readonly conditionSet: {
    readonly __typename: 'ConditionSet';
    readonly conjunction: GQLConditionConjunction;
    readonly conditions: ReadonlyArray<
      | {
          readonly __typename: 'ConditionSet';
          readonly conjunction: GQLConditionConjunction;
          readonly conditions: ReadonlyArray<
            | { readonly __typename: 'ConditionSet' }
            | {
                readonly __typename: 'LeafCondition';
                readonly comparator?: GQLValueComparator | null;
                readonly threshold?: string | number | null;
                readonly input: {
                  readonly __typename: 'ConditionInputField';
                  readonly type: GQLConditionInputInputType;
                  readonly name?: string | null;
                  readonly contentTypeId?: string | null;
                  readonly contentTypeIds?: ReadonlyArray<string> | null;
                  readonly spec?: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  } | null;
                };
                readonly signal?: {
                  readonly __typename: 'Signal';
                  readonly id: string;
                  readonly type: GQLSignalType;
                  readonly name: string;
                  readonly subcategory?: string | null;
                  readonly args?:
                    | { readonly __typename: 'AggregationSignalArgs' }
                    | {
                        readonly __typename: 'Gpt4oMiniSignalArgs';
                        readonly policyId: string;
                      }
                    | null;
                } | null;
                readonly matchingValues?: {
                  readonly __typename: 'MatchingValues';
                  readonly strings?: ReadonlyArray<string> | null;
                  readonly textBankIds?: ReadonlyArray<string> | null;
                  readonly locationBankIds?: ReadonlyArray<string> | null;
                  readonly locations?: ReadonlyArray<{
                    readonly __typename: 'LocationArea';
                    readonly id: string;
                    readonly name?: string | null;
                    readonly geometry: {
                      readonly __typename: 'LocationGeometry';
                      readonly radius: number;
                      readonly center: {
                        readonly __typename: 'LatLng';
                        readonly lat: number;
                        readonly lng: number;
                      };
                    };
                    readonly bounds?: {
                      readonly __typename: 'PlaceBounds';
                      readonly northeastCorner: {
                        readonly __typename: 'LatLng';
                        readonly lat: number;
                        readonly lng: number;
                      };
                      readonly southwestCorner: {
                        readonly __typename: 'LatLng';
                        readonly lat: number;
                        readonly lng: number;
                      };
                    } | null;
                  }> | null;
                } | null;
              }
          >;
        }
      | {
          readonly __typename: 'LeafCondition';
          readonly comparator?: GQLValueComparator | null;
          readonly threshold?: string | number | null;
          readonly input: {
            readonly __typename: 'ConditionInputField';
            readonly type: GQLConditionInputInputType;
            readonly name?: string | null;
            readonly contentTypeId?: string | null;
            readonly contentTypeIds?: ReadonlyArray<string> | null;
            readonly spec?: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            } | null;
          };
          readonly signal?: {
            readonly __typename: 'Signal';
            readonly id: string;
            readonly type: GQLSignalType;
            readonly name: string;
            readonly subcategory?: string | null;
            readonly args?:
              | { readonly __typename: 'AggregationSignalArgs' }
              | {
                  readonly __typename: 'Gpt4oMiniSignalArgs';
                  readonly policyId: string;
                }
              | null;
          } | null;
          readonly matchingValues?: {
            readonly __typename: 'MatchingValues';
            readonly strings?: ReadonlyArray<string> | null;
            readonly textBankIds?: ReadonlyArray<string> | null;
            readonly locationBankIds?: ReadonlyArray<string> | null;
            readonly locations?: ReadonlyArray<{
              readonly __typename: 'LocationArea';
              readonly id: string;
              readonly name?: string | null;
              readonly geometry: {
                readonly __typename: 'LocationGeometry';
                readonly radius: number;
                readonly center: {
                  readonly __typename: 'LatLng';
                  readonly lat: number;
                  readonly lng: number;
                };
              };
              readonly bounds?: {
                readonly __typename: 'PlaceBounds';
                readonly northeastCorner: {
                  readonly __typename: 'LatLng';
                  readonly lat: number;
                  readonly lng: number;
                };
                readonly southwestCorner: {
                  readonly __typename: 'LatLng';
                  readonly lat: number;
                  readonly lng: number;
                };
              } | null;
            }> | null;
          } | null;
        }
    >;
  };
  readonly actions: ReadonlyArray<
    | {
        readonly __typename: 'CustomerDefinedAction';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly itemTypes: ReadonlyArray<
          | {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'ThreadItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'UserItemType';
              readonly id: string;
              readonly name: string;
            }
        >;
      }
    | {
        readonly __typename: 'EnqueueAuthorToMrtAction';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly itemTypes: ReadonlyArray<
          | {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'ThreadItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'UserItemType';
              readonly id: string;
              readonly name: string;
            }
        >;
      }
    | {
        readonly __typename: 'EnqueueToMrtAction';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly itemTypes: ReadonlyArray<
          | {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'ThreadItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'UserItemType';
              readonly id: string;
              readonly name: string;
            }
        >;
      }
    | {
        readonly __typename: 'EnqueueToNcmecAction';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly itemTypes: ReadonlyArray<
          | {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'ThreadItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'UserItemType';
              readonly id: string;
              readonly name: string;
            }
        >;
      }
  >;
};

export type GQLReportingRuleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLReportingRuleQuery = {
  readonly __typename: 'Query';
  readonly reportingRule?: {
    readonly __typename: 'ReportingRule';
    readonly id: string;
    readonly name: string;
    readonly description?: string | null;
    readonly status: GQLReportingRuleStatus;
    readonly itemTypes: ReadonlyArray<
      | {
          readonly __typename: 'ContentItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'ContentSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly parentId?: string | null;
            readonly threadId?: string | null;
            readonly createdAt?: string | null;
            readonly creatorId?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
      | {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'ThreadSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly creatorId?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
      | {
          readonly __typename: 'UserItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'UserSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly profileIcon?: string | null;
            readonly backgroundImage?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
    >;
    readonly policies: ReadonlyArray<{
      readonly __typename: 'Policy';
      readonly id: string;
    }>;
    readonly conditionSet: {
      readonly __typename: 'ConditionSet';
      readonly conjunction: GQLConditionConjunction;
      readonly conditions: ReadonlyArray<
        | {
            readonly __typename: 'ConditionSet';
            readonly conjunction: GQLConditionConjunction;
            readonly conditions: ReadonlyArray<
              | { readonly __typename: 'ConditionSet' }
              | {
                  readonly __typename: 'LeafCondition';
                  readonly comparator?: GQLValueComparator | null;
                  readonly threshold?: string | number | null;
                  readonly input: {
                    readonly __typename: 'ConditionInputField';
                    readonly type: GQLConditionInputInputType;
                    readonly name?: string | null;
                    readonly contentTypeId?: string | null;
                    readonly contentTypeIds?: ReadonlyArray<string> | null;
                    readonly spec?: {
                      readonly __typename: 'DerivedFieldSpec';
                      readonly derivationType: GQLDerivedFieldDerivationType;
                      readonly source:
                        | {
                            readonly __typename: 'DerivedFieldFieldSource';
                            readonly name: string;
                            readonly contentTypeId: string;
                          }
                        | {
                            readonly __typename: 'DerivedFieldFullItemSource';
                            readonly _?: boolean | null;
                          }
                        | {
                            readonly __typename: 'DerivedFieldProtegoInputSource';
                            readonly protegoInput: GQLProtegoInput;
                          };
                    } | null;
                  };
                  readonly signal?: {
                    readonly __typename: 'Signal';
                    readonly id: string;
                    readonly type: GQLSignalType;
                    readonly name: string;
                    readonly subcategory?: string | null;
                    readonly args?:
                      | { readonly __typename: 'AggregationSignalArgs' }
                      | {
                          readonly __typename: 'Gpt4oMiniSignalArgs';
                          readonly policyId: string;
                        }
                      | null;
                  } | null;
                  readonly matchingValues?: {
                    readonly __typename: 'MatchingValues';
                    readonly strings?: ReadonlyArray<string> | null;
                    readonly textBankIds?: ReadonlyArray<string> | null;
                    readonly locationBankIds?: ReadonlyArray<string> | null;
                    readonly locations?: ReadonlyArray<{
                      readonly __typename: 'LocationArea';
                      readonly id: string;
                      readonly name?: string | null;
                      readonly geometry: {
                        readonly __typename: 'LocationGeometry';
                        readonly radius: number;
                        readonly center: {
                          readonly __typename: 'LatLng';
                          readonly lat: number;
                          readonly lng: number;
                        };
                      };
                      readonly bounds?: {
                        readonly __typename: 'PlaceBounds';
                        readonly northeastCorner: {
                          readonly __typename: 'LatLng';
                          readonly lat: number;
                          readonly lng: number;
                        };
                        readonly southwestCorner: {
                          readonly __typename: 'LatLng';
                          readonly lat: number;
                          readonly lng: number;
                        };
                      } | null;
                    }> | null;
                  } | null;
                }
            >;
          }
        | {
            readonly __typename: 'LeafCondition';
            readonly comparator?: GQLValueComparator | null;
            readonly threshold?: string | number | null;
            readonly input: {
              readonly __typename: 'ConditionInputField';
              readonly type: GQLConditionInputInputType;
              readonly name?: string | null;
              readonly contentTypeId?: string | null;
              readonly contentTypeIds?: ReadonlyArray<string> | null;
              readonly spec?: {
                readonly __typename: 'DerivedFieldSpec';
                readonly derivationType: GQLDerivedFieldDerivationType;
                readonly source:
                  | {
                      readonly __typename: 'DerivedFieldFieldSource';
                      readonly name: string;
                      readonly contentTypeId: string;
                    }
                  | {
                      readonly __typename: 'DerivedFieldFullItemSource';
                      readonly _?: boolean | null;
                    }
                  | {
                      readonly __typename: 'DerivedFieldProtegoInputSource';
                      readonly protegoInput: GQLProtegoInput;
                    };
              } | null;
            };
            readonly signal?: {
              readonly __typename: 'Signal';
              readonly id: string;
              readonly type: GQLSignalType;
              readonly name: string;
              readonly subcategory?: string | null;
              readonly args?:
                | { readonly __typename: 'AggregationSignalArgs' }
                | {
                    readonly __typename: 'Gpt4oMiniSignalArgs';
                    readonly policyId: string;
                  }
                | null;
            } | null;
            readonly matchingValues?: {
              readonly __typename: 'MatchingValues';
              readonly strings?: ReadonlyArray<string> | null;
              readonly textBankIds?: ReadonlyArray<string> | null;
              readonly locationBankIds?: ReadonlyArray<string> | null;
              readonly locations?: ReadonlyArray<{
                readonly __typename: 'LocationArea';
                readonly id: string;
                readonly name?: string | null;
                readonly geometry: {
                  readonly __typename: 'LocationGeometry';
                  readonly radius: number;
                  readonly center: {
                    readonly __typename: 'LatLng';
                    readonly lat: number;
                    readonly lng: number;
                  };
                };
                readonly bounds?: {
                  readonly __typename: 'PlaceBounds';
                  readonly northeastCorner: {
                    readonly __typename: 'LatLng';
                    readonly lat: number;
                    readonly lng: number;
                  };
                  readonly southwestCorner: {
                    readonly __typename: 'LatLng';
                    readonly lat: number;
                    readonly lng: number;
                  };
                } | null;
              }> | null;
            } | null;
          }
      >;
    };
    readonly actions: ReadonlyArray<
      | {
          readonly __typename: 'CustomerDefinedAction';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
      | {
          readonly __typename: 'EnqueueAuthorToMrtAction';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
      | {
          readonly __typename: 'EnqueueToMrtAction';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
      | {
          readonly __typename: 'EnqueueToNcmecAction';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
    >;
  } | null;
};

export type GQLReportingRuleFormOrgDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLReportingRuleFormOrgDataQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly policies: ReadonlyArray<{
      readonly __typename: 'Policy';
      readonly id: string;
      readonly name: string;
      readonly parentId?: string | null;
    }>;
    readonly itemTypes: ReadonlyArray<
      | {
          readonly __typename: 'ContentItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'ContentSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly parentId?: string | null;
            readonly threadId?: string | null;
            readonly createdAt?: string | null;
            readonly creatorId?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
      | {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'ThreadSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly creatorId?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
      | {
          readonly __typename: 'UserItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'UserSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly profileIcon?: string | null;
            readonly backgroundImage?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
    >;
    readonly signals: ReadonlyArray<{
      readonly __typename: 'Signal';
      readonly id: string;
      readonly type: GQLSignalType;
      readonly name: string;
      readonly integration?: GQLIntegration | null;
      readonly docsUrl?: string | null;
      readonly description: string;
      readonly eligibleInputs: ReadonlyArray<GQLSignalInputType>;
      readonly shouldPromptForMatchingValues: boolean;
      readonly recommendedThresholds?: {
        readonly __typename: 'RecommendedThresholds';
        readonly highPrecisionThreshold: string | number;
        readonly highRecallThreshold: string | number;
      } | null;
      readonly supportedLanguages:
        | { readonly __typename: 'AllLanguages'; readonly _?: boolean | null }
        | {
            readonly __typename: 'Languages';
            readonly languages: ReadonlyArray<GQLLanguage>;
          };
      readonly pricingStructure: {
        readonly __typename: 'SignalPricingStructure';
        readonly type: GQLSignalPricingStructureType;
      };
      readonly disabledInfo: {
        readonly __typename: 'DisabledInfo';
        readonly disabled: boolean;
        readonly disabledMessage?: string | null;
      };
      readonly outputType:
        | {
            readonly __typename: 'EnumSignalOutputType';
            readonly scalarType: GQLScalarType;
            readonly enum: ReadonlyArray<string>;
            readonly ordered: boolean;
          }
        | {
            readonly __typename: 'ScalarSignalOutputType';
            readonly scalarType: GQLScalarType;
          };
      readonly eligibleSubcategories: ReadonlyArray<{
        readonly __typename: 'SignalSubcategory';
        readonly id: string;
        readonly label: string;
        readonly description?: string | null;
        readonly childrenIds: ReadonlyArray<string>;
      }>;
    }>;
    readonly actions: ReadonlyArray<
      | {
          readonly __typename: 'CustomerDefinedAction';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
      | {
          readonly __typename: 'EnqueueAuthorToMrtAction';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
      | {
          readonly __typename: 'EnqueueToMrtAction';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
      | {
          readonly __typename: 'EnqueueToNcmecAction';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
    >;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly permissions: ReadonlyArray<GQLUserPermission>;
  } | null;
};

export type GQLDerivedFieldFieldsFragment = {
  readonly __typename: 'DerivedField';
  readonly type: GQLFieldType;
  readonly name: string;
  readonly container?: {
    readonly __typename: 'Container';
    readonly containerType: GQLContainerType;
    readonly keyScalarType?: GQLScalarType | null;
    readonly valueScalarType: GQLScalarType;
  } | null;
  readonly spec: {
    readonly __typename: 'DerivedFieldSpec';
    readonly derivationType: GQLDerivedFieldDerivationType;
    readonly source:
      | {
          readonly __typename: 'DerivedFieldFieldSource';
          readonly name: string;
          readonly contentTypeId: string;
        }
      | {
          readonly __typename: 'DerivedFieldFullItemSource';
          readonly _?: boolean | null;
        }
      | {
          readonly __typename: 'DerivedFieldProtegoInputSource';
          readonly protegoInput: GQLProtegoInput;
        };
  };
};

type GQLItemTypeFragmentContentItemTypeFragment = {
  readonly __typename: 'ContentItemType';
  readonly id: string;
  readonly name: string;
  readonly description?: string | null;
  readonly version: string;
  readonly schemaVariant: GQLItemTypeSchemaVariant;
  readonly hiddenFields: ReadonlyArray<string>;
  readonly schemaFieldRoles: {
    readonly __typename: 'ContentSchemaFieldRoles';
    readonly displayName?: string | null;
    readonly parentId?: string | null;
    readonly threadId?: string | null;
    readonly createdAt?: string | null;
    readonly creatorId?: string | null;
    readonly isDeleted?: string | null;
  };
  readonly baseFields: ReadonlyArray<{
    readonly __typename: 'BaseField';
    readonly name: string;
    readonly required: boolean;
    readonly type: GQLFieldType;
    readonly container?: {
      readonly __typename: 'Container';
      readonly containerType: GQLContainerType;
      readonly keyScalarType?: GQLScalarType | null;
      readonly valueScalarType: GQLScalarType;
    } | null;
  }>;
  readonly derivedFields: ReadonlyArray<{
    readonly __typename: 'DerivedField';
    readonly type: GQLFieldType;
    readonly name: string;
    readonly container?: {
      readonly __typename: 'Container';
      readonly containerType: GQLContainerType;
      readonly keyScalarType?: GQLScalarType | null;
      readonly valueScalarType: GQLScalarType;
    } | null;
    readonly spec: {
      readonly __typename: 'DerivedFieldSpec';
      readonly derivationType: GQLDerivedFieldDerivationType;
      readonly source:
        | {
            readonly __typename: 'DerivedFieldFieldSource';
            readonly name: string;
            readonly contentTypeId: string;
          }
        | {
            readonly __typename: 'DerivedFieldFullItemSource';
            readonly _?: boolean | null;
          }
        | {
            readonly __typename: 'DerivedFieldProtegoInputSource';
            readonly protegoInput: GQLProtegoInput;
          };
    };
  }>;
};

type GQLItemTypeFragmentThreadItemTypeFragment = {
  readonly __typename: 'ThreadItemType';
  readonly id: string;
  readonly name: string;
  readonly description?: string | null;
  readonly version: string;
  readonly schemaVariant: GQLItemTypeSchemaVariant;
  readonly hiddenFields: ReadonlyArray<string>;
  readonly schemaFieldRoles: {
    readonly __typename: 'ThreadSchemaFieldRoles';
    readonly displayName?: string | null;
    readonly createdAt?: string | null;
    readonly creatorId?: string | null;
    readonly isDeleted?: string | null;
  };
  readonly baseFields: ReadonlyArray<{
    readonly __typename: 'BaseField';
    readonly name: string;
    readonly required: boolean;
    readonly type: GQLFieldType;
    readonly container?: {
      readonly __typename: 'Container';
      readonly containerType: GQLContainerType;
      readonly keyScalarType?: GQLScalarType | null;
      readonly valueScalarType: GQLScalarType;
    } | null;
  }>;
  readonly derivedFields: ReadonlyArray<{
    readonly __typename: 'DerivedField';
    readonly type: GQLFieldType;
    readonly name: string;
    readonly container?: {
      readonly __typename: 'Container';
      readonly containerType: GQLContainerType;
      readonly keyScalarType?: GQLScalarType | null;
      readonly valueScalarType: GQLScalarType;
    } | null;
    readonly spec: {
      readonly __typename: 'DerivedFieldSpec';
      readonly derivationType: GQLDerivedFieldDerivationType;
      readonly source:
        | {
            readonly __typename: 'DerivedFieldFieldSource';
            readonly name: string;
            readonly contentTypeId: string;
          }
        | {
            readonly __typename: 'DerivedFieldFullItemSource';
            readonly _?: boolean | null;
          }
        | {
            readonly __typename: 'DerivedFieldProtegoInputSource';
            readonly protegoInput: GQLProtegoInput;
          };
    };
  }>;
};

type GQLItemTypeFragmentUserItemTypeFragment = {
  readonly __typename: 'UserItemType';
  readonly id: string;
  readonly name: string;
  readonly description?: string | null;
  readonly version: string;
  readonly schemaVariant: GQLItemTypeSchemaVariant;
  readonly hiddenFields: ReadonlyArray<string>;
  readonly schemaFieldRoles: {
    readonly __typename: 'UserSchemaFieldRoles';
    readonly displayName?: string | null;
    readonly createdAt?: string | null;
    readonly profileIcon?: string | null;
    readonly backgroundImage?: string | null;
    readonly isDeleted?: string | null;
  };
  readonly baseFields: ReadonlyArray<{
    readonly __typename: 'BaseField';
    readonly name: string;
    readonly required: boolean;
    readonly type: GQLFieldType;
    readonly container?: {
      readonly __typename: 'Container';
      readonly containerType: GQLContainerType;
      readonly keyScalarType?: GQLScalarType | null;
      readonly valueScalarType: GQLScalarType;
    } | null;
  }>;
  readonly derivedFields: ReadonlyArray<{
    readonly __typename: 'DerivedField';
    readonly type: GQLFieldType;
    readonly name: string;
    readonly container?: {
      readonly __typename: 'Container';
      readonly containerType: GQLContainerType;
      readonly keyScalarType?: GQLScalarType | null;
      readonly valueScalarType: GQLScalarType;
    } | null;
    readonly spec: {
      readonly __typename: 'DerivedFieldSpec';
      readonly derivationType: GQLDerivedFieldDerivationType;
      readonly source:
        | {
            readonly __typename: 'DerivedFieldFieldSource';
            readonly name: string;
            readonly contentTypeId: string;
          }
        | {
            readonly __typename: 'DerivedFieldFullItemSource';
            readonly _?: boolean | null;
          }
        | {
            readonly __typename: 'DerivedFieldProtegoInputSource';
            readonly protegoInput: GQLProtegoInput;
          };
    };
  }>;
};

export type GQLItemTypeFragmentFragment =
  | GQLItemTypeFragmentContentItemTypeFragment
  | GQLItemTypeFragmentThreadItemTypeFragment
  | GQLItemTypeFragmentUserItemTypeFragment;

export type GQLSignalsFragmentFragment = {
  readonly __typename: 'Signal';
  readonly id: string;
  readonly type: GQLSignalType;
  readonly name: string;
  readonly integration?: GQLIntegration | null;
  readonly docsUrl?: string | null;
  readonly description: string;
  readonly eligibleInputs: ReadonlyArray<GQLSignalInputType>;
  readonly shouldPromptForMatchingValues: boolean;
  readonly recommendedThresholds?: {
    readonly __typename: 'RecommendedThresholds';
    readonly highPrecisionThreshold: string | number;
    readonly highRecallThreshold: string | number;
  } | null;
  readonly supportedLanguages:
    | { readonly __typename: 'AllLanguages'; readonly _?: boolean | null }
    | {
        readonly __typename: 'Languages';
        readonly languages: ReadonlyArray<GQLLanguage>;
      };
  readonly pricingStructure: {
    readonly __typename: 'SignalPricingStructure';
    readonly type: GQLSignalPricingStructureType;
  };
  readonly disabledInfo: {
    readonly __typename: 'DisabledInfo';
    readonly disabled: boolean;
    readonly disabledMessage?: string | null;
  };
  readonly outputType:
    | {
        readonly __typename: 'EnumSignalOutputType';
        readonly scalarType: GQLScalarType;
        readonly enum: ReadonlyArray<string>;
        readonly ordered: boolean;
      }
    | {
        readonly __typename: 'ScalarSignalOutputType';
        readonly scalarType: GQLScalarType;
      };
  readonly eligibleSubcategories: ReadonlyArray<{
    readonly __typename: 'SignalSubcategory';
    readonly id: string;
    readonly label: string;
    readonly description?: string | null;
    readonly childrenIds: ReadonlyArray<string>;
  }>;
};

export type GQLLeafConditionFieldsFragment = {
  readonly __typename: 'LeafCondition';
  readonly comparator?: GQLValueComparator | null;
  readonly threshold?: string | number | null;
  readonly input: {
    readonly __typename: 'ConditionInputField';
    readonly type: GQLConditionInputInputType;
    readonly name?: string | null;
    readonly contentTypeId?: string | null;
    readonly contentTypeIds?: ReadonlyArray<string> | null;
    readonly spec?: {
      readonly __typename: 'DerivedFieldSpec';
      readonly derivationType: GQLDerivedFieldDerivationType;
      readonly source:
        | {
            readonly __typename: 'DerivedFieldFieldSource';
            readonly name: string;
            readonly contentTypeId: string;
          }
        | {
            readonly __typename: 'DerivedFieldFullItemSource';
            readonly _?: boolean | null;
          }
        | {
            readonly __typename: 'DerivedFieldProtegoInputSource';
            readonly protegoInput: GQLProtegoInput;
          };
    } | null;
  };
  readonly signal?: {
    readonly __typename: 'Signal';
    readonly id: string;
    readonly type: GQLSignalType;
    readonly name: string;
    readonly subcategory?: string | null;
    readonly args?:
      | { readonly __typename: 'AggregationSignalArgs' }
      | {
          readonly __typename: 'Gpt4oMiniSignalArgs';
          readonly policyId: string;
        }
      | null;
  } | null;
  readonly matchingValues?: {
    readonly __typename: 'MatchingValues';
    readonly strings?: ReadonlyArray<string> | null;
    readonly textBankIds?: ReadonlyArray<string> | null;
    readonly locationBankIds?: ReadonlyArray<string> | null;
    readonly locations?: ReadonlyArray<{
      readonly __typename: 'LocationArea';
      readonly id: string;
      readonly name?: string | null;
      readonly geometry: {
        readonly __typename: 'LocationGeometry';
        readonly radius: number;
        readonly center: {
          readonly __typename: 'LatLng';
          readonly lat: number;
          readonly lng: number;
        };
      };
      readonly bounds?: {
        readonly __typename: 'PlaceBounds';
        readonly northeastCorner: {
          readonly __typename: 'LatLng';
          readonly lat: number;
          readonly lng: number;
        };
        readonly southwestCorner: {
          readonly __typename: 'LatLng';
          readonly lat: number;
          readonly lng: number;
        };
      } | null;
    }> | null;
  } | null;
};

export type GQLConditionSetFieldsFragment = {
  readonly __typename: 'ConditionSet';
  readonly conjunction: GQLConditionConjunction;
  readonly conditions: ReadonlyArray<
    | {
        readonly __typename: 'ConditionSet';
        readonly conjunction: GQLConditionConjunction;
        readonly conditions: ReadonlyArray<
          | { readonly __typename: 'ConditionSet' }
          | {
              readonly __typename: 'LeafCondition';
              readonly comparator?: GQLValueComparator | null;
              readonly threshold?: string | number | null;
              readonly input: {
                readonly __typename: 'ConditionInputField';
                readonly type: GQLConditionInputInputType;
                readonly name?: string | null;
                readonly contentTypeId?: string | null;
                readonly contentTypeIds?: ReadonlyArray<string> | null;
                readonly spec?: {
                  readonly __typename: 'DerivedFieldSpec';
                  readonly derivationType: GQLDerivedFieldDerivationType;
                  readonly source:
                    | {
                        readonly __typename: 'DerivedFieldFieldSource';
                        readonly name: string;
                        readonly contentTypeId: string;
                      }
                    | {
                        readonly __typename: 'DerivedFieldFullItemSource';
                        readonly _?: boolean | null;
                      }
                    | {
                        readonly __typename: 'DerivedFieldProtegoInputSource';
                        readonly protegoInput: GQLProtegoInput;
                      };
                } | null;
              };
              readonly signal?: {
                readonly __typename: 'Signal';
                readonly id: string;
                readonly type: GQLSignalType;
                readonly name: string;
                readonly subcategory?: string | null;
                readonly args?:
                  | { readonly __typename: 'AggregationSignalArgs' }
                  | {
                      readonly __typename: 'Gpt4oMiniSignalArgs';
                      readonly policyId: string;
                    }
                  | null;
              } | null;
              readonly matchingValues?: {
                readonly __typename: 'MatchingValues';
                readonly strings?: ReadonlyArray<string> | null;
                readonly textBankIds?: ReadonlyArray<string> | null;
                readonly locationBankIds?: ReadonlyArray<string> | null;
                readonly locations?: ReadonlyArray<{
                  readonly __typename: 'LocationArea';
                  readonly id: string;
                  readonly name?: string | null;
                  readonly geometry: {
                    readonly __typename: 'LocationGeometry';
                    readonly radius: number;
                    readonly center: {
                      readonly __typename: 'LatLng';
                      readonly lat: number;
                      readonly lng: number;
                    };
                  };
                  readonly bounds?: {
                    readonly __typename: 'PlaceBounds';
                    readonly northeastCorner: {
                      readonly __typename: 'LatLng';
                      readonly lat: number;
                      readonly lng: number;
                    };
                    readonly southwestCorner: {
                      readonly __typename: 'LatLng';
                      readonly lat: number;
                      readonly lng: number;
                    };
                  } | null;
                }> | null;
              } | null;
            }
        >;
      }
    | {
        readonly __typename: 'LeafCondition';
        readonly comparator?: GQLValueComparator | null;
        readonly threshold?: string | number | null;
        readonly input: {
          readonly __typename: 'ConditionInputField';
          readonly type: GQLConditionInputInputType;
          readonly name?: string | null;
          readonly contentTypeId?: string | null;
          readonly contentTypeIds?: ReadonlyArray<string> | null;
          readonly spec?: {
            readonly __typename: 'DerivedFieldSpec';
            readonly derivationType: GQLDerivedFieldDerivationType;
            readonly source:
              | {
                  readonly __typename: 'DerivedFieldFieldSource';
                  readonly name: string;
                  readonly contentTypeId: string;
                }
              | {
                  readonly __typename: 'DerivedFieldFullItemSource';
                  readonly _?: boolean | null;
                }
              | {
                  readonly __typename: 'DerivedFieldProtegoInputSource';
                  readonly protegoInput: GQLProtegoInput;
                };
          } | null;
        };
        readonly signal?: {
          readonly __typename: 'Signal';
          readonly id: string;
          readonly type: GQLSignalType;
          readonly name: string;
          readonly subcategory?: string | null;
          readonly args?:
            | { readonly __typename: 'AggregationSignalArgs' }
            | {
                readonly __typename: 'Gpt4oMiniSignalArgs';
                readonly policyId: string;
              }
            | null;
        } | null;
        readonly matchingValues?: {
          readonly __typename: 'MatchingValues';
          readonly strings?: ReadonlyArray<string> | null;
          readonly textBankIds?: ReadonlyArray<string> | null;
          readonly locationBankIds?: ReadonlyArray<string> | null;
          readonly locations?: ReadonlyArray<{
            readonly __typename: 'LocationArea';
            readonly id: string;
            readonly name?: string | null;
            readonly geometry: {
              readonly __typename: 'LocationGeometry';
              readonly radius: number;
              readonly center: {
                readonly __typename: 'LatLng';
                readonly lat: number;
                readonly lng: number;
              };
            };
            readonly bounds?: {
              readonly __typename: 'PlaceBounds';
              readonly northeastCorner: {
                readonly __typename: 'LatLng';
                readonly lat: number;
                readonly lng: number;
              };
              readonly southwestCorner: {
                readonly __typename: 'LatLng';
                readonly lat: number;
                readonly lng: number;
              };
            } | null;
          }> | null;
        } | null;
      }
  >;
};

type GQLRuleFormRuleFieldsFragmentContentRuleFragment = {
  readonly __typename: 'ContentRule';
  readonly id: string;
  readonly name: string;
  readonly description?: string | null;
  readonly status: GQLRuleStatus;
  readonly tags?: ReadonlyArray<string | null> | null;
  readonly maxDailyActions?: number | null;
  readonly expirationTime?: string | null;
  readonly policies: ReadonlyArray<{
    readonly __typename: 'Policy';
    readonly id: string;
  }>;
  readonly conditionSet: {
    readonly __typename: 'ConditionSet';
    readonly conjunction: GQLConditionConjunction;
    readonly conditions: ReadonlyArray<
      | {
          readonly __typename: 'ConditionSet';
          readonly conjunction: GQLConditionConjunction;
          readonly conditions: ReadonlyArray<
            | { readonly __typename: 'ConditionSet' }
            | {
                readonly __typename: 'LeafCondition';
                readonly comparator?: GQLValueComparator | null;
                readonly threshold?: string | number | null;
                readonly input: {
                  readonly __typename: 'ConditionInputField';
                  readonly type: GQLConditionInputInputType;
                  readonly name?: string | null;
                  readonly contentTypeId?: string | null;
                  readonly contentTypeIds?: ReadonlyArray<string> | null;
                  readonly spec?: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  } | null;
                };
                readonly signal?: {
                  readonly __typename: 'Signal';
                  readonly id: string;
                  readonly type: GQLSignalType;
                  readonly name: string;
                  readonly subcategory?: string | null;
                  readonly args?:
                    | { readonly __typename: 'AggregationSignalArgs' }
                    | {
                        readonly __typename: 'Gpt4oMiniSignalArgs';
                        readonly policyId: string;
                      }
                    | null;
                } | null;
                readonly matchingValues?: {
                  readonly __typename: 'MatchingValues';
                  readonly strings?: ReadonlyArray<string> | null;
                  readonly textBankIds?: ReadonlyArray<string> | null;
                  readonly locationBankIds?: ReadonlyArray<string> | null;
                  readonly locations?: ReadonlyArray<{
                    readonly __typename: 'LocationArea';
                    readonly id: string;
                    readonly name?: string | null;
                    readonly geometry: {
                      readonly __typename: 'LocationGeometry';
                      readonly radius: number;
                      readonly center: {
                        readonly __typename: 'LatLng';
                        readonly lat: number;
                        readonly lng: number;
                      };
                    };
                    readonly bounds?: {
                      readonly __typename: 'PlaceBounds';
                      readonly northeastCorner: {
                        readonly __typename: 'LatLng';
                        readonly lat: number;
                        readonly lng: number;
                      };
                      readonly southwestCorner: {
                        readonly __typename: 'LatLng';
                        readonly lat: number;
                        readonly lng: number;
                      };
                    } | null;
                  }> | null;
                } | null;
              }
          >;
        }
      | {
          readonly __typename: 'LeafCondition';
          readonly comparator?: GQLValueComparator | null;
          readonly threshold?: string | number | null;
          readonly input: {
            readonly __typename: 'ConditionInputField';
            readonly type: GQLConditionInputInputType;
            readonly name?: string | null;
            readonly contentTypeId?: string | null;
            readonly contentTypeIds?: ReadonlyArray<string> | null;
            readonly spec?: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            } | null;
          };
          readonly signal?: {
            readonly __typename: 'Signal';
            readonly id: string;
            readonly type: GQLSignalType;
            readonly name: string;
            readonly subcategory?: string | null;
            readonly args?:
              | { readonly __typename: 'AggregationSignalArgs' }
              | {
                  readonly __typename: 'Gpt4oMiniSignalArgs';
                  readonly policyId: string;
                }
              | null;
          } | null;
          readonly matchingValues?: {
            readonly __typename: 'MatchingValues';
            readonly strings?: ReadonlyArray<string> | null;
            readonly textBankIds?: ReadonlyArray<string> | null;
            readonly locationBankIds?: ReadonlyArray<string> | null;
            readonly locations?: ReadonlyArray<{
              readonly __typename: 'LocationArea';
              readonly id: string;
              readonly name?: string | null;
              readonly geometry: {
                readonly __typename: 'LocationGeometry';
                readonly radius: number;
                readonly center: {
                  readonly __typename: 'LatLng';
                  readonly lat: number;
                  readonly lng: number;
                };
              };
              readonly bounds?: {
                readonly __typename: 'PlaceBounds';
                readonly northeastCorner: {
                  readonly __typename: 'LatLng';
                  readonly lat: number;
                  readonly lng: number;
                };
                readonly southwestCorner: {
                  readonly __typename: 'LatLng';
                  readonly lat: number;
                  readonly lng: number;
                };
              } | null;
            }> | null;
          } | null;
        }
    >;
  };
  readonly actions: ReadonlyArray<
    | {
        readonly __typename: 'CustomerDefinedAction';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly itemTypes: ReadonlyArray<
          | {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'ThreadItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'UserItemType';
              readonly id: string;
              readonly name: string;
            }
        >;
      }
    | {
        readonly __typename: 'EnqueueAuthorToMrtAction';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly itemTypes: ReadonlyArray<
          | {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'ThreadItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'UserItemType';
              readonly id: string;
              readonly name: string;
            }
        >;
      }
    | {
        readonly __typename: 'EnqueueToMrtAction';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly itemTypes: ReadonlyArray<
          | {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'ThreadItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'UserItemType';
              readonly id: string;
              readonly name: string;
            }
        >;
      }
    | {
        readonly __typename: 'EnqueueToNcmecAction';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly itemTypes: ReadonlyArray<
          | {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'ThreadItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'UserItemType';
              readonly id: string;
              readonly name: string;
            }
        >;
      }
  >;
};

type GQLRuleFormRuleFieldsFragmentUserRuleFragment = {
  readonly __typename: 'UserRule';
  readonly id: string;
  readonly name: string;
  readonly description?: string | null;
  readonly status: GQLRuleStatus;
  readonly tags?: ReadonlyArray<string | null> | null;
  readonly maxDailyActions?: number | null;
  readonly expirationTime?: string | null;
  readonly policies: ReadonlyArray<{
    readonly __typename: 'Policy';
    readonly id: string;
  }>;
  readonly conditionSet: {
    readonly __typename: 'ConditionSet';
    readonly conjunction: GQLConditionConjunction;
    readonly conditions: ReadonlyArray<
      | {
          readonly __typename: 'ConditionSet';
          readonly conjunction: GQLConditionConjunction;
          readonly conditions: ReadonlyArray<
            | { readonly __typename: 'ConditionSet' }
            | {
                readonly __typename: 'LeafCondition';
                readonly comparator?: GQLValueComparator | null;
                readonly threshold?: string | number | null;
                readonly input: {
                  readonly __typename: 'ConditionInputField';
                  readonly type: GQLConditionInputInputType;
                  readonly name?: string | null;
                  readonly contentTypeId?: string | null;
                  readonly contentTypeIds?: ReadonlyArray<string> | null;
                  readonly spec?: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  } | null;
                };
                readonly signal?: {
                  readonly __typename: 'Signal';
                  readonly id: string;
                  readonly type: GQLSignalType;
                  readonly name: string;
                  readonly subcategory?: string | null;
                  readonly args?:
                    | { readonly __typename: 'AggregationSignalArgs' }
                    | {
                        readonly __typename: 'Gpt4oMiniSignalArgs';
                        readonly policyId: string;
                      }
                    | null;
                } | null;
                readonly matchingValues?: {
                  readonly __typename: 'MatchingValues';
                  readonly strings?: ReadonlyArray<string> | null;
                  readonly textBankIds?: ReadonlyArray<string> | null;
                  readonly locationBankIds?: ReadonlyArray<string> | null;
                  readonly locations?: ReadonlyArray<{
                    readonly __typename: 'LocationArea';
                    readonly id: string;
                    readonly name?: string | null;
                    readonly geometry: {
                      readonly __typename: 'LocationGeometry';
                      readonly radius: number;
                      readonly center: {
                        readonly __typename: 'LatLng';
                        readonly lat: number;
                        readonly lng: number;
                      };
                    };
                    readonly bounds?: {
                      readonly __typename: 'PlaceBounds';
                      readonly northeastCorner: {
                        readonly __typename: 'LatLng';
                        readonly lat: number;
                        readonly lng: number;
                      };
                      readonly southwestCorner: {
                        readonly __typename: 'LatLng';
                        readonly lat: number;
                        readonly lng: number;
                      };
                    } | null;
                  }> | null;
                } | null;
              }
          >;
        }
      | {
          readonly __typename: 'LeafCondition';
          readonly comparator?: GQLValueComparator | null;
          readonly threshold?: string | number | null;
          readonly input: {
            readonly __typename: 'ConditionInputField';
            readonly type: GQLConditionInputInputType;
            readonly name?: string | null;
            readonly contentTypeId?: string | null;
            readonly contentTypeIds?: ReadonlyArray<string> | null;
            readonly spec?: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            } | null;
          };
          readonly signal?: {
            readonly __typename: 'Signal';
            readonly id: string;
            readonly type: GQLSignalType;
            readonly name: string;
            readonly subcategory?: string | null;
            readonly args?:
              | { readonly __typename: 'AggregationSignalArgs' }
              | {
                  readonly __typename: 'Gpt4oMiniSignalArgs';
                  readonly policyId: string;
                }
              | null;
          } | null;
          readonly matchingValues?: {
            readonly __typename: 'MatchingValues';
            readonly strings?: ReadonlyArray<string> | null;
            readonly textBankIds?: ReadonlyArray<string> | null;
            readonly locationBankIds?: ReadonlyArray<string> | null;
            readonly locations?: ReadonlyArray<{
              readonly __typename: 'LocationArea';
              readonly id: string;
              readonly name?: string | null;
              readonly geometry: {
                readonly __typename: 'LocationGeometry';
                readonly radius: number;
                readonly center: {
                  readonly __typename: 'LatLng';
                  readonly lat: number;
                  readonly lng: number;
                };
              };
              readonly bounds?: {
                readonly __typename: 'PlaceBounds';
                readonly northeastCorner: {
                  readonly __typename: 'LatLng';
                  readonly lat: number;
                  readonly lng: number;
                };
                readonly southwestCorner: {
                  readonly __typename: 'LatLng';
                  readonly lat: number;
                  readonly lng: number;
                };
              } | null;
            }> | null;
          } | null;
        }
    >;
  };
  readonly actions: ReadonlyArray<
    | {
        readonly __typename: 'CustomerDefinedAction';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly itemTypes: ReadonlyArray<
          | {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'ThreadItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'UserItemType';
              readonly id: string;
              readonly name: string;
            }
        >;
      }
    | {
        readonly __typename: 'EnqueueAuthorToMrtAction';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly itemTypes: ReadonlyArray<
          | {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'ThreadItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'UserItemType';
              readonly id: string;
              readonly name: string;
            }
        >;
      }
    | {
        readonly __typename: 'EnqueueToMrtAction';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly itemTypes: ReadonlyArray<
          | {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'ThreadItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'UserItemType';
              readonly id: string;
              readonly name: string;
            }
        >;
      }
    | {
        readonly __typename: 'EnqueueToNcmecAction';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly itemTypes: ReadonlyArray<
          | {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'ThreadItemType';
              readonly id: string;
              readonly name: string;
            }
          | {
              readonly __typename: 'UserItemType';
              readonly id: string;
              readonly name: string;
            }
        >;
      }
  >;
};

export type GQLRuleFormRuleFieldsFragmentFragment =
  | GQLRuleFormRuleFieldsFragmentContentRuleFragment
  | GQLRuleFormRuleFieldsFragmentUserRuleFragment;

export type GQLRuleQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLRuleQuery = {
  readonly __typename: 'Query';
  readonly rule?:
    | {
        readonly __typename: 'ContentRule';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly status: GQLRuleStatus;
        readonly tags?: ReadonlyArray<string | null> | null;
        readonly maxDailyActions?: number | null;
        readonly expirationTime?: string | null;
        readonly itemTypes: ReadonlyArray<
          | {
              readonly __typename: 'ContentItemType';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly version: string;
              readonly schemaVariant: GQLItemTypeSchemaVariant;
              readonly hiddenFields: ReadonlyArray<string>;
              readonly schemaFieldRoles: {
                readonly __typename: 'ContentSchemaFieldRoles';
                readonly displayName?: string | null;
                readonly parentId?: string | null;
                readonly threadId?: string | null;
                readonly createdAt?: string | null;
                readonly creatorId?: string | null;
                readonly isDeleted?: string | null;
              };
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly required: boolean;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly type: GQLFieldType;
                readonly name: string;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
                readonly spec: {
                  readonly __typename: 'DerivedFieldSpec';
                  readonly derivationType: GQLDerivedFieldDerivationType;
                  readonly source:
                    | {
                        readonly __typename: 'DerivedFieldFieldSource';
                        readonly name: string;
                        readonly contentTypeId: string;
                      }
                    | {
                        readonly __typename: 'DerivedFieldFullItemSource';
                        readonly _?: boolean | null;
                      }
                    | {
                        readonly __typename: 'DerivedFieldProtegoInputSource';
                        readonly protegoInput: GQLProtegoInput;
                      };
                };
              }>;
            }
          | {
              readonly __typename: 'ThreadItemType';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly version: string;
              readonly schemaVariant: GQLItemTypeSchemaVariant;
              readonly hiddenFields: ReadonlyArray<string>;
              readonly schemaFieldRoles: {
                readonly __typename: 'ThreadSchemaFieldRoles';
                readonly displayName?: string | null;
                readonly createdAt?: string | null;
                readonly creatorId?: string | null;
                readonly isDeleted?: string | null;
              };
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly required: boolean;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly type: GQLFieldType;
                readonly name: string;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
                readonly spec: {
                  readonly __typename: 'DerivedFieldSpec';
                  readonly derivationType: GQLDerivedFieldDerivationType;
                  readonly source:
                    | {
                        readonly __typename: 'DerivedFieldFieldSource';
                        readonly name: string;
                        readonly contentTypeId: string;
                      }
                    | {
                        readonly __typename: 'DerivedFieldFullItemSource';
                        readonly _?: boolean | null;
                      }
                    | {
                        readonly __typename: 'DerivedFieldProtegoInputSource';
                        readonly protegoInput: GQLProtegoInput;
                      };
                };
              }>;
            }
          | {
              readonly __typename: 'UserItemType';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly version: string;
              readonly schemaVariant: GQLItemTypeSchemaVariant;
              readonly hiddenFields: ReadonlyArray<string>;
              readonly schemaFieldRoles: {
                readonly __typename: 'UserSchemaFieldRoles';
                readonly displayName?: string | null;
                readonly createdAt?: string | null;
                readonly profileIcon?: string | null;
                readonly backgroundImage?: string | null;
                readonly isDeleted?: string | null;
              };
              readonly baseFields: ReadonlyArray<{
                readonly __typename: 'BaseField';
                readonly name: string;
                readonly required: boolean;
                readonly type: GQLFieldType;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
              }>;
              readonly derivedFields: ReadonlyArray<{
                readonly __typename: 'DerivedField';
                readonly type: GQLFieldType;
                readonly name: string;
                readonly container?: {
                  readonly __typename: 'Container';
                  readonly containerType: GQLContainerType;
                  readonly keyScalarType?: GQLScalarType | null;
                  readonly valueScalarType: GQLScalarType;
                } | null;
                readonly spec: {
                  readonly __typename: 'DerivedFieldSpec';
                  readonly derivationType: GQLDerivedFieldDerivationType;
                  readonly source:
                    | {
                        readonly __typename: 'DerivedFieldFieldSource';
                        readonly name: string;
                        readonly contentTypeId: string;
                      }
                    | {
                        readonly __typename: 'DerivedFieldFullItemSource';
                        readonly _?: boolean | null;
                      }
                    | {
                        readonly __typename: 'DerivedFieldProtegoInputSource';
                        readonly protegoInput: GQLProtegoInput;
                      };
                };
              }>;
            }
        >;
        readonly policies: ReadonlyArray<{
          readonly __typename: 'Policy';
          readonly id: string;
        }>;
        readonly conditionSet: {
          readonly __typename: 'ConditionSet';
          readonly conjunction: GQLConditionConjunction;
          readonly conditions: ReadonlyArray<
            | {
                readonly __typename: 'ConditionSet';
                readonly conjunction: GQLConditionConjunction;
                readonly conditions: ReadonlyArray<
                  | { readonly __typename: 'ConditionSet' }
                  | {
                      readonly __typename: 'LeafCondition';
                      readonly comparator?: GQLValueComparator | null;
                      readonly threshold?: string | number | null;
                      readonly input: {
                        readonly __typename: 'ConditionInputField';
                        readonly type: GQLConditionInputInputType;
                        readonly name?: string | null;
                        readonly contentTypeId?: string | null;
                        readonly contentTypeIds?: ReadonlyArray<string> | null;
                        readonly spec?: {
                          readonly __typename: 'DerivedFieldSpec';
                          readonly derivationType: GQLDerivedFieldDerivationType;
                          readonly source:
                            | {
                                readonly __typename: 'DerivedFieldFieldSource';
                                readonly name: string;
                                readonly contentTypeId: string;
                              }
                            | {
                                readonly __typename: 'DerivedFieldFullItemSource';
                                readonly _?: boolean | null;
                              }
                            | {
                                readonly __typename: 'DerivedFieldProtegoInputSource';
                                readonly protegoInput: GQLProtegoInput;
                              };
                        } | null;
                      };
                      readonly signal?: {
                        readonly __typename: 'Signal';
                        readonly id: string;
                        readonly type: GQLSignalType;
                        readonly name: string;
                        readonly subcategory?: string | null;
                        readonly args?:
                          | { readonly __typename: 'AggregationSignalArgs' }
                          | {
                              readonly __typename: 'Gpt4oMiniSignalArgs';
                              readonly policyId: string;
                            }
                          | null;
                      } | null;
                      readonly matchingValues?: {
                        readonly __typename: 'MatchingValues';
                        readonly strings?: ReadonlyArray<string> | null;
                        readonly textBankIds?: ReadonlyArray<string> | null;
                        readonly locationBankIds?: ReadonlyArray<string> | null;
                        readonly locations?: ReadonlyArray<{
                          readonly __typename: 'LocationArea';
                          readonly id: string;
                          readonly name?: string | null;
                          readonly geometry: {
                            readonly __typename: 'LocationGeometry';
                            readonly radius: number;
                            readonly center: {
                              readonly __typename: 'LatLng';
                              readonly lat: number;
                              readonly lng: number;
                            };
                          };
                          readonly bounds?: {
                            readonly __typename: 'PlaceBounds';
                            readonly northeastCorner: {
                              readonly __typename: 'LatLng';
                              readonly lat: number;
                              readonly lng: number;
                            };
                            readonly southwestCorner: {
                              readonly __typename: 'LatLng';
                              readonly lat: number;
                              readonly lng: number;
                            };
                          } | null;
                        }> | null;
                      } | null;
                    }
                >;
              }
            | {
                readonly __typename: 'LeafCondition';
                readonly comparator?: GQLValueComparator | null;
                readonly threshold?: string | number | null;
                readonly input: {
                  readonly __typename: 'ConditionInputField';
                  readonly type: GQLConditionInputInputType;
                  readonly name?: string | null;
                  readonly contentTypeId?: string | null;
                  readonly contentTypeIds?: ReadonlyArray<string> | null;
                  readonly spec?: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  } | null;
                };
                readonly signal?: {
                  readonly __typename: 'Signal';
                  readonly id: string;
                  readonly type: GQLSignalType;
                  readonly name: string;
                  readonly subcategory?: string | null;
                  readonly args?:
                    | { readonly __typename: 'AggregationSignalArgs' }
                    | {
                        readonly __typename: 'Gpt4oMiniSignalArgs';
                        readonly policyId: string;
                      }
                    | null;
                } | null;
                readonly matchingValues?: {
                  readonly __typename: 'MatchingValues';
                  readonly strings?: ReadonlyArray<string> | null;
                  readonly textBankIds?: ReadonlyArray<string> | null;
                  readonly locationBankIds?: ReadonlyArray<string> | null;
                  readonly locations?: ReadonlyArray<{
                    readonly __typename: 'LocationArea';
                    readonly id: string;
                    readonly name?: string | null;
                    readonly geometry: {
                      readonly __typename: 'LocationGeometry';
                      readonly radius: number;
                      readonly center: {
                        readonly __typename: 'LatLng';
                        readonly lat: number;
                        readonly lng: number;
                      };
                    };
                    readonly bounds?: {
                      readonly __typename: 'PlaceBounds';
                      readonly northeastCorner: {
                        readonly __typename: 'LatLng';
                        readonly lat: number;
                        readonly lng: number;
                      };
                      readonly southwestCorner: {
                        readonly __typename: 'LatLng';
                        readonly lat: number;
                        readonly lng: number;
                      };
                    } | null;
                  }> | null;
                } | null;
              }
          >;
        };
        readonly actions: ReadonlyArray<
          | {
              readonly __typename: 'CustomerDefinedAction';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly itemTypes: ReadonlyArray<
                | {
                    readonly __typename: 'ContentItemType';
                    readonly id: string;
                    readonly name: string;
                  }
                | {
                    readonly __typename: 'ThreadItemType';
                    readonly id: string;
                    readonly name: string;
                  }
                | {
                    readonly __typename: 'UserItemType';
                    readonly id: string;
                    readonly name: string;
                  }
              >;
            }
          | {
              readonly __typename: 'EnqueueAuthorToMrtAction';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly itemTypes: ReadonlyArray<
                | {
                    readonly __typename: 'ContentItemType';
                    readonly id: string;
                    readonly name: string;
                  }
                | {
                    readonly __typename: 'ThreadItemType';
                    readonly id: string;
                    readonly name: string;
                  }
                | {
                    readonly __typename: 'UserItemType';
                    readonly id: string;
                    readonly name: string;
                  }
              >;
            }
          | {
              readonly __typename: 'EnqueueToMrtAction';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly itemTypes: ReadonlyArray<
                | {
                    readonly __typename: 'ContentItemType';
                    readonly id: string;
                    readonly name: string;
                  }
                | {
                    readonly __typename: 'ThreadItemType';
                    readonly id: string;
                    readonly name: string;
                  }
                | {
                    readonly __typename: 'UserItemType';
                    readonly id: string;
                    readonly name: string;
                  }
              >;
            }
          | {
              readonly __typename: 'EnqueueToNcmecAction';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly itemTypes: ReadonlyArray<
                | {
                    readonly __typename: 'ContentItemType';
                    readonly id: string;
                    readonly name: string;
                  }
                | {
                    readonly __typename: 'ThreadItemType';
                    readonly id: string;
                    readonly name: string;
                  }
                | {
                    readonly __typename: 'UserItemType';
                    readonly id: string;
                    readonly name: string;
                  }
              >;
            }
        >;
      }
    | {
        readonly __typename: 'UserRule';
        readonly id: string;
        readonly name: string;
        readonly description?: string | null;
        readonly status: GQLRuleStatus;
        readonly tags?: ReadonlyArray<string | null> | null;
        readonly maxDailyActions?: number | null;
        readonly expirationTime?: string | null;
        readonly policies: ReadonlyArray<{
          readonly __typename: 'Policy';
          readonly id: string;
        }>;
        readonly conditionSet: {
          readonly __typename: 'ConditionSet';
          readonly conjunction: GQLConditionConjunction;
          readonly conditions: ReadonlyArray<
            | {
                readonly __typename: 'ConditionSet';
                readonly conjunction: GQLConditionConjunction;
                readonly conditions: ReadonlyArray<
                  | { readonly __typename: 'ConditionSet' }
                  | {
                      readonly __typename: 'LeafCondition';
                      readonly comparator?: GQLValueComparator | null;
                      readonly threshold?: string | number | null;
                      readonly input: {
                        readonly __typename: 'ConditionInputField';
                        readonly type: GQLConditionInputInputType;
                        readonly name?: string | null;
                        readonly contentTypeId?: string | null;
                        readonly contentTypeIds?: ReadonlyArray<string> | null;
                        readonly spec?: {
                          readonly __typename: 'DerivedFieldSpec';
                          readonly derivationType: GQLDerivedFieldDerivationType;
                          readonly source:
                            | {
                                readonly __typename: 'DerivedFieldFieldSource';
                                readonly name: string;
                                readonly contentTypeId: string;
                              }
                            | {
                                readonly __typename: 'DerivedFieldFullItemSource';
                                readonly _?: boolean | null;
                              }
                            | {
                                readonly __typename: 'DerivedFieldProtegoInputSource';
                                readonly protegoInput: GQLProtegoInput;
                              };
                        } | null;
                      };
                      readonly signal?: {
                        readonly __typename: 'Signal';
                        readonly id: string;
                        readonly type: GQLSignalType;
                        readonly name: string;
                        readonly subcategory?: string | null;
                        readonly args?:
                          | { readonly __typename: 'AggregationSignalArgs' }
                          | {
                              readonly __typename: 'Gpt4oMiniSignalArgs';
                              readonly policyId: string;
                            }
                          | null;
                      } | null;
                      readonly matchingValues?: {
                        readonly __typename: 'MatchingValues';
                        readonly strings?: ReadonlyArray<string> | null;
                        readonly textBankIds?: ReadonlyArray<string> | null;
                        readonly locationBankIds?: ReadonlyArray<string> | null;
                        readonly locations?: ReadonlyArray<{
                          readonly __typename: 'LocationArea';
                          readonly id: string;
                          readonly name?: string | null;
                          readonly geometry: {
                            readonly __typename: 'LocationGeometry';
                            readonly radius: number;
                            readonly center: {
                              readonly __typename: 'LatLng';
                              readonly lat: number;
                              readonly lng: number;
                            };
                          };
                          readonly bounds?: {
                            readonly __typename: 'PlaceBounds';
                            readonly northeastCorner: {
                              readonly __typename: 'LatLng';
                              readonly lat: number;
                              readonly lng: number;
                            };
                            readonly southwestCorner: {
                              readonly __typename: 'LatLng';
                              readonly lat: number;
                              readonly lng: number;
                            };
                          } | null;
                        }> | null;
                      } | null;
                    }
                >;
              }
            | {
                readonly __typename: 'LeafCondition';
                readonly comparator?: GQLValueComparator | null;
                readonly threshold?: string | number | null;
                readonly input: {
                  readonly __typename: 'ConditionInputField';
                  readonly type: GQLConditionInputInputType;
                  readonly name?: string | null;
                  readonly contentTypeId?: string | null;
                  readonly contentTypeIds?: ReadonlyArray<string> | null;
                  readonly spec?: {
                    readonly __typename: 'DerivedFieldSpec';
                    readonly derivationType: GQLDerivedFieldDerivationType;
                    readonly source:
                      | {
                          readonly __typename: 'DerivedFieldFieldSource';
                          readonly name: string;
                          readonly contentTypeId: string;
                        }
                      | {
                          readonly __typename: 'DerivedFieldFullItemSource';
                          readonly _?: boolean | null;
                        }
                      | {
                          readonly __typename: 'DerivedFieldProtegoInputSource';
                          readonly protegoInput: GQLProtegoInput;
                        };
                  } | null;
                };
                readonly signal?: {
                  readonly __typename: 'Signal';
                  readonly id: string;
                  readonly type: GQLSignalType;
                  readonly name: string;
                  readonly subcategory?: string | null;
                  readonly args?:
                    | { readonly __typename: 'AggregationSignalArgs' }
                    | {
                        readonly __typename: 'Gpt4oMiniSignalArgs';
                        readonly policyId: string;
                      }
                    | null;
                } | null;
                readonly matchingValues?: {
                  readonly __typename: 'MatchingValues';
                  readonly strings?: ReadonlyArray<string> | null;
                  readonly textBankIds?: ReadonlyArray<string> | null;
                  readonly locationBankIds?: ReadonlyArray<string> | null;
                  readonly locations?: ReadonlyArray<{
                    readonly __typename: 'LocationArea';
                    readonly id: string;
                    readonly name?: string | null;
                    readonly geometry: {
                      readonly __typename: 'LocationGeometry';
                      readonly radius: number;
                      readonly center: {
                        readonly __typename: 'LatLng';
                        readonly lat: number;
                        readonly lng: number;
                      };
                    };
                    readonly bounds?: {
                      readonly __typename: 'PlaceBounds';
                      readonly northeastCorner: {
                        readonly __typename: 'LatLng';
                        readonly lat: number;
                        readonly lng: number;
                      };
                      readonly southwestCorner: {
                        readonly __typename: 'LatLng';
                        readonly lat: number;
                        readonly lng: number;
                      };
                    } | null;
                  }> | null;
                } | null;
              }
          >;
        };
        readonly actions: ReadonlyArray<
          | {
              readonly __typename: 'CustomerDefinedAction';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly itemTypes: ReadonlyArray<
                | {
                    readonly __typename: 'ContentItemType';
                    readonly id: string;
                    readonly name: string;
                  }
                | {
                    readonly __typename: 'ThreadItemType';
                    readonly id: string;
                    readonly name: string;
                  }
                | {
                    readonly __typename: 'UserItemType';
                    readonly id: string;
                    readonly name: string;
                  }
              >;
            }
          | {
              readonly __typename: 'EnqueueAuthorToMrtAction';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly itemTypes: ReadonlyArray<
                | {
                    readonly __typename: 'ContentItemType';
                    readonly id: string;
                    readonly name: string;
                  }
                | {
                    readonly __typename: 'ThreadItemType';
                    readonly id: string;
                    readonly name: string;
                  }
                | {
                    readonly __typename: 'UserItemType';
                    readonly id: string;
                    readonly name: string;
                  }
              >;
            }
          | {
              readonly __typename: 'EnqueueToMrtAction';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly itemTypes: ReadonlyArray<
                | {
                    readonly __typename: 'ContentItemType';
                    readonly id: string;
                    readonly name: string;
                  }
                | {
                    readonly __typename: 'ThreadItemType';
                    readonly id: string;
                    readonly name: string;
                  }
                | {
                    readonly __typename: 'UserItemType';
                    readonly id: string;
                    readonly name: string;
                  }
              >;
            }
          | {
              readonly __typename: 'EnqueueToNcmecAction';
              readonly id: string;
              readonly name: string;
              readonly description?: string | null;
              readonly itemTypes: ReadonlyArray<
                | {
                    readonly __typename: 'ContentItemType';
                    readonly id: string;
                    readonly name: string;
                  }
                | {
                    readonly __typename: 'ThreadItemType';
                    readonly id: string;
                    readonly name: string;
                  }
                | {
                    readonly __typename: 'UserItemType';
                    readonly id: string;
                    readonly name: string;
                  }
              >;
            }
        >;
      }
    | null;
};

type GQLActionFragmentCustomerDefinedActionFragment = {
  readonly __typename: 'CustomerDefinedAction';
  readonly id: string;
  readonly name: string;
  readonly description?: string | null;
  readonly itemTypes: ReadonlyArray<
    | {
        readonly __typename: 'ContentItemType';
        readonly id: string;
        readonly name: string;
      }
    | {
        readonly __typename: 'ThreadItemType';
        readonly id: string;
        readonly name: string;
      }
    | {
        readonly __typename: 'UserItemType';
        readonly id: string;
        readonly name: string;
      }
  >;
};

type GQLActionFragmentEnqueueAuthorToMrtActionFragment = {
  readonly __typename: 'EnqueueAuthorToMrtAction';
  readonly id: string;
  readonly name: string;
  readonly description?: string | null;
  readonly itemTypes: ReadonlyArray<
    | {
        readonly __typename: 'ContentItemType';
        readonly id: string;
        readonly name: string;
      }
    | {
        readonly __typename: 'ThreadItemType';
        readonly id: string;
        readonly name: string;
      }
    | {
        readonly __typename: 'UserItemType';
        readonly id: string;
        readonly name: string;
      }
  >;
};

type GQLActionFragmentEnqueueToMrtActionFragment = {
  readonly __typename: 'EnqueueToMrtAction';
  readonly id: string;
  readonly name: string;
  readonly description?: string | null;
  readonly itemTypes: ReadonlyArray<
    | {
        readonly __typename: 'ContentItemType';
        readonly id: string;
        readonly name: string;
      }
    | {
        readonly __typename: 'ThreadItemType';
        readonly id: string;
        readonly name: string;
      }
    | {
        readonly __typename: 'UserItemType';
        readonly id: string;
        readonly name: string;
      }
  >;
};

type GQLActionFragmentEnqueueToNcmecActionFragment = {
  readonly __typename: 'EnqueueToNcmecAction';
  readonly id: string;
  readonly name: string;
  readonly description?: string | null;
  readonly itemTypes: ReadonlyArray<
    | {
        readonly __typename: 'ContentItemType';
        readonly id: string;
        readonly name: string;
      }
    | {
        readonly __typename: 'ThreadItemType';
        readonly id: string;
        readonly name: string;
      }
    | {
        readonly __typename: 'UserItemType';
        readonly id: string;
        readonly name: string;
      }
  >;
};

export type GQLActionFragmentFragment =
  | GQLActionFragmentCustomerDefinedActionFragment
  | GQLActionFragmentEnqueueAuthorToMrtActionFragment
  | GQLActionFragmentEnqueueToMrtActionFragment
  | GQLActionFragmentEnqueueToNcmecActionFragment;

export type GQLContentRuleFormConfigQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLContentRuleFormConfigQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly policies: ReadonlyArray<{
      readonly __typename: 'Policy';
      readonly id: string;
      readonly name: string;
      readonly parentId?: string | null;
    }>;
    readonly itemTypes: ReadonlyArray<
      | {
          readonly __typename: 'ContentItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'ContentSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly parentId?: string | null;
            readonly threadId?: string | null;
            readonly createdAt?: string | null;
            readonly creatorId?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
      | {
          readonly __typename: 'ThreadItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'ThreadSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly creatorId?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
      | {
          readonly __typename: 'UserItemType';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly version: string;
          readonly schemaVariant: GQLItemTypeSchemaVariant;
          readonly hiddenFields: ReadonlyArray<string>;
          readonly schemaFieldRoles: {
            readonly __typename: 'UserSchemaFieldRoles';
            readonly displayName?: string | null;
            readonly createdAt?: string | null;
            readonly profileIcon?: string | null;
            readonly backgroundImage?: string | null;
            readonly isDeleted?: string | null;
          };
          readonly baseFields: ReadonlyArray<{
            readonly __typename: 'BaseField';
            readonly name: string;
            readonly required: boolean;
            readonly type: GQLFieldType;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
          }>;
          readonly derivedFields: ReadonlyArray<{
            readonly __typename: 'DerivedField';
            readonly type: GQLFieldType;
            readonly name: string;
            readonly container?: {
              readonly __typename: 'Container';
              readonly containerType: GQLContainerType;
              readonly keyScalarType?: GQLScalarType | null;
              readonly valueScalarType: GQLScalarType;
            } | null;
            readonly spec: {
              readonly __typename: 'DerivedFieldSpec';
              readonly derivationType: GQLDerivedFieldDerivationType;
              readonly source:
                | {
                    readonly __typename: 'DerivedFieldFieldSource';
                    readonly name: string;
                    readonly contentTypeId: string;
                  }
                | {
                    readonly __typename: 'DerivedFieldFullItemSource';
                    readonly _?: boolean | null;
                  }
                | {
                    readonly __typename: 'DerivedFieldProtegoInputSource';
                    readonly protegoInput: GQLProtegoInput;
                  };
            };
          }>;
        }
    >;
    readonly actions: ReadonlyArray<
      | {
          readonly __typename: 'CustomerDefinedAction';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
      | {
          readonly __typename: 'EnqueueAuthorToMrtAction';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
      | {
          readonly __typename: 'EnqueueToMrtAction';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
      | {
          readonly __typename: 'EnqueueToNcmecAction';
          readonly id: string;
          readonly name: string;
          readonly description?: string | null;
          readonly itemTypes: ReadonlyArray<
            | {
                readonly __typename: 'ContentItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'ThreadItemType';
                readonly id: string;
                readonly name: string;
              }
            | {
                readonly __typename: 'UserItemType';
                readonly id: string;
                readonly name: string;
              }
          >;
        }
    >;
    readonly signals: ReadonlyArray<{
      readonly __typename: 'Signal';
      readonly id: string;
      readonly type: GQLSignalType;
      readonly name: string;
      readonly integration?: GQLIntegration | null;
      readonly docsUrl?: string | null;
      readonly description: string;
      readonly eligibleInputs: ReadonlyArray<GQLSignalInputType>;
      readonly shouldPromptForMatchingValues: boolean;
      readonly recommendedThresholds?: {
        readonly __typename: 'RecommendedThresholds';
        readonly highPrecisionThreshold: string | number;
        readonly highRecallThreshold: string | number;
      } | null;
      readonly supportedLanguages:
        | { readonly __typename: 'AllLanguages'; readonly _?: boolean | null }
        | {
            readonly __typename: 'Languages';
            readonly languages: ReadonlyArray<GQLLanguage>;
          };
      readonly pricingStructure: {
        readonly __typename: 'SignalPricingStructure';
        readonly type: GQLSignalPricingStructureType;
      };
      readonly disabledInfo: {
        readonly __typename: 'DisabledInfo';
        readonly disabled: boolean;
        readonly disabledMessage?: string | null;
      };
      readonly outputType:
        | {
            readonly __typename: 'EnumSignalOutputType';
            readonly scalarType: GQLScalarType;
            readonly enum: ReadonlyArray<string>;
            readonly ordered: boolean;
          }
        | {
            readonly __typename: 'ScalarSignalOutputType';
            readonly scalarType: GQLScalarType;
          };
      readonly eligibleSubcategories: ReadonlyArray<{
        readonly __typename: 'SignalSubcategory';
        readonly id: string;
        readonly label: string;
        readonly description?: string | null;
        readonly childrenIds: ReadonlyArray<string>;
      }>;
    }>;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly permissions: ReadonlyArray<GQLUserPermission>;
  } | null;
};

export type GQLCreateContentRuleMutationVariables = Exact<{
  input: GQLCreateContentRuleInput;
}>;

export type GQLCreateContentRuleMutation = {
  readonly __typename: 'Mutation';
  readonly createContentRule:
    | {
        readonly __typename: 'MutateContentRuleSuccessResponse';
        readonly data: {
          readonly __typename: 'ContentRule';
          readonly id: string;
        };
      }
    | { readonly __typename: 'RuleNameExistsError'; readonly title: string };
};

export type GQLUpdateContentRuleMutationVariables = Exact<{
  input: GQLUpdateContentRuleInput;
}>;

export type GQLUpdateContentRuleMutation = {
  readonly __typename: 'Mutation';
  readonly updateContentRule:
    | {
        readonly __typename: 'MutateContentRuleSuccessResponse';
        readonly data: {
          readonly __typename: 'ContentRule';
          readonly id: string;
        };
      }
    | { readonly __typename: 'NotFoundError'; readonly title: string }
    | {
        readonly __typename: 'RuleHasRunningBacktestsError';
        readonly title: string;
      }
    | { readonly __typename: 'RuleNameExistsError'; readonly title: string };
};

export type GQLCreateUserRuleMutationVariables = Exact<{
  input: GQLCreateUserRuleInput;
}>;

export type GQLCreateUserRuleMutation = {
  readonly __typename: 'Mutation';
  readonly createUserRule:
    | {
        readonly __typename: 'MutateUserRuleSuccessResponse';
        readonly data: { readonly __typename: 'UserRule'; readonly id: string };
      }
    | { readonly __typename: 'RuleNameExistsError'; readonly title: string };
};

export type GQLUpdateUserRuleMutationVariables = Exact<{
  input: GQLUpdateUserRuleInput;
}>;

export type GQLUpdateUserRuleMutation = {
  readonly __typename: 'Mutation';
  readonly updateUserRule:
    | {
        readonly __typename: 'MutateUserRuleSuccessResponse';
        readonly data: { readonly __typename: 'UserRule'; readonly id: string };
      }
    | { readonly __typename: 'NotFoundError'; readonly title: string }
    | {
        readonly __typename: 'RuleHasRunningBacktestsError';
        readonly title: string;
      }
    | { readonly __typename: 'RuleNameExistsError'; readonly title: string };
};

export type GQLRecentUserStrikeActionsQueryVariables = Exact<{
  input: GQLRecentUserStrikeActionsInput;
}>;

export type GQLRecentUserStrikeActionsQuery = {
  readonly __typename: 'Query';
  readonly recentUserStrikeActions: ReadonlyArray<{
    readonly __typename: 'RecentUserStrikeActions';
    readonly itemId: string;
    readonly itemTypeId: string;
    readonly actionId: string;
    readonly source: string;
    readonly time: Date | string;
  }>;
};

export type GQLUserStrikeDistributionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLUserStrikeDistributionQuery = {
  readonly __typename: 'Query';
  readonly getUserStrikeCountDistribution: ReadonlyArray<{
    readonly __typename: 'UserStrikeBucket';
    readonly numStrikes: number;
    readonly numUsers: number;
  }>;
};

export type GQLUserStrikeThresholdsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLUserStrikeThresholdsQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly userStrikeTTL: number;
    readonly userStrikeThresholds: ReadonlyArray<{
      readonly __typename: 'UserStrikeThreshold';
      readonly id: string;
      readonly threshold: number;
      readonly actions: ReadonlyArray<string>;
    }>;
  } | null;
};

export type GQLSetAllUserStrikeThresholdMutationVariables = Exact<{
  input: GQLSetAllUserStrikeThresholdsInput;
}>;

export type GQLSetAllUserStrikeThresholdMutation = {
  readonly __typename: 'Mutation';
  readonly setAllUserStrikeThresholds: {
    readonly __typename: 'SetAllUserStrikeThresholdsSuccessResponse';
    readonly _?: boolean | null;
  };
};

export type GQLUpdateUserStrikeTtlMutationVariables = Exact<{
  input: GQLUpdateUserStrikeTtlInput;
}>;

export type GQLUpdateUserStrikeTtlMutation = {
  readonly __typename: 'Mutation';
  readonly updateUserStrikeTTL: {
    readonly __typename: 'UpdateUserStrikeTTLSuccessResponse';
    readonly _?: boolean | null;
  };
};

export type GQLNavigationBarAuthInfoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLNavigationBarAuthInfoQuery = {
  readonly __typename: 'Query';
  readonly me?: {
    readonly __typename: 'User';
    readonly id: string;
    readonly email: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly readMeJWT?: string | null;
  } | null;
};

export type GQLRequestDemoMutationVariables = Exact<{
  input: GQLRequestDemoInput;
}>;

export type GQLRequestDemoMutation = {
  readonly __typename: 'Mutation';
  readonly requestDemo?: boolean | null;
};

export type GQLInternalModelTrainingPageDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLInternalModelTrainingPageDataQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly id: string;
    readonly email: string;
    readonly name: string;
    readonly policies: ReadonlyArray<{
      readonly __typename: 'Policy';
      readonly id: string;
      readonly name: string;
    }>;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly id: string;
    readonly email: string;
  } | null;
};

export type GQLGetLabelsForPolicyQueryVariables = Exact<{
  input: GQLGetLabelsForPolicyInput;
}>;

export type GQLGetLabelsForPolicyQuery = {
  readonly __typename: 'Query';
  readonly getLabelsForPolicy: {
    readonly __typename: 'GetLabelsForPolicyResponse';
    readonly items: ReadonlyArray<{
      readonly __typename: 'CoveModelItem';
      readonly id: string;
      readonly data: JsonObject;
      readonly itemType: {
        readonly __typename: 'ItemTypeIdentifier';
        readonly id: string;
        readonly version: string;
        readonly schemaVariant: GQLItemTypeSchemaVariant;
      };
    }>;
    readonly labels: ReadonlyArray<{
      readonly __typename: 'CoveModelLabel';
      readonly id: string;
      readonly itemId: string;
      readonly itemFieldJsonPointers: ReadonlyArray<string>;
      readonly violatesPolicy: boolean;
      readonly labelerId?: string | null;
      readonly labelerType?: string | null;
    }>;
  };
};

export type GQLSetLabelsForItemsMutationVariables = Exact<{
  input: GQLSetLabelsForItemsInput;
}>;

export type GQLSetLabelsForItemsMutation = {
  readonly __typename: 'Mutation';
  readonly setLabelsForItems: boolean;
};

export type GQLDeleteLabelsMutationVariables = Exact<{
  labelIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;

export type GQLDeleteLabelsMutation = {
  readonly __typename: 'Mutation';
  readonly deleteLabels: boolean;
};

export type GQLAccountSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GQLAccountSettingsQuery = {
  readonly __typename: 'Query';
  readonly me?: {
    readonly __typename: 'User';
    readonly id: string;
    readonly email: string;
    readonly firstName: string;
    readonly lastName: string;
  } | null;
};

export type GQLPersonalSafetySettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLPersonalSafetySettingsQuery = {
  readonly __typename: 'Query';
  readonly me?: {
    readonly __typename: 'User';
    readonly interfacePreferences: {
      readonly __typename: 'UserInterfacePreferences';
      readonly moderatorSafetyMuteVideo: boolean;
      readonly moderatorSafetyGrayscale: boolean;
      readonly moderatorSafetyBlurLevel: number;
    };
  } | null;
};

export type GQLUpdateAccountInfoMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
}>;

export type GQLUpdateAccountInfoMutation = {
  readonly __typename: 'Mutation';
  readonly updateAccountInfo?: boolean | null;
};

export type GQLApiKeyQueryVariables = Exact<{ [key: string]: never }>;

export type GQLApiKeyQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly apiKey: string;
    readonly publicSigningKey: string;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly permissions: ReadonlyArray<GQLUserPermission>;
  } | null;
};

export type GQLCancelSubscriptionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type GQLCancelSubscriptionMutation = {
  readonly __typename: 'Mutation';
  readonly cancelSubscription?: boolean | null;
};

export type GQLManageUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GQLManageUsersQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly id: string;
    readonly name: string;
    readonly hasNCMECReportingEnabled: boolean;
    readonly users: ReadonlyArray<{
      readonly __typename: 'User';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
      readonly email: string;
      readonly role?: GQLUserRole | null;
      readonly createdAt: string;
      readonly approvedByAdmin?: boolean | null;
      readonly rejectedByAdmin?: boolean | null;
    }>;
  } | null;
  readonly me?: {
    readonly __typename: 'User';
    readonly id: string;
    readonly email: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly permissions: ReadonlyArray<GQLUserPermission>;
  } | null;
};

export type GQLDeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLDeleteUserMutation = {
  readonly __typename: 'Mutation';
  readonly deleteUser?: boolean | null;
};

export type GQLUpdateRoleMutationVariables = Exact<{
  input: GQLUpdateRoleInput;
}>;

export type GQLUpdateRoleMutation = {
  readonly __typename: 'Mutation';
  readonly updateRole?: boolean | null;
};

export type GQLApproveUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLApproveUserMutation = {
  readonly __typename: 'Mutation';
  readonly approveUser?: boolean | null;
};

export type GQLRejectUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GQLRejectUserMutation = {
  readonly __typename: 'Mutation';
  readonly rejectUser?: boolean | null;
};

export type GQLHasNcmecReportingEnabledQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLHasNcmecReportingEnabledQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly hasNCMECReportingEnabled: boolean;
  } | null;
};

export type GQLInviteUserMutationVariables = Exact<{
  input: GQLInviteUserInput;
}>;

export type GQLInviteUserMutation = {
  readonly __typename: 'Mutation';
  readonly inviteUser?: boolean | null;
};

export type GQLOrgDefaultSafetySettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLOrgDefaultSafetySettingsQuery = {
  readonly __typename: 'Query';
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly defaultInterfacePreferences: {
      readonly __typename: 'UserInterfacePreferences';
      readonly moderatorSafetyMuteVideo: boolean;
      readonly moderatorSafetyGrayscale: boolean;
      readonly moderatorSafetyBlurLevel: number;
    };
  } | null;
};

export type GQLSetOrgDefaultSafetySettingsMutationVariables = Exact<{
  orgDefaultSafetySettings: GQLModeratorSafetySettingsInput;
}>;

export type GQLSetOrgDefaultSafetySettingsMutation = {
  readonly __typename: 'Mutation';
  readonly setOrgDefaultSafetySettings?: {
    readonly __typename: 'SetModeratorSafetySettingsSuccessResponse';
    readonly _?: boolean | null;
  } | null;
};

export type GQLGetSsoCredentialsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQLGetSsoCredentialsQuery = {
  readonly __typename: 'Query';
  readonly me?: {
    readonly __typename: 'User';
    readonly permissions: ReadonlyArray<GQLUserPermission>;
  } | null;
  readonly myOrg?: {
    readonly __typename: 'Org';
    readonly id: string;
    readonly ssoUrl?: string | null;
    readonly ssoCert?: string | null;
  } | null;
};

export type GQLUpdateSsoCredentialsMutationVariables = Exact<{
  input: GQLUpdateSsoCredentialsInput;
}>;

export type GQLUpdateSsoCredentialsMutation = {
  readonly __typename: 'Mutation';
  readonly updateSSOCredentials: boolean;
};

export const GQLCustomerDefinedActionFragmentFragmentDoc = gql`
  fragment CustomerDefinedActionFragment on CustomerDefinedAction {
    id
    name
    description
    itemTypes {
      ... on ItemTypeBase {
        id
      }
    }
    callbackUrl
    callbackUrlHeaders
    callbackUrlBody
  }
`;
export const GQLManualReviewDecisionComponentFieldsFragmentDoc = gql`
  fragment ManualReviewDecisionComponentFields on ManualReviewDecisionComponentBase {
    type
    ... on CustomerOrRelatedActionDecisionComponent {
      itemTypeId
      itemIds
      actionIds
      policyIds
    }
    ... on RejectAppealDecisionComponent {
      appealId
    }
    ... on AcceptAppealDecisionComponent {
      appealId
    }
    ... on TransformJobAndRecreateInQueueDecisionComponent {
      newQueueId
      originalQueueId
    }
    ... on SubmitNCMECReportDecisionComponent {
      type
      reportedMedia {
        id
        typeId
        url
        fileAnnotations
        industryClassification
      }
    }
  }
`;
export const GQLDerivedFieldFieldsFragmentDoc = gql`
  fragment DerivedFieldFields on DerivedField {
    type
    name
    container {
      containerType
      keyScalarType
      valueScalarType
    }
    spec {
      derivationType
      source {
        ... on DerivedFieldFieldSource {
          name
          contentTypeId
        }
        ... on DerivedFieldFullItemSource {
          _
        }
        ... on DerivedFieldProtegoInputSource {
          protegoInput: name
        }
      }
    }
  }
`;
export const GQLItemTypeFragmentFragmentDoc = gql`
  fragment ItemTypeFragment on ItemTypeBase {
    id
    name
    description
    version
    schemaVariant
    hiddenFields
    baseFields {
      name
      required
      type
      container {
        containerType
        keyScalarType
        valueScalarType
      }
    }
    derivedFields {
      ...DerivedFieldFields
    }
    ... on UserItemType {
      schemaFieldRoles {
        displayName
        createdAt
        profileIcon
        backgroundImage
        isDeleted
      }
    }
    ... on ContentItemType {
      schemaFieldRoles {
        displayName
        parentId
        threadId
        createdAt
        creatorId
        isDeleted
      }
    }
    ... on ThreadItemType {
      schemaFieldRoles {
        displayName
        createdAt
        creatorId
        isDeleted
      }
    }
  }
  ${GQLDerivedFieldFieldsFragmentDoc}
`;
export const GQLItemFieldsFragmentDoc = gql`
  fragment ItemFields on ItemBase {
    id
    submissionId
    submissionTime
    type {
      ...ItemTypeFragment
      ... on UserItemType {
        schemaFieldRoles {
          displayName
          createdAt
          profileIcon
          backgroundImage
          isDeleted
        }
      }
      ... on ContentItemType {
        schemaFieldRoles {
          displayName
          parentId
          threadId
          createdAt
          creatorId
          isDeleted
        }
      }
      ... on ThreadItemType {
        schemaFieldRoles {
          displayName
          createdAt
          creatorId
          isDeleted
        }
      }
    }
    data
  }
  ${GQLItemTypeFragmentFragmentDoc}
`;
export const GQLJobFieldsFragmentDoc = gql`
  fragment JobFields on ManualReviewJob {
    id
    createdAt
    policyIds
    numTimesReported
    payload {
      ... on ContentManualReviewJobPayload {
        userScore
        reportHistory {
          reporterId {
            id
            typeId
          }
          policyId
          reportId
          reason
          reportedAt
        }
        item {
          ... on ItemBase {
            ...ItemFields
          }
        }
        additionalContentItems {
          ... on ContentItem {
            ...ItemFields
          }
        }
        itemThreadContentItems {
          ... on ContentItem {
            ...ItemFields
          }
        }
        reportedForReasons {
          ... on ReportedForReason {
            reporterId {
              id
              typeId
            }
            reason
          }
        }
        enqueueSourceInfo {
          ... on ReportEnqueueSourceInfo {
            kind
          }
          ... on RuleExecutionEnqueueSourceInfo {
            kind
            rules {
              ... on ContentRule {
                id
                name
              }
              ... on UserRule {
                id
                name
              }
            }
          }
          ... on MrtJobEnqueueSourceInfo {
            kind
          }
          ... on PostActionsEnqueueSourceInfo {
            kind
          }
        }
      }
      ... on UserManualReviewJobPayload {
        userScore
        reportHistory {
          reportId
          reporterId {
            id
            typeId
          }
          policyId
          reason
          reportedAt
        }
        item {
          ... on ItemBase {
            ...ItemFields
          }
        }
        itemThreadContentItems {
          ... on ContentItem {
            ...ItemFields
          }
        }
        reportedItems {
          id
          typeId
        }
        additionalContentItems {
          ... on ContentItem {
            ...ItemFields
          }
        }
        reportedForReasons {
          ... on ReportedForReason {
            reporterId {
              id
              typeId
            }
            reason
          }
        }
        enqueueSourceInfo {
          ... on ReportEnqueueSourceInfo {
            kind
          }
          ... on RuleExecutionEnqueueSourceInfo {
            kind
            rules {
              ... on ContentRule {
                id
                name
              }
              ... on UserRule {
                id
                name
              }
            }
          }
          ... on MrtJobEnqueueSourceInfo {
            kind
          }
          ... on PostActionsEnqueueSourceInfo {
            kind
          }
        }
      }
      ... on ThreadManualReviewJobPayload {
        reportHistory {
          reportId
          reporterId {
            id
            typeId
          }
          policyId
          reason
          reportedAt
        }
        item {
          ... on ItemBase {
            ...ItemFields
          }
        }
        reportedForReasons {
          ... on ReportedForReason {
            reporterId {
              id
              typeId
            }
            reason
          }
        }
        enqueueSourceInfo {
          ... on ReportEnqueueSourceInfo {
            kind
          }
          ... on RuleExecutionEnqueueSourceInfo {
            kind
            rules {
              ... on ContentRule {
                id
                name
              }
              ... on UserRule {
                id
                name
              }
            }
          }
          ... on MrtJobEnqueueSourceInfo {
            kind
          }
          ... on PostActionsEnqueueSourceInfo {
            kind
          }
        }
      }
      ... on ContentAppealManualReviewJobPayload {
        userScore
        item {
          ... on ItemBase {
            ...ItemFields
          }
        }
        additionalContentItems {
          ... on ContentItem {
            ...ItemFields
          }
        }
        appealReason
        appealId
        actionsTaken
        appealerIdentifier {
          id
          typeId
        }
        enqueueSourceInfo {
          ... on AppealEnqueueSourceInfo {
            kind
          }
        }
      }
      ... on UserAppealManualReviewJobPayload {
        userScore
        item {
          ... on ItemBase {
            ...ItemFields
          }
        }
        additionalContentItems {
          ... on ContentItem {
            ...ItemFields
          }
        }
        appealReason
        appealId
        actionsTaken
        appealerIdentifier {
          id
          typeId
        }
        enqueueSourceInfo {
          ... on AppealEnqueueSourceInfo {
            kind
          }
        }
      }
      ... on ThreadAppealManualReviewJobPayload {
        item {
          ... on ItemBase {
            ...ItemFields
          }
        }
        appealId
        appealReason
        actionsTaken
        appealerIdentifier {
          id
          typeId
        }
        enqueueSourceInfo {
          ... on AppealEnqueueSourceInfo {
            kind
          }
        }
      }
      ... on NcmecManualReviewJobPayload {
        item {
          ... on ItemBase {
            ...ItemFields
          }
        }
        allMediaItems {
          contentItem {
            ...ItemFields
          }
          isConfirmedCSAM
          isReported
        }
        enqueueSourceInfo {
          ... on ReportEnqueueSourceInfo {
            kind
          }
          ... on RuleExecutionEnqueueSourceInfo {
            kind
            rules {
              ... on ContentRule {
                id
                name
              }
              ... on UserRule {
                id
                name
              }
            }
          }
          ... on MrtJobEnqueueSourceInfo {
            kind
          }
          ... on PostActionsEnqueueSourceInfo {
            kind
          }
        }
      }
    }
  }
  ${GQLItemFieldsFragmentDoc}
`;
export const GQLManualReviewJobCommentFieldsFragmentDoc = gql`
  fragment ManualReviewJobCommentFields on ManualReviewJobComment {
    id
    createdAt
    commentText
    author {
      id
      firstName
      lastName
    }
  }
`;
export const GQLNcmecReportValuesFragmentDoc = gql`
  fragment NCMECReportValues on NCMECReport {
    ts
    reportId
    userId
    userItemType {
      name
    }
    reportedMedia {
      id
      xml
    }
    additionalFiles {
      url
      xml
      ncmecFileId
    }
    reviewerId
    reportXml
    reportedMessages {
      fileName
      csv
      ncmecFileId
    }
    isTest
  }
`;
export const GQLPolicyFieldsFragmentDoc = gql`
  fragment PolicyFields on Policy {
    id
    name
    policyText
    enforcementGuidelines
    parentId
    policyType
    userStrikeCount
    applyUserStrikeCountConfigToChildren
  }
`;
export const GQLRulesDashboardRuleFieldsFragmentFragmentDoc = gql`
  fragment RulesDashboardRuleFieldsFragment on Rule {
    id
    name
    creator {
      firstName
      lastName
    }
    createdAt
    updatedAt
    status
    policies {
      name
    }
    actions {
      ... on ActionBase {
        id
        name
      }
    }
  }
`;
export const GQLSampleReportingRuleExecutionResultFieldsFragmentDoc = gql`
  fragment SampleReportingRuleExecutionResultFields on ReportingRuleExecutionResult {
    ts
    itemId
    itemTypeName
    itemTypeId
    creatorId
    creatorTypeId
    itemData
    environment
    signalResults {
      signalName
      integration
      subcategory
      score
    }
  }
`;
export const GQLSampleRuleExecutionResultFieldsFragmentDoc = gql`
  fragment SampleRuleExecutionResultFields on RuleExecutionResult {
    ts
    contentId
    itemTypeName
    itemTypeId
    userId
    userTypeId
    content
    environment
    signalResults {
      signalName
      integration
      subcategory
      score
    }
  }
`;
export const GQLLeafConditionWithResultFieldsFragmentDoc = gql`
  fragment LeafConditionWithResultFields on LeafConditionWithResult {
    input {
      type
      name
      contentTypeId
      spec {
        derivationType
      }
    }
    signal {
      id
      type
      name
      subcategory
      args {
        ... on Gpt4oMiniSignalArgs {
          policyId
        }
      }
    }
    matchingValues {
      strings
      textBankIds
      locationBankIds
      locations {
        id
        name
        geometry {
          center {
            lat
            lng
          }
          radius
        }
        bounds {
          northeastCorner {
            lat
            lng
          }
          southwestCorner {
            lat
            lng
          }
        }
        googlePlaceInfo {
          id
        }
      }
    }
    comparator
    threshold
    result {
      outcome
      score
      matchedValue
    }
  }
`;
export const GQLSampleRuleExecutionResultConditionResultFieldsFragmentDoc = gql`
  fragment SampleRuleExecutionResultConditionResultFields on ConditionSetWithResult {
    conjunction
    conditions {
      ... on ConditionSetWithResult {
        conjunction
        conditions {
          ... on LeafConditionWithResult {
            ...LeafConditionWithResultFields
          }
        }
        result {
          outcome
          score
          matchedValue
        }
      }
      ... on LeafConditionWithResult {
        ...LeafConditionWithResultFields
      }
    }
    result {
      outcome
      score
      matchedValue
    }
  }
  ${GQLLeafConditionWithResultFieldsFragmentDoc}
`;
export const GQLLeafConditionFieldsFragmentDoc = gql`
  fragment LeafConditionFields on LeafCondition {
    input {
      type
      name
      contentTypeId
      contentTypeIds
      spec {
        source {
          ... on DerivedFieldFieldSource {
            name
            contentTypeId
          }
          ... on DerivedFieldFullItemSource {
            _
          }
          ... on DerivedFieldProtegoInputSource {
            protegoInput: name
          }
        }
        derivationType
      }
    }
    signal {
      id
      type
      name
      subcategory
      args {
        ... on Gpt4oMiniSignalArgs {
          policyId
        }
      }
    }
    matchingValues {
      strings
      textBankIds
      locationBankIds
      locations {
        id
        name
        geometry {
          center {
            lat
            lng
          }
          radius
        }
        bounds {
          northeastCorner {
            lat
            lng
          }
          southwestCorner {
            lat
            lng
          }
        }
      }
    }
    comparator
    threshold
  }
`;
export const GQLConditionSetFieldsFragmentDoc = gql`
  fragment ConditionSetFields on ConditionSet {
    conjunction
    conditions {
      ... on ConditionSet {
        conjunction
        conditions {
          ... on LeafCondition {
            ...LeafConditionFields
          }
        }
      }
      ... on LeafCondition {
        ...LeafConditionFields
      }
    }
  }
  ${GQLLeafConditionFieldsFragmentDoc}
`;
export const GQLReportingRuleFormRuleFieldsFragmentFragmentDoc = gql`
  fragment ReportingRuleFormRuleFieldsFragment on ReportingRule {
    __typename
    id
    name
    description
    status
    policies {
      id
    }
    conditionSet {
      ...ConditionSetFields
    }
    actions {
      ... on CustomerDefinedAction {
        id
        name
        description
        itemTypes {
          ... on ItemTypeBase {
            id
            name
          }
        }
      }
      ... on EnqueueToMrtAction {
        id
        name
        description
        itemTypes {
          ... on ItemTypeBase {
            id
            name
          }
        }
      }
      ... on EnqueueToNcmecAction {
        id
        name
        description
        itemTypes {
          ... on ItemTypeBase {
            id
            name
          }
        }
      }
      ... on EnqueueAuthorToMrtAction {
        id
        name
        description
        itemTypes {
          ... on ItemTypeBase {
            id
            name
          }
        }
      }
    }
  }
  ${GQLConditionSetFieldsFragmentDoc}
`;
export const GQLSignalsFragmentFragmentDoc = gql`
  fragment SignalsFragment on Signal {
    id
    type
    name
    integration
    docsUrl
    recommendedThresholds {
      highPrecisionThreshold
      highRecallThreshold
    }
    supportedLanguages {
      ... on AllLanguages {
        _
      }
      ... on Languages {
        languages
      }
    }
    pricingStructure {
      type
    }
    disabledInfo {
      disabled
      disabledMessage
    }
    description
    eligibleInputs
    outputType {
      ... on ScalarSignalOutputType {
        scalarType
      }
      ... on EnumSignalOutputType {
        scalarType
        enum
        ordered
      }
    }
    shouldPromptForMatchingValues
    eligibleSubcategories {
      id
      label
      description
      childrenIds
    }
  }
`;
export const GQLRuleFormRuleFieldsFragmentFragmentDoc = gql`
  fragment RuleFormRuleFieldsFragment on Rule {
    __typename
    id
    name
    description
    status
    policies {
      id
    }
    tags
    maxDailyActions
    expirationTime
    conditionSet {
      ...ConditionSetFields
    }
    actions {
      ... on CustomerDefinedAction {
        id
        name
        description
        itemTypes {
          ... on ItemTypeBase {
            id
            name
          }
        }
      }
      ... on EnqueueToMrtAction {
        id
        name
        description
        itemTypes {
          ... on ItemTypeBase {
            id
            name
          }
        }
      }
      ... on EnqueueToNcmecAction {
        id
        name
        description
        itemTypes {
          ... on ItemTypeBase {
            id
            name
          }
        }
      }
      ... on EnqueueAuthorToMrtAction {
        id
        name
        description
        itemTypes {
          ... on ItemTypeBase {
            id
            name
          }
        }
      }
    }
  }
  ${GQLConditionSetFieldsFragmentDoc}
`;
export const GQLActionFragmentFragmentDoc = gql`
  fragment ActionFragment on ActionBase {
    ... on CustomerDefinedAction {
      id
      name
      description
      itemTypes {
        ... on ItemTypeBase {
          id
          name
        }
      }
    }
    ... on EnqueueToMrtAction {
      id
      name
      description
      itemTypes {
        ... on ItemTypeBase {
          id
          name
        }
      }
    }
    ... on EnqueueToNcmecAction {
      id
      name
      description
      itemTypes {
        ... on ItemTypeBase {
          id
          name
        }
      }
    }
    ... on EnqueueAuthorToMrtAction {
      id
      name
      description
      itemTypes {
        ... on ItemTypeBase {
          id
          name
        }
      }
    }
  }
`;
export const GQLUserAndOrgDocument = gql`
  query UserAndOrg {
    me {
      id
      email
    }
    myOrg {
      id
      hasMrtAccess
    }
  }
`;

/**
 * __useGQLUserAndOrgQuery__
 *
 * To run a query within a React component, call `useGQLUserAndOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLUserAndOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLUserAndOrgQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLUserAndOrgQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLUserAndOrgQuery,
    GQLUserAndOrgQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLUserAndOrgQuery, GQLUserAndOrgQueryVariables>(
    GQLUserAndOrgDocument,
    options,
  );
}
export function useGQLUserAndOrgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLUserAndOrgQuery,
    GQLUserAndOrgQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQLUserAndOrgQuery, GQLUserAndOrgQueryVariables>(
    GQLUserAndOrgDocument,
    options,
  );
}
export type GQLUserAndOrgQueryHookResult = ReturnType<
  typeof useGQLUserAndOrgQuery
>;
export type GQLUserAndOrgLazyQueryHookResult = ReturnType<
  typeof useGQLUserAndOrgLazyQuery
>;
export type GQLUserAndOrgQueryResult = Apollo.QueryResult<
  GQLUserAndOrgQuery,
  GQLUserAndOrgQueryVariables
>;
export const GQLLoggedInUserForRouteDocument = gql`
  query LoggedInUserForRoute {
    me {
      id
      approvedByAdmin
      rejectedByAdmin
    }
  }
`;

/**
 * __useGQLLoggedInUserForRouteQuery__
 *
 * To run a query within a React component, call `useGQLLoggedInUserForRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLLoggedInUserForRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLLoggedInUserForRouteQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLLoggedInUserForRouteQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLLoggedInUserForRouteQuery,
    GQLLoggedInUserForRouteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLLoggedInUserForRouteQuery,
    GQLLoggedInUserForRouteQueryVariables
  >(GQLLoggedInUserForRouteDocument, options);
}
export function useGQLLoggedInUserForRouteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLLoggedInUserForRouteQuery,
    GQLLoggedInUserForRouteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLLoggedInUserForRouteQuery,
    GQLLoggedInUserForRouteQueryVariables
  >(GQLLoggedInUserForRouteDocument, options);
}
export type GQLLoggedInUserForRouteQueryHookResult = ReturnType<
  typeof useGQLLoggedInUserForRouteQuery
>;
export type GQLLoggedInUserForRouteLazyQueryHookResult = ReturnType<
  typeof useGQLLoggedInUserForRouteLazyQuery
>;
export type GQLLoggedInUserForRouteQueryResult = Apollo.QueryResult<
  GQLLoggedInUserForRouteQuery,
  GQLLoggedInUserForRouteQueryVariables
>;
export const GQLPermissionGatedRouteLoggedInUserDocument = gql`
  query PermissionGatedRouteLoggedInUser {
    me {
      id
      permissions
    }
  }
`;

/**
 * __useGQLPermissionGatedRouteLoggedInUserQuery__
 *
 * To run a query within a React component, call `useGQLPermissionGatedRouteLoggedInUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLPermissionGatedRouteLoggedInUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLPermissionGatedRouteLoggedInUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLPermissionGatedRouteLoggedInUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLPermissionGatedRouteLoggedInUserQuery,
    GQLPermissionGatedRouteLoggedInUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLPermissionGatedRouteLoggedInUserQuery,
    GQLPermissionGatedRouteLoggedInUserQueryVariables
  >(GQLPermissionGatedRouteLoggedInUserDocument, options);
}
export function useGQLPermissionGatedRouteLoggedInUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLPermissionGatedRouteLoggedInUserQuery,
    GQLPermissionGatedRouteLoggedInUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLPermissionGatedRouteLoggedInUserQuery,
    GQLPermissionGatedRouteLoggedInUserQueryVariables
  >(GQLPermissionGatedRouteLoggedInUserDocument, options);
}
export type GQLPermissionGatedRouteLoggedInUserQueryHookResult = ReturnType<
  typeof useGQLPermissionGatedRouteLoggedInUserQuery
>;
export type GQLPermissionGatedRouteLoggedInUserLazyQueryHookResult = ReturnType<
  typeof useGQLPermissionGatedRouteLoggedInUserLazyQuery
>;
export type GQLPermissionGatedRouteLoggedInUserQueryResult = Apollo.QueryResult<
  GQLPermissionGatedRouteLoggedInUserQuery,
  GQLPermissionGatedRouteLoggedInUserQueryVariables
>;
export const GQLLoginDocument = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      ... on LoginSuccessResponse {
        user {
          id
          email
        }
      }
      ... on LoginUserDoesNotExistError {
        title
        status
      }
      ... on LoginIncorrectPasswordError {
        title
        status
      }
      ... on LoginSsoRequiredError {
        title
        status
      }
    }
  }
`;
export type GQLLoginMutationFn = Apollo.MutationFunction<
  GQLLoginMutation,
  GQLLoginMutationVariables
>;

/**
 * __useGQLLoginMutation__
 *
 * To run a mutation, you first call `useGQLLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlLoginMutation, { data, loading, error }] = useGQLLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLLoginMutation,
    GQLLoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GQLLoginMutation, GQLLoginMutationVariables>(
    GQLLoginDocument,
    options,
  );
}
export type GQLLoginMutationHookResult = ReturnType<typeof useGQLLoginMutation>;
export type GQLLoginMutationResult = Apollo.MutationResult<GQLLoginMutation>;
export type GQLLoginMutationOptions = Apollo.BaseMutationOptions<
  GQLLoginMutation,
  GQLLoginMutationVariables
>;
export const GQLGetSsoRedirectUrlDocument = gql`
  query GetSSORedirectUrl($emailAddress: String!) {
    getSSORedirectUrl(emailAddress: $emailAddress)
  }
`;

/**
 * __useGQLGetSsoRedirectUrlQuery__
 *
 * To run a query within a React component, call `useGQLGetSsoRedirectUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetSsoRedirectUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetSsoRedirectUrlQuery({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *   },
 * });
 */
export function useGQLGetSsoRedirectUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetSsoRedirectUrlQuery,
    GQLGetSsoRedirectUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetSsoRedirectUrlQuery,
    GQLGetSsoRedirectUrlQueryVariables
  >(GQLGetSsoRedirectUrlDocument, options);
}
export function useGQLGetSsoRedirectUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetSsoRedirectUrlQuery,
    GQLGetSsoRedirectUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetSsoRedirectUrlQuery,
    GQLGetSsoRedirectUrlQueryVariables
  >(GQLGetSsoRedirectUrlDocument, options);
}
export type GQLGetSsoRedirectUrlQueryHookResult = ReturnType<
  typeof useGQLGetSsoRedirectUrlQuery
>;
export type GQLGetSsoRedirectUrlLazyQueryHookResult = ReturnType<
  typeof useGQLGetSsoRedirectUrlLazyQuery
>;
export type GQLGetSsoRedirectUrlQueryResult = Apollo.QueryResult<
  GQLGetSsoRedirectUrlQuery,
  GQLGetSsoRedirectUrlQueryVariables
>;
export const GQLRejectedUserDocument = gql`
  query RejectedUser {
    me {
      id
    }
  }
`;

/**
 * __useGQLRejectedUserQuery__
 *
 * To run a query within a React component, call `useGQLRejectedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLRejectedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLRejectedUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLRejectedUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLRejectedUserQuery,
    GQLRejectedUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLRejectedUserQuery, GQLRejectedUserQueryVariables>(
    GQLRejectedUserDocument,
    options,
  );
}
export function useGQLRejectedUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLRejectedUserQuery,
    GQLRejectedUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLRejectedUserQuery,
    GQLRejectedUserQueryVariables
  >(GQLRejectedUserDocument, options);
}
export type GQLRejectedUserQueryHookResult = ReturnType<
  typeof useGQLRejectedUserQuery
>;
export type GQLRejectedUserLazyQueryHookResult = ReturnType<
  typeof useGQLRejectedUserLazyQuery
>;
export type GQLRejectedUserQueryResult = Apollo.QueryResult<
  GQLRejectedUserQuery,
  GQLRejectedUserQueryVariables
>;
export const GQLDeleteRejectedUserDocument = gql`
  mutation DeleteRejectedUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
export type GQLDeleteRejectedUserMutationFn = Apollo.MutationFunction<
  GQLDeleteRejectedUserMutation,
  GQLDeleteRejectedUserMutationVariables
>;

/**
 * __useGQLDeleteRejectedUserMutation__
 *
 * To run a mutation, you first call `useGQLDeleteRejectedUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLDeleteRejectedUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlDeleteRejectedUserMutation, { data, loading, error }] = useGQLDeleteRejectedUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLDeleteRejectedUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLDeleteRejectedUserMutation,
    GQLDeleteRejectedUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLDeleteRejectedUserMutation,
    GQLDeleteRejectedUserMutationVariables
  >(GQLDeleteRejectedUserDocument, options);
}
export type GQLDeleteRejectedUserMutationHookResult = ReturnType<
  typeof useGQLDeleteRejectedUserMutation
>;
export type GQLDeleteRejectedUserMutationResult =
  Apollo.MutationResult<GQLDeleteRejectedUserMutation>;
export type GQLDeleteRejectedUserMutationOptions = Apollo.BaseMutationOptions<
  GQLDeleteRejectedUserMutation,
  GQLDeleteRejectedUserMutationVariables
>;
export const GQLAllOrgsDocument = gql`
  query AllOrgs {
    allOrgs {
      id
      email
      name
    }
  }
`;

/**
 * __useGQLAllOrgsQuery__
 *
 * To run a query within a React component, call `useGQLAllOrgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLAllOrgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLAllOrgsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLAllOrgsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLAllOrgsQuery,
    GQLAllOrgsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLAllOrgsQuery, GQLAllOrgsQueryVariables>(
    GQLAllOrgsDocument,
    options,
  );
}
export function useGQLAllOrgsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLAllOrgsQuery,
    GQLAllOrgsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQLAllOrgsQuery, GQLAllOrgsQueryVariables>(
    GQLAllOrgsDocument,
    options,
  );
}
export type GQLAllOrgsQueryHookResult = ReturnType<typeof useGQLAllOrgsQuery>;
export type GQLAllOrgsLazyQueryHookResult = ReturnType<
  typeof useGQLAllOrgsLazyQuery
>;
export type GQLAllOrgsQueryResult = Apollo.QueryResult<
  GQLAllOrgsQuery,
  GQLAllOrgsQueryVariables
>;
export const GQLInviteUserTokenDocument = gql`
  query InviteUserToken($token: String!) {
    inviteUserToken(token: $token) {
      ... on InviteUserTokenSuccessResponse {
        tokenData {
          token
          email
          role
          orgId
          samlEnabled
        }
      }
      ... on InviteUserTokenExpiredError {
        title
      }
      ... on InviteUserTokenMissingError {
        title
      }
    }
  }
`;

/**
 * __useGQLInviteUserTokenQuery__
 *
 * To run a query within a React component, call `useGQLInviteUserTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLInviteUserTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLInviteUserTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGQLInviteUserTokenQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLInviteUserTokenQuery,
    GQLInviteUserTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLInviteUserTokenQuery,
    GQLInviteUserTokenQueryVariables
  >(GQLInviteUserTokenDocument, options);
}
export function useGQLInviteUserTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLInviteUserTokenQuery,
    GQLInviteUserTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLInviteUserTokenQuery,
    GQLInviteUserTokenQueryVariables
  >(GQLInviteUserTokenDocument, options);
}
export type GQLInviteUserTokenQueryHookResult = ReturnType<
  typeof useGQLInviteUserTokenQuery
>;
export type GQLInviteUserTokenLazyQueryHookResult = ReturnType<
  typeof useGQLInviteUserTokenLazyQuery
>;
export type GQLInviteUserTokenQueryResult = Apollo.QueryResult<
  GQLInviteUserTokenQuery,
  GQLInviteUserTokenQueryVariables
>;
export const GQLCreateOrgDocument = gql`
  mutation CreateOrg($input: CreateOrgInput!) {
    createOrg(input: $input) {
      ... on CreateOrgSuccessResponse {
        id
      }
      ... on OrgWithEmailExistsError {
        title
      }
      ... on OrgWithNameExistsError {
        title
      }
    }
  }
`;
export type GQLCreateOrgMutationFn = Apollo.MutationFunction<
  GQLCreateOrgMutation,
  GQLCreateOrgMutationVariables
>;

/**
 * __useGQLCreateOrgMutation__
 *
 * To run a mutation, you first call `useGQLCreateOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLCreateOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCreateOrgMutation, { data, loading, error }] = useGQLCreateOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLCreateOrgMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLCreateOrgMutation,
    GQLCreateOrgMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLCreateOrgMutation,
    GQLCreateOrgMutationVariables
  >(GQLCreateOrgDocument, options);
}
export type GQLCreateOrgMutationHookResult = ReturnType<
  typeof useGQLCreateOrgMutation
>;
export type GQLCreateOrgMutationResult =
  Apollo.MutationResult<GQLCreateOrgMutation>;
export type GQLCreateOrgMutationOptions = Apollo.BaseMutationOptions<
  GQLCreateOrgMutation,
  GQLCreateOrgMutationVariables
>;
export const GQLSignUpDocument = gql`
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      ... on SignUpSuccessResponse {
        data {
          id
          loginMethods
        }
      }
      ... on SignUpUserExistsError {
        title
      }
    }
  }
`;
export type GQLSignUpMutationFn = Apollo.MutationFunction<
  GQLSignUpMutation,
  GQLSignUpMutationVariables
>;

/**
 * __useGQLSignUpMutation__
 *
 * To run a mutation, you first call `useGQLSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlSignUpMutation, { data, loading, error }] = useGQLSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLSignUpMutation,
    GQLSignUpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GQLSignUpMutation, GQLSignUpMutationVariables>(
    GQLSignUpDocument,
    options,
  );
}
export type GQLSignUpMutationHookResult = ReturnType<
  typeof useGQLSignUpMutation
>;
export type GQLSignUpMutationResult = Apollo.MutationResult<GQLSignUpMutation>;
export type GQLSignUpMutationOptions = Apollo.BaseMutationOptions<
  GQLSignUpMutation,
  GQLSignUpMutationVariables
>;
export const GQLSendPasswordResetDocument = gql`
  mutation SendPasswordReset($input: SendPasswordResetInput!) {
    sendPasswordReset(input: $input)
  }
`;
export type GQLSendPasswordResetMutationFn = Apollo.MutationFunction<
  GQLSendPasswordResetMutation,
  GQLSendPasswordResetMutationVariables
>;

/**
 * __useGQLSendPasswordResetMutation__
 *
 * To run a mutation, you first call `useGQLSendPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLSendPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlSendPasswordResetMutation, { data, loading, error }] = useGQLSendPasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLSendPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLSendPasswordResetMutation,
    GQLSendPasswordResetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLSendPasswordResetMutation,
    GQLSendPasswordResetMutationVariables
  >(GQLSendPasswordResetDocument, options);
}
export type GQLSendPasswordResetMutationHookResult = ReturnType<
  typeof useGQLSendPasswordResetMutation
>;
export type GQLSendPasswordResetMutationResult =
  Apollo.MutationResult<GQLSendPasswordResetMutation>;
export type GQLSendPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  GQLSendPasswordResetMutation,
  GQLSendPasswordResetMutationVariables
>;
export const GQLResetPasswordDocument = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;
export type GQLResetPasswordMutationFn = Apollo.MutationFunction<
  GQLResetPasswordMutation,
  GQLResetPasswordMutationVariables
>;

/**
 * __useGQLResetPasswordMutation__
 *
 * To run a mutation, you first call `useGQLResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlResetPasswordMutation, { data, loading, error }] = useGQLResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLResetPasswordMutation,
    GQLResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLResetPasswordMutation,
    GQLResetPasswordMutationVariables
  >(GQLResetPasswordDocument, options);
}
export type GQLResetPasswordMutationHookResult = ReturnType<
  typeof useGQLResetPasswordMutation
>;
export type GQLResetPasswordMutationResult =
  Apollo.MutationResult<GQLResetPasswordMutation>;
export type GQLResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  GQLResetPasswordMutation,
  GQLResetPasswordMutationVariables
>;
export const GQLDashboardOrgDocument = gql`
  query DashboardOrg {
    myOrg {
      id
      name
      hasMrtAccess
      hasReportingRulesEnabled
      hasNCMECReportingEnabled
      hasAppealsEnabled
      isDemoOrg
    }
    me {
      id
      permissions
      email
    }
  }
`;

/**
 * __useGQLDashboardOrgQuery__
 *
 * To run a query within a React component, call `useGQLDashboardOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLDashboardOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLDashboardOrgQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLDashboardOrgQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLDashboardOrgQuery,
    GQLDashboardOrgQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLDashboardOrgQuery, GQLDashboardOrgQueryVariables>(
    GQLDashboardOrgDocument,
    options,
  );
}
export function useGQLDashboardOrgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLDashboardOrgQuery,
    GQLDashboardOrgQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLDashboardOrgQuery,
    GQLDashboardOrgQueryVariables
  >(GQLDashboardOrgDocument, options);
}
export type GQLDashboardOrgQueryHookResult = ReturnType<
  typeof useGQLDashboardOrgQuery
>;
export type GQLDashboardOrgLazyQueryHookResult = ReturnType<
  typeof useGQLDashboardOrgLazyQuery
>;
export type GQLDashboardOrgQueryResult = Apollo.QueryResult<
  GQLDashboardOrgQuery,
  GQLDashboardOrgQueryVariables
>;
export const GQLLogoutDocument = gql`
  mutation Logout {
    logout
  }
`;
export type GQLLogoutMutationFn = Apollo.MutationFunction<
  GQLLogoutMutation,
  GQLLogoutMutationVariables
>;

/**
 * __useGQLLogoutMutation__
 *
 * To run a mutation, you first call `useGQLLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlLogoutMutation, { data, loading, error }] = useGQLLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useGQLLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLLogoutMutation,
    GQLLogoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GQLLogoutMutation, GQLLogoutMutationVariables>(
    GQLLogoutDocument,
    options,
  );
}
export type GQLLogoutMutationHookResult = ReturnType<
  typeof useGQLLogoutMutation
>;
export type GQLLogoutMutationResult = Apollo.MutationResult<GQLLogoutMutation>;
export type GQLLogoutMutationOptions = Apollo.BaseMutationOptions<
  GQLLogoutMutation,
  GQLLogoutMutationVariables
>;
export const GQLActionDocument = gql`
  query Action($id: ID!) {
    action(id: $id) {
      ... on CustomerDefinedAction {
        ...CustomerDefinedActionFragment
      }
    }
  }
  ${GQLCustomerDefinedActionFragmentFragmentDoc}
`;

/**
 * __useGQLActionQuery__
 *
 * To run a query within a React component, call `useGQLActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLActionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLActionQuery(
  baseOptions: Apollo.QueryHookOptions<GQLActionQuery, GQLActionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLActionQuery, GQLActionQueryVariables>(
    GQLActionDocument,
    options,
  );
}
export function useGQLActionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLActionQuery,
    GQLActionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQLActionQuery, GQLActionQueryVariables>(
    GQLActionDocument,
    options,
  );
}
export type GQLActionQueryHookResult = ReturnType<typeof useGQLActionQuery>;
export type GQLActionLazyQueryHookResult = ReturnType<
  typeof useGQLActionLazyQuery
>;
export type GQLActionQueryResult = Apollo.QueryResult<
  GQLActionQuery,
  GQLActionQueryVariables
>;
export const GQLActionFormDocument = gql`
  query ActionForm {
    myOrg {
      itemTypes {
        ... on ItemTypeBase {
          id
          name
        }
      }
    }
    me {
      permissions
    }
  }
`;

/**
 * __useGQLActionFormQuery__
 *
 * To run a query within a React component, call `useGQLActionFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLActionFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLActionFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLActionFormQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLActionFormQuery,
    GQLActionFormQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLActionFormQuery, GQLActionFormQueryVariables>(
    GQLActionFormDocument,
    options,
  );
}
export function useGQLActionFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLActionFormQuery,
    GQLActionFormQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQLActionFormQuery, GQLActionFormQueryVariables>(
    GQLActionFormDocument,
    options,
  );
}
export type GQLActionFormQueryHookResult = ReturnType<
  typeof useGQLActionFormQuery
>;
export type GQLActionFormLazyQueryHookResult = ReturnType<
  typeof useGQLActionFormLazyQuery
>;
export type GQLActionFormQueryResult = Apollo.QueryResult<
  GQLActionFormQuery,
  GQLActionFormQueryVariables
>;
export const GQLCreateActionDocument = gql`
  mutation CreateAction($input: CreateActionInput!) {
    createAction(input: $input) {
      ... on MutateActionSuccessResponse {
        data {
          ... on CustomerDefinedAction {
            ...CustomerDefinedActionFragment
          }
        }
      }
      ... on ActionNameExistsError {
        title
        status
        type
      }
    }
  }
  ${GQLCustomerDefinedActionFragmentFragmentDoc}
`;
export type GQLCreateActionMutationFn = Apollo.MutationFunction<
  GQLCreateActionMutation,
  GQLCreateActionMutationVariables
>;

/**
 * __useGQLCreateActionMutation__
 *
 * To run a mutation, you first call `useGQLCreateActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLCreateActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCreateActionMutation, { data, loading, error }] = useGQLCreateActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLCreateActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLCreateActionMutation,
    GQLCreateActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLCreateActionMutation,
    GQLCreateActionMutationVariables
  >(GQLCreateActionDocument, options);
}
export type GQLCreateActionMutationHookResult = ReturnType<
  typeof useGQLCreateActionMutation
>;
export type GQLCreateActionMutationResult =
  Apollo.MutationResult<GQLCreateActionMutation>;
export type GQLCreateActionMutationOptions = Apollo.BaseMutationOptions<
  GQLCreateActionMutation,
  GQLCreateActionMutationVariables
>;
export const GQLUpdateActionDocument = gql`
  mutation UpdateAction($input: UpdateActionInput!) {
    updateAction(input: $input) {
      ... on MutateActionSuccessResponse {
        data {
          ... on CustomerDefinedAction {
            ...CustomerDefinedActionFragment
          }
        }
      }
      ... on ActionNameExistsError {
        title
        status
        type
      }
    }
  }
  ${GQLCustomerDefinedActionFragmentFragmentDoc}
`;
export type GQLUpdateActionMutationFn = Apollo.MutationFunction<
  GQLUpdateActionMutation,
  GQLUpdateActionMutationVariables
>;

/**
 * __useGQLUpdateActionMutation__
 *
 * To run a mutation, you first call `useGQLUpdateActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLUpdateActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlUpdateActionMutation, { data, loading, error }] = useGQLUpdateActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLUpdateActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLUpdateActionMutation,
    GQLUpdateActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLUpdateActionMutation,
    GQLUpdateActionMutationVariables
  >(GQLUpdateActionDocument, options);
}
export type GQLUpdateActionMutationHookResult = ReturnType<
  typeof useGQLUpdateActionMutation
>;
export type GQLUpdateActionMutationResult =
  Apollo.MutationResult<GQLUpdateActionMutation>;
export type GQLUpdateActionMutationOptions = Apollo.BaseMutationOptions<
  GQLUpdateActionMutation,
  GQLUpdateActionMutationVariables
>;
export const GQLActionsDocument = gql`
  query Actions {
    myOrg {
      actions {
        ... on ActionBase {
          id
          name
          description
          penalty
          applyUserStrikes
        }
      }
    }
    me {
      permissions
    }
  }
`;

/**
 * __useGQLActionsQuery__
 *
 * To run a query within a React component, call `useGQLActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLActionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLActionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLActionsQuery,
    GQLActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLActionsQuery, GQLActionsQueryVariables>(
    GQLActionsDocument,
    options,
  );
}
export function useGQLActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLActionsQuery,
    GQLActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQLActionsQuery, GQLActionsQueryVariables>(
    GQLActionsDocument,
    options,
  );
}
export type GQLActionsQueryHookResult = ReturnType<typeof useGQLActionsQuery>;
export type GQLActionsLazyQueryHookResult = ReturnType<
  typeof useGQLActionsLazyQuery
>;
export type GQLActionsQueryResult = Apollo.QueryResult<
  GQLActionsQuery,
  GQLActionsQueryVariables
>;
export const GQLDeleteActionDocument = gql`
  mutation DeleteAction($id: ID!) {
    deleteAction(id: $id)
  }
`;
export type GQLDeleteActionMutationFn = Apollo.MutationFunction<
  GQLDeleteActionMutation,
  GQLDeleteActionMutationVariables
>;

/**
 * __useGQLDeleteActionMutation__
 *
 * To run a mutation, you first call `useGQLDeleteActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLDeleteActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlDeleteActionMutation, { data, loading, error }] = useGQLDeleteActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLDeleteActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLDeleteActionMutation,
    GQLDeleteActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLDeleteActionMutation,
    GQLDeleteActionMutationVariables
  >(GQLDeleteActionDocument, options);
}
export type GQLDeleteActionMutationHookResult = ReturnType<
  typeof useGQLDeleteActionMutation
>;
export type GQLDeleteActionMutationResult =
  Apollo.MutationResult<GQLDeleteActionMutation>;
export type GQLDeleteActionMutationOptions = Apollo.BaseMutationOptions<
  GQLDeleteActionMutation,
  GQLDeleteActionMutationVariables
>;
export const GQLCreateLocationBankDocument = gql`
  mutation CreateLocationBank($input: CreateLocationBankInput!) {
    createLocationBank(input: $input) {
      ... on MutateLocationBankSuccessResponse {
        data {
          id
        }
      }
    }
  }
`;
export type GQLCreateLocationBankMutationFn = Apollo.MutationFunction<
  GQLCreateLocationBankMutation,
  GQLCreateLocationBankMutationVariables
>;

/**
 * __useGQLCreateLocationBankMutation__
 *
 * To run a mutation, you first call `useGQLCreateLocationBankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLCreateLocationBankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCreateLocationBankMutation, { data, loading, error }] = useGQLCreateLocationBankMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLCreateLocationBankMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLCreateLocationBankMutation,
    GQLCreateLocationBankMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLCreateLocationBankMutation,
    GQLCreateLocationBankMutationVariables
  >(GQLCreateLocationBankDocument, options);
}
export type GQLCreateLocationBankMutationHookResult = ReturnType<
  typeof useGQLCreateLocationBankMutation
>;
export type GQLCreateLocationBankMutationResult =
  Apollo.MutationResult<GQLCreateLocationBankMutation>;
export type GQLCreateLocationBankMutationOptions = Apollo.BaseMutationOptions<
  GQLCreateLocationBankMutation,
  GQLCreateLocationBankMutationVariables
>;
export const GQLUpdateLocationBankDocument = gql`
  mutation UpdateLocationBank($input: UpdateLocationBankInput!) {
    updateLocationBank(input: $input) {
      ... on MutateLocationBankSuccessResponse {
        data {
          id
        }
      }
    }
  }
`;
export type GQLUpdateLocationBankMutationFn = Apollo.MutationFunction<
  GQLUpdateLocationBankMutation,
  GQLUpdateLocationBankMutationVariables
>;

/**
 * __useGQLUpdateLocationBankMutation__
 *
 * To run a mutation, you first call `useGQLUpdateLocationBankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLUpdateLocationBankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlUpdateLocationBankMutation, { data, loading, error }] = useGQLUpdateLocationBankMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLUpdateLocationBankMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLUpdateLocationBankMutation,
    GQLUpdateLocationBankMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLUpdateLocationBankMutation,
    GQLUpdateLocationBankMutationVariables
  >(GQLUpdateLocationBankDocument, options);
}
export type GQLUpdateLocationBankMutationHookResult = ReturnType<
  typeof useGQLUpdateLocationBankMutation
>;
export type GQLUpdateLocationBankMutationResult =
  Apollo.MutationResult<GQLUpdateLocationBankMutation>;
export type GQLUpdateLocationBankMutationOptions = Apollo.BaseMutationOptions<
  GQLUpdateLocationBankMutation,
  GQLUpdateLocationBankMutationVariables
>;
export const GQLLocationBankDocument = gql`
  query LocationBank($id: ID!) {
    locationBank(id: $id) {
      id
      name
      description
      locations {
        id
        name
        geometry {
          center {
            lat
            lng
          }
          radius
        }
        googlePlaceInfo {
          id
        }
        bounds {
          northeastCorner {
            lat
            lng
          }
          southwestCorner {
            lat
            lng
          }
        }
      }
    }
  }
`;

/**
 * __useGQLLocationBankQuery__
 *
 * To run a query within a React component, call `useGQLLocationBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLLocationBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLLocationBankQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLLocationBankQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLLocationBankQuery,
    GQLLocationBankQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLLocationBankQuery, GQLLocationBankQueryVariables>(
    GQLLocationBankDocument,
    options,
  );
}
export function useGQLLocationBankLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLLocationBankQuery,
    GQLLocationBankQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLLocationBankQuery,
    GQLLocationBankQueryVariables
  >(GQLLocationBankDocument, options);
}
export type GQLLocationBankQueryHookResult = ReturnType<
  typeof useGQLLocationBankQuery
>;
export type GQLLocationBankLazyQueryHookResult = ReturnType<
  typeof useGQLLocationBankLazyQuery
>;
export type GQLLocationBankQueryResult = Apollo.QueryResult<
  GQLLocationBankQuery,
  GQLLocationBankQueryVariables
>;
export const GQLLocationBankFormDocument = gql`
  query LocationBankForm {
    me {
      permissions
    }
  }
`;

/**
 * __useGQLLocationBankFormQuery__
 *
 * To run a query within a React component, call `useGQLLocationBankFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLLocationBankFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLLocationBankFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLLocationBankFormQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLLocationBankFormQuery,
    GQLLocationBankFormQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLLocationBankFormQuery,
    GQLLocationBankFormQueryVariables
  >(GQLLocationBankFormDocument, options);
}
export function useGQLLocationBankFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLLocationBankFormQuery,
    GQLLocationBankFormQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLLocationBankFormQuery,
    GQLLocationBankFormQueryVariables
  >(GQLLocationBankFormDocument, options);
}
export type GQLLocationBankFormQueryHookResult = ReturnType<
  typeof useGQLLocationBankFormQuery
>;
export type GQLLocationBankFormLazyQueryHookResult = ReturnType<
  typeof useGQLLocationBankFormLazyQuery
>;
export type GQLLocationBankFormQueryResult = Apollo.QueryResult<
  GQLLocationBankFormQuery,
  GQLLocationBankFormQueryVariables
>;
export const GQLLocationBanksDocument = gql`
  query LocationBanks {
    myOrg {
      banks {
        locationBanks {
          id
          name
          description
        }
      }
    }
    me {
      permissions
    }
  }
`;

/**
 * __useGQLLocationBanksQuery__
 *
 * To run a query within a React component, call `useGQLLocationBanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLLocationBanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLLocationBanksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLLocationBanksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLLocationBanksQuery,
    GQLLocationBanksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLLocationBanksQuery, GQLLocationBanksQueryVariables>(
    GQLLocationBanksDocument,
    options,
  );
}
export function useGQLLocationBanksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLLocationBanksQuery,
    GQLLocationBanksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLLocationBanksQuery,
    GQLLocationBanksQueryVariables
  >(GQLLocationBanksDocument, options);
}
export type GQLLocationBanksQueryHookResult = ReturnType<
  typeof useGQLLocationBanksQuery
>;
export type GQLLocationBanksLazyQueryHookResult = ReturnType<
  typeof useGQLLocationBanksLazyQuery
>;
export type GQLLocationBanksQueryResult = Apollo.QueryResult<
  GQLLocationBanksQuery,
  GQLLocationBanksQueryVariables
>;
export const GQLDeleteLocationBankDocument = gql`
  mutation DeleteLocationBank($id: ID!) {
    deleteLocationBank(id: $id)
  }
`;
export type GQLDeleteLocationBankMutationFn = Apollo.MutationFunction<
  GQLDeleteLocationBankMutation,
  GQLDeleteLocationBankMutationVariables
>;

/**
 * __useGQLDeleteLocationBankMutation__
 *
 * To run a mutation, you first call `useGQLDeleteLocationBankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLDeleteLocationBankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlDeleteLocationBankMutation, { data, loading, error }] = useGQLDeleteLocationBankMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLDeleteLocationBankMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLDeleteLocationBankMutation,
    GQLDeleteLocationBankMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLDeleteLocationBankMutation,
    GQLDeleteLocationBankMutationVariables
  >(GQLDeleteLocationBankDocument, options);
}
export type GQLDeleteLocationBankMutationHookResult = ReturnType<
  typeof useGQLDeleteLocationBankMutation
>;
export type GQLDeleteLocationBankMutationResult =
  Apollo.MutationResult<GQLDeleteLocationBankMutation>;
export type GQLDeleteLocationBankMutationOptions = Apollo.BaseMutationOptions<
  GQLDeleteLocationBankMutation,
  GQLDeleteLocationBankMutationVariables
>;
export const GQLCreateTextBankDocument = gql`
  mutation CreateTextBank($input: CreateTextBankInput!) {
    createTextBank(input: $input) {
      success
      error
    }
  }
`;
export type GQLCreateTextBankMutationFn = Apollo.MutationFunction<
  GQLCreateTextBankMutation,
  GQLCreateTextBankMutationVariables
>;

/**
 * __useGQLCreateTextBankMutation__
 *
 * To run a mutation, you first call `useGQLCreateTextBankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLCreateTextBankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCreateTextBankMutation, { data, loading, error }] = useGQLCreateTextBankMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLCreateTextBankMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLCreateTextBankMutation,
    GQLCreateTextBankMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLCreateTextBankMutation,
    GQLCreateTextBankMutationVariables
  >(GQLCreateTextBankDocument, options);
}
export type GQLCreateTextBankMutationHookResult = ReturnType<
  typeof useGQLCreateTextBankMutation
>;
export type GQLCreateTextBankMutationResult =
  Apollo.MutationResult<GQLCreateTextBankMutation>;
export type GQLCreateTextBankMutationOptions = Apollo.BaseMutationOptions<
  GQLCreateTextBankMutation,
  GQLCreateTextBankMutationVariables
>;
export const GQLUpdateTextBankDocument = gql`
  mutation UpdateTextBank($input: UpdateTextBankInput!) {
    updateTextBank(input: $input) {
      success
      error
    }
  }
`;
export type GQLUpdateTextBankMutationFn = Apollo.MutationFunction<
  GQLUpdateTextBankMutation,
  GQLUpdateTextBankMutationVariables
>;

/**
 * __useGQLUpdateTextBankMutation__
 *
 * To run a mutation, you first call `useGQLUpdateTextBankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLUpdateTextBankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlUpdateTextBankMutation, { data, loading, error }] = useGQLUpdateTextBankMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLUpdateTextBankMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLUpdateTextBankMutation,
    GQLUpdateTextBankMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLUpdateTextBankMutation,
    GQLUpdateTextBankMutationVariables
  >(GQLUpdateTextBankDocument, options);
}
export type GQLUpdateTextBankMutationHookResult = ReturnType<
  typeof useGQLUpdateTextBankMutation
>;
export type GQLUpdateTextBankMutationResult =
  Apollo.MutationResult<GQLUpdateTextBankMutation>;
export type GQLUpdateTextBankMutationOptions = Apollo.BaseMutationOptions<
  GQLUpdateTextBankMutation,
  GQLUpdateTextBankMutationVariables
>;
export const GQLTextBankDocument = gql`
  query TextBank($id: ID!) {
    textBank(id: $id) {
      id
      name
      description
      type
      strings
    }
  }
`;

/**
 * __useGQLTextBankQuery__
 *
 * To run a query within a React component, call `useGQLTextBankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLTextBankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLTextBankQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLTextBankQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLTextBankQuery,
    GQLTextBankQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLTextBankQuery, GQLTextBankQueryVariables>(
    GQLTextBankDocument,
    options,
  );
}
export function useGQLTextBankLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLTextBankQuery,
    GQLTextBankQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQLTextBankQuery, GQLTextBankQueryVariables>(
    GQLTextBankDocument,
    options,
  );
}
export type GQLTextBankQueryHookResult = ReturnType<typeof useGQLTextBankQuery>;
export type GQLTextBankLazyQueryHookResult = ReturnType<
  typeof useGQLTextBankLazyQuery
>;
export type GQLTextBankQueryResult = Apollo.QueryResult<
  GQLTextBankQuery,
  GQLTextBankQueryVariables
>;
export const GQLTextBankFormDocument = gql`
  query TextBankForm {
    me {
      permissions
    }
  }
`;

/**
 * __useGQLTextBankFormQuery__
 *
 * To run a query within a React component, call `useGQLTextBankFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLTextBankFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLTextBankFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLTextBankFormQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLTextBankFormQuery,
    GQLTextBankFormQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLTextBankFormQuery, GQLTextBankFormQueryVariables>(
    GQLTextBankFormDocument,
    options,
  );
}
export function useGQLTextBankFormLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLTextBankFormQuery,
    GQLTextBankFormQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLTextBankFormQuery,
    GQLTextBankFormQueryVariables
  >(GQLTextBankFormDocument, options);
}
export type GQLTextBankFormQueryHookResult = ReturnType<
  typeof useGQLTextBankFormQuery
>;
export type GQLTextBankFormLazyQueryHookResult = ReturnType<
  typeof useGQLTextBankFormLazyQuery
>;
export type GQLTextBankFormQueryResult = Apollo.QueryResult<
  GQLTextBankFormQuery,
  GQLTextBankFormQueryVariables
>;
export const GQLTextBanksDocument = gql`
  query TextBanks {
    myOrg {
      banks {
        textBanks {
          id
          name
          description
          type
        }
      }
    }
    me {
      permissions
    }
  }
`;

/**
 * __useGQLTextBanksQuery__
 *
 * To run a query within a React component, call `useGQLTextBanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLTextBanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLTextBanksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLTextBanksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLTextBanksQuery,
    GQLTextBanksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLTextBanksQuery, GQLTextBanksQueryVariables>(
    GQLTextBanksDocument,
    options,
  );
}
export function useGQLTextBanksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLTextBanksQuery,
    GQLTextBanksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQLTextBanksQuery, GQLTextBanksQueryVariables>(
    GQLTextBanksDocument,
    options,
  );
}
export type GQLTextBanksQueryHookResult = ReturnType<
  typeof useGQLTextBanksQuery
>;
export type GQLTextBanksLazyQueryHookResult = ReturnType<
  typeof useGQLTextBanksLazyQuery
>;
export type GQLTextBanksQueryResult = Apollo.QueryResult<
  GQLTextBanksQuery,
  GQLTextBanksQueryVariables
>;
export const GQLDeleteTextBankDocument = gql`
  mutation DeleteTextBank($id: ID!) {
    deleteTextBank(id: $id)
  }
`;
export type GQLDeleteTextBankMutationFn = Apollo.MutationFunction<
  GQLDeleteTextBankMutation,
  GQLDeleteTextBankMutationVariables
>;

/**
 * __useGQLDeleteTextBankMutation__
 *
 * To run a mutation, you first call `useGQLDeleteTextBankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLDeleteTextBankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlDeleteTextBankMutation, { data, loading, error }] = useGQLDeleteTextBankMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLDeleteTextBankMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLDeleteTextBankMutation,
    GQLDeleteTextBankMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLDeleteTextBankMutation,
    GQLDeleteTextBankMutationVariables
  >(GQLDeleteTextBankDocument, options);
}
export type GQLDeleteTextBankMutationHookResult = ReturnType<
  typeof useGQLDeleteTextBankMutation
>;
export type GQLDeleteTextBankMutationResult =
  Apollo.MutationResult<GQLDeleteTextBankMutation>;
export type GQLDeleteTextBankMutationOptions = Apollo.BaseMutationOptions<
  GQLDeleteTextBankMutation,
  GQLDeleteTextBankMutationVariables
>;
export const GQLBulkActionsFormDataDocument = gql`
  query BulkActionsFormData {
    myOrg {
      id
      itemTypes {
        ... on ItemTypeBase {
          id
          name
        }
      }
      actions {
        ... on ActionBase {
          id
          name
        }
        ... on CustomerDefinedAction {
          itemTypes {
            ... on ItemTypeBase {
              id
            }
          }
        }
        ... on EnqueueToMrtAction {
          itemTypes {
            ... on ItemTypeBase {
              id
            }
          }
        }
        ... on EnqueueToNcmecAction {
          itemTypes {
            ... on ItemTypeBase {
              id
            }
          }
        }
        ... on EnqueueAuthorToMrtAction {
          itemTypes {
            ... on ItemTypeBase {
              id
            }
          }
        }
      }
      policies {
        id
        name
        parentId
      }
      allowMultiplePoliciesPerAction
    }
    me {
      permissions
    }
  }
`;

/**
 * __useGQLBulkActionsFormDataQuery__
 *
 * To run a query within a React component, call `useGQLBulkActionsFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLBulkActionsFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLBulkActionsFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLBulkActionsFormDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLBulkActionsFormDataQuery,
    GQLBulkActionsFormDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLBulkActionsFormDataQuery,
    GQLBulkActionsFormDataQueryVariables
  >(GQLBulkActionsFormDataDocument, options);
}
export function useGQLBulkActionsFormDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLBulkActionsFormDataQuery,
    GQLBulkActionsFormDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLBulkActionsFormDataQuery,
    GQLBulkActionsFormDataQueryVariables
  >(GQLBulkActionsFormDataDocument, options);
}
export type GQLBulkActionsFormDataQueryHookResult = ReturnType<
  typeof useGQLBulkActionsFormDataQuery
>;
export type GQLBulkActionsFormDataLazyQueryHookResult = ReturnType<
  typeof useGQLBulkActionsFormDataLazyQuery
>;
export type GQLBulkActionsFormDataQueryResult = Apollo.QueryResult<
  GQLBulkActionsFormDataQuery,
  GQLBulkActionsFormDataQueryVariables
>;
export const GQLBulkActionExecutionDocument = gql`
  mutation BulkActionExecution($input: ExecuteBulkActionInput!) {
    bulkExecuteActions(input: $input) {
      results {
        itemId
        actionId
        success
      }
    }
  }
`;
export type GQLBulkActionExecutionMutationFn = Apollo.MutationFunction<
  GQLBulkActionExecutionMutation,
  GQLBulkActionExecutionMutationVariables
>;

/**
 * __useGQLBulkActionExecutionMutation__
 *
 * To run a mutation, you first call `useGQLBulkActionExecutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLBulkActionExecutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlBulkActionExecutionMutation, { data, loading, error }] = useGQLBulkActionExecutionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLBulkActionExecutionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLBulkActionExecutionMutation,
    GQLBulkActionExecutionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLBulkActionExecutionMutation,
    GQLBulkActionExecutionMutationVariables
  >(GQLBulkActionExecutionDocument, options);
}
export type GQLBulkActionExecutionMutationHookResult = ReturnType<
  typeof useGQLBulkActionExecutionMutation
>;
export type GQLBulkActionExecutionMutationResult =
  Apollo.MutationResult<GQLBulkActionExecutionMutation>;
export type GQLBulkActionExecutionMutationOptions = Apollo.BaseMutationOptions<
  GQLBulkActionExecutionMutation,
  GQLBulkActionExecutionMutationVariables
>;
export const GQLMatchingBankIdsDocument = gql`
  query MatchingBankIds {
    myOrg {
      banks {
        textBanks {
          id
          name
          description
          type
        }
        locationBanks {
          id
          name
          description
          locations {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useGQLMatchingBankIdsQuery__
 *
 * To run a query within a React component, call `useGQLMatchingBankIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLMatchingBankIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLMatchingBankIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLMatchingBankIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLMatchingBankIdsQuery,
    GQLMatchingBankIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLMatchingBankIdsQuery,
    GQLMatchingBankIdsQueryVariables
  >(GQLMatchingBankIdsDocument, options);
}
export function useGQLMatchingBankIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLMatchingBankIdsQuery,
    GQLMatchingBankIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLMatchingBankIdsQuery,
    GQLMatchingBankIdsQueryVariables
  >(GQLMatchingBankIdsDocument, options);
}
export type GQLMatchingBankIdsQueryHookResult = ReturnType<
  typeof useGQLMatchingBankIdsQuery
>;
export type GQLMatchingBankIdsLazyQueryHookResult = ReturnType<
  typeof useGQLMatchingBankIdsLazyQuery
>;
export type GQLMatchingBankIdsQueryResult = Apollo.QueryResult<
  GQLMatchingBankIdsQuery,
  GQLMatchingBankIdsQueryVariables
>;
export const GQLSetIntegrationConfigDocument = gql`
  mutation SetIntegrationConfig($input: SetIntegrationConfigInput!) {
    setIntegrationConfig(input: $input) {
      ... on SetIntegrationConfigSuccessResponse {
        config {
          name
        }
      }
      ... on IntegrationConfigTooManyCredentialsError {
        title
      }
      ... on IntegrationNoInputCredentialsError {
        title
      }
      ... on IntegrationEmptyInputCredentialsError {
        title
      }
    }
  }
`;
export type GQLSetIntegrationConfigMutationFn = Apollo.MutationFunction<
  GQLSetIntegrationConfigMutation,
  GQLSetIntegrationConfigMutationVariables
>;

/**
 * __useGQLSetIntegrationConfigMutation__
 *
 * To run a mutation, you first call `useGQLSetIntegrationConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLSetIntegrationConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlSetIntegrationConfigMutation, { data, loading, error }] = useGQLSetIntegrationConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLSetIntegrationConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLSetIntegrationConfigMutation,
    GQLSetIntegrationConfigMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLSetIntegrationConfigMutation,
    GQLSetIntegrationConfigMutationVariables
  >(GQLSetIntegrationConfigDocument, options);
}
export type GQLSetIntegrationConfigMutationHookResult = ReturnType<
  typeof useGQLSetIntegrationConfigMutation
>;
export type GQLSetIntegrationConfigMutationResult =
  Apollo.MutationResult<GQLSetIntegrationConfigMutation>;
export type GQLSetIntegrationConfigMutationOptions = Apollo.BaseMutationOptions<
  GQLSetIntegrationConfigMutation,
  GQLSetIntegrationConfigMutationVariables
>;
export const GQLIntegrationConfigDocument = gql`
  query IntegrationConfig($name: Integration!) {
    integrationConfig(name: $name) {
      ... on IntegrationConfigSuccessResult {
        config {
          name
          apiCredential {
            ... on HiveIntegrationApiCredential {
              apiKeys {
                apiKey
                project
              }
            }
            ... on SpectrumIntegrationApiCredential {
              apiKey
              clientId
            }
            ... on OpenAiIntegrationApiCredential {
              apiKey
            }
            ... on PerspectiveIntegrationApiCredential {
              apiKey
            }
            ... on ClarifaiIntegrationApiCredential {
              text {
                ... on ClarifaiIntegrationApiKey {
                  apiKey
                }
                ... on ClarifaiIntegrationPersonalAccessToken {
                  pat
                  userId
                  appId
                }
              }
              image {
                ... on ClarifaiIntegrationApiKey {
                  apiKey
                }
                ... on ClarifaiIntegrationPersonalAccessToken {
                  pat
                  userId
                  appId
                }
              }
            }
            ... on AmazonRekognitionIntegrationApiCredential {
              awsAccessKeyId
              awsSecretAccessKey
            }
            ... on CheckstepIntegrationApiCredential {
              apiKeys {
                apiKey
                platform
              }
            }
          }
        }
      }
      ... on IntegrationConfigUnsupportedIntegrationError {
        title
      }
      ... on IntegrationConfigUnsupportedIntegrationError {
        title
      }
    }
  }
`;

/**
 * __useGQLIntegrationConfigQuery__
 *
 * To run a query within a React component, call `useGQLIntegrationConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLIntegrationConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLIntegrationConfigQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGQLIntegrationConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLIntegrationConfigQuery,
    GQLIntegrationConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLIntegrationConfigQuery,
    GQLIntegrationConfigQueryVariables
  >(GQLIntegrationConfigDocument, options);
}
export function useGQLIntegrationConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLIntegrationConfigQuery,
    GQLIntegrationConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLIntegrationConfigQuery,
    GQLIntegrationConfigQueryVariables
  >(GQLIntegrationConfigDocument, options);
}
export type GQLIntegrationConfigQueryHookResult = ReturnType<
  typeof useGQLIntegrationConfigQuery
>;
export type GQLIntegrationConfigLazyQueryHookResult = ReturnType<
  typeof useGQLIntegrationConfigLazyQuery
>;
export type GQLIntegrationConfigQueryResult = Apollo.QueryResult<
  GQLIntegrationConfigQuery,
  GQLIntegrationConfigQueryVariables
>;
export const GQLMyIntegrationsDocument = gql`
  query MyIntegrations {
    myOrg {
      integrationConfigs {
        name
      }
    }
  }
`;

/**
 * __useGQLMyIntegrationsQuery__
 *
 * To run a query within a React component, call `useGQLMyIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLMyIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLMyIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLMyIntegrationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLMyIntegrationsQuery,
    GQLMyIntegrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLMyIntegrationsQuery,
    GQLMyIntegrationsQueryVariables
  >(GQLMyIntegrationsDocument, options);
}
export function useGQLMyIntegrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLMyIntegrationsQuery,
    GQLMyIntegrationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLMyIntegrationsQuery,
    GQLMyIntegrationsQueryVariables
  >(GQLMyIntegrationsDocument, options);
}
export type GQLMyIntegrationsQueryHookResult = ReturnType<
  typeof useGQLMyIntegrationsQuery
>;
export type GQLMyIntegrationsLazyQueryHookResult = ReturnType<
  typeof useGQLMyIntegrationsLazyQuery
>;
export type GQLMyIntegrationsQueryResult = Apollo.QueryResult<
  GQLMyIntegrationsQuery,
  GQLMyIntegrationsQueryVariables
>;
export const GQLInvestigationItemTypesDocument = gql`
  query InvestigationItemTypes {
    myOrg {
      itemTypes {
        ...ItemTypeFragment
      }
    }
  }
  ${GQLItemTypeFragmentFragmentDoc}
`;

/**
 * __useGQLInvestigationItemTypesQuery__
 *
 * To run a query within a React component, call `useGQLInvestigationItemTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLInvestigationItemTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLInvestigationItemTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLInvestigationItemTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLInvestigationItemTypesQuery,
    GQLInvestigationItemTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLInvestigationItemTypesQuery,
    GQLInvestigationItemTypesQueryVariables
  >(GQLInvestigationItemTypesDocument, options);
}
export function useGQLInvestigationItemTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLInvestigationItemTypesQuery,
    GQLInvestigationItemTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLInvestigationItemTypesQuery,
    GQLInvestigationItemTypesQueryVariables
  >(GQLInvestigationItemTypesDocument, options);
}
export type GQLInvestigationItemTypesQueryHookResult = ReturnType<
  typeof useGQLInvestigationItemTypesQuery
>;
export type GQLInvestigationItemTypesLazyQueryHookResult = ReturnType<
  typeof useGQLInvestigationItemTypesLazyQuery
>;
export type GQLInvestigationItemTypesQueryResult = Apollo.QueryResult<
  GQLInvestigationItemTypesQuery,
  GQLInvestigationItemTypesQueryVariables
>;
export const GQLGetOrgDataDocument = gql`
  query GetOrgData {
    myOrg {
      actions {
        ... on ActionBase {
          id
          name
          penalty
          itemTypes {
            ... on ItemTypeBase {
              id
              name
            }
          }
        }
      }
      itemTypes {
        ...ItemTypeFragment
      }
      policies {
        id
        name
        parentId
      }
      rules {
        id
        actions {
          ... on ActionBase {
            id
            name
          }
        }
      }
      requiresPolicyForDecisionsInMrt
      allowMultiplePoliciesPerAction
    }
  }
  ${GQLItemTypeFragmentFragmentDoc}
`;

/**
 * __useGQLGetOrgDataQuery__
 *
 * To run a query within a React component, call `useGQLGetOrgDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetOrgDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetOrgDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLGetOrgDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLGetOrgDataQuery,
    GQLGetOrgDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLGetOrgDataQuery, GQLGetOrgDataQueryVariables>(
    GQLGetOrgDataDocument,
    options,
  );
}
export function useGQLGetOrgDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetOrgDataQuery,
    GQLGetOrgDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQLGetOrgDataQuery, GQLGetOrgDataQueryVariables>(
    GQLGetOrgDataDocument,
    options,
  );
}
export type GQLGetOrgDataQueryHookResult = ReturnType<
  typeof useGQLGetOrgDataQuery
>;
export type GQLGetOrgDataLazyQueryHookResult = ReturnType<
  typeof useGQLGetOrgDataLazyQuery
>;
export type GQLGetOrgDataQueryResult = Apollo.QueryResult<
  GQLGetOrgDataQuery,
  GQLGetOrgDataQueryVariables
>;
export const GQLGetItemsWithIdDocument = gql`
  query GetItemsWithId($id: ID!, $typeId: ID) {
    itemsWithId(itemId: $id, typeId: $typeId, returnFirstResultOnly: true) {
      latest {
        ... on ItemBase {
          id
          data
          submissionId
          submissionTime
          type {
            ... on ItemTypeBase {
              id
              name
              version
              schemaVariant
              baseFields {
                name
                type
                required
                container {
                  containerType
                  keyScalarType
                  valueScalarType
                }
              }
              ... on ContentItemType {
                schemaFieldRoles {
                  displayName
                  parentId
                  threadId
                  createdAt
                  creatorId
                }
              }
              ... on UserItemType {
                schemaFieldRoles {
                  displayName
                  createdAt
                  profileIcon
                }
              }
              ... on ThreadItemType {
                schemaFieldRoles {
                  displayName
                  createdAt
                  creatorId
                }
              }
            }
          }
        }
        ... on UserItem {
          userScore
        }
      }
    }
  }
`;

/**
 * __useGQLGetItemsWithIdQuery__
 *
 * To run a query within a React component, call `useGQLGetItemsWithIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetItemsWithIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetItemsWithIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      typeId: // value for 'typeId'
 *   },
 * });
 */
export function useGQLGetItemsWithIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetItemsWithIdQuery,
    GQLGetItemsWithIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetItemsWithIdQuery,
    GQLGetItemsWithIdQueryVariables
  >(GQLGetItemsWithIdDocument, options);
}
export function useGQLGetItemsWithIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetItemsWithIdQuery,
    GQLGetItemsWithIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetItemsWithIdQuery,
    GQLGetItemsWithIdQueryVariables
  >(GQLGetItemsWithIdDocument, options);
}
export type GQLGetItemsWithIdQueryHookResult = ReturnType<
  typeof useGQLGetItemsWithIdQuery
>;
export type GQLGetItemsWithIdLazyQueryHookResult = ReturnType<
  typeof useGQLGetItemsWithIdLazyQuery
>;
export type GQLGetItemsWithIdQueryResult = Apollo.QueryResult<
  GQLGetItemsWithIdQuery,
  GQLGetItemsWithIdQueryVariables
>;
export const GQLInvestigationItemsDocument = gql`
  query InvestigationItems(
    $itemIdentifier: ItemIdentifierInput!
    $submissionTime: DateTime
  ) {
    itemWithHistory(
      itemIdentifier: $itemIdentifier
      submissionTime: $submissionTime
    ) {
      ... on ItemHistoryResult {
        item {
          ... on ItemBase {
            id
            submissionId
            type {
              ... on ItemTypeBase {
                id
              }
            }
          }
        }
        executions {
          date
          ts
          contentId
          itemTypeName
          itemTypeId
          userId
          userTypeId
          content
          environment
          passed
          ruleId
          ruleName
          policies
          tags
          result {
            conjunction
            conditions {
              ... on ConditionSetWithResult {
                conjunction
                conditions {
                  ... on LeafConditionWithResult {
                    ...LeafConditionWithResultFields
                  }
                }
                result {
                  outcome
                  score
                  matchedValue
                }
              }
              ... on LeafConditionWithResult {
                ...LeafConditionWithResultFields
              }
            }
            result {
              outcome
              score
              matchedValue
            }
          }
        }
      }
      ... on NotFoundError {
        title
      }
    }
  }
  ${GQLLeafConditionWithResultFieldsFragmentDoc}
`;

/**
 * __useGQLInvestigationItemsQuery__
 *
 * To run a query within a React component, call `useGQLInvestigationItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLInvestigationItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLInvestigationItemsQuery({
 *   variables: {
 *      itemIdentifier: // value for 'itemIdentifier'
 *      submissionTime: // value for 'submissionTime'
 *   },
 * });
 */
export function useGQLInvestigationItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLInvestigationItemsQuery,
    GQLInvestigationItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLInvestigationItemsQuery,
    GQLInvestigationItemsQueryVariables
  >(GQLInvestigationItemsDocument, options);
}
export function useGQLInvestigationItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLInvestigationItemsQuery,
    GQLInvestigationItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLInvestigationItemsQuery,
    GQLInvestigationItemsQueryVariables
  >(GQLInvestigationItemsDocument, options);
}
export type GQLInvestigationItemsQueryHookResult = ReturnType<
  typeof useGQLInvestigationItemsQuery
>;
export type GQLInvestigationItemsLazyQueryHookResult = ReturnType<
  typeof useGQLInvestigationItemsLazyQuery
>;
export type GQLInvestigationItemsQueryResult = Apollo.QueryResult<
  GQLInvestigationItemsQuery,
  GQLInvestigationItemsQueryVariables
>;
export const GQLGetAuthorInfoDocument = gql`
  query GetAuthorInfo($userIdentifiers: [ItemIdentifierInput!]!) {
    latestItemSubmissions(itemIdentifiers: $userIdentifiers) {
      ... on UserItem {
        id
        data
        submissionId
        submissionTime
        type {
          ... on UserItemType {
            id
            baseFields {
              name
              required
              type
              container {
                containerType
                keyScalarType
                valueScalarType
              }
            }
            schemaFieldRoles {
              displayName
              createdAt
              profileIcon
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGQLGetAuthorInfoQuery__
 *
 * To run a query within a React component, call `useGQLGetAuthorInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetAuthorInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetAuthorInfoQuery({
 *   variables: {
 *      userIdentifiers: // value for 'userIdentifiers'
 *   },
 * });
 */
export function useGQLGetAuthorInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetAuthorInfoQuery,
    GQLGetAuthorInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLGetAuthorInfoQuery, GQLGetAuthorInfoQueryVariables>(
    GQLGetAuthorInfoDocument,
    options,
  );
}
export function useGQLGetAuthorInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetAuthorInfoQuery,
    GQLGetAuthorInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetAuthorInfoQuery,
    GQLGetAuthorInfoQueryVariables
  >(GQLGetAuthorInfoDocument, options);
}
export type GQLGetAuthorInfoQueryHookResult = ReturnType<
  typeof useGQLGetAuthorInfoQuery
>;
export type GQLGetAuthorInfoLazyQueryHookResult = ReturnType<
  typeof useGQLGetAuthorInfoLazyQuery
>;
export type GQLGetAuthorInfoQueryResult = Apollo.QueryResult<
  GQLGetAuthorInfoQuery,
  GQLGetAuthorInfoQueryVariables
>;
export const GQLItemTypeDocument = gql`
  query ItemType($id: ID!) {
    itemType(id: $id) {
      ...ItemTypeFragment
    }
  }
  ${GQLItemTypeFragmentFragmentDoc}
`;

/**
 * __useGQLItemTypeQuery__
 *
 * To run a query within a React component, call `useGQLItemTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLItemTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLItemTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLItemTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLItemTypeQuery,
    GQLItemTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLItemTypeQuery, GQLItemTypeQueryVariables>(
    GQLItemTypeDocument,
    options,
  );
}
export function useGQLItemTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLItemTypeQuery,
    GQLItemTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQLItemTypeQuery, GQLItemTypeQueryVariables>(
    GQLItemTypeDocument,
    options,
  );
}
export type GQLItemTypeQueryHookResult = ReturnType<typeof useGQLItemTypeQuery>;
export type GQLItemTypeLazyQueryHookResult = ReturnType<
  typeof useGQLItemTypeLazyQuery
>;
export type GQLItemTypeQueryResult = Apollo.QueryResult<
  GQLItemTypeQuery,
  GQLItemTypeQueryVariables
>;
export const GQLItemTypeFormOrgDocument = gql`
  query ItemTypeFormOrg {
    myOrg {
      hasMrtAccess
    }
  }
`;

/**
 * __useGQLItemTypeFormOrgQuery__
 *
 * To run a query within a React component, call `useGQLItemTypeFormOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLItemTypeFormOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLItemTypeFormOrgQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLItemTypeFormOrgQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLItemTypeFormOrgQuery,
    GQLItemTypeFormOrgQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLItemTypeFormOrgQuery,
    GQLItemTypeFormOrgQueryVariables
  >(GQLItemTypeFormOrgDocument, options);
}
export function useGQLItemTypeFormOrgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLItemTypeFormOrgQuery,
    GQLItemTypeFormOrgQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLItemTypeFormOrgQuery,
    GQLItemTypeFormOrgQueryVariables
  >(GQLItemTypeFormOrgDocument, options);
}
export type GQLItemTypeFormOrgQueryHookResult = ReturnType<
  typeof useGQLItemTypeFormOrgQuery
>;
export type GQLItemTypeFormOrgLazyQueryHookResult = ReturnType<
  typeof useGQLItemTypeFormOrgLazyQuery
>;
export type GQLItemTypeFormOrgQueryResult = Apollo.QueryResult<
  GQLItemTypeFormOrgQuery,
  GQLItemTypeFormOrgQueryVariables
>;
export const GQLDeleteItemTypeDocument = gql`
  mutation DeleteItemType($id: ID!) {
    deleteItemType(id: $id) {
      ... on DeleteItemTypeSuccessResponse {
        _
      }
      ... on CannotDeleteDefaultUserError {
        title
      }
    }
  }
`;
export type GQLDeleteItemTypeMutationFn = Apollo.MutationFunction<
  GQLDeleteItemTypeMutation,
  GQLDeleteItemTypeMutationVariables
>;

/**
 * __useGQLDeleteItemTypeMutation__
 *
 * To run a mutation, you first call `useGQLDeleteItemTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLDeleteItemTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlDeleteItemTypeMutation, { data, loading, error }] = useGQLDeleteItemTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLDeleteItemTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLDeleteItemTypeMutation,
    GQLDeleteItemTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLDeleteItemTypeMutation,
    GQLDeleteItemTypeMutationVariables
  >(GQLDeleteItemTypeDocument, options);
}
export type GQLDeleteItemTypeMutationHookResult = ReturnType<
  typeof useGQLDeleteItemTypeMutation
>;
export type GQLDeleteItemTypeMutationResult =
  Apollo.MutationResult<GQLDeleteItemTypeMutation>;
export type GQLDeleteItemTypeMutationOptions = Apollo.BaseMutationOptions<
  GQLDeleteItemTypeMutation,
  GQLDeleteItemTypeMutationVariables
>;
export const GQLCreateContentTypeDocument = gql`
  mutation CreateContentType($input: CreateContentItemTypeInput!) {
    createContentItemType(input: $input) {
      ... on MutateContentTypeSuccessResponse {
        data {
          id
        }
      }
      ... on Error {
        title
      }
    }
  }
`;
export type GQLCreateContentTypeMutationFn = Apollo.MutationFunction<
  GQLCreateContentTypeMutation,
  GQLCreateContentTypeMutationVariables
>;

/**
 * __useGQLCreateContentTypeMutation__
 *
 * To run a mutation, you first call `useGQLCreateContentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLCreateContentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCreateContentTypeMutation, { data, loading, error }] = useGQLCreateContentTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLCreateContentTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLCreateContentTypeMutation,
    GQLCreateContentTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLCreateContentTypeMutation,
    GQLCreateContentTypeMutationVariables
  >(GQLCreateContentTypeDocument, options);
}
export type GQLCreateContentTypeMutationHookResult = ReturnType<
  typeof useGQLCreateContentTypeMutation
>;
export type GQLCreateContentTypeMutationResult =
  Apollo.MutationResult<GQLCreateContentTypeMutation>;
export type GQLCreateContentTypeMutationOptions = Apollo.BaseMutationOptions<
  GQLCreateContentTypeMutation,
  GQLCreateContentTypeMutationVariables
>;
export const GQLUpdateContentTypeDocument = gql`
  mutation UpdateContentType($input: UpdateContentItemTypeInput!) {
    updateContentItemType(input: $input) {
      ... on MutateContentTypeSuccessResponse {
        data {
          id
        }
      }
      ... on Error {
        title
      }
    }
  }
`;
export type GQLUpdateContentTypeMutationFn = Apollo.MutationFunction<
  GQLUpdateContentTypeMutation,
  GQLUpdateContentTypeMutationVariables
>;

/**
 * __useGQLUpdateContentTypeMutation__
 *
 * To run a mutation, you first call `useGQLUpdateContentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLUpdateContentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlUpdateContentTypeMutation, { data, loading, error }] = useGQLUpdateContentTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLUpdateContentTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLUpdateContentTypeMutation,
    GQLUpdateContentTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLUpdateContentTypeMutation,
    GQLUpdateContentTypeMutationVariables
  >(GQLUpdateContentTypeDocument, options);
}
export type GQLUpdateContentTypeMutationHookResult = ReturnType<
  typeof useGQLUpdateContentTypeMutation
>;
export type GQLUpdateContentTypeMutationResult =
  Apollo.MutationResult<GQLUpdateContentTypeMutation>;
export type GQLUpdateContentTypeMutationOptions = Apollo.BaseMutationOptions<
  GQLUpdateContentTypeMutation,
  GQLUpdateContentTypeMutationVariables
>;
export const GQLCreateUserTypeDocument = gql`
  mutation CreateUserType($input: CreateUserItemTypeInput!) {
    createUserItemType(input: $input) {
      ... on MutateUserTypeSuccessResponse {
        data {
          id
        }
      }
      ... on Error {
        title
      }
    }
  }
`;
export type GQLCreateUserTypeMutationFn = Apollo.MutationFunction<
  GQLCreateUserTypeMutation,
  GQLCreateUserTypeMutationVariables
>;

/**
 * __useGQLCreateUserTypeMutation__
 *
 * To run a mutation, you first call `useGQLCreateUserTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLCreateUserTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCreateUserTypeMutation, { data, loading, error }] = useGQLCreateUserTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLCreateUserTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLCreateUserTypeMutation,
    GQLCreateUserTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLCreateUserTypeMutation,
    GQLCreateUserTypeMutationVariables
  >(GQLCreateUserTypeDocument, options);
}
export type GQLCreateUserTypeMutationHookResult = ReturnType<
  typeof useGQLCreateUserTypeMutation
>;
export type GQLCreateUserTypeMutationResult =
  Apollo.MutationResult<GQLCreateUserTypeMutation>;
export type GQLCreateUserTypeMutationOptions = Apollo.BaseMutationOptions<
  GQLCreateUserTypeMutation,
  GQLCreateUserTypeMutationVariables
>;
export const GQLUpdateUserTypeDocument = gql`
  mutation UpdateUserType($input: UpdateUserItemTypeInput!) {
    updateUserItemType(input: $input) {
      ... on MutateUserTypeSuccessResponse {
        data {
          id
        }
      }
      ... on Error {
        title
      }
    }
  }
`;
export type GQLUpdateUserTypeMutationFn = Apollo.MutationFunction<
  GQLUpdateUserTypeMutation,
  GQLUpdateUserTypeMutationVariables
>;

/**
 * __useGQLUpdateUserTypeMutation__
 *
 * To run a mutation, you first call `useGQLUpdateUserTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLUpdateUserTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlUpdateUserTypeMutation, { data, loading, error }] = useGQLUpdateUserTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLUpdateUserTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLUpdateUserTypeMutation,
    GQLUpdateUserTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLUpdateUserTypeMutation,
    GQLUpdateUserTypeMutationVariables
  >(GQLUpdateUserTypeDocument, options);
}
export type GQLUpdateUserTypeMutationHookResult = ReturnType<
  typeof useGQLUpdateUserTypeMutation
>;
export type GQLUpdateUserTypeMutationResult =
  Apollo.MutationResult<GQLUpdateUserTypeMutation>;
export type GQLUpdateUserTypeMutationOptions = Apollo.BaseMutationOptions<
  GQLUpdateUserTypeMutation,
  GQLUpdateUserTypeMutationVariables
>;
export const GQLCreateThreadTypeDocument = gql`
  mutation CreateThreadType($input: CreateThreadItemTypeInput!) {
    createThreadItemType(input: $input) {
      ... on MutateThreadTypeSuccessResponse {
        data {
          id
        }
      }
      ... on Error {
        title
      }
    }
  }
`;
export type GQLCreateThreadTypeMutationFn = Apollo.MutationFunction<
  GQLCreateThreadTypeMutation,
  GQLCreateThreadTypeMutationVariables
>;

/**
 * __useGQLCreateThreadTypeMutation__
 *
 * To run a mutation, you first call `useGQLCreateThreadTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLCreateThreadTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCreateThreadTypeMutation, { data, loading, error }] = useGQLCreateThreadTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLCreateThreadTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLCreateThreadTypeMutation,
    GQLCreateThreadTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLCreateThreadTypeMutation,
    GQLCreateThreadTypeMutationVariables
  >(GQLCreateThreadTypeDocument, options);
}
export type GQLCreateThreadTypeMutationHookResult = ReturnType<
  typeof useGQLCreateThreadTypeMutation
>;
export type GQLCreateThreadTypeMutationResult =
  Apollo.MutationResult<GQLCreateThreadTypeMutation>;
export type GQLCreateThreadTypeMutationOptions = Apollo.BaseMutationOptions<
  GQLCreateThreadTypeMutation,
  GQLCreateThreadTypeMutationVariables
>;
export const GQLUpdateThreadTypeDocument = gql`
  mutation UpdateThreadType($input: UpdateThreadItemTypeInput!) {
    updateThreadItemType(input: $input) {
      ... on MutateThreadTypeSuccessResponse {
        data {
          id
        }
      }
      ... on Error {
        title
      }
    }
  }
`;
export type GQLUpdateThreadTypeMutationFn = Apollo.MutationFunction<
  GQLUpdateThreadTypeMutation,
  GQLUpdateThreadTypeMutationVariables
>;

/**
 * __useGQLUpdateThreadTypeMutation__
 *
 * To run a mutation, you first call `useGQLUpdateThreadTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLUpdateThreadTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlUpdateThreadTypeMutation, { data, loading, error }] = useGQLUpdateThreadTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLUpdateThreadTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLUpdateThreadTypeMutation,
    GQLUpdateThreadTypeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLUpdateThreadTypeMutation,
    GQLUpdateThreadTypeMutationVariables
  >(GQLUpdateThreadTypeDocument, options);
}
export type GQLUpdateThreadTypeMutationHookResult = ReturnType<
  typeof useGQLUpdateThreadTypeMutation
>;
export type GQLUpdateThreadTypeMutationResult =
  Apollo.MutationResult<GQLUpdateThreadTypeMutation>;
export type GQLUpdateThreadTypeMutationOptions = Apollo.BaseMutationOptions<
  GQLUpdateThreadTypeMutation,
  GQLUpdateThreadTypeMutationVariables
>;
export const GQLItemTypesDocument = gql`
  query ItemTypes {
    myOrg {
      itemTypes {
        ... on ItemTypeBase {
          ...ItemTypeFragment
        }
        ... on UserItemType {
          isDefaultUserType
        }
      }
    }
    me {
      permissions
    }
  }
  ${GQLItemTypeFragmentFragmentDoc}
`;

/**
 * __useGQLItemTypesQuery__
 *
 * To run a query within a React component, call `useGQLItemTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLItemTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLItemTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLItemTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLItemTypesQuery,
    GQLItemTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLItemTypesQuery, GQLItemTypesQueryVariables>(
    GQLItemTypesDocument,
    options,
  );
}
export function useGQLItemTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLItemTypesQuery,
    GQLItemTypesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQLItemTypesQuery, GQLItemTypesQueryVariables>(
    GQLItemTypesDocument,
    options,
  );
}
export type GQLItemTypesQueryHookResult = ReturnType<
  typeof useGQLItemTypesQuery
>;
export type GQLItemTypesLazyQueryHookResult = ReturnType<
  typeof useGQLItemTypesLazyQuery
>;
export type GQLItemTypesQueryResult = Apollo.QueryResult<
  GQLItemTypesQuery,
  GQLItemTypesQueryVariables
>;
export const GQLItemActionHistoryDocument = gql`
  query ItemActionHistory(
    $itemIdentifier: ItemIdentifierInput!
    $submissionTime: DateTime
  ) {
    itemActionHistory(
      itemIdentifier: $itemIdentifier
      submissionTime: $submissionTime
    ) {
      ... on ItemAction {
        itemId
        itemTypeId
        itemCreatorId
        itemCreatorTypeId
        actionId
        actorId
        jobId
        policies
        ruleIds
        ts
      }
    }
    myOrg {
      users {
        id
        firstName
        lastName
      }
      actions {
        ... on ActionBase {
          id
          name
        }
      }
      policies {
        id
        name
      }
      itemTypes {
        ... on ItemTypeBase {
          id
          name
        }
      }
      rules {
        ... on ContentRule {
          id
          name
        }
        ... on UserRule {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGQLItemActionHistoryQuery__
 *
 * To run a query within a React component, call `useGQLItemActionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLItemActionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLItemActionHistoryQuery({
 *   variables: {
 *      itemIdentifier: // value for 'itemIdentifier'
 *      submissionTime: // value for 'submissionTime'
 *   },
 * });
 */
export function useGQLItemActionHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLItemActionHistoryQuery,
    GQLItemActionHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLItemActionHistoryQuery,
    GQLItemActionHistoryQueryVariables
  >(GQLItemActionHistoryDocument, options);
}
export function useGQLItemActionHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLItemActionHistoryQuery,
    GQLItemActionHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLItemActionHistoryQuery,
    GQLItemActionHistoryQueryVariables
  >(GQLItemActionHistoryDocument, options);
}
export type GQLItemActionHistoryQueryHookResult = ReturnType<
  typeof useGQLItemActionHistoryQuery
>;
export type GQLItemActionHistoryLazyQueryHookResult = ReturnType<
  typeof useGQLItemActionHistoryLazyQuery
>;
export type GQLItemActionHistoryQueryResult = Apollo.QueryResult<
  GQLItemActionHistoryQuery,
  GQLItemActionHistoryQueryVariables
>;
export const GQLGetDecidedJobFromJobIdDocument = gql`
  query getDecidedJobFromJobId($id: String!) {
    getDecidedJobFromJobId(id: $id) {
      job {
        ...JobFields
      }
      decision {
        id
        queueId
        reviewerId
        itemId
        itemTypeId
        jobId
        decisionReason
        decisions {
          ... on ManualReviewDecisionComponentBase {
            ...ManualReviewDecisionComponentFields
          }
        }
        relatedActions {
          ... on ManualReviewDecisionComponentBase {
            ...ManualReviewDecisionComponentFields
          }
        }
        createdAt
      }
    }
  }
  ${GQLJobFieldsFragmentDoc}
  ${GQLManualReviewDecisionComponentFieldsFragmentDoc}
`;

/**
 * __useGQLGetDecidedJobFromJobIdQuery__
 *
 * To run a query within a React component, call `useGQLGetDecidedJobFromJobIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetDecidedJobFromJobIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetDecidedJobFromJobIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLGetDecidedJobFromJobIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetDecidedJobFromJobIdQuery,
    GQLGetDecidedJobFromJobIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetDecidedJobFromJobIdQuery,
    GQLGetDecidedJobFromJobIdQueryVariables
  >(GQLGetDecidedJobFromJobIdDocument, options);
}
export function useGQLGetDecidedJobFromJobIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetDecidedJobFromJobIdQuery,
    GQLGetDecidedJobFromJobIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetDecidedJobFromJobIdQuery,
    GQLGetDecidedJobFromJobIdQueryVariables
  >(GQLGetDecidedJobFromJobIdDocument, options);
}
export type GQLGetDecidedJobFromJobIdQueryHookResult = ReturnType<
  typeof useGQLGetDecidedJobFromJobIdQuery
>;
export type GQLGetDecidedJobFromJobIdLazyQueryHookResult = ReturnType<
  typeof useGQLGetDecidedJobFromJobIdLazyQuery
>;
export type GQLGetDecidedJobFromJobIdQueryResult = Apollo.QueryResult<
  GQLGetDecidedJobFromJobIdQuery,
  GQLGetDecidedJobFromJobIdQueryVariables
>;
export const GQLSpotTestModelDocument = gql`
  query SpotTestModel(
    $modelVersion: Int!
    $modelId: ID!
    $item: SpotTestItemInput!
  ) {
    spotTestModel(modelVersion: $modelVersion, modelId: $modelId, item: $item) {
      ... on ModelExecutionSuccessResult {
        score
      }
      ... on ModelExecutionUnscoreableResult {
        _
      }
      ... on ModelExecutionErrorResult {
        _
      }
    }
  }
`;

/**
 * __useGQLSpotTestModelQuery__
 *
 * To run a query within a React component, call `useGQLSpotTestModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLSpotTestModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLSpotTestModelQuery({
 *   variables: {
 *      modelVersion: // value for 'modelVersion'
 *      modelId: // value for 'modelId'
 *      item: // value for 'item'
 *   },
 * });
 */
export function useGQLSpotTestModelQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLSpotTestModelQuery,
    GQLSpotTestModelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLSpotTestModelQuery, GQLSpotTestModelQueryVariables>(
    GQLSpotTestModelDocument,
    options,
  );
}
export function useGQLSpotTestModelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLSpotTestModelQuery,
    GQLSpotTestModelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLSpotTestModelQuery,
    GQLSpotTestModelQueryVariables
  >(GQLSpotTestModelDocument, options);
}
export type GQLSpotTestModelQueryHookResult = ReturnType<
  typeof useGQLSpotTestModelQuery
>;
export type GQLSpotTestModelLazyQueryHookResult = ReturnType<
  typeof useGQLSpotTestModelLazyQuery
>;
export type GQLSpotTestModelQueryResult = Apollo.QueryResult<
  GQLSpotTestModelQuery,
  GQLSpotTestModelQueryVariables
>;
export const GQLLatestSuccessfulSamplingJobDocument = gql`
  query LatestSuccessfulSamplingJob(
    $modelId: String!
    $modelVersion: Int!
    $samplingStrategy: SamplingStrategyInput!
  ) {
    getLatestSuccessfulSamplingJobResultForModel(
      modelId: $modelId
      modelVersion: $modelVersion
      samplingStrategy: $samplingStrategy
    ) {
      ... on SamplingJobSuccess {
        samples {
          item {
            itemId
            itemType {
              id
              version
              schemaVariant
            }
            data
          }
          score
          additionalModelScore {
            score
          }
        }
        samplingStrategy
      }
    }
  }
`;

/**
 * __useGQLLatestSuccessfulSamplingJobQuery__
 *
 * To run a query within a React component, call `useGQLLatestSuccessfulSamplingJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLLatestSuccessfulSamplingJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLLatestSuccessfulSamplingJobQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *      modelVersion: // value for 'modelVersion'
 *      samplingStrategy: // value for 'samplingStrategy'
 *   },
 * });
 */
export function useGQLLatestSuccessfulSamplingJobQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLLatestSuccessfulSamplingJobQuery,
    GQLLatestSuccessfulSamplingJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLLatestSuccessfulSamplingJobQuery,
    GQLLatestSuccessfulSamplingJobQueryVariables
  >(GQLLatestSuccessfulSamplingJobDocument, options);
}
export function useGQLLatestSuccessfulSamplingJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLLatestSuccessfulSamplingJobQuery,
    GQLLatestSuccessfulSamplingJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLLatestSuccessfulSamplingJobQuery,
    GQLLatestSuccessfulSamplingJobQueryVariables
  >(GQLLatestSuccessfulSamplingJobDocument, options);
}
export type GQLLatestSuccessfulSamplingJobQueryHookResult = ReturnType<
  typeof useGQLLatestSuccessfulSamplingJobQuery
>;
export type GQLLatestSuccessfulSamplingJobLazyQueryHookResult = ReturnType<
  typeof useGQLLatestSuccessfulSamplingJobLazyQuery
>;
export type GQLLatestSuccessfulSamplingJobQueryResult = Apollo.QueryResult<
  GQLLatestSuccessfulSamplingJobQuery,
  GQLLatestSuccessfulSamplingJobQueryVariables
>;
export const GQLCreateExistingCoveModelSamplingJobDocument = gql`
  mutation CreateExistingCoveModelSamplingJob(
    $input: CreateExistingCoveModelSamplingJobInput!
  ) {
    createExistingCoveModelSamplingJob(input: $input) {
      jobId
    }
  }
`;
export type GQLCreateExistingCoveModelSamplingJobMutationFn =
  Apollo.MutationFunction<
    GQLCreateExistingCoveModelSamplingJobMutation,
    GQLCreateExistingCoveModelSamplingJobMutationVariables
  >;

/**
 * __useGQLCreateExistingCoveModelSamplingJobMutation__
 *
 * To run a mutation, you first call `useGQLCreateExistingCoveModelSamplingJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLCreateExistingCoveModelSamplingJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCreateExistingCoveModelSamplingJobMutation, { data, loading, error }] = useGQLCreateExistingCoveModelSamplingJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLCreateExistingCoveModelSamplingJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLCreateExistingCoveModelSamplingJobMutation,
    GQLCreateExistingCoveModelSamplingJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLCreateExistingCoveModelSamplingJobMutation,
    GQLCreateExistingCoveModelSamplingJobMutationVariables
  >(GQLCreateExistingCoveModelSamplingJobDocument, options);
}
export type GQLCreateExistingCoveModelSamplingJobMutationHookResult =
  ReturnType<typeof useGQLCreateExistingCoveModelSamplingJobMutation>;
export type GQLCreateExistingCoveModelSamplingJobMutationResult =
  Apollo.MutationResult<GQLCreateExistingCoveModelSamplingJobMutation>;
export type GQLCreateExistingCoveModelSamplingJobMutationOptions =
  Apollo.BaseMutationOptions<
    GQLCreateExistingCoveModelSamplingJobMutation,
    GQLCreateExistingCoveModelSamplingJobMutationVariables
  >;
export const GQLRetrainModelDocument = gql`
  mutation RetrainModel($input: RetrainExistingModelInput!) {
    retrainExistingModel(input: $input) {
      _
    }
  }
`;
export type GQLRetrainModelMutationFn = Apollo.MutationFunction<
  GQLRetrainModelMutation,
  GQLRetrainModelMutationVariables
>;

/**
 * __useGQLRetrainModelMutation__
 *
 * To run a mutation, you first call `useGQLRetrainModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLRetrainModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlRetrainModelMutation, { data, loading, error }] = useGQLRetrainModelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLRetrainModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLRetrainModelMutation,
    GQLRetrainModelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLRetrainModelMutation,
    GQLRetrainModelMutationVariables
  >(GQLRetrainModelDocument, options);
}
export type GQLRetrainModelMutationHookResult = ReturnType<
  typeof useGQLRetrainModelMutation
>;
export type GQLRetrainModelMutationResult =
  Apollo.MutationResult<GQLRetrainModelMutation>;
export type GQLRetrainModelMutationOptions = Apollo.BaseMutationOptions<
  GQLRetrainModelMutation,
  GQLRetrainModelMutationVariables
>;
export const GQLGetLiveModelForPolicyDocument = gql`
  query GetLiveModelForPolicy($policyId: ID!) {
    getLiveModelForPolicy(policyId: $policyId) {
      id
      version
    }
  }
`;

/**
 * __useGQLGetLiveModelForPolicyQuery__
 *
 * To run a query within a React component, call `useGQLGetLiveModelForPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetLiveModelForPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetLiveModelForPolicyQuery({
 *   variables: {
 *      policyId: // value for 'policyId'
 *   },
 * });
 */
export function useGQLGetLiveModelForPolicyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetLiveModelForPolicyQuery,
    GQLGetLiveModelForPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetLiveModelForPolicyQuery,
    GQLGetLiveModelForPolicyQueryVariables
  >(GQLGetLiveModelForPolicyDocument, options);
}
export function useGQLGetLiveModelForPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetLiveModelForPolicyQuery,
    GQLGetLiveModelForPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetLiveModelForPolicyQuery,
    GQLGetLiveModelForPolicyQueryVariables
  >(GQLGetLiveModelForPolicyDocument, options);
}
export type GQLGetLiveModelForPolicyQueryHookResult = ReturnType<
  typeof useGQLGetLiveModelForPolicyQuery
>;
export type GQLGetLiveModelForPolicyLazyQueryHookResult = ReturnType<
  typeof useGQLGetLiveModelForPolicyLazyQuery
>;
export type GQLGetLiveModelForPolicyQueryResult = Apollo.QueryResult<
  GQLGetLiveModelForPolicyQuery,
  GQLGetLiveModelForPolicyQueryVariables
>;
export const GQLGetComparisonSamplingJobResultsDocument = gql`
  query GetComparisonSamplingJobResults($jobId: ID!) {
    getSamplingJobResults(jobId: $jobId) {
      ... on SamplingJobSuccess {
        samples {
          item {
            itemId
            itemType {
              id
              version
              schemaVariant
            }
            data
          }
          score
          additionalModelScore {
            score
          }
        }
        samplingStrategy
      }
      ... on SamplingJobFailure {
        title
      }
      ... on SamplingJobNotFoundError {
        title
      }
    }
  }
`;

/**
 * __useGQLGetComparisonSamplingJobResultsQuery__
 *
 * To run a query within a React component, call `useGQLGetComparisonSamplingJobResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetComparisonSamplingJobResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetComparisonSamplingJobResultsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGQLGetComparisonSamplingJobResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetComparisonSamplingJobResultsQuery,
    GQLGetComparisonSamplingJobResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetComparisonSamplingJobResultsQuery,
    GQLGetComparisonSamplingJobResultsQueryVariables
  >(GQLGetComparisonSamplingJobResultsDocument, options);
}
export function useGQLGetComparisonSamplingJobResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetComparisonSamplingJobResultsQuery,
    GQLGetComparisonSamplingJobResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetComparisonSamplingJobResultsQuery,
    GQLGetComparisonSamplingJobResultsQueryVariables
  >(GQLGetComparisonSamplingJobResultsDocument, options);
}
export type GQLGetComparisonSamplingJobResultsQueryHookResult = ReturnType<
  typeof useGQLGetComparisonSamplingJobResultsQuery
>;
export type GQLGetComparisonSamplingJobResultsLazyQueryHookResult = ReturnType<
  typeof useGQLGetComparisonSamplingJobResultsLazyQuery
>;
export type GQLGetComparisonSamplingJobResultsQueryResult = Apollo.QueryResult<
  GQLGetComparisonSamplingJobResultsQuery,
  GQLGetComparisonSamplingJobResultsQueryVariables
>;
export const GQLGetModelVersionsWithDetailsDocument = gql`
  query GetModelVersionsWithDetails($modelId: ID!) {
    getModelVerionsWithDetails(modelId: $modelId) {
      id
      version
      family
      status
      policy {
        id
        name
        parentId
      }
      createdAt
      itemRequirement
      alias
    }
  }
`;

/**
 * __useGQLGetModelVersionsWithDetailsQuery__
 *
 * To run a query within a React component, call `useGQLGetModelVersionsWithDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetModelVersionsWithDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetModelVersionsWithDetailsQuery({
 *   variables: {
 *      modelId: // value for 'modelId'
 *   },
 * });
 */
export function useGQLGetModelVersionsWithDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetModelVersionsWithDetailsQuery,
    GQLGetModelVersionsWithDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetModelVersionsWithDetailsQuery,
    GQLGetModelVersionsWithDetailsQueryVariables
  >(GQLGetModelVersionsWithDetailsDocument, options);
}
export function useGQLGetModelVersionsWithDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetModelVersionsWithDetailsQuery,
    GQLGetModelVersionsWithDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetModelVersionsWithDetailsQuery,
    GQLGetModelVersionsWithDetailsQueryVariables
  >(GQLGetModelVersionsWithDetailsDocument, options);
}
export type GQLGetModelVersionsWithDetailsQueryHookResult = ReturnType<
  typeof useGQLGetModelVersionsWithDetailsQuery
>;
export type GQLGetModelVersionsWithDetailsLazyQueryHookResult = ReturnType<
  typeof useGQLGetModelVersionsWithDetailsLazyQuery
>;
export type GQLGetModelVersionsWithDetailsQueryResult = Apollo.QueryResult<
  GQLGetModelVersionsWithDetailsQuery,
  GQLGetModelVersionsWithDetailsQueryVariables
>;
export const GQLModelsDashboardDocument = gql`
  query ModelsDashboard {
    myOrg {
      isDemoOrg
      policies {
        id
        name
        policyText
        enforcementGuidelines
        parentId
        policyType
      }
      models {
        id
        status
        version
        policy {
          id
        }
        itemRequirement
        createdAt
        alias
      }
    }
  }
`;

/**
 * __useGQLModelsDashboardQuery__
 *
 * To run a query within a React component, call `useGQLModelsDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLModelsDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLModelsDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLModelsDashboardQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLModelsDashboardQuery,
    GQLModelsDashboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLModelsDashboardQuery,
    GQLModelsDashboardQueryVariables
  >(GQLModelsDashboardDocument, options);
}
export function useGQLModelsDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLModelsDashboardQuery,
    GQLModelsDashboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLModelsDashboardQuery,
    GQLModelsDashboardQueryVariables
  >(GQLModelsDashboardDocument, options);
}
export type GQLModelsDashboardQueryHookResult = ReturnType<
  typeof useGQLModelsDashboardQuery
>;
export type GQLModelsDashboardLazyQueryHookResult = ReturnType<
  typeof useGQLModelsDashboardLazyQuery
>;
export type GQLModelsDashboardQueryResult = Apollo.QueryResult<
  GQLModelsDashboardQuery,
  GQLModelsDashboardQueryVariables
>;
export const GQLSaveCustomerInfoDocument = gql`
  mutation SaveCustomerInfo($input: CustomerInfoInput!) {
    saveCustomerInfo(input: $input)
  }
`;
export type GQLSaveCustomerInfoMutationFn = Apollo.MutationFunction<
  GQLSaveCustomerInfoMutation,
  GQLSaveCustomerInfoMutationVariables
>;

/**
 * __useGQLSaveCustomerInfoMutation__
 *
 * To run a mutation, you first call `useGQLSaveCustomerInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLSaveCustomerInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlSaveCustomerInfoMutation, { data, loading, error }] = useGQLSaveCustomerInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLSaveCustomerInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLSaveCustomerInfoMutation,
    GQLSaveCustomerInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLSaveCustomerInfoMutation,
    GQLSaveCustomerInfoMutationVariables
  >(GQLSaveCustomerInfoDocument, options);
}
export type GQLSaveCustomerInfoMutationHookResult = ReturnType<
  typeof useGQLSaveCustomerInfoMutation
>;
export type GQLSaveCustomerInfoMutationResult =
  Apollo.MutationResult<GQLSaveCustomerInfoMutation>;
export type GQLSaveCustomerInfoMutationOptions = Apollo.BaseMutationOptions<
  GQLSaveCustomerInfoMutation,
  GQLSaveCustomerInfoMutationVariables
>;
export const GQLFetchSignedUrlDocument = gql`
  query fetchSignedUrl($filename: String!, $fileType: String!) {
    getSignedS3Url(filename: $filename, fileType: $fileType) {
      url
    }
  }
`;

/**
 * __useGQLFetchSignedUrlQuery__
 *
 * To run a query within a React component, call `useGQLFetchSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLFetchSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLFetchSignedUrlQuery({
 *   variables: {
 *      filename: // value for 'filename'
 *      fileType: // value for 'fileType'
 *   },
 * });
 */
export function useGQLFetchSignedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLFetchSignedUrlQuery,
    GQLFetchSignedUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLFetchSignedUrlQuery,
    GQLFetchSignedUrlQueryVariables
  >(GQLFetchSignedUrlDocument, options);
}
export function useGQLFetchSignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLFetchSignedUrlQuery,
    GQLFetchSignedUrlQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLFetchSignedUrlQuery,
    GQLFetchSignedUrlQueryVariables
  >(GQLFetchSignedUrlDocument, options);
}
export type GQLFetchSignedUrlQueryHookResult = ReturnType<
  typeof useGQLFetchSignedUrlQuery
>;
export type GQLFetchSignedUrlLazyQueryHookResult = ReturnType<
  typeof useGQLFetchSignedUrlLazyQuery
>;
export type GQLFetchSignedUrlQueryResult = Apollo.QueryResult<
  GQLFetchSignedUrlQuery,
  GQLFetchSignedUrlQueryVariables
>;
export const GQLGetSamplesForLabelingDocument = gql`
  query GetSamplesForLabeling(
    $input: GetRedditExamplesForLabelingScreenInput!
  ) {
    getRedditExamplesForLabelingScreen(input: $input) {
      items
    }
  }
`;

/**
 * __useGQLGetSamplesForLabelingQuery__
 *
 * To run a query within a React component, call `useGQLGetSamplesForLabelingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetSamplesForLabelingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetSamplesForLabelingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLGetSamplesForLabelingQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetSamplesForLabelingQuery,
    GQLGetSamplesForLabelingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetSamplesForLabelingQuery,
    GQLGetSamplesForLabelingQueryVariables
  >(GQLGetSamplesForLabelingDocument, options);
}
export function useGQLGetSamplesForLabelingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetSamplesForLabelingQuery,
    GQLGetSamplesForLabelingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetSamplesForLabelingQuery,
    GQLGetSamplesForLabelingQueryVariables
  >(GQLGetSamplesForLabelingDocument, options);
}
export type GQLGetSamplesForLabelingQueryHookResult = ReturnType<
  typeof useGQLGetSamplesForLabelingQuery
>;
export type GQLGetSamplesForLabelingLazyQueryHookResult = ReturnType<
  typeof useGQLGetSamplesForLabelingLazyQuery
>;
export type GQLGetSamplesForLabelingQueryResult = Apollo.QueryResult<
  GQLGetSamplesForLabelingQuery,
  GQLGetSamplesForLabelingQueryVariables
>;
export const GQLGetRedditExamplesDocument = gql`
  query GetRedditExamples($input: GetRedditExamplesForThresholdScreenInput!) {
    getRedditExamplesForThresholdScreen(input: $input) {
      items {
        text
        score
      }
    }
  }
`;

/**
 * __useGQLGetRedditExamplesQuery__
 *
 * To run a query within a React component, call `useGQLGetRedditExamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetRedditExamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetRedditExamplesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLGetRedditExamplesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetRedditExamplesQuery,
    GQLGetRedditExamplesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetRedditExamplesQuery,
    GQLGetRedditExamplesQueryVariables
  >(GQLGetRedditExamplesDocument, options);
}
export function useGQLGetRedditExamplesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetRedditExamplesQuery,
    GQLGetRedditExamplesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetRedditExamplesQuery,
    GQLGetRedditExamplesQueryVariables
  >(GQLGetRedditExamplesDocument, options);
}
export type GQLGetRedditExamplesQueryHookResult = ReturnType<
  typeof useGQLGetRedditExamplesQuery
>;
export type GQLGetRedditExamplesLazyQueryHookResult = ReturnType<
  typeof useGQLGetRedditExamplesLazyQuery
>;
export type GQLGetRedditExamplesQueryResult = Apollo.QueryResult<
  GQLGetRedditExamplesQuery,
  GQLGetRedditExamplesQueryVariables
>;
export const GQLOrgDataForModelTrainingDocument = gql`
  query OrgDataForModelTraining {
    myOrg {
      id
      itemTypes {
        ... on ItemTypeBase {
          ...ItemTypeFragment
        }
        ... on UserItemType {
          isDefaultUserType
        }
      }
      actions {
        ... on ActionBase {
          id
          name
          itemTypes {
            ... on ItemTypeBase {
              id
            }
          }
        }
      }
      policies {
        id
        name
        policyText
        enforcementGuidelines
      }
    }
    me {
      permissions
    }
  }
  ${GQLItemTypeFragmentFragmentDoc}
`;

/**
 * __useGQLOrgDataForModelTrainingQuery__
 *
 * To run a query within a React component, call `useGQLOrgDataForModelTrainingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLOrgDataForModelTrainingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLOrgDataForModelTrainingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLOrgDataForModelTrainingQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLOrgDataForModelTrainingQuery,
    GQLOrgDataForModelTrainingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLOrgDataForModelTrainingQuery,
    GQLOrgDataForModelTrainingQueryVariables
  >(GQLOrgDataForModelTrainingDocument, options);
}
export function useGQLOrgDataForModelTrainingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLOrgDataForModelTrainingQuery,
    GQLOrgDataForModelTrainingQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLOrgDataForModelTrainingQuery,
    GQLOrgDataForModelTrainingQueryVariables
  >(GQLOrgDataForModelTrainingDocument, options);
}
export type GQLOrgDataForModelTrainingQueryHookResult = ReturnType<
  typeof useGQLOrgDataForModelTrainingQuery
>;
export type GQLOrgDataForModelTrainingLazyQueryHookResult = ReturnType<
  typeof useGQLOrgDataForModelTrainingLazyQuery
>;
export type GQLOrgDataForModelTrainingQueryResult = Apollo.QueryResult<
  GQLOrgDataForModelTrainingQuery,
  GQLOrgDataForModelTrainingQueryVariables
>;
export const GQLGetItemTypesByIdentifiersDocument = gql`
  query GetItemTypesByIdentifiers($identifiers: [ItemTypeIdentifierInput!]!) {
    itemTypes(identifiers: $identifiers) {
      ... on ItemTypeBase {
        ...ItemTypeFragment
      }
    }
  }
  ${GQLItemTypeFragmentFragmentDoc}
`;

/**
 * __useGQLGetItemTypesByIdentifiersQuery__
 *
 * To run a query within a React component, call `useGQLGetItemTypesByIdentifiersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetItemTypesByIdentifiersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetItemTypesByIdentifiersQuery({
 *   variables: {
 *      identifiers: // value for 'identifiers'
 *   },
 * });
 */
export function useGQLGetItemTypesByIdentifiersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetItemTypesByIdentifiersQuery,
    GQLGetItemTypesByIdentifiersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetItemTypesByIdentifiersQuery,
    GQLGetItemTypesByIdentifiersQueryVariables
  >(GQLGetItemTypesByIdentifiersDocument, options);
}
export function useGQLGetItemTypesByIdentifiersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetItemTypesByIdentifiersQuery,
    GQLGetItemTypesByIdentifiersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetItemTypesByIdentifiersQuery,
    GQLGetItemTypesByIdentifiersQueryVariables
  >(GQLGetItemTypesByIdentifiersDocument, options);
}
export type GQLGetItemTypesByIdentifiersQueryHookResult = ReturnType<
  typeof useGQLGetItemTypesByIdentifiersQuery
>;
export type GQLGetItemTypesByIdentifiersLazyQueryHookResult = ReturnType<
  typeof useGQLGetItemTypesByIdentifiersLazyQuery
>;
export type GQLGetItemTypesByIdentifiersQueryResult = Apollo.QueryResult<
  GQLGetItemTypesByIdentifiersQuery,
  GQLGetItemTypesByIdentifiersQueryVariables
>;
export const GQLGenerateDefaultPolicyDefinitionDocument = gql`
  query GenerateDefaultPolicyDefinition($input: GeneratePolicyInput!) {
    generatePolicyText(input: $input) {
      ... on GeneratedPolicyTextCompletionSuccess {
        policyText
      }
      ... on GeneratedPolicyTextCompletionError {
        title
      }
    }
  }
`;

/**
 * __useGQLGenerateDefaultPolicyDefinitionQuery__
 *
 * To run a query within a React component, call `useGQLGenerateDefaultPolicyDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGenerateDefaultPolicyDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGenerateDefaultPolicyDefinitionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLGenerateDefaultPolicyDefinitionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGenerateDefaultPolicyDefinitionQuery,
    GQLGenerateDefaultPolicyDefinitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGenerateDefaultPolicyDefinitionQuery,
    GQLGenerateDefaultPolicyDefinitionQueryVariables
  >(GQLGenerateDefaultPolicyDefinitionDocument, options);
}
export function useGQLGenerateDefaultPolicyDefinitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGenerateDefaultPolicyDefinitionQuery,
    GQLGenerateDefaultPolicyDefinitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGenerateDefaultPolicyDefinitionQuery,
    GQLGenerateDefaultPolicyDefinitionQueryVariables
  >(GQLGenerateDefaultPolicyDefinitionDocument, options);
}
export type GQLGenerateDefaultPolicyDefinitionQueryHookResult = ReturnType<
  typeof useGQLGenerateDefaultPolicyDefinitionQuery
>;
export type GQLGenerateDefaultPolicyDefinitionLazyQueryHookResult = ReturnType<
  typeof useGQLGenerateDefaultPolicyDefinitionLazyQuery
>;
export type GQLGenerateDefaultPolicyDefinitionQueryResult = Apollo.QueryResult<
  GQLGenerateDefaultPolicyDefinitionQuery,
  GQLGenerateDefaultPolicyDefinitionQueryVariables
>;
export const GQLUpdatePolicyDefinitionDocument = gql`
  mutation UpdatePolicyDefinition($input: UpdatePolicyInput!) {
    updatePolicy(input: $input) {
      ... on Policy {
        id
        name
        policyText
      }
      ... on NotFoundError {
        title
      }
    }
  }
`;
export type GQLUpdatePolicyDefinitionMutationFn = Apollo.MutationFunction<
  GQLUpdatePolicyDefinitionMutation,
  GQLUpdatePolicyDefinitionMutationVariables
>;

/**
 * __useGQLUpdatePolicyDefinitionMutation__
 *
 * To run a mutation, you first call `useGQLUpdatePolicyDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLUpdatePolicyDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlUpdatePolicyDefinitionMutation, { data, loading, error }] = useGQLUpdatePolicyDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLUpdatePolicyDefinitionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLUpdatePolicyDefinitionMutation,
    GQLUpdatePolicyDefinitionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLUpdatePolicyDefinitionMutation,
    GQLUpdatePolicyDefinitionMutationVariables
  >(GQLUpdatePolicyDefinitionDocument, options);
}
export type GQLUpdatePolicyDefinitionMutationHookResult = ReturnType<
  typeof useGQLUpdatePolicyDefinitionMutation
>;
export type GQLUpdatePolicyDefinitionMutationResult =
  Apollo.MutationResult<GQLUpdatePolicyDefinitionMutation>;
export type GQLUpdatePolicyDefinitionMutationOptions =
  Apollo.BaseMutationOptions<
    GQLUpdatePolicyDefinitionMutation,
    GQLUpdatePolicyDefinitionMutationVariables
  >;
export const GQLStartCoveModelSignalCreationSessionDocument = gql`
  mutation StartCoveModelSignalCreationSession(
    $input: StartCoveModelSignalCreationSessionInput!
  ) {
    startCoveModelSignalCreationSession(input: $input) {
      ... on StartCoveModelSignalCreationSessionSuccessResponse {
        sessionId
      }
    }
  }
`;
export type GQLStartCoveModelSignalCreationSessionMutationFn =
  Apollo.MutationFunction<
    GQLStartCoveModelSignalCreationSessionMutation,
    GQLStartCoveModelSignalCreationSessionMutationVariables
  >;

/**
 * __useGQLStartCoveModelSignalCreationSessionMutation__
 *
 * To run a mutation, you first call `useGQLStartCoveModelSignalCreationSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLStartCoveModelSignalCreationSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlStartCoveModelSignalCreationSessionMutation, { data, loading, error }] = useGQLStartCoveModelSignalCreationSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLStartCoveModelSignalCreationSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLStartCoveModelSignalCreationSessionMutation,
    GQLStartCoveModelSignalCreationSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLStartCoveModelSignalCreationSessionMutation,
    GQLStartCoveModelSignalCreationSessionMutationVariables
  >(GQLStartCoveModelSignalCreationSessionDocument, options);
}
export type GQLStartCoveModelSignalCreationSessionMutationHookResult =
  ReturnType<typeof useGQLStartCoveModelSignalCreationSessionMutation>;
export type GQLStartCoveModelSignalCreationSessionMutationResult =
  Apollo.MutationResult<GQLStartCoveModelSignalCreationSessionMutation>;
export type GQLStartCoveModelSignalCreationSessionMutationOptions =
  Apollo.BaseMutationOptions<
    GQLStartCoveModelSignalCreationSessionMutation,
    GQLStartCoveModelSignalCreationSessionMutationVariables
  >;
export const GQLGetLabelCountForPolicyDocument = gql`
  query GetLabelCountForPolicy($policyId: ID!, $policySemanticVersion: Int) {
    getLabelCountForPolicy(
      policyId: $policyId
      policySemanticVersion: $policySemanticVersion
    ) {
      positiveCount
      negativeCount
    }
  }
`;

/**
 * __useGQLGetLabelCountForPolicyQuery__
 *
 * To run a query within a React component, call `useGQLGetLabelCountForPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetLabelCountForPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetLabelCountForPolicyQuery({
 *   variables: {
 *      policyId: // value for 'policyId'
 *      policySemanticVersion: // value for 'policySemanticVersion'
 *   },
 * });
 */
export function useGQLGetLabelCountForPolicyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetLabelCountForPolicyQuery,
    GQLGetLabelCountForPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetLabelCountForPolicyQuery,
    GQLGetLabelCountForPolicyQueryVariables
  >(GQLGetLabelCountForPolicyDocument, options);
}
export function useGQLGetLabelCountForPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetLabelCountForPolicyQuery,
    GQLGetLabelCountForPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetLabelCountForPolicyQuery,
    GQLGetLabelCountForPolicyQueryVariables
  >(GQLGetLabelCountForPolicyDocument, options);
}
export type GQLGetLabelCountForPolicyQueryHookResult = ReturnType<
  typeof useGQLGetLabelCountForPolicyQuery
>;
export type GQLGetLabelCountForPolicyLazyQueryHookResult = ReturnType<
  typeof useGQLGetLabelCountForPolicyLazyQuery
>;
export type GQLGetLabelCountForPolicyQueryResult = Apollo.QueryResult<
  GQLGetLabelCountForPolicyQuery,
  GQLGetLabelCountForPolicyQueryVariables
>;
export const GQLGetModelsDocument = gql`
  query GetModels {
    myOrg {
      models {
        id
        version
        name
        policy {
          id
          name
        }
      }
      policies {
        id
        name
      }
    }
  }
`;

/**
 * __useGQLGetModelsQuery__
 *
 * To run a query within a React component, call `useGQLGetModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLGetModelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLGetModelsQuery,
    GQLGetModelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLGetModelsQuery, GQLGetModelsQueryVariables>(
    GQLGetModelsDocument,
    options,
  );
}
export function useGQLGetModelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetModelsQuery,
    GQLGetModelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQLGetModelsQuery, GQLGetModelsQueryVariables>(
    GQLGetModelsDocument,
    options,
  );
}
export type GQLGetModelsQueryHookResult = ReturnType<
  typeof useGQLGetModelsQuery
>;
export type GQLGetModelsLazyQueryHookResult = ReturnType<
  typeof useGQLGetModelsLazyQuery
>;
export type GQLGetModelsQueryResult = Apollo.QueryResult<
  GQLGetModelsQuery,
  GQLGetModelsQueryVariables
>;
export const GQLCreateCoveModelSignalCreationSamplingJobDocument = gql`
  mutation CreateCoveModelSignalCreationSamplingJob(
    $input: CreateCoveModelSignalCreationSamplingJobInput!
  ) {
    createCoveModelSignalCreationSamplingJob(input: $input) {
      jobId
    }
  }
`;
export type GQLCreateCoveModelSignalCreationSamplingJobMutationFn =
  Apollo.MutationFunction<
    GQLCreateCoveModelSignalCreationSamplingJobMutation,
    GQLCreateCoveModelSignalCreationSamplingJobMutationVariables
  >;

/**
 * __useGQLCreateCoveModelSignalCreationSamplingJobMutation__
 *
 * To run a mutation, you first call `useGQLCreateCoveModelSignalCreationSamplingJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLCreateCoveModelSignalCreationSamplingJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCreateCoveModelSignalCreationSamplingJobMutation, { data, loading, error }] = useGQLCreateCoveModelSignalCreationSamplingJobMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLCreateCoveModelSignalCreationSamplingJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLCreateCoveModelSignalCreationSamplingJobMutation,
    GQLCreateCoveModelSignalCreationSamplingJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLCreateCoveModelSignalCreationSamplingJobMutation,
    GQLCreateCoveModelSignalCreationSamplingJobMutationVariables
  >(GQLCreateCoveModelSignalCreationSamplingJobDocument, options);
}
export type GQLCreateCoveModelSignalCreationSamplingJobMutationHookResult =
  ReturnType<typeof useGQLCreateCoveModelSignalCreationSamplingJobMutation>;
export type GQLCreateCoveModelSignalCreationSamplingJobMutationResult =
  Apollo.MutationResult<GQLCreateCoveModelSignalCreationSamplingJobMutation>;
export type GQLCreateCoveModelSignalCreationSamplingJobMutationOptions =
  Apollo.BaseMutationOptions<
    GQLCreateCoveModelSignalCreationSamplingJobMutation,
    GQLCreateCoveModelSignalCreationSamplingJobMutationVariables
  >;
export const GQLSubmitLabelsDocument = gql`
  mutation SubmitLabels($input: LabelItemsInput!) {
    labelItems(input: $input) {
      _
    }
  }
`;
export type GQLSubmitLabelsMutationFn = Apollo.MutationFunction<
  GQLSubmitLabelsMutation,
  GQLSubmitLabelsMutationVariables
>;

/**
 * __useGQLSubmitLabelsMutation__
 *
 * To run a mutation, you first call `useGQLSubmitLabelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLSubmitLabelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlSubmitLabelsMutation, { data, loading, error }] = useGQLSubmitLabelsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLSubmitLabelsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLSubmitLabelsMutation,
    GQLSubmitLabelsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLSubmitLabelsMutation,
    GQLSubmitLabelsMutationVariables
  >(GQLSubmitLabelsDocument, options);
}
export type GQLSubmitLabelsMutationHookResult = ReturnType<
  typeof useGQLSubmitLabelsMutation
>;
export type GQLSubmitLabelsMutationResult =
  Apollo.MutationResult<GQLSubmitLabelsMutation>;
export type GQLSubmitLabelsMutationOptions = Apollo.BaseMutationOptions<
  GQLSubmitLabelsMutation,
  GQLSubmitLabelsMutationVariables
>;
export const GQLGetSamplingJobResultsDocument = gql`
  query GetSamplingJobResults($jobId: ID!) {
    getSamplingJobResults(jobId: $jobId) {
      ... on SamplingJobSuccess {
        samples {
          item {
            itemId
            itemType {
              id
              version
              schemaVariant
            }
            data
          }
          score
        }
        samplingStrategy
      }
      ... on SamplingJobFailure {
        title
      }
      ... on SamplingJobNotFoundError {
        title
      }
    }
  }
`;

/**
 * __useGQLGetSamplingJobResultsQuery__
 *
 * To run a query within a React component, call `useGQLGetSamplingJobResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetSamplingJobResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetSamplingJobResultsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGQLGetSamplingJobResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetSamplingJobResultsQuery,
    GQLGetSamplingJobResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetSamplingJobResultsQuery,
    GQLGetSamplingJobResultsQueryVariables
  >(GQLGetSamplingJobResultsDocument, options);
}
export function useGQLGetSamplingJobResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetSamplingJobResultsQuery,
    GQLGetSamplingJobResultsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetSamplingJobResultsQuery,
    GQLGetSamplingJobResultsQueryVariables
  >(GQLGetSamplingJobResultsDocument, options);
}
export type GQLGetSamplingJobResultsQueryHookResult = ReturnType<
  typeof useGQLGetSamplingJobResultsQuery
>;
export type GQLGetSamplingJobResultsLazyQueryHookResult = ReturnType<
  typeof useGQLGetSamplingJobResultsLazyQuery
>;
export type GQLGetSamplingJobResultsQueryResult = Apollo.QueryResult<
  GQLGetSamplingJobResultsQuery,
  GQLGetSamplingJobResultsQueryVariables
>;
export const GQLTrainAndSaveModelDocument = gql`
  mutation TrainAndSaveModel($sessionId: CoveModelSignalCreationSessionId!) {
    finishCoveModelSignalCreationSession(sessionId: $sessionId)
  }
`;
export type GQLTrainAndSaveModelMutationFn = Apollo.MutationFunction<
  GQLTrainAndSaveModelMutation,
  GQLTrainAndSaveModelMutationVariables
>;

/**
 * __useGQLTrainAndSaveModelMutation__
 *
 * To run a mutation, you first call `useGQLTrainAndSaveModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLTrainAndSaveModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlTrainAndSaveModelMutation, { data, loading, error }] = useGQLTrainAndSaveModelMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGQLTrainAndSaveModelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLTrainAndSaveModelMutation,
    GQLTrainAndSaveModelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLTrainAndSaveModelMutation,
    GQLTrainAndSaveModelMutationVariables
  >(GQLTrainAndSaveModelDocument, options);
}
export type GQLTrainAndSaveModelMutationHookResult = ReturnType<
  typeof useGQLTrainAndSaveModelMutation
>;
export type GQLTrainAndSaveModelMutationResult =
  Apollo.MutationResult<GQLTrainAndSaveModelMutation>;
export type GQLTrainAndSaveModelMutationOptions = Apollo.BaseMutationOptions<
  GQLTrainAndSaveModelMutation,
  GQLTrainAndSaveModelMutationVariables
>;
export const GQLCreateRulesForModelThresholdsDocument = gql`
  mutation CreateRulesForModelThresholds(
    $input: CreateRulesForCoveModelInput!
  ) {
    createRulesForModel(input: $input) {
      rules {
        id
      }
    }
  }
`;
export type GQLCreateRulesForModelThresholdsMutationFn =
  Apollo.MutationFunction<
    GQLCreateRulesForModelThresholdsMutation,
    GQLCreateRulesForModelThresholdsMutationVariables
  >;

/**
 * __useGQLCreateRulesForModelThresholdsMutation__
 *
 * To run a mutation, you first call `useGQLCreateRulesForModelThresholdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLCreateRulesForModelThresholdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCreateRulesForModelThresholdsMutation, { data, loading, error }] = useGQLCreateRulesForModelThresholdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLCreateRulesForModelThresholdsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLCreateRulesForModelThresholdsMutation,
    GQLCreateRulesForModelThresholdsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLCreateRulesForModelThresholdsMutation,
    GQLCreateRulesForModelThresholdsMutationVariables
  >(GQLCreateRulesForModelThresholdsDocument, options);
}
export type GQLCreateRulesForModelThresholdsMutationHookResult = ReturnType<
  typeof useGQLCreateRulesForModelThresholdsMutation
>;
export type GQLCreateRulesForModelThresholdsMutationResult =
  Apollo.MutationResult<GQLCreateRulesForModelThresholdsMutation>;
export type GQLCreateRulesForModelThresholdsMutationOptions =
  Apollo.BaseMutationOptions<
    GQLCreateRulesForModelThresholdsMutation,
    GQLCreateRulesForModelThresholdsMutationVariables
  >;
export const GQLGetCoveModelSignalCreationSessionResultDocument = gql`
  query GetCoveModelSignalCreationSessionResult(
    $sessionId: CoveModelSignalCreationSessionId!
  ) {
    getCoveModelSignalCreationSessionResult(sessionId: $sessionId) {
      ... on CoveModelSignalCreatedSuccess {
        signal {
          id
        }
      }
      ... on CoveModelSignalCreatedPending {
        _
      }
      ... on CoveModelSignalCreationError {
        title
      }
    }
  }
`;

/**
 * __useGQLGetCoveModelSignalCreationSessionResultQuery__
 *
 * To run a query within a React component, call `useGQLGetCoveModelSignalCreationSessionResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetCoveModelSignalCreationSessionResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetCoveModelSignalCreationSessionResultQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGQLGetCoveModelSignalCreationSessionResultQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetCoveModelSignalCreationSessionResultQuery,
    GQLGetCoveModelSignalCreationSessionResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetCoveModelSignalCreationSessionResultQuery,
    GQLGetCoveModelSignalCreationSessionResultQueryVariables
  >(GQLGetCoveModelSignalCreationSessionResultDocument, options);
}
export function useGQLGetCoveModelSignalCreationSessionResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetCoveModelSignalCreationSessionResultQuery,
    GQLGetCoveModelSignalCreationSessionResultQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetCoveModelSignalCreationSessionResultQuery,
    GQLGetCoveModelSignalCreationSessionResultQueryVariables
  >(GQLGetCoveModelSignalCreationSessionResultDocument, options);
}
export type GQLGetCoveModelSignalCreationSessionResultQueryHookResult =
  ReturnType<typeof useGQLGetCoveModelSignalCreationSessionResultQuery>;
export type GQLGetCoveModelSignalCreationSessionResultLazyQueryHookResult =
  ReturnType<typeof useGQLGetCoveModelSignalCreationSessionResultLazyQuery>;
export type GQLGetCoveModelSignalCreationSessionResultQueryResult =
  Apollo.QueryResult<
    GQLGetCoveModelSignalCreationSessionResultQuery,
    GQLGetCoveModelSignalCreationSessionResultQueryVariables
  >;
export const GQLManualReviewMetricsDocument = gql`
  query ManualReviewMetrics {
    getTotalPendingJobsCount
    reportingInsights {
      totalIngestedReportsByDay {
        date
        count
      }
    }
  }
`;

/**
 * __useGQLManualReviewMetricsQuery__
 *
 * To run a query within a React component, call `useGQLManualReviewMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLManualReviewMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLManualReviewMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLManualReviewMetricsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLManualReviewMetricsQuery,
    GQLManualReviewMetricsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLManualReviewMetricsQuery,
    GQLManualReviewMetricsQueryVariables
  >(GQLManualReviewMetricsDocument, options);
}
export function useGQLManualReviewMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLManualReviewMetricsQuery,
    GQLManualReviewMetricsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLManualReviewMetricsQuery,
    GQLManualReviewMetricsQueryVariables
  >(GQLManualReviewMetricsDocument, options);
}
export type GQLManualReviewMetricsQueryHookResult = ReturnType<
  typeof useGQLManualReviewMetricsQuery
>;
export type GQLManualReviewMetricsLazyQueryHookResult = ReturnType<
  typeof useGQLManualReviewMetricsLazyQuery
>;
export type GQLManualReviewMetricsQueryResult = Apollo.QueryResult<
  GQLManualReviewMetricsQuery,
  GQLManualReviewMetricsQueryVariables
>;
export const GQLGetAverageTimeToReviewDocument = gql`
  query getAverageTimeToReview($input: TimeToActionInput!) {
    getTimeToAction(input: $input) {
      timeToAction
      queueId
    }
  }
`;

/**
 * __useGQLGetAverageTimeToReviewQuery__
 *
 * To run a query within a React component, call `useGQLGetAverageTimeToReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetAverageTimeToReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetAverageTimeToReviewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLGetAverageTimeToReviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetAverageTimeToReviewQuery,
    GQLGetAverageTimeToReviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetAverageTimeToReviewQuery,
    GQLGetAverageTimeToReviewQueryVariables
  >(GQLGetAverageTimeToReviewDocument, options);
}
export function useGQLGetAverageTimeToReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetAverageTimeToReviewQuery,
    GQLGetAverageTimeToReviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetAverageTimeToReviewQuery,
    GQLGetAverageTimeToReviewQueryVariables
  >(GQLGetAverageTimeToReviewDocument, options);
}
export type GQLGetAverageTimeToReviewQueryHookResult = ReturnType<
  typeof useGQLGetAverageTimeToReviewQuery
>;
export type GQLGetAverageTimeToReviewLazyQueryHookResult = ReturnType<
  typeof useGQLGetAverageTimeToReviewLazyQuery
>;
export type GQLGetAverageTimeToReviewQueryResult = Apollo.QueryResult<
  GQLGetAverageTimeToReviewQuery,
  GQLGetAverageTimeToReviewQueryVariables
>;
export const GQLAppealSettingsDocument = gql`
  query AppealSettings {
    appealSettings {
      appealsCallbackUrl
      appealsCallbackHeaders
      appealsCallbackBody
    }
    me {
      role
    }
  }
`;

/**
 * __useGQLAppealSettingsQuery__
 *
 * To run a query within a React component, call `useGQLAppealSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLAppealSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLAppealSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLAppealSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLAppealSettingsQuery,
    GQLAppealSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLAppealSettingsQuery,
    GQLAppealSettingsQueryVariables
  >(GQLAppealSettingsDocument, options);
}
export function useGQLAppealSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLAppealSettingsQuery,
    GQLAppealSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLAppealSettingsQuery,
    GQLAppealSettingsQueryVariables
  >(GQLAppealSettingsDocument, options);
}
export type GQLAppealSettingsQueryHookResult = ReturnType<
  typeof useGQLAppealSettingsQuery
>;
export type GQLAppealSettingsLazyQueryHookResult = ReturnType<
  typeof useGQLAppealSettingsLazyQuery
>;
export type GQLAppealSettingsQueryResult = Apollo.QueryResult<
  GQLAppealSettingsQuery,
  GQLAppealSettingsQueryVariables
>;
export const GQLUpdateAppealSettingsDocument = gql`
  mutation UpdateAppealSettings($input: AppealSettingsInput!) {
    updateAppealSettings(input: $input) {
      appealsCallbackUrl
      appealsCallbackHeaders
      appealsCallbackBody
    }
  }
`;
export type GQLUpdateAppealSettingsMutationFn = Apollo.MutationFunction<
  GQLUpdateAppealSettingsMutation,
  GQLUpdateAppealSettingsMutationVariables
>;

/**
 * __useGQLUpdateAppealSettingsMutation__
 *
 * To run a mutation, you first call `useGQLUpdateAppealSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLUpdateAppealSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlUpdateAppealSettingsMutation, { data, loading, error }] = useGQLUpdateAppealSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLUpdateAppealSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLUpdateAppealSettingsMutation,
    GQLUpdateAppealSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLUpdateAppealSettingsMutation,
    GQLUpdateAppealSettingsMutationVariables
  >(GQLUpdateAppealSettingsDocument, options);
}
export type GQLUpdateAppealSettingsMutationHookResult = ReturnType<
  typeof useGQLUpdateAppealSettingsMutation
>;
export type GQLUpdateAppealSettingsMutationResult =
  Apollo.MutationResult<GQLUpdateAppealSettingsMutation>;
export type GQLUpdateAppealSettingsMutationOptions = Apollo.BaseMutationOptions<
  GQLUpdateAppealSettingsMutation,
  GQLUpdateAppealSettingsMutationVariables
>;
export const GQLGetDecisionsTableDocument = gql`
  query getDecisionsTable($input: GetDecisionCountsTableInput!) {
    getDecisionsTable(input: $input) {
      count
      type
      action_id
      queue_id
      reviewer_id
    }
    myOrg {
      actions {
        ... on ActionBase {
          id
          name
        }
      }
      users {
        id
        firstName
        lastName
      }
      mrtQueues {
        id
        name
      }
    }
  }
`;

/**
 * __useGQLGetDecisionsTableQuery__
 *
 * To run a query within a React component, call `useGQLGetDecisionsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetDecisionsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetDecisionsTableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLGetDecisionsTableQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetDecisionsTableQuery,
    GQLGetDecisionsTableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetDecisionsTableQuery,
    GQLGetDecisionsTableQueryVariables
  >(GQLGetDecisionsTableDocument, options);
}
export function useGQLGetDecisionsTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetDecisionsTableQuery,
    GQLGetDecisionsTableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetDecisionsTableQuery,
    GQLGetDecisionsTableQueryVariables
  >(GQLGetDecisionsTableDocument, options);
}
export type GQLGetDecisionsTableQueryHookResult = ReturnType<
  typeof useGQLGetDecisionsTableQuery
>;
export type GQLGetDecisionsTableLazyQueryHookResult = ReturnType<
  typeof useGQLGetDecisionsTableLazyQuery
>;
export type GQLGetDecisionsTableQueryResult = Apollo.QueryResult<
  GQLGetDecisionsTableQuery,
  GQLGetDecisionsTableQueryVariables
>;
export const GQLQueueFormDataDocument = gql`
  query QueueFormData {
    myOrg {
      hasAppealsEnabled
      hasPartialItemsEndpoint
      users {
        id
        firstName
        lastName
        role
      }
      actions {
        ... on ActionBase {
          id
          name
        }
      }
      usersWhoCanReviewEveryQueue {
        id
      }
    }
  }
`;

/**
 * __useGQLQueueFormDataQuery__
 *
 * To run a query within a React component, call `useGQLQueueFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLQueueFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLQueueFormDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLQueueFormDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLQueueFormDataQuery,
    GQLQueueFormDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLQueueFormDataQuery, GQLQueueFormDataQueryVariables>(
    GQLQueueFormDataDocument,
    options,
  );
}
export function useGQLQueueFormDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLQueueFormDataQuery,
    GQLQueueFormDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLQueueFormDataQuery,
    GQLQueueFormDataQueryVariables
  >(GQLQueueFormDataDocument, options);
}
export type GQLQueueFormDataQueryHookResult = ReturnType<
  typeof useGQLQueueFormDataQuery
>;
export type GQLQueueFormDataLazyQueryHookResult = ReturnType<
  typeof useGQLQueueFormDataLazyQuery
>;
export type GQLQueueFormDataQueryResult = Apollo.QueryResult<
  GQLQueueFormDataQuery,
  GQLQueueFormDataQueryVariables
>;
export const GQLManualReviewQueueDocument = gql`
  query ManualReviewQueue($id: ID!) {
    manualReviewQueue(id: $id) {
      ... on ManualReviewQueue {
        id
        name
        description
        explicitlyAssignedReviewers {
          id
        }
        hiddenActionIds
        isAppealsQueue
        autoCloseJobs
      }
    }
  }
`;

/**
 * __useGQLManualReviewQueueQuery__
 *
 * To run a query within a React component, call `useGQLManualReviewQueueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLManualReviewQueueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLManualReviewQueueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLManualReviewQueueQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLManualReviewQueueQuery,
    GQLManualReviewQueueQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLManualReviewQueueQuery,
    GQLManualReviewQueueQueryVariables
  >(GQLManualReviewQueueDocument, options);
}
export function useGQLManualReviewQueueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLManualReviewQueueQuery,
    GQLManualReviewQueueQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLManualReviewQueueQuery,
    GQLManualReviewQueueQueryVariables
  >(GQLManualReviewQueueDocument, options);
}
export type GQLManualReviewQueueQueryHookResult = ReturnType<
  typeof useGQLManualReviewQueueQuery
>;
export type GQLManualReviewQueueLazyQueryHookResult = ReturnType<
  typeof useGQLManualReviewQueueLazyQuery
>;
export type GQLManualReviewQueueQueryResult = Apollo.QueryResult<
  GQLManualReviewQueueQuery,
  GQLManualReviewQueueQueryVariables
>;
export const GQLCreateManualReviewQueueDocument = gql`
  mutation CreateManualReviewQueue($input: CreateManualReviewQueueInput!) {
    createManualReviewQueue(input: $input) {
      ... on MutateManualReviewQueueSuccessResponse {
        data {
          ... on ManualReviewQueue {
            id
            name
            description
          }
        }
      }
      ... on ManualReviewQueueNameExistsError {
        title
        status
        type
      }
    }
  }
`;
export type GQLCreateManualReviewQueueMutationFn = Apollo.MutationFunction<
  GQLCreateManualReviewQueueMutation,
  GQLCreateManualReviewQueueMutationVariables
>;

/**
 * __useGQLCreateManualReviewQueueMutation__
 *
 * To run a mutation, you first call `useGQLCreateManualReviewQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLCreateManualReviewQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCreateManualReviewQueueMutation, { data, loading, error }] = useGQLCreateManualReviewQueueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLCreateManualReviewQueueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLCreateManualReviewQueueMutation,
    GQLCreateManualReviewQueueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLCreateManualReviewQueueMutation,
    GQLCreateManualReviewQueueMutationVariables
  >(GQLCreateManualReviewQueueDocument, options);
}
export type GQLCreateManualReviewQueueMutationHookResult = ReturnType<
  typeof useGQLCreateManualReviewQueueMutation
>;
export type GQLCreateManualReviewQueueMutationResult =
  Apollo.MutationResult<GQLCreateManualReviewQueueMutation>;
export type GQLCreateManualReviewQueueMutationOptions =
  Apollo.BaseMutationOptions<
    GQLCreateManualReviewQueueMutation,
    GQLCreateManualReviewQueueMutationVariables
  >;
export const GQLUpdateManualReviewQueueDocument = gql`
  mutation UpdateManualReviewQueue($input: UpdateManualReviewQueueInput!) {
    updateManualReviewQueue(input: $input) {
      ... on MutateManualReviewQueueSuccessResponse {
        data {
          ... on ManualReviewQueue {
            id
            name
            description
          }
        }
      }
      ... on ManualReviewQueueNameExistsError {
        title
        status
        type
      }
      ... on NotFoundError {
        title
        status
        type
      }
    }
  }
`;
export type GQLUpdateManualReviewQueueMutationFn = Apollo.MutationFunction<
  GQLUpdateManualReviewQueueMutation,
  GQLUpdateManualReviewQueueMutationVariables
>;

/**
 * __useGQLUpdateManualReviewQueueMutation__
 *
 * To run a mutation, you first call `useGQLUpdateManualReviewQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLUpdateManualReviewQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlUpdateManualReviewQueueMutation, { data, loading, error }] = useGQLUpdateManualReviewQueueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLUpdateManualReviewQueueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLUpdateManualReviewQueueMutation,
    GQLUpdateManualReviewQueueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLUpdateManualReviewQueueMutation,
    GQLUpdateManualReviewQueueMutationVariables
  >(GQLUpdateManualReviewQueueDocument, options);
}
export type GQLUpdateManualReviewQueueMutationHookResult = ReturnType<
  typeof useGQLUpdateManualReviewQueueMutation
>;
export type GQLUpdateManualReviewQueueMutationResult =
  Apollo.MutationResult<GQLUpdateManualReviewQueueMutation>;
export type GQLUpdateManualReviewQueueMutationOptions =
  Apollo.BaseMutationOptions<
    GQLUpdateManualReviewQueueMutation,
    GQLUpdateManualReviewQueueMutationVariables
  >;
export const GQLManualReviewQueueJobsPreviewDocument = gql`
  query ManualReviewQueueJobsPreview($queueIds: [ID!]) {
    myOrg {
      policies {
        id
        name
      }
    }
    me {
      id
      permissions
      reviewableQueues(queueIds: $queueIds) {
        id
        name
        description
        pendingJobCount
        jobs {
          id
          createdAt
          policyIds
          payload {
            ... on ContentManualReviewJobPayload {
              item {
                ... on ItemBase {
                  ...ItemFields
                }
              }
            }
            ... on UserManualReviewJobPayload {
              item {
                ... on ItemBase {
                  ...ItemFields
                }
              }
            }
            ... on ThreadManualReviewJobPayload {
              item {
                ... on ItemBase {
                  ...ItemFields
                }
              }
            }
            ... on ContentAppealManualReviewJobPayload {
              item {
                ... on ItemBase {
                  ...ItemFields
                }
              }
            }
            ... on UserAppealManualReviewJobPayload {
              item {
                ... on ItemBase {
                  ...ItemFields
                }
              }
            }
            ... on ThreadAppealManualReviewJobPayload {
              item {
                ... on ItemBase {
                  ...ItemFields
                }
              }
            }
            ... on NcmecManualReviewJobPayload {
              item {
                ... on ItemBase {
                  ...ItemFields
                }
              }
            }
          }
        }
      }
    }
  }
  ${GQLItemFieldsFragmentDoc}
`;

/**
 * __useGQLManualReviewQueueJobsPreviewQuery__
 *
 * To run a query within a React component, call `useGQLManualReviewQueueJobsPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLManualReviewQueueJobsPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLManualReviewQueueJobsPreviewQuery({
 *   variables: {
 *      queueIds: // value for 'queueIds'
 *   },
 * });
 */
export function useGQLManualReviewQueueJobsPreviewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLManualReviewQueueJobsPreviewQuery,
    GQLManualReviewQueueJobsPreviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLManualReviewQueueJobsPreviewQuery,
    GQLManualReviewQueueJobsPreviewQueryVariables
  >(GQLManualReviewQueueJobsPreviewDocument, options);
}
export function useGQLManualReviewQueueJobsPreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLManualReviewQueueJobsPreviewQuery,
    GQLManualReviewQueueJobsPreviewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLManualReviewQueueJobsPreviewQuery,
    GQLManualReviewQueueJobsPreviewQueryVariables
  >(GQLManualReviewQueueJobsPreviewDocument, options);
}
export type GQLManualReviewQueueJobsPreviewQueryHookResult = ReturnType<
  typeof useGQLManualReviewQueueJobsPreviewQuery
>;
export type GQLManualReviewQueueJobsPreviewLazyQueryHookResult = ReturnType<
  typeof useGQLManualReviewQueueJobsPreviewLazyQuery
>;
export type GQLManualReviewQueueJobsPreviewQueryResult = Apollo.QueryResult<
  GQLManualReviewQueueJobsPreviewQuery,
  GQLManualReviewQueueJobsPreviewQueryVariables
>;
export const GQLManualReviewQueuesDocument = gql`
  query ManualReviewQueues {
    myOrg {
      hasAppealsEnabled
      previewJobsViewEnabled
    }
    me {
      id
      permissions
      favoriteMRTQueues {
        id
      }
      reviewableQueues {
        id
        name
        description
        pendingJobCount
        isDefaultQueue
        isAppealsQueue
      }
    }
  }
`;

/**
 * __useGQLManualReviewQueuesQuery__
 *
 * To run a query within a React component, call `useGQLManualReviewQueuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLManualReviewQueuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLManualReviewQueuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLManualReviewQueuesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLManualReviewQueuesQuery,
    GQLManualReviewQueuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLManualReviewQueuesQuery,
    GQLManualReviewQueuesQueryVariables
  >(GQLManualReviewQueuesDocument, options);
}
export function useGQLManualReviewQueuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLManualReviewQueuesQuery,
    GQLManualReviewQueuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLManualReviewQueuesQuery,
    GQLManualReviewQueuesQueryVariables
  >(GQLManualReviewQueuesDocument, options);
}
export type GQLManualReviewQueuesQueryHookResult = ReturnType<
  typeof useGQLManualReviewQueuesQuery
>;
export type GQLManualReviewQueuesLazyQueryHookResult = ReturnType<
  typeof useGQLManualReviewQueuesLazyQuery
>;
export type GQLManualReviewQueuesQueryResult = Apollo.QueryResult<
  GQLManualReviewQueuesQuery,
  GQLManualReviewQueuesQueryVariables
>;
export const GQLRoutingRulesDocument = gql`
  query RoutingRules {
    myOrg {
      id
      routingRules {
        id
        name
        destinationQueue {
          id
        }
      }
    }
  }
`;

/**
 * __useGQLRoutingRulesQuery__
 *
 * To run a query within a React component, call `useGQLRoutingRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLRoutingRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLRoutingRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLRoutingRulesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLRoutingRulesQuery,
    GQLRoutingRulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLRoutingRulesQuery, GQLRoutingRulesQueryVariables>(
    GQLRoutingRulesDocument,
    options,
  );
}
export function useGQLRoutingRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLRoutingRulesQuery,
    GQLRoutingRulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLRoutingRulesQuery,
    GQLRoutingRulesQueryVariables
  >(GQLRoutingRulesDocument, options);
}
export type GQLRoutingRulesQueryHookResult = ReturnType<
  typeof useGQLRoutingRulesQuery
>;
export type GQLRoutingRulesLazyQueryHookResult = ReturnType<
  typeof useGQLRoutingRulesLazyQuery
>;
export type GQLRoutingRulesQueryResult = Apollo.QueryResult<
  GQLRoutingRulesQuery,
  GQLRoutingRulesQueryVariables
>;
export const GQLDeleteManualReviewQueueDocument = gql`
  mutation DeleteManualReviewQueue($id: ID!) {
    deleteManualReviewQueue(id: $id)
  }
`;
export type GQLDeleteManualReviewQueueMutationFn = Apollo.MutationFunction<
  GQLDeleteManualReviewQueueMutation,
  GQLDeleteManualReviewQueueMutationVariables
>;

/**
 * __useGQLDeleteManualReviewQueueMutation__
 *
 * To run a mutation, you first call `useGQLDeleteManualReviewQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLDeleteManualReviewQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlDeleteManualReviewQueueMutation, { data, loading, error }] = useGQLDeleteManualReviewQueueMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLDeleteManualReviewQueueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLDeleteManualReviewQueueMutation,
    GQLDeleteManualReviewQueueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLDeleteManualReviewQueueMutation,
    GQLDeleteManualReviewQueueMutationVariables
  >(GQLDeleteManualReviewQueueDocument, options);
}
export type GQLDeleteManualReviewQueueMutationHookResult = ReturnType<
  typeof useGQLDeleteManualReviewQueueMutation
>;
export type GQLDeleteManualReviewQueueMutationResult =
  Apollo.MutationResult<GQLDeleteManualReviewQueueMutation>;
export type GQLDeleteManualReviewQueueMutationOptions =
  Apollo.BaseMutationOptions<
    GQLDeleteManualReviewQueueMutation,
    GQLDeleteManualReviewQueueMutationVariables
  >;
export const GQLDeleteAllJobsFromQueueDocument = gql`
  mutation DeleteAllJobsFromQueue($queueId: ID!) {
    deleteAllJobsFromQueue(queueId: $queueId) {
      ... on DeleteAllJobsFromQueueSuccessResponse {
        _
      }
      ... on Error {
        title
      }
    }
  }
`;
export type GQLDeleteAllJobsFromQueueMutationFn = Apollo.MutationFunction<
  GQLDeleteAllJobsFromQueueMutation,
  GQLDeleteAllJobsFromQueueMutationVariables
>;

/**
 * __useGQLDeleteAllJobsFromQueueMutation__
 *
 * To run a mutation, you first call `useGQLDeleteAllJobsFromQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLDeleteAllJobsFromQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlDeleteAllJobsFromQueueMutation, { data, loading, error }] = useGQLDeleteAllJobsFromQueueMutation({
 *   variables: {
 *      queueId: // value for 'queueId'
 *   },
 * });
 */
export function useGQLDeleteAllJobsFromQueueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLDeleteAllJobsFromQueueMutation,
    GQLDeleteAllJobsFromQueueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLDeleteAllJobsFromQueueMutation,
    GQLDeleteAllJobsFromQueueMutationVariables
  >(GQLDeleteAllJobsFromQueueDocument, options);
}
export type GQLDeleteAllJobsFromQueueMutationHookResult = ReturnType<
  typeof useGQLDeleteAllJobsFromQueueMutation
>;
export type GQLDeleteAllJobsFromQueueMutationResult =
  Apollo.MutationResult<GQLDeleteAllJobsFromQueueMutation>;
export type GQLDeleteAllJobsFromQueueMutationOptions =
  Apollo.BaseMutationOptions<
    GQLDeleteAllJobsFromQueueMutation,
    GQLDeleteAllJobsFromQueueMutationVariables
  >;
export const GQLAddFavoriteMrtQueueDocument = gql`
  mutation AddFavoriteMRTQueue($queueId: ID!) {
    addFavoriteMRTQueue(queueId: $queueId) {
      ... on AddFavoriteMRTQueueSuccessResponse {
        _
      }
    }
  }
`;
export type GQLAddFavoriteMrtQueueMutationFn = Apollo.MutationFunction<
  GQLAddFavoriteMrtQueueMutation,
  GQLAddFavoriteMrtQueueMutationVariables
>;

/**
 * __useGQLAddFavoriteMrtQueueMutation__
 *
 * To run a mutation, you first call `useGQLAddFavoriteMrtQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLAddFavoriteMrtQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlAddFavoriteMrtQueueMutation, { data, loading, error }] = useGQLAddFavoriteMrtQueueMutation({
 *   variables: {
 *      queueId: // value for 'queueId'
 *   },
 * });
 */
export function useGQLAddFavoriteMrtQueueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLAddFavoriteMrtQueueMutation,
    GQLAddFavoriteMrtQueueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLAddFavoriteMrtQueueMutation,
    GQLAddFavoriteMrtQueueMutationVariables
  >(GQLAddFavoriteMrtQueueDocument, options);
}
export type GQLAddFavoriteMrtQueueMutationHookResult = ReturnType<
  typeof useGQLAddFavoriteMrtQueueMutation
>;
export type GQLAddFavoriteMrtQueueMutationResult =
  Apollo.MutationResult<GQLAddFavoriteMrtQueueMutation>;
export type GQLAddFavoriteMrtQueueMutationOptions = Apollo.BaseMutationOptions<
  GQLAddFavoriteMrtQueueMutation,
  GQLAddFavoriteMrtQueueMutationVariables
>;
export const GQLRemoveFavoriteMrtQueueDocument = gql`
  mutation RemoveFavoriteMRTQueue($queueId: ID!) {
    removeFavoriteMRTQueue(queueId: $queueId) {
      ... on RemoveFavoriteMRTQueueSuccessResponse {
        _
      }
    }
  }
`;
export type GQLRemoveFavoriteMrtQueueMutationFn = Apollo.MutationFunction<
  GQLRemoveFavoriteMrtQueueMutation,
  GQLRemoveFavoriteMrtQueueMutationVariables
>;

/**
 * __useGQLRemoveFavoriteMrtQueueMutation__
 *
 * To run a mutation, you first call `useGQLRemoveFavoriteMrtQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLRemoveFavoriteMrtQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlRemoveFavoriteMrtQueueMutation, { data, loading, error }] = useGQLRemoveFavoriteMrtQueueMutation({
 *   variables: {
 *      queueId: // value for 'queueId'
 *   },
 * });
 */
export function useGQLRemoveFavoriteMrtQueueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLRemoveFavoriteMrtQueueMutation,
    GQLRemoveFavoriteMrtQueueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLRemoveFavoriteMrtQueueMutation,
    GQLRemoveFavoriteMrtQueueMutationVariables
  >(GQLRemoveFavoriteMrtQueueDocument, options);
}
export type GQLRemoveFavoriteMrtQueueMutationHookResult = ReturnType<
  typeof useGQLRemoveFavoriteMrtQueueMutation
>;
export type GQLRemoveFavoriteMrtQueueMutationResult =
  Apollo.MutationResult<GQLRemoveFavoriteMrtQueueMutation>;
export type GQLRemoveFavoriteMrtQueueMutationOptions =
  Apollo.BaseMutationOptions<
    GQLRemoveFavoriteMrtQueueMutation,
    GQLRemoveFavoriteMrtQueueMutationVariables
  >;
export const GQLGetResolvedJobsForUserDocument = gql`
  query getResolvedJobsForUser($timeZone: String!) {
    getResolvedJobsForUser(timeZone: $timeZone)
  }
`;

/**
 * __useGQLGetResolvedJobsForUserQuery__
 *
 * To run a query within a React component, call `useGQLGetResolvedJobsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetResolvedJobsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetResolvedJobsForUserQuery({
 *   variables: {
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGQLGetResolvedJobsForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetResolvedJobsForUserQuery,
    GQLGetResolvedJobsForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetResolvedJobsForUserQuery,
    GQLGetResolvedJobsForUserQueryVariables
  >(GQLGetResolvedJobsForUserDocument, options);
}
export function useGQLGetResolvedJobsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetResolvedJobsForUserQuery,
    GQLGetResolvedJobsForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetResolvedJobsForUserQuery,
    GQLGetResolvedJobsForUserQueryVariables
  >(GQLGetResolvedJobsForUserDocument, options);
}
export type GQLGetResolvedJobsForUserQueryHookResult = ReturnType<
  typeof useGQLGetResolvedJobsForUserQuery
>;
export type GQLGetResolvedJobsForUserLazyQueryHookResult = ReturnType<
  typeof useGQLGetResolvedJobsForUserLazyQuery
>;
export type GQLGetResolvedJobsForUserQueryResult = Apollo.QueryResult<
  GQLGetResolvedJobsForUserQuery,
  GQLGetResolvedJobsForUserQueryVariables
>;
export const GQLGetSkippedJobsForUserDocument = gql`
  query getSkippedJobsForUser($timeZone: String!) {
    getSkippedJobsForUser(timeZone: $timeZone)
  }
`;

/**
 * __useGQLGetSkippedJobsForUserQuery__
 *
 * To run a query within a React component, call `useGQLGetSkippedJobsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetSkippedJobsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetSkippedJobsForUserQuery({
 *   variables: {
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGQLGetSkippedJobsForUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetSkippedJobsForUserQuery,
    GQLGetSkippedJobsForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetSkippedJobsForUserQuery,
    GQLGetSkippedJobsForUserQueryVariables
  >(GQLGetSkippedJobsForUserDocument, options);
}
export function useGQLGetSkippedJobsForUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetSkippedJobsForUserQuery,
    GQLGetSkippedJobsForUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetSkippedJobsForUserQuery,
    GQLGetSkippedJobsForUserQueryVariables
  >(GQLGetSkippedJobsForUserDocument, options);
}
export type GQLGetSkippedJobsForUserQueryHookResult = ReturnType<
  typeof useGQLGetSkippedJobsForUserQuery
>;
export type GQLGetSkippedJobsForUserLazyQueryHookResult = ReturnType<
  typeof useGQLGetSkippedJobsForUserLazyQuery
>;
export type GQLGetSkippedJobsForUserQueryResult = Apollo.QueryResult<
  GQLGetSkippedJobsForUserQuery,
  GQLGetSkippedJobsForUserQueryVariables
>;
export const GQLRecentDecisionsSummaryDataDocument = gql`
  query RecentDecisionsSummaryData {
    myOrg {
      users {
        id
        firstName
        lastName
      }
      mrtQueues {
        id
        name
      }
      actions {
        ... on ActionBase {
          id
          name
        }
      }
      policies {
        id
        name
      }
      itemTypes {
        ... on ItemTypeBase {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGQLRecentDecisionsSummaryDataQuery__
 *
 * To run a query within a React component, call `useGQLRecentDecisionsSummaryDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLRecentDecisionsSummaryDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLRecentDecisionsSummaryDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLRecentDecisionsSummaryDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLRecentDecisionsSummaryDataQuery,
    GQLRecentDecisionsSummaryDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLRecentDecisionsSummaryDataQuery,
    GQLRecentDecisionsSummaryDataQueryVariables
  >(GQLRecentDecisionsSummaryDataDocument, options);
}
export function useGQLRecentDecisionsSummaryDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLRecentDecisionsSummaryDataQuery,
    GQLRecentDecisionsSummaryDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLRecentDecisionsSummaryDataQuery,
    GQLRecentDecisionsSummaryDataQueryVariables
  >(GQLRecentDecisionsSummaryDataDocument, options);
}
export type GQLRecentDecisionsSummaryDataQueryHookResult = ReturnType<
  typeof useGQLRecentDecisionsSummaryDataQuery
>;
export type GQLRecentDecisionsSummaryDataLazyQueryHookResult = ReturnType<
  typeof useGQLRecentDecisionsSummaryDataLazyQuery
>;
export type GQLRecentDecisionsSummaryDataQueryResult = Apollo.QueryResult<
  GQLRecentDecisionsSummaryDataQuery,
  GQLRecentDecisionsSummaryDataQueryVariables
>;
export const GQLGetRecentDecisionsDocument = gql`
  query GetRecentDecisions($input: RecentDecisionsInput!) {
    getRecentDecisions(input: $input) {
      id
      jobId
      queueId
      reviewerId
      itemId
      itemTypeId
      decisions {
        ... on ManualReviewDecisionComponentBase {
          ...ManualReviewDecisionComponentFields
        }
      }
      relatedActions {
        ... on ManualReviewDecisionComponentBase {
          ...ManualReviewDecisionComponentFields
        }
      }
      createdAt
      decisionReason
    }
    myOrg {
      id
      actions {
        ... on ActionBase {
          id
          name
        }
      }
      policies {
        id
        name
      }
      users {
        id
        firstName
        lastName
      }
      mrtQueues {
        id
        name
      }
    }
  }
  ${GQLManualReviewDecisionComponentFieldsFragmentDoc}
`;

/**
 * __useGQLGetRecentDecisionsQuery__
 *
 * To run a query within a React component, call `useGQLGetRecentDecisionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetRecentDecisionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetRecentDecisionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLGetRecentDecisionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetRecentDecisionsQuery,
    GQLGetRecentDecisionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetRecentDecisionsQuery,
    GQLGetRecentDecisionsQueryVariables
  >(GQLGetRecentDecisionsDocument, options);
}
export function useGQLGetRecentDecisionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetRecentDecisionsQuery,
    GQLGetRecentDecisionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetRecentDecisionsQuery,
    GQLGetRecentDecisionsQueryVariables
  >(GQLGetRecentDecisionsDocument, options);
}
export type GQLGetRecentDecisionsQueryHookResult = ReturnType<
  typeof useGQLGetRecentDecisionsQuery
>;
export type GQLGetRecentDecisionsLazyQueryHookResult = ReturnType<
  typeof useGQLGetRecentDecisionsLazyQuery
>;
export type GQLGetRecentDecisionsQueryResult = Apollo.QueryResult<
  GQLGetRecentDecisionsQuery,
  GQLGetRecentDecisionsQueryVariables
>;
export const GQLGetSkipsForRecentDecisionsDocument = gql`
  query getSkipsForRecentDecisions($input: RecentDecisionsInput!) {
    getSkipsForRecentDecisions(input: $input) {
      jobId
      userId
      queueId
      ts
    }
  }
`;

/**
 * __useGQLGetSkipsForRecentDecisionsQuery__
 *
 * To run a query within a React component, call `useGQLGetSkipsForRecentDecisionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetSkipsForRecentDecisionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetSkipsForRecentDecisionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLGetSkipsForRecentDecisionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetSkipsForRecentDecisionsQuery,
    GQLGetSkipsForRecentDecisionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetSkipsForRecentDecisionsQuery,
    GQLGetSkipsForRecentDecisionsQueryVariables
  >(GQLGetSkipsForRecentDecisionsDocument, options);
}
export function useGQLGetSkipsForRecentDecisionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetSkipsForRecentDecisionsQuery,
    GQLGetSkipsForRecentDecisionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetSkipsForRecentDecisionsQuery,
    GQLGetSkipsForRecentDecisionsQueryVariables
  >(GQLGetSkipsForRecentDecisionsDocument, options);
}
export type GQLGetSkipsForRecentDecisionsQueryHookResult = ReturnType<
  typeof useGQLGetSkipsForRecentDecisionsQuery
>;
export type GQLGetSkipsForRecentDecisionsLazyQueryHookResult = ReturnType<
  typeof useGQLGetSkipsForRecentDecisionsLazyQuery
>;
export type GQLGetSkipsForRecentDecisionsQueryResult = Apollo.QueryResult<
  GQLGetSkipsForRecentDecisionsQuery,
  GQLGetSkipsForRecentDecisionsQueryVariables
>;
export const GQLGetDecidedJobDocument = gql`
  query GetDecidedJob($id: ID!) {
    getDecidedJob(id: $id) {
      ...JobFields
    }
  }
  ${GQLJobFieldsFragmentDoc}
`;

/**
 * __useGQLGetDecidedJobQuery__
 *
 * To run a query within a React component, call `useGQLGetDecidedJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetDecidedJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetDecidedJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLGetDecidedJobQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetDecidedJobQuery,
    GQLGetDecidedJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLGetDecidedJobQuery, GQLGetDecidedJobQueryVariables>(
    GQLGetDecidedJobDocument,
    options,
  );
}
export function useGQLGetDecidedJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetDecidedJobQuery,
    GQLGetDecidedJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetDecidedJobQuery,
    GQLGetDecidedJobQueryVariables
  >(GQLGetDecidedJobDocument, options);
}
export type GQLGetDecidedJobQueryHookResult = ReturnType<
  typeof useGQLGetDecidedJobQuery
>;
export type GQLGetDecidedJobLazyQueryHookResult = ReturnType<
  typeof useGQLGetDecidedJobLazyQuery
>;
export type GQLGetDecidedJobQueryResult = Apollo.QueryResult<
  GQLGetDecidedJobQuery,
  GQLGetDecidedJobQueryVariables
>;
export const GQLManualReviewSafetySettingsDocument = gql`
  query ManualReviewSafetySettings {
    me {
      interfacePreferences {
        moderatorSafetyMuteVideo
        moderatorSafetyGrayscale
        moderatorSafetyBlurLevel
      }
    }
  }
`;

/**
 * __useGQLManualReviewSafetySettingsQuery__
 *
 * To run a query within a React component, call `useGQLManualReviewSafetySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLManualReviewSafetySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLManualReviewSafetySettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLManualReviewSafetySettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLManualReviewSafetySettingsQuery,
    GQLManualReviewSafetySettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLManualReviewSafetySettingsQuery,
    GQLManualReviewSafetySettingsQueryVariables
  >(GQLManualReviewSafetySettingsDocument, options);
}
export function useGQLManualReviewSafetySettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLManualReviewSafetySettingsQuery,
    GQLManualReviewSafetySettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLManualReviewSafetySettingsQuery,
    GQLManualReviewSafetySettingsQueryVariables
  >(GQLManualReviewSafetySettingsDocument, options);
}
export type GQLManualReviewSafetySettingsQueryHookResult = ReturnType<
  typeof useGQLManualReviewSafetySettingsQuery
>;
export type GQLManualReviewSafetySettingsLazyQueryHookResult = ReturnType<
  typeof useGQLManualReviewSafetySettingsLazyQuery
>;
export type GQLManualReviewSafetySettingsQueryResult = Apollo.QueryResult<
  GQLManualReviewSafetySettingsQuery,
  GQLManualReviewSafetySettingsQueryVariables
>;
export const GQLSetModeratorSafetySettingsDocument = gql`
  mutation SetModeratorSafetySettings(
    $moderatorSafetySettings: ModeratorSafetySettingsInput!
  ) {
    setModeratorSafetySettings(
      moderatorSafetySettings: $moderatorSafetySettings
    ) {
      _
    }
  }
`;
export type GQLSetModeratorSafetySettingsMutationFn = Apollo.MutationFunction<
  GQLSetModeratorSafetySettingsMutation,
  GQLSetModeratorSafetySettingsMutationVariables
>;

/**
 * __useGQLSetModeratorSafetySettingsMutation__
 *
 * To run a mutation, you first call `useGQLSetModeratorSafetySettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLSetModeratorSafetySettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlSetModeratorSafetySettingsMutation, { data, loading, error }] = useGQLSetModeratorSafetySettingsMutation({
 *   variables: {
 *      moderatorSafetySettings: // value for 'moderatorSafetySettings'
 *   },
 * });
 */
export function useGQLSetModeratorSafetySettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLSetModeratorSafetySettingsMutation,
    GQLSetModeratorSafetySettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLSetModeratorSafetySettingsMutation,
    GQLSetModeratorSafetySettingsMutationVariables
  >(GQLSetModeratorSafetySettingsDocument, options);
}
export type GQLSetModeratorSafetySettingsMutationHookResult = ReturnType<
  typeof useGQLSetModeratorSafetySettingsMutation
>;
export type GQLSetModeratorSafetySettingsMutationResult =
  Apollo.MutationResult<GQLSetModeratorSafetySettingsMutation>;
export type GQLSetModeratorSafetySettingsMutationOptions =
  Apollo.BaseMutationOptions<
    GQLSetModeratorSafetySettingsMutation,
    GQLSetModeratorSafetySettingsMutationVariables
  >;
export const GQLActionsWithCustomParamsDocument = gql`
  query ActionsWithCustomParams {
    myOrg {
      actions {
        ... on ActionBase {
          id
          name
        }
        ... on CustomerDefinedAction {
          id
          name
          customMrtApiParams {
            name
            type
            displayName
          }
        }
      }
    }
  }
`;

/**
 * __useGQLActionsWithCustomParamsQuery__
 *
 * To run a query within a React component, call `useGQLActionsWithCustomParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLActionsWithCustomParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLActionsWithCustomParamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLActionsWithCustomParamsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLActionsWithCustomParamsQuery,
    GQLActionsWithCustomParamsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLActionsWithCustomParamsQuery,
    GQLActionsWithCustomParamsQueryVariables
  >(GQLActionsWithCustomParamsDocument, options);
}
export function useGQLActionsWithCustomParamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLActionsWithCustomParamsQuery,
    GQLActionsWithCustomParamsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLActionsWithCustomParamsQuery,
    GQLActionsWithCustomParamsQueryVariables
  >(GQLActionsWithCustomParamsDocument, options);
}
export type GQLActionsWithCustomParamsQueryHookResult = ReturnType<
  typeof useGQLActionsWithCustomParamsQuery
>;
export type GQLActionsWithCustomParamsLazyQueryHookResult = ReturnType<
  typeof useGQLActionsWithCustomParamsLazyQuery
>;
export type GQLActionsWithCustomParamsQueryResult = Apollo.QueryResult<
  GQLActionsWithCustomParamsQuery,
  GQLActionsWithCustomParamsQueryVariables
>;
export const GQLManualReviewJobInfoDocument = gql`
  query ManualReviewJobInfo($jobIds: [ID!]) {
    myOrg {
      id
      policies {
        id
        name
        parentId
        policyText
        enforcementGuidelines
      }
      itemTypes {
        ...ItemTypeFragment
      }
      actions {
        ... on ActionBase {
          id
          name
          penalty
          itemTypes {
            ... on ItemTypeBase {
              id
              name
            }
          }
        }
        ... on CustomerDefinedAction {
          id
          name
          penalty
          itemTypes {
            ... on ItemTypeBase {
              id
              name
            }
          }
          customMrtApiParams {
            name
            type
            displayName
          }
        }
      }
      mrtQueues {
        id
        name
        isAppealsQueue
        autoCloseJobs
      }
      hasNCMECReportingEnabled
      requiresPolicyForDecisionsInMrt
      requiresDecisionReasonInMrt
      allowMultiplePoliciesPerAction
      hideSkipButtonForNonAdmins
    }
    me {
      id
      reviewableQueues {
        id
        name
        pendingJobCount
        hiddenActionIds
        jobs(ids: $jobIds) {
          ...JobFields
        }
      }
      role
    }
  }
  ${GQLItemTypeFragmentFragmentDoc}
  ${GQLJobFieldsFragmentDoc}
`;

/**
 * __useGQLManualReviewJobInfoQuery__
 *
 * To run a query within a React component, call `useGQLManualReviewJobInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLManualReviewJobInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLManualReviewJobInfoQuery({
 *   variables: {
 *      jobIds: // value for 'jobIds'
 *   },
 * });
 */
export function useGQLManualReviewJobInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLManualReviewJobInfoQuery,
    GQLManualReviewJobInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLManualReviewJobInfoQuery,
    GQLManualReviewJobInfoQueryVariables
  >(GQLManualReviewJobInfoDocument, options);
}
export function useGQLManualReviewJobInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLManualReviewJobInfoQuery,
    GQLManualReviewJobInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLManualReviewJobInfoQuery,
    GQLManualReviewJobInfoQueryVariables
  >(GQLManualReviewJobInfoDocument, options);
}
export type GQLManualReviewJobInfoQueryHookResult = ReturnType<
  typeof useGQLManualReviewJobInfoQuery
>;
export type GQLManualReviewJobInfoLazyQueryHookResult = ReturnType<
  typeof useGQLManualReviewJobInfoLazyQuery
>;
export type GQLManualReviewJobInfoQueryResult = Apollo.QueryResult<
  GQLManualReviewJobInfoQuery,
  GQLManualReviewJobInfoQueryVariables
>;
export const GQLDequeueManualReviewJobDocument = gql`
  mutation DequeueManualReviewJob($queueId: ID!) {
    dequeueManualReviewJob(queueId: $queueId) {
      ... on DequeueManualReviewJobSuccessResponse {
        job {
          ...JobFields
        }
        lockToken
        numPendingJobs
      }
    }
  }
  ${GQLJobFieldsFragmentDoc}
`;
export type GQLDequeueManualReviewJobMutationFn = Apollo.MutationFunction<
  GQLDequeueManualReviewJobMutation,
  GQLDequeueManualReviewJobMutationVariables
>;

/**
 * __useGQLDequeueManualReviewJobMutation__
 *
 * To run a mutation, you first call `useGQLDequeueManualReviewJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLDequeueManualReviewJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlDequeueManualReviewJobMutation, { data, loading, error }] = useGQLDequeueManualReviewJobMutation({
 *   variables: {
 *      queueId: // value for 'queueId'
 *   },
 * });
 */
export function useGQLDequeueManualReviewJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLDequeueManualReviewJobMutation,
    GQLDequeueManualReviewJobMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLDequeueManualReviewJobMutation,
    GQLDequeueManualReviewJobMutationVariables
  >(GQLDequeueManualReviewJobDocument, options);
}
export type GQLDequeueManualReviewJobMutationHookResult = ReturnType<
  typeof useGQLDequeueManualReviewJobMutation
>;
export type GQLDequeueManualReviewJobMutationResult =
  Apollo.MutationResult<GQLDequeueManualReviewJobMutation>;
export type GQLDequeueManualReviewJobMutationOptions =
  Apollo.BaseMutationOptions<
    GQLDequeueManualReviewJobMutation,
    GQLDequeueManualReviewJobMutationVariables
  >;
export const GQLSubmitManualReviewDecisionDocument = gql`
  mutation SubmitManualReviewDecision($input: SubmitDecisionInput!) {
    submitManualReviewDecision(input: $input) {
      ... on SubmitDecisionSuccessResponse {
        success
      }
      ... on JobHasAlreadyBeenSubmittedError {
        title
        status
        type
      }
      ... on SubmittedJobActionNotFoundError {
        title
        status
        type
      }
      ... on NoJobWithIdInQueueError {
        title
        status
        type
      }
      ... on RecordingJobDecisionFailedError {
        title
        status
        type
        detail
      }
    }
  }
`;
export type GQLSubmitManualReviewDecisionMutationFn = Apollo.MutationFunction<
  GQLSubmitManualReviewDecisionMutation,
  GQLSubmitManualReviewDecisionMutationVariables
>;

/**
 * __useGQLSubmitManualReviewDecisionMutation__
 *
 * To run a mutation, you first call `useGQLSubmitManualReviewDecisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLSubmitManualReviewDecisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlSubmitManualReviewDecisionMutation, { data, loading, error }] = useGQLSubmitManualReviewDecisionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLSubmitManualReviewDecisionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLSubmitManualReviewDecisionMutation,
    GQLSubmitManualReviewDecisionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLSubmitManualReviewDecisionMutation,
    GQLSubmitManualReviewDecisionMutationVariables
  >(GQLSubmitManualReviewDecisionDocument, options);
}
export type GQLSubmitManualReviewDecisionMutationHookResult = ReturnType<
  typeof useGQLSubmitManualReviewDecisionMutation
>;
export type GQLSubmitManualReviewDecisionMutationResult =
  Apollo.MutationResult<GQLSubmitManualReviewDecisionMutation>;
export type GQLSubmitManualReviewDecisionMutationOptions =
  Apollo.BaseMutationOptions<
    GQLSubmitManualReviewDecisionMutation,
    GQLSubmitManualReviewDecisionMutationVariables
  >;
export const GQLLogSkipDocument = gql`
  mutation LogSkip($input: LogSkipInput!) {
    logSkip(input: $input)
  }
`;
export type GQLLogSkipMutationFn = Apollo.MutationFunction<
  GQLLogSkipMutation,
  GQLLogSkipMutationVariables
>;

/**
 * __useGQLLogSkipMutation__
 *
 * To run a mutation, you first call `useGQLLogSkipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLLogSkipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlLogSkipMutation, { data, loading, error }] = useGQLLogSkipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLLogSkipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLLogSkipMutation,
    GQLLogSkipMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GQLLogSkipMutation, GQLLogSkipMutationVariables>(
    GQLLogSkipDocument,
    options,
  );
}
export type GQLLogSkipMutationHookResult = ReturnType<
  typeof useGQLLogSkipMutation
>;
export type GQLLogSkipMutationResult =
  Apollo.MutationResult<GQLLogSkipMutation>;
export type GQLLogSkipMutationOptions = Apollo.BaseMutationOptions<
  GQLLogSkipMutation,
  GQLLogSkipMutationVariables
>;
export const GQLGetRelatedItemsDocument = gql`
  query getRelatedItems($itemIdentifiers: [ItemIdentifierInput!]!) {
    latestItemSubmissions(itemIdentifiers: $itemIdentifiers) {
      ... on UserItem {
        id
        submissionId
        submissionTime
        data
        type {
          id
          name
          baseFields {
            name
            type
            required
            container {
              containerType
              keyScalarType
              valueScalarType
            }
          }
          schemaFieldRoles {
            displayName
            createdAt
            profileIcon
            backgroundImage
          }
        }
      }
      ... on ContentItem {
        id
        submissionId
        submissionTime
        data
        type {
          id
          name
          baseFields {
            name
            type
            required
            container {
              containerType
              keyScalarType
              valueScalarType
            }
          }
        }
      }
      ... on ThreadItem {
        id
        submissionId
        submissionTime
        data
        type {
          id
          name
          baseFields {
            name
            type
            required
            container {
              containerType
              keyScalarType
              valueScalarType
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGQLGetRelatedItemsQuery__
 *
 * To run a query within a React component, call `useGQLGetRelatedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetRelatedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetRelatedItemsQuery({
 *   variables: {
 *      itemIdentifiers: // value for 'itemIdentifiers'
 *   },
 * });
 */
export function useGQLGetRelatedItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetRelatedItemsQuery,
    GQLGetRelatedItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetRelatedItemsQuery,
    GQLGetRelatedItemsQueryVariables
  >(GQLGetRelatedItemsDocument, options);
}
export function useGQLGetRelatedItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetRelatedItemsQuery,
    GQLGetRelatedItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetRelatedItemsQuery,
    GQLGetRelatedItemsQueryVariables
  >(GQLGetRelatedItemsDocument, options);
}
export type GQLGetRelatedItemsQueryHookResult = ReturnType<
  typeof useGQLGetRelatedItemsQuery
>;
export type GQLGetRelatedItemsLazyQueryHookResult = ReturnType<
  typeof useGQLGetRelatedItemsLazyQuery
>;
export type GQLGetRelatedItemsQueryResult = Apollo.QueryResult<
  GQLGetRelatedItemsQuery,
  GQLGetRelatedItemsQueryVariables
>;
export const GQLGetCommentsForJobDocument = gql`
  query GetCommentsForJob($jobId: ID!) {
    getCommentsForJob(jobId: $jobId) {
      ... on ManualReviewJobComment {
        ...ManualReviewJobCommentFields
      }
    }
  }
  ${GQLManualReviewJobCommentFieldsFragmentDoc}
`;

/**
 * __useGQLGetCommentsForJobQuery__
 *
 * To run a query within a React component, call `useGQLGetCommentsForJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetCommentsForJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetCommentsForJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGQLGetCommentsForJobQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetCommentsForJobQuery,
    GQLGetCommentsForJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetCommentsForJobQuery,
    GQLGetCommentsForJobQueryVariables
  >(GQLGetCommentsForJobDocument, options);
}
export function useGQLGetCommentsForJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetCommentsForJobQuery,
    GQLGetCommentsForJobQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetCommentsForJobQuery,
    GQLGetCommentsForJobQueryVariables
  >(GQLGetCommentsForJobDocument, options);
}
export type GQLGetCommentsForJobQueryHookResult = ReturnType<
  typeof useGQLGetCommentsForJobQuery
>;
export type GQLGetCommentsForJobLazyQueryHookResult = ReturnType<
  typeof useGQLGetCommentsForJobLazyQuery
>;
export type GQLGetCommentsForJobQueryResult = Apollo.QueryResult<
  GQLGetCommentsForJobQuery,
  GQLGetCommentsForJobQueryVariables
>;
export const GQLAddJobCommentDocument = gql`
  mutation AddJobComment($input: CreateManualReviewJobCommentInput!) {
    createManualReviewJobComment(input: $input) {
      ... on AddManualReviewJobCommentSuccessResponse {
        comment {
          ... on ManualReviewJobComment {
            ...ManualReviewJobCommentFields
          }
        }
      }
      ... on NotFoundError {
        title
      }
    }
  }
  ${GQLManualReviewJobCommentFieldsFragmentDoc}
`;
export type GQLAddJobCommentMutationFn = Apollo.MutationFunction<
  GQLAddJobCommentMutation,
  GQLAddJobCommentMutationVariables
>;

/**
 * __useGQLAddJobCommentMutation__
 *
 * To run a mutation, you first call `useGQLAddJobCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLAddJobCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlAddJobCommentMutation, { data, loading, error }] = useGQLAddJobCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLAddJobCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLAddJobCommentMutation,
    GQLAddJobCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLAddJobCommentMutation,
    GQLAddJobCommentMutationVariables
  >(GQLAddJobCommentDocument, options);
}
export type GQLAddJobCommentMutationHookResult = ReturnType<
  typeof useGQLAddJobCommentMutation
>;
export type GQLAddJobCommentMutationResult =
  Apollo.MutationResult<GQLAddJobCommentMutation>;
export type GQLAddJobCommentMutationOptions = Apollo.BaseMutationOptions<
  GQLAddJobCommentMutation,
  GQLAddJobCommentMutationVariables
>;
export const GQLDeleteJobCommentDocument = gql`
  mutation DeleteJobComment($input: DeleteManualReviewJobCommentInput!) {
    deleteManualReviewJobComment(input: $input)
  }
`;
export type GQLDeleteJobCommentMutationFn = Apollo.MutationFunction<
  GQLDeleteJobCommentMutation,
  GQLDeleteJobCommentMutationVariables
>;

/**
 * __useGQLDeleteJobCommentMutation__
 *
 * To run a mutation, you first call `useGQLDeleteJobCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLDeleteJobCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlDeleteJobCommentMutation, { data, loading, error }] = useGQLDeleteJobCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLDeleteJobCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLDeleteJobCommentMutation,
    GQLDeleteJobCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLDeleteJobCommentMutation,
    GQLDeleteJobCommentMutationVariables
  >(GQLDeleteJobCommentDocument, options);
}
export type GQLDeleteJobCommentMutationHookResult = ReturnType<
  typeof useGQLDeleteJobCommentMutation
>;
export type GQLDeleteJobCommentMutationResult =
  Apollo.MutationResult<GQLDeleteJobCommentMutation>;
export type GQLDeleteJobCommentMutationOptions = Apollo.BaseMutationOptions<
  GQLDeleteJobCommentMutation,
  GQLDeleteJobCommentMutationVariables
>;
export const GQLGetThreadHistoryDocument = gql`
  query getThreadHistory(
    $threadIdentifier: ItemIdentifierInput!
    $endDate: DateTime
  ) {
    threadHistory(threadIdentifier: $threadIdentifier, endDate: $endDate) {
      latest {
        ... on ContentItem {
          id
          submissionId
          data
          type {
            id
            name
            baseFields {
              name
              type
              required
              container {
                containerType
                keyScalarType
                valueScalarType
              }
            }
            schemaFieldRoles {
              displayName
              parentId
              threadId
              createdAt
              creatorId
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGQLGetThreadHistoryQuery__
 *
 * To run a query within a React component, call `useGQLGetThreadHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetThreadHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetThreadHistoryQuery({
 *   variables: {
 *      threadIdentifier: // value for 'threadIdentifier'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGQLGetThreadHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetThreadHistoryQuery,
    GQLGetThreadHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetThreadHistoryQuery,
    GQLGetThreadHistoryQueryVariables
  >(GQLGetThreadHistoryDocument, options);
}
export function useGQLGetThreadHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetThreadHistoryQuery,
    GQLGetThreadHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetThreadHistoryQuery,
    GQLGetThreadHistoryQueryVariables
  >(GQLGetThreadHistoryDocument, options);
}
export type GQLGetThreadHistoryQueryHookResult = ReturnType<
  typeof useGQLGetThreadHistoryQuery
>;
export type GQLGetThreadHistoryLazyQueryHookResult = ReturnType<
  typeof useGQLGetThreadHistoryLazyQuery
>;
export type GQLGetThreadHistoryQueryResult = Apollo.QueryResult<
  GQLGetThreadHistoryQuery,
  GQLGetThreadHistoryQueryVariables
>;
export const GQLItemTypeHiddenFieldsDocument = gql`
  query ItemTypeHiddenFields {
    myOrg {
      itemTypes {
        ... on ItemTypeBase {
          id
          hiddenFields
        }
      }
    }
  }
`;

/**
 * __useGQLItemTypeHiddenFieldsQuery__
 *
 * To run a query within a React component, call `useGQLItemTypeHiddenFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLItemTypeHiddenFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLItemTypeHiddenFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLItemTypeHiddenFieldsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLItemTypeHiddenFieldsQuery,
    GQLItemTypeHiddenFieldsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLItemTypeHiddenFieldsQuery,
    GQLItemTypeHiddenFieldsQueryVariables
  >(GQLItemTypeHiddenFieldsDocument, options);
}
export function useGQLItemTypeHiddenFieldsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLItemTypeHiddenFieldsQuery,
    GQLItemTypeHiddenFieldsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLItemTypeHiddenFieldsQuery,
    GQLItemTypeHiddenFieldsQueryVariables
  >(GQLItemTypeHiddenFieldsDocument, options);
}
export type GQLItemTypeHiddenFieldsQueryHookResult = ReturnType<
  typeof useGQLItemTypeHiddenFieldsQuery
>;
export type GQLItemTypeHiddenFieldsLazyQueryHookResult = ReturnType<
  typeof useGQLItemTypeHiddenFieldsLazyQuery
>;
export type GQLItemTypeHiddenFieldsQueryResult = Apollo.QueryResult<
  GQLItemTypeHiddenFieldsQuery,
  GQLItemTypeHiddenFieldsQueryVariables
>;
export const GQLAllManualReviewQueuesDocument = gql`
  query AllManualReviewQueues {
    myOrg {
      mrtQueues {
        id
        name
      }
    }
  }
`;

/**
 * __useGQLAllManualReviewQueuesQuery__
 *
 * To run a query within a React component, call `useGQLAllManualReviewQueuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLAllManualReviewQueuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLAllManualReviewQueuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLAllManualReviewQueuesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLAllManualReviewQueuesQuery,
    GQLAllManualReviewQueuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLAllManualReviewQueuesQuery,
    GQLAllManualReviewQueuesQueryVariables
  >(GQLAllManualReviewQueuesDocument, options);
}
export function useGQLAllManualReviewQueuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLAllManualReviewQueuesQuery,
    GQLAllManualReviewQueuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLAllManualReviewQueuesQuery,
    GQLAllManualReviewQueuesQueryVariables
  >(GQLAllManualReviewQueuesDocument, options);
}
export type GQLAllManualReviewQueuesQueryHookResult = ReturnType<
  typeof useGQLAllManualReviewQueuesQuery
>;
export type GQLAllManualReviewQueuesLazyQueryHookResult = ReturnType<
  typeof useGQLAllManualReviewQueuesLazyQuery
>;
export type GQLAllManualReviewQueuesQueryResult = Apollo.QueryResult<
  GQLAllManualReviewQueuesQuery,
  GQLAllManualReviewQueuesQueryVariables
>;
export const GQLGetLatestUserSubmittedItemsWithThreadsDocument = gql`
  query getLatestUserSubmittedItemsWithThreads(
    $userId: ItemIdentifierInput!
    $reportedMessages: [ItemIdentifierInput!]!
  ) {
    ncmecThreads(userId: $userId, reportedMessages: $reportedMessages) {
      threadId
      threadTypeId
      messages {
        message {
          ... on ContentItem {
            id
            submissionId
            data
            type {
              id
              name
              baseFields {
                name
                type
                required
                container {
                  containerType
                  keyScalarType
                  valueScalarType
                }
              }
              schemaFieldRoles {
                displayName
                parentId
                threadId
                createdAt
                creatorId
              }
            }
          }
        }
        ipAddress {
          ip
          port
        }
      }
    }
  }
`;

/**
 * __useGQLGetLatestUserSubmittedItemsWithThreadsQuery__
 *
 * To run a query within a React component, call `useGQLGetLatestUserSubmittedItemsWithThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetLatestUserSubmittedItemsWithThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetLatestUserSubmittedItemsWithThreadsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      reportedMessages: // value for 'reportedMessages'
 *   },
 * });
 */
export function useGQLGetLatestUserSubmittedItemsWithThreadsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetLatestUserSubmittedItemsWithThreadsQuery,
    GQLGetLatestUserSubmittedItemsWithThreadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetLatestUserSubmittedItemsWithThreadsQuery,
    GQLGetLatestUserSubmittedItemsWithThreadsQueryVariables
  >(GQLGetLatestUserSubmittedItemsWithThreadsDocument, options);
}
export function useGQLGetLatestUserSubmittedItemsWithThreadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetLatestUserSubmittedItemsWithThreadsQuery,
    GQLGetLatestUserSubmittedItemsWithThreadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetLatestUserSubmittedItemsWithThreadsQuery,
    GQLGetLatestUserSubmittedItemsWithThreadsQueryVariables
  >(GQLGetLatestUserSubmittedItemsWithThreadsDocument, options);
}
export type GQLGetLatestUserSubmittedItemsWithThreadsQueryHookResult =
  ReturnType<typeof useGQLGetLatestUserSubmittedItemsWithThreadsQuery>;
export type GQLGetLatestUserSubmittedItemsWithThreadsLazyQueryHookResult =
  ReturnType<typeof useGQLGetLatestUserSubmittedItemsWithThreadsLazyQuery>;
export type GQLGetLatestUserSubmittedItemsWithThreadsQueryResult =
  Apollo.QueryResult<
    GQLGetLatestUserSubmittedItemsWithThreadsQuery,
    GQLGetLatestUserSubmittedItemsWithThreadsQueryVariables
  >;
export const GQLGetMoreInfoForThreadItemsDocument = gql`
  query getMoreInfoForThreadItems($ids: [ItemIdentifierInput!]!) {
    partialItems(input: $ids) {
      ... on PartialItemsSuccessResponse {
        items {
          ... on ThreadItem {
            id
            submissionId
            type {
              id
              name
              baseFields {
                name
                type
                required
                container {
                  containerType
                  keyScalarType
                  valueScalarType
                }
              }
            }
            data
          }
        }
      }
      ... on PartialItemsMissingEndpointError {
        title
        status
        type
      }
      ... on PartialItemsEndpointResponseError {
        title
        status
        type
      }
      ... on PartialItemsInvalidResponseError {
        title
        status
        type
      }
    }
  }
`;

/**
 * __useGQLGetMoreInfoForThreadItemsQuery__
 *
 * To run a query within a React component, call `useGQLGetMoreInfoForThreadItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetMoreInfoForThreadItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetMoreInfoForThreadItemsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGQLGetMoreInfoForThreadItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetMoreInfoForThreadItemsQuery,
    GQLGetMoreInfoForThreadItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetMoreInfoForThreadItemsQuery,
    GQLGetMoreInfoForThreadItemsQueryVariables
  >(GQLGetMoreInfoForThreadItemsDocument, options);
}
export function useGQLGetMoreInfoForThreadItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetMoreInfoForThreadItemsQuery,
    GQLGetMoreInfoForThreadItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetMoreInfoForThreadItemsQuery,
    GQLGetMoreInfoForThreadItemsQueryVariables
  >(GQLGetMoreInfoForThreadItemsDocument, options);
}
export type GQLGetMoreInfoForThreadItemsQueryHookResult = ReturnType<
  typeof useGQLGetMoreInfoForThreadItemsQuery
>;
export type GQLGetMoreInfoForThreadItemsLazyQueryHookResult = ReturnType<
  typeof useGQLGetMoreInfoForThreadItemsLazyQuery
>;
export type GQLGetMoreInfoForThreadItemsQueryResult = Apollo.QueryResult<
  GQLGetMoreInfoForThreadItemsQuery,
  GQLGetMoreInfoForThreadItemsQueryVariables
>;
export const GQLGetMoreInfoForPartialItemsDocument = gql`
  query getMoreInfoForPartialItems($ids: [ItemIdentifierInput!]!) {
    partialItems(input: $ids) {
      ... on PartialItemsSuccessResponse {
        items {
          ...ItemFields
        }
      }
      ... on PartialItemsMissingEndpointError {
        title
        status
        type
      }
      ... on PartialItemsEndpointResponseError {
        title
        status
        type
      }
      ... on PartialItemsInvalidResponseError {
        title
        status
        type
      }
    }
  }
  ${GQLItemFieldsFragmentDoc}
`;

/**
 * __useGQLGetMoreInfoForPartialItemsQuery__
 *
 * To run a query within a React component, call `useGQLGetMoreInfoForPartialItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetMoreInfoForPartialItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetMoreInfoForPartialItemsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGQLGetMoreInfoForPartialItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetMoreInfoForPartialItemsQuery,
    GQLGetMoreInfoForPartialItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetMoreInfoForPartialItemsQuery,
    GQLGetMoreInfoForPartialItemsQueryVariables
  >(GQLGetMoreInfoForPartialItemsDocument, options);
}
export function useGQLGetMoreInfoForPartialItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetMoreInfoForPartialItemsQuery,
    GQLGetMoreInfoForPartialItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetMoreInfoForPartialItemsQuery,
    GQLGetMoreInfoForPartialItemsQueryVariables
  >(GQLGetMoreInfoForPartialItemsDocument, options);
}
export type GQLGetMoreInfoForPartialItemsQueryHookResult = ReturnType<
  typeof useGQLGetMoreInfoForPartialItemsQuery
>;
export type GQLGetMoreInfoForPartialItemsLazyQueryHookResult = ReturnType<
  typeof useGQLGetMoreInfoForPartialItemsLazyQuery
>;
export type GQLGetMoreInfoForPartialItemsQueryResult = Apollo.QueryResult<
  GQLGetMoreInfoForPartialItemsQuery,
  GQLGetMoreInfoForPartialItemsQueryVariables
>;
export const GQLGetExistingJobsForItemDocument = gql`
  query getExistingJobsForItem($itemId: ID!, $itemTypeId: ID!) {
    getExistingJobsForItem(itemId: $itemId, itemTypeId: $itemTypeId) {
      queueId
      job {
        createdAt
      }
    }
    myOrg {
      mrtQueues {
        id
        name
      }
    }
  }
`;

/**
 * __useGQLGetExistingJobsForItemQuery__
 *
 * To run a query within a React component, call `useGQLGetExistingJobsForItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetExistingJobsForItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetExistingJobsForItemQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      itemTypeId: // value for 'itemTypeId'
 *   },
 * });
 */
export function useGQLGetExistingJobsForItemQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetExistingJobsForItemQuery,
    GQLGetExistingJobsForItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetExistingJobsForItemQuery,
    GQLGetExistingJobsForItemQueryVariables
  >(GQLGetExistingJobsForItemDocument, options);
}
export function useGQLGetExistingJobsForItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetExistingJobsForItemQuery,
    GQLGetExistingJobsForItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetExistingJobsForItemQuery,
    GQLGetExistingJobsForItemQueryVariables
  >(GQLGetExistingJobsForItemDocument, options);
}
export type GQLGetExistingJobsForItemQueryHookResult = ReturnType<
  typeof useGQLGetExistingJobsForItemQuery
>;
export type GQLGetExistingJobsForItemLazyQueryHookResult = ReturnType<
  typeof useGQLGetExistingJobsForItemLazyQuery
>;
export type GQLGetExistingJobsForItemQueryResult = Apollo.QueryResult<
  GQLGetExistingJobsForItemQuery,
  GQLGetExistingJobsForItemQueryVariables
>;
export const GQLGetLatestUserSubmittedItemsDocument = gql`
  query getLatestUserSubmittedItems(
    $itemIdentifier: ItemIdentifierInput!
    $oldestReturnedSubmissionDate: DateTime
    $earliestReturnedSubmissionDate: DateTime
  ) {
    latestItemsCreatedBy(
      itemIdentifier: $itemIdentifier
      oldestReturnedSubmissionDate: $oldestReturnedSubmissionDate
      earliestReturnedSubmissionDate: $earliestReturnedSubmissionDate
    ) {
      latest {
        ... on ContentItem {
          id
          submissionId
          data
          type {
            id
            name
            baseFields {
              name
              type
              required
              container {
                containerType
                keyScalarType
                valueScalarType
              }
            }
            schemaFieldRoles {
              displayName
              parentId
              threadId
              createdAt
              creatorId
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGQLGetLatestUserSubmittedItemsQuery__
 *
 * To run a query within a React component, call `useGQLGetLatestUserSubmittedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetLatestUserSubmittedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetLatestUserSubmittedItemsQuery({
 *   variables: {
 *      itemIdentifier: // value for 'itemIdentifier'
 *      oldestReturnedSubmissionDate: // value for 'oldestReturnedSubmissionDate'
 *      earliestReturnedSubmissionDate: // value for 'earliestReturnedSubmissionDate'
 *   },
 * });
 */
export function useGQLGetLatestUserSubmittedItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetLatestUserSubmittedItemsQuery,
    GQLGetLatestUserSubmittedItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetLatestUserSubmittedItemsQuery,
    GQLGetLatestUserSubmittedItemsQueryVariables
  >(GQLGetLatestUserSubmittedItemsDocument, options);
}
export function useGQLGetLatestUserSubmittedItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetLatestUserSubmittedItemsQuery,
    GQLGetLatestUserSubmittedItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetLatestUserSubmittedItemsQuery,
    GQLGetLatestUserSubmittedItemsQueryVariables
  >(GQLGetLatestUserSubmittedItemsDocument, options);
}
export type GQLGetLatestUserSubmittedItemsQueryHookResult = ReturnType<
  typeof useGQLGetLatestUserSubmittedItemsQuery
>;
export type GQLGetLatestUserSubmittedItemsLazyQueryHookResult = ReturnType<
  typeof useGQLGetLatestUserSubmittedItemsLazyQuery
>;
export type GQLGetLatestUserSubmittedItemsQueryResult = Apollo.QueryResult<
  GQLGetLatestUserSubmittedItemsQuery,
  GQLGetLatestUserSubmittedItemsQueryVariables
>;
export const GQLOrgDataDocument = gql`
  query OrgData {
    myOrg {
      id
      name
    }
  }
`;

/**
 * __useGQLOrgDataQuery__
 *
 * To run a query within a React component, call `useGQLOrgDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLOrgDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLOrgDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLOrgDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLOrgDataQuery,
    GQLOrgDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLOrgDataQuery, GQLOrgDataQueryVariables>(
    GQLOrgDataDocument,
    options,
  );
}
export function useGQLOrgDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLOrgDataQuery,
    GQLOrgDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQLOrgDataQuery, GQLOrgDataQueryVariables>(
    GQLOrgDataDocument,
    options,
  );
}
export type GQLOrgDataQueryHookResult = ReturnType<typeof useGQLOrgDataQuery>;
export type GQLOrgDataLazyQueryHookResult = ReturnType<
  typeof useGQLOrgDataLazyQuery
>;
export type GQLOrgDataQueryResult = Apollo.QueryResult<
  GQLOrgDataQuery,
  GQLOrgDataQueryVariables
>;
export const GQLGetMoreInfoForItemsDocument = gql`
  query getMoreInfoForItems($ids: [ItemIdentifierInput!]!) {
    partialItems(input: $ids) {
      ... on PartialItemsSuccessResponse {
        items {
          ... on UserItem {
            id
            submissionId
            type {
              baseFields {
                name
                type
                required
                container {
                  containerType
                  keyScalarType
                  valueScalarType
                }
              }
              schemaFieldRoles {
                displayName
                createdAt
                profileIcon
                backgroundImage
              }
              id
            }
            data
            userScore
          }
        }
      }
      ... on PartialItemsMissingEndpointError {
        title
        status
        type
      }
      ... on PartialItemsEndpointResponseError {
        title
        status
        type
      }
      ... on PartialItemsInvalidResponseError {
        title
        status
        type
      }
    }
  }
`;

/**
 * __useGQLGetMoreInfoForItemsQuery__
 *
 * To run a query within a React component, call `useGQLGetMoreInfoForItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetMoreInfoForItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetMoreInfoForItemsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGQLGetMoreInfoForItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetMoreInfoForItemsQuery,
    GQLGetMoreInfoForItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetMoreInfoForItemsQuery,
    GQLGetMoreInfoForItemsQueryVariables
  >(GQLGetMoreInfoForItemsDocument, options);
}
export function useGQLGetMoreInfoForItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetMoreInfoForItemsQuery,
    GQLGetMoreInfoForItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetMoreInfoForItemsQuery,
    GQLGetMoreInfoForItemsQueryVariables
  >(GQLGetMoreInfoForItemsDocument, options);
}
export type GQLGetMoreInfoForItemsQueryHookResult = ReturnType<
  typeof useGQLGetMoreInfoForItemsQuery
>;
export type GQLGetMoreInfoForItemsLazyQueryHookResult = ReturnType<
  typeof useGQLGetMoreInfoForItemsLazyQuery
>;
export type GQLGetMoreInfoForItemsQueryResult = Apollo.QueryResult<
  GQLGetMoreInfoForItemsQuery,
  GQLGetMoreInfoForItemsQueryVariables
>;
export const GQLGetUserItemsDocument = gql`
  query getUserItems($itemIdentifiers: [ItemIdentifierInput!]!) {
    latestItemSubmissions(itemIdentifiers: $itemIdentifiers) {
      ... on UserItem {
        id
        submissionId
        submissionTime
        data
        type {
          id
          name
          baseFields {
            name
            type
            required
            container {
              containerType
              keyScalarType
              valueScalarType
            }
          }
          schemaFieldRoles {
            displayName
            createdAt
            profileIcon
            backgroundImage
          }
        }
      }
    }
  }
`;

/**
 * __useGQLGetUserItemsQuery__
 *
 * To run a query within a React component, call `useGQLGetUserItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetUserItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetUserItemsQuery({
 *   variables: {
 *      itemIdentifiers: // value for 'itemIdentifiers'
 *   },
 * });
 */
export function useGQLGetUserItemsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetUserItemsQuery,
    GQLGetUserItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLGetUserItemsQuery, GQLGetUserItemsQueryVariables>(
    GQLGetUserItemsDocument,
    options,
  );
}
export function useGQLGetUserItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetUserItemsQuery,
    GQLGetUserItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetUserItemsQuery,
    GQLGetUserItemsQueryVariables
  >(GQLGetUserItemsDocument, options);
}
export type GQLGetUserItemsQueryHookResult = ReturnType<
  typeof useGQLGetUserItemsQuery
>;
export type GQLGetUserItemsLazyQueryHookResult = ReturnType<
  typeof useGQLGetUserItemsLazyQuery
>;
export type GQLGetUserItemsQueryResult = Apollo.QueryResult<
  GQLGetUserItemsQuery,
  GQLGetUserItemsQueryVariables
>;
export const GQLManualReviewHasAppealsEnabledDocument = gql`
  query ManualReviewHasAppealsEnabled {
    myOrg {
      hasAppealsEnabled
    }
  }
`;

/**
 * __useGQLManualReviewHasAppealsEnabledQuery__
 *
 * To run a query within a React component, call `useGQLManualReviewHasAppealsEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLManualReviewHasAppealsEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLManualReviewHasAppealsEnabledQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLManualReviewHasAppealsEnabledQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLManualReviewHasAppealsEnabledQuery,
    GQLManualReviewHasAppealsEnabledQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLManualReviewHasAppealsEnabledQuery,
    GQLManualReviewHasAppealsEnabledQueryVariables
  >(GQLManualReviewHasAppealsEnabledDocument, options);
}
export function useGQLManualReviewHasAppealsEnabledLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLManualReviewHasAppealsEnabledQuery,
    GQLManualReviewHasAppealsEnabledQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLManualReviewHasAppealsEnabledQuery,
    GQLManualReviewHasAppealsEnabledQueryVariables
  >(GQLManualReviewHasAppealsEnabledDocument, options);
}
export type GQLManualReviewHasAppealsEnabledQueryHookResult = ReturnType<
  typeof useGQLManualReviewHasAppealsEnabledQuery
>;
export type GQLManualReviewHasAppealsEnabledLazyQueryHookResult = ReturnType<
  typeof useGQLManualReviewHasAppealsEnabledLazyQuery
>;
export type GQLManualReviewHasAppealsEnabledQueryResult = Apollo.QueryResult<
  GQLManualReviewHasAppealsEnabledQuery,
  GQLManualReviewHasAppealsEnabledQueryVariables
>;
export const GQLDeleteRoutingRuleDocument = gql`
  mutation DeleteRoutingRule($input: DeleteRoutingRuleInput!) {
    deleteRoutingRule(input: $input)
  }
`;
export type GQLDeleteRoutingRuleMutationFn = Apollo.MutationFunction<
  GQLDeleteRoutingRuleMutation,
  GQLDeleteRoutingRuleMutationVariables
>;

/**
 * __useGQLDeleteRoutingRuleMutation__
 *
 * To run a mutation, you first call `useGQLDeleteRoutingRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLDeleteRoutingRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlDeleteRoutingRuleMutation, { data, loading, error }] = useGQLDeleteRoutingRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLDeleteRoutingRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLDeleteRoutingRuleMutation,
    GQLDeleteRoutingRuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLDeleteRoutingRuleMutation,
    GQLDeleteRoutingRuleMutationVariables
  >(GQLDeleteRoutingRuleDocument, options);
}
export type GQLDeleteRoutingRuleMutationHookResult = ReturnType<
  typeof useGQLDeleteRoutingRuleMutation
>;
export type GQLDeleteRoutingRuleMutationResult =
  Apollo.MutationResult<GQLDeleteRoutingRuleMutation>;
export type GQLDeleteRoutingRuleMutationOptions = Apollo.BaseMutationOptions<
  GQLDeleteRoutingRuleMutation,
  GQLDeleteRoutingRuleMutationVariables
>;
export const GQLManualReviewQueueRoutingRulesDocument = gql`
  query ManualReviewQueueRoutingRules {
    myOrg {
      itemTypes {
        ...ItemTypeFragment
        ... on ItemTypeBase {
          derivedFields {
            ...DerivedFieldFields
          }
        }
      }
      routingRules {
        id
        name
        description
        itemTypes {
          ...ItemTypeFragment
        }
        conditionSet {
          ...ConditionSetFields
        }
        destinationQueue {
          id
          name
        }
      }
      appealsRoutingRules {
        id
        name
        description
        itemTypes {
          ...ItemTypeFragment
        }
        conditionSet {
          ...ConditionSetFields
        }
        destinationQueue {
          id
          name
        }
      }
      signals {
        ...SignalsFragment
      }
    }
    me {
      id
      reviewableQueues {
        id
        name
        isAppealsQueue
      }
    }
  }
  ${GQLItemTypeFragmentFragmentDoc}
  ${GQLDerivedFieldFieldsFragmentDoc}
  ${GQLConditionSetFieldsFragmentDoc}
  ${GQLSignalsFragmentFragmentDoc}
`;

/**
 * __useGQLManualReviewQueueRoutingRulesQuery__
 *
 * To run a query within a React component, call `useGQLManualReviewQueueRoutingRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLManualReviewQueueRoutingRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLManualReviewQueueRoutingRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLManualReviewQueueRoutingRulesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLManualReviewQueueRoutingRulesQuery,
    GQLManualReviewQueueRoutingRulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLManualReviewQueueRoutingRulesQuery,
    GQLManualReviewQueueRoutingRulesQueryVariables
  >(GQLManualReviewQueueRoutingRulesDocument, options);
}
export function useGQLManualReviewQueueRoutingRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLManualReviewQueueRoutingRulesQuery,
    GQLManualReviewQueueRoutingRulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLManualReviewQueueRoutingRulesQuery,
    GQLManualReviewQueueRoutingRulesQueryVariables
  >(GQLManualReviewQueueRoutingRulesDocument, options);
}
export type GQLManualReviewQueueRoutingRulesQueryHookResult = ReturnType<
  typeof useGQLManualReviewQueueRoutingRulesQuery
>;
export type GQLManualReviewQueueRoutingRulesLazyQueryHookResult = ReturnType<
  typeof useGQLManualReviewQueueRoutingRulesLazyQuery
>;
export type GQLManualReviewQueueRoutingRulesQueryResult = Apollo.QueryResult<
  GQLManualReviewQueueRoutingRulesQuery,
  GQLManualReviewQueueRoutingRulesQueryVariables
>;
export const GQLCreateRoutingRuleDocument = gql`
  mutation CreateRoutingRule($input: CreateRoutingRuleInput!) {
    createRoutingRule(input: $input) {
      ... on MutateRoutingRuleSuccessResponse {
        data {
          id
        }
      }
      ... on Error {
        title
      }
    }
  }
`;
export type GQLCreateRoutingRuleMutationFn = Apollo.MutationFunction<
  GQLCreateRoutingRuleMutation,
  GQLCreateRoutingRuleMutationVariables
>;

/**
 * __useGQLCreateRoutingRuleMutation__
 *
 * To run a mutation, you first call `useGQLCreateRoutingRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLCreateRoutingRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCreateRoutingRuleMutation, { data, loading, error }] = useGQLCreateRoutingRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLCreateRoutingRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLCreateRoutingRuleMutation,
    GQLCreateRoutingRuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLCreateRoutingRuleMutation,
    GQLCreateRoutingRuleMutationVariables
  >(GQLCreateRoutingRuleDocument, options);
}
export type GQLCreateRoutingRuleMutationHookResult = ReturnType<
  typeof useGQLCreateRoutingRuleMutation
>;
export type GQLCreateRoutingRuleMutationResult =
  Apollo.MutationResult<GQLCreateRoutingRuleMutation>;
export type GQLCreateRoutingRuleMutationOptions = Apollo.BaseMutationOptions<
  GQLCreateRoutingRuleMutation,
  GQLCreateRoutingRuleMutationVariables
>;
export const GQLUpdateRoutingRuleDocument = gql`
  mutation UpdateRoutingRule($input: UpdateRoutingRuleInput!) {
    updateRoutingRule(input: $input) {
      ... on MutateRoutingRuleSuccessResponse {
        data {
          id
        }
      }
      ... on Error {
        title
      }
    }
  }
`;
export type GQLUpdateRoutingRuleMutationFn = Apollo.MutationFunction<
  GQLUpdateRoutingRuleMutation,
  GQLUpdateRoutingRuleMutationVariables
>;

/**
 * __useGQLUpdateRoutingRuleMutation__
 *
 * To run a mutation, you first call `useGQLUpdateRoutingRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLUpdateRoutingRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlUpdateRoutingRuleMutation, { data, loading, error }] = useGQLUpdateRoutingRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLUpdateRoutingRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLUpdateRoutingRuleMutation,
    GQLUpdateRoutingRuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLUpdateRoutingRuleMutation,
    GQLUpdateRoutingRuleMutationVariables
  >(GQLUpdateRoutingRuleDocument, options);
}
export type GQLUpdateRoutingRuleMutationHookResult = ReturnType<
  typeof useGQLUpdateRoutingRuleMutation
>;
export type GQLUpdateRoutingRuleMutationResult =
  Apollo.MutationResult<GQLUpdateRoutingRuleMutation>;
export type GQLUpdateRoutingRuleMutationOptions = Apollo.BaseMutationOptions<
  GQLUpdateRoutingRuleMutation,
  GQLUpdateRoutingRuleMutationVariables
>;
export const GQLReorderRoutingRulesDocument = gql`
  mutation ReorderRoutingRules($input: ReorderRoutingRulesInput!) {
    reorderRoutingRules(input: $input) {
      ... on MutateRoutingRulesOrderSuccessResponse {
        data {
          id
        }
      }
    }
  }
`;
export type GQLReorderRoutingRulesMutationFn = Apollo.MutationFunction<
  GQLReorderRoutingRulesMutation,
  GQLReorderRoutingRulesMutationVariables
>;

/**
 * __useGQLReorderRoutingRulesMutation__
 *
 * To run a mutation, you first call `useGQLReorderRoutingRulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLReorderRoutingRulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlReorderRoutingRulesMutation, { data, loading, error }] = useGQLReorderRoutingRulesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLReorderRoutingRulesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLReorderRoutingRulesMutation,
    GQLReorderRoutingRulesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLReorderRoutingRulesMutation,
    GQLReorderRoutingRulesMutationVariables
  >(GQLReorderRoutingRulesDocument, options);
}
export type GQLReorderRoutingRulesMutationHookResult = ReturnType<
  typeof useGQLReorderRoutingRulesMutation
>;
export type GQLReorderRoutingRulesMutationResult =
  Apollo.MutationResult<GQLReorderRoutingRulesMutation>;
export type GQLReorderRoutingRulesMutationOptions = Apollo.BaseMutationOptions<
  GQLReorderRoutingRulesMutation,
  GQLReorderRoutingRulesMutationVariables
>;
export const GQLManualReviewChartConfigurationSettingsDocument = gql`
  query ManualReviewChartConfigurationSettings {
    me {
      interfacePreferences {
        mrtChartConfigurations {
          ... on GetDecisionCountSettings {
            title
            metric
            decisionGroupBy: groupBy
            filterBy {
              startDate
              endDate
              type
              actionIds
              policyIds
              queueIds
              reviewerIds
              itemTypeIds
            }
            timeDivision
          }
          ... on GetJobCreationCountSettings {
            title
            metric
            jobCreationGroupBy: groupBy
            filterBy {
              startDate
              endDate
              policyIds
              queueIds
              itemTypeIds
              ruleIds
              sources
            }
            timeDivision
          }
        }
      }
    }
  }
`;

/**
 * __useGQLManualReviewChartConfigurationSettingsQuery__
 *
 * To run a query within a React component, call `useGQLManualReviewChartConfigurationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLManualReviewChartConfigurationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLManualReviewChartConfigurationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLManualReviewChartConfigurationSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLManualReviewChartConfigurationSettingsQuery,
    GQLManualReviewChartConfigurationSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLManualReviewChartConfigurationSettingsQuery,
    GQLManualReviewChartConfigurationSettingsQueryVariables
  >(GQLManualReviewChartConfigurationSettingsDocument, options);
}
export function useGQLManualReviewChartConfigurationSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLManualReviewChartConfigurationSettingsQuery,
    GQLManualReviewChartConfigurationSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLManualReviewChartConfigurationSettingsQuery,
    GQLManualReviewChartConfigurationSettingsQueryVariables
  >(GQLManualReviewChartConfigurationSettingsDocument, options);
}
export type GQLManualReviewChartConfigurationSettingsQueryHookResult =
  ReturnType<typeof useGQLManualReviewChartConfigurationSettingsQuery>;
export type GQLManualReviewChartConfigurationSettingsLazyQueryHookResult =
  ReturnType<typeof useGQLManualReviewChartConfigurationSettingsLazyQuery>;
export type GQLManualReviewChartConfigurationSettingsQueryResult =
  Apollo.QueryResult<
    GQLManualReviewChartConfigurationSettingsQuery,
    GQLManualReviewChartConfigurationSettingsQueryVariables
  >;
export const GQLSetMrtChartConfigurationSettingsDocument = gql`
  mutation SetMrtChartConfigurationSettings(
    $mrtChartConfigurationSettings: ManualReviewChartConfigurationsInput!
  ) {
    setMrtChartConfigurationSettings(
      mrtChartConfigurationSettings: $mrtChartConfigurationSettings
    ) {
      _
    }
  }
`;
export type GQLSetMrtChartConfigurationSettingsMutationFn =
  Apollo.MutationFunction<
    GQLSetMrtChartConfigurationSettingsMutation,
    GQLSetMrtChartConfigurationSettingsMutationVariables
  >;

/**
 * __useGQLSetMrtChartConfigurationSettingsMutation__
 *
 * To run a mutation, you first call `useGQLSetMrtChartConfigurationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLSetMrtChartConfigurationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlSetMrtChartConfigurationSettingsMutation, { data, loading, error }] = useGQLSetMrtChartConfigurationSettingsMutation({
 *   variables: {
 *      mrtChartConfigurationSettings: // value for 'mrtChartConfigurationSettings'
 *   },
 * });
 */
export function useGQLSetMrtChartConfigurationSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLSetMrtChartConfigurationSettingsMutation,
    GQLSetMrtChartConfigurationSettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLSetMrtChartConfigurationSettingsMutation,
    GQLSetMrtChartConfigurationSettingsMutationVariables
  >(GQLSetMrtChartConfigurationSettingsDocument, options);
}
export type GQLSetMrtChartConfigurationSettingsMutationHookResult = ReturnType<
  typeof useGQLSetMrtChartConfigurationSettingsMutation
>;
export type GQLSetMrtChartConfigurationSettingsMutationResult =
  Apollo.MutationResult<GQLSetMrtChartConfigurationSettingsMutation>;
export type GQLSetMrtChartConfigurationSettingsMutationOptions =
  Apollo.BaseMutationOptions<
    GQLSetMrtChartConfigurationSettingsMutation,
    GQLSetMrtChartConfigurationSettingsMutationVariables
  >;
export const GQLManualReviewDecisionInsightsOrgInfoDocument = gql`
  query ManualReviewDecisionInsightsOrgInfo {
    myOrg {
      id
      actions {
        ... on ActionBase {
          id
          name
        }
      }
      users {
        id
        firstName
        lastName
      }
      policies {
        id
        name
      }
      mrtQueues {
        id
        name
      }
      itemTypes {
        ... on ItemTypeBase {
          id
          name
        }
      }
      rules {
        ... on ContentRule {
          id
          name
        }
        ... on UserRule {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGQLManualReviewDecisionInsightsOrgInfoQuery__
 *
 * To run a query within a React component, call `useGQLManualReviewDecisionInsightsOrgInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLManualReviewDecisionInsightsOrgInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLManualReviewDecisionInsightsOrgInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLManualReviewDecisionInsightsOrgInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLManualReviewDecisionInsightsOrgInfoQuery,
    GQLManualReviewDecisionInsightsOrgInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLManualReviewDecisionInsightsOrgInfoQuery,
    GQLManualReviewDecisionInsightsOrgInfoQueryVariables
  >(GQLManualReviewDecisionInsightsOrgInfoDocument, options);
}
export function useGQLManualReviewDecisionInsightsOrgInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLManualReviewDecisionInsightsOrgInfoQuery,
    GQLManualReviewDecisionInsightsOrgInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLManualReviewDecisionInsightsOrgInfoQuery,
    GQLManualReviewDecisionInsightsOrgInfoQueryVariables
  >(GQLManualReviewDecisionInsightsOrgInfoDocument, options);
}
export type GQLManualReviewDecisionInsightsOrgInfoQueryHookResult = ReturnType<
  typeof useGQLManualReviewDecisionInsightsOrgInfoQuery
>;
export type GQLManualReviewDecisionInsightsOrgInfoLazyQueryHookResult =
  ReturnType<typeof useGQLManualReviewDecisionInsightsOrgInfoLazyQuery>;
export type GQLManualReviewDecisionInsightsOrgInfoQueryResult =
  Apollo.QueryResult<
    GQLManualReviewDecisionInsightsOrgInfoQuery,
    GQLManualReviewDecisionInsightsOrgInfoQueryVariables
  >;
export const GQLGetDecisionCountsDocument = gql`
  query getDecisionCounts($input: GetDecisionCountInput!) {
    getDecisionCounts(input: $input) {
      count
      time
      type
      action_id
      policy_id
      queue_id
      reviewer_id
    }
  }
`;

/**
 * __useGQLGetDecisionCountsQuery__
 *
 * To run a query within a React component, call `useGQLGetDecisionCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetDecisionCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetDecisionCountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLGetDecisionCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetDecisionCountsQuery,
    GQLGetDecisionCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetDecisionCountsQuery,
    GQLGetDecisionCountsQueryVariables
  >(GQLGetDecisionCountsDocument, options);
}
export function useGQLGetDecisionCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetDecisionCountsQuery,
    GQLGetDecisionCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetDecisionCountsQuery,
    GQLGetDecisionCountsQueryVariables
  >(GQLGetDecisionCountsDocument, options);
}
export type GQLGetDecisionCountsQueryHookResult = ReturnType<
  typeof useGQLGetDecisionCountsQuery
>;
export type GQLGetDecisionCountsLazyQueryHookResult = ReturnType<
  typeof useGQLGetDecisionCountsLazyQuery
>;
export type GQLGetDecisionCountsQueryResult = Apollo.QueryResult<
  GQLGetDecisionCountsQuery,
  GQLGetDecisionCountsQueryVariables
>;
export const GQLGetJobCreationCountsDocument = gql`
  query getJobCreationCounts($input: GetJobCreationCountInput!) {
    getJobCreationCounts(input: $input) {
      count
      time
      policyId
      queueId
      itemTypeId
      ruleId
      source
    }
  }
`;

/**
 * __useGQLGetJobCreationCountsQuery__
 *
 * To run a query within a React component, call `useGQLGetJobCreationCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetJobCreationCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetJobCreationCountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLGetJobCreationCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetJobCreationCountsQuery,
    GQLGetJobCreationCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetJobCreationCountsQuery,
    GQLGetJobCreationCountsQueryVariables
  >(GQLGetJobCreationCountsDocument, options);
}
export function useGQLGetJobCreationCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetJobCreationCountsQuery,
    GQLGetJobCreationCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetJobCreationCountsQuery,
    GQLGetJobCreationCountsQueryVariables
  >(GQLGetJobCreationCountsDocument, options);
}
export type GQLGetJobCreationCountsQueryHookResult = ReturnType<
  typeof useGQLGetJobCreationCountsQuery
>;
export type GQLGetJobCreationCountsLazyQueryHookResult = ReturnType<
  typeof useGQLGetJobCreationCountsLazyQuery
>;
export type GQLGetJobCreationCountsQueryResult = Apollo.QueryResult<
  GQLGetJobCreationCountsQuery,
  GQLGetJobCreationCountsQueryVariables
>;
export const GQLGetResolvedJobCountsDocument = gql`
  query getResolvedJobCounts($input: GetResolvedJobCountInput!) {
    getResolvedJobCounts(input: $input) {
      count
      time
      reviewerId
      queueId
    }
  }
`;

/**
 * __useGQLGetResolvedJobCountsQuery__
 *
 * To run a query within a React component, call `useGQLGetResolvedJobCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetResolvedJobCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetResolvedJobCountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLGetResolvedJobCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetResolvedJobCountsQuery,
    GQLGetResolvedJobCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetResolvedJobCountsQuery,
    GQLGetResolvedJobCountsQueryVariables
  >(GQLGetResolvedJobCountsDocument, options);
}
export function useGQLGetResolvedJobCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetResolvedJobCountsQuery,
    GQLGetResolvedJobCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetResolvedJobCountsQuery,
    GQLGetResolvedJobCountsQueryVariables
  >(GQLGetResolvedJobCountsDocument, options);
}
export type GQLGetResolvedJobCountsQueryHookResult = ReturnType<
  typeof useGQLGetResolvedJobCountsQuery
>;
export type GQLGetResolvedJobCountsLazyQueryHookResult = ReturnType<
  typeof useGQLGetResolvedJobCountsLazyQuery
>;
export type GQLGetResolvedJobCountsQueryResult = Apollo.QueryResult<
  GQLGetResolvedJobCountsQuery,
  GQLGetResolvedJobCountsQueryVariables
>;
export const GQLGetSkippedJobCountsDocument = gql`
  query getSkippedJobCounts($input: GetSkippedJobCountInput!) {
    getSkippedJobCounts(input: $input) {
      count
      time
      reviewerId
      queueId
    }
  }
`;

/**
 * __useGQLGetSkippedJobCountsQuery__
 *
 * To run a query within a React component, call `useGQLGetSkippedJobCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetSkippedJobCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetSkippedJobCountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLGetSkippedJobCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetSkippedJobCountsQuery,
    GQLGetSkippedJobCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetSkippedJobCountsQuery,
    GQLGetSkippedJobCountsQueryVariables
  >(GQLGetSkippedJobCountsDocument, options);
}
export function useGQLGetSkippedJobCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetSkippedJobCountsQuery,
    GQLGetSkippedJobCountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetSkippedJobCountsQuery,
    GQLGetSkippedJobCountsQueryVariables
  >(GQLGetSkippedJobCountsDocument, options);
}
export type GQLGetSkippedJobCountsQueryHookResult = ReturnType<
  typeof useGQLGetSkippedJobCountsQuery
>;
export type GQLGetSkippedJobCountsLazyQueryHookResult = ReturnType<
  typeof useGQLGetSkippedJobCountsLazyQuery
>;
export type GQLGetSkippedJobCountsQueryResult = Apollo.QueryResult<
  GQLGetSkippedJobCountsQuery,
  GQLGetSkippedJobCountsQueryVariables
>;
export const GQLManualReviewDecisionInsightsFilterByInfoDocument = gql`
  query ManualReviewDecisionInsightsFilterByInfo {
    myOrg {
      actions {
        ... on ActionBase {
          id
          name
        }
      }
      itemTypes {
        ... on ItemTypeBase {
          id
          name
        }
      }
      users {
        id
        firstName
        lastName
      }
      policies {
        id
        name
      }
      mrtQueues {
        id
        name
      }
      rules {
        ... on Rule {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGQLManualReviewDecisionInsightsFilterByInfoQuery__
 *
 * To run a query within a React component, call `useGQLManualReviewDecisionInsightsFilterByInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLManualReviewDecisionInsightsFilterByInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLManualReviewDecisionInsightsFilterByInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLManualReviewDecisionInsightsFilterByInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLManualReviewDecisionInsightsFilterByInfoQuery,
    GQLManualReviewDecisionInsightsFilterByInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLManualReviewDecisionInsightsFilterByInfoQuery,
    GQLManualReviewDecisionInsightsFilterByInfoQueryVariables
  >(GQLManualReviewDecisionInsightsFilterByInfoDocument, options);
}
export function useGQLManualReviewDecisionInsightsFilterByInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLManualReviewDecisionInsightsFilterByInfoQuery,
    GQLManualReviewDecisionInsightsFilterByInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLManualReviewDecisionInsightsFilterByInfoQuery,
    GQLManualReviewDecisionInsightsFilterByInfoQueryVariables
  >(GQLManualReviewDecisionInsightsFilterByInfoDocument, options);
}
export type GQLManualReviewDecisionInsightsFilterByInfoQueryHookResult =
  ReturnType<typeof useGQLManualReviewDecisionInsightsFilterByInfoQuery>;
export type GQLManualReviewDecisionInsightsFilterByInfoLazyQueryHookResult =
  ReturnType<typeof useGQLManualReviewDecisionInsightsFilterByInfoLazyQuery>;
export type GQLManualReviewDecisionInsightsFilterByInfoQueryResult =
  Apollo.QueryResult<
    GQLManualReviewDecisionInsightsFilterByInfoQuery,
    GQLManualReviewDecisionInsightsFilterByInfoQueryVariables
  >;
export const GQLAllNcmecReportsDocument = gql`
  query AllNCMECReports {
    myOrg {
      hasNCMECReportingEnabled
      ncmecReports {
        ...NCMECReportValues
      }
      users {
        id
        firstName
        lastName
      }
    }
  }
  ${GQLNcmecReportValuesFragmentDoc}
`;

/**
 * __useGQLAllNcmecReportsQuery__
 *
 * To run a query within a React component, call `useGQLAllNcmecReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLAllNcmecReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLAllNcmecReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLAllNcmecReportsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLAllNcmecReportsQuery,
    GQLAllNcmecReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLAllNcmecReportsQuery,
    GQLAllNcmecReportsQueryVariables
  >(GQLAllNcmecReportsDocument, options);
}
export function useGQLAllNcmecReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLAllNcmecReportsQuery,
    GQLAllNcmecReportsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLAllNcmecReportsQuery,
    GQLAllNcmecReportsQueryVariables
  >(GQLAllNcmecReportsDocument, options);
}
export type GQLAllNcmecReportsQueryHookResult = ReturnType<
  typeof useGQLAllNcmecReportsQuery
>;
export type GQLAllNcmecReportsLazyQueryHookResult = ReturnType<
  typeof useGQLAllNcmecReportsLazyQuery
>;
export type GQLAllNcmecReportsQueryResult = Apollo.QueryResult<
  GQLAllNcmecReportsQuery,
  GQLAllNcmecReportsQueryVariables
>;
export const GQLPermissionsDocument = gql`
  query Permissions {
    me {
      permissions
    }
  }
`;

/**
 * __useGQLPermissionsQuery__
 *
 * To run a query within a React component, call `useGQLPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLPermissionsQuery,
    GQLPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLPermissionsQuery, GQLPermissionsQueryVariables>(
    GQLPermissionsDocument,
    options,
  );
}
export function useGQLPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLPermissionsQuery,
    GQLPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQLPermissionsQuery, GQLPermissionsQueryVariables>(
    GQLPermissionsDocument,
    options,
  );
}
export type GQLPermissionsQueryHookResult = ReturnType<
  typeof useGQLPermissionsQuery
>;
export type GQLPermissionsLazyQueryHookResult = ReturnType<
  typeof useGQLPermissionsLazyQuery
>;
export type GQLPermissionsQueryResult = Apollo.QueryResult<
  GQLPermissionsQuery,
  GQLPermissionsQueryVariables
>;
export const GQLGetNcmecReportDocument = gql`
  query GetNCMECReport($reportId: ID!) {
    ncmecReportById(reportId: $reportId) {
      ...NCMECReportValues
    }
  }
  ${GQLNcmecReportValuesFragmentDoc}
`;

/**
 * __useGQLGetNcmecReportQuery__
 *
 * To run a query within a React component, call `useGQLGetNcmecReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetNcmecReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetNcmecReportQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useGQLGetNcmecReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetNcmecReportQuery,
    GQLGetNcmecReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetNcmecReportQuery,
    GQLGetNcmecReportQueryVariables
  >(GQLGetNcmecReportDocument, options);
}
export function useGQLGetNcmecReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetNcmecReportQuery,
    GQLGetNcmecReportQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetNcmecReportQuery,
    GQLGetNcmecReportQueryVariables
  >(GQLGetNcmecReportDocument, options);
}
export type GQLGetNcmecReportQueryHookResult = ReturnType<
  typeof useGQLGetNcmecReportQuery
>;
export type GQLGetNcmecReportLazyQueryHookResult = ReturnType<
  typeof useGQLGetNcmecReportLazyQuery
>;
export type GQLGetNcmecReportQueryResult = Apollo.QueryResult<
  GQLGetNcmecReportQuery,
  GQLGetNcmecReportQueryVariables
>;
export const GQLTotalPendingJobsDocument = gql`
  query TotalPendingJobs {
    getTotalPendingJobsCount
  }
`;

/**
 * __useGQLTotalPendingJobsQuery__
 *
 * To run a query within a React component, call `useGQLTotalPendingJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLTotalPendingJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLTotalPendingJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLTotalPendingJobsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLTotalPendingJobsQuery,
    GQLTotalPendingJobsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLTotalPendingJobsQuery,
    GQLTotalPendingJobsQueryVariables
  >(GQLTotalPendingJobsDocument, options);
}
export function useGQLTotalPendingJobsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLTotalPendingJobsQuery,
    GQLTotalPendingJobsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLTotalPendingJobsQuery,
    GQLTotalPendingJobsQueryVariables
  >(GQLTotalPendingJobsDocument, options);
}
export type GQLTotalPendingJobsQueryHookResult = ReturnType<
  typeof useGQLTotalPendingJobsQuery
>;
export type GQLTotalPendingJobsLazyQueryHookResult = ReturnType<
  typeof useGQLTotalPendingJobsLazyQuery
>;
export type GQLTotalPendingJobsQueryResult = Apollo.QueryResult<
  GQLTotalPendingJobsQuery,
  GQLTotalPendingJobsQueryVariables
>;
export const GQLRuleNamesAndIdsDocument = gql`
  query RuleNamesAndIds {
    myOrg {
      rules {
        id
        name
      }
    }
  }
`;

/**
 * __useGQLRuleNamesAndIdsQuery__
 *
 * To run a query within a React component, call `useGQLRuleNamesAndIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLRuleNamesAndIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLRuleNamesAndIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLRuleNamesAndIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLRuleNamesAndIdsQuery,
    GQLRuleNamesAndIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLRuleNamesAndIdsQuery,
    GQLRuleNamesAndIdsQueryVariables
  >(GQLRuleNamesAndIdsDocument, options);
}
export function useGQLRuleNamesAndIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLRuleNamesAndIdsQuery,
    GQLRuleNamesAndIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLRuleNamesAndIdsQuery,
    GQLRuleNamesAndIdsQueryVariables
  >(GQLRuleNamesAndIdsDocument, options);
}
export type GQLRuleNamesAndIdsQueryHookResult = ReturnType<
  typeof useGQLRuleNamesAndIdsQuery
>;
export type GQLRuleNamesAndIdsLazyQueryHookResult = ReturnType<
  typeof useGQLRuleNamesAndIdsLazyQuery
>;
export type GQLRuleNamesAndIdsQueryResult = Apollo.QueryResult<
  GQLRuleNamesAndIdsQuery,
  GQLRuleNamesAndIdsQueryVariables
>;
export const GQLDataForOverviewChartsDocument = gql`
  query DataForOverviewCharts {
    myOrg {
      users {
        id
        firstName
        lastName
      }
      rules {
        id
        name
      }
    }
  }
`;

/**
 * __useGQLDataForOverviewChartsQuery__
 *
 * To run a query within a React component, call `useGQLDataForOverviewChartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLDataForOverviewChartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLDataForOverviewChartsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLDataForOverviewChartsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLDataForOverviewChartsQuery,
    GQLDataForOverviewChartsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLDataForOverviewChartsQuery,
    GQLDataForOverviewChartsQueryVariables
  >(GQLDataForOverviewChartsDocument, options);
}
export function useGQLDataForOverviewChartsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLDataForOverviewChartsQuery,
    GQLDataForOverviewChartsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLDataForOverviewChartsQuery,
    GQLDataForOverviewChartsQueryVariables
  >(GQLDataForOverviewChartsDocument, options);
}
export type GQLDataForOverviewChartsQueryHookResult = ReturnType<
  typeof useGQLDataForOverviewChartsQuery
>;
export type GQLDataForOverviewChartsLazyQueryHookResult = ReturnType<
  typeof useGQLDataForOverviewChartsLazyQuery
>;
export type GQLDataForOverviewChartsQueryResult = Apollo.QueryResult<
  GQLDataForOverviewChartsQuery,
  GQLDataForOverviewChartsQueryVariables
>;
export const GQLViolationsPerPolicyDocument = gql`
  query ViolationsPerPolicy($input: TopPolicyViolationsInput!) {
    topPolicyViolations(input: $input) {
      count
      policyId
    }
  }
`;

/**
 * __useGQLViolationsPerPolicyQuery__
 *
 * To run a query within a React component, call `useGQLViolationsPerPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLViolationsPerPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLViolationsPerPolicyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLViolationsPerPolicyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLViolationsPerPolicyQuery,
    GQLViolationsPerPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLViolationsPerPolicyQuery,
    GQLViolationsPerPolicyQueryVariables
  >(GQLViolationsPerPolicyDocument, options);
}
export function useGQLViolationsPerPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLViolationsPerPolicyQuery,
    GQLViolationsPerPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLViolationsPerPolicyQuery,
    GQLViolationsPerPolicyQueryVariables
  >(GQLViolationsPerPolicyDocument, options);
}
export type GQLViolationsPerPolicyQueryHookResult = ReturnType<
  typeof useGQLViolationsPerPolicyQuery
>;
export type GQLViolationsPerPolicyLazyQueryHookResult = ReturnType<
  typeof useGQLViolationsPerPolicyLazyQuery
>;
export type GQLViolationsPerPolicyQueryResult = Apollo.QueryResult<
  GQLViolationsPerPolicyQuery,
  GQLViolationsPerPolicyQueryVariables
>;
export const GQLPoliciesDocument = gql`
  query Policies {
    myOrg {
      policies {
        ...PolicyFields
      }
    }
  }
  ${GQLPolicyFieldsFragmentDoc}
`;

/**
 * __useGQLPoliciesQuery__
 *
 * To run a query within a React component, call `useGQLPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLPoliciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLPoliciesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLPoliciesQuery,
    GQLPoliciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLPoliciesQuery, GQLPoliciesQueryVariables>(
    GQLPoliciesDocument,
    options,
  );
}
export function useGQLPoliciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLPoliciesQuery,
    GQLPoliciesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQLPoliciesQuery, GQLPoliciesQueryVariables>(
    GQLPoliciesDocument,
    options,
  );
}
export type GQLPoliciesQueryHookResult = ReturnType<typeof useGQLPoliciesQuery>;
export type GQLPoliciesLazyQueryHookResult = ReturnType<
  typeof useGQLPoliciesLazyQuery
>;
export type GQLPoliciesQueryResult = Apollo.QueryResult<
  GQLPoliciesQuery,
  GQLPoliciesQueryVariables
>;
export const GQLPoliciesWithModelsDocument = gql`
  query PoliciesWithModels {
    myOrg {
      policies {
        ...PolicyFields
      }
      models {
        id
        status
        version
        alias
        policy {
          id
        }
      }
    }
    me {
      permissions
    }
  }
  ${GQLPolicyFieldsFragmentDoc}
`;

/**
 * __useGQLPoliciesWithModelsQuery__
 *
 * To run a query within a React component, call `useGQLPoliciesWithModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLPoliciesWithModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLPoliciesWithModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLPoliciesWithModelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLPoliciesWithModelsQuery,
    GQLPoliciesWithModelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLPoliciesWithModelsQuery,
    GQLPoliciesWithModelsQueryVariables
  >(GQLPoliciesWithModelsDocument, options);
}
export function useGQLPoliciesWithModelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLPoliciesWithModelsQuery,
    GQLPoliciesWithModelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLPoliciesWithModelsQuery,
    GQLPoliciesWithModelsQueryVariables
  >(GQLPoliciesWithModelsDocument, options);
}
export type GQLPoliciesWithModelsQueryHookResult = ReturnType<
  typeof useGQLPoliciesWithModelsQuery
>;
export type GQLPoliciesWithModelsLazyQueryHookResult = ReturnType<
  typeof useGQLPoliciesWithModelsLazyQuery
>;
export type GQLPoliciesWithModelsQueryResult = Apollo.QueryResult<
  GQLPoliciesWithModelsQuery,
  GQLPoliciesWithModelsQueryVariables
>;
export const GQLAddPoliciesDocument = gql`
  mutation AddPolicies($policies: [AddPolicyInput!]!) {
    addPolicies(policies: $policies) {
      policies {
        ...PolicyFields
      }
      failures
    }
  }
  ${GQLPolicyFieldsFragmentDoc}
`;
export type GQLAddPoliciesMutationFn = Apollo.MutationFunction<
  GQLAddPoliciesMutation,
  GQLAddPoliciesMutationVariables
>;

/**
 * __useGQLAddPoliciesMutation__
 *
 * To run a mutation, you first call `useGQLAddPoliciesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLAddPoliciesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlAddPoliciesMutation, { data, loading, error }] = useGQLAddPoliciesMutation({
 *   variables: {
 *      policies: // value for 'policies'
 *   },
 * });
 */
export function useGQLAddPoliciesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLAddPoliciesMutation,
    GQLAddPoliciesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLAddPoliciesMutation,
    GQLAddPoliciesMutationVariables
  >(GQLAddPoliciesDocument, options);
}
export type GQLAddPoliciesMutationHookResult = ReturnType<
  typeof useGQLAddPoliciesMutation
>;
export type GQLAddPoliciesMutationResult =
  Apollo.MutationResult<GQLAddPoliciesMutation>;
export type GQLAddPoliciesMutationOptions = Apollo.BaseMutationOptions<
  GQLAddPoliciesMutation,
  GQLAddPoliciesMutationVariables
>;
export const GQLGetOrgIsReadyToCreateCoveModelsDocument = gql`
  query GetOrgIsReadyToCreateCoveModels {
    getOrgIsReadyToCreateCoveModels
  }
`;

/**
 * __useGQLGetOrgIsReadyToCreateCoveModelsQuery__
 *
 * To run a query within a React component, call `useGQLGetOrgIsReadyToCreateCoveModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetOrgIsReadyToCreateCoveModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetOrgIsReadyToCreateCoveModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLGetOrgIsReadyToCreateCoveModelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLGetOrgIsReadyToCreateCoveModelsQuery,
    GQLGetOrgIsReadyToCreateCoveModelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetOrgIsReadyToCreateCoveModelsQuery,
    GQLGetOrgIsReadyToCreateCoveModelsQueryVariables
  >(GQLGetOrgIsReadyToCreateCoveModelsDocument, options);
}
export function useGQLGetOrgIsReadyToCreateCoveModelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetOrgIsReadyToCreateCoveModelsQuery,
    GQLGetOrgIsReadyToCreateCoveModelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetOrgIsReadyToCreateCoveModelsQuery,
    GQLGetOrgIsReadyToCreateCoveModelsQueryVariables
  >(GQLGetOrgIsReadyToCreateCoveModelsDocument, options);
}
export type GQLGetOrgIsReadyToCreateCoveModelsQueryHookResult = ReturnType<
  typeof useGQLGetOrgIsReadyToCreateCoveModelsQuery
>;
export type GQLGetOrgIsReadyToCreateCoveModelsLazyQueryHookResult = ReturnType<
  typeof useGQLGetOrgIsReadyToCreateCoveModelsLazyQuery
>;
export type GQLGetOrgIsReadyToCreateCoveModelsQueryResult = Apollo.QueryResult<
  GQLGetOrgIsReadyToCreateCoveModelsQuery,
  GQLGetOrgIsReadyToCreateCoveModelsQueryVariables
>;
export const GQLUpdatePolicyDocument = gql`
  mutation UpdatePolicy($input: UpdatePolicyInput!) {
    updatePolicy(input: $input) {
      ...PolicyFields
    }
  }
  ${GQLPolicyFieldsFragmentDoc}
`;
export type GQLUpdatePolicyMutationFn = Apollo.MutationFunction<
  GQLUpdatePolicyMutation,
  GQLUpdatePolicyMutationVariables
>;

/**
 * __useGQLUpdatePolicyMutation__
 *
 * To run a mutation, you first call `useGQLUpdatePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLUpdatePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlUpdatePolicyMutation, { data, loading, error }] = useGQLUpdatePolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLUpdatePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLUpdatePolicyMutation,
    GQLUpdatePolicyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLUpdatePolicyMutation,
    GQLUpdatePolicyMutationVariables
  >(GQLUpdatePolicyDocument, options);
}
export type GQLUpdatePolicyMutationHookResult = ReturnType<
  typeof useGQLUpdatePolicyMutation
>;
export type GQLUpdatePolicyMutationResult =
  Apollo.MutationResult<GQLUpdatePolicyMutation>;
export type GQLUpdatePolicyMutationOptions = Apollo.BaseMutationOptions<
  GQLUpdatePolicyMutation,
  GQLUpdatePolicyMutationVariables
>;
export const GQLDeletePolicyDocument = gql`
  mutation DeletePolicy($id: ID!) {
    deletePolicy(id: $id)
  }
`;
export type GQLDeletePolicyMutationFn = Apollo.MutationFunction<
  GQLDeletePolicyMutation,
  GQLDeletePolicyMutationVariables
>;

/**
 * __useGQLDeletePolicyMutation__
 *
 * To run a mutation, you first call `useGQLDeletePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLDeletePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlDeletePolicyMutation, { data, loading, error }] = useGQLDeletePolicyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLDeletePolicyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLDeletePolicyMutation,
    GQLDeletePolicyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLDeletePolicyMutation,
    GQLDeletePolicyMutationVariables
  >(GQLDeletePolicyDocument, options);
}
export type GQLDeletePolicyMutationHookResult = ReturnType<
  typeof useGQLDeletePolicyMutation
>;
export type GQLDeletePolicyMutationResult =
  Apollo.MutationResult<GQLDeletePolicyMutation>;
export type GQLDeletePolicyMutationOptions = Apollo.BaseMutationOptions<
  GQLDeletePolicyMutation,
  GQLDeletePolicyMutationVariables
>;
export const GQLIsDemoOrgDocument = gql`
  query IsDemoOrg {
    myOrg {
      isDemoOrg
    }
  }
`;

/**
 * __useGQLIsDemoOrgQuery__
 *
 * To run a query within a React component, call `useGQLIsDemoOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLIsDemoOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLIsDemoOrgQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLIsDemoOrgQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLIsDemoOrgQuery,
    GQLIsDemoOrgQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLIsDemoOrgQuery, GQLIsDemoOrgQueryVariables>(
    GQLIsDemoOrgDocument,
    options,
  );
}
export function useGQLIsDemoOrgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLIsDemoOrgQuery,
    GQLIsDemoOrgQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQLIsDemoOrgQuery, GQLIsDemoOrgQueryVariables>(
    GQLIsDemoOrgDocument,
    options,
  );
}
export type GQLIsDemoOrgQueryHookResult = ReturnType<
  typeof useGQLIsDemoOrgQuery
>;
export type GQLIsDemoOrgLazyQueryHookResult = ReturnType<
  typeof useGQLIsDemoOrgLazyQuery
>;
export type GQLIsDemoOrgQueryResult = Apollo.QueryResult<
  GQLIsDemoOrgQuery,
  GQLIsDemoOrgQueryVariables
>;
export const GQLReportingRulesDocument = gql`
  query ReportingRules {
    myOrg {
      id
      name
      reportingRules {
        id
        name
        creator {
          firstName
          lastName
        }
        status
        policies {
          name
        }
        actions {
          ... on ActionBase {
            id
            name
          }
        }
        itemTypes {
          ... on ItemTypeBase {
            id
            name
          }
        }
      }
    }
    me {
      permissions
    }
  }
`;

/**
 * __useGQLReportingRulesQuery__
 *
 * To run a query within a React component, call `useGQLReportingRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLReportingRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLReportingRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLReportingRulesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLReportingRulesQuery,
    GQLReportingRulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLReportingRulesQuery,
    GQLReportingRulesQueryVariables
  >(GQLReportingRulesDocument, options);
}
export function useGQLReportingRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLReportingRulesQuery,
    GQLReportingRulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLReportingRulesQuery,
    GQLReportingRulesQueryVariables
  >(GQLReportingRulesDocument, options);
}
export type GQLReportingRulesQueryHookResult = ReturnType<
  typeof useGQLReportingRulesQuery
>;
export type GQLReportingRulesLazyQueryHookResult = ReturnType<
  typeof useGQLReportingRulesLazyQuery
>;
export type GQLReportingRulesQueryResult = Apollo.QueryResult<
  GQLReportingRulesQuery,
  GQLReportingRulesQueryVariables
>;
export const GQLCreateReportingRuleDocument = gql`
  mutation CreateReportingRule($input: CreateReportingRuleInput!) {
    createReportingRule(input: $input) {
      ... on MutateReportingRuleSuccessResponse {
        data {
          id
        }
      }
      ... on Error {
        title
      }
    }
  }
`;
export type GQLCreateReportingRuleMutationFn = Apollo.MutationFunction<
  GQLCreateReportingRuleMutation,
  GQLCreateReportingRuleMutationVariables
>;

/**
 * __useGQLCreateReportingRuleMutation__
 *
 * To run a mutation, you first call `useGQLCreateReportingRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLCreateReportingRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCreateReportingRuleMutation, { data, loading, error }] = useGQLCreateReportingRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLCreateReportingRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLCreateReportingRuleMutation,
    GQLCreateReportingRuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLCreateReportingRuleMutation,
    GQLCreateReportingRuleMutationVariables
  >(GQLCreateReportingRuleDocument, options);
}
export type GQLCreateReportingRuleMutationHookResult = ReturnType<
  typeof useGQLCreateReportingRuleMutation
>;
export type GQLCreateReportingRuleMutationResult =
  Apollo.MutationResult<GQLCreateReportingRuleMutation>;
export type GQLCreateReportingRuleMutationOptions = Apollo.BaseMutationOptions<
  GQLCreateReportingRuleMutation,
  GQLCreateReportingRuleMutationVariables
>;
export const GQLUpdateReportingRuleDocument = gql`
  mutation UpdateReportingRule($input: UpdateReportingRuleInput!) {
    updateReportingRule(input: $input) {
      ... on MutateReportingRuleSuccessResponse {
        data {
          id
        }
      }
      ... on Error {
        title
      }
    }
  }
`;
export type GQLUpdateReportingRuleMutationFn = Apollo.MutationFunction<
  GQLUpdateReportingRuleMutation,
  GQLUpdateReportingRuleMutationVariables
>;

/**
 * __useGQLUpdateReportingRuleMutation__
 *
 * To run a mutation, you first call `useGQLUpdateReportingRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLUpdateReportingRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlUpdateReportingRuleMutation, { data, loading, error }] = useGQLUpdateReportingRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLUpdateReportingRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLUpdateReportingRuleMutation,
    GQLUpdateReportingRuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLUpdateReportingRuleMutation,
    GQLUpdateReportingRuleMutationVariables
  >(GQLUpdateReportingRuleDocument, options);
}
export type GQLUpdateReportingRuleMutationHookResult = ReturnType<
  typeof useGQLUpdateReportingRuleMutation
>;
export type GQLUpdateReportingRuleMutationResult =
  Apollo.MutationResult<GQLUpdateReportingRuleMutation>;
export type GQLUpdateReportingRuleMutationOptions = Apollo.BaseMutationOptions<
  GQLUpdateReportingRuleMutation,
  GQLUpdateReportingRuleMutationVariables
>;
export const GQLDeleteReportingRuleDocument = gql`
  mutation DeleteReportingRule($id: ID!) {
    deleteReportingRule(id: $id)
  }
`;
export type GQLDeleteReportingRuleMutationFn = Apollo.MutationFunction<
  GQLDeleteReportingRuleMutation,
  GQLDeleteReportingRuleMutationVariables
>;

/**
 * __useGQLDeleteReportingRuleMutation__
 *
 * To run a mutation, you first call `useGQLDeleteReportingRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLDeleteReportingRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlDeleteReportingRuleMutation, { data, loading, error }] = useGQLDeleteReportingRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLDeleteReportingRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLDeleteReportingRuleMutation,
    GQLDeleteReportingRuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLDeleteReportingRuleMutation,
    GQLDeleteReportingRuleMutationVariables
  >(GQLDeleteReportingRuleDocument, options);
}
export type GQLDeleteReportingRuleMutationHookResult = ReturnType<
  typeof useGQLDeleteReportingRuleMutation
>;
export type GQLDeleteReportingRuleMutationResult =
  Apollo.MutationResult<GQLDeleteReportingRuleMutation>;
export type GQLDeleteReportingRuleMutationOptions = Apollo.BaseMutationOptions<
  GQLDeleteReportingRuleMutation,
  GQLDeleteReportingRuleMutationVariables
>;
export const GQLRulesDocument = gql`
  query Rules {
    myOrg {
      id
      name
      rules {
        conditionSet {
          ...ConditionSetFields
        }
        ... on ContentRule {
          ...RulesDashboardRuleFieldsFragment
          itemTypes {
            ... on ItemTypeBase {
              id
              name
            }
          }
        }
        ... on UserRule {
          ...RulesDashboardRuleFieldsFragment
        }
      }
    }
    me {
      permissions
      favoriteRules {
        id
      }
    }
  }
  ${GQLConditionSetFieldsFragmentDoc}
  ${GQLRulesDashboardRuleFieldsFragmentFragmentDoc}
`;

/**
 * __useGQLRulesQuery__
 *
 * To run a query within a React component, call `useGQLRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLRulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLRulesQuery(
  baseOptions?: Apollo.QueryHookOptions<GQLRulesQuery, GQLRulesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLRulesQuery, GQLRulesQueryVariables>(
    GQLRulesDocument,
    options,
  );
}
export function useGQLRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLRulesQuery,
    GQLRulesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQLRulesQuery, GQLRulesQueryVariables>(
    GQLRulesDocument,
    options,
  );
}
export type GQLRulesQueryHookResult = ReturnType<typeof useGQLRulesQuery>;
export type GQLRulesLazyQueryHookResult = ReturnType<
  typeof useGQLRulesLazyQuery
>;
export type GQLRulesQueryResult = Apollo.QueryResult<
  GQLRulesQuery,
  GQLRulesQueryVariables
>;
export const GQLDeleteRuleDocument = gql`
  mutation DeleteRule($id: ID!) {
    deleteRule(id: $id)
  }
`;
export type GQLDeleteRuleMutationFn = Apollo.MutationFunction<
  GQLDeleteRuleMutation,
  GQLDeleteRuleMutationVariables
>;

/**
 * __useGQLDeleteRuleMutation__
 *
 * To run a mutation, you first call `useGQLDeleteRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLDeleteRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlDeleteRuleMutation, { data, loading, error }] = useGQLDeleteRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLDeleteRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLDeleteRuleMutation,
    GQLDeleteRuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLDeleteRuleMutation,
    GQLDeleteRuleMutationVariables
  >(GQLDeleteRuleDocument, options);
}
export type GQLDeleteRuleMutationHookResult = ReturnType<
  typeof useGQLDeleteRuleMutation
>;
export type GQLDeleteRuleMutationResult =
  Apollo.MutationResult<GQLDeleteRuleMutation>;
export type GQLDeleteRuleMutationOptions = Apollo.BaseMutationOptions<
  GQLDeleteRuleMutation,
  GQLDeleteRuleMutationVariables
>;
export const GQLAddFavoriteRuleDocument = gql`
  mutation AddFavoriteRule($ruleId: ID!) {
    addFavoriteRule(ruleId: $ruleId) {
      ... on AddFavoriteRuleSuccessResponse {
        _
      }
    }
  }
`;
export type GQLAddFavoriteRuleMutationFn = Apollo.MutationFunction<
  GQLAddFavoriteRuleMutation,
  GQLAddFavoriteRuleMutationVariables
>;

/**
 * __useGQLAddFavoriteRuleMutation__
 *
 * To run a mutation, you first call `useGQLAddFavoriteRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLAddFavoriteRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlAddFavoriteRuleMutation, { data, loading, error }] = useGQLAddFavoriteRuleMutation({
 *   variables: {
 *      ruleId: // value for 'ruleId'
 *   },
 * });
 */
export function useGQLAddFavoriteRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLAddFavoriteRuleMutation,
    GQLAddFavoriteRuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLAddFavoriteRuleMutation,
    GQLAddFavoriteRuleMutationVariables
  >(GQLAddFavoriteRuleDocument, options);
}
export type GQLAddFavoriteRuleMutationHookResult = ReturnType<
  typeof useGQLAddFavoriteRuleMutation
>;
export type GQLAddFavoriteRuleMutationResult =
  Apollo.MutationResult<GQLAddFavoriteRuleMutation>;
export type GQLAddFavoriteRuleMutationOptions = Apollo.BaseMutationOptions<
  GQLAddFavoriteRuleMutation,
  GQLAddFavoriteRuleMutationVariables
>;
export const GQLRemoveFavoriteRuleDocument = gql`
  mutation RemoveFavoriteRule($ruleId: ID!) {
    removeFavoriteRule(ruleId: $ruleId) {
      ... on RemoveFavoriteRuleSuccessResponse {
        _
      }
    }
  }
`;
export type GQLRemoveFavoriteRuleMutationFn = Apollo.MutationFunction<
  GQLRemoveFavoriteRuleMutation,
  GQLRemoveFavoriteRuleMutationVariables
>;

/**
 * __useGQLRemoveFavoriteRuleMutation__
 *
 * To run a mutation, you first call `useGQLRemoveFavoriteRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLRemoveFavoriteRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlRemoveFavoriteRuleMutation, { data, loading, error }] = useGQLRemoveFavoriteRuleMutation({
 *   variables: {
 *      ruleId: // value for 'ruleId'
 *   },
 * });
 */
export function useGQLRemoveFavoriteRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLRemoveFavoriteRuleMutation,
    GQLRemoveFavoriteRuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLRemoveFavoriteRuleMutation,
    GQLRemoveFavoriteRuleMutationVariables
  >(GQLRemoveFavoriteRuleDocument, options);
}
export type GQLRemoveFavoriteRuleMutationHookResult = ReturnType<
  typeof useGQLRemoveFavoriteRuleMutation
>;
export type GQLRemoveFavoriteRuleMutationResult =
  Apollo.MutationResult<GQLRemoveFavoriteRuleMutation>;
export type GQLRemoveFavoriteRuleMutationOptions = Apollo.BaseMutationOptions<
  GQLRemoveFavoriteRuleMutation,
  GQLRemoveFavoriteRuleMutationVariables
>;
export const GQLRulesDashboardInsightsDocument = gql`
  query RulesDashboardInsights {
    allRuleInsights {
      actionedSubmissionsByPolicyByDay {
        date
        count
        policy {
          name
          id
        }
      }
      actionedSubmissionsByTagByDay {
        date
        count
        tag
      }
      actionedSubmissionsByActionByDay {
        date
        count
        action {
          name
        }
      }
      actionedSubmissionsByDay {
        date
        count
      }
      totalSubmissionsByDay {
        date
        count
      }
    }
  }
`;

/**
 * __useGQLRulesDashboardInsightsQuery__
 *
 * To run a query within a React component, call `useGQLRulesDashboardInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLRulesDashboardInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLRulesDashboardInsightsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLRulesDashboardInsightsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLRulesDashboardInsightsQuery,
    GQLRulesDashboardInsightsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLRulesDashboardInsightsQuery,
    GQLRulesDashboardInsightsQueryVariables
  >(GQLRulesDashboardInsightsDocument, options);
}
export function useGQLRulesDashboardInsightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLRulesDashboardInsightsQuery,
    GQLRulesDashboardInsightsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLRulesDashboardInsightsQuery,
    GQLRulesDashboardInsightsQueryVariables
  >(GQLRulesDashboardInsightsDocument, options);
}
export type GQLRulesDashboardInsightsQueryHookResult = ReturnType<
  typeof useGQLRulesDashboardInsightsQuery
>;
export type GQLRulesDashboardInsightsLazyQueryHookResult = ReturnType<
  typeof useGQLRulesDashboardInsightsLazyQuery
>;
export type GQLRulesDashboardInsightsQueryResult = Apollo.QueryResult<
  GQLRulesDashboardInsightsQuery,
  GQLRulesDashboardInsightsQueryVariables
>;
export const GQLPolicyRollupDataDocument = gql`
  query PolicyRollupData {
    myOrg {
      id
      policies {
        id
        name
        parentId
      }
    }
  }
`;

/**
 * __useGQLPolicyRollupDataQuery__
 *
 * To run a query within a React component, call `useGQLPolicyRollupDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLPolicyRollupDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLPolicyRollupDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLPolicyRollupDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLPolicyRollupDataQuery,
    GQLPolicyRollupDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLPolicyRollupDataQuery,
    GQLPolicyRollupDataQueryVariables
  >(GQLPolicyRollupDataDocument, options);
}
export function useGQLPolicyRollupDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLPolicyRollupDataQuery,
    GQLPolicyRollupDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLPolicyRollupDataQuery,
    GQLPolicyRollupDataQueryVariables
  >(GQLPolicyRollupDataDocument, options);
}
export type GQLPolicyRollupDataQueryHookResult = ReturnType<
  typeof useGQLPolicyRollupDataQuery
>;
export type GQLPolicyRollupDataLazyQueryHookResult = ReturnType<
  typeof useGQLPolicyRollupDataLazyQuery
>;
export type GQLPolicyRollupDataQueryResult = Apollo.QueryResult<
  GQLPolicyRollupDataQuery,
  GQLPolicyRollupDataQueryVariables
>;
export const GQLActionStatisticsDataDocument = gql`
  query ActionStatisticsData($input: ActionStatisticsInput!) {
    actionStatistics(input: $input) {
      item_type_id
      action_id
      policy_id
      rule_id
      source
      count
      time
      count
    }
  }
`;

/**
 * __useGQLActionStatisticsDataQuery__
 *
 * To run a query within a React component, call `useGQLActionStatisticsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLActionStatisticsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLActionStatisticsDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLActionStatisticsDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLActionStatisticsDataQuery,
    GQLActionStatisticsDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLActionStatisticsDataQuery,
    GQLActionStatisticsDataQueryVariables
  >(GQLActionStatisticsDataDocument, options);
}
export function useGQLActionStatisticsDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLActionStatisticsDataQuery,
    GQLActionStatisticsDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLActionStatisticsDataQuery,
    GQLActionStatisticsDataQueryVariables
  >(GQLActionStatisticsDataDocument, options);
}
export type GQLActionStatisticsDataQueryHookResult = ReturnType<
  typeof useGQLActionStatisticsDataQuery
>;
export type GQLActionStatisticsDataLazyQueryHookResult = ReturnType<
  typeof useGQLActionStatisticsDataLazyQuery
>;
export type GQLActionStatisticsDataQueryResult = Apollo.QueryResult<
  GQLActionStatisticsDataQuery,
  GQLActionStatisticsDataQueryVariables
>;
export const GQLReportingRuleInfoDocument = gql`
  query ReportingRuleInfo($id: ID!) {
    reportingRule(id: $id) {
      name
    }
  }
`;

/**
 * __useGQLReportingRuleInfoQuery__
 *
 * To run a query within a React component, call `useGQLReportingRuleInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLReportingRuleInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLReportingRuleInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLReportingRuleInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLReportingRuleInfoQuery,
    GQLReportingRuleInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLReportingRuleInfoQuery,
    GQLReportingRuleInfoQueryVariables
  >(GQLReportingRuleInfoDocument, options);
}
export function useGQLReportingRuleInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLReportingRuleInfoQuery,
    GQLReportingRuleInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLReportingRuleInfoQuery,
    GQLReportingRuleInfoQueryVariables
  >(GQLReportingRuleInfoDocument, options);
}
export type GQLReportingRuleInfoQueryHookResult = ReturnType<
  typeof useGQLReportingRuleInfoQuery
>;
export type GQLReportingRuleInfoLazyQueryHookResult = ReturnType<
  typeof useGQLReportingRuleInfoLazyQuery
>;
export type GQLReportingRuleInfoQueryResult = Apollo.QueryResult<
  GQLReportingRuleInfoQuery,
  GQLReportingRuleInfoQueryVariables
>;
export const GQLRuleInfoDocument = gql`
  query RuleInfo($id: ID!) {
    me {
      permissions
    }
    rule(id: $id) {
      name
    }
  }
`;

/**
 * __useGQLRuleInfoQuery__
 *
 * To run a query within a React component, call `useGQLRuleInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLRuleInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLRuleInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLRuleInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLRuleInfoQuery,
    GQLRuleInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLRuleInfoQuery, GQLRuleInfoQueryVariables>(
    GQLRuleInfoDocument,
    options,
  );
}
export function useGQLRuleInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLRuleInfoQuery,
    GQLRuleInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQLRuleInfoQuery, GQLRuleInfoQueryVariables>(
    GQLRuleInfoDocument,
    options,
  );
}
export type GQLRuleInfoQueryHookResult = ReturnType<typeof useGQLRuleInfoQuery>;
export type GQLRuleInfoLazyQueryHookResult = ReturnType<
  typeof useGQLRuleInfoLazyQuery
>;
export type GQLRuleInfoQueryResult = Apollo.QueryResult<
  GQLRuleInfoQuery,
  GQLRuleInfoQueryVariables
>;
export const GQLSpotTestRuleDocument = gql`
  query SpotTestRule($ruleId: ID!, $item: SpotTestItemInput!) {
    spotTestRule(ruleId: $ruleId, item: $item) {
      ... on RuleExecutionResult {
        ...SampleRuleExecutionResultFields
        result {
          ...SampleRuleExecutionResultConditionResultFields
        }
      }
    }
  }
  ${GQLSampleRuleExecutionResultFieldsFragmentDoc}
  ${GQLSampleRuleExecutionResultConditionResultFieldsFragmentDoc}
`;

/**
 * __useGQLSpotTestRuleQuery__
 *
 * To run a query within a React component, call `useGQLSpotTestRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLSpotTestRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLSpotTestRuleQuery({
 *   variables: {
 *      ruleId: // value for 'ruleId'
 *      item: // value for 'item'
 *   },
 * });
 */
export function useGQLSpotTestRuleQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLSpotTestRuleQuery,
    GQLSpotTestRuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLSpotTestRuleQuery, GQLSpotTestRuleQueryVariables>(
    GQLSpotTestRuleDocument,
    options,
  );
}
export function useGQLSpotTestRuleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLSpotTestRuleQuery,
    GQLSpotTestRuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLSpotTestRuleQuery,
    GQLSpotTestRuleQueryVariables
  >(GQLSpotTestRuleDocument, options);
}
export type GQLSpotTestRuleQueryHookResult = ReturnType<
  typeof useGQLSpotTestRuleQuery
>;
export type GQLSpotTestRuleLazyQueryHookResult = ReturnType<
  typeof useGQLSpotTestRuleLazyQuery
>;
export type GQLSpotTestRuleQueryResult = Apollo.QueryResult<
  GQLSpotTestRuleQuery,
  GQLSpotTestRuleQueryVariables
>;
export const GQLReportingRulePassRateAnalyticsDocument = gql`
  query ReportingRulePassRateAnalytics($id: ID!) {
    rule(id: $id) {
      insights {
        passRateData {
          date
          totalMatches
          totalRequests
        }
      }
    }
  }
`;

/**
 * __useGQLReportingRulePassRateAnalyticsQuery__
 *
 * To run a query within a React component, call `useGQLReportingRulePassRateAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLReportingRulePassRateAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLReportingRulePassRateAnalyticsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLReportingRulePassRateAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLReportingRulePassRateAnalyticsQuery,
    GQLReportingRulePassRateAnalyticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLReportingRulePassRateAnalyticsQuery,
    GQLReportingRulePassRateAnalyticsQueryVariables
  >(GQLReportingRulePassRateAnalyticsDocument, options);
}
export function useGQLReportingRulePassRateAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLReportingRulePassRateAnalyticsQuery,
    GQLReportingRulePassRateAnalyticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLReportingRulePassRateAnalyticsQuery,
    GQLReportingRulePassRateAnalyticsQueryVariables
  >(GQLReportingRulePassRateAnalyticsDocument, options);
}
export type GQLReportingRulePassRateAnalyticsQueryHookResult = ReturnType<
  typeof useGQLReportingRulePassRateAnalyticsQuery
>;
export type GQLReportingRulePassRateAnalyticsLazyQueryHookResult = ReturnType<
  typeof useGQLReportingRulePassRateAnalyticsLazyQuery
>;
export type GQLReportingRulePassRateAnalyticsQueryResult = Apollo.QueryResult<
  GQLReportingRulePassRateAnalyticsQuery,
  GQLReportingRulePassRateAnalyticsQueryVariables
>;
export const GQLReportingRuleInsightsCurrentVersionSamplesDocument = gql`
  query ReportingRuleInsightsCurrentVersionSamples($id: ID!) {
    reportingRule(id: $id) {
      id
      name
      itemTypes {
        ... on ItemTypeBase {
          id
          name
          baseFields {
            name
            type
          }
          derivedFields {
            name
            type
          }
        }
      }
      insights {
        samples: latestVersionSamples {
          ...SampleReportingRuleExecutionResultFields
        }
      }
    }
  }
  ${GQLSampleReportingRuleExecutionResultFieldsFragmentDoc}
`;

/**
 * __useGQLReportingRuleInsightsCurrentVersionSamplesQuery__
 *
 * To run a query within a React component, call `useGQLReportingRuleInsightsCurrentVersionSamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLReportingRuleInsightsCurrentVersionSamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLReportingRuleInsightsCurrentVersionSamplesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLReportingRuleInsightsCurrentVersionSamplesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLReportingRuleInsightsCurrentVersionSamplesQuery,
    GQLReportingRuleInsightsCurrentVersionSamplesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLReportingRuleInsightsCurrentVersionSamplesQuery,
    GQLReportingRuleInsightsCurrentVersionSamplesQueryVariables
  >(GQLReportingRuleInsightsCurrentVersionSamplesDocument, options);
}
export function useGQLReportingRuleInsightsCurrentVersionSamplesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLReportingRuleInsightsCurrentVersionSamplesQuery,
    GQLReportingRuleInsightsCurrentVersionSamplesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLReportingRuleInsightsCurrentVersionSamplesQuery,
    GQLReportingRuleInsightsCurrentVersionSamplesQueryVariables
  >(GQLReportingRuleInsightsCurrentVersionSamplesDocument, options);
}
export type GQLReportingRuleInsightsCurrentVersionSamplesQueryHookResult =
  ReturnType<typeof useGQLReportingRuleInsightsCurrentVersionSamplesQuery>;
export type GQLReportingRuleInsightsCurrentVersionSamplesLazyQueryHookResult =
  ReturnType<typeof useGQLReportingRuleInsightsCurrentVersionSamplesLazyQuery>;
export type GQLReportingRuleInsightsCurrentVersionSamplesQueryResult =
  Apollo.QueryResult<
    GQLReportingRuleInsightsCurrentVersionSamplesQuery,
    GQLReportingRuleInsightsCurrentVersionSamplesQueryVariables
  >;
export const GQLReportingRuleInsightsPriorVersionSamplesDocument = gql`
  query ReportingRuleInsightsPriorVersionSamples($id: ID!) {
    reportingRule(id: $id) {
      name
      itemTypes {
        ... on ItemTypeBase {
          id
          name
          baseFields {
            name
            type
          }
          derivedFields {
            name
            type
          }
        }
      }
      insights {
        samples: priorVersionSamples {
          ...SampleReportingRuleExecutionResultFields
        }
      }
    }
  }
  ${GQLSampleReportingRuleExecutionResultFieldsFragmentDoc}
`;

/**
 * __useGQLReportingRuleInsightsPriorVersionSamplesQuery__
 *
 * To run a query within a React component, call `useGQLReportingRuleInsightsPriorVersionSamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLReportingRuleInsightsPriorVersionSamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLReportingRuleInsightsPriorVersionSamplesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLReportingRuleInsightsPriorVersionSamplesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLReportingRuleInsightsPriorVersionSamplesQuery,
    GQLReportingRuleInsightsPriorVersionSamplesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLReportingRuleInsightsPriorVersionSamplesQuery,
    GQLReportingRuleInsightsPriorVersionSamplesQueryVariables
  >(GQLReportingRuleInsightsPriorVersionSamplesDocument, options);
}
export function useGQLReportingRuleInsightsPriorVersionSamplesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLReportingRuleInsightsPriorVersionSamplesQuery,
    GQLReportingRuleInsightsPriorVersionSamplesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLReportingRuleInsightsPriorVersionSamplesQuery,
    GQLReportingRuleInsightsPriorVersionSamplesQueryVariables
  >(GQLReportingRuleInsightsPriorVersionSamplesDocument, options);
}
export type GQLReportingRuleInsightsPriorVersionSamplesQueryHookResult =
  ReturnType<typeof useGQLReportingRuleInsightsPriorVersionSamplesQuery>;
export type GQLReportingRuleInsightsPriorVersionSamplesLazyQueryHookResult =
  ReturnType<typeof useGQLReportingRuleInsightsPriorVersionSamplesLazyQuery>;
export type GQLReportingRuleInsightsPriorVersionSamplesQueryResult =
  Apollo.QueryResult<
    GQLReportingRuleInsightsPriorVersionSamplesQuery,
    GQLReportingRuleInsightsPriorVersionSamplesQueryVariables
  >;
export const GQLRulePassRateAnalyticsDocument = gql`
  query RulePassRateAnalytics($id: ID!) {
    rule(id: $id) {
      insights {
        passRateData {
          date
          totalMatches
          totalRequests
        }
      }
    }
    allRuleInsights {
      totalSubmissionsByDay {
        date
        count
      }
    }
  }
`;

/**
 * __useGQLRulePassRateAnalyticsQuery__
 *
 * To run a query within a React component, call `useGQLRulePassRateAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLRulePassRateAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLRulePassRateAnalyticsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLRulePassRateAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLRulePassRateAnalyticsQuery,
    GQLRulePassRateAnalyticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLRulePassRateAnalyticsQuery,
    GQLRulePassRateAnalyticsQueryVariables
  >(GQLRulePassRateAnalyticsDocument, options);
}
export function useGQLRulePassRateAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLRulePassRateAnalyticsQuery,
    GQLRulePassRateAnalyticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLRulePassRateAnalyticsQuery,
    GQLRulePassRateAnalyticsQueryVariables
  >(GQLRulePassRateAnalyticsDocument, options);
}
export type GQLRulePassRateAnalyticsQueryHookResult = ReturnType<
  typeof useGQLRulePassRateAnalyticsQuery
>;
export type GQLRulePassRateAnalyticsLazyQueryHookResult = ReturnType<
  typeof useGQLRulePassRateAnalyticsLazyQuery
>;
export type GQLRulePassRateAnalyticsQueryResult = Apollo.QueryResult<
  GQLRulePassRateAnalyticsQuery,
  GQLRulePassRateAnalyticsQueryVariables
>;
export const GQLRuleInsightsTableAllSignalsDocument = gql`
  query RuleInsightsTableAllSignals {
    myOrg {
      signals(customOnly: false) {
        id
        integration
        eligibleSubcategories {
          id
          label
        }
      }
    }
  }
`;

/**
 * __useGQLRuleInsightsTableAllSignalsQuery__
 *
 * To run a query within a React component, call `useGQLRuleInsightsTableAllSignalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLRuleInsightsTableAllSignalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLRuleInsightsTableAllSignalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLRuleInsightsTableAllSignalsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLRuleInsightsTableAllSignalsQuery,
    GQLRuleInsightsTableAllSignalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLRuleInsightsTableAllSignalsQuery,
    GQLRuleInsightsTableAllSignalsQueryVariables
  >(GQLRuleInsightsTableAllSignalsDocument, options);
}
export function useGQLRuleInsightsTableAllSignalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLRuleInsightsTableAllSignalsQuery,
    GQLRuleInsightsTableAllSignalsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLRuleInsightsTableAllSignalsQuery,
    GQLRuleInsightsTableAllSignalsQueryVariables
  >(GQLRuleInsightsTableAllSignalsDocument, options);
}
export type GQLRuleInsightsTableAllSignalsQueryHookResult = ReturnType<
  typeof useGQLRuleInsightsTableAllSignalsQuery
>;
export type GQLRuleInsightsTableAllSignalsLazyQueryHookResult = ReturnType<
  typeof useGQLRuleInsightsTableAllSignalsLazyQuery
>;
export type GQLRuleInsightsTableAllSignalsQueryResult = Apollo.QueryResult<
  GQLRuleInsightsTableAllSignalsQuery,
  GQLRuleInsightsTableAllSignalsQueryVariables
>;
export const GQLRuleInsightsCurrentVersionSamplesDocument = gql`
  query RuleInsightsCurrentVersionSamples($id: ID!) {
    rule(id: $id) {
      ... on ContentRule {
        __typename
        name
        insights {
          samples: latestVersionSamples {
            ...SampleRuleExecutionResultFields
          }
        }
        itemTypes {
          ... on ItemTypeBase {
            id
            name
            baseFields {
              name
              type
            }
            derivedFields {
              name
              type
            }
          }
        }
      }
      ... on UserRule {
        __typename
        name
        insights {
          samples: latestVersionSamples {
            ...SampleRuleExecutionResultFields
          }
        }
      }
    }
  }
  ${GQLSampleRuleExecutionResultFieldsFragmentDoc}
`;

/**
 * __useGQLRuleInsightsCurrentVersionSamplesQuery__
 *
 * To run a query within a React component, call `useGQLRuleInsightsCurrentVersionSamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLRuleInsightsCurrentVersionSamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLRuleInsightsCurrentVersionSamplesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLRuleInsightsCurrentVersionSamplesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLRuleInsightsCurrentVersionSamplesQuery,
    GQLRuleInsightsCurrentVersionSamplesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLRuleInsightsCurrentVersionSamplesQuery,
    GQLRuleInsightsCurrentVersionSamplesQueryVariables
  >(GQLRuleInsightsCurrentVersionSamplesDocument, options);
}
export function useGQLRuleInsightsCurrentVersionSamplesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLRuleInsightsCurrentVersionSamplesQuery,
    GQLRuleInsightsCurrentVersionSamplesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLRuleInsightsCurrentVersionSamplesQuery,
    GQLRuleInsightsCurrentVersionSamplesQueryVariables
  >(GQLRuleInsightsCurrentVersionSamplesDocument, options);
}
export type GQLRuleInsightsCurrentVersionSamplesQueryHookResult = ReturnType<
  typeof useGQLRuleInsightsCurrentVersionSamplesQuery
>;
export type GQLRuleInsightsCurrentVersionSamplesLazyQueryHookResult =
  ReturnType<typeof useGQLRuleInsightsCurrentVersionSamplesLazyQuery>;
export type GQLRuleInsightsCurrentVersionSamplesQueryResult =
  Apollo.QueryResult<
    GQLRuleInsightsCurrentVersionSamplesQuery,
    GQLRuleInsightsCurrentVersionSamplesQueryVariables
  >;
export const GQLRuleInsightsPriorVersionSamplesDocument = gql`
  query RuleInsightsPriorVersionSamples($id: ID!) {
    rule(id: $id) {
      ... on ContentRule {
        name
        insights {
          samples: priorVersionSamples {
            ...SampleRuleExecutionResultFields
          }
        }
        itemTypes {
          ... on ItemTypeBase {
            name
            id
            baseFields {
              name
              type
              container {
                keyScalarType
                valueScalarType
              }
            }
            derivedFields {
              name
              type
              container {
                keyScalarType
                valueScalarType
              }
            }
          }
        }
      }
      ... on UserRule {
        name
        insights {
          samples: priorVersionSamples {
            ...SampleRuleExecutionResultFields
          }
        }
      }
    }
  }
  ${GQLSampleRuleExecutionResultFieldsFragmentDoc}
`;

/**
 * __useGQLRuleInsightsPriorVersionSamplesQuery__
 *
 * To run a query within a React component, call `useGQLRuleInsightsPriorVersionSamplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLRuleInsightsPriorVersionSamplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLRuleInsightsPriorVersionSamplesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLRuleInsightsPriorVersionSamplesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLRuleInsightsPriorVersionSamplesQuery,
    GQLRuleInsightsPriorVersionSamplesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLRuleInsightsPriorVersionSamplesQuery,
    GQLRuleInsightsPriorVersionSamplesQueryVariables
  >(GQLRuleInsightsPriorVersionSamplesDocument, options);
}
export function useGQLRuleInsightsPriorVersionSamplesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLRuleInsightsPriorVersionSamplesQuery,
    GQLRuleInsightsPriorVersionSamplesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLRuleInsightsPriorVersionSamplesQuery,
    GQLRuleInsightsPriorVersionSamplesQueryVariables
  >(GQLRuleInsightsPriorVersionSamplesDocument, options);
}
export type GQLRuleInsightsPriorVersionSamplesQueryHookResult = ReturnType<
  typeof useGQLRuleInsightsPriorVersionSamplesQuery
>;
export type GQLRuleInsightsPriorVersionSamplesLazyQueryHookResult = ReturnType<
  typeof useGQLRuleInsightsPriorVersionSamplesLazyQuery
>;
export type GQLRuleInsightsPriorVersionSamplesQueryResult = Apollo.QueryResult<
  GQLRuleInsightsPriorVersionSamplesQuery,
  GQLRuleInsightsPriorVersionSamplesQueryVariables
>;
export const GQLGetFullResultForRuleDocument = gql`
  query GetFullResultForRule($input: GetFullResultForItemInput!) {
    getFullRuleResultForItem(input: $input) {
      ... on RuleExecutionResult {
        ...SampleRuleExecutionResultFields
        result {
          ...SampleRuleExecutionResultConditionResultFields
        }
      }
      ... on NotFoundError {
        title
      }
    }
  }
  ${GQLSampleRuleExecutionResultFieldsFragmentDoc}
  ${GQLSampleRuleExecutionResultConditionResultFieldsFragmentDoc}
`;

/**
 * __useGQLGetFullResultForRuleQuery__
 *
 * To run a query within a React component, call `useGQLGetFullResultForRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetFullResultForRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetFullResultForRuleQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLGetFullResultForRuleQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetFullResultForRuleQuery,
    GQLGetFullResultForRuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetFullResultForRuleQuery,
    GQLGetFullResultForRuleQueryVariables
  >(GQLGetFullResultForRuleDocument, options);
}
export function useGQLGetFullResultForRuleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetFullResultForRuleQuery,
    GQLGetFullResultForRuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetFullResultForRuleQuery,
    GQLGetFullResultForRuleQueryVariables
  >(GQLGetFullResultForRuleDocument, options);
}
export type GQLGetFullResultForRuleQueryHookResult = ReturnType<
  typeof useGQLGetFullResultForRuleQuery
>;
export type GQLGetFullResultForRuleLazyQueryHookResult = ReturnType<
  typeof useGQLGetFullResultForRuleLazyQuery
>;
export type GQLGetFullResultForRuleQueryResult = Apollo.QueryResult<
  GQLGetFullResultForRuleQuery,
  GQLGetFullResultForRuleQueryVariables
>;
export const GQLMatchingBankNamesDocument = gql`
  query MatchingBankNames {
    myOrg {
      banks {
        textBanks {
          id
          name
        }
        locationBanks {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGQLMatchingBankNamesQuery__
 *
 * To run a query within a React component, call `useGQLMatchingBankNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLMatchingBankNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLMatchingBankNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLMatchingBankNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLMatchingBankNamesQuery,
    GQLMatchingBankNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLMatchingBankNamesQuery,
    GQLMatchingBankNamesQueryVariables
  >(GQLMatchingBankNamesDocument, options);
}
export function useGQLMatchingBankNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLMatchingBankNamesQuery,
    GQLMatchingBankNamesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLMatchingBankNamesQuery,
    GQLMatchingBankNamesQueryVariables
  >(GQLMatchingBankNamesDocument, options);
}
export type GQLMatchingBankNamesQueryHookResult = ReturnType<
  typeof useGQLMatchingBankNamesQuery
>;
export type GQLMatchingBankNamesLazyQueryHookResult = ReturnType<
  typeof useGQLMatchingBankNamesLazyQuery
>;
export type GQLMatchingBankNamesQueryResult = Apollo.QueryResult<
  GQLMatchingBankNamesQuery,
  GQLMatchingBankNamesQueryVariables
>;
export const GQLReportingRuleDocument = gql`
  query ReportingRule($id: ID!) {
    reportingRule(id: $id) {
      ...ReportingRuleFormRuleFieldsFragment
      itemTypes {
        ... on ItemTypeBase {
          ...ItemTypeFragment
        }
      }
    }
  }
  ${GQLReportingRuleFormRuleFieldsFragmentFragmentDoc}
  ${GQLItemTypeFragmentFragmentDoc}
`;

/**
 * __useGQLReportingRuleQuery__
 *
 * To run a query within a React component, call `useGQLReportingRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLReportingRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLReportingRuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLReportingRuleQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLReportingRuleQuery,
    GQLReportingRuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLReportingRuleQuery, GQLReportingRuleQueryVariables>(
    GQLReportingRuleDocument,
    options,
  );
}
export function useGQLReportingRuleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLReportingRuleQuery,
    GQLReportingRuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLReportingRuleQuery,
    GQLReportingRuleQueryVariables
  >(GQLReportingRuleDocument, options);
}
export type GQLReportingRuleQueryHookResult = ReturnType<
  typeof useGQLReportingRuleQuery
>;
export type GQLReportingRuleLazyQueryHookResult = ReturnType<
  typeof useGQLReportingRuleLazyQuery
>;
export type GQLReportingRuleQueryResult = Apollo.QueryResult<
  GQLReportingRuleQuery,
  GQLReportingRuleQueryVariables
>;
export const GQLReportingRuleFormOrgDataDocument = gql`
  query ReportingRuleFormOrgData {
    myOrg {
      policies {
        id
        name
        parentId
      }
      itemTypes {
        ... on ItemTypeBase {
          ...ItemTypeFragment
        }
      }
      signals {
        ...SignalsFragment
      }
      actions {
        ...ActionFragment
      }
    }
    me {
      permissions
    }
  }
  ${GQLItemTypeFragmentFragmentDoc}
  ${GQLSignalsFragmentFragmentDoc}
  ${GQLActionFragmentFragmentDoc}
`;

/**
 * __useGQLReportingRuleFormOrgDataQuery__
 *
 * To run a query within a React component, call `useGQLReportingRuleFormOrgDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLReportingRuleFormOrgDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLReportingRuleFormOrgDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLReportingRuleFormOrgDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLReportingRuleFormOrgDataQuery,
    GQLReportingRuleFormOrgDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLReportingRuleFormOrgDataQuery,
    GQLReportingRuleFormOrgDataQueryVariables
  >(GQLReportingRuleFormOrgDataDocument, options);
}
export function useGQLReportingRuleFormOrgDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLReportingRuleFormOrgDataQuery,
    GQLReportingRuleFormOrgDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLReportingRuleFormOrgDataQuery,
    GQLReportingRuleFormOrgDataQueryVariables
  >(GQLReportingRuleFormOrgDataDocument, options);
}
export type GQLReportingRuleFormOrgDataQueryHookResult = ReturnType<
  typeof useGQLReportingRuleFormOrgDataQuery
>;
export type GQLReportingRuleFormOrgDataLazyQueryHookResult = ReturnType<
  typeof useGQLReportingRuleFormOrgDataLazyQuery
>;
export type GQLReportingRuleFormOrgDataQueryResult = Apollo.QueryResult<
  GQLReportingRuleFormOrgDataQuery,
  GQLReportingRuleFormOrgDataQueryVariables
>;
export const GQLRuleDocument = gql`
  query Rule($id: ID!) {
    rule(id: $id) {
      ... on ContentRule {
        ...RuleFormRuleFieldsFragment
        itemTypes {
          ...ItemTypeFragment
        }
      }
      ... on UserRule {
        ...RuleFormRuleFieldsFragment
      }
    }
  }
  ${GQLRuleFormRuleFieldsFragmentFragmentDoc}
  ${GQLItemTypeFragmentFragmentDoc}
`;

/**
 * __useGQLRuleQuery__
 *
 * To run a query within a React component, call `useGQLRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLRuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLRuleQuery(
  baseOptions: Apollo.QueryHookOptions<GQLRuleQuery, GQLRuleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLRuleQuery, GQLRuleQueryVariables>(
    GQLRuleDocument,
    options,
  );
}
export function useGQLRuleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLRuleQuery,
    GQLRuleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQLRuleQuery, GQLRuleQueryVariables>(
    GQLRuleDocument,
    options,
  );
}
export type GQLRuleQueryHookResult = ReturnType<typeof useGQLRuleQuery>;
export type GQLRuleLazyQueryHookResult = ReturnType<typeof useGQLRuleLazyQuery>;
export type GQLRuleQueryResult = Apollo.QueryResult<
  GQLRuleQuery,
  GQLRuleQueryVariables
>;
export const GQLContentRuleFormConfigDocument = gql`
  query ContentRuleFormConfig {
    myOrg {
      policies {
        id
        name
        parentId
      }
      itemTypes {
        ...ItemTypeFragment
      }
      actions {
        ...ActionFragment
      }
      signals {
        ...SignalsFragment
      }
    }
    me {
      permissions
    }
  }
  ${GQLItemTypeFragmentFragmentDoc}
  ${GQLActionFragmentFragmentDoc}
  ${GQLSignalsFragmentFragmentDoc}
`;

/**
 * __useGQLContentRuleFormConfigQuery__
 *
 * To run a query within a React component, call `useGQLContentRuleFormConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLContentRuleFormConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLContentRuleFormConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLContentRuleFormConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLContentRuleFormConfigQuery,
    GQLContentRuleFormConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLContentRuleFormConfigQuery,
    GQLContentRuleFormConfigQueryVariables
  >(GQLContentRuleFormConfigDocument, options);
}
export function useGQLContentRuleFormConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLContentRuleFormConfigQuery,
    GQLContentRuleFormConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLContentRuleFormConfigQuery,
    GQLContentRuleFormConfigQueryVariables
  >(GQLContentRuleFormConfigDocument, options);
}
export type GQLContentRuleFormConfigQueryHookResult = ReturnType<
  typeof useGQLContentRuleFormConfigQuery
>;
export type GQLContentRuleFormConfigLazyQueryHookResult = ReturnType<
  typeof useGQLContentRuleFormConfigLazyQuery
>;
export type GQLContentRuleFormConfigQueryResult = Apollo.QueryResult<
  GQLContentRuleFormConfigQuery,
  GQLContentRuleFormConfigQueryVariables
>;
export const GQLCreateContentRuleDocument = gql`
  mutation CreateContentRule($input: CreateContentRuleInput!) {
    createContentRule(input: $input) {
      ... on MutateContentRuleSuccessResponse {
        data {
          id
        }
      }
      ... on Error {
        title
      }
    }
  }
`;
export type GQLCreateContentRuleMutationFn = Apollo.MutationFunction<
  GQLCreateContentRuleMutation,
  GQLCreateContentRuleMutationVariables
>;

/**
 * __useGQLCreateContentRuleMutation__
 *
 * To run a mutation, you first call `useGQLCreateContentRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLCreateContentRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCreateContentRuleMutation, { data, loading, error }] = useGQLCreateContentRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLCreateContentRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLCreateContentRuleMutation,
    GQLCreateContentRuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLCreateContentRuleMutation,
    GQLCreateContentRuleMutationVariables
  >(GQLCreateContentRuleDocument, options);
}
export type GQLCreateContentRuleMutationHookResult = ReturnType<
  typeof useGQLCreateContentRuleMutation
>;
export type GQLCreateContentRuleMutationResult =
  Apollo.MutationResult<GQLCreateContentRuleMutation>;
export type GQLCreateContentRuleMutationOptions = Apollo.BaseMutationOptions<
  GQLCreateContentRuleMutation,
  GQLCreateContentRuleMutationVariables
>;
export const GQLUpdateContentRuleDocument = gql`
  mutation UpdateContentRule($input: UpdateContentRuleInput!) {
    updateContentRule(input: $input) {
      ... on MutateContentRuleSuccessResponse {
        data {
          id
        }
      }
      ... on Error {
        title
      }
    }
  }
`;
export type GQLUpdateContentRuleMutationFn = Apollo.MutationFunction<
  GQLUpdateContentRuleMutation,
  GQLUpdateContentRuleMutationVariables
>;

/**
 * __useGQLUpdateContentRuleMutation__
 *
 * To run a mutation, you first call `useGQLUpdateContentRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLUpdateContentRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlUpdateContentRuleMutation, { data, loading, error }] = useGQLUpdateContentRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLUpdateContentRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLUpdateContentRuleMutation,
    GQLUpdateContentRuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLUpdateContentRuleMutation,
    GQLUpdateContentRuleMutationVariables
  >(GQLUpdateContentRuleDocument, options);
}
export type GQLUpdateContentRuleMutationHookResult = ReturnType<
  typeof useGQLUpdateContentRuleMutation
>;
export type GQLUpdateContentRuleMutationResult =
  Apollo.MutationResult<GQLUpdateContentRuleMutation>;
export type GQLUpdateContentRuleMutationOptions = Apollo.BaseMutationOptions<
  GQLUpdateContentRuleMutation,
  GQLUpdateContentRuleMutationVariables
>;
export const GQLCreateUserRuleDocument = gql`
  mutation CreateUserRule($input: CreateUserRuleInput!) {
    createUserRule(input: $input) {
      ... on MutateUserRuleSuccessResponse {
        data {
          id
        }
      }
      ... on Error {
        title
      }
    }
  }
`;
export type GQLCreateUserRuleMutationFn = Apollo.MutationFunction<
  GQLCreateUserRuleMutation,
  GQLCreateUserRuleMutationVariables
>;

/**
 * __useGQLCreateUserRuleMutation__
 *
 * To run a mutation, you first call `useGQLCreateUserRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLCreateUserRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCreateUserRuleMutation, { data, loading, error }] = useGQLCreateUserRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLCreateUserRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLCreateUserRuleMutation,
    GQLCreateUserRuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLCreateUserRuleMutation,
    GQLCreateUserRuleMutationVariables
  >(GQLCreateUserRuleDocument, options);
}
export type GQLCreateUserRuleMutationHookResult = ReturnType<
  typeof useGQLCreateUserRuleMutation
>;
export type GQLCreateUserRuleMutationResult =
  Apollo.MutationResult<GQLCreateUserRuleMutation>;
export type GQLCreateUserRuleMutationOptions = Apollo.BaseMutationOptions<
  GQLCreateUserRuleMutation,
  GQLCreateUserRuleMutationVariables
>;
export const GQLUpdateUserRuleDocument = gql`
  mutation UpdateUserRule($input: UpdateUserRuleInput!) {
    updateUserRule(input: $input) {
      ... on MutateUserRuleSuccessResponse {
        data {
          id
        }
      }
      ... on Error {
        title
      }
    }
  }
`;
export type GQLUpdateUserRuleMutationFn = Apollo.MutationFunction<
  GQLUpdateUserRuleMutation,
  GQLUpdateUserRuleMutationVariables
>;

/**
 * __useGQLUpdateUserRuleMutation__
 *
 * To run a mutation, you first call `useGQLUpdateUserRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLUpdateUserRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlUpdateUserRuleMutation, { data, loading, error }] = useGQLUpdateUserRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLUpdateUserRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLUpdateUserRuleMutation,
    GQLUpdateUserRuleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLUpdateUserRuleMutation,
    GQLUpdateUserRuleMutationVariables
  >(GQLUpdateUserRuleDocument, options);
}
export type GQLUpdateUserRuleMutationHookResult = ReturnType<
  typeof useGQLUpdateUserRuleMutation
>;
export type GQLUpdateUserRuleMutationResult =
  Apollo.MutationResult<GQLUpdateUserRuleMutation>;
export type GQLUpdateUserRuleMutationOptions = Apollo.BaseMutationOptions<
  GQLUpdateUserRuleMutation,
  GQLUpdateUserRuleMutationVariables
>;
export const GQLRecentUserStrikeActionsDocument = gql`
  query RecentUserStrikeActions($input: RecentUserStrikeActionsInput!) {
    recentUserStrikeActions(input: $input) {
      itemId
      itemTypeId
      actionId
      source
      time
    }
  }
`;

/**
 * __useGQLRecentUserStrikeActionsQuery__
 *
 * To run a query within a React component, call `useGQLRecentUserStrikeActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLRecentUserStrikeActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLRecentUserStrikeActionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLRecentUserStrikeActionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLRecentUserStrikeActionsQuery,
    GQLRecentUserStrikeActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLRecentUserStrikeActionsQuery,
    GQLRecentUserStrikeActionsQueryVariables
  >(GQLRecentUserStrikeActionsDocument, options);
}
export function useGQLRecentUserStrikeActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLRecentUserStrikeActionsQuery,
    GQLRecentUserStrikeActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLRecentUserStrikeActionsQuery,
    GQLRecentUserStrikeActionsQueryVariables
  >(GQLRecentUserStrikeActionsDocument, options);
}
export type GQLRecentUserStrikeActionsQueryHookResult = ReturnType<
  typeof useGQLRecentUserStrikeActionsQuery
>;
export type GQLRecentUserStrikeActionsLazyQueryHookResult = ReturnType<
  typeof useGQLRecentUserStrikeActionsLazyQuery
>;
export type GQLRecentUserStrikeActionsQueryResult = Apollo.QueryResult<
  GQLRecentUserStrikeActionsQuery,
  GQLRecentUserStrikeActionsQueryVariables
>;
export const GQLUserStrikeDistributionDocument = gql`
  query UserStrikeDistribution {
    getUserStrikeCountDistribution {
      numStrikes
      numUsers
    }
  }
`;

/**
 * __useGQLUserStrikeDistributionQuery__
 *
 * To run a query within a React component, call `useGQLUserStrikeDistributionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLUserStrikeDistributionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLUserStrikeDistributionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLUserStrikeDistributionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLUserStrikeDistributionQuery,
    GQLUserStrikeDistributionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLUserStrikeDistributionQuery,
    GQLUserStrikeDistributionQueryVariables
  >(GQLUserStrikeDistributionDocument, options);
}
export function useGQLUserStrikeDistributionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLUserStrikeDistributionQuery,
    GQLUserStrikeDistributionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLUserStrikeDistributionQuery,
    GQLUserStrikeDistributionQueryVariables
  >(GQLUserStrikeDistributionDocument, options);
}
export type GQLUserStrikeDistributionQueryHookResult = ReturnType<
  typeof useGQLUserStrikeDistributionQuery
>;
export type GQLUserStrikeDistributionLazyQueryHookResult = ReturnType<
  typeof useGQLUserStrikeDistributionLazyQuery
>;
export type GQLUserStrikeDistributionQueryResult = Apollo.QueryResult<
  GQLUserStrikeDistributionQuery,
  GQLUserStrikeDistributionQueryVariables
>;
export const GQLUserStrikeThresholdsDocument = gql`
  query UserStrikeThresholds {
    myOrg {
      userStrikeThresholds {
        id
        threshold
        actions
      }
      userStrikeTTL
    }
  }
`;

/**
 * __useGQLUserStrikeThresholdsQuery__
 *
 * To run a query within a React component, call `useGQLUserStrikeThresholdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLUserStrikeThresholdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLUserStrikeThresholdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLUserStrikeThresholdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLUserStrikeThresholdsQuery,
    GQLUserStrikeThresholdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLUserStrikeThresholdsQuery,
    GQLUserStrikeThresholdsQueryVariables
  >(GQLUserStrikeThresholdsDocument, options);
}
export function useGQLUserStrikeThresholdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLUserStrikeThresholdsQuery,
    GQLUserStrikeThresholdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLUserStrikeThresholdsQuery,
    GQLUserStrikeThresholdsQueryVariables
  >(GQLUserStrikeThresholdsDocument, options);
}
export type GQLUserStrikeThresholdsQueryHookResult = ReturnType<
  typeof useGQLUserStrikeThresholdsQuery
>;
export type GQLUserStrikeThresholdsLazyQueryHookResult = ReturnType<
  typeof useGQLUserStrikeThresholdsLazyQuery
>;
export type GQLUserStrikeThresholdsQueryResult = Apollo.QueryResult<
  GQLUserStrikeThresholdsQuery,
  GQLUserStrikeThresholdsQueryVariables
>;
export const GQLSetAllUserStrikeThresholdDocument = gql`
  mutation SetAllUserStrikeThreshold($input: SetAllUserStrikeThresholdsInput!) {
    setAllUserStrikeThresholds(input: $input) {
      ... on SetAllUserStrikeThresholdsSuccessResponse {
        _
      }
    }
  }
`;
export type GQLSetAllUserStrikeThresholdMutationFn = Apollo.MutationFunction<
  GQLSetAllUserStrikeThresholdMutation,
  GQLSetAllUserStrikeThresholdMutationVariables
>;

/**
 * __useGQLSetAllUserStrikeThresholdMutation__
 *
 * To run a mutation, you first call `useGQLSetAllUserStrikeThresholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLSetAllUserStrikeThresholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlSetAllUserStrikeThresholdMutation, { data, loading, error }] = useGQLSetAllUserStrikeThresholdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLSetAllUserStrikeThresholdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLSetAllUserStrikeThresholdMutation,
    GQLSetAllUserStrikeThresholdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLSetAllUserStrikeThresholdMutation,
    GQLSetAllUserStrikeThresholdMutationVariables
  >(GQLSetAllUserStrikeThresholdDocument, options);
}
export type GQLSetAllUserStrikeThresholdMutationHookResult = ReturnType<
  typeof useGQLSetAllUserStrikeThresholdMutation
>;
export type GQLSetAllUserStrikeThresholdMutationResult =
  Apollo.MutationResult<GQLSetAllUserStrikeThresholdMutation>;
export type GQLSetAllUserStrikeThresholdMutationOptions =
  Apollo.BaseMutationOptions<
    GQLSetAllUserStrikeThresholdMutation,
    GQLSetAllUserStrikeThresholdMutationVariables
  >;
export const GQLUpdateUserStrikeTtlDocument = gql`
  mutation UpdateUserStrikeTTL($input: UpdateUserStrikeTTLInput!) {
    updateUserStrikeTTL(input: $input) {
      ... on UpdateUserStrikeTTLSuccessResponse {
        _
      }
    }
  }
`;
export type GQLUpdateUserStrikeTtlMutationFn = Apollo.MutationFunction<
  GQLUpdateUserStrikeTtlMutation,
  GQLUpdateUserStrikeTtlMutationVariables
>;

/**
 * __useGQLUpdateUserStrikeTtlMutation__
 *
 * To run a mutation, you first call `useGQLUpdateUserStrikeTtlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLUpdateUserStrikeTtlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlUpdateUserStrikeTtlMutation, { data, loading, error }] = useGQLUpdateUserStrikeTtlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLUpdateUserStrikeTtlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLUpdateUserStrikeTtlMutation,
    GQLUpdateUserStrikeTtlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLUpdateUserStrikeTtlMutation,
    GQLUpdateUserStrikeTtlMutationVariables
  >(GQLUpdateUserStrikeTtlDocument, options);
}
export type GQLUpdateUserStrikeTtlMutationHookResult = ReturnType<
  typeof useGQLUpdateUserStrikeTtlMutation
>;
export type GQLUpdateUserStrikeTtlMutationResult =
  Apollo.MutationResult<GQLUpdateUserStrikeTtlMutation>;
export type GQLUpdateUserStrikeTtlMutationOptions = Apollo.BaseMutationOptions<
  GQLUpdateUserStrikeTtlMutation,
  GQLUpdateUserStrikeTtlMutationVariables
>;
export const GQLNavigationBarAuthInfoDocument = gql`
  query NavigationBarAuthInfo {
    me {
      id
      email
      firstName
      lastName
      readMeJWT
    }
  }
`;

/**
 * __useGQLNavigationBarAuthInfoQuery__
 *
 * To run a query within a React component, call `useGQLNavigationBarAuthInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLNavigationBarAuthInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLNavigationBarAuthInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLNavigationBarAuthInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLNavigationBarAuthInfoQuery,
    GQLNavigationBarAuthInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLNavigationBarAuthInfoQuery,
    GQLNavigationBarAuthInfoQueryVariables
  >(GQLNavigationBarAuthInfoDocument, options);
}
export function useGQLNavigationBarAuthInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLNavigationBarAuthInfoQuery,
    GQLNavigationBarAuthInfoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLNavigationBarAuthInfoQuery,
    GQLNavigationBarAuthInfoQueryVariables
  >(GQLNavigationBarAuthInfoDocument, options);
}
export type GQLNavigationBarAuthInfoQueryHookResult = ReturnType<
  typeof useGQLNavigationBarAuthInfoQuery
>;
export type GQLNavigationBarAuthInfoLazyQueryHookResult = ReturnType<
  typeof useGQLNavigationBarAuthInfoLazyQuery
>;
export type GQLNavigationBarAuthInfoQueryResult = Apollo.QueryResult<
  GQLNavigationBarAuthInfoQuery,
  GQLNavigationBarAuthInfoQueryVariables
>;
export const GQLRequestDemoDocument = gql`
  mutation RequestDemo($input: RequestDemoInput!) {
    requestDemo(input: $input)
  }
`;
export type GQLRequestDemoMutationFn = Apollo.MutationFunction<
  GQLRequestDemoMutation,
  GQLRequestDemoMutationVariables
>;

/**
 * __useGQLRequestDemoMutation__
 *
 * To run a mutation, you first call `useGQLRequestDemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLRequestDemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlRequestDemoMutation, { data, loading, error }] = useGQLRequestDemoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLRequestDemoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLRequestDemoMutation,
    GQLRequestDemoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLRequestDemoMutation,
    GQLRequestDemoMutationVariables
  >(GQLRequestDemoDocument, options);
}
export type GQLRequestDemoMutationHookResult = ReturnType<
  typeof useGQLRequestDemoMutation
>;
export type GQLRequestDemoMutationResult =
  Apollo.MutationResult<GQLRequestDemoMutation>;
export type GQLRequestDemoMutationOptions = Apollo.BaseMutationOptions<
  GQLRequestDemoMutation,
  GQLRequestDemoMutationVariables
>;
export const GQLInternalModelTrainingPageDataDocument = gql`
  query InternalModelTrainingPageData {
    myOrg {
      id
      email
      name
      policies {
        id
        name
      }
    }
    me {
      id
      email
    }
  }
`;

/**
 * __useGQLInternalModelTrainingPageDataQuery__
 *
 * To run a query within a React component, call `useGQLInternalModelTrainingPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLInternalModelTrainingPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLInternalModelTrainingPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLInternalModelTrainingPageDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLInternalModelTrainingPageDataQuery,
    GQLInternalModelTrainingPageDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLInternalModelTrainingPageDataQuery,
    GQLInternalModelTrainingPageDataQueryVariables
  >(GQLInternalModelTrainingPageDataDocument, options);
}
export function useGQLInternalModelTrainingPageDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLInternalModelTrainingPageDataQuery,
    GQLInternalModelTrainingPageDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLInternalModelTrainingPageDataQuery,
    GQLInternalModelTrainingPageDataQueryVariables
  >(GQLInternalModelTrainingPageDataDocument, options);
}
export type GQLInternalModelTrainingPageDataQueryHookResult = ReturnType<
  typeof useGQLInternalModelTrainingPageDataQuery
>;
export type GQLInternalModelTrainingPageDataLazyQueryHookResult = ReturnType<
  typeof useGQLInternalModelTrainingPageDataLazyQuery
>;
export type GQLInternalModelTrainingPageDataQueryResult = Apollo.QueryResult<
  GQLInternalModelTrainingPageDataQuery,
  GQLInternalModelTrainingPageDataQueryVariables
>;
export const GQLGetLabelsForPolicyDocument = gql`
  query GetLabelsForPolicy($input: GetLabelsForPolicyInput!) {
    getLabelsForPolicy(input: $input) {
      items {
        id
        data
        itemType {
          id
          version
          schemaVariant
        }
      }
      labels {
        id
        itemId
        itemFieldJsonPointers
        violatesPolicy
        labelerId
        labelerType
      }
    }
  }
`;

/**
 * __useGQLGetLabelsForPolicyQuery__
 *
 * To run a query within a React component, call `useGQLGetLabelsForPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetLabelsForPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetLabelsForPolicyQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLGetLabelsForPolicyQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQLGetLabelsForPolicyQuery,
    GQLGetLabelsForPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetLabelsForPolicyQuery,
    GQLGetLabelsForPolicyQueryVariables
  >(GQLGetLabelsForPolicyDocument, options);
}
export function useGQLGetLabelsForPolicyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetLabelsForPolicyQuery,
    GQLGetLabelsForPolicyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetLabelsForPolicyQuery,
    GQLGetLabelsForPolicyQueryVariables
  >(GQLGetLabelsForPolicyDocument, options);
}
export type GQLGetLabelsForPolicyQueryHookResult = ReturnType<
  typeof useGQLGetLabelsForPolicyQuery
>;
export type GQLGetLabelsForPolicyLazyQueryHookResult = ReturnType<
  typeof useGQLGetLabelsForPolicyLazyQuery
>;
export type GQLGetLabelsForPolicyQueryResult = Apollo.QueryResult<
  GQLGetLabelsForPolicyQuery,
  GQLGetLabelsForPolicyQueryVariables
>;
export const GQLSetLabelsForItemsDocument = gql`
  mutation SetLabelsForItems($input: SetLabelsForItemsInput!) {
    setLabelsForItems(input: $input)
  }
`;
export type GQLSetLabelsForItemsMutationFn = Apollo.MutationFunction<
  GQLSetLabelsForItemsMutation,
  GQLSetLabelsForItemsMutationVariables
>;

/**
 * __useGQLSetLabelsForItemsMutation__
 *
 * To run a mutation, you first call `useGQLSetLabelsForItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLSetLabelsForItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlSetLabelsForItemsMutation, { data, loading, error }] = useGQLSetLabelsForItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLSetLabelsForItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLSetLabelsForItemsMutation,
    GQLSetLabelsForItemsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLSetLabelsForItemsMutation,
    GQLSetLabelsForItemsMutationVariables
  >(GQLSetLabelsForItemsDocument, options);
}
export type GQLSetLabelsForItemsMutationHookResult = ReturnType<
  typeof useGQLSetLabelsForItemsMutation
>;
export type GQLSetLabelsForItemsMutationResult =
  Apollo.MutationResult<GQLSetLabelsForItemsMutation>;
export type GQLSetLabelsForItemsMutationOptions = Apollo.BaseMutationOptions<
  GQLSetLabelsForItemsMutation,
  GQLSetLabelsForItemsMutationVariables
>;
export const GQLDeleteLabelsDocument = gql`
  mutation DeleteLabels($labelIds: [ID!]!) {
    deleteLabels(labelIds: $labelIds)
  }
`;
export type GQLDeleteLabelsMutationFn = Apollo.MutationFunction<
  GQLDeleteLabelsMutation,
  GQLDeleteLabelsMutationVariables
>;

/**
 * __useGQLDeleteLabelsMutation__
 *
 * To run a mutation, you first call `useGQLDeleteLabelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLDeleteLabelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlDeleteLabelsMutation, { data, loading, error }] = useGQLDeleteLabelsMutation({
 *   variables: {
 *      labelIds: // value for 'labelIds'
 *   },
 * });
 */
export function useGQLDeleteLabelsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLDeleteLabelsMutation,
    GQLDeleteLabelsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLDeleteLabelsMutation,
    GQLDeleteLabelsMutationVariables
  >(GQLDeleteLabelsDocument, options);
}
export type GQLDeleteLabelsMutationHookResult = ReturnType<
  typeof useGQLDeleteLabelsMutation
>;
export type GQLDeleteLabelsMutationResult =
  Apollo.MutationResult<GQLDeleteLabelsMutation>;
export type GQLDeleteLabelsMutationOptions = Apollo.BaseMutationOptions<
  GQLDeleteLabelsMutation,
  GQLDeleteLabelsMutationVariables
>;
export const GQLAccountSettingsDocument = gql`
  query AccountSettings {
    me {
      id
      email
      firstName
      lastName
    }
  }
`;

/**
 * __useGQLAccountSettingsQuery__
 *
 * To run a query within a React component, call `useGQLAccountSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLAccountSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLAccountSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLAccountSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLAccountSettingsQuery,
    GQLAccountSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLAccountSettingsQuery,
    GQLAccountSettingsQueryVariables
  >(GQLAccountSettingsDocument, options);
}
export function useGQLAccountSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLAccountSettingsQuery,
    GQLAccountSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLAccountSettingsQuery,
    GQLAccountSettingsQueryVariables
  >(GQLAccountSettingsDocument, options);
}
export type GQLAccountSettingsQueryHookResult = ReturnType<
  typeof useGQLAccountSettingsQuery
>;
export type GQLAccountSettingsLazyQueryHookResult = ReturnType<
  typeof useGQLAccountSettingsLazyQuery
>;
export type GQLAccountSettingsQueryResult = Apollo.QueryResult<
  GQLAccountSettingsQuery,
  GQLAccountSettingsQueryVariables
>;
export const GQLPersonalSafetySettingsDocument = gql`
  query PersonalSafetySettings {
    me {
      interfacePreferences {
        moderatorSafetyMuteVideo
        moderatorSafetyGrayscale
        moderatorSafetyBlurLevel
      }
    }
  }
`;

/**
 * __useGQLPersonalSafetySettingsQuery__
 *
 * To run a query within a React component, call `useGQLPersonalSafetySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLPersonalSafetySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLPersonalSafetySettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLPersonalSafetySettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLPersonalSafetySettingsQuery,
    GQLPersonalSafetySettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLPersonalSafetySettingsQuery,
    GQLPersonalSafetySettingsQueryVariables
  >(GQLPersonalSafetySettingsDocument, options);
}
export function useGQLPersonalSafetySettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLPersonalSafetySettingsQuery,
    GQLPersonalSafetySettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLPersonalSafetySettingsQuery,
    GQLPersonalSafetySettingsQueryVariables
  >(GQLPersonalSafetySettingsDocument, options);
}
export type GQLPersonalSafetySettingsQueryHookResult = ReturnType<
  typeof useGQLPersonalSafetySettingsQuery
>;
export type GQLPersonalSafetySettingsLazyQueryHookResult = ReturnType<
  typeof useGQLPersonalSafetySettingsLazyQuery
>;
export type GQLPersonalSafetySettingsQueryResult = Apollo.QueryResult<
  GQLPersonalSafetySettingsQuery,
  GQLPersonalSafetySettingsQueryVariables
>;
export const GQLUpdateAccountInfoDocument = gql`
  mutation UpdateAccountInfo($firstName: String, $lastName: String) {
    updateAccountInfo(firstName: $firstName, lastName: $lastName)
  }
`;
export type GQLUpdateAccountInfoMutationFn = Apollo.MutationFunction<
  GQLUpdateAccountInfoMutation,
  GQLUpdateAccountInfoMutationVariables
>;

/**
 * __useGQLUpdateAccountInfoMutation__
 *
 * To run a mutation, you first call `useGQLUpdateAccountInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLUpdateAccountInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlUpdateAccountInfoMutation, { data, loading, error }] = useGQLUpdateAccountInfoMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useGQLUpdateAccountInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLUpdateAccountInfoMutation,
    GQLUpdateAccountInfoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLUpdateAccountInfoMutation,
    GQLUpdateAccountInfoMutationVariables
  >(GQLUpdateAccountInfoDocument, options);
}
export type GQLUpdateAccountInfoMutationHookResult = ReturnType<
  typeof useGQLUpdateAccountInfoMutation
>;
export type GQLUpdateAccountInfoMutationResult =
  Apollo.MutationResult<GQLUpdateAccountInfoMutation>;
export type GQLUpdateAccountInfoMutationOptions = Apollo.BaseMutationOptions<
  GQLUpdateAccountInfoMutation,
  GQLUpdateAccountInfoMutationVariables
>;
export const GQLApiKeyDocument = gql`
  query ApiKey {
    myOrg {
      apiKey
      publicSigningKey
    }
    me {
      permissions
    }
  }
`;

/**
 * __useGQLApiKeyQuery__
 *
 * To run a query within a React component, call `useGQLApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLApiKeyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLApiKeyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLApiKeyQuery,
    GQLApiKeyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLApiKeyQuery, GQLApiKeyQueryVariables>(
    GQLApiKeyDocument,
    options,
  );
}
export function useGQLApiKeyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLApiKeyQuery,
    GQLApiKeyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQLApiKeyQuery, GQLApiKeyQueryVariables>(
    GQLApiKeyDocument,
    options,
  );
}
export type GQLApiKeyQueryHookResult = ReturnType<typeof useGQLApiKeyQuery>;
export type GQLApiKeyLazyQueryHookResult = ReturnType<
  typeof useGQLApiKeyLazyQuery
>;
export type GQLApiKeyQueryResult = Apollo.QueryResult<
  GQLApiKeyQuery,
  GQLApiKeyQueryVariables
>;
export const GQLCancelSubscriptionDocument = gql`
  mutation CancelSubscription {
    cancelSubscription
  }
`;
export type GQLCancelSubscriptionMutationFn = Apollo.MutationFunction<
  GQLCancelSubscriptionMutation,
  GQLCancelSubscriptionMutationVariables
>;

/**
 * __useGQLCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useGQLCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCancelSubscriptionMutation, { data, loading, error }] = useGQLCancelSubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useGQLCancelSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLCancelSubscriptionMutation,
    GQLCancelSubscriptionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLCancelSubscriptionMutation,
    GQLCancelSubscriptionMutationVariables
  >(GQLCancelSubscriptionDocument, options);
}
export type GQLCancelSubscriptionMutationHookResult = ReturnType<
  typeof useGQLCancelSubscriptionMutation
>;
export type GQLCancelSubscriptionMutationResult =
  Apollo.MutationResult<GQLCancelSubscriptionMutation>;
export type GQLCancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  GQLCancelSubscriptionMutation,
  GQLCancelSubscriptionMutationVariables
>;
export const GQLManageUsersDocument = gql`
  query ManageUsers {
    myOrg {
      id
      name
      hasNCMECReportingEnabled
      users {
        id
        firstName
        lastName
        email
        role
        createdAt
        approvedByAdmin
        rejectedByAdmin
      }
    }
    me {
      id
      email
      firstName
      lastName
      permissions
    }
  }
`;

/**
 * __useGQLManageUsersQuery__
 *
 * To run a query within a React component, call `useGQLManageUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLManageUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLManageUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLManageUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLManageUsersQuery,
    GQLManageUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQLManageUsersQuery, GQLManageUsersQueryVariables>(
    GQLManageUsersDocument,
    options,
  );
}
export function useGQLManageUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLManageUsersQuery,
    GQLManageUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQLManageUsersQuery, GQLManageUsersQueryVariables>(
    GQLManageUsersDocument,
    options,
  );
}
export type GQLManageUsersQueryHookResult = ReturnType<
  typeof useGQLManageUsersQuery
>;
export type GQLManageUsersLazyQueryHookResult = ReturnType<
  typeof useGQLManageUsersLazyQuery
>;
export type GQLManageUsersQueryResult = Apollo.QueryResult<
  GQLManageUsersQuery,
  GQLManageUsersQueryVariables
>;
export const GQLDeleteUserDocument = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;
export type GQLDeleteUserMutationFn = Apollo.MutationFunction<
  GQLDeleteUserMutation,
  GQLDeleteUserMutationVariables
>;

/**
 * __useGQLDeleteUserMutation__
 *
 * To run a mutation, you first call `useGQLDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlDeleteUserMutation, { data, loading, error }] = useGQLDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLDeleteUserMutation,
    GQLDeleteUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLDeleteUserMutation,
    GQLDeleteUserMutationVariables
  >(GQLDeleteUserDocument, options);
}
export type GQLDeleteUserMutationHookResult = ReturnType<
  typeof useGQLDeleteUserMutation
>;
export type GQLDeleteUserMutationResult =
  Apollo.MutationResult<GQLDeleteUserMutation>;
export type GQLDeleteUserMutationOptions = Apollo.BaseMutationOptions<
  GQLDeleteUserMutation,
  GQLDeleteUserMutationVariables
>;
export const GQLUpdateRoleDocument = gql`
  mutation UpdateRole($input: UpdateRoleInput!) {
    updateRole(input: $input)
  }
`;
export type GQLUpdateRoleMutationFn = Apollo.MutationFunction<
  GQLUpdateRoleMutation,
  GQLUpdateRoleMutationVariables
>;

/**
 * __useGQLUpdateRoleMutation__
 *
 * To run a mutation, you first call `useGQLUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlUpdateRoleMutation, { data, loading, error }] = useGQLUpdateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLUpdateRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLUpdateRoleMutation,
    GQLUpdateRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLUpdateRoleMutation,
    GQLUpdateRoleMutationVariables
  >(GQLUpdateRoleDocument, options);
}
export type GQLUpdateRoleMutationHookResult = ReturnType<
  typeof useGQLUpdateRoleMutation
>;
export type GQLUpdateRoleMutationResult =
  Apollo.MutationResult<GQLUpdateRoleMutation>;
export type GQLUpdateRoleMutationOptions = Apollo.BaseMutationOptions<
  GQLUpdateRoleMutation,
  GQLUpdateRoleMutationVariables
>;
export const GQLApproveUserDocument = gql`
  mutation ApproveUser($id: ID!) {
    approveUser(id: $id)
  }
`;
export type GQLApproveUserMutationFn = Apollo.MutationFunction<
  GQLApproveUserMutation,
  GQLApproveUserMutationVariables
>;

/**
 * __useGQLApproveUserMutation__
 *
 * To run a mutation, you first call `useGQLApproveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLApproveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlApproveUserMutation, { data, loading, error }] = useGQLApproveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLApproveUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLApproveUserMutation,
    GQLApproveUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLApproveUserMutation,
    GQLApproveUserMutationVariables
  >(GQLApproveUserDocument, options);
}
export type GQLApproveUserMutationHookResult = ReturnType<
  typeof useGQLApproveUserMutation
>;
export type GQLApproveUserMutationResult =
  Apollo.MutationResult<GQLApproveUserMutation>;
export type GQLApproveUserMutationOptions = Apollo.BaseMutationOptions<
  GQLApproveUserMutation,
  GQLApproveUserMutationVariables
>;
export const GQLRejectUserDocument = gql`
  mutation RejectUser($id: ID!) {
    rejectUser(id: $id)
  }
`;
export type GQLRejectUserMutationFn = Apollo.MutationFunction<
  GQLRejectUserMutation,
  GQLRejectUserMutationVariables
>;

/**
 * __useGQLRejectUserMutation__
 *
 * To run a mutation, you first call `useGQLRejectUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLRejectUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlRejectUserMutation, { data, loading, error }] = useGQLRejectUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGQLRejectUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLRejectUserMutation,
    GQLRejectUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLRejectUserMutation,
    GQLRejectUserMutationVariables
  >(GQLRejectUserDocument, options);
}
export type GQLRejectUserMutationHookResult = ReturnType<
  typeof useGQLRejectUserMutation
>;
export type GQLRejectUserMutationResult =
  Apollo.MutationResult<GQLRejectUserMutation>;
export type GQLRejectUserMutationOptions = Apollo.BaseMutationOptions<
  GQLRejectUserMutation,
  GQLRejectUserMutationVariables
>;
export const GQLHasNcmecReportingEnabledDocument = gql`
  query HasNcmecReportingEnabled {
    myOrg {
      hasNCMECReportingEnabled
    }
  }
`;

/**
 * __useGQLHasNcmecReportingEnabledQuery__
 *
 * To run a query within a React component, call `useGQLHasNcmecReportingEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLHasNcmecReportingEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLHasNcmecReportingEnabledQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLHasNcmecReportingEnabledQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLHasNcmecReportingEnabledQuery,
    GQLHasNcmecReportingEnabledQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLHasNcmecReportingEnabledQuery,
    GQLHasNcmecReportingEnabledQueryVariables
  >(GQLHasNcmecReportingEnabledDocument, options);
}
export function useGQLHasNcmecReportingEnabledLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLHasNcmecReportingEnabledQuery,
    GQLHasNcmecReportingEnabledQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLHasNcmecReportingEnabledQuery,
    GQLHasNcmecReportingEnabledQueryVariables
  >(GQLHasNcmecReportingEnabledDocument, options);
}
export type GQLHasNcmecReportingEnabledQueryHookResult = ReturnType<
  typeof useGQLHasNcmecReportingEnabledQuery
>;
export type GQLHasNcmecReportingEnabledLazyQueryHookResult = ReturnType<
  typeof useGQLHasNcmecReportingEnabledLazyQuery
>;
export type GQLHasNcmecReportingEnabledQueryResult = Apollo.QueryResult<
  GQLHasNcmecReportingEnabledQuery,
  GQLHasNcmecReportingEnabledQueryVariables
>;
export const GQLInviteUserDocument = gql`
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input)
  }
`;
export type GQLInviteUserMutationFn = Apollo.MutationFunction<
  GQLInviteUserMutation,
  GQLInviteUserMutationVariables
>;

/**
 * __useGQLInviteUserMutation__
 *
 * To run a mutation, you first call `useGQLInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlInviteUserMutation, { data, loading, error }] = useGQLInviteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLInviteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLInviteUserMutation,
    GQLInviteUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLInviteUserMutation,
    GQLInviteUserMutationVariables
  >(GQLInviteUserDocument, options);
}
export type GQLInviteUserMutationHookResult = ReturnType<
  typeof useGQLInviteUserMutation
>;
export type GQLInviteUserMutationResult =
  Apollo.MutationResult<GQLInviteUserMutation>;
export type GQLInviteUserMutationOptions = Apollo.BaseMutationOptions<
  GQLInviteUserMutation,
  GQLInviteUserMutationVariables
>;
export const GQLOrgDefaultSafetySettingsDocument = gql`
  query OrgDefaultSafetySettings {
    myOrg {
      defaultInterfacePreferences {
        moderatorSafetyMuteVideo
        moderatorSafetyGrayscale
        moderatorSafetyBlurLevel
      }
    }
  }
`;

/**
 * __useGQLOrgDefaultSafetySettingsQuery__
 *
 * To run a query within a React component, call `useGQLOrgDefaultSafetySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLOrgDefaultSafetySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLOrgDefaultSafetySettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLOrgDefaultSafetySettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLOrgDefaultSafetySettingsQuery,
    GQLOrgDefaultSafetySettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLOrgDefaultSafetySettingsQuery,
    GQLOrgDefaultSafetySettingsQueryVariables
  >(GQLOrgDefaultSafetySettingsDocument, options);
}
export function useGQLOrgDefaultSafetySettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLOrgDefaultSafetySettingsQuery,
    GQLOrgDefaultSafetySettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLOrgDefaultSafetySettingsQuery,
    GQLOrgDefaultSafetySettingsQueryVariables
  >(GQLOrgDefaultSafetySettingsDocument, options);
}
export type GQLOrgDefaultSafetySettingsQueryHookResult = ReturnType<
  typeof useGQLOrgDefaultSafetySettingsQuery
>;
export type GQLOrgDefaultSafetySettingsLazyQueryHookResult = ReturnType<
  typeof useGQLOrgDefaultSafetySettingsLazyQuery
>;
export type GQLOrgDefaultSafetySettingsQueryResult = Apollo.QueryResult<
  GQLOrgDefaultSafetySettingsQuery,
  GQLOrgDefaultSafetySettingsQueryVariables
>;
export const GQLSetOrgDefaultSafetySettingsDocument = gql`
  mutation SetOrgDefaultSafetySettings(
    $orgDefaultSafetySettings: ModeratorSafetySettingsInput!
  ) {
    setOrgDefaultSafetySettings(
      orgDefaultSafetySettings: $orgDefaultSafetySettings
    ) {
      _
    }
  }
`;
export type GQLSetOrgDefaultSafetySettingsMutationFn = Apollo.MutationFunction<
  GQLSetOrgDefaultSafetySettingsMutation,
  GQLSetOrgDefaultSafetySettingsMutationVariables
>;

/**
 * __useGQLSetOrgDefaultSafetySettingsMutation__
 *
 * To run a mutation, you first call `useGQLSetOrgDefaultSafetySettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLSetOrgDefaultSafetySettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlSetOrgDefaultSafetySettingsMutation, { data, loading, error }] = useGQLSetOrgDefaultSafetySettingsMutation({
 *   variables: {
 *      orgDefaultSafetySettings: // value for 'orgDefaultSafetySettings'
 *   },
 * });
 */
export function useGQLSetOrgDefaultSafetySettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLSetOrgDefaultSafetySettingsMutation,
    GQLSetOrgDefaultSafetySettingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLSetOrgDefaultSafetySettingsMutation,
    GQLSetOrgDefaultSafetySettingsMutationVariables
  >(GQLSetOrgDefaultSafetySettingsDocument, options);
}
export type GQLSetOrgDefaultSafetySettingsMutationHookResult = ReturnType<
  typeof useGQLSetOrgDefaultSafetySettingsMutation
>;
export type GQLSetOrgDefaultSafetySettingsMutationResult =
  Apollo.MutationResult<GQLSetOrgDefaultSafetySettingsMutation>;
export type GQLSetOrgDefaultSafetySettingsMutationOptions =
  Apollo.BaseMutationOptions<
    GQLSetOrgDefaultSafetySettingsMutation,
    GQLSetOrgDefaultSafetySettingsMutationVariables
  >;
export const GQLGetSsoCredentialsDocument = gql`
  query GetSSOCredentials {
    me {
      permissions
    }
    myOrg {
      id
      ssoUrl
      ssoCert
    }
  }
`;

/**
 * __useGQLGetSsoCredentialsQuery__
 *
 * To run a query within a React component, call `useGQLGetSsoCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGQLGetSsoCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGQLGetSsoCredentialsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGQLGetSsoCredentialsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQLGetSsoCredentialsQuery,
    GQLGetSsoCredentialsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQLGetSsoCredentialsQuery,
    GQLGetSsoCredentialsQueryVariables
  >(GQLGetSsoCredentialsDocument, options);
}
export function useGQLGetSsoCredentialsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQLGetSsoCredentialsQuery,
    GQLGetSsoCredentialsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQLGetSsoCredentialsQuery,
    GQLGetSsoCredentialsQueryVariables
  >(GQLGetSsoCredentialsDocument, options);
}
export type GQLGetSsoCredentialsQueryHookResult = ReturnType<
  typeof useGQLGetSsoCredentialsQuery
>;
export type GQLGetSsoCredentialsLazyQueryHookResult = ReturnType<
  typeof useGQLGetSsoCredentialsLazyQuery
>;
export type GQLGetSsoCredentialsQueryResult = Apollo.QueryResult<
  GQLGetSsoCredentialsQuery,
  GQLGetSsoCredentialsQueryVariables
>;
export const GQLUpdateSsoCredentialsDocument = gql`
  mutation UpdateSSOCredentials($input: UpdateSSOCredentialsInput!) {
    updateSSOCredentials(input: $input)
  }
`;
export type GQLUpdateSsoCredentialsMutationFn = Apollo.MutationFunction<
  GQLUpdateSsoCredentialsMutation,
  GQLUpdateSsoCredentialsMutationVariables
>;

/**
 * __useGQLUpdateSsoCredentialsMutation__
 *
 * To run a mutation, you first call `useGQLUpdateSsoCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGQLUpdateSsoCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlUpdateSsoCredentialsMutation, { data, loading, error }] = useGQLUpdateSsoCredentialsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGQLUpdateSsoCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQLUpdateSsoCredentialsMutation,
    GQLUpdateSsoCredentialsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQLUpdateSsoCredentialsMutation,
    GQLUpdateSsoCredentialsMutationVariables
  >(GQLUpdateSsoCredentialsDocument, options);
}
export type GQLUpdateSsoCredentialsMutationHookResult = ReturnType<
  typeof useGQLUpdateSsoCredentialsMutation
>;
export type GQLUpdateSsoCredentialsMutationResult =
  Apollo.MutationResult<GQLUpdateSsoCredentialsMutation>;
export type GQLUpdateSsoCredentialsMutationOptions = Apollo.BaseMutationOptions<
  GQLUpdateSsoCredentialsMutation,
  GQLUpdateSsoCredentialsMutationVariables
>;
export const namedOperations = {
  Query: {
    UserAndOrg: 'UserAndOrg',
    LoggedInUserForRoute: 'LoggedInUserForRoute',
    PermissionGatedRouteLoggedInUser: 'PermissionGatedRouteLoggedInUser',
    GetSSORedirectUrl: 'GetSSORedirectUrl',
    RejectedUser: 'RejectedUser',
    AllOrgs: 'AllOrgs',
    InviteUserToken: 'InviteUserToken',
    DashboardOrg: 'DashboardOrg',
    Action: 'Action',
    ActionForm: 'ActionForm',
    Actions: 'Actions',
    LocationBank: 'LocationBank',
    LocationBankForm: 'LocationBankForm',
    LocationBanks: 'LocationBanks',
    TextBank: 'TextBank',
    TextBankForm: 'TextBankForm',
    TextBanks: 'TextBanks',
    BulkActionsFormData: 'BulkActionsFormData',
    MatchingBankIds: 'MatchingBankIds',
    IntegrationConfig: 'IntegrationConfig',
    MyIntegrations: 'MyIntegrations',
    InvestigationItemTypes: 'InvestigationItemTypes',
    GetOrgData: 'GetOrgData',
    GetItemsWithId: 'GetItemsWithId',
    InvestigationItems: 'InvestigationItems',
    GetAuthorInfo: 'GetAuthorInfo',
    ItemType: 'ItemType',
    ItemTypeFormOrg: 'ItemTypeFormOrg',
    ItemTypes: 'ItemTypes',
    ItemActionHistory: 'ItemActionHistory',
    getDecidedJobFromJobId: 'getDecidedJobFromJobId',
    SpotTestModel: 'SpotTestModel',
    LatestSuccessfulSamplingJob: 'LatestSuccessfulSamplingJob',
    GetLiveModelForPolicy: 'GetLiveModelForPolicy',
    GetComparisonSamplingJobResults: 'GetComparisonSamplingJobResults',
    GetModelVersionsWithDetails: 'GetModelVersionsWithDetails',
    ModelsDashboard: 'ModelsDashboard',
    fetchSignedUrl: 'fetchSignedUrl',
    GetSamplesForLabeling: 'GetSamplesForLabeling',
    GetRedditExamples: 'GetRedditExamples',
    OrgDataForModelTraining: 'OrgDataForModelTraining',
    GetItemTypesByIdentifiers: 'GetItemTypesByIdentifiers',
    GenerateDefaultPolicyDefinition: 'GenerateDefaultPolicyDefinition',
    GetLabelCountForPolicy: 'GetLabelCountForPolicy',
    GetModels: 'GetModels',
    GetSamplingJobResults: 'GetSamplingJobResults',
    GetCoveModelSignalCreationSessionResult:
      'GetCoveModelSignalCreationSessionResult',
    ManualReviewMetrics: 'ManualReviewMetrics',
    getAverageTimeToReview: 'getAverageTimeToReview',
    AppealSettings: 'AppealSettings',
    getDecisionsTable: 'getDecisionsTable',
    QueueFormData: 'QueueFormData',
    ManualReviewQueue: 'ManualReviewQueue',
    ManualReviewQueueJobsPreview: 'ManualReviewQueueJobsPreview',
    ManualReviewQueues: 'ManualReviewQueues',
    RoutingRules: 'RoutingRules',
    getResolvedJobsForUser: 'getResolvedJobsForUser',
    getSkippedJobsForUser: 'getSkippedJobsForUser',
    RecentDecisionsSummaryData: 'RecentDecisionsSummaryData',
    GetRecentDecisions: 'GetRecentDecisions',
    getSkipsForRecentDecisions: 'getSkipsForRecentDecisions',
    GetDecidedJob: 'GetDecidedJob',
    ManualReviewSafetySettings: 'ManualReviewSafetySettings',
    ActionsWithCustomParams: 'ActionsWithCustomParams',
    ManualReviewJobInfo: 'ManualReviewJobInfo',
    getRelatedItems: 'getRelatedItems',
    GetCommentsForJob: 'GetCommentsForJob',
    getThreadHistory: 'getThreadHistory',
    ItemTypeHiddenFields: 'ItemTypeHiddenFields',
    AllManualReviewQueues: 'AllManualReviewQueues',
    getLatestUserSubmittedItemsWithThreads:
      'getLatestUserSubmittedItemsWithThreads',
    getMoreInfoForThreadItems: 'getMoreInfoForThreadItems',
    getMoreInfoForPartialItems: 'getMoreInfoForPartialItems',
    getExistingJobsForItem: 'getExistingJobsForItem',
    getLatestUserSubmittedItems: 'getLatestUserSubmittedItems',
    OrgData: 'OrgData',
    getMoreInfoForItems: 'getMoreInfoForItems',
    getUserItems: 'getUserItems',
    ManualReviewHasAppealsEnabled: 'ManualReviewHasAppealsEnabled',
    ManualReviewQueueRoutingRules: 'ManualReviewQueueRoutingRules',
    ManualReviewChartConfigurationSettings:
      'ManualReviewChartConfigurationSettings',
    ManualReviewDecisionInsightsOrgInfo: 'ManualReviewDecisionInsightsOrgInfo',
    getDecisionCounts: 'getDecisionCounts',
    getJobCreationCounts: 'getJobCreationCounts',
    getResolvedJobCounts: 'getResolvedJobCounts',
    getSkippedJobCounts: 'getSkippedJobCounts',
    ManualReviewDecisionInsightsFilterByInfo:
      'ManualReviewDecisionInsightsFilterByInfo',
    AllNCMECReports: 'AllNCMECReports',
    Permissions: 'Permissions',
    GetNCMECReport: 'GetNCMECReport',
    TotalPendingJobs: 'TotalPendingJobs',
    RuleNamesAndIds: 'RuleNamesAndIds',
    DataForOverviewCharts: 'DataForOverviewCharts',
    ViolationsPerPolicy: 'ViolationsPerPolicy',
    Policies: 'Policies',
    PoliciesWithModels: 'PoliciesWithModels',
    GetOrgIsReadyToCreateCoveModels: 'GetOrgIsReadyToCreateCoveModels',
    IsDemoOrg: 'IsDemoOrg',
    ReportingRules: 'ReportingRules',
    Rules: 'Rules',
    RulesDashboardInsights: 'RulesDashboardInsights',
    PolicyRollupData: 'PolicyRollupData',
    ActionStatisticsData: 'ActionStatisticsData',
    ReportingRuleInfo: 'ReportingRuleInfo',
    RuleInfo: 'RuleInfo',
    SpotTestRule: 'SpotTestRule',
    ReportingRulePassRateAnalytics: 'ReportingRulePassRateAnalytics',
    ReportingRuleInsightsCurrentVersionSamples:
      'ReportingRuleInsightsCurrentVersionSamples',
    ReportingRuleInsightsPriorVersionSamples:
      'ReportingRuleInsightsPriorVersionSamples',
    RulePassRateAnalytics: 'RulePassRateAnalytics',
    RuleInsightsTableAllSignals: 'RuleInsightsTableAllSignals',
    RuleInsightsCurrentVersionSamples: 'RuleInsightsCurrentVersionSamples',
    RuleInsightsPriorVersionSamples: 'RuleInsightsPriorVersionSamples',
    GetFullResultForRule: 'GetFullResultForRule',
    MatchingBankNames: 'MatchingBankNames',
    ReportingRule: 'ReportingRule',
    ReportingRuleFormOrgData: 'ReportingRuleFormOrgData',
    Rule: 'Rule',
    ContentRuleFormConfig: 'ContentRuleFormConfig',
    RecentUserStrikeActions: 'RecentUserStrikeActions',
    UserStrikeDistribution: 'UserStrikeDistribution',
    UserStrikeThresholds: 'UserStrikeThresholds',
    NavigationBarAuthInfo: 'NavigationBarAuthInfo',
    InternalModelTrainingPageData: 'InternalModelTrainingPageData',
    GetLabelsForPolicy: 'GetLabelsForPolicy',
    AccountSettings: 'AccountSettings',
    PersonalSafetySettings: 'PersonalSafetySettings',
    ApiKey: 'ApiKey',
    ManageUsers: 'ManageUsers',
    HasNcmecReportingEnabled: 'HasNcmecReportingEnabled',
    OrgDefaultSafetySettings: 'OrgDefaultSafetySettings',
    GetSSOCredentials: 'GetSSOCredentials',
  },
  Mutation: {
    Login: 'Login',
    DeleteRejectedUser: 'DeleteRejectedUser',
    CreateOrg: 'CreateOrg',
    SignUp: 'SignUp',
    SendPasswordReset: 'SendPasswordReset',
    ResetPassword: 'ResetPassword',
    Logout: 'Logout',
    CreateAction: 'CreateAction',
    UpdateAction: 'UpdateAction',
    DeleteAction: 'DeleteAction',
    CreateLocationBank: 'CreateLocationBank',
    UpdateLocationBank: 'UpdateLocationBank',
    DeleteLocationBank: 'DeleteLocationBank',
    CreateTextBank: 'CreateTextBank',
    UpdateTextBank: 'UpdateTextBank',
    DeleteTextBank: 'DeleteTextBank',
    BulkActionExecution: 'BulkActionExecution',
    SetIntegrationConfig: 'SetIntegrationConfig',
    DeleteItemType: 'DeleteItemType',
    CreateContentType: 'CreateContentType',
    UpdateContentType: 'UpdateContentType',
    CreateUserType: 'CreateUserType',
    UpdateUserType: 'UpdateUserType',
    CreateThreadType: 'CreateThreadType',
    UpdateThreadType: 'UpdateThreadType',
    CreateExistingCoveModelSamplingJob: 'CreateExistingCoveModelSamplingJob',
    RetrainModel: 'RetrainModel',
    SaveCustomerInfo: 'SaveCustomerInfo',
    UpdatePolicyDefinition: 'UpdatePolicyDefinition',
    StartCoveModelSignalCreationSession: 'StartCoveModelSignalCreationSession',
    CreateCoveModelSignalCreationSamplingJob:
      'CreateCoveModelSignalCreationSamplingJob',
    SubmitLabels: 'SubmitLabels',
    TrainAndSaveModel: 'TrainAndSaveModel',
    CreateRulesForModelThresholds: 'CreateRulesForModelThresholds',
    UpdateAppealSettings: 'UpdateAppealSettings',
    CreateManualReviewQueue: 'CreateManualReviewQueue',
    UpdateManualReviewQueue: 'UpdateManualReviewQueue',
    DeleteManualReviewQueue: 'DeleteManualReviewQueue',
    DeleteAllJobsFromQueue: 'DeleteAllJobsFromQueue',
    AddFavoriteMRTQueue: 'AddFavoriteMRTQueue',
    RemoveFavoriteMRTQueue: 'RemoveFavoriteMRTQueue',
    SetModeratorSafetySettings: 'SetModeratorSafetySettings',
    DequeueManualReviewJob: 'DequeueManualReviewJob',
    SubmitManualReviewDecision: 'SubmitManualReviewDecision',
    LogSkip: 'LogSkip',
    AddJobComment: 'AddJobComment',
    DeleteJobComment: 'DeleteJobComment',
    DeleteRoutingRule: 'DeleteRoutingRule',
    CreateRoutingRule: 'CreateRoutingRule',
    UpdateRoutingRule: 'UpdateRoutingRule',
    ReorderRoutingRules: 'ReorderRoutingRules',
    SetMrtChartConfigurationSettings: 'SetMrtChartConfigurationSettings',
    AddPolicies: 'AddPolicies',
    UpdatePolicy: 'UpdatePolicy',
    DeletePolicy: 'DeletePolicy',
    CreateReportingRule: 'CreateReportingRule',
    UpdateReportingRule: 'UpdateReportingRule',
    DeleteReportingRule: 'DeleteReportingRule',
    DeleteRule: 'DeleteRule',
    AddFavoriteRule: 'AddFavoriteRule',
    RemoveFavoriteRule: 'RemoveFavoriteRule',
    CreateContentRule: 'CreateContentRule',
    UpdateContentRule: 'UpdateContentRule',
    CreateUserRule: 'CreateUserRule',
    UpdateUserRule: 'UpdateUserRule',
    SetAllUserStrikeThreshold: 'SetAllUserStrikeThreshold',
    UpdateUserStrikeTTL: 'UpdateUserStrikeTTL',
    RequestDemo: 'RequestDemo',
    SetLabelsForItems: 'SetLabelsForItems',
    DeleteLabels: 'DeleteLabels',
    UpdateAccountInfo: 'UpdateAccountInfo',
    CancelSubscription: 'CancelSubscription',
    DeleteUser: 'DeleteUser',
    UpdateRole: 'UpdateRole',
    ApproveUser: 'ApproveUser',
    RejectUser: 'RejectUser',
    InviteUser: 'InviteUser',
    SetOrgDefaultSafetySettings: 'SetOrgDefaultSafetySettings',
    UpdateSSOCredentials: 'UpdateSSOCredentials',
  },
  Fragment: {
    CustomerDefinedActionFragment: 'CustomerDefinedActionFragment',
    ItemFields: 'ItemFields',
    ManualReviewDecisionComponentFields: 'ManualReviewDecisionComponentFields',
    JobFields: 'JobFields',
    ManualReviewJobCommentFields: 'ManualReviewJobCommentFields',
    NCMECReportValues: 'NCMECReportValues',
    PolicyFields: 'PolicyFields',
    RulesDashboardRuleFieldsFragment: 'RulesDashboardRuleFieldsFragment',
    SampleReportingRuleExecutionResultFields:
      'SampleReportingRuleExecutionResultFields',
    LeafConditionWithResultFields: 'LeafConditionWithResultFields',
    SampleRuleExecutionResultFields: 'SampleRuleExecutionResultFields',
    SampleRuleExecutionResultConditionResultFields:
      'SampleRuleExecutionResultConditionResultFields',
    ReportingRuleFormRuleFieldsFragment: 'ReportingRuleFormRuleFieldsFragment',
    DerivedFieldFields: 'DerivedFieldFields',
    ItemTypeFragment: 'ItemTypeFragment',
    SignalsFragment: 'SignalsFragment',
    LeafConditionFields: 'LeafConditionFields',
    ConditionSetFields: 'ConditionSetFields',
    RuleFormRuleFieldsFragment: 'RuleFormRuleFieldsFragment',
    ActionFragment: 'ActionFragment',
  },
};
