import type { FilledIconProps } from '../types';
import { ReactComponent as AddGroupAlt } from './add-group-alt.svg';
import { ReactComponent as AddGroup } from './add-group.svg';
import { ReactComponent as AddUserAlt } from './add-user-alt.svg';
import { ReactComponent as AddUser } from './add-user.svg';
import { ReactComponent as BlockUser } from './block-user.svg';
import { ReactComponent as Earbuds1 } from './earbuds-1.svg';
import { ReactComponent as Fan } from './fan.svg';
import { ReactComponent as Flute1 } from './flute-1.svg';
import { ReactComponent as Flute } from './flute.svg';
import { ReactComponent as Friends } from './friends.svg';
import { ReactComponent as PersonalFile1 } from './personal-file-1.svg';
import { ReactComponent as PersonalFile } from './personal-file.svg';
import { ReactComponent as RemoveGroup } from './remove-group.svg';
import { ReactComponent as RemoveUser } from './remove-user.svg';
import { ReactComponent as ShoePrint } from './shoe-print.svg';
import { ReactComponent as Team } from './team.svg';
import { ReactComponent as UserAlt3 } from './user-alt-3.svg';
import { ReactComponent as UserAlt4 } from './user-alt-4.svg';
import { ReactComponent as UserAlt5 } from './user-alt-5.svg';
import { ReactComponent as UserBlock } from './user-block.svg';
import { ReactComponent as UserCheck } from './user-check.svg';
import { ReactComponent as UserDelete } from './user-delete.svg';
import { ReactComponent as UserDownload } from './user-download.svg';
import { ReactComponent as UserEdit } from './user-edit.svg';
import { ReactComponent as UserGlobe } from './user-globe.svg';
import { ReactComponent as UserIdentification } from './user-identification.svg';
import { ReactComponent as UserInformation } from './user-information.svg';
import { ReactComponent as UserKey } from './user-key.svg';
import { ReactComponent as UserLock } from './user-lock.svg';
import { ReactComponent as UserLove } from './user-love.svg';
import { ReactComponent as UserMoney } from './user-money.svg';
import { ReactComponent as UserQuestion } from './user-question.svg';
import { ReactComponent as UserReload } from './user-reload.svg';
import { ReactComponent as UserRemove } from './user-remove.svg';
import { ReactComponent as UserSearch } from './user-search.svg';
import { ReactComponent as UserSettings } from './user-settings.svg';
import { ReactComponent as UserShield } from './user-shield.svg';
import { ReactComponent as UserStar } from './user-star.svg';
import { ReactComponent as UserTimer } from './user-timer.svg';
import { ReactComponent as UserTopArrow } from './user-top-arrow.svg';

const AddGroupAltFilled = (props: FilledIconProps) => (
  <AddGroupAlt fill="currentColor" {...props} />
);

const AddGroupFilled = (props: FilledIconProps) => (
  <AddGroup fill="currentColor" {...props} />
);

const AddUserAltFilled = (props: FilledIconProps) => (
  <AddUserAlt fill="currentColor" {...props} />
);

const AddUserFilled = (props: FilledIconProps) => (
  <AddUser fill="currentColor" {...props} />
);

const BlockUserFilled = (props: FilledIconProps) => (
  <BlockUser fill="currentColor" {...props} />
);

const Earbuds1Filled = (props: FilledIconProps) => (
  <Earbuds1 fill="currentColor" {...props} />
);

const FanFilled = (props: FilledIconProps) => (
  <Fan fill="currentColor" {...props} />
);

const Flute1Filled = (props: FilledIconProps) => (
  <Flute1 fill="currentColor" {...props} />
);

const FluteFilled = (props: FilledIconProps) => (
  <Flute fill="currentColor" {...props} />
);

const FriendsFilled = (props: FilledIconProps) => (
  <Friends fill="currentColor" {...props} />
);

const PersonalFile1Filled = (props: FilledIconProps) => (
  <PersonalFile1 fill="currentColor" {...props} />
);

const PersonalFileFilled = (props: FilledIconProps) => (
  <PersonalFile fill="currentColor" {...props} />
);

const RemoveGroupFilled = (props: FilledIconProps) => (
  <RemoveGroup fill="currentColor" {...props} />
);

const RemoveUserFilled = (props: FilledIconProps) => (
  <RemoveUser fill="currentColor" {...props} />
);

const ShoePrintFilled = (props: FilledIconProps) => (
  <ShoePrint fill="currentColor" {...props} />
);

const TeamFilled = (props: FilledIconProps) => (
  <Team fill="currentColor" {...props} />
);

const UserAlt3Filled = (props: FilledIconProps) => (
  <UserAlt3 fill="currentColor" {...props} />
);

const UserAlt4Filled = (props: FilledIconProps) => (
  <UserAlt4 fill="currentColor" {...props} />
);

const UserAlt5Filled = (props: FilledIconProps) => (
  <UserAlt5 fill="currentColor" {...props} />
);

const UserBlockFilled = (props: FilledIconProps) => (
  <UserBlock fill="currentColor" {...props} />
);

const UserCheckFilled = (props: FilledIconProps) => (
  <UserCheck fill="currentColor" {...props} />
);

const UserDeleteFilled = (props: FilledIconProps) => (
  <UserDelete fill="currentColor" {...props} />
);

const UserDownloadFilled = (props: FilledIconProps) => (
  <UserDownload fill="currentColor" {...props} />
);

const UserEditFilled = (props: FilledIconProps) => (
  <UserEdit fill="currentColor" {...props} />
);

const UserGlobeFilled = (props: FilledIconProps) => (
  <UserGlobe fill="currentColor" {...props} />
);

const UserIdentificationFilled = (props: FilledIconProps) => (
  <UserIdentification fill="currentColor" {...props} />
);

const UserInformationFilled = (props: FilledIconProps) => (
  <UserInformation fill="currentColor" {...props} />
);

const UserKeyFilled = (props: FilledIconProps) => (
  <UserKey fill="currentColor" {...props} />
);

const UserLockFilled = (props: FilledIconProps) => (
  <UserLock fill="currentColor" {...props} />
);

const UserLoveFilled = (props: FilledIconProps) => (
  <UserLove fill="currentColor" {...props} />
);

const UserMoneyFilled = (props: FilledIconProps) => (
  <UserMoney fill="currentColor" {...props} />
);

const UserQuestionFilled = (props: FilledIconProps) => (
  <UserQuestion fill="currentColor" {...props} />
);

const UserReloadFilled = (props: FilledIconProps) => (
  <UserReload fill="currentColor" {...props} />
);

const UserRemoveFilled = (props: FilledIconProps) => (
  <UserRemove fill="currentColor" {...props} />
);

const UserSearchFilled = (props: FilledIconProps) => (
  <UserSearch fill="currentColor" {...props} />
);

const UserSettingsFilled = (props: FilledIconProps) => (
  <UserSettings fill="currentColor" {...props} />
);

const UserShieldFilled = (props: FilledIconProps) => (
  <UserShield fill="currentColor" {...props} />
);

const UserStarFilled = (props: FilledIconProps) => (
  <UserStar fill="currentColor" {...props} />
);

const UserTimerFilled = (props: FilledIconProps) => (
  <UserTimer fill="currentColor" {...props} />
);

const UserTopArrowFilled = (props: FilledIconProps) => (
  <UserTopArrow fill="currentColor" {...props} />
);

export {
  AddGroupAltFilled,
  AddGroupFilled,
  AddUserAltFilled,
  AddUserFilled,
  BlockUserFilled,
  Earbuds1Filled,
  FanFilled,
  Flute1Filled,
  FluteFilled,
  FriendsFilled,
  PersonalFile1Filled,
  PersonalFileFilled,
  RemoveGroupFilled,
  RemoveUserFilled,
  ShoePrintFilled,
  TeamFilled,
  UserAlt3Filled,
  UserAlt4Filled,
  UserAlt5Filled,
  UserBlockFilled,
  UserCheckFilled,
  UserDeleteFilled,
  UserDownloadFilled,
  UserEditFilled,
  UserGlobeFilled,
  UserIdentificationFilled,
  UserInformationFilled,
  UserKeyFilled,
  UserLockFilled,
  UserLoveFilled,
  UserMoneyFilled,
  UserQuestionFilled,
  UserReloadFilled,
  UserRemoveFilled,
  UserSearchFilled,
  UserSettingsFilled,
  UserShieldFilled,
  UserStarFilled,
  UserTimerFilled,
  UserTopArrowFilled,
};
