export class PathBuilder {
  private path: (string | number)[];

  constructor(
    private x: number,
    private y: number,
  ) {
    this.path = [`M${x} ${y}`];
  }

  public vertical(y: number) {
    this.path.push(`V${y}`);
    this.y = y;
    return this;
  }

  public horizontal(x: number) {
    this.path.push(`H${x}`);
    this.x = x;
    return this;
  }

  public arc(
    radius: number,
    corner: "tl" | "br" | "tr" | "bl" = "tl",
    clockwise = true,
  ) {
    this.x += ["tl", "bl", "br", "tr"].includes(corner) ? radius : -radius;
    this.y += ["tr", "bl"].includes(corner) ? radius : -radius;

    this.path.push(
      `A${radius} ${radius}`,
      `0 0 ${clockwise ? 1 : 0}`,
      this.x,
      this.y,
    );

    return this;
  }

  public build() {
    return this.path.join(" ");
  }
}
