import type { FilledIconProps } from '../types';
import { ReactComponent as Icon3d } from './3d.svg';
import { ReactComponent as Icon5gAlt1 } from './5g-alt-1.svg';
import { ReactComponent as Icon5gSim } from './5g-sim.svg';
import { ReactComponent as Icon5g } from './5g.svg';
import { ReactComponent as Icon360Degree } from './360-degree.svg';
import { ReactComponent as AddAlt } from './add-alt.svg';
import { ReactComponent as AddFiles } from './add-files.svg';
import { ReactComponent as AddServer } from './add-server.svg';
import { ReactComponent as AiAlt } from './ai-alt.svg';
import { ReactComponent as Ai } from './ai.svg';
import { ReactComponent as Alarm2 } from './alarm-2.svg';
import { ReactComponent as Alarm3 } from './alarm-3.svg';
import { ReactComponent as Alarm4 } from './alarm-4.svg';
import { ReactComponent as AlarmClockAlt1 } from './alarm-clock-alt-1.svg';
import { ReactComponent as AlarmClock } from './alarm-clock.svg';
import { ReactComponent as Alarm } from './alarm.svg';
import { ReactComponent as AlartDatabase } from './alart-database.svg';
import { ReactComponent as AlertServer } from './alert-server.svg';
import { ReactComponent as Appointment } from './appointment.svg';
import { ReactComponent as Archive } from './archive.svg';
import { ReactComponent as Automation } from './automation.svg';
import { ReactComponent as Ban } from './ban.svg';
import { ReactComponent as Battery } from './battery.svg';
import { ReactComponent as Bells } from './bells.svg';
import { ReactComponent as BillboardAlt1 } from './billboard-alt-1.svg';
import { ReactComponent as BillboardAlt3 } from './billboard-alt-3.svg';
import { ReactComponent as BillboardAlt4 } from './billboard-alt-4.svg';
import { ReactComponent as BookmarkAlt } from './bookmark-alt.svg';
import { ReactComponent as Bookmark } from './bookmark.svg';
import { ReactComponent as Bot1 } from './bot-1.svg';
import { ReactComponent as Bot } from './bot.svg';
import { ReactComponent as Brightness } from './brightness.svg';
import { ReactComponent as BulbAlt } from './bulb-alt.svg';
import { ReactComponent as Bulb } from './bulb.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as CalenderAlt1 } from './calender-alt-1.svg';
import { ReactComponent as CalenderAlt2 } from './calender-alt-2.svg';
import { ReactComponent as CalenderAlt3 } from './calender-alt-3.svg';
import { ReactComponent as CalenderAlt4 } from './calender-alt-4.svg';
import { ReactComponent as CalenderAlt } from './calender-alt.svg';
import { ReactComponent as CameraAlt1 } from './camera-alt-1.svg';
import { ReactComponent as CameraAlt } from './camera-alt.svg';
import { ReactComponent as Camera } from './camera.svg';
import { ReactComponent as CellphoneVr1 } from './cellphone-vr-1.svg';
import { ReactComponent as Change } from './change.svg';
import { ReactComponent as Charging } from './charging.svg';
import { ReactComponent as CheckBoxAlt } from './check-box-alt.svg';
import { ReactComponent as CheckDatabase } from './check-database.svg';
import { ReactComponent as CheckServer } from './check-server.svg';
import { ReactComponent as Cloud1 } from './cloud-1.svg';
import { ReactComponent as Cloud3 } from './cloud-3.svg';
import { ReactComponent as CloudCheckAlt } from './cloud-check-alt.svg';
import { ReactComponent as CloudCheck } from './cloud-check.svg';
import { ReactComponent as CloudComputing1 } from './cloud-computing-1.svg';
import { ReactComponent as CloudComputing } from './cloud-computing.svg';
import { ReactComponent as CloudDownload1 } from './cloud-download-1.svg';
import { ReactComponent as CloudDownload } from './cloud-download.svg';
import { ReactComponent as CloudNetwork2 } from './cloud-network-2.svg';
import { ReactComponent as CloudNetwork } from './cloud-network.svg';
import { ReactComponent as CloudSearch1 } from './cloud-search-1.svg';
import { ReactComponent as CloudSearch2 } from './cloud-search-2.svg';
import { ReactComponent as CloudSearch } from './cloud-search.svg';
import { ReactComponent as CloudServer1 } from './cloud-server-1.svg';
import { ReactComponent as CloudServer } from './cloud-server.svg';
import { ReactComponent as CloudSyncAlt } from './cloud-sync-alt.svg';
import { ReactComponent as CloudSync } from './cloud-sync.svg';
import { ReactComponent as CloudUploadAlt } from './cloud-upload-alt.svg';
import { ReactComponent as CloudUpload } from './cloud-upload.svg';
import { ReactComponent as Cms } from './cms.svg';
import { ReactComponent as CodeAlt } from './code-alt.svg';
import { ReactComponent as CodeBrowser } from './code-browser.svg';
import { ReactComponent as CodeCommit } from './code-commit.svg';
import { ReactComponent as CodeMerge } from './code-merge.svg';
import { ReactComponent as Code } from './code.svg';
import { ReactComponent as CodingAlt1 } from './coding-alt-1.svg';
import { ReactComponent as CodingAlt } from './coding-alt.svg';
import { ReactComponent as Coding } from './coding.svg';
import { ReactComponent as CogAlt } from './cog-alt.svg';
import { ReactComponent as Cog } from './cog.svg';
import { ReactComponent as Cogs1 } from './cogs-1.svg';
import { ReactComponent as Cogs2 } from './cogs-2.svg';
import { ReactComponent as Cogs } from './cogs.svg';
import { ReactComponent as ControlPanelAlt } from './control-panel-alt.svg';
import { ReactComponent as ControlPanel } from './control-panel.svg';
import { ReactComponent as CopyAlt } from './copy-alt.svg';
import { ReactComponent as Copy } from './copy.svg';
import { ReactComponent as CpuAlt } from './cpu-alt.svg';
import { ReactComponent as Cut } from './cut.svg';
import { ReactComponent as Dashboard } from './dashboard.svg';
import { ReactComponent as Database1 } from './database-1.svg';
import { ReactComponent as Database2 } from './database-2.svg';
import { ReactComponent as Database3 } from './database-3.svg';
import { ReactComponent as Database7 } from './database-7.svg';
import { ReactComponent as Database8 } from './database-8.svg';
import { ReactComponent as Database9 } from './database-9.svg';
import { ReactComponent as Database10 } from './database-10.svg';
import { ReactComponent as Database11 } from './database-11.svg';
import { ReactComponent as DatabaseDown } from './database-down.svg';
import { ReactComponent as DatabaseUp } from './database-up.svg';
import { ReactComponent as Database } from './database.svg';
import { ReactComponent as Devops } from './devops.svg';
import { ReactComponent as DisplayAlt } from './display-alt.svg';
import { ReactComponent as Display } from './display.svg';
import { ReactComponent as Domain } from './domain.svg';
import { ReactComponent as DownServer } from './down-server.svg';
import { ReactComponent as DownloadAlt } from './download-alt.svg';
import { ReactComponent as EmptyBattery } from './empty-battery.svg';
import { ReactComponent as EmptyFile } from './empty-file.svg';
import { ReactComponent as Event } from './event.svg';
import { ReactComponent as Figma } from './figma.svg';
import { ReactComponent as FileDownload } from './file-download.svg';
import { ReactComponent as FileExclamation } from './file-exclamation.svg';
import { ReactComponent as FileExport } from './file-export.svg';
import { ReactComponent as FileLock } from './file-lock.svg';
import { ReactComponent as FileName } from './file-name.svg';
import { ReactComponent as FilePen } from './file-pen.svg';
import { ReactComponent as FileProtection } from './file-protection.svg';
import { ReactComponent as FileUploadAlt1 } from './file-upload-alt-1.svg';
import { ReactComponent as FileUploadAlt } from './file-upload-alt.svg';
import { ReactComponent as FileUpload } from './file-upload.svg';
import { ReactComponent as File } from './file.svg';
import { ReactComponent as FilesAlt } from './files-alt.svg';
import { ReactComponent as Files } from './files.svg';
import { ReactComponent as FireExtinguisher } from './fire-extinguisher.svg';
import { ReactComponent as FolderAlt1 } from './folder-alt-1.svg';
import { ReactComponent as FolderAlt } from './folder-alt.svg';
import { ReactComponent as FolderNetworking1 } from './folder-networking-1.svg';
import { ReactComponent as FolderNetworking2 } from './folder-networking-2.svg';
import { ReactComponent as Folder } from './folder.svg';
import { ReactComponent as FullBattery } from './full-battery.svg';
import { ReactComponent as GameAlt } from './game-alt.svg';
import { ReactComponent as Game } from './game.svg';
import { ReactComponent as HalfBattery } from './half-battery.svg';
import { ReactComponent as Harddrive } from './harddrive.svg';
import { ReactComponent as HeadphoneAlt } from './headphone-alt.svg';
import { ReactComponent as Headphone } from './headphone.svg';
import { ReactComponent as IdCardAlt } from './id-card-alt.svg';
import { ReactComponent as Image } from './image.svg';
import { ReactComponent as IncognitoBrowser1 } from './incognito-browser-1.svg';
import { ReactComponent as IncognitoBrowserAlt } from './incognito-browser-alt.svg';
import { ReactComponent as IncognitoBrowser } from './incognito-browser.svg';
import { ReactComponent as InformationAlt } from './information-alt.svg';
import { ReactComponent as Infrared } from './infrared.svg';
import { ReactComponent as InternetAlt } from './internet-alt.svg';
import { ReactComponent as IotMobile } from './iot-mobile.svg';
import { ReactComponent as IOT } from './IOT.svg';
import { ReactComponent as Jpg } from './jpg.svg';
import { ReactComponent as Keyboard } from './keyboard.svg';
import { ReactComponent as LaptopAltKeyboard } from './laptop-alt-keyboard.svg';
import { ReactComponent as LaptopAltSwitch } from './laptop-alt-switch.svg';
import { ReactComponent as LaptopAlt } from './laptop-alt.svg';
import { ReactComponent as LaptopPhone } from './laptop-phone.svg';
import { ReactComponent as LaptopStar1 } from './laptop-star-1.svg';
import { ReactComponent as LaptopStar } from './laptop-star.svg';
import { ReactComponent as LaptopThumbsDown } from './laptop-thumbs-down.svg';
import { ReactComponent as LaptopThumbsUp } from './laptop-thumbs-up.svg';
import { ReactComponent as Laptop } from './laptop.svg';
import { ReactComponent as LinkAlt1 } from './link-alt-1.svg';
import { ReactComponent as LinkAlt2 } from './link-alt-2.svg';
import { ReactComponent as LinkAlt3 } from './link-alt-3.svg';
import { ReactComponent as LinkAltSplash } from './link-alt-splash.svg';
import { ReactComponent as LinkAlt } from './link-alt.svg';
import { ReactComponent as Link } from './link.svg';
import { ReactComponent as LiveStreamAlt } from './live-stream-alt.svg';
import { ReactComponent as LiveStream } from './live-stream.svg';
import { ReactComponent as LowBattery } from './low-battery.svg';
import { ReactComponent as Magnet } from './magnet.svg';
import { ReactComponent as MailServer } from './mail-server.svg';
import { ReactComponent as MapAlt } from './map-alt.svg';
import { ReactComponent as MapMarker } from './map-marker.svg';
import { ReactComponent as Map } from './map.svg';
import { ReactComponent as MediaServer } from './media-server.svg';
import { ReactComponent as MenuAlt2 } from './menu-alt-2.svg';
import { ReactComponent as MenuAlt3 } from './menu-alt-3.svg';
import { ReactComponent as MenuAlt4 } from './menu-alt-4.svg';
import { ReactComponent as MenuAlt5 } from './menu-alt-5.svg';
import { ReactComponent as MenuAlt } from './menu-alt.svg';
import { ReactComponent as MenuCircle } from './menu-circle.svg';
import { ReactComponent as Menu } from './menu.svg';
import { ReactComponent as Merge } from './merge.svg';
import { ReactComponent as Mic } from './mic.svg';
import { ReactComponent as MicrophoneAlt1 } from './microphone-alt-1.svg';
import { ReactComponent as MicrophoneAlt } from './microphone-alt.svg';
import { ReactComponent as Microphone } from './microphone.svg';
import { ReactComponent as MobileAltButton } from './mobile-alt-button.svg';
import { ReactComponent as MobileAltRoundedBottom } from './mobile-alt-rounded-bottom.svg';
import { ReactComponent as MobileAltRounded } from './mobile-alt-rounded.svg';
import { ReactComponent as MobileApplicationAlt } from './mobile-application-alt.svg';
import { ReactComponent as MobileApplication } from './mobile-application.svg';
import { ReactComponent as Mobile } from './mobile.svg';
import { ReactComponent as MouseAlt } from './mouse-alt.svg';
import { ReactComponent as Mouse } from './mouse.svg';
import { ReactComponent as Network1 } from './network-1.svg';
import { ReactComponent as NetworkCloud } from './network-cloud.svg';
import { ReactComponent as Networking } from './networking.svg';
import { ReactComponent as NoChargeBattery } from './no-charge-battery.svg';
import { ReactComponent as Package } from './package.svg';
import { ReactComponent as PaperPlane } from './paper-plane.svg';
import { ReactComponent as Paste } from './paste.svg';
import { ReactComponent as Pdf } from './pdf.svg';
import { ReactComponent as Plug } from './plug.svg';
import { ReactComponent as Png } from './png.svg';
import { ReactComponent as Presentation } from './presentation.svg';
import { ReactComponent as ProcessorAlt } from './processor-alt.svg';
import { ReactComponent as Programmer } from './programmer.svg';
import { ReactComponent as Psd } from './psd.svg';
import { ReactComponent as RecycleAlt } from './recycle-alt.svg';
import { ReactComponent as Recycle } from './recycle.svg';
import { ReactComponent as ReloadAltBox } from './reload-alt-box.svg';
import { ReactComponent as ReloadAlt } from './reload-alt.svg';
import { ReactComponent as ReloadBrowser } from './reload-browser.svg';
import { ReactComponent as RemoveFile } from './remove-file.svg';
import { ReactComponent as Rotation } from './rotation.svg';
import { ReactComponent as RssFeed } from './rss-feed.svg';
import { ReactComponent as SaveAlt } from './save-alt.svg';
import { ReactComponent as Save } from './save.svg';
import { ReactComponent as Scan } from './scan.svg';
import { ReactComponent as ScrollDown } from './scroll-down.svg';
import { ReactComponent as SearchAlt } from './search-alt.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Send } from './send.svg';
import { ReactComponent as Server2 } from './server-2.svg';
import { ReactComponent as Server3 } from './server-3.svg';
import { ReactComponent as Server4 } from './server-4.svg';
import { ReactComponent as Server5 } from './server-5.svg';
import { ReactComponent as Server6 } from './server-6.svg';
import { ReactComponent as Server7 } from './server-7.svg';
import { ReactComponent as Server8 } from './server-8.svg';
import { ReactComponent as Server9 } from './server-9.svg';
import { ReactComponent as Server14 } from './server-14.svg';
import { ReactComponent as Server15 } from './server-15.svg';
import { ReactComponent as Server18 } from './server-18.svg';
import { ReactComponent as Server19 } from './server-19.svg';
import { ReactComponent as Server } from './server.svg';
import { ReactComponent as Share1 } from './share-1.svg';
import { ReactComponent as ShareAlt } from './share-alt.svg';
import { ReactComponent as Share } from './share.svg';
import { ReactComponent as ShoppingBasket } from './shopping-basket.svg';
import { ReactComponent as Shortcode } from './shortcode.svg';
import { ReactComponent as Signal } from './signal.svg';
import { ReactComponent as SimCard } from './sim-card.svg';
import { ReactComponent as Sitemap } from './sitemap.svg';
import { ReactComponent as SmartCloud1 } from './smart-cloud-1.svg';
import { ReactComponent as SmartCloud } from './smart-cloud.svg';
import { ReactComponent as SmartHome } from './smart-home.svg';
import { ReactComponent as SoftwareDeployment } from './software-deployment.svg';
import { ReactComponent as SpeedometerAlt1 } from './speedometer-alt-1.svg';
import { ReactComponent as SpeedometerAlt } from './speedometer-alt.svg';
import { ReactComponent as Speedometer } from './speedometer.svg';
import { ReactComponent as Ssd1 } from './ssd-1.svg';
import { ReactComponent as Ssd } from './ssd.svg';
import { ReactComponent as StarEmpty } from './star-empty.svg';
import { ReactComponent as StarHalf } from './star-half.svg';
import { ReactComponent as Star } from './star.svg';
import { ReactComponent as Svg } from './svg.svg';
import { ReactComponent as SwitchAlt } from './switch-alt.svg';
import { ReactComponent as Tab } from './tab.svg';
import { ReactComponent as Tap } from './tap.svg';
import { ReactComponent as Terminal } from './terminal.svg';
import { ReactComponent as Tether } from './tether.svg';
import { ReactComponent as Thumbnail } from './thumbnail.svg';
import { ReactComponent as Timer } from './timer.svg';
import { ReactComponent as TrashCanAlt } from './trash-can-alt.svg';
import { ReactComponent as TrashCan } from './trash-can.svg';
import { ReactComponent as Tv1 } from './tv-1.svg';
import { ReactComponent as Tv } from './tv.svg';
import { ReactComponent as Unlink } from './unlink.svg';
import { ReactComponent as UpServer } from './up-server.svg';
import { ReactComponent as UploadAlt } from './upload-alt.svg';
import { ReactComponent as Upload } from './upload.svg';
import { ReactComponent as Usb } from './usb.svg';
import { ReactComponent as UserAlt2 } from './user-alt-2.svg';
import { ReactComponent as Users2 } from './users-2.svg';
import { ReactComponent as Users } from './users.svg';
import { ReactComponent as VectorNode1 } from './vector-node-1.svg';
import { ReactComponent as VectorNode11 } from './vector-node-11.svg';
import { ReactComponent as VectorNodeAlt } from './vector-node-alt.svg';
import { ReactComponent as VectorNode } from './vector-node.svg';
import { ReactComponent as VirtualRealityAlt } from './virtual-reality-alt.svg';
import { ReactComponent as VrAlt1 } from './vr-alt-1.svg';
import { ReactComponent as VrAlt } from './vr-alt.svg';
import { ReactComponent as VrBrowser } from './vr-browser.svg';
import { ReactComponent as VrBug } from './vr-bug.svg';
import { ReactComponent as VrChat } from './vr-chat.svg';
import { ReactComponent as VrDesign } from './vr-design.svg';
import { ReactComponent as VrGlasses } from './vr-glasses.svg';
import { ReactComponent as VrLaptop } from './vr-laptop.svg';
import { ReactComponent as VrMonitor1 } from './vr-monitor-1.svg';
import { ReactComponent as VrMonitor2 } from './vr-monitor-2.svg';
import { ReactComponent as VrMonitor } from './vr-monitor.svg';
import { ReactComponent as VrMotion } from './vr-motion.svg';
import { ReactComponent as VrTech } from './vr-tech.svg';
import { ReactComponent as Vr } from './vr.svg';
import { ReactComponent as WalkieTalkie } from './walkie-talkie.svg';
import { ReactComponent as WebcamAlt } from './webcam-alt.svg';
import { ReactComponent as WebsiteAlt } from './website-alt.svg';
import { ReactComponent as WebsiteBrowser } from './website-browser.svg';
import { ReactComponent as Website } from './website.svg';
import { ReactComponent as WifiAlt } from './wifi-alt.svg';
import { ReactComponent as WifiError } from './wifi-error.svg';
import { ReactComponent as Wifi } from './wifi.svg';
import { ReactComponent as WindowMaximize } from './window-maximize.svg';
import { ReactComponent as WindowRestore } from './window-restore.svg';
import { ReactComponent as Wire } from './wire.svg';
import { ReactComponent as Word } from './word.svg';
import { ReactComponent as Zip } from './zip.svg';
import { ReactComponent as ZoomInAlt } from './zoom-in-alt.svg';
import { ReactComponent as ZoomIn } from './zoom-in.svg';
import { ReactComponent as ZoomOutAlt } from './zoom-out-alt.svg';
import { ReactComponent as ZoomOut } from './zoom-out.svg';

const Icon360DegreeFilled = (props: FilledIconProps) => (
  <Icon360Degree fill="currentColor" {...props} />
);

const Icon3dFilled = (props: FilledIconProps) => (
  <Icon3d fill="currentColor" {...props} />
);

const Icon5gAlt1Filled = (props: FilledIconProps) => (
  <Icon5gAlt1 fill="currentColor" {...props} />
);

const Icon5gSimFilled = (props: FilledIconProps) => (
  <Icon5gSim fill="currentColor" {...props} />
);

const Icon5gFilled = (props: FilledIconProps) => (
  <Icon5g fill="currentColor" {...props} />
);

const IOTFilled = (props: FilledIconProps) => (
  <IOT fill="currentColor" {...props} />
);

const AddAltFilled = (props: FilledIconProps) => (
  <AddAlt fill="currentColor" {...props} />
);

const AddFilesFilled = (props: FilledIconProps) => (
  <AddFiles fill="currentColor" {...props} />
);

const AddServerFilled = (props: FilledIconProps) => (
  <AddServer fill="currentColor" {...props} />
);

const AiAltFilled = (props: FilledIconProps) => (
  <AiAlt fill="currentColor" {...props} />
);

const AiFilled = (props: FilledIconProps) => (
  <Ai fill="currentColor" {...props} />
);

const Alarm2Filled = (props: FilledIconProps) => (
  <Alarm2 fill="currentColor" {...props} />
);

const Alarm3Filled = (props: FilledIconProps) => (
  <Alarm3 fill="currentColor" {...props} />
);

const Alarm4Filled = (props: FilledIconProps) => (
  <Alarm4 fill="currentColor" {...props} />
);

const AlarmClockAlt1Filled = (props: FilledIconProps) => (
  <AlarmClockAlt1 fill="currentColor" {...props} />
);

const AlarmClockFilled = (props: FilledIconProps) => (
  <AlarmClock fill="currentColor" {...props} />
);

const AlarmFilled = (props: FilledIconProps) => (
  <Alarm fill="currentColor" {...props} />
);

const AlartDatabaseFilled = (props: FilledIconProps) => (
  <AlartDatabase fill="currentColor" {...props} />
);

const AlertServerFilled = (props: FilledIconProps) => (
  <AlertServer fill="currentColor" {...props} />
);

const AppointmentFilled = (props: FilledIconProps) => (
  <Appointment fill="currentColor" {...props} />
);

const ArchiveFilled = (props: FilledIconProps) => (
  <Archive fill="currentColor" {...props} />
);

const AutomationFilled = (props: FilledIconProps) => (
  <Automation fill="currentColor" {...props} />
);

const BanFilled = (props: FilledIconProps) => (
  <Ban fill="currentColor" {...props} />
);

const BatteryFilled = (props: FilledIconProps) => (
  <Battery fill="currentColor" {...props} />
);

const BellsFilled = (props: FilledIconProps) => (
  <Bells fill="currentColor" {...props} />
);

const BillboardAlt1Filled = (props: FilledIconProps) => (
  <BillboardAlt1 fill="currentColor" {...props} />
);

const BillboardAlt3Filled = (props: FilledIconProps) => (
  <BillboardAlt3 fill="currentColor" {...props} />
);

const BillboardAlt4Filled = (props: FilledIconProps) => (
  <BillboardAlt4 fill="currentColor" {...props} />
);

const BookmarkAltFilled = (props: FilledIconProps) => (
  <BookmarkAlt fill="currentColor" {...props} />
);

const BookmarkFilled = (props: FilledIconProps) => (
  <Bookmark fill="currentColor" {...props} />
);

const Bot1Filled = (props: FilledIconProps) => (
  <Bot1 fill="currentColor" {...props} />
);

const BotFilled = (props: FilledIconProps) => (
  <Bot fill="currentColor" {...props} />
);

const BrightnessFilled = (props: FilledIconProps) => (
  <Brightness fill="currentColor" {...props} />
);

const BulbAltFilled = (props: FilledIconProps) => (
  <BulbAlt fill="currentColor" {...props} />
);

const BulbFilled = (props: FilledIconProps) => (
  <Bulb fill="currentColor" {...props} />
);

const CalendarFilled = (props: FilledIconProps) => (
  <Calendar fill="currentColor" {...props} />
);

const CalenderAlt1Filled = (props: FilledIconProps) => (
  <CalenderAlt1 fill="currentColor" {...props} />
);

const CalenderAlt2Filled = (props: FilledIconProps) => (
  <CalenderAlt2 fill="currentColor" {...props} />
);

const CalenderAlt3Filled = (props: FilledIconProps) => (
  <CalenderAlt3 fill="currentColor" {...props} />
);

const CalenderAlt4Filled = (props: FilledIconProps) => (
  <CalenderAlt4 fill="currentColor" {...props} />
);

const CalenderAltFilled = (props: FilledIconProps) => (
  <CalenderAlt fill="currentColor" {...props} />
);

const CameraAlt1Filled = (props: FilledIconProps) => (
  <CameraAlt1 fill="currentColor" {...props} />
);

const CameraAltFilled = (props: FilledIconProps) => (
  <CameraAlt fill="currentColor" {...props} />
);

const CameraFilled = (props: FilledIconProps) => (
  <Camera fill="currentColor" {...props} />
);

const CellphoneVr1Filled = (props: FilledIconProps) => (
  <CellphoneVr1 fill="currentColor" {...props} />
);

const ChangeFilled = (props: FilledIconProps) => (
  <Change fill="currentColor" {...props} />
);

const ChargingFilled = (props: FilledIconProps) => (
  <Charging fill="currentColor" {...props} />
);

const CheckBoxAltFilled = (props: FilledIconProps) => (
  <CheckBoxAlt fill="currentColor" {...props} />
);

const CheckDatabaseFilled = (props: FilledIconProps) => (
  <CheckDatabase fill="currentColor" {...props} />
);

const CheckServerFilled = (props: FilledIconProps) => (
  <CheckServer fill="currentColor" {...props} />
);

const Cloud1Filled = (props: FilledIconProps) => (
  <Cloud1 fill="currentColor" {...props} />
);

const Cloud3Filled = (props: FilledIconProps) => (
  <Cloud3 fill="currentColor" {...props} />
);

const CloudCheckAltFilled = (props: FilledIconProps) => (
  <CloudCheckAlt fill="currentColor" {...props} />
);

const CloudCheckFilled = (props: FilledIconProps) => (
  <CloudCheck fill="currentColor" {...props} />
);

const CloudComputing1Filled = (props: FilledIconProps) => (
  <CloudComputing1 fill="currentColor" {...props} />
);

const CloudComputingFilled = (props: FilledIconProps) => (
  <CloudComputing fill="currentColor" {...props} />
);

const CloudDownload1Filled = (props: FilledIconProps) => (
  <CloudDownload1 fill="currentColor" {...props} />
);

const CloudDownloadFilled = (props: FilledIconProps) => (
  <CloudDownload fill="currentColor" {...props} />
);

const CloudNetwork2Filled = (props: FilledIconProps) => (
  <CloudNetwork2 fill="currentColor" {...props} />
);

const CloudNetworkFilled = (props: FilledIconProps) => (
  <CloudNetwork fill="currentColor" {...props} />
);

const CloudSearch1Filled = (props: FilledIconProps) => (
  <CloudSearch1 fill="currentColor" {...props} />
);

const CloudSearch2Filled = (props: FilledIconProps) => (
  <CloudSearch2 fill="currentColor" {...props} />
);

const CloudSearchFilled = (props: FilledIconProps) => (
  <CloudSearch fill="currentColor" {...props} />
);

const CloudServer1Filled = (props: FilledIconProps) => (
  <CloudServer1 fill="currentColor" {...props} />
);

const CloudServerFilled = (props: FilledIconProps) => (
  <CloudServer fill="currentColor" {...props} />
);

const CloudSyncAltFilled = (props: FilledIconProps) => (
  <CloudSyncAlt fill="currentColor" {...props} />
);

const CloudSyncFilled = (props: FilledIconProps) => (
  <CloudSync fill="currentColor" {...props} />
);

const CloudUploadAltFilled = (props: FilledIconProps) => (
  <CloudUploadAlt fill="currentColor" {...props} />
);

const CloudUploadFilled = (props: FilledIconProps) => (
  <CloudUpload fill="currentColor" {...props} />
);

const CmsFilled = (props: FilledIconProps) => (
  <Cms fill="currentColor" {...props} />
);

const CodeAltFilled = (props: FilledIconProps) => (
  <CodeAlt fill="currentColor" {...props} />
);

const CodeBrowserFilled = (props: FilledIconProps) => (
  <CodeBrowser fill="currentColor" {...props} />
);

const CodeCommitFilled = (props: FilledIconProps) => (
  <CodeCommit fill="currentColor" {...props} />
);

const CodeMergeFilled = (props: FilledIconProps) => (
  <CodeMerge fill="currentColor" {...props} />
);

const CodeFilled = (props: FilledIconProps) => (
  <Code fill="currentColor" {...props} />
);

const CodingAlt1Filled = (props: FilledIconProps) => (
  <CodingAlt1 fill="currentColor" {...props} />
);

const CodingAltFilled = (props: FilledIconProps) => (
  <CodingAlt fill="currentColor" {...props} />
);

const CodingFilled = (props: FilledIconProps) => (
  <Coding fill="currentColor" {...props} />
);

const CogAltFilled = (props: FilledIconProps) => (
  <CogAlt fill="currentColor" {...props} />
);

const CogFilled = (props: FilledIconProps) => (
  <Cog fill="currentColor" {...props} />
);

const Cogs1Filled = (props: FilledIconProps) => (
  <Cogs1 fill="currentColor" {...props} />
);

const Cogs2Filled = (props: FilledIconProps) => (
  <Cogs2 fill="currentColor" {...props} />
);

const CogsFilled = (props: FilledIconProps) => (
  <Cogs fill="currentColor" {...props} />
);

const ControlPanelAltFilled = (props: FilledIconProps) => (
  <ControlPanelAlt fill="currentColor" {...props} />
);

const ControlPanelFilled = (props: FilledIconProps) => (
  <ControlPanel fill="currentColor" {...props} />
);

const CopyAltFilled = (props: FilledIconProps) => (
  <CopyAlt fill="currentColor" {...props} />
);

const CopyFilled = (props: FilledIconProps) => (
  <Copy fill="currentColor" {...props} />
);

const CpuAltFilled = (props: FilledIconProps) => (
  <CpuAlt fill="currentColor" {...props} />
);

const CutFilled = (props: FilledIconProps) => (
  <Cut fill="currentColor" {...props} />
);

const DashboardFilled = (props: FilledIconProps) => (
  <Dashboard fill="currentColor" {...props} />
);

const Database1Filled = (props: FilledIconProps) => (
  <Database1 fill="currentColor" {...props} />
);

const Database10Filled = (props: FilledIconProps) => (
  <Database10 fill="currentColor" {...props} />
);

const Database11Filled = (props: FilledIconProps) => (
  <Database11 fill="currentColor" {...props} />
);

const Database2Filled = (props: FilledIconProps) => (
  <Database2 fill="currentColor" {...props} />
);

const Database3Filled = (props: FilledIconProps) => (
  <Database3 fill="currentColor" {...props} />
);

const Database7Filled = (props: FilledIconProps) => (
  <Database7 fill="currentColor" {...props} />
);

const Database8Filled = (props: FilledIconProps) => (
  <Database8 fill="currentColor" {...props} />
);

const Database9Filled = (props: FilledIconProps) => (
  <Database9 fill="currentColor" {...props} />
);

const DatabaseDownFilled = (props: FilledIconProps) => (
  <DatabaseDown fill="currentColor" {...props} />
);

const DatabaseUpFilled = (props: FilledIconProps) => (
  <DatabaseUp fill="currentColor" {...props} />
);

const DatabaseFilled = (props: FilledIconProps) => (
  <Database fill="currentColor" {...props} />
);

const DevopsFilled = (props: FilledIconProps) => (
  <Devops fill="currentColor" {...props} />
);

const DisplayAltFilled = (props: FilledIconProps) => (
  <DisplayAlt fill="currentColor" {...props} />
);

const DisplayFilled = (props: FilledIconProps) => (
  <Display fill="currentColor" {...props} />
);

const DomainFilled = (props: FilledIconProps) => (
  <Domain fill="currentColor" {...props} />
);

const DownServerFilled = (props: FilledIconProps) => (
  <DownServer fill="currentColor" {...props} />
);

const DownloadAltFilled = (props: FilledIconProps) => (
  <DownloadAlt fill="currentColor" {...props} />
);

const EmptyBatteryFilled = (props: FilledIconProps) => (
  <EmptyBattery fill="currentColor" {...props} />
);

const EmptyFileFilled = (props: FilledIconProps) => (
  <EmptyFile fill="currentColor" {...props} />
);

const EventFilled = (props: FilledIconProps) => (
  <Event fill="currentColor" {...props} />
);

const FigmaFilled = (props: FilledIconProps) => (
  <Figma fill="currentColor" {...props} />
);

const FileDownloadFilled = (props: FilledIconProps) => (
  <FileDownload fill="currentColor" {...props} />
);

const FileExclamationFilled = (props: FilledIconProps) => (
  <FileExclamation fill="currentColor" {...props} />
);

const FileExportFilled = (props: FilledIconProps) => (
  <FileExport fill="currentColor" {...props} />
);

const FileLockFilled = (props: FilledIconProps) => (
  <FileLock fill="currentColor" {...props} />
);

const FileNameFilled = (props: FilledIconProps) => (
  <FileName fill="currentColor" {...props} />
);

const FilePenFilled = (props: FilledIconProps) => (
  <FilePen fill="currentColor" {...props} />
);

const FileProtectionFilled = (props: FilledIconProps) => (
  <FileProtection fill="currentColor" {...props} />
);

const FileUploadAlt1Filled = (props: FilledIconProps) => (
  <FileUploadAlt1 fill="currentColor" {...props} />
);

const FileUploadAltFilled = (props: FilledIconProps) => (
  <FileUploadAlt fill="currentColor" {...props} />
);

const FileUploadFilled = (props: FilledIconProps) => (
  <FileUpload fill="currentColor" {...props} />
);

const FileFilled = (props: FilledIconProps) => (
  <File fill="currentColor" {...props} />
);

const FilesAltFilled = (props: FilledIconProps) => (
  <FilesAlt fill="currentColor" {...props} />
);

const FilesFilled = (props: FilledIconProps) => (
  <Files fill="currentColor" {...props} />
);

const FireExtinguisherFilled = (props: FilledIconProps) => (
  <FireExtinguisher fill="currentColor" {...props} />
);

const FolderAlt1Filled = (props: FilledIconProps) => (
  <FolderAlt1 fill="currentColor" {...props} />
);

const FolderAltFilled = (props: FilledIconProps) => (
  <FolderAlt fill="currentColor" {...props} />
);

const FolderNetworking1Filled = (props: FilledIconProps) => (
  <FolderNetworking1 fill="currentColor" {...props} />
);

const FolderNetworking2Filled = (props: FilledIconProps) => (
  <FolderNetworking2 fill="currentColor" {...props} />
);

const FolderFilled = (props: FilledIconProps) => (
  <Folder fill="currentColor" {...props} />
);

const FullBatteryFilled = (props: FilledIconProps) => (
  <FullBattery fill="currentColor" {...props} />
);

const GameAltFilled = (props: FilledIconProps) => (
  <GameAlt fill="currentColor" {...props} />
);

const GameFilled = (props: FilledIconProps) => (
  <Game fill="currentColor" {...props} />
);

const HalfBatteryFilled = (props: FilledIconProps) => (
  <HalfBattery fill="currentColor" {...props} />
);

const HarddriveFilled = (props: FilledIconProps) => (
  <Harddrive fill="currentColor" {...props} />
);

const HeadphoneAltFilled = (props: FilledIconProps) => (
  <HeadphoneAlt fill="currentColor" {...props} />
);

const HeadphoneFilled = (props: FilledIconProps) => (
  <Headphone fill="currentColor" {...props} />
);

const IdCardAltFilled = (props: FilledIconProps) => (
  <IdCardAlt fill="currentColor" {...props} />
);

const ImageFilled = (props: FilledIconProps) => (
  <Image fill="currentColor" {...props} />
);

const IncognitoBrowser1Filled = (props: FilledIconProps) => (
  <IncognitoBrowser1 fill="currentColor" {...props} />
);

const IncognitoBrowserAltFilled = (props: FilledIconProps) => (
  <IncognitoBrowserAlt fill="currentColor" {...props} />
);

const IncognitoBrowserFilled = (props: FilledIconProps) => (
  <IncognitoBrowser fill="currentColor" {...props} />
);

const InformationAltFilled = (props: FilledIconProps) => (
  <InformationAlt fill="currentColor" {...props} />
);

const InfraredFilled = (props: FilledIconProps) => (
  <Infrared fill="currentColor" {...props} />
);

const InternetAltFilled = (props: FilledIconProps) => (
  <InternetAlt fill="currentColor" {...props} />
);

const IotMobileFilled = (props: FilledIconProps) => (
  <IotMobile fill="currentColor" {...props} />
);

const JpgFilled = (props: FilledIconProps) => (
  <Jpg fill="currentColor" {...props} />
);

const KeyboardFilled = (props: FilledIconProps) => (
  <Keyboard fill="currentColor" {...props} />
);

const LaptopAltKeyboardFilled = (props: FilledIconProps) => (
  <LaptopAltKeyboard fill="currentColor" {...props} />
);

const LaptopAltSwitchFilled = (props: FilledIconProps) => (
  <LaptopAltSwitch fill="currentColor" {...props} />
);

const LaptopAltFilled = (props: FilledIconProps) => (
  <LaptopAlt fill="currentColor" {...props} />
);

const LaptopPhoneFilled = (props: FilledIconProps) => (
  <LaptopPhone fill="currentColor" {...props} />
);

const LaptopStar1Filled = (props: FilledIconProps) => (
  <LaptopStar1 fill="currentColor" {...props} />
);

const LaptopStarFilled = (props: FilledIconProps) => (
  <LaptopStar fill="currentColor" {...props} />
);

const LaptopThumbsDownFilled = (props: FilledIconProps) => (
  <LaptopThumbsDown fill="currentColor" {...props} />
);

const LaptopThumbsUpFilled = (props: FilledIconProps) => (
  <LaptopThumbsUp fill="currentColor" {...props} />
);

const LaptopFilled = (props: FilledIconProps) => (
  <Laptop fill="currentColor" {...props} />
);

const LinkAlt1Filled = (props: FilledIconProps) => (
  <LinkAlt1 fill="currentColor" {...props} />
);

const LinkAlt2Filled = (props: FilledIconProps) => (
  <LinkAlt2 fill="currentColor" {...props} />
);

const LinkAlt3Filled = (props: FilledIconProps) => (
  <LinkAlt3 fill="currentColor" {...props} />
);

const LinkAltSplashFilled = (props: FilledIconProps) => (
  <LinkAltSplash fill="currentColor" {...props} />
);

const LinkAltFilled = (props: FilledIconProps) => (
  <LinkAlt fill="currentColor" {...props} />
);

const LinkFilled = (props: FilledIconProps) => (
  <Link fill="currentColor" {...props} />
);

const LiveStreamAltFilled = (props: FilledIconProps) => (
  <LiveStreamAlt fill="currentColor" {...props} />
);

const LiveStreamFilled = (props: FilledIconProps) => (
  <LiveStream fill="currentColor" {...props} />
);

const LowBatteryFilled = (props: FilledIconProps) => (
  <LowBattery fill="currentColor" {...props} />
);

const MagnetFilled = (props: FilledIconProps) => (
  <Magnet fill="currentColor" {...props} />
);

const MailServerFilled = (props: FilledIconProps) => (
  <MailServer fill="currentColor" {...props} />
);

const MapAltFilled = (props: FilledIconProps) => (
  <MapAlt fill="currentColor" {...props} />
);

const MapMarkerFilled = (props: FilledIconProps) => (
  <MapMarker fill="currentColor" {...props} />
);

const MapFilled = (props: FilledIconProps) => (
  <Map fill="currentColor" {...props} />
);

const MediaServerFilled = (props: FilledIconProps) => (
  <MediaServer fill="currentColor" {...props} />
);

const MenuAlt2Filled = (props: FilledIconProps) => (
  <MenuAlt2 fill="currentColor" {...props} />
);

const MenuAlt3Filled = (props: FilledIconProps) => (
  <MenuAlt3 fill="currentColor" {...props} />
);

const MenuAlt4Filled = (props: FilledIconProps) => (
  <MenuAlt4 fill="currentColor" {...props} />
);

const MenuAlt5Filled = (props: FilledIconProps) => (
  <MenuAlt5 fill="currentColor" {...props} />
);

const MenuAltFilled = (props: FilledIconProps) => (
  <MenuAlt fill="currentColor" {...props} />
);

const MenuCircleFilled = (props: FilledIconProps) => (
  <MenuCircle fill="currentColor" {...props} />
);

const MenuFilled = (props: FilledIconProps) => (
  <Menu fill="currentColor" {...props} />
);

const MergeFilled = (props: FilledIconProps) => (
  <Merge fill="currentColor" {...props} />
);

const MicFilled = (props: FilledIconProps) => (
  <Mic fill="currentColor" {...props} />
);

const MicrophoneAlt1Filled = (props: FilledIconProps) => (
  <MicrophoneAlt1 fill="currentColor" {...props} />
);

const MicrophoneAltFilled = (props: FilledIconProps) => (
  <MicrophoneAlt fill="currentColor" {...props} />
);

const MicrophoneFilled = (props: FilledIconProps) => (
  <Microphone fill="currentColor" {...props} />
);

const MobileAltButtonFilled = (props: FilledIconProps) => (
  <MobileAltButton fill="currentColor" {...props} />
);

const MobileAltRoundedBottomFilled = (props: FilledIconProps) => (
  <MobileAltRoundedBottom fill="currentColor" {...props} />
);

const MobileAltRoundedFilled = (props: FilledIconProps) => (
  <MobileAltRounded fill="currentColor" {...props} />
);

const MobileApplicationAltFilled = (props: FilledIconProps) => (
  <MobileApplicationAlt fill="currentColor" {...props} />
);

const MobileApplicationFilled = (props: FilledIconProps) => (
  <MobileApplication fill="currentColor" {...props} />
);

const MobileFilled = (props: FilledIconProps) => (
  <Mobile fill="currentColor" {...props} />
);

const MouseAltFilled = (props: FilledIconProps) => (
  <MouseAlt fill="currentColor" {...props} />
);

const MouseFilled = (props: FilledIconProps) => (
  <Mouse fill="currentColor" {...props} />
);

const Network1Filled = (props: FilledIconProps) => (
  <Network1 fill="currentColor" {...props} />
);

const NetworkCloudFilled = (props: FilledIconProps) => (
  <NetworkCloud fill="currentColor" {...props} />
);

const NetworkingFilled = (props: FilledIconProps) => (
  <Networking fill="currentColor" {...props} />
);

const NoChargeBatteryFilled = (props: FilledIconProps) => (
  <NoChargeBattery fill="currentColor" {...props} />
);

const PackageFilled = (props: FilledIconProps) => (
  <Package fill="currentColor" {...props} />
);

const PaperPlaneFilled = (props: FilledIconProps) => (
  <PaperPlane fill="currentColor" {...props} />
);

const PasteFilled = (props: FilledIconProps) => (
  <Paste fill="currentColor" {...props} />
);

const PdfFilled = (props: FilledIconProps) => (
  <Pdf fill="currentColor" {...props} />
);

const PlugFilled = (props: FilledIconProps) => (
  <Plug fill="currentColor" {...props} />
);

const PngFilled = (props: FilledIconProps) => (
  <Png fill="currentColor" {...props} />
);

const PresentationFilled = (props: FilledIconProps) => (
  <Presentation fill="currentColor" {...props} />
);

const ProcessorAltFilled = (props: FilledIconProps) => (
  <ProcessorAlt fill="currentColor" {...props} />
);

const ProgrammerFilled = (props: FilledIconProps) => (
  <Programmer fill="currentColor" {...props} />
);

const PsdFilled = (props: FilledIconProps) => (
  <Psd fill="currentColor" {...props} />
);

const RecycleAltFilled = (props: FilledIconProps) => (
  <RecycleAlt fill="currentColor" {...props} />
);

const RecycleFilled = (props: FilledIconProps) => (
  <Recycle fill="currentColor" {...props} />
);

const ReloadAltBoxFilled = (props: FilledIconProps) => (
  <ReloadAltBox fill="currentColor" {...props} />
);

const ReloadAltFilled = (props: FilledIconProps) => (
  <ReloadAlt fill="currentColor" {...props} />
);

const ReloadBrowserFilled = (props: FilledIconProps) => (
  <ReloadBrowser fill="currentColor" {...props} />
);

const RemoveFileFilled = (props: FilledIconProps) => (
  <RemoveFile fill="currentColor" {...props} />
);

const RotationFilled = (props: FilledIconProps) => (
  <Rotation fill="currentColor" {...props} />
);

const RssFeedFilled = (props: FilledIconProps) => (
  <RssFeed fill="currentColor" {...props} />
);

const SaveAltFilled = (props: FilledIconProps) => (
  <SaveAlt fill="currentColor" {...props} />
);

const SaveFilled = (props: FilledIconProps) => (
  <Save fill="currentColor" {...props} />
);

const ScanFilled = (props: FilledIconProps) => (
  <Scan fill="currentColor" {...props} />
);

const ScrollDownFilled = (props: FilledIconProps) => (
  <ScrollDown fill="currentColor" {...props} />
);

const SearchAltFilled = (props: FilledIconProps) => (
  <SearchAlt fill="currentColor" {...props} />
);

const SearchFilled = (props: FilledIconProps) => (
  <Search fill="currentColor" {...props} />
);

const SendFilled = (props: FilledIconProps) => (
  <Send fill="currentColor" {...props} />
);

const Server14Filled = (props: FilledIconProps) => (
  <Server14 fill="currentColor" {...props} />
);

const Server15Filled = (props: FilledIconProps) => (
  <Server15 fill="currentColor" {...props} />
);

const Server18Filled = (props: FilledIconProps) => (
  <Server18 fill="currentColor" {...props} />
);

const Server19Filled = (props: FilledIconProps) => (
  <Server19 fill="currentColor" {...props} />
);

const Server2Filled = (props: FilledIconProps) => (
  <Server2 fill="currentColor" {...props} />
);

const Server3Filled = (props: FilledIconProps) => (
  <Server3 fill="currentColor" {...props} />
);

const Server4Filled = (props: FilledIconProps) => (
  <Server4 fill="currentColor" {...props} />
);

const Server5Filled = (props: FilledIconProps) => (
  <Server5 fill="currentColor" {...props} />
);

const Server6Filled = (props: FilledIconProps) => (
  <Server6 fill="currentColor" {...props} />
);

const Server7Filled = (props: FilledIconProps) => (
  <Server7 fill="currentColor" {...props} />
);

const Server8Filled = (props: FilledIconProps) => (
  <Server8 fill="currentColor" {...props} />
);

const Server9Filled = (props: FilledIconProps) => (
  <Server9 fill="currentColor" {...props} />
);

const ServerFilled = (props: FilledIconProps) => (
  <Server fill="currentColor" {...props} />
);

const Share1Filled = (props: FilledIconProps) => (
  <Share1 fill="currentColor" {...props} />
);

const ShareAltFilled = (props: FilledIconProps) => (
  <ShareAlt fill="currentColor" {...props} />
);

const ShareFilled = (props: FilledIconProps) => (
  <Share fill="currentColor" {...props} />
);

const ShoppingBasketFilled = (props: FilledIconProps) => (
  <ShoppingBasket fill="currentColor" {...props} />
);

const ShortcodeFilled = (props: FilledIconProps) => (
  <Shortcode fill="currentColor" {...props} />
);

const SignalFilled = (props: FilledIconProps) => (
  <Signal fill="currentColor" {...props} />
);

const SimCardFilled = (props: FilledIconProps) => (
  <SimCard fill="currentColor" {...props} />
);

const SitemapFilled = (props: FilledIconProps) => (
  <Sitemap fill="currentColor" {...props} />
);

const SmartCloud1Filled = (props: FilledIconProps) => (
  <SmartCloud1 fill="currentColor" {...props} />
);

const SmartCloudFilled = (props: FilledIconProps) => (
  <SmartCloud fill="currentColor" {...props} />
);

const SmartHomeFilled = (props: FilledIconProps) => (
  <SmartHome fill="currentColor" {...props} />
);

const SoftwareDeploymentFilled = (props: FilledIconProps) => (
  <SoftwareDeployment fill="currentColor" {...props} />
);

const SpeedometerAlt1Filled = (props: FilledIconProps) => (
  <SpeedometerAlt1 fill="currentColor" {...props} />
);

const SpeedometerAltFilled = (props: FilledIconProps) => (
  <SpeedometerAlt fill="currentColor" {...props} />
);

const SpeedometerFilled = (props: FilledIconProps) => (
  <Speedometer fill="currentColor" {...props} />
);

const Ssd1Filled = (props: FilledIconProps) => (
  <Ssd1 fill="currentColor" {...props} />
);

const SsdFilled = (props: FilledIconProps) => (
  <Ssd fill="currentColor" {...props} />
);

const StarEmptyFilled = (props: FilledIconProps) => (
  <StarEmpty fill="currentColor" {...props} />
);

const StarHalfFilled = (props: FilledIconProps) => (
  <StarHalf fill="currentColor" {...props} />
);

const StarFilled = (props: FilledIconProps) => (
  <Star fill="currentColor" {...props} />
);

const SvgFilled = (props: FilledIconProps) => (
  <Svg fill="currentColor" {...props} />
);

const SwitchAltFilled = (props: FilledIconProps) => (
  <SwitchAlt fill="currentColor" {...props} />
);

const TabFilled = (props: FilledIconProps) => (
  <Tab fill="currentColor" {...props} />
);

const TapFilled = (props: FilledIconProps) => (
  <Tap fill="currentColor" {...props} />
);

const TerminalFilled = (props: FilledIconProps) => (
  <Terminal fill="currentColor" {...props} />
);

const TetherFilled = (props: FilledIconProps) => (
  <Tether fill="currentColor" {...props} />
);

const ThumbnailFilled = (props: FilledIconProps) => (
  <Thumbnail fill="currentColor" {...props} />
);

const TimerFilled = (props: FilledIconProps) => (
  <Timer fill="currentColor" {...props} />
);

const TrashCanAltFilled = (props: FilledIconProps) => (
  <TrashCanAlt fill="currentColor" {...props} />
);

const TrashCanFilled = (props: FilledIconProps) => (
  <TrashCan fill="currentColor" {...props} />
);

const Tv1Filled = (props: FilledIconProps) => (
  <Tv1 fill="currentColor" {...props} />
);

const TvFilled = (props: FilledIconProps) => (
  <Tv fill="currentColor" {...props} />
);

const UnlinkFilled = (props: FilledIconProps) => (
  <Unlink fill="currentColor" {...props} />
);

const UpServerFilled = (props: FilledIconProps) => (
  <UpServer fill="currentColor" {...props} />
);

const UploadAltFilled = (props: FilledIconProps) => (
  <UploadAlt fill="currentColor" {...props} />
);

const UploadFilled = (props: FilledIconProps) => (
  <Upload fill="currentColor" {...props} />
);

const UsbFilled = (props: FilledIconProps) => (
  <Usb fill="currentColor" {...props} />
);

const UserAlt2Filled = (props: FilledIconProps) => (
  <UserAlt2 fill="currentColor" {...props} />
);

const Users2Filled = (props: FilledIconProps) => (
  <Users2 fill="currentColor" {...props} />
);

const UsersFilled = (props: FilledIconProps) => (
  <Users fill="currentColor" {...props} />
);

const VectorNode1Filled = (props: FilledIconProps) => (
  <VectorNode1 fill="currentColor" {...props} />
);

const VectorNode11Filled = (props: FilledIconProps) => (
  <VectorNode11 fill="currentColor" {...props} />
);

const VectorNodeAltFilled = (props: FilledIconProps) => (
  <VectorNodeAlt fill="currentColor" {...props} />
);

const VectorNodeFilled = (props: FilledIconProps) => (
  <VectorNode fill="currentColor" {...props} />
);

const VirtualRealityAltFilled = (props: FilledIconProps) => (
  <VirtualRealityAlt fill="currentColor" {...props} />
);

const VrAlt1Filled = (props: FilledIconProps) => (
  <VrAlt1 fill="currentColor" {...props} />
);

const VrAltFilled = (props: FilledIconProps) => (
  <VrAlt fill="currentColor" {...props} />
);

const VrBrowserFilled = (props: FilledIconProps) => (
  <VrBrowser fill="currentColor" {...props} />
);

const VrBugFilled = (props: FilledIconProps) => (
  <VrBug fill="currentColor" {...props} />
);

const VrChatFilled = (props: FilledIconProps) => (
  <VrChat fill="currentColor" {...props} />
);

const VrDesignFilled = (props: FilledIconProps) => (
  <VrDesign fill="currentColor" {...props} />
);

const VrGlassesFilled = (props: FilledIconProps) => (
  <VrGlasses fill="currentColor" {...props} />
);

const VrLaptopFilled = (props: FilledIconProps) => (
  <VrLaptop fill="currentColor" {...props} />
);

const VrMonitor1Filled = (props: FilledIconProps) => (
  <VrMonitor1 fill="currentColor" {...props} />
);

const VrMonitor2Filled = (props: FilledIconProps) => (
  <VrMonitor2 fill="currentColor" {...props} />
);

const VrMonitorFilled = (props: FilledIconProps) => (
  <VrMonitor fill="currentColor" {...props} />
);

const VrMotionFilled = (props: FilledIconProps) => (
  <VrMotion fill="currentColor" {...props} />
);

const VrTechFilled = (props: FilledIconProps) => (
  <VrTech fill="currentColor" {...props} />
);

const VrFilled = (props: FilledIconProps) => (
  <Vr fill="currentColor" {...props} />
);

const WalkieTalkieFilled = (props: FilledIconProps) => (
  <WalkieTalkie fill="currentColor" {...props} />
);

const WebcamAltFilled = (props: FilledIconProps) => (
  <WebcamAlt fill="currentColor" {...props} />
);

const WebsiteAltFilled = (props: FilledIconProps) => (
  <WebsiteAlt fill="currentColor" {...props} />
);

const WebsiteBrowserFilled = (props: FilledIconProps) => (
  <WebsiteBrowser fill="currentColor" {...props} />
);

const WebsiteFilled = (props: FilledIconProps) => (
  <Website fill="currentColor" {...props} />
);

const WifiAltFilled = (props: FilledIconProps) => (
  <WifiAlt fill="currentColor" {...props} />
);

const WifiErrorFilled = (props: FilledIconProps) => (
  <WifiError fill="currentColor" {...props} />
);

const WifiFilled = (props: FilledIconProps) => (
  <Wifi fill="currentColor" {...props} />
);

const WindowMaximizeFilled = (props: FilledIconProps) => (
  <WindowMaximize fill="currentColor" {...props} />
);

const WindowRestoreFilled = (props: FilledIconProps) => (
  <WindowRestore fill="currentColor" {...props} />
);

const WireFilled = (props: FilledIconProps) => (
  <Wire fill="currentColor" {...props} />
);

const WordFilled = (props: FilledIconProps) => (
  <Word fill="currentColor" {...props} />
);

const ZipFilled = (props: FilledIconProps) => (
  <Zip fill="currentColor" {...props} />
);

const ZoomInAltFilled = (props: FilledIconProps) => (
  <ZoomInAlt fill="currentColor" {...props} />
);

const ZoomInFilled = (props: FilledIconProps) => (
  <ZoomIn fill="currentColor" {...props} />
);

const ZoomOutAltFilled = (props: FilledIconProps) => (
  <ZoomOutAlt fill="currentColor" {...props} />
);

const ZoomOutFilled = (props: FilledIconProps) => (
  <ZoomOut fill="currentColor" {...props} />
);

export {
  Icon360DegreeFilled,
  Icon3dFilled,
  Icon5gAlt1Filled,
  Icon5gSimFilled,
  Icon5gFilled,
  IOTFilled,
  AddAltFilled,
  AddFilesFilled,
  AddServerFilled,
  AiAltFilled,
  AiFilled,
  Alarm2Filled,
  Alarm3Filled,
  Alarm4Filled,
  AlarmClockAlt1Filled,
  AlarmClockFilled,
  AlarmFilled,
  AlartDatabaseFilled,
  AlertServerFilled,
  AppointmentFilled,
  ArchiveFilled,
  AutomationFilled,
  BanFilled,
  BatteryFilled,
  BellsFilled,
  BillboardAlt1Filled,
  BillboardAlt3Filled,
  BillboardAlt4Filled,
  BookmarkAltFilled,
  BookmarkFilled,
  Bot1Filled,
  BotFilled,
  BrightnessFilled,
  BulbAltFilled,
  BulbFilled,
  CalendarFilled,
  CalenderAlt1Filled,
  CalenderAlt2Filled,
  CalenderAlt3Filled,
  CalenderAlt4Filled,
  CalenderAltFilled,
  CameraAlt1Filled,
  CameraAltFilled,
  CameraFilled,
  CellphoneVr1Filled,
  ChangeFilled,
  ChargingFilled,
  CheckBoxAltFilled,
  CheckDatabaseFilled,
  CheckServerFilled,
  Cloud1Filled,
  Cloud3Filled,
  CloudCheckAltFilled,
  CloudCheckFilled,
  CloudComputing1Filled,
  CloudComputingFilled,
  CloudDownload1Filled,
  CloudDownloadFilled,
  CloudNetwork2Filled,
  CloudNetworkFilled,
  CloudSearch1Filled,
  CloudSearch2Filled,
  CloudSearchFilled,
  CloudServer1Filled,
  CloudServerFilled,
  CloudSyncAltFilled,
  CloudSyncFilled,
  CloudUploadAltFilled,
  CloudUploadFilled,
  CmsFilled,
  CodeAltFilled,
  CodeBrowserFilled,
  CodeCommitFilled,
  CodeMergeFilled,
  CodeFilled,
  CodingAlt1Filled,
  CodingAltFilled,
  CodingFilled,
  CogAltFilled,
  CogFilled,
  Cogs1Filled,
  Cogs2Filled,
  CogsFilled,
  ControlPanelAltFilled,
  ControlPanelFilled,
  CopyAltFilled,
  CopyFilled,
  CpuAltFilled,
  CutFilled,
  DashboardFilled,
  Database1Filled,
  Database10Filled,
  Database11Filled,
  Database2Filled,
  Database3Filled,
  Database7Filled,
  Database8Filled,
  Database9Filled,
  DatabaseDownFilled,
  DatabaseUpFilled,
  DatabaseFilled,
  DevopsFilled,
  DisplayAltFilled,
  DisplayFilled,
  DomainFilled,
  DownServerFilled,
  DownloadAltFilled,
  EmptyBatteryFilled,
  EmptyFileFilled,
  EventFilled,
  FigmaFilled,
  FileDownloadFilled,
  FileExclamationFilled,
  FileExportFilled,
  FileLockFilled,
  FileNameFilled,
  FilePenFilled,
  FileProtectionFilled,
  FileUploadAlt1Filled,
  FileUploadAltFilled,
  FileUploadFilled,
  FileFilled,
  FilesAltFilled,
  FilesFilled,
  FireExtinguisherFilled,
  FolderAlt1Filled,
  FolderAltFilled,
  FolderNetworking1Filled,
  FolderNetworking2Filled,
  FolderFilled,
  FullBatteryFilled,
  GameAltFilled,
  GameFilled,
  HalfBatteryFilled,
  HarddriveFilled,
  HeadphoneAltFilled,
  HeadphoneFilled,
  IdCardAltFilled,
  ImageFilled,
  IncognitoBrowser1Filled,
  IncognitoBrowserAltFilled,
  IncognitoBrowserFilled,
  InformationAltFilled,
  InfraredFilled,
  InternetAltFilled,
  IotMobileFilled,
  JpgFilled,
  KeyboardFilled,
  LaptopAltKeyboardFilled,
  LaptopAltSwitchFilled,
  LaptopAltFilled,
  LaptopPhoneFilled,
  LaptopStar1Filled,
  LaptopStarFilled,
  LaptopThumbsDownFilled,
  LaptopThumbsUpFilled,
  LaptopFilled,
  LinkAlt1Filled,
  LinkAlt2Filled,
  LinkAlt3Filled,
  LinkAltSplashFilled,
  LinkAltFilled,
  LinkFilled,
  LiveStreamAltFilled,
  LiveStreamFilled,
  LowBatteryFilled,
  MagnetFilled,
  MailServerFilled,
  MapAltFilled,
  MapMarkerFilled,
  MapFilled,
  MediaServerFilled,
  MenuAlt2Filled,
  MenuAlt3Filled,
  MenuAlt4Filled,
  MenuAlt5Filled,
  MenuAltFilled,
  MenuCircleFilled,
  MenuFilled,
  MergeFilled,
  MicFilled,
  MicrophoneAlt1Filled,
  MicrophoneAltFilled,
  MicrophoneFilled,
  MobileAltButtonFilled,
  MobileAltRoundedBottomFilled,
  MobileAltRoundedFilled,
  MobileApplicationAltFilled,
  MobileApplicationFilled,
  MobileFilled,
  MouseAltFilled,
  MouseFilled,
  Network1Filled,
  NetworkCloudFilled,
  NetworkingFilled,
  NoChargeBatteryFilled,
  PackageFilled,
  PaperPlaneFilled,
  PasteFilled,
  PdfFilled,
  PlugFilled,
  PngFilled,
  PresentationFilled,
  ProcessorAltFilled,
  ProgrammerFilled,
  PsdFilled,
  RecycleAltFilled,
  RecycleFilled,
  ReloadAltBoxFilled,
  ReloadAltFilled,
  ReloadBrowserFilled,
  RemoveFileFilled,
  RotationFilled,
  RssFeedFilled,
  SaveAltFilled,
  SaveFilled,
  ScanFilled,
  ScrollDownFilled,
  SearchAltFilled,
  SearchFilled,
  SendFilled,
  Server14Filled,
  Server15Filled,
  Server18Filled,
  Server19Filled,
  Server2Filled,
  Server3Filled,
  Server4Filled,
  Server5Filled,
  Server6Filled,
  Server7Filled,
  Server8Filled,
  Server9Filled,
  ServerFilled,
  Share1Filled,
  ShareAltFilled,
  ShareFilled,
  ShoppingBasketFilled,
  ShortcodeFilled,
  SignalFilled,
  SimCardFilled,
  SitemapFilled,
  SmartCloud1Filled,
  SmartCloudFilled,
  SmartHomeFilled,
  SoftwareDeploymentFilled,
  SpeedometerAlt1Filled,
  SpeedometerAltFilled,
  SpeedometerFilled,
  Ssd1Filled,
  SsdFilled,
  StarEmptyFilled,
  StarHalfFilled,
  StarFilled,
  SvgFilled,
  SwitchAltFilled,
  TabFilled,
  TapFilled,
  TerminalFilled,
  TetherFilled,
  ThumbnailFilled,
  TimerFilled,
  TrashCanAltFilled,
  TrashCanFilled,
  Tv1Filled,
  TvFilled,
  UnlinkFilled,
  UpServerFilled,
  UploadAltFilled,
  UploadFilled,
  UsbFilled,
  UserAlt2Filled,
  Users2Filled,
  UsersFilled,
  VectorNode1Filled,
  VectorNode11Filled,
  VectorNodeAltFilled,
  VectorNodeFilled,
  VirtualRealityAltFilled,
  VrAlt1Filled,
  VrAltFilled,
  VrBrowserFilled,
  VrBugFilled,
  VrChatFilled,
  VrDesignFilled,
  VrGlassesFilled,
  VrLaptopFilled,
  VrMonitor1Filled,
  VrMonitor2Filled,
  VrMonitorFilled,
  VrMotionFilled,
  VrTechFilled,
  VrFilled,
  WalkieTalkieFilled,
  WebcamAltFilled,
  WebsiteAltFilled,
  WebsiteBrowserFilled,
  WebsiteFilled,
  WifiAltFilled,
  WifiErrorFilled,
  WifiFilled,
  WindowMaximizeFilled,
  WindowRestoreFilled,
  WireFilled,
  WordFilled,
  ZipFilled,
  ZoomInAltFilled,
  ZoomInFilled,
  ZoomOutAltFilled,
  ZoomOutFilled,
};
