import { easeOut, m } from 'framer-motion';
import type { CSSProperties } from 'react';

import { cn } from '../../../../lib/utils';
import { CARDS, GRID_GAP } from './constants';
import { PipeGrid } from './PipeGrid';
import { SuiteCard } from './SuiteCard';

export function SuiteGrid() {
  return (
    <m.div
      initial={{ opacity: 0, x: 20 }}
      whileInView={{
        opacity: 1,
        x: 0,
        transition: {
          duration: 1.4,
          type: 'spring',
          ease: easeOut,
        },
      }}
      viewport={{ amount: 0.5, once: true }}
      className="grid w-full md:w-[calc(110px*4+(var(--gap)*3))] aspect-square justify-self-start container-[size] relative gap-[var(--gap)] grid-cols-4 grid-rows-4"
      style={
        {
          '--gap': `${GRID_GAP / 4}rem`,
          gridTemplateAreas: `
  '. b . .'
  'a . d f'
  '. c . g'
  '. . e .'
        `,
        } as CSSProperties
      }
    >
      <div
        className={cn(
          'hidden lg:block',
          'absolute inset-0 bg-graph-paper -translate-y-[calc(1*var(--cell-height))] -translate-x-[calc(3*var(--cell-width))] -mt-[calc(var(--gap)/2)] -ml-[calc(var(--gap)/2)]',
          '[--cell-width:calc((100cqw/8)+(var(--gap)/4))]',
          '[--cell-height:calc((100cqh/8)+(var(--gap)/4))]',
          '[background-size:var(--cell-width)_var(--cell-height)]',
          'w-screen h-[calc(100vh+var(--cell-height))]',
          'mask-to-l reach-closest-side',
          'pointer-events-none',
        )}
      />

      {CARDS.map(({ gridArea, ...props }) => (
        <SuiteCard
          {...props}
          key={JSON.stringify({ gridArea, ...props })}
          style={{ gridArea }}
        />
      ))}

      <PipeGrid />
    </m.div>
  );
}
