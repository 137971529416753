import { m } from 'framer-motion';

import { SignalCarousel } from '../../../components/SignalCarousel';
import { Typography } from '../../../components/ui/Typography';

const cards = [
  {
    name: 'User Risk Score',
    partner: 'Cove',
  },
  {
    name: 'Insult Score',
    partner: 'Perspective',
  },
  {
    name: 'Nudity Score',
    partner: 'AWS Rekognition',
  },
  {
    name: 'Hate Speech Score',
    partner: 'OpenAI',
  },
  {
    name: 'Drug Sales Score',
    partner: 'Cove',
  },
  {
    name: 'Phishing Score',
    partner: 'Cove',
  },
  {
    name: 'Counterfeit Goods Score',
    partner: 'Cove',
  },
  {
    name: 'Sexual Harassment Score',
    partner: 'Cove',
  },
  {
    name: 'Weapons Score',
    partner: 'Cove',
  },
];

export function AutomatedEnforcement() {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex w-full max-w-[455px] flex-col gap-3">
        <m.img
          initial={{ y: 6, opacity: 0 }}
          animate={{
            y: 0,
            opacity: 1,
            transition: { duration: 0.5, delay: 0.2 },
          }}
          src="/graphics/conditional1.svg"
          alt=""
        />
        <m.img
          initial={{ y: 6, opacity: 0 }}
          animate={{
            y: 0,
            opacity: 1,
            transition: { duration: 0.5, delay: 0.5 },
          }}
          src="/graphics/conditional2.svg"
          alt=""
        />
      </div>

      <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 1, delay: 0.7 } }}
        className="flex flex-col w-full"
      >
        <Typography variant="title" size="sm" className="py-8 text-center">
          Signal Library
        </Typography>

        <SignalCarousel cards={cards} />
      </m.div>
    </div>
  );
}
