import { cva, type VariantProps } from 'class-variance-authority';
import { forwardRef } from 'react';

const pillVariants = cva(
  'rounded-full whitespace-nowrap transition-all duration-1000 ease-in-out px-4 py-2 border text-xs font-medium',
  {
    variants: {
      color: {
        default: 'text-subtle',
        primary: 'bg-primary text-inverted',
        accent: 'bg-[#3E8EFF] text-inverted',
      },
    },
    defaultVariants: {
      color: 'default',
    },
  },
);

interface PillProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'color'>,
    VariantProps<typeof pillVariants> {}

export const Pill = forwardRef<HTMLDivElement, PillProps>(
  ({ children, color }, ref) => {
    return (
      <div ref={ref} className={pillVariants({ color })}>
        {children}
      </div>
    );
  },
);
