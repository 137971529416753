import { m, type Variants } from "framer-motion";

const dotsVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.25,
      delayChildren: 0.8,
    },
  },
};

const dotVariants: Variants = {
  hidden: {
    scale: 0,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 1,
      type: "spring",
      bounce: 0.5,
    },
  },
};

interface GraphDotsProps {
  animate: boolean;
}

export function GraphDots({ animate }: GraphDotsProps) {
  return (
    <m.g
      variants={dotsVariants}
      animate={animate ? "visible" : "hidden"}
      initial="hidden"
    >
      <m.g variants={dotVariants}>
        <ellipse cx="96.836" cy="117.803" fill="#fff" rx="5.836" ry="5.822" />
        <ellipse
          cx="96.836"
          cy="117.804"
          fill="#0C313B"
          rx="1.945"
          ry="1.941"
        />
      </m.g>

      <m.g variants={dotVariants}>
        <ellipse cx="206.836" cy="160.883" fill="#fff" rx="5.836" ry="5.822" />
        <ellipse
          cx="206.836"
          cy="160.884"
          fill="#0C313B"
          rx="1.945"
          ry="1.941"
        />
      </m.g>

      <m.g variants={dotVariants} filter="url(#b)">
        <ellipse cx="306.836" cy="260.289" fill="#fff" rx="5.836" ry="5.822" />
        <ellipse
          cx="306.836"
          cy="260.29"
          fill="#0C313B"
          rx="1.945"
          ry="1.941"
        />
      </m.g>

      <m.g variants={dotVariants}>
        <ellipse cx="414.836" cy="377.008" fill="#fff" rx="5.836" ry="5.822" />
        <ellipse
          cx="414.836"
          cy="377.009"
          fill="#0C313B"
          rx="1.945"
          ry="1.941"
        />
      </m.g>

      <m.g variants={dotVariants}>
        <ellipse cx="517.836" cy="320.145" fill="#fff" rx="5.836" ry="5.822" />
        <ellipse
          cx="517.836"
          cy="320.146"
          fill="#0C313B"
          rx="1.945"
          ry="1.941"
        />
      </m.g>

      <m.g variants={dotVariants}>
        <ellipse cx="619.836" cy="234.351" fill="#fff" rx="5.836" ry="5.822" />
        <ellipse
          cx="619.836"
          cy="234.352"
          fill="#0C313B"
          rx="1.945"
          ry="1.941"
        />
      </m.g>

      <m.g variants={dotVariants}>
        <ellipse cx="724.836" cy="143.569" fill="#fff" rx="5.836" ry="5.822" />
        <ellipse
          cx="724.836"
          cy="143.569"
          fill="#0C313B"
          rx="1.945"
          ry="1.941"
        />
      </m.g>
    </m.g>
  );
}
