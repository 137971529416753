import type { FilledIconProps } from '../types';
import { ReactComponent as Icon247Alt } from './24-7-alt.svg';
import { ReactComponent as Icon247 } from './24-7.svg';
import { ReactComponent as Icon24HoursTruck } from './24-hours-truck.svg';
import { ReactComponent as AddBasket } from './add-basket.svg';
import { ReactComponent as AddCart1 } from './add-cart-1.svg';
import { ReactComponent as AddCart21 } from './add-cart-2-1.svg';
import { ReactComponent as AddCart2 } from './add-cart-2.svg';
import { ReactComponent as AddCart } from './add-cart.svg';
import { ReactComponent as AeroplaneDelivery } from './aeroplane-delivery.svg';
import { ReactComponent as AirMail } from './air-mail.svg';
import { ReactComponent as BadgeDollar } from './badge-dollar.svg';
import { ReactComponent as BarCodeSearch } from './bar-code-search.svg';
import { ReactComponent as Barcode } from './barcode.svg';
import { ReactComponent as Basket1 } from './basket-1.svg';
import { ReactComponent as Basket2 } from './basket-2.svg';
import { ReactComponent as Bills1 } from './bills-1.svg';
import { ReactComponent as Bills } from './bills.svg';
import { ReactComponent as Box1 } from './box-1.svg';
import { ReactComponent as Box2 } from './box-2.svg';
import { ReactComponent as Box3 } from './box-3.svg';
import { ReactComponent as Box4 } from './box-4.svg';
import { ReactComponent as Box5 } from './box-5.svg';
import { ReactComponent as Box6 } from './box-6.svg';
import { ReactComponent as Box7 } from './box-7.svg';
import { ReactComponent as Box8 } from './box-8.svg';
import { ReactComponent as Box9 } from './box-9.svg';
import { ReactComponent as Box10 } from './box-10.svg';
import { ReactComponent as BoxAdd } from './box-add.svg';
import { ReactComponent as BoxCart } from './box-cart.svg';
import { ReactComponent as BoxOpen } from './box-open.svg';
import { ReactComponent as BoxPercentage } from './box-percentage.svg';
import { ReactComponent as BoxRemove } from './box-remove.svg';
import { ReactComponent as Boxes } from './boxes.svg';
import { ReactComponent as CalculatorAlt } from './calculator-alt.svg';
import { ReactComponent as CalculatorCard } from './calculator-card.svg';
import { ReactComponent as CalculatorPaper } from './calculator-paper.svg';
import { ReactComponent as CargoMover } from './cargo-mover.svg';
import { ReactComponent as Cash } from './cash.svg';
import { ReactComponent as CheckSign } from './check-sign.svg';
import { ReactComponent as CloseSign } from './close-sign.svg';
import { ReactComponent as CoinsAlt } from './coins-alt.svg';
import { ReactComponent as CommercialMachine } from './commercial-machine.svg';
import { ReactComponent as CouponScissors } from './coupon-scissors.svg';
import { ReactComponent as CreditCardDelete } from './credit-card-delete.svg';
import { ReactComponent as CreditCardLock } from './credit-card-lock.svg';
import { ReactComponent as CreditCardsAlt } from './credit-cards-alt.svg';
import { ReactComponent as Cube } from './cube.svg';
import { ReactComponent as DeliveryTruck1 } from './delivery-truck-1.svg';
import { ReactComponent as DeliveryTruck2 } from './delivery-truck-2.svg';
import { ReactComponent as DeliveryTruck3 } from './delivery-truck-3.svg';
import { ReactComponent as DeliveryTruck4 } from './delivery-truck-4.svg';
import { ReactComponent as DeliveryTruck } from './delivery-truck.svg';
import { ReactComponent as DiscountLabel } from './discount-label.svg';
import { ReactComponent as Dislike } from './dislike.svg';
import { ReactComponent as DollarBubble } from './dollar-bubble.svg';
import { ReactComponent as DollarBundle } from './dollar-bundle.svg';
import { ReactComponent as DollarCoinStack } from './dollar-coin-stack.svg';
import { ReactComponent as DollarMonitor } from './dollar-monitor.svg';
import { ReactComponent as DollarSettings } from './dollar-settings.svg';
import { ReactComponent as DollarSymbol } from './dollar-symbol.svg';
import { ReactComponent as DollarsGift } from './dollars-gift.svg';
import { ReactComponent as DumpTruck } from './dump-truck.svg';
import { ReactComponent as EuroCoinStack } from './euro-coin-stack.svg';
import { ReactComponent as ExpressShopping } from './express-shopping.svg';
import { ReactComponent as FAQ } from './FAQ.svg';
import { ReactComponent as FastDelivery } from './fast-delivery.svg';
import { ReactComponent as FuelTruckAlt } from './fuel-truck-alt.svg';
import { ReactComponent as HeadphonesAlt } from './headphones-alt.svg';
import { ReactComponent as IdCard } from './id-card.svg';
import { ReactComponent as IntCommercialMail } from './int-commercial-mail.svg';
import { ReactComponent as IntEcommerce } from './int-ecommerce.svg';
import { ReactComponent as Invoice1 } from './invoice-1.svg';
import { ReactComponent as LabelNew } from './label-new.svg';
import { ReactComponent as LabelOff } from './label-off.svg';
import { ReactComponent as Like2 } from './like-2.svg';
import { ReactComponent as Like3 } from './like-3.svg';
import { ReactComponent as Like } from './like.svg';
import { ReactComponent as LockedWindow } from './locked-window.svg';
import { ReactComponent as Mathematics } from './mathematics.svg';
import { ReactComponent as MedalFirstAlt } from './medal-first-alt.svg';
import { ReactComponent as MoneyBagAlt } from './money-bag-alt.svg';
import { ReactComponent as MoneySecurity } from './money-security.svg';
import { ReactComponent as MonitorDollarLock } from './monitor-dollar-lock.svg';
import { ReactComponent as Newspaper } from './newspaper.svg';
import { ReactComponent as NfcSymbol } from './nfc-symbol.svg';
import { ReactComponent as OilTanker } from './oil-tanker.svg';
import { ReactComponent as OnlineShopping } from './online-shopping.svg';
import { ReactComponent as OpenSign } from './open-sign.svg';
import { ReactComponent as PackageBox } from './package-box.svg';
import { ReactComponent as Padlock } from './padlock.svg';
import { ReactComponent as PaidSign } from './paid-sign.svg';
import { ReactComponent as ParachuteParcel } from './parachute-parcel.svg';
import { ReactComponent as ParcelAir } from './parcel-air.svg';
import { ReactComponent as PenAlt } from './pen-alt.svg';
import { ReactComponent as PercentageAlt } from './percentage-alt.svg';
import { ReactComponent as PercentageTicket } from './percentage-ticket.svg';
import { ReactComponent as PiggyBank11 } from './piggy-bank-1-1.svg';
import { ReactComponent as PiggyBank1 } from './piggy-bank-1.svg';
import { ReactComponent as PiggyBank } from './piggy-bank.svg';
import { ReactComponent as PriceTag1 } from './price-tag-1.svg';
import { ReactComponent as PriceTag } from './price-tag.svg';
import { ReactComponent as Profits } from './profits.svg';
import { ReactComponent as RemoveBasket } from './remove-basket.svg';
import { ReactComponent as RemoveCart } from './remove-cart.svg';
import { ReactComponent as SaleSign } from './sale-sign.svg';
import { ReactComponent as SavingPig } from './saving-pig.svg';
import { ReactComponent as SearchStore } from './search-store.svg';
import { ReactComponent as SecureShopping } from './secure-shopping.svg';
import { ReactComponent as ShieldAlt1 } from './shield-alt-1.svg';
import { ReactComponent as ShieldAlt } from './shield-alt.svg';
import { ReactComponent as ShipCargo } from './ship-cargo.svg';
import { ReactComponent as ShipCheck } from './ship-check.svg';
import { ReactComponent as ShipContainer } from './ship-container.svg';
import { ReactComponent as ShipDeliveryBoxAlt1 } from './ship-delivery-box-alt-1.svg';
import { ReactComponent as ShipDeliveryBoxAlt } from './ship-delivery-box-alt.svg';
import { ReactComponent as ShipDeliveryBox } from './ship-delivery-box.svg';
import { ReactComponent as ShipDownArrow } from './ship-down-arrow.svg';
import { ReactComponent as ShipFavourite } from './ship-favourite.svg';
import { ReactComponent as ShipInternational } from './ship-international.svg';
import { ReactComponent as ShipLocked } from './ship-locked.svg';
import { ReactComponent as ShipReload } from './ship-reload.svg';
import { ReactComponent as ShipSearch } from './ship-search.svg';
import { ReactComponent as ShipSecure } from './ship-secure.svg';
import { ReactComponent as ShipStar } from './ship-star.svg';
import { ReactComponent as ShipStop } from './ship-stop.svg';
import { ReactComponent as ShipThumbsUp } from './ship-thumbs-up.svg';
import { ReactComponent as ShipTimer } from './ship-timer.svg';
import { ReactComponent as ShipUnknown } from './ship-unknown.svg';
import { ReactComponent as ShipUpArrow } from './ship-up-arrow.svg';
import { ReactComponent as ShipWarning } from './ship-warning.svg';
import { ReactComponent as ShoppingBag1 } from './shopping-bag-1.svg';
import { ReactComponent as ShoppingBag2 } from './shopping-bag-2.svg';
import { ReactComponent as ShoppingBag3 } from './shopping-bag-3.svg';
import { ReactComponent as ShoppingBag4 } from './shopping-bag-4.svg';
import { ReactComponent as ShoppingBag5 } from './shopping-bag-5.svg';
import { ReactComponent as ShoppingBag6 } from './shopping-bag-6.svg';
import { ReactComponent as ShoppingBag7 } from './shopping-bag-7.svg';
import { ReactComponent as ShoppingBag8 } from './shopping-bag-8.svg';
import { ReactComponent as ShoppingBag9 } from './shopping-bag-9.svg';
import { ReactComponent as ShoppingBag10 } from './shopping-bag-10.svg';
import { ReactComponent as ShoppingBag11 } from './shopping-bag-11.svg';
import { ReactComponent as ShoppingBag12 } from './shopping-bag-12.svg';
import { ReactComponent as ShoppingBag13 } from './shopping-bag-13.svg';
import { ReactComponent as ShoppingBag14 } from './shopping-bag-14.svg';
import { ReactComponent as ShoppingBag15 } from './shopping-bag-15.svg';
import { ReactComponent as ShoppingBag16 } from './shopping-bag-16.svg';
import { ReactComponent as ShoppingBag17 } from './shopping-bag-17.svg';
import { ReactComponent as ShoppingCart1 } from './shopping-cart-1.svg';
import { ReactComponent as ShoppingCart2 } from './shopping-cart-2.svg';
import { ReactComponent as ShoppingCart3 } from './shopping-cart-3.svg';
import { ReactComponent as ShoppingCart4 } from './shopping-cart-4.svg';
import { ReactComponent as ShoppingCart6 } from './shopping-cart-6.svg';
import { ReactComponent as ShoppingCart7 } from './shopping-cart-7.svg';
import { ReactComponent as ShoppingCart8 } from './shopping-cart-8.svg';
import { ReactComponent as ShoppingCart9 } from './shopping-cart-9.svg';
import { ReactComponent as ShoppingCart10 } from './shopping-cart-10.svg';
import { ReactComponent as ShoppingCart11 } from './shopping-cart-11.svg';
import { ReactComponent as ShoppingCart12 } from './shopping-cart-12.svg';
import { ReactComponent as ShoppingMobile } from './shopping-mobile.svg';
import { ReactComponent as SignPole } from './sign-pole.svg';
import { ReactComponent as StarDollar } from './star-dollar.svg';
import { ReactComponent as Telephone2 } from './telephone-2.svg';
import { ReactComponent as Telephone3 } from './telephone-3.svg';
import { ReactComponent as Trailer } from './trailer.svg';
import { ReactComponent as TruckBlocked } from './truck-blocked.svg';
import { ReactComponent as TruckBox1 } from './truck-box-1.svg';
import { ReactComponent as TruckBox } from './truck-box.svg';
import { ReactComponent as TruckChecked } from './truck-checked.svg';
import { ReactComponent as TruckDownArrow } from './truck-down-arrow.svg';
import { ReactComponent as TruckFeedback } from './truck-feedback.svg';
import { ReactComponent as TruckGlobal } from './truck-global.svg';
import { ReactComponent as TruckLocation } from './truck-location.svg';
import { ReactComponent as TruckPrivate } from './truck-private.svg';
import { ReactComponent as TruckReload } from './truck-reload.svg';
import { ReactComponent as TruckSearch } from './truck-search.svg';
import { ReactComponent as TruckSecure } from './truck-secure.svg';
import { ReactComponent as TruckStar } from './truck-star.svg';
import { ReactComponent as TruckTimer } from './truck-timer.svg';
import { ReactComponent as TruckUnknown } from './truck-unknown.svg';
import { ReactComponent as TruckUpArrow } from './truck-up-arrow.svg';
import { ReactComponent as TruckWarning } from './truck-warning.svg';

const Icon247AltFilled = (props: FilledIconProps) => (
  <Icon247Alt fill="currentColor" {...props} />
);

const Icon247Filled = (props: FilledIconProps) => (
  <Icon247 fill="currentColor" {...props} />
);

const Icon24HoursTruckFilled = (props: FilledIconProps) => (
  <Icon24HoursTruck fill="currentColor" {...props} />
);

const FAQFilled = (props: FilledIconProps) => (
  <FAQ fill="currentColor" {...props} />
);

const AddBasketFilled = (props: FilledIconProps) => (
  <AddBasket fill="currentColor" {...props} />
);

const AddCart1Filled = (props: FilledIconProps) => (
  <AddCart1 fill="currentColor" {...props} />
);

const AddCart21Filled = (props: FilledIconProps) => (
  <AddCart21 fill="currentColor" {...props} />
);

const AddCart2Filled = (props: FilledIconProps) => (
  <AddCart2 fill="currentColor" {...props} />
);

const AddCartFilled = (props: FilledIconProps) => (
  <AddCart fill="currentColor" {...props} />
);

const AeroplaneDeliveryFilled = (props: FilledIconProps) => (
  <AeroplaneDelivery fill="currentColor" {...props} />
);

const AirMailFilled = (props: FilledIconProps) => (
  <AirMail fill="currentColor" {...props} />
);

const BadgeDollarFilled = (props: FilledIconProps) => (
  <BadgeDollar fill="currentColor" {...props} />
);

const BarCodeSearchFilled = (props: FilledIconProps) => (
  <BarCodeSearch fill="currentColor" {...props} />
);

const BarcodeFilled = (props: FilledIconProps) => (
  <Barcode fill="currentColor" {...props} />
);

const Basket1Filled = (props: FilledIconProps) => (
  <Basket1 fill="currentColor" {...props} />
);

const Basket2Filled = (props: FilledIconProps) => (
  <Basket2 fill="currentColor" {...props} />
);

const Bills1Filled = (props: FilledIconProps) => (
  <Bills1 fill="currentColor" {...props} />
);

const BillsFilled = (props: FilledIconProps) => (
  <Bills fill="currentColor" {...props} />
);

const Box1Filled = (props: FilledIconProps) => (
  <Box1 fill="currentColor" {...props} />
);

const Box10Filled = (props: FilledIconProps) => (
  <Box10 fill="currentColor" {...props} />
);

const Box2Filled = (props: FilledIconProps) => (
  <Box2 fill="currentColor" {...props} />
);

const Box3Filled = (props: FilledIconProps) => (
  <Box3 fill="currentColor" {...props} />
);

const Box4Filled = (props: FilledIconProps) => (
  <Box4 fill="currentColor" {...props} />
);

const Box5Filled = (props: FilledIconProps) => (
  <Box5 fill="currentColor" {...props} />
);

const Box6Filled = (props: FilledIconProps) => (
  <Box6 fill="currentColor" {...props} />
);

const Box7Filled = (props: FilledIconProps) => (
  <Box7 fill="currentColor" {...props} />
);

const Box8Filled = (props: FilledIconProps) => (
  <Box8 fill="currentColor" {...props} />
);

const Box9Filled = (props: FilledIconProps) => (
  <Box9 fill="currentColor" {...props} />
);

const BoxAddFilled = (props: FilledIconProps) => (
  <BoxAdd fill="currentColor" {...props} />
);

const BoxCartFilled = (props: FilledIconProps) => (
  <BoxCart fill="currentColor" {...props} />
);

const BoxOpenFilled = (props: FilledIconProps) => (
  <BoxOpen fill="currentColor" {...props} />
);

const BoxPercentageFilled = (props: FilledIconProps) => (
  <BoxPercentage fill="currentColor" {...props} />
);

const BoxRemoveFilled = (props: FilledIconProps) => (
  <BoxRemove fill="currentColor" {...props} />
);

const BoxesFilled = (props: FilledIconProps) => (
  <Boxes fill="currentColor" {...props} />
);

const CalculatorAltFilled = (props: FilledIconProps) => (
  <CalculatorAlt fill="currentColor" {...props} />
);

const CalculatorCardFilled = (props: FilledIconProps) => (
  <CalculatorCard fill="currentColor" {...props} />
);

const CalculatorPaperFilled = (props: FilledIconProps) => (
  <CalculatorPaper fill="currentColor" {...props} />
);

const CargoMoverFilled = (props: FilledIconProps) => (
  <CargoMover fill="currentColor" {...props} />
);

const CashFilled = (props: FilledIconProps) => (
  <Cash fill="currentColor" {...props} />
);

const CheckSignFilled = (props: FilledIconProps) => (
  <CheckSign fill="currentColor" {...props} />
);

const CloseSignFilled = (props: FilledIconProps) => (
  <CloseSign fill="currentColor" {...props} />
);

const CoinsAltFilled = (props: FilledIconProps) => (
  <CoinsAlt fill="currentColor" {...props} />
);

const CommercialMachineFilled = (props: FilledIconProps) => (
  <CommercialMachine fill="currentColor" {...props} />
);

const CouponScissorsFilled = (props: FilledIconProps) => (
  <CouponScissors fill="currentColor" {...props} />
);

const CreditCardDeleteFilled = (props: FilledIconProps) => (
  <CreditCardDelete fill="currentColor" {...props} />
);

const CreditCardLockFilled = (props: FilledIconProps) => (
  <CreditCardLock fill="currentColor" {...props} />
);

const CreditCardsAltFilled = (props: FilledIconProps) => (
  <CreditCardsAlt fill="currentColor" {...props} />
);

const CubeFilled = (props: FilledIconProps) => (
  <Cube fill="currentColor" {...props} />
);

const DeliveryTruck1Filled = (props: FilledIconProps) => (
  <DeliveryTruck1 fill="currentColor" {...props} />
);

const DeliveryTruck2Filled = (props: FilledIconProps) => (
  <DeliveryTruck2 fill="currentColor" {...props} />
);

const DeliveryTruck3Filled = (props: FilledIconProps) => (
  <DeliveryTruck3 fill="currentColor" {...props} />
);

const DeliveryTruck4Filled = (props: FilledIconProps) => (
  <DeliveryTruck4 fill="currentColor" {...props} />
);

const DeliveryTruckFilled = (props: FilledIconProps) => (
  <DeliveryTruck fill="currentColor" {...props} />
);

const DiscountLabelFilled = (props: FilledIconProps) => (
  <DiscountLabel fill="currentColor" {...props} />
);

const DislikeFilled = (props: FilledIconProps) => (
  <Dislike fill="currentColor" {...props} />
);

const DollarBubbleFilled = (props: FilledIconProps) => (
  <DollarBubble fill="currentColor" {...props} />
);

const DollarBundleFilled = (props: FilledIconProps) => (
  <DollarBundle fill="currentColor" {...props} />
);

const DollarCoinStackFilled = (props: FilledIconProps) => (
  <DollarCoinStack fill="currentColor" {...props} />
);

const DollarMonitorFilled = (props: FilledIconProps) => (
  <DollarMonitor fill="currentColor" {...props} />
);

const DollarSettingsFilled = (props: FilledIconProps) => (
  <DollarSettings fill="currentColor" {...props} />
);

const DollarSymbolFilled = (props: FilledIconProps) => (
  <DollarSymbol fill="currentColor" {...props} />
);

const DollarsGiftFilled = (props: FilledIconProps) => (
  <DollarsGift fill="currentColor" {...props} />
);

const DumpTruckFilled = (props: FilledIconProps) => (
  <DumpTruck fill="currentColor" {...props} />
);

const EuroCoinStackFilled = (props: FilledIconProps) => (
  <EuroCoinStack fill="currentColor" {...props} />
);

const ExpressShoppingFilled = (props: FilledIconProps) => (
  <ExpressShopping fill="currentColor" {...props} />
);

const FastDeliveryFilled = (props: FilledIconProps) => (
  <FastDelivery fill="currentColor" {...props} />
);

const FuelTruckAltFilled = (props: FilledIconProps) => (
  <FuelTruckAlt fill="currentColor" {...props} />
);

const HeadphonesAltFilled = (props: FilledIconProps) => (
  <HeadphonesAlt fill="currentColor" {...props} />
);

const IdCardFilled = (props: FilledIconProps) => (
  <IdCard fill="currentColor" {...props} />
);

const IntCommercialMailFilled = (props: FilledIconProps) => (
  <IntCommercialMail fill="currentColor" {...props} />
);

const IntEcommerceFilled = (props: FilledIconProps) => (
  <IntEcommerce fill="currentColor" {...props} />
);

const Invoice1Filled = (props: FilledIconProps) => (
  <Invoice1 fill="currentColor" {...props} />
);

const LabelNewFilled = (props: FilledIconProps) => (
  <LabelNew fill="currentColor" {...props} />
);

const LabelOffFilled = (props: FilledIconProps) => (
  <LabelOff fill="currentColor" {...props} />
);

const Like2Filled = (props: FilledIconProps) => (
  <Like2 fill="currentColor" {...props} />
);

const Like3Filled = (props: FilledIconProps) => (
  <Like3 fill="currentColor" {...props} />
);

const LikeFilled = (props: FilledIconProps) => (
  <Like fill="currentColor" {...props} />
);

const LockedWindowFilled = (props: FilledIconProps) => (
  <LockedWindow fill="currentColor" {...props} />
);

const MathematicsFilled = (props: FilledIconProps) => (
  <Mathematics fill="currentColor" {...props} />
);

const MedalFirstAltFilled = (props: FilledIconProps) => (
  <MedalFirstAlt fill="currentColor" {...props} />
);

const MoneyBagAltFilled = (props: FilledIconProps) => (
  <MoneyBagAlt fill="currentColor" {...props} />
);

const MoneySecurityFilled = (props: FilledIconProps) => (
  <MoneySecurity fill="currentColor" {...props} />
);

const MonitorDollarLockFilled = (props: FilledIconProps) => (
  <MonitorDollarLock fill="currentColor" {...props} />
);

const NewspaperFilled = (props: FilledIconProps) => (
  <Newspaper fill="currentColor" {...props} />
);

const NfcSymbolFilled = (props: FilledIconProps) => (
  <NfcSymbol fill="currentColor" {...props} />
);

const OilTankerFilled = (props: FilledIconProps) => (
  <OilTanker fill="currentColor" {...props} />
);

const OnlineShoppingFilled = (props: FilledIconProps) => (
  <OnlineShopping fill="currentColor" {...props} />
);

const OpenSignFilled = (props: FilledIconProps) => (
  <OpenSign fill="currentColor" {...props} />
);

const PackageBoxFilled = (props: FilledIconProps) => (
  <PackageBox fill="currentColor" {...props} />
);

const PadlockFilled = (props: FilledIconProps) => (
  <Padlock fill="currentColor" {...props} />
);

const PaidSignFilled = (props: FilledIconProps) => (
  <PaidSign fill="currentColor" {...props} />
);

const ParachuteParcelFilled = (props: FilledIconProps) => (
  <ParachuteParcel fill="currentColor" {...props} />
);

const ParcelAirFilled = (props: FilledIconProps) => (
  <ParcelAir fill="currentColor" {...props} />
);

const PenAltFilled = (props: FilledIconProps) => (
  <PenAlt fill="currentColor" {...props} />
);

const PercentageAltFilled = (props: FilledIconProps) => (
  <PercentageAlt fill="currentColor" {...props} />
);

const PercentageTicketFilled = (props: FilledIconProps) => (
  <PercentageTicket fill="currentColor" {...props} />
);

const PiggyBank11Filled = (props: FilledIconProps) => (
  <PiggyBank11 fill="currentColor" {...props} />
);

const PiggyBank1Filled = (props: FilledIconProps) => (
  <PiggyBank1 fill="currentColor" {...props} />
);

const PiggyBankFilled = (props: FilledIconProps) => (
  <PiggyBank fill="currentColor" {...props} />
);

const PriceTag1Filled = (props: FilledIconProps) => (
  <PriceTag1 fill="currentColor" {...props} />
);

const PriceTagFilled = (props: FilledIconProps) => (
  <PriceTag fill="currentColor" {...props} />
);

const ProfitsFilled = (props: FilledIconProps) => (
  <Profits fill="currentColor" {...props} />
);

const RemoveBasketFilled = (props: FilledIconProps) => (
  <RemoveBasket fill="currentColor" {...props} />
);

const RemoveCartFilled = (props: FilledIconProps) => (
  <RemoveCart fill="currentColor" {...props} />
);

const SaleSignFilled = (props: FilledIconProps) => (
  <SaleSign fill="currentColor" {...props} />
);

const SavingPigFilled = (props: FilledIconProps) => (
  <SavingPig fill="currentColor" {...props} />
);

const SearchStoreFilled = (props: FilledIconProps) => (
  <SearchStore fill="currentColor" {...props} />
);

const SecureShoppingFilled = (props: FilledIconProps) => (
  <SecureShopping fill="currentColor" {...props} />
);

const ShieldAlt1Filled = (props: FilledIconProps) => (
  <ShieldAlt1 fill="currentColor" {...props} />
);

const ShieldAltFilled = (props: FilledIconProps) => (
  <ShieldAlt fill="currentColor" {...props} />
);

const ShipCargoFilled = (props: FilledIconProps) => (
  <ShipCargo fill="currentColor" {...props} />
);

const ShipCheckFilled = (props: FilledIconProps) => (
  <ShipCheck fill="currentColor" {...props} />
);

const ShipContainerFilled = (props: FilledIconProps) => (
  <ShipContainer fill="currentColor" {...props} />
);

const ShipDeliveryBoxAlt1Filled = (props: FilledIconProps) => (
  <ShipDeliveryBoxAlt1 fill="currentColor" {...props} />
);

const ShipDeliveryBoxAltFilled = (props: FilledIconProps) => (
  <ShipDeliveryBoxAlt fill="currentColor" {...props} />
);

const ShipDeliveryBoxFilled = (props: FilledIconProps) => (
  <ShipDeliveryBox fill="currentColor" {...props} />
);

const ShipDownArrowFilled = (props: FilledIconProps) => (
  <ShipDownArrow fill="currentColor" {...props} />
);

const ShipFavouriteFilled = (props: FilledIconProps) => (
  <ShipFavourite fill="currentColor" {...props} />
);

const ShipInternationalFilled = (props: FilledIconProps) => (
  <ShipInternational fill="currentColor" {...props} />
);

const ShipLockedFilled = (props: FilledIconProps) => (
  <ShipLocked fill="currentColor" {...props} />
);

const ShipReloadFilled = (props: FilledIconProps) => (
  <ShipReload fill="currentColor" {...props} />
);

const ShipSearchFilled = (props: FilledIconProps) => (
  <ShipSearch fill="currentColor" {...props} />
);

const ShipSecureFilled = (props: FilledIconProps) => (
  <ShipSecure fill="currentColor" {...props} />
);

const ShipStarFilled = (props: FilledIconProps) => (
  <ShipStar fill="currentColor" {...props} />
);

const ShipStopFilled = (props: FilledIconProps) => (
  <ShipStop fill="currentColor" {...props} />
);

const ShipThumbsUpFilled = (props: FilledIconProps) => (
  <ShipThumbsUp fill="currentColor" {...props} />
);

const ShipTimerFilled = (props: FilledIconProps) => (
  <ShipTimer fill="currentColor" {...props} />
);

const ShipUnknownFilled = (props: FilledIconProps) => (
  <ShipUnknown fill="currentColor" {...props} />
);

const ShipUpArrowFilled = (props: FilledIconProps) => (
  <ShipUpArrow fill="currentColor" {...props} />
);

const ShipWarningFilled = (props: FilledIconProps) => (
  <ShipWarning fill="currentColor" {...props} />
);

const ShoppingBag1Filled = (props: FilledIconProps) => (
  <ShoppingBag1 fill="currentColor" {...props} />
);

const ShoppingBag10Filled = (props: FilledIconProps) => (
  <ShoppingBag10 fill="currentColor" {...props} />
);

const ShoppingBag11Filled = (props: FilledIconProps) => (
  <ShoppingBag11 fill="currentColor" {...props} />
);

const ShoppingBag12Filled = (props: FilledIconProps) => (
  <ShoppingBag12 fill="currentColor" {...props} />
);

const ShoppingBag13Filled = (props: FilledIconProps) => (
  <ShoppingBag13 fill="currentColor" {...props} />
);

const ShoppingBag14Filled = (props: FilledIconProps) => (
  <ShoppingBag14 fill="currentColor" {...props} />
);

const ShoppingBag15Filled = (props: FilledIconProps) => (
  <ShoppingBag15 fill="currentColor" {...props} />
);

const ShoppingBag16Filled = (props: FilledIconProps) => (
  <ShoppingBag16 fill="currentColor" {...props} />
);

const ShoppingBag17Filled = (props: FilledIconProps) => (
  <ShoppingBag17 fill="currentColor" {...props} />
);

const ShoppingBag2Filled = (props: FilledIconProps) => (
  <ShoppingBag2 fill="currentColor" {...props} />
);

const ShoppingBag3Filled = (props: FilledIconProps) => (
  <ShoppingBag3 fill="currentColor" {...props} />
);

const ShoppingBag4Filled = (props: FilledIconProps) => (
  <ShoppingBag4 fill="currentColor" {...props} />
);

const ShoppingBag5Filled = (props: FilledIconProps) => (
  <ShoppingBag5 fill="currentColor" {...props} />
);

const ShoppingBag6Filled = (props: FilledIconProps) => (
  <ShoppingBag6 fill="currentColor" {...props} />
);

const ShoppingBag7Filled = (props: FilledIconProps) => (
  <ShoppingBag7 fill="currentColor" {...props} />
);

const ShoppingBag8Filled = (props: FilledIconProps) => (
  <ShoppingBag8 fill="currentColor" {...props} />
);

const ShoppingBag9Filled = (props: FilledIconProps) => (
  <ShoppingBag9 fill="currentColor" {...props} />
);

const ShoppingCart1Filled = (props: FilledIconProps) => (
  <ShoppingCart1 fill="currentColor" {...props} />
);

const ShoppingCart10Filled = (props: FilledIconProps) => (
  <ShoppingCart10 fill="currentColor" {...props} />
);

const ShoppingCart11Filled = (props: FilledIconProps) => (
  <ShoppingCart11 fill="currentColor" {...props} />
);

const ShoppingCart12Filled = (props: FilledIconProps) => (
  <ShoppingCart12 fill="currentColor" {...props} />
);

const ShoppingCart2Filled = (props: FilledIconProps) => (
  <ShoppingCart2 fill="currentColor" {...props} />
);

const ShoppingCart3Filled = (props: FilledIconProps) => (
  <ShoppingCart3 fill="currentColor" {...props} />
);

const ShoppingCart4Filled = (props: FilledIconProps) => (
  <ShoppingCart4 fill="currentColor" {...props} />
);

const ShoppingCart6Filled = (props: FilledIconProps) => (
  <ShoppingCart6 fill="currentColor" {...props} />
);

const ShoppingCart7Filled = (props: FilledIconProps) => (
  <ShoppingCart7 fill="currentColor" {...props} />
);

const ShoppingCart8Filled = (props: FilledIconProps) => (
  <ShoppingCart8 fill="currentColor" {...props} />
);

const ShoppingCart9Filled = (props: FilledIconProps) => (
  <ShoppingCart9 fill="currentColor" {...props} />
);

const ShoppingMobileFilled = (props: FilledIconProps) => (
  <ShoppingMobile fill="currentColor" {...props} />
);

const SignPoleFilled = (props: FilledIconProps) => (
  <SignPole fill="currentColor" {...props} />
);

const StarDollarFilled = (props: FilledIconProps) => (
  <StarDollar fill="currentColor" {...props} />
);

const Telephone2Filled = (props: FilledIconProps) => (
  <Telephone2 fill="currentColor" {...props} />
);

const Telephone3Filled = (props: FilledIconProps) => (
  <Telephone3 fill="currentColor" {...props} />
);

const TrailerFilled = (props: FilledIconProps) => (
  <Trailer fill="currentColor" {...props} />
);

const TruckBlockedFilled = (props: FilledIconProps) => (
  <TruckBlocked fill="currentColor" {...props} />
);

const TruckBox1Filled = (props: FilledIconProps) => (
  <TruckBox1 fill="currentColor" {...props} />
);

const TruckBoxFilled = (props: FilledIconProps) => (
  <TruckBox fill="currentColor" {...props} />
);

const TruckCheckedFilled = (props: FilledIconProps) => (
  <TruckChecked fill="currentColor" {...props} />
);

const TruckDownArrowFilled = (props: FilledIconProps) => (
  <TruckDownArrow fill="currentColor" {...props} />
);

const TruckFeedbackFilled = (props: FilledIconProps) => (
  <TruckFeedback fill="currentColor" {...props} />
);

const TruckGlobalFilled = (props: FilledIconProps) => (
  <TruckGlobal fill="currentColor" {...props} />
);

const TruckLocationFilled = (props: FilledIconProps) => (
  <TruckLocation fill="currentColor" {...props} />
);

const TruckPrivateFilled = (props: FilledIconProps) => (
  <TruckPrivate fill="currentColor" {...props} />
);

const TruckReloadFilled = (props: FilledIconProps) => (
  <TruckReload fill="currentColor" {...props} />
);

const TruckSearchFilled = (props: FilledIconProps) => (
  <TruckSearch fill="currentColor" {...props} />
);

const TruckSecureFilled = (props: FilledIconProps) => (
  <TruckSecure fill="currentColor" {...props} />
);

const TruckStarFilled = (props: FilledIconProps) => (
  <TruckStar fill="currentColor" {...props} />
);

const TruckTimerFilled = (props: FilledIconProps) => (
  <TruckTimer fill="currentColor" {...props} />
);

const TruckUnknownFilled = (props: FilledIconProps) => (
  <TruckUnknown fill="currentColor" {...props} />
);

const TruckUpArrowFilled = (props: FilledIconProps) => (
  <TruckUpArrow fill="currentColor" {...props} />
);

const TruckWarningFilled = (props: FilledIconProps) => (
  <TruckWarning fill="currentColor" {...props} />
);

export {
  Icon247AltFilled,
  Icon247Filled,
  Icon24HoursTruckFilled,
  FAQFilled,
  AddBasketFilled,
  AddCart1Filled,
  AddCart21Filled,
  AddCart2Filled,
  AddCartFilled,
  AeroplaneDeliveryFilled,
  AirMailFilled,
  BadgeDollarFilled,
  BarCodeSearchFilled,
  BarcodeFilled,
  Basket1Filled,
  Basket2Filled,
  Bills1Filled,
  BillsFilled,
  Box1Filled,
  Box10Filled,
  Box2Filled,
  Box3Filled,
  Box4Filled,
  Box5Filled,
  Box6Filled,
  Box7Filled,
  Box8Filled,
  Box9Filled,
  BoxAddFilled,
  BoxCartFilled,
  BoxOpenFilled,
  BoxPercentageFilled,
  BoxRemoveFilled,
  BoxesFilled,
  CalculatorAltFilled,
  CalculatorCardFilled,
  CalculatorPaperFilled,
  CargoMoverFilled,
  CashFilled,
  CheckSignFilled,
  CloseSignFilled,
  CoinsAltFilled,
  CommercialMachineFilled,
  CouponScissorsFilled,
  CreditCardDeleteFilled,
  CreditCardLockFilled,
  CreditCardsAltFilled,
  CubeFilled,
  DeliveryTruck1Filled,
  DeliveryTruck2Filled,
  DeliveryTruck3Filled,
  DeliveryTruck4Filled,
  DeliveryTruckFilled,
  DiscountLabelFilled,
  DislikeFilled,
  DollarBubbleFilled,
  DollarBundleFilled,
  DollarCoinStackFilled,
  DollarMonitorFilled,
  DollarSettingsFilled,
  DollarSymbolFilled,
  DollarsGiftFilled,
  DumpTruckFilled,
  EuroCoinStackFilled,
  ExpressShoppingFilled,
  FastDeliveryFilled,
  FuelTruckAltFilled,
  HeadphonesAltFilled,
  IdCardFilled,
  IntCommercialMailFilled,
  IntEcommerceFilled,
  Invoice1Filled,
  LabelNewFilled,
  LabelOffFilled,
  Like2Filled,
  Like3Filled,
  LikeFilled,
  LockedWindowFilled,
  MathematicsFilled,
  MedalFirstAltFilled,
  MoneyBagAltFilled,
  MoneySecurityFilled,
  MonitorDollarLockFilled,
  NewspaperFilled,
  NfcSymbolFilled,
  OilTankerFilled,
  OnlineShoppingFilled,
  OpenSignFilled,
  PackageBoxFilled,
  PadlockFilled,
  PaidSignFilled,
  ParachuteParcelFilled,
  ParcelAirFilled,
  PenAltFilled,
  PercentageAltFilled,
  PercentageTicketFilled,
  PiggyBank11Filled,
  PiggyBank1Filled,
  PiggyBankFilled,
  PriceTag1Filled,
  PriceTagFilled,
  ProfitsFilled,
  RemoveBasketFilled,
  RemoveCartFilled,
  SaleSignFilled,
  SavingPigFilled,
  SearchStoreFilled,
  SecureShoppingFilled,
  ShieldAlt1Filled,
  ShieldAltFilled,
  ShipCargoFilled,
  ShipCheckFilled,
  ShipContainerFilled,
  ShipDeliveryBoxAlt1Filled,
  ShipDeliveryBoxAltFilled,
  ShipDeliveryBoxFilled,
  ShipDownArrowFilled,
  ShipFavouriteFilled,
  ShipInternationalFilled,
  ShipLockedFilled,
  ShipReloadFilled,
  ShipSearchFilled,
  ShipSecureFilled,
  ShipStarFilled,
  ShipStopFilled,
  ShipThumbsUpFilled,
  ShipTimerFilled,
  ShipUnknownFilled,
  ShipUpArrowFilled,
  ShipWarningFilled,
  ShoppingBag1Filled,
  ShoppingBag10Filled,
  ShoppingBag11Filled,
  ShoppingBag12Filled,
  ShoppingBag13Filled,
  ShoppingBag14Filled,
  ShoppingBag15Filled,
  ShoppingBag16Filled,
  ShoppingBag17Filled,
  ShoppingBag2Filled,
  ShoppingBag3Filled,
  ShoppingBag4Filled,
  ShoppingBag5Filled,
  ShoppingBag6Filled,
  ShoppingBag7Filled,
  ShoppingBag8Filled,
  ShoppingBag9Filled,
  ShoppingCart1Filled,
  ShoppingCart10Filled,
  ShoppingCart11Filled,
  ShoppingCart12Filled,
  ShoppingCart2Filled,
  ShoppingCart3Filled,
  ShoppingCart4Filled,
  ShoppingCart6Filled,
  ShoppingCart7Filled,
  ShoppingCart8Filled,
  ShoppingCart9Filled,
  ShoppingMobileFilled,
  SignPoleFilled,
  StarDollarFilled,
  Telephone2Filled,
  Telephone3Filled,
  TrailerFilled,
  TruckBlockedFilled,
  TruckBox1Filled,
  TruckBoxFilled,
  TruckCheckedFilled,
  TruckDownArrowFilled,
  TruckFeedbackFilled,
  TruckGlobalFilled,
  TruckLocationFilled,
  TruckPrivateFilled,
  TruckReloadFilled,
  TruckSearchFilled,
  TruckSecureFilled,
  TruckStarFilled,
  TruckTimerFilled,
  TruckUnknownFilled,
  TruckUpArrowFilled,
  TruckWarningFilled,
};
