import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Logo } from './Logo';
import { SystemStatus } from './SystemStatus';
import { Typography } from './ui/Typography';

type FooterLinkProps = {
  url: string;
  children: ReactNode;
  reloadDocument?: boolean;
};

function FooterLink({ url, children, reloadDocument }: FooterLinkProps) {
  const className =
    'flex flex-row items-center py-2 hover:text-default text-subtle decoration-inherit gap-2 transition-all duration-300';
  const LinkComponent = url.startsWith('http') ? (
    <a className={className} href={url}>
      {children}
    </a>
  ) : (
    <Link className={className} reloadDocument={reloadDocument} to={url}>
      {children}
    </Link>
  );
  return <li>{LinkComponent}</li>;
}

interface LinkListProps {
  title: string;
  children: ReactNode;
}

function LinkList({ title, children }: LinkListProps) {
  return (
    <div className="flex flex-col gap-2 lg:px-4">
      <Typography variant="title" size="sm">
        {title}
      </Typography>
      <ul className="my-0 list-none ps-0">{children}</ul>
    </div>
  );
}
export function Footer() {
  return (
    <footer className="border-t border-b-0 border-solid border-primary/10 border-x-0">
      <div className="container flex flex-col justify-between p-16 lg:flex-row gap-16">
        <div className="flex flex-col items-start gap-5">
          <Logo width={131} height={36} />

          <Typography variant="body" color="subtle" size="sm">
            Copyright {new Date().getFullYear()} &copy; Cove Software, Inc.{' '}
            <br />
            All rights reserved.
          </Typography>

          <SystemStatus />
        </div>

        <div className="flex flex-col items-start justify-start lg:flex-row gap-8">
          <LinkList title="Products">
            <FooterLink url="/ai">Custom AI Models</FooterLink>
            <FooterLink url="/enforcement">Automated Enforcement</FooterLink>
            <FooterLink url="/console">Moderator Console</FooterLink>
            <FooterLink url="/compliance">Compliance Toolkit</FooterLink>
          </LinkList>

          <LinkList title="Company">
            {/* without reload document, navigation between /about and /about?to=careers doesn't work */}
            <FooterLink url="/about" reloadDocument={true}>
              About
            </FooterLink>
            <FooterLink url="/blog">Blog</FooterLink>
            {/* without reload document, navigation between /about and /about?to=careers doesn't work */}
            <FooterLink url="/about?to=careers" reloadDocument={true}>
              Careers{' '}
              <span className="mt-px text-sm font-semibold text-primary">
                We're hiring!
              </span>
            </FooterLink>
            <FooterLink url="https://docs.getcove.com">Docs</FooterLink>
          </LinkList>

          <LinkList title="Legal">
            <FooterLink url="/terms">Terms</FooterLink>
            <FooterLink url="/privacy">Privacy</FooterLink>
          </LinkList>
        </div>
      </div>
    </footer>
  );
}
