import {
  AddCartFilled,
  BotFilled,
  CustomerFilled,
  GameFilled,
  HeartFilled,
  MobileApplicationAltFilled,
  SpeechBubble1Filled,
  UserStarFilled,
} from '@/icons';
import { m } from 'framer-motion';
import type { SVGProps } from 'react';

import { Card } from '../../Card';
import { FeatureSection } from '../../sections/landing/FeatureSection';
import { Typography } from '../../ui/Typography';

interface UseCardCardProps {
  color: string;
  title: string;
  description: string;
  icon: React.JSXElementConstructor<SVGProps<SVGSVGElement>>;
}

function UseCaseCard({
  title,
  color,
  description,
  icon: Icon,
}: UseCardCardProps) {
  return (
    <Card className="p-4 min-w-96 max-w-[496px]" style={{ color }}>
      <Icon className="mb-2" width={48} height={48} />
      <Typography variant="title" size="md" className="mb-1">
        {title}
      </Typography>
      <Typography
        variant="body"
        color="subtle"
        size="md"
        className="w-full line-clamp-2"
      >
        {description}
      </Typography>
    </Card>
  );
}

function UseCaseCards() {
  return (
    <>
      <UseCaseCard
        title="Dating apps"
        description="Detect scams, solicitation, and abuse in conversations and profiles."
        icon={HeartFilled}
        color="#FF008A"
      />

      <UseCaseCard
        title="Gaming"
        description="Monitor harassment, hate, and illicit activity in text, audio, and video chat."
        icon={GameFilled}
        color="#BD00FF"
      />

      <UseCaseCard
        title="Marketplaces"
        description="Prevent sales of illegal or illicit goods and counterfeit products."
        icon={AddCartFilled}
        color="#FFC700"
      />

      <UseCaseCard
        title="Messaging platforms"
        description="Filter spam, phishing, threats, and other common abuses in DMs and group chats."
        icon={SpeechBubble1Filled}
        color="#00A3FF"
      />

      <UseCaseCard
        title="Gen AI Tools"
        description="Prevent models from generating harmful content, and detect prompt hacking."
        icon={BotFilled}
        color="#0CD0AD"
      />

      <UseCaseCard
        title="Social Media"
        description="Detect any form of abuse in text, audio, image, and video content."
        icon={MobileApplicationAltFilled}
        color="#12A5D3"
      />

      <UseCaseCard
        title="Reviews & Forums"
        description="Remove fake reviews, hateful comments, and trolls."
        icon={UserStarFilled}
        color="#1FA87F"
      />

      <UseCaseCard
        title="Creator Economy"
        description="Weed out harassment, doxxing, and threats to keep creators and fans safe."
        icon={CustomerFilled}
        color="#4F40FF"
      />
    </>
  );
}

export function UseCases() {
  return (
    <FeatureSection
      container={false}
      centered
      orientation="ttb"
      title="Popular use cases"
      className="gap-4 md:gap-8 xl:gap-20"
    >
      <m.div
        initial={{ y: 10, opacity: 0 }}
        whileInView={{ y: 0, opacity: 100, transition: { duration: 0.5 } }}
        viewport={{ once: true }}
      >
        <div className="relative flex flex-row items-center gap-4 animate-carousel-left w-min">
          <UseCaseCards />
          <div className="absolute inset-y-0 flex flex-row items-center gap-4 translate-x-full w-min -right-4">
            <UseCaseCards />
            <UseCaseCards />
          </div>
        </div>
      </m.div>
    </FeatureSection>
  );
}
