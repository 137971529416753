import type { FilledIconProps } from '../types';
import { ReactComponent as AngleDoubleDown } from './angle-double-down.svg';
import { ReactComponent as AngleDoubleLeft } from './angle-double-left.svg';
import { ReactComponent as AngleDoubleRight } from './angle-double-right.svg';
import { ReactComponent as AngleDoubleUp } from './angle-double-up.svg';
import { ReactComponent as ArrowAlignH } from './arrow-align-h.svg';
import { ReactComponent as ArrowAlignV } from './arrow-align-v.svg';
import { ReactComponent as ArrowDoubleDown } from './arrow-double-down.svg';
import { ReactComponent as ArrowDoubleLeft } from './arrow-double-left.svg';
import { ReactComponent as ArrowDoubleRight } from './arrow-double-right.svg';
import { ReactComponent as ArrowDoubleTop } from './arrow-double-top.svg';
import { ReactComponent as ArrowDownCircle } from './arrow-down-circle.svg';
import { ReactComponent as ArrowDown } from './arrow-down.svg';
import { ReactComponent as ArrowExpandAlt } from './arrow-expand-alt.svg';
import { ReactComponent as ArrowExpand } from './arrow-expand.svg';
import { ReactComponent as ArrowLeftCircle } from './arrow-left-circle.svg';
import { ReactComponent as ArrowLeft } from './arrow-left.svg';
import { ReactComponent as ArrowLongDownC } from './arrow-long-down-c.svg';
import { ReactComponent as ArrowLongDownE1 } from './arrow-long-down-e-1.svg';
import { ReactComponent as ArrowLongDownE } from './arrow-long-down-e.svg';
import { ReactComponent as ArrowLongDownR } from './arrow-long-down-r.svg';
import { ReactComponent as ArrowLongLeftC } from './arrow-long-left-c.svg';
import { ReactComponent as ArrowLongLeftE } from './arrow-long-left-e.svg';
import { ReactComponent as ArrowLongLeftI } from './arrow-long-left-i.svg';
import { ReactComponent as ArrowLongLeftR } from './arrow-long-left-r.svg';
import { ReactComponent as ArrowLongRightC } from './arrow-long-right-c.svg';
import { ReactComponent as ArrowMinimizeAlt } from './arrow-minimize-alt.svg';
import { ReactComponent as ArrowMinimize } from './arrow-minimize.svg';
import { ReactComponent as ArrowRightCircle } from './arrow-right-circle.svg';
import { ReactComponent as ArrowRight } from './arrow-right.svg';
import { ReactComponent as ArrowTopLeft } from './arrow-top-left.svg';
import { ReactComponent as ArrowTopRight } from './arrow-top-right.svg';
import { ReactComponent as ArrowUpCircle } from './arrow-up-circle.svg';
import { ReactComponent as ArrowUp } from './arrow-up.svg';
import { ReactComponent as ArrowsBreakH } from './arrows-break-h.svg';
import { ReactComponent as ArrowsBreakV } from './arrows-break-v.svg';
import { ReactComponent as ArrowsExchangeAltV1 } from './arrows-exchange-alt-v-1.svg';
import { ReactComponent as ArrowsExchangeAltV } from './arrows-exchange-alt-v.svg';
import { ReactComponent as ArrowsExchangeAlt } from './arrows-exchange-alt.svg';
import { ReactComponent as ArrowsExchange } from './arrows-exchange.svg';
import { ReactComponent as ArrowsExpandDownLeft } from './arrows-expand-down-left.svg';
import { ReactComponent as ArrowsExpandDownRight } from './arrows-expand-down-right.svg';
import { ReactComponent as ArrowsExpandLeftAlt } from './arrows-expand-left-alt.svg';
import { ReactComponent as ArrowsExpandRightAlt } from './arrows-expand-right-alt.svg';
import { ReactComponent as ArrowsExpandUpLeft } from './arrows-expand-up-left.svg';
import { ReactComponent as ArrowsExpandUpRight } from './arrows-expand-up-right.svg';
import { ReactComponent as ArrowsHorizontal } from './arrows-horizontal.svg';
import { ReactComponent as ArrowsMergeAltH } from './arrows-merge-alt-h.svg';
import { ReactComponent as ArrowsMergeAltV } from './arrows-merge-alt-v.svg';
import { ReactComponent as ArrowsScrollH } from './arrows-scroll-h.svg';
import { ReactComponent as ArrowsScrollV } from './arrows-scroll-v.svg';
import { ReactComponent as ArrowsShrinkH } from './arrows-shrink-h.svg';
import { ReactComponent as ArrowsShrinkV } from './arrows-shrink-v.svg';
import { ReactComponent as ArrowsVertical } from './arrows-vertical.svg';
import { ReactComponent as Axis } from './axis.svg';
import { ReactComponent as ChevronDownCircle } from './chevron-down-circle.svg';
import { ReactComponent as ChevronDown } from './chevron-down.svg';
import { ReactComponent as ChevronLeftCircle } from './chevron-left-circle.svg';
import { ReactComponent as ChevronLeft } from './chevron-left.svg';
import { ReactComponent as ChevronRightCircle } from './chevron-right-circle.svg';
import { ReactComponent as ChevronRight } from './chevron-right.svg';
import { ReactComponent as ChevronUpCircle } from './chevron-up-circle.svg';
import { ReactComponent as ChevronUp } from './chevron-up.svg';
import { ReactComponent as CompassAlt1 } from './compass-alt-1.svg';
import { ReactComponent as Compress } from './compress.svg';
import { ReactComponent as CornerDoubleDownLeft } from './corner-double-down-left.svg';
import { ReactComponent as CornerDoubleDownRight } from './corner-double-down-right.svg';
import { ReactComponent as CornerDoubleLeftDown } from './corner-double-left-down.svg';
import { ReactComponent as CornerDoubleLeftUp } from './corner-double-left-up.svg';
import { ReactComponent as CornerDoubleRightDown } from './corner-double-right-down.svg';
import { ReactComponent as CornerDoubleRightUp } from './corner-double-right-up.svg';
import { ReactComponent as CornerDoubleUpLeft } from './corner-double-up-left.svg';
import { ReactComponent as CornerDoubleUpRight } from './corner-double-up-right.svg';
import { ReactComponent as CornerDownLeft } from './corner-down-left.svg';
import { ReactComponent as CornerDownRight } from './corner-down-right.svg';
import { ReactComponent as CornerLeftDown } from './corner-left-down.svg';
import { ReactComponent as CornerLeftUp } from './corner-left-up.svg';
import { ReactComponent as CornerRightDown } from './corner-right-down.svg';
import { ReactComponent as CornerRightUp } from './corner-right-up.svg';
import { ReactComponent as CornerUpLeft } from './corner-up-left.svg';
import { ReactComponent as CornerUpRight } from './corner-up-right.svg';
import { ReactComponent as DirectionAlt } from './direction-alt.svg';
import { ReactComponent as DirectionLtr } from './direction-ltr.svg';
import { ReactComponent as DirectionRtl } from './direction-rtl.svg';
import { ReactComponent as Direction } from './direction.svg';
import { ReactComponent as DownArrowBox } from './down-arrow-box.svg';
import { ReactComponent as DownLeftArrowBox } from './down-left-arrow-box.svg';
import { ReactComponent as DownRightArrowBox } from './down-right-arrow-box.svg';
import { ReactComponent as Enter } from './enter.svg';
import { ReactComponent as ExitDown } from './exit-down.svg';
import { ReactComponent as ExitUp } from './exit-up.svg';
import { ReactComponent as Exit } from './exit.svg';
import { ReactComponent as FlagCorner } from './flag-corner.svg';
import { ReactComponent as Flags } from './flags.svg';
import { ReactComponent as LeftArrowBox } from './left-arrow-box.svg';
import { ReactComponent as MagnifyingGlass } from './magnifying-glass.svg';
import { ReactComponent as MergeHorizontal } from './merge-horizontal.svg';
import { ReactComponent as MergeVertical } from './merge-vertical.svg';
import { ReactComponent as Motion } from './motion.svg';
import { ReactComponent as MoveDown } from './move-down.svg';
import { ReactComponent as MoveLeft } from './move-left.svg';
import { ReactComponent as MoveRight } from './move-right.svg';
import { ReactComponent as MoveTask } from './move-task.svg';
import { ReactComponent as MoveUp } from './move-up.svg';
import { ReactComponent as Navigation } from './navigation.svg';
import { ReactComponent as North } from './north.svg';
import { ReactComponent as Placeholders } from './placeholders.svg';
import { ReactComponent as PointerAlt } from './pointer-alt.svg';
import { ReactComponent as PointerDown } from './pointer-down.svg';
import { ReactComponent as PointerLeft } from './pointer-left.svg';
import { ReactComponent as PointerRight } from './pointer-right.svg';
import { ReactComponent as PointerUp } from './pointer-up.svg';
import { ReactComponent as PushDown } from './push-down.svg';
import { ReactComponent as PushLeft } from './push-left.svg';
import { ReactComponent as PushRight } from './push-right.svg';
import { ReactComponent as PushUp } from './push-up.svg';
import { ReactComponent as RightAlt } from './right-alt.svg';
import { ReactComponent as RightArrowBox } from './right-arrow-box.svg';
import { ReactComponent as Right } from './right.svg';
import { ReactComponent as RouteAlt } from './route-alt.svg';
import { ReactComponent as Route } from './route.svg';
import { ReactComponent as ScrollH } from './scroll-h.svg';
import { ReactComponent as ScrollV } from './scroll-v.svg';
import { ReactComponent as SelectArrow } from './select-arrow.svg';
import { ReactComponent as SelectBox } from './select-box.svg';
import { ReactComponent as SelectOval } from './select-oval.svg';
import { ReactComponent as ShiftLeft } from './shift-left.svg';
import { ReactComponent as ShiftRight } from './shift-right.svg';
import { ReactComponent as Signaling } from './signaling.svg';
import { ReactComponent as Straight } from './straight.svg';
import { ReactComponent as SwapArrow } from './swap-arrow.svg';
import { ReactComponent as SwapVertical } from './swap-vertical.svg';
import { ReactComponent as TopArrowBox } from './top-arrow-box.svg';
import { ReactComponent as TopLeftArrowBox } from './top-left-arrow-box.svg';
import { ReactComponent as TopRightArrowBox } from './top-right-arrow-box.svg';
import { ReactComponent as TrafficLight } from './traffic-light.svg';
import { ReactComponent as TrainAlt1 } from './train-alt-1.svg';
import { ReactComponent as Transfer2 } from './transfer-2.svg';
import { ReactComponent as TransferAlt2 } from './transfer-alt-2.svg';
import { ReactComponent as TransferAlt } from './transfer-alt.svg';
import { ReactComponent as Transfer } from './transfer.svg';
import { ReactComponent as TrendingDown } from './trending-down.svg';
import { ReactComponent as TrendingUp } from './trending-up.svg';
import { ReactComponent as Turn } from './turn.svg';
import { ReactComponent as Walking } from './walking.svg';

const AngleDoubleDownFilled = (props: FilledIconProps) => (
  <AngleDoubleDown fill="currentColor" {...props} />
);

const AngleDoubleLeftFilled = (props: FilledIconProps) => (
  <AngleDoubleLeft fill="currentColor" {...props} />
);

const AngleDoubleRightFilled = (props: FilledIconProps) => (
  <AngleDoubleRight fill="currentColor" {...props} />
);

const AngleDoubleUpFilled = (props: FilledIconProps) => (
  <AngleDoubleUp fill="currentColor" {...props} />
);

const ArrowAlignHFilled = (props: FilledIconProps) => (
  <ArrowAlignH fill="currentColor" {...props} />
);

const ArrowAlignVFilled = (props: FilledIconProps) => (
  <ArrowAlignV fill="currentColor" {...props} />
);

const ArrowDoubleDownFilled = (props: FilledIconProps) => (
  <ArrowDoubleDown fill="currentColor" {...props} />
);

const ArrowDoubleLeftFilled = (props: FilledIconProps) => (
  <ArrowDoubleLeft fill="currentColor" {...props} />
);

const ArrowDoubleRightFilled = (props: FilledIconProps) => (
  <ArrowDoubleRight fill="currentColor" {...props} />
);

const ArrowDoubleTopFilled = (props: FilledIconProps) => (
  <ArrowDoubleTop fill="currentColor" {...props} />
);

const ArrowDownCircleFilled = (props: FilledIconProps) => (
  <ArrowDownCircle fill="currentColor" {...props} />
);

const ArrowDownFilled = (props: FilledIconProps) => (
  <ArrowDown fill="currentColor" {...props} />
);

const ArrowExpandAltFilled = (props: FilledIconProps) => (
  <ArrowExpandAlt fill="currentColor" {...props} />
);

const ArrowExpandFilled = (props: FilledIconProps) => (
  <ArrowExpand fill="currentColor" {...props} />
);

const ArrowLeftCircleFilled = (props: FilledIconProps) => (
  <ArrowLeftCircle fill="currentColor" {...props} />
);

const ArrowLeftFilled = (props: FilledIconProps) => (
  <ArrowLeft fill="currentColor" {...props} />
);

const ArrowLongDownCFilled = (props: FilledIconProps) => (
  <ArrowLongDownC fill="currentColor" {...props} />
);

const ArrowLongDownE1Filled = (props: FilledIconProps) => (
  <ArrowLongDownE1 fill="currentColor" {...props} />
);

const ArrowLongDownEFilled = (props: FilledIconProps) => (
  <ArrowLongDownE fill="currentColor" {...props} />
);

const ArrowLongDownRFilled = (props: FilledIconProps) => (
  <ArrowLongDownR fill="currentColor" {...props} />
);

const ArrowLongLeftCFilled = (props: FilledIconProps) => (
  <ArrowLongLeftC fill="currentColor" {...props} />
);

const ArrowLongLeftEFilled = (props: FilledIconProps) => (
  <ArrowLongLeftE fill="currentColor" {...props} />
);

const ArrowLongLeftIFilled = (props: FilledIconProps) => (
  <ArrowLongLeftI fill="currentColor" {...props} />
);

const ArrowLongLeftRFilled = (props: FilledIconProps) => (
  <ArrowLongLeftR fill="currentColor" {...props} />
);

const ArrowLongRightCFilled = (props: FilledIconProps) => (
  <ArrowLongRightC fill="currentColor" {...props} />
);

const ArrowMinimizeAltFilled = (props: FilledIconProps) => (
  <ArrowMinimizeAlt fill="currentColor" {...props} />
);

const ArrowMinimizeFilled = (props: FilledIconProps) => (
  <ArrowMinimize fill="currentColor" {...props} />
);

const ArrowRightCircleFilled = (props: FilledIconProps) => (
  <ArrowRightCircle fill="currentColor" {...props} />
);

const ArrowRightFilled = (props: FilledIconProps) => (
  <ArrowRight fill="currentColor" {...props} />
);

const ArrowTopLeftFilled = (props: FilledIconProps) => (
  <ArrowTopLeft fill="currentColor" {...props} />
);

const ArrowTopRightFilled = (props: FilledIconProps) => (
  <ArrowTopRight fill="currentColor" {...props} />
);

const ArrowUpCircleFilled = (props: FilledIconProps) => (
  <ArrowUpCircle fill="currentColor" {...props} />
);

const ArrowUpFilled = (props: FilledIconProps) => (
  <ArrowUp fill="currentColor" {...props} />
);

const ArrowsBreakHFilled = (props: FilledIconProps) => (
  <ArrowsBreakH fill="currentColor" {...props} />
);

const ArrowsBreakVFilled = (props: FilledIconProps) => (
  <ArrowsBreakV fill="currentColor" {...props} />
);

const ArrowsExchangeAltV1Filled = (props: FilledIconProps) => (
  <ArrowsExchangeAltV1 fill="currentColor" {...props} />
);

const ArrowsExchangeAltVFilled = (props: FilledIconProps) => (
  <ArrowsExchangeAltV fill="currentColor" {...props} />
);

const ArrowsExchangeAltFilled = (props: FilledIconProps) => (
  <ArrowsExchangeAlt fill="currentColor" {...props} />
);

const ArrowsExchangeFilled = (props: FilledIconProps) => (
  <ArrowsExchange fill="currentColor" {...props} />
);

const ArrowsExpandDownLeftFilled = (props: FilledIconProps) => (
  <ArrowsExpandDownLeft fill="currentColor" {...props} />
);

const ArrowsExpandDownRightFilled = (props: FilledIconProps) => (
  <ArrowsExpandDownRight fill="currentColor" {...props} />
);

const ArrowsExpandLeftAltFilled = (props: FilledIconProps) => (
  <ArrowsExpandLeftAlt fill="currentColor" {...props} />
);

const ArrowsExpandRightAltFilled = (props: FilledIconProps) => (
  <ArrowsExpandRightAlt fill="currentColor" {...props} />
);

const ArrowsExpandUpLeftFilled = (props: FilledIconProps) => (
  <ArrowsExpandUpLeft fill="currentColor" {...props} />
);

const ArrowsExpandUpRightFilled = (props: FilledIconProps) => (
  <ArrowsExpandUpRight fill="currentColor" {...props} />
);

const ArrowsHorizontalFilled = (props: FilledIconProps) => (
  <ArrowsHorizontal fill="currentColor" {...props} />
);

const ArrowsMergeAltHFilled = (props: FilledIconProps) => (
  <ArrowsMergeAltH fill="currentColor" {...props} />
);

const ArrowsMergeAltVFilled = (props: FilledIconProps) => (
  <ArrowsMergeAltV fill="currentColor" {...props} />
);

const ArrowsScrollHFilled = (props: FilledIconProps) => (
  <ArrowsScrollH fill="currentColor" {...props} />
);

const ArrowsScrollVFilled = (props: FilledIconProps) => (
  <ArrowsScrollV fill="currentColor" {...props} />
);

const ArrowsShrinkHFilled = (props: FilledIconProps) => (
  <ArrowsShrinkH fill="currentColor" {...props} />
);

const ArrowsShrinkVFilled = (props: FilledIconProps) => (
  <ArrowsShrinkV fill="currentColor" {...props} />
);

const ArrowsVerticalFilled = (props: FilledIconProps) => (
  <ArrowsVertical fill="currentColor" {...props} />
);

const AxisFilled = (props: FilledIconProps) => (
  <Axis fill="currentColor" {...props} />
);

const ChevronDownCircleFilled = (props: FilledIconProps) => (
  <ChevronDownCircle fill="currentColor" {...props} />
);

const ChevronDownFilled = (props: FilledIconProps) => (
  <ChevronDown fill="currentColor" {...props} />
);

const ChevronLeftCircleFilled = (props: FilledIconProps) => (
  <ChevronLeftCircle fill="currentColor" {...props} />
);

const ChevronLeftFilled = (props: FilledIconProps) => (
  <ChevronLeft fill="currentColor" {...props} />
);

const ChevronRightCircleFilled = (props: FilledIconProps) => (
  <ChevronRightCircle fill="currentColor" {...props} />
);

const ChevronRightFilled = (props: FilledIconProps) => (
  <ChevronRight fill="currentColor" {...props} />
);

const ChevronUpCircleFilled = (props: FilledIconProps) => (
  <ChevronUpCircle fill="currentColor" {...props} />
);

const ChevronUpFilled = (props: FilledIconProps) => (
  <ChevronUp fill="currentColor" {...props} />
);

const CompassAlt1Filled = (props: FilledIconProps) => (
  <CompassAlt1 fill="currentColor" {...props} />
);

const CompressFilled = (props: FilledIconProps) => (
  <Compress fill="currentColor" {...props} />
);

const CornerDoubleDownLeftFilled = (props: FilledIconProps) => (
  <CornerDoubleDownLeft fill="currentColor" {...props} />
);

const CornerDoubleDownRightFilled = (props: FilledIconProps) => (
  <CornerDoubleDownRight fill="currentColor" {...props} />
);

const CornerDoubleLeftDownFilled = (props: FilledIconProps) => (
  <CornerDoubleLeftDown fill="currentColor" {...props} />
);

const CornerDoubleLeftUpFilled = (props: FilledIconProps) => (
  <CornerDoubleLeftUp fill="currentColor" {...props} />
);

const CornerDoubleRightDownFilled = (props: FilledIconProps) => (
  <CornerDoubleRightDown fill="currentColor" {...props} />
);

const CornerDoubleRightUpFilled = (props: FilledIconProps) => (
  <CornerDoubleRightUp fill="currentColor" {...props} />
);

const CornerDoubleUpLeftFilled = (props: FilledIconProps) => (
  <CornerDoubleUpLeft fill="currentColor" {...props} />
);

const CornerDoubleUpRightFilled = (props: FilledIconProps) => (
  <CornerDoubleUpRight fill="currentColor" {...props} />
);

const CornerDownLeftFilled = (props: FilledIconProps) => (
  <CornerDownLeft fill="currentColor" {...props} />
);

const CornerDownRightFilled = (props: FilledIconProps) => (
  <CornerDownRight fill="currentColor" {...props} />
);

const CornerLeftDownFilled = (props: FilledIconProps) => (
  <CornerLeftDown fill="currentColor" {...props} />
);

const CornerLeftUpFilled = (props: FilledIconProps) => (
  <CornerLeftUp fill="currentColor" {...props} />
);

const CornerRightDownFilled = (props: FilledIconProps) => (
  <CornerRightDown fill="currentColor" {...props} />
);

const CornerRightUpFilled = (props: FilledIconProps) => (
  <CornerRightUp fill="currentColor" {...props} />
);

const CornerUpLeftFilled = (props: FilledIconProps) => (
  <CornerUpLeft fill="currentColor" {...props} />
);

const CornerUpRightFilled = (props: FilledIconProps) => (
  <CornerUpRight fill="currentColor" {...props} />
);

const DirectionAltFilled = (props: FilledIconProps) => (
  <DirectionAlt fill="currentColor" {...props} />
);

const DirectionLtrFilled = (props: FilledIconProps) => (
  <DirectionLtr fill="currentColor" {...props} />
);

const DirectionRtlFilled = (props: FilledIconProps) => (
  <DirectionRtl fill="currentColor" {...props} />
);

const DirectionFilled = (props: FilledIconProps) => (
  <Direction fill="currentColor" {...props} />
);

const DownArrowBoxFilled = (props: FilledIconProps) => (
  <DownArrowBox fill="currentColor" {...props} />
);

const DownLeftArrowBoxFilled = (props: FilledIconProps) => (
  <DownLeftArrowBox fill="currentColor" {...props} />
);

const DownRightArrowBoxFilled = (props: FilledIconProps) => (
  <DownRightArrowBox fill="currentColor" {...props} />
);

const EnterFilled = (props: FilledIconProps) => (
  <Enter fill="currentColor" {...props} />
);

const ExitDownFilled = (props: FilledIconProps) => (
  <ExitDown fill="currentColor" {...props} />
);

const ExitUpFilled = (props: FilledIconProps) => (
  <ExitUp fill="currentColor" {...props} />
);

const ExitFilled = (props: FilledIconProps) => (
  <Exit fill="currentColor" {...props} />
);

const FlagCornerFilled = (props: FilledIconProps) => (
  <FlagCorner fill="currentColor" {...props} />
);

const FlagsFilled = (props: FilledIconProps) => (
  <Flags fill="currentColor" {...props} />
);

const LeftArrowBoxFilled = (props: FilledIconProps) => (
  <LeftArrowBox fill="currentColor" {...props} />
);

const MagnifyingGlassFilled = (props: FilledIconProps) => (
  <MagnifyingGlass fill="currentColor" {...props} />
);

const MergeHorizontalFilled = (props: FilledIconProps) => (
  <MergeHorizontal fill="currentColor" {...props} />
);

const MergeVerticalFilled = (props: FilledIconProps) => (
  <MergeVertical fill="currentColor" {...props} />
);

const MotionFilled = (props: FilledIconProps) => (
  <Motion fill="currentColor" {...props} />
);

const MoveDownFilled = (props: FilledIconProps) => (
  <MoveDown fill="currentColor" {...props} />
);

const MoveLeftFilled = (props: FilledIconProps) => (
  <MoveLeft fill="currentColor" {...props} />
);

const MoveRightFilled = (props: FilledIconProps) => (
  <MoveRight fill="currentColor" {...props} />
);

const MoveTaskFilled = (props: FilledIconProps) => (
  <MoveTask fill="currentColor" {...props} />
);

const MoveUpFilled = (props: FilledIconProps) => (
  <MoveUp fill="currentColor" {...props} />
);

const NavigationFilled = (props: FilledIconProps) => (
  <Navigation fill="currentColor" {...props} />
);

const NorthFilled = (props: FilledIconProps) => (
  <North fill="currentColor" {...props} />
);

const PlaceholdersFilled = (props: FilledIconProps) => (
  <Placeholders fill="currentColor" {...props} />
);

const PointerAltFilled = (props: FilledIconProps) => (
  <PointerAlt fill="currentColor" {...props} />
);

const PointerDownFilled = (props: FilledIconProps) => (
  <PointerDown fill="currentColor" {...props} />
);

const PointerLeftFilled = (props: FilledIconProps) => (
  <PointerLeft fill="currentColor" {...props} />
);

const PointerRightFilled = (props: FilledIconProps) => (
  <PointerRight fill="currentColor" {...props} />
);

const PointerUpFilled = (props: FilledIconProps) => (
  <PointerUp fill="currentColor" {...props} />
);

const PushDownFilled = (props: FilledIconProps) => (
  <PushDown fill="currentColor" {...props} />
);

const PushLeftFilled = (props: FilledIconProps) => (
  <PushLeft fill="currentColor" {...props} />
);

const PushRightFilled = (props: FilledIconProps) => (
  <PushRight fill="currentColor" {...props} />
);

const PushUpFilled = (props: FilledIconProps) => (
  <PushUp fill="currentColor" {...props} />
);

const RightAltFilled = (props: FilledIconProps) => (
  <RightAlt fill="currentColor" {...props} />
);

const RightArrowBoxFilled = (props: FilledIconProps) => (
  <RightArrowBox fill="currentColor" {...props} />
);

const RightFilled = (props: FilledIconProps) => (
  <Right fill="currentColor" {...props} />
);

const RouteAltFilled = (props: FilledIconProps) => (
  <RouteAlt fill="currentColor" {...props} />
);

const RouteFilled = (props: FilledIconProps) => (
  <Route fill="currentColor" {...props} />
);

const ScrollHFilled = (props: FilledIconProps) => (
  <ScrollH fill="currentColor" {...props} />
);

const ScrollVFilled = (props: FilledIconProps) => (
  <ScrollV fill="currentColor" {...props} />
);

const SelectArrowFilled = (props: FilledIconProps) => (
  <SelectArrow fill="currentColor" {...props} />
);

const SelectBoxFilled = (props: FilledIconProps) => (
  <SelectBox fill="currentColor" {...props} />
);

const SelectOvalFilled = (props: FilledIconProps) => (
  <SelectOval fill="currentColor" {...props} />
);

const ShiftLeftFilled = (props: FilledIconProps) => (
  <ShiftLeft fill="currentColor" {...props} />
);

const ShiftRightFilled = (props: FilledIconProps) => (
  <ShiftRight fill="currentColor" {...props} />
);

const SignalingFilled = (props: FilledIconProps) => (
  <Signaling fill="currentColor" {...props} />
);

const StraightFilled = (props: FilledIconProps) => (
  <Straight fill="currentColor" {...props} />
);

const SwapArrowFilled = (props: FilledIconProps) => (
  <SwapArrow fill="currentColor" {...props} />
);

const SwapVerticalFilled = (props: FilledIconProps) => (
  <SwapVertical fill="currentColor" {...props} />
);

const TopArrowBoxFilled = (props: FilledIconProps) => (
  <TopArrowBox fill="currentColor" {...props} />
);

const TopLeftArrowBoxFilled = (props: FilledIconProps) => (
  <TopLeftArrowBox fill="currentColor" {...props} />
);

const TopRightArrowBoxFilled = (props: FilledIconProps) => (
  <TopRightArrowBox fill="currentColor" {...props} />
);

const TrafficLightFilled = (props: FilledIconProps) => (
  <TrafficLight fill="currentColor" {...props} />
);

const TrainAlt1Filled = (props: FilledIconProps) => (
  <TrainAlt1 fill="currentColor" {...props} />
);

const Transfer2Filled = (props: FilledIconProps) => (
  <Transfer2 fill="currentColor" {...props} />
);

const TransferAlt2Filled = (props: FilledIconProps) => (
  <TransferAlt2 fill="currentColor" {...props} />
);

const TransferAltFilled = (props: FilledIconProps) => (
  <TransferAlt fill="currentColor" {...props} />
);

const TransferFilled = (props: FilledIconProps) => (
  <Transfer fill="currentColor" {...props} />
);

const TrendingDownFilled = (props: FilledIconProps) => (
  <TrendingDown fill="currentColor" {...props} />
);

const TrendingUpFilled = (props: FilledIconProps) => (
  <TrendingUp fill="currentColor" {...props} />
);

const TurnFilled = (props: FilledIconProps) => (
  <Turn fill="currentColor" {...props} />
);

const WalkingFilled = (props: FilledIconProps) => (
  <Walking fill="currentColor" {...props} />
);

export {
  AngleDoubleDownFilled,
  AngleDoubleLeftFilled,
  AngleDoubleRightFilled,
  AngleDoubleUpFilled,
  ArrowAlignHFilled,
  ArrowAlignVFilled,
  ArrowDoubleDownFilled,
  ArrowDoubleLeftFilled,
  ArrowDoubleRightFilled,
  ArrowDoubleTopFilled,
  ArrowDownCircleFilled,
  ArrowDownFilled,
  ArrowExpandAltFilled,
  ArrowExpandFilled,
  ArrowLeftCircleFilled,
  ArrowLeftFilled,
  ArrowLongDownCFilled,
  ArrowLongDownE1Filled,
  ArrowLongDownEFilled,
  ArrowLongDownRFilled,
  ArrowLongLeftCFilled,
  ArrowLongLeftEFilled,
  ArrowLongLeftIFilled,
  ArrowLongLeftRFilled,
  ArrowLongRightCFilled,
  ArrowMinimizeAltFilled,
  ArrowMinimizeFilled,
  ArrowRightCircleFilled,
  ArrowRightFilled,
  ArrowTopLeftFilled,
  ArrowTopRightFilled,
  ArrowUpCircleFilled,
  ArrowUpFilled,
  ArrowsBreakHFilled,
  ArrowsBreakVFilled,
  ArrowsExchangeAltV1Filled,
  ArrowsExchangeAltVFilled,
  ArrowsExchangeAltFilled,
  ArrowsExchangeFilled,
  ArrowsExpandDownLeftFilled,
  ArrowsExpandDownRightFilled,
  ArrowsExpandLeftAltFilled,
  ArrowsExpandRightAltFilled,
  ArrowsExpandUpLeftFilled,
  ArrowsExpandUpRightFilled,
  ArrowsHorizontalFilled,
  ArrowsMergeAltHFilled,
  ArrowsMergeAltVFilled,
  ArrowsScrollHFilled,
  ArrowsScrollVFilled,
  ArrowsShrinkHFilled,
  ArrowsShrinkVFilled,
  ArrowsVerticalFilled,
  AxisFilled,
  ChevronDownCircleFilled,
  ChevronDownFilled,
  ChevronLeftCircleFilled,
  ChevronLeftFilled,
  ChevronRightCircleFilled,
  ChevronRightFilled,
  ChevronUpCircleFilled,
  ChevronUpFilled,
  CompassAlt1Filled,
  CompressFilled,
  CornerDoubleDownLeftFilled,
  CornerDoubleDownRightFilled,
  CornerDoubleLeftDownFilled,
  CornerDoubleLeftUpFilled,
  CornerDoubleRightDownFilled,
  CornerDoubleRightUpFilled,
  CornerDoubleUpLeftFilled,
  CornerDoubleUpRightFilled,
  CornerDownLeftFilled,
  CornerDownRightFilled,
  CornerLeftDownFilled,
  CornerLeftUpFilled,
  CornerRightDownFilled,
  CornerRightUpFilled,
  CornerUpLeftFilled,
  CornerUpRightFilled,
  DirectionAltFilled,
  DirectionLtrFilled,
  DirectionRtlFilled,
  DirectionFilled,
  DownArrowBoxFilled,
  DownLeftArrowBoxFilled,
  DownRightArrowBoxFilled,
  EnterFilled,
  ExitDownFilled,
  ExitUpFilled,
  ExitFilled,
  FlagCornerFilled,
  FlagsFilled,
  LeftArrowBoxFilled,
  MagnifyingGlassFilled,
  MergeHorizontalFilled,
  MergeVerticalFilled,
  MotionFilled,
  MoveDownFilled,
  MoveLeftFilled,
  MoveRightFilled,
  MoveTaskFilled,
  MoveUpFilled,
  NavigationFilled,
  NorthFilled,
  PlaceholdersFilled,
  PointerAltFilled,
  PointerDownFilled,
  PointerLeftFilled,
  PointerRightFilled,
  PointerUpFilled,
  PushDownFilled,
  PushLeftFilled,
  PushRightFilled,
  PushUpFilled,
  RightAltFilled,
  RightArrowBoxFilled,
  RightFilled,
  RouteAltFilled,
  RouteFilled,
  ScrollHFilled,
  ScrollVFilled,
  SelectArrowFilled,
  SelectBoxFilled,
  SelectOvalFilled,
  ShiftLeftFilled,
  ShiftRightFilled,
  SignalingFilled,
  StraightFilled,
  SwapArrowFilled,
  SwapVerticalFilled,
  TopArrowBoxFilled,
  TopLeftArrowBoxFilled,
  TopRightArrowBoxFilled,
  TrafficLightFilled,
  TrainAlt1Filled,
  Transfer2Filled,
  TransferAlt2Filled,
  TransferAltFilled,
  TransferFilled,
  TrendingDownFilled,
  TrendingUpFilled,
  TurnFilled,
  WalkingFilled,
};
